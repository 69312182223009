import { Button, Modal, Popover } from 'antd'
import React, { Component, Fragment } from 'react'

export default class OrderPerformancesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            performancesWithQtd: [],
        }
    }

    render() {
        return (
            <Fragment>
                <Popover content='Detalhes do pedido'>
                    <Button
                        icon='info-circle'
                        onClick={() => {
                            const performances = [...new Set(this.props.products.filter(el => el.profile_performance).map(el => el.profile_performance.split('-')[0]))]
                            const performancesWithQtd = performances.map(performance => {
                                const qtd = this.props.products.reduce((prev, current) => {
                                    if (current.profile_performance && current.profile_performance.split('-')[0] === performance)
                                        return prev += (current.amount || 0)
                                    else return prev
                                }, 0)
                                return { performance, qtd }
                            })

                            this.setState({ modal: true, performancesWithQtd })
                        }}
                    />
                </Popover>

                <Modal
                    title='Detalhes do pedido'
                    visible={this.state.modal}
                    onCancel={() => this.setState({ modal: false })}
                    cancelText='Fechar'
                >
                    Perfis de desempenho
                    {
                        this.state.performancesWithQtd.map(el => {
                            return (
                                <div>
                                    <div className='label'>{el.performance}</div>
                                    <div>{el.qtd}</div>
                                </div>
                            )
                        })
                    }
                </Modal>
            </Fragment>
        )
    }
}