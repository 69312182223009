import React from 'react';
import { Icon, Button } from 'antd';
import generalInfoFields from './components/general-info-fields';
import inputCompositionFields from './components/input-composition-fields';
import priceCompositionFields from './components/price-composition-fields';
import {
    calculateLocalManHour, calculateProductionCost, calculateSubTotalCost,
    calculateTotal, calculateTotalCost, calculateTotalManHour, calculateUnitCost,
    formTabs, getConvertionFormulas, getInputs, getLastUpdatedDate, getManHours, updateInputValues
} from '../shared/component-mounting-instalation-functions';
import MassChangeModal from './entities/mass-changes-modal';
import { findWhere } from '../../../utils/db_functions';

let inputs = [];
let manHours = [];
let convertionFormulas = [];
let typologies = [];

export default {
    formTabs: formTabs(
        generalInfoFields(),
        inputCompositionFields({
            inputs: inputs,
            convertionFormulas: convertionFormulas,
            calculateSubTotalCost: (rows) => calculateSubTotalCost(rows, inputs, convertionFormulas),
            calculateUnitCost: (input, valueObj) => calculateUnitCost(input, valueObj, convertionFormulas),
        }),
        priceCompositionFields({
            manHours: manHours,
            calculateTotalCost: (compositions) => calculateTotalCost(compositions, inputs, convertionFormulas),
            calculateProductionCost: (component) => calculateProductionCost(component, inputs, convertionFormulas),
            calculateTotal: (component, marginZero) => calculateTotal(component, marginZero, inputs, convertionFormulas),
            calculateLocalManHour: (manHour) => calculateLocalManHour(manHour),
            calculateTotalManHour: (manHourValue, qtd) => calculateTotalManHour(manHourValue, qtd),
        }),
    ),
    ListWillMount: async (_) => {
        inputs = await getInputs('updateInputDateId')
        manHours = await getManHours()
        convertionFormulas = await getConvertionFormulas()

        const typologiesParseArray = await findWhere('Config', 'name', ['typology']);
        typologies = typologiesParseArray.length && typologiesParseArray[0].get('array_with_id');
        _.forceUpdate()
    },
    FormHasMounted: (_) => {
        setTimeout(async () => {

            if (!inputs.length) inputs = await getInputs('updateInputDateId')
            // getLastUpdatedDate('updateInputDateId', inputs)
            if (!manHours.length) manHours = await getManHours()
            if (!convertionFormulas.length) convertionFormulas = await getConvertionFormulas()

            _.module.form.formTabs = formTabs(
                generalInfoFields(),
                inputCompositionFields({
                    inputs: inputs,
                    convertionFormulas: convertionFormulas,
                    calculateSubTotalCost: (rows) => calculateSubTotalCost(rows, inputs, convertionFormulas),
                    calculateUnitCost: (input, valueObj) => calculateUnitCost(input, valueObj, convertionFormulas),
                }),
                priceCompositionFields({
                    manHours: manHours,
                    calculateTotalCost: (compositions) => calculateTotalCost(compositions, inputs, convertionFormulas),
                    calculateProductionCost: (component) => calculateProductionCost(component, inputs, convertionFormulas),
                    calculateTotal: (component, marginZero) => calculateTotal(component, marginZero, inputs, convertionFormulas),
                    calculateLocalManHour: (manHour) => calculateLocalManHour(manHour),
                    calculateTotalManHour: (manHourValue, qtd) => calculateTotalManHour(manHourValue, qtd),
                }),
            )

            _.forceUpdate();
        }, 500);
    },
    submit: {
        collection: 'MountingKit'
    },
    schema: [
        {
            title: 'Código',
            key: 'code',
            dataIndex: 'code',
            width: '150px',
            type: 'text',
        },
        {
            title: 'Tipologia',
            key: 'typology',
            dataIndex: 'typology',
            width: '100px',
            type: 'render',
            render: (value) => {
                const typology = typologies.find(el => el.value === value)
                return typology && typology.label
            }
        },
        {
            title: 'Descrição',
            key: 'description',
            dataIndex: 'description',
            type: 'text',
        },
        {
            title: 'Peso',
            key: 'weight',
            dataIndex: 'weight',
            width: '80px',
            align: 'center',
            render: (peso) => `${peso || 0} Kg`
        },
        {
            title: 'HH',
            key: 'unity_value_hh',
            align: 'center',
            width: '70px',
            dataIndex: 'HH',
            type: 'text',
            render: (_, row) => `${(row.size_hh || 0).toFixed(2).replace('.', ',')}`
        },
        {
            title: 'Margem',
            key: 'margin_contri',
            align: 'center',
            width: '70px',
            dataIndex: 'Margem',
            type: 'text',
            render: (_, row) => `${row.margin_contri || 0}%`
        },
        {
            title: 'Preço',
            key: 'price_industry',
            dataIndex: 'price_industry',
            align: 'center',
            width: '120px',
            type: 'render',
            "relation-select": {
                key: ['vinculated_component']
            },
            render: (_, row) => {
                let total = 0;

                const manHour = manHours.find((manHour) => manHour.id === row.unity_value_hh.objectId)

                if (manHour) {
                    total = calculateTotal({ ...row, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas)
                }

                if (row.vinculated_component) {
                    if (manHour)
                        total = calculateTotal({ ...row.vinculated_component, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas);
                    total = total * (row.fact_multiple || 1);
                }
                return (total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
        },
    ],
    'title-module': 'Montagem de Kits',
    'topBarAction': ({ module }) =>
        <div style={{ float: 'right' }}>
            <MassChangeModal />
            <Button
                style={{ float: 'right', marginRight: '10px' }}
                onClick={() => updateInputValues(inputs)}
            >
                Atualizar preços
            </Button>
        </div>,
    'title-navigation':
        <div>
            <Icon type="tool" />
            Montagem de Kits
            <span style={{ fontSize: 'small', fontWeight: 'lighter', marginLeft: '50px' }}>
                Atualização de preços:&nbsp;
                <span className='link-style' onClick={() => getLastUpdatedDate('updateInputDateId', inputs)} id='seeDateUpdateInputDateId'>Ver data</span>
                <span id='updateInputDateId' />
            </span>
        </div>,
    module: 'MountingKit',
    'router-base': '/panel/montagem-kit',
}