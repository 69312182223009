import React from 'react'
import { Button, notification, Popconfirm } from "antd";
import Parse from 'parse'

let convertVisualizationType = (visualizationType) => {

    switch (visualizationType) {
        case 'Especificação':
            return 'Especificação';
        case 'Cotação múltipla':
            return 'Cotação múltipla';
        case 'Preço Final':
            return 'Preço projeto';
        case 'Detalhado Blocos':
            return 'Preço bloco';
        case 'Detalhado':
            return 'Preço unitário';
        case 'Simplificada':
            return 'Simplificada';
        default:
            return '';
    };
}

let getFileIcon = (typeToGetIcon) => {
    let type = 'file';
    let color = '';

    switch (typeToGetIcon) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
            type = 'file-image';
            color = 'khaki';
            break;
        case 'application/pdf':
            type = 'file-pdf';
            color = 'red';
            break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            type = 'file-excel';
            color = 'green';
            break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            type = 'file-word';
            color = 'blue';
            break;
        default:
            break;
    }

    return { type, color };
}

/**
 * Convert money string to number.
 * @param {String} moneyString 
 */
const moneyToNumber = (moneyString) => {
    if (typeof moneyString === 'number') return moneyString;

    // Remove R$
    const moneyStringWithoutCifrao = moneyString.replace('R$ ', '');

    // Remove all dots
    const moneyStringWithoutDots = moneyStringWithoutCifrao.replace(/\./g, '');

    // Change string to number
    const moneyNumber = parseFloat(moneyStringWithoutDots.replace(',', '.'));

    return moneyNumber;
}

/**
     * Return string date.
     * @param {String} date
     * @returns String date. 
     */
const dateToString = (date) => {
    // @ts-ignore
    date = new Date(date)
    return (
        // @ts-ignore
        (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear()
    )
}

/**
   * Buscar Endereço pelo CEP
   * @param {String} cep CEP
   * @return
   */
const searchAddress = async (cep) => {
    cep = cep.replace(/[^\d]+/g, '');
    if (cep === '' || cep.length !== 8) {
        return null;
    } else {
        let response = await fetch(`https://viacep.com.br/ws/${cep}/json/`,
            {
                method: 'GET',
            });
        let address = await response.json();
        if (address.erro) return null;
        return address;
    }
}

/**
   * Verifica se o CPF é valido.
   * @param {String} strCPF CPF
   * @return {Boolean}
   */
const validateCPF = (strCPF) => {
    strCPF = strCPF.trim();

    strCPF = strCPF.replace(/\./g, '');
    strCPF = strCPF.replace('-', '');

    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

/**
 * Verifica se o CNPJ é valido.
 * @param {String} cnpj CNPJ
 * @return {Boolean}
 */
const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
    ) return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        // @ts-ignore
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        // @ts-ignore
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() !== digitos.charAt(1))
        return false;

    return true;
}

const CopyObject = (objectId, objectClass, callback = null) => {
    Parse.Cloud.run('copyObject', {
        class: objectClass,
        id: objectId
    }).then(r => {
        notification.success({ message: 'Formulário copiado!', description: 'Foi gerado um novo dado no banco com as mesmas informações deste formulário.' })
        if (callback) callback(r)
    }).catch(e => {
        notification.error({ message: 'Erro ao copiar o formulário', description: 'Aconteceu alguma coisa, entre em contato com os administradores.' })

    })
}

const copyButton = Form => {
    return (Form.props.objectEdit) ? (
        <Popconfirm
            placement="left"
            title={'Confirme para gerar uma cópia ?'}
            onConfirm={() => CopyObject(Form.props.objectEdit, Form.props.module.form.submit.collection, Form)}
            okText="Confirmar"
            cancelText="Cancelar">
            <Button style={{ 'marginRight': 16 }} icon="copy">
                Gerar copia
            </Button>
        </Popconfirm>
    ) : ''
}

const ParseNumber = number => (isNaN(number) || number === Infinity) ? 0 : number

const kebabfy = (string) => {
    return string.replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}

export {
    convertVisualizationType,
    getFileIcon,
    moneyToNumber,
    dateToString,
    searchAddress,
    validateCPF,
    validateCNPJ,
    copyButton,
    CopyObject,
    ParseNumber,
    kebabfy
}