import { Row } from 'antd';
import React from 'react'

const tableFooterComponent = () => {
    let emailArray = ['Enviado', 'Não enviado'];
    let statusArray = ['Ativa ou em negociação', 'Especificação BIM', 'Concorrência', 'Aprovada', 'Parada', 'Cancelada', 'Substituida'];

    return (
        <div>

            <Row>
                <div style={{ clear: 'both', paddingBottom: 20 }}>
                    <div style={{ float: 'left', marginRight: 10 }}>
                        <b>E-mail: </b>
                    </div>
                    {
                        emailArray.map(el => {
                            let color = null

                            switch (el) {
                                case 'Enviado':
                                    color = 'green'
                                    break

                                case 'Não enviado':
                                    color = 'red'
                                    break

                                default:
                                    color = null
                            }

                            return (
                                <div style={{ float: 'left', marginRight: 30 }}>
                                    <div
                                        key={el}
                                        style={{ float: 'left', backgroundColor: color, width: '15px', height: '15px', margin: 'auto', marginBottom: '5px', marginRight: 5, borderRadius: '50%' }}
                                    /> {el}
                                </div>
                            )
                        })
                    }
                </div>
            </Row>
            <Row>
                <div style={{ clear: 'both', paddingBottom: 20 }}>
                    <div style={{ float: 'left', marginRight: 10 }}>
                        <b>Status: </b>
                    </div>
                    {
                        statusArray.map(el => {
                            let color = null
                            let label = '';

                            switch (el) {
                                case 'Ativa ou em negociação':
                                    color = 'orange'
                                    break

                                case 'Especificação BIM':
                                    color = 'green'
                                    break

                                case 'Concorrência':
                                    color = 'yellow'
                                    break

                                case 'Aprovada':
                                    color = 'blue'
                                    break

                                case 'Parada':
                                    color = 'yellow'
                                    label = 'P'
                                    break

                                case 'Cancelada':
                                    color = 'red'
                                    break

                                case 'Substituida':
                                    color = 'black'
                                    break

                                default:
                                    color = null
                            }

                            return (
                                <div style={{ float: 'left', marginRight: 30 }}>
                                    <div
                                        key={el}
                                        style={{
                                            float: 'left', backgroundColor: color,
                                            width: '15px', height: '15px', lineHeight: '15px',
                                            margin: 'auto', marginBottom: '5px', marginRight: 5,
                                            borderRadius: '50%',
                                            fontWeight: 'bold', fontSize: '10px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {label}
                                    </div>
                                    {el}
                                </div>
                            )
                        })
                    }

                </div>
            </Row>
        </div>
    )
}

export default tableFooterComponent