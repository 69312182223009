/**
 * Cria uma mascara que pode ser usada em campos de formulário (como tel, cpf e cnpj por exemplo).
 * @param {String} mask Ex.: xxx.xxx.xxx-xx.
 * @param {*} value Valor a ser aplicado a mask.
 * @param {Number} maxSize Quantidade de dígitos.
 * @param {Boolean} onlyNumber Apenas números?
 * @returns Valor com a máscara aplicada.
 */
function CreateMask(mask, value, maxSize, onlyNumber) {
    if(onlyNumber && value){
        if(isNaN((parseInt(value[value.length - 1])))) return value.slice(0, -1)
    }

    if(value){
        if(value.length <= maxSize){
            // Criar array com os simbolos e posições
            let array = []
            let arrayMask = mask.split('')
            let count = 0
            arrayMask.forEach(element => {
                if(element !== 'x'){
                    array.push({ symbol: element, position: count })
                }
                count += 1
            })
            array.forEach(x => {
                if(value.length > x.position && value[x.position] !== x.symbol){
                    let valueArray = value.split('')
                    valueArray.splice(x.position, 0, x.symbol)
                    value = valueArray.join('')
                }
            })
            return value
        } else {
            return value.substring(0, maxSize)
        }
    }
}

/**
 * Cria uma mascara específica para telefone.
 * @param {Number} value Valor a ser aplicado a mask.
 * @returns Valor com a máscara aplicada.
 */
function CreatePhoneMask(value) {
    if(value){
		if(isNaN((parseInt(value[value.length - 1])))) return value.slice(0, -1)

		
        if(value.length <= 15){
			value = value.replace('-', '')
			let mask = value.length < 14 ? '(xx) xxxx-xxxx' : '(xx) xxxxx-xxxx'

            // Criar array com os simbolos e posições
            let array = []
            let arrayMask = mask.split('')
            let count = 0
            arrayMask.forEach(element => {
                if(element !== 'x'){
                    array.push({ symbol: element, position: count })
                }
                count += 1
            })
            array.forEach(x => {
                if(value.length > x.position && value[x.position] !== x.symbol){
                    let valueArray = value.split('')
                    valueArray.splice(x.position, 0, x.symbol)
                    value = valueArray.join('')
                }
			})
            return value
        } else {
            return value.substring(0, 15)
        }
    }
}

/**
 * Valida email em Form.Item
 * @param {String} email E-mail a ser validado.
 * @returns Status e mensagem de retorno.
 */
function ValidateEmail(email) {
    let status = ''
    let msg = ''
    if(
        email.length &&
        email.length >= 3 && 
        email.search("@") !== -1 &&
        email.search(" ") === -1 &&
        email.search(/[.]/g) !== -1 &&      
        email.indexOf(".") >= 1 &&
        email.lastIndexOf(".") < email.length - 1
    ){
        status = "success"
        msg = ""
    } else {
        status = "error"
        msg = "Formato inválido de E-mail"
    }
    
    return {
        validateStatus: email.length ? status : '',
        help: email.length ? msg : ''
    }
}

export {
	CreateMask,
    CreatePhoneMask,
    ValidateEmail
}