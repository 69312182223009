import React from 'react';
import { Icon } from 'antd';
import FolderTree from './folder-tree';

const AbntIptDocsComponent = ({
	moduleToFilter,
	titleModule,
	routerBase,
	icon,
	module,
}) => {
	return {
		fields: [
			[
				{
					title: 'Descrição',
					key: 'description',
					type: "text",
					colSize: 12,
					beforeSave: async (Form, ParseObject) => {
						ParseObject.set("moduleToFilter", moduleToFilter)
						return true;
					},
				},
			],
			[
				{
					title: 'Complemento',
					key: 'additional_info',
					type: "text",
				},
			],
			[
				{
					key: 'docs_render',
					type: 'render',
					render: (_, form) => {
						return (
							<FolderTree
								parenteFolder='Documentos'
								docList={_.formRef.docs}
								updateDocList={(docList) => {
									_.formRef.docs = docList
									form.state.invalidForm = false
									form.forceUpdate()
								}}
							/>
						)
					}
				},
				{
					key: 'docs',
					render: () => '',
				}
			],
			[
				{
					type: 'render',
					render: () => {
						return (
							<div>
								<div className='order-folder-guidelines-label'>Orientações:</div>
								<div>
									<ul>
										<li>
											Clique na pasta para adicionar um arquivo/pasta, editar seu nome ou excluí-la.
										</li>

										<li>
											Para mover um arquivo, clique e arraste-o para a pasta que deseja mover (arraste para o nome da pasta e não para seu interior).
										</li>
									</ul>
								</div>
							</div>
						)
					}
				}
			]
		]
		,
		"submit": { "collection": "AbntIptDocs" },
		"defaultCurrentPage": 'last',
		"schema": [
			{
				"title": "Descrição",
				"key": "description",
				"dataIndex": "description",
				"type": "text",
			},
			{
				"title": "Complemento",
				"key": "additional_info",
				"dataIndex": "additional_info",
				"type": "text",
			},
		],
		"title-module": titleModule,
		"title-navigation": <div><Icon type={icon} /> {titleModule}</div>,
		"module": module || "AbntIptDocs",
		"router-base": routerBase,
		'filterList': () => {
			return {
				'field': 'moduleToFilter',
				'value': moduleToFilter,
			}
		}
	};
}

export default AbntIptDocsComponent;