import { Button, Table } from "antd";
import React from "react";

const inputOrdersFields = (props) => {
    return [
        [
            {
                type: 'render',
                render: (_, node) => {

                    // Get orders made to this providers
                    if (node.state.formRef.objectId) {

                        let providerCode = node.state.formRef.objectId;

                        let inputOrderArray = props.inputOrders.filter(ipt => ipt.get('provider').id === providerCode);

                        return (

                            <Table
                                locale={{ emptyText: 'Sem pedidos' }}
                                columns={[
                                    {
                                        title: 'Número',
                                        dataIndex: 'order_code',
                                        key: 'order_code',
                                        sorter: (a, b) =>
                                            (a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 :
                                                ((b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)
                                        // (a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) -
                                        // (a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")),
                                    },
                                    {
                                        title: 'Valor',
                                        dataIndex: 'total_value',
                                        key: 'total_value',
                                        width: '200px',
                                        align: 'center',
                                    },
                                    {
                                        title: 'Data da entrega',
                                        dataIndex: 'delivery_conditions',
                                        key: 'delivery_conditions',
                                        width: '200px',
                                        align: 'center',
                                        sorter: (a, b) =>
                                            (a.delivery_conditions > b.delivery_conditions) ? 1 :
                                                (a.delivery_conditions < b.delivery_conditions) ? -1 : 0,
                                    },
                                    {
                                        title: 'Pedido de compra',
                                        dataIndex: 'pdf',
                                        key: 'pdf',
                                        width: '150px',
                                        align: 'center',
                                    },
                                ]}
                                dataSource={(inputOrderArray || []).map((item, index) => {

                                    const localDate = new Date(item.get('delivery_conditions'));
                                    const localDateString = `${localDate.getDate() < 10 ? '0' : ''}${localDate.getDate()}/${(localDate.getMonth() + 1) < 10 ? '0' : ''}${localDate.getMonth() + 1}/${localDate.getFullYear()}`

                                    let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_input_order/pedido-compra-${(item.get('order_code') || '').replace('/', '')}.pdf`.replace(/\s/g, '-')

                                    return {
                                        'order_code': item.get('order_code'),
                                        'total_value': (item.get('total_value') || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                                        'delivery_conditions': localDateString,
                                        'pdf': <Button
                                            title="Visualizar pedido em PDF" type="primary" shape="circle" icon="file-pdf"
                                            onClick={() => window.open(pdfName, '_blink')}
                                        />
                                    }
                                })}
                            />
                        )
                    }
                }
            }
        ]
    ]
}

export default inputOrdersFields