import { Button, Icon, Input, message, Modal, notification } from 'antd'
import React, { Component, Fragment } from 'react'
import Parse from 'parse';

export default class SendQualificationSurvey extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <Button
                    onClick={async () => {
                        let email = '';
                        Modal.confirm({
                            title: 'Enviar Pesquisa de Qualificação',
                            content: (
                                <div>
                                    <p>Informe o e-mail</p>
                                    <Input placeholder='E-mail' onChange={e => email = e.target.value} />

                                    <div
                                        className='link-style'
                                        style={{ marginTop: '20px' }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `http://minhamultidoor.com.br/cadastro-para-atendimento`
                                            ).then(() => {
                                                message.success('Link copiado com sucesso!');
                                            })
                                        }}
                                    >
                                        Copiar link
                                    </div>
                                </div>
                            ),
                            onOk() {
                                if (!email) {
                                    message.error('Informe um e-mail');
                                } else {

                                    notification.open({
                                        message: 'Enviando e-mail.',
                                        description: `A pesquisa de qualificação está sendo enviada para o email ${email}, aguarde...`,
                                        key: 'sendingQualificationSurvey',
                                        duration: 0,
                                        placement: 'topLeft',
                                        icon: <Icon type="loading" />
                                    });

                                    Parse.Cloud.run('sendQualificationSurveyByEmail', { email })
                                        .then(async () => {
                                            notification.close('sendingQualificationSurvey');
                                            notification.success({
                                                message: 'E-mail enviado.',
                                                description: 'O e-mail foi enviado'
                                            })
                                        })
                                        .catch(() => {
                                            notification.close('sendingQualificationSurvey');
                                            notification.error({
                                                message: 'Alguma coisa deu errado',
                                                description: 'Verifique os dados e tente novamente',
                                            });
                                        })
                                }
                            },
                            okText: 'Enviar',
                            cancelText: 'Cancelar'
                        });
                    }}
                    style={{ marginRight: '10px' }}
                >Enviar pesquisa</Button>
            </Fragment>
        )
    }

}