import contractModel from "../suport-files/contract-model";

const React = require("react");
const { default: ReactQuill } = require("react-quill");

const defaultContractFields = (modules, formats) => [
    [
        {
            type: 'render',
            render: () => <div style={{ color: 'red', fontSize: 'small' }}>
                ATENÇÃO: Não deletar os termos abaixo listados. Os mesmos são importantes para o carregamento adequado dos dados no pdf do contrato.<br /><br />
                <ul>
                    <li>%CLIENTE_DADOS%</li>
                    <li>%MULTIDOOR_DADOS%</li>
                    <li>%OBRA_DADOS%</li>
                    <li>%PREÇO%</li>
                    <li>%PREÇO_SERVIÇO%</li>
                    <li>%CRONOGRAMA_SERVIÇOS%</li>
                    <li>%PREÇO_INDUSTRIALIZAÇÃO%</li>
                    <li>%CRONOGRAMA_INDUSTRIALIZAÇÃO%</li>
                    <li>%PREÇO_INSTALAÇÃO%</li>
                    <li>%CRONOGRAMA_INSTALAÇÃO%</li>
                    <li>%CRONOGRAMA_EXECUÇÃO%</li>
                    <li>%DATA_EMISSÃO%</li>
                </ul>
            </div>
        }
    ],
    [
        {
            key: 'defaultContract',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.defaultContract || contractModel}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'defaultContract')
                        }}
                    />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('defaultContract', module.state.formRef.defaultContract)
                return true;
            }
        }
    ],
]

export default defaultContractFields