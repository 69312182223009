import React from 'react'
import { Icon, Button, message, notification } from 'antd'
import '../../styles/input.css';
import { findAll, findById } from '../../../utils/db_functions';
import UpdateInputsModal from './entities/update-inputs-modal';
import { getStringFormula, stringFormulas } from '../../../utils/unity';
import { calculateInputTax, getCostArrayToUpdateHistoric, renderCostHistoricTable } from './suport-files/inputs-functions';

export default {
  "formTabs": [
    {
      title: 'Informações do Insumo',
      fields: [
        [
          {
            title: "Código",
            key: "code",
            type: "text",
            colSize: 6,
            required: true,
            "element-attr": {
              "required": true,
              "prefix": <Icon type="code-o" />,
            },
            beforeSave: async (Form, ParseObject, node) => {
              if (!ParseObject.get('updated_data')) {
                const updatedData = await getUpdatedInputToPutInUpdatedDataField(Form.state.formRef)
                await ParseObject.set('updated_data', updatedData)
              }

              const costsRecorded = await getCostArrayToUpdateHistoric(Form)
              await ParseObject.set('industry_cost_history', costsRecorded)

              return true;
            }
          },
          {
            title: "Fornecedor",
            key: "provider",
            type: "text",
            colSize: 8,
            "element-attr": { "required": true, "prefix": <Icon type="tag-o" /> }
          },
          {
            type: 'select',
            key: 'measure',
            title: 'Unidade Compra',
            options: [],
            optionConfig: { name: 'unidades' },
            loaded: false,
            "element-attr": {
              mode: 'select',
              required: true
            },
            colSize: 3
          },
          {
            title: "Código Fornecedor",
            key: "provider_code",
            type: "text",
            colSize: 4,
            "element-attr": { required: false, "prefix": <Icon type="tag-o" /> }
          },
          {
            title: 'Preço base ?',
            key: 'is_price_base',
            type: 'switch',
            colSize: 3
          },
          {
            title: "Descrição do Material",
            key: "material_description",
            type: "text",
            colSize: 24,
            "element-attr": { required: false, "prefix": <Icon type="form" /> }
          },
          {
            title: "Embalagem",
            key: "package",
            type: "text",
            colSize: 6,
            "element-attr": { required: false, "prefix": <Icon type="dropbox" /> }
          },
          {
            title: "Conversão",
            key: "convert",
            type: "number",
            colSize: 3,
            step: 0.1,
            "element-attr": { required: false, "prefix": <Icon type="link" /> }
          },
          {
            "title": "Insumos Equivalentes",
            "key": "price_base",
            "type": "relation",
            "options": [],
            "relation-select": {
              "class": "Input",
              "label": (obj) => `${obj.code} - ${obj.material_description}`,
              "orderBy": "code",
              "value": "objectId",
              "key": "objectId",
              searchFields: ['code', 'material_description'],
              where: {
                code: (Relation, Query) => {
                  const code = Relation.props.form.state.formRef.code
                  let id = (code) ? code.split('.')[0] : ''
                  let query = { $regex: '^' + id + '.', $options: "i" }
                  return query
                }
              },
              excludeValue: {
                code: 'code',
                value: (obj) => {
                  return obj.props.form.state.formRef.code
                }
              }
            },
            _ignoreCache: true,
            colSize: 15,
            "element-attr": {
              "required": false,
              disabled: (Form) => {
                return (!Form.state.formRef.code || !Form.state.formRef.is_price_base)
              }
            },
          },
          {
            title: "Classificação NCM",
            key: "ncm",
            type: "text",
            colSize: 8,
            "element-attr": { required: false }
          },
          {
            title: "Destino insumo",
            key: "input_destiny",
            type: "select",
            colSize: 8,
            options: [
              { label: 'Industrialização - matéria prima', value: 'ind_mat' },
              { label: 'Industrialização - montagem', value: 'ind_mont' },
              { label: 'Industrialização - instalação', value: 'ind_inst' },
              { label: 'Consumo', value: 'consumption' },
              { label: 'Manutenção', value: 'maintenance' },
              { label: 'Ativo fixo', value: 'fixed_asset' },
              { label: 'Revenda', value: 'resale' },
            ],
            "element-attr": { required: false, mode: 'default' }
          },
          {
            type: 'select',
            key: 'consumption_measure',
            title: 'Unidade Consumo',
            options: [],
            optionConfig: { name: 'unidades' },
            loaded: false,
            "element-attr": {
              mode: 'select',
              required: true
            },
            colSize: 8
          },
          {
            title: "Fórmula de conversão",
            type: "render",
            colSize: 24,
            key: 'render-conversion-formula',
            render: (state, module, node) => {
              const formula = getStringFormula(formulas, module.getNodeValue('measure'), module.getNodeValue('consumption_measure'))
              const formulaToGetName = formulas.find(el => el.get('formula') === formula)
              return (
                <div style={{ lineHeight: '40px', fontSize: 'larger' }}>
                  <span style={{ fontWeight: 'bold' }}>{formulaToGetName ? `${formulaToGetName.get('name')}: ` : ''}</span>
                  {formula ? stringFormulas[formula] : 'Sem fórmula padrão'}
                </div>
              )
            }
          },
          {
            title: "Informações Adicionais",
            key: "info_add",
            type: "textarea",
            colSize: 24,
            "element-attr": { required: false, "prefix": <Icon type="dropbox" /> }
          }
        ],
      ],
    },
    {
      title: 'Custos de Aquisição Industria',
      fields: [
        [
          {
            title: "Material",
            key: "material",
            type: "money",
            colSize: 6,
            "element-attr": { "required": true }
          },
          {
            title: "Crédito Imposto?",
            key: "credit_tax_material",
            type: "switch",
            defaultValue: true,
            colSize: 3,
            "element-attr": { required: true, defaultChecked: true }
          },
        ],
        [
          {
            title: "Frete (%)",
            key: "shipment_rate_percent",
            type: "number",
            step: 0.01,
            colSize: 3,
            "element-attr": { "required": false }
          },
          {
            title: "Frete (valor)",
            key: "shipment_rate",
            type: "money",
            colSize: 3,
            "element-attr": { "required": false }
          },
          {
            title: "Crédito Imposto?",
            key: "credit_tax_shipment",
            type: "switch",
            defaultValue: false,
            colSize: 3,
            "element-attr": { "required": true }
          },
        ],
        [
          {
            title: "IPI (%)",
            key: "ipi",
            "element-attr": { "required": false },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
          {
            title: "Valor do IPI",
            type: "render",
            colSize: 3,

            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {(module.getNumberValue('ipi') / 100 * module.getNumberValue('material')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
        ],
        [
          {
            title: "ICMS (%)",
            key: "pis_icms",
            "element-attr": { "required": false },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
          {
            title: "Valor do ICMS",
            type: "render",
            colSize: 3,

            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {
                    calculateInputTax('pis_icms', module).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  }
                </div>
              )
            }
          },
        ],
        [
          {
            title: "PIS + COFINS (%)",
            key: "pis_c",
            "element-attr": { "required": false },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
          {
            title: "Valor do PIS + COFINS",
            type: "render",
            colSize: 3,
            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {
                    calculateInputTax('pis_c', module).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  }
                </div>
              )
            }
          },
        ],
        [
          {
            title: "Perdas",
            key: "loss",
            "element-attr": { "required": false, "prefix": <Icon type="paper-clip" /> },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
        ],
        [
          {
            title: "Total do material",
            type: "render",
            colSize: 4,
            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {getTotalMaterial(module).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
          {
            title: "Total de impostos",
            type: "render",
            colSize: 4,
            key: 'render-total-imposto',
            render: (state, module, node) => {
              let totalImp = getTotalImp(module)
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {totalImp.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            },
          },
          {
            title: "Perdas",
            type: "render",
            colSize: 4,
            key: 'render-total-imposto',
            render: (state, module, node) => {
              const totalImp = getTotalImp(module)
              const totalMaterial = getTotalMaterial(module)
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {(loss || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            },
          },
          {
            title: "Custo final",
            type: "render",
            colSize: 4,
            key: 'render-total-final',
            render: (_, module, node) => {
              const totalImp = getTotalImp(module)
              const totalMaterial = getTotalMaterial(module)
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {((total + loss) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          }
        ],
        [
          {
            key: 'industry_cost_history',
            type: 'render',
            render: (_, form) => {
              return renderCostHistoricTable(_.formRef.industry_cost_history, form)
            },
          }
        ]
      ],
    },
    {
      title: 'Custos de Aquisição Cliente-Consumidor',
      fields: [
        [
          {
            title: "Material",
            key: "material",
            type: "money",
            colSize: 6,
            "element-attr": { "required": true }
          },
        ],
        [
          {
            title: "Frete (%)",
            key: "shipment_rate_percent_customer_cost",
            type: "number",
            step: 0.01,
            colSize: 3,
            "element-attr": { "required": false }
          },
          {
            title: "Frete (valor)",
            key: "shipment_rate_customer_cost",
            type: "money",
            colSize: 3,
            "element-attr": { "required": false }
          },
          {
            title: "Crédito Imposto?",
            key: "credit_tax_shipment_customer_cost",
            type: "switch",
            defaultValue: false,
            colSize: 3,
            "element-attr": { "required": true }
          },
        ],
        [
          {
            title: "IPI (%)",
            key: "ipi_customer_cost",
            "element-attr": { "required": false },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
          {
            title: "Valor do IPI",
            type: "render",
            colSize: 3,

            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {(module.getNumberValue('ipi_customer_cost') / 100 * module.getNumberValue('material')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
        ],
        [
          {
            title: "ICMS (%)",
            key: "pis_icms_customer_cost",
            "element-attr": { "required": false },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
          {
            title: "Valor do ICMS",
            type: "render",
            colSize: 3,

            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {
                    calculateInputTax('pis_icms_customer_cost', module, '_customer_cost').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  }
                </div>
              )
            }
          },
        ],
        [
          {
            title: "PIS + COFINS (%)",
            key: "pis_c_customer_cost",
            "element-attr": { "required": false },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
          {
            title: "Valor do PIS + COFINS",
            type: "render",
            colSize: 3,
            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {
                    calculateInputTax('pis_c_customer_cost', module, '_customer_cost').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  }
                </div>
              )
            }
          },
        ],
        [
          {
            title: "Perdas",
            key: "loss",
            "element-attr": { "required": false, "prefix": <Icon type="paper-clip" /> },
            type: 'number',
            step: 0.01,
            colSize: 3,
          },
        ],
        [
          {
            title: "Total do material",
            type: "render",
            colSize: 4,

            key: 'render-total-material',
            render: (state, module, node) => {
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {getTotalMaterial(module, '_customer_cost').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
          {
            title: "Total de impostos",
            type: "render",
            colSize: 4,
            key: 'render-total-imposto',
            render: (state, module, node) => {
              let totalImp = getTotalImp(module, '_customer_cost')
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {totalImp.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            },
          },
          {
            title: "Perdas",
            type: "render",
            colSize: 4,
            key: 'render-total-imposto',
            render: (state, module, node) => {
              const totalImp = getTotalImp(module, '_customer_cost')
              const totalMaterial = getTotalMaterial(module, '_customer_cost')
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {(loss || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            },
          },
          {
            title: "Custo final",
            type: "render",
            colSize: 4,
            key: 'render-total-final',
            render: (_, module, node) => {
              const totalImp = getTotalImp(module, '_customer_cost')
              const totalMaterial = getTotalMaterial(module, '_customer_cost')
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {((total + loss) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          }
        ],
      ],
    },
    {
      title: 'Preço revenda',
      fields: [
        [
          {
            title: "Código",
            type: "render",
            colSize: 4,
            key: 'render-code',
            render: (_, module, node) => {
              return (
                <div style={{ lineHeight: '40px' }}>
                  {module.state.formRef.code}
                </div>
              )
            }
          },
          {
            title: "Descrição",
            type: "render",
            key: 'render-description',
            render: (_, module, node) => {
              return (
                <div style={{ lineHeight: '40px' }}>
                  {module.state.formRef.material_description}
                </div>
              )
            }
          },
        ],
        [
          {
            title: "Custo de aquisição Industria",
            type: "render",
            colSize: 4,
            key: 'render-total-final',
            render: (_, module, node) => {
              const totalImp = getTotalImp(module)
              const totalMaterial = getTotalMaterial(module)
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {((total + loss) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
        ],
        [
          {
            title: "Markup Revenda",
            key: "resale_markup",
            type: "number",
            step: 0.1,
            colSize: 3,
            "element-attr": { "required": false, min: 2, max: 5 }
          },
          {
            title: "Mão de obra de instalação",
            key: "resale_service",
            type: "money",
            colSize: 4,
            "element-attr": { "required": false }
          },
        ],
        [
          {
            title: "Preço Revenda",
            type: "render",
            colSize: 4,
            key: 'render-resale-price',
            render: (_, module, node) => {
              const totalImp = getTotalImp(module)
              const totalMaterial = getTotalMaterial(module)
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {(((total + loss) || 0) * module.getNumberValue('resale_markup')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
          {
            title: "Preço Revenda c/ instalação",
            type: "render",
            colSize: 4,
            key: 'render-resale-price',
            render: (_, module, node) => {
              const totalImp = getTotalImp(module)
              const totalMaterial = getTotalMaterial(module)
              const total = totalMaterial - totalImp;
              const loss = ((module.getNumberValue('loss') / 100) * (total))
              return (
                <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                  {(((total + loss) || 0) * module.getNumberValue('resale_markup') + (module.getNumberValue('resale_service') || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </div>
              )
            }
          },
        ],
      ],
    },
  ],
  "ListWillMount": async (_) => {
    formulas = await findAll('ConvertionConfigs')
    _.forceUpdate()
  },
  "submit": { "collection": "Input" },
  "schema": [{
    title: "Código",
    key: "code",
    dataIndex: "code",
    align: 'center',
    sorter: (a, b) => a.code.localeCompare(b.code),
    type: "text"
  }, {
    title: "Descrição do Material",
    key: "material_description",
    sorter: (a, b) => a.material_description.localeCompare(b.material_description),
    dataIndex: "material_description",
    type: "text"
  }, {
    title: "Nome do Fornecedor",
    key: "provider",
    align: 'center',
    sorter: (a, b) => a.provider.localeCompare(b.provider),
    dataIndex: "provider",
    type: "text"
  }, {
    title: 'Unidade de Compra',
    key: 'measure',
    align: 'center',
    dataIndex: 'measure',
    type: 'text'
  }, {
    title: 'Custo Aquisição',
    key: 'material',
    dataIndex: 'material',
    type: 'render',
    width: '110px',
    render: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }, {
    title: "Unidade Consumo",
    key: "consumption_measure",
    align: 'center',
    dataIndex: "consumption_measure",
    type: "text"
  }, {
    title: "Custo de Produção",
    key: "cost",
    dataIndex: "cost",
    type: "render",
    width: '110px',
    render: (_, row) => {
      return (getUpdatedTotalCost(row) * (row.convert || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }, {
    title: 'Preço Base',
    key: 'is_price_base',
    dataIndex: 'is_price_base',
    type: 'render',
    render: (value) => {
      return (
        <div
          style={{ backgroundColor: value ? 'green' : 'red', width: '25px', height: '25px', margin: 'auto', marginBottom: '5px', borderRadius: '50%' }}
        />
      )
    }
  }, {
    title: 'Atualizar',
    key: 'update_components',
    dataIndex: 'update_components',
    type: 'render',
    align: 'center',
    render: (_, row) => {
      // Check if price is update
      const updated = !row['updated_data'] ? true : fieldsToUpdate.every(el => (row[el] || '').toString() === (row['updated_data'][0][el] || '').toString())

      return (
        <Button
          title="Atualizar preço do insumo"
          disabled={updated}
          type="primary" shape="circle" icon="sync"
          onClick={async () => {
            message.info('Atualizando...')

            // Get input to update
            let inputToUpdate = await findById('Input', row['objectId']);

            // Update cost
            let updated_data = row['updated_data'];
            fieldsToUpdate.forEach(el => {
              if (!updated_data) updated_data = [{}];
              updated_data[0][el] = row[el];
            })

            // Set and save in db
            await inputToUpdate.set('updated_data', updated_data);
            await inputToUpdate.save();

            notification.success({
              message: 'Cadatro atualizado com sucesso',
              description: 'Atualize a página para verificar a atualização.',
            });
          }}
        />
      )
    }
  }],
  "title-module": "Insumos",
  "title-navigation": <div><Icon type="dropbox" /> Insumos</div>,
  "module": "Input",
  "router-base": "/panel/insumos",
  'topBarAction': ({ module }) => <UpdateInputsModal />,
}

let formulas = [];

function getTotalMaterial(module, nodeValueAdditionalString = '') {
  const shipmentRatePercent = (module.getNumberValue(`shipment_rate_percent${nodeValueAdditionalString}`) || 0) / 100
  return (
    (
      module.getNumberValue(`shipment_rate${nodeValueAdditionalString}`) +
      ((module.getNumberValue(`ipi${nodeValueAdditionalString}`) / 100) * module.getNumberValue('material')) +
      (module.getNumberValue('material') * (1 + shipmentRatePercent))
    )
  )
}

function getTotalImp(module, nodeValueAdditionalString = '') {

  return calculateInputTax('pis_c', module, nodeValueAdditionalString) + calculateInputTax('pis_icms', module, nodeValueAdditionalString)
}

function getUpdatedTotalCost(row) {

  let pis_c = (row['pis_c'] / 100 || 0)
  let pis_icms = (row['pis_icms'] / 100 || 0)
  let ipi = (row['ipi'] / 100 || 0)
  let material = (row['material'] || 0)
  let shipmentRate = (row['shipment_rate'] || 0)
  let shipmentRatePercent = (row['shipment_rate_percent'] || 0) / 100
  let loss = (row['loss'] / 100 || 0)

  let totalImp = (pis_c * ((row['credit_tax_shipment'] && shipmentRate) + (row['credit_tax_material'] && material))) +
    (pis_icms * ((row['credit_tax_shipment'] && shipmentRate) + (row['credit_tax_material'] && material)));

  let totalMaterial = (shipmentRate + (shipmentRatePercent * material) + (ipi * material) + material);
  loss = (loss * (totalMaterial - totalImp));

  return totalMaterial + loss - totalImp;
}

/**
 * Get total cost updated.
 * @param {*} row 
 */
function getTotalCost(row, index = 0) {

  let pis_c = (row['updated_data'][index]['pis_c'] / 100 || 0)
  let pis_icms = (row['updated_data'][index]['pis_icms'] / 100 || 0)
  let ipi = (row['updated_data'][index]['ipi'] / 100 || 0)
  let shipmentRate = (row['updated_data'][index]['shipment_rate'] || 0)
  let material = (row['updated_data'][index]['material'] || 0)
  let loss = (row['updated_data'][index]['loss'] / 100 || 0)

  let totalImp = (pis_c * ((row['updated_data'][index]['credit_tax_shipment'] && shipmentRate) + (row['updated_data'][index]['credit_tax_material'] && material))) +
    (pis_icms * ((row['updated_data'][index]['credit_tax_shipment'] && shipmentRate) + (row['updated_data'][index]['credit_tax_material'] && material)));

  let totalMaterial = (shipmentRate + (ipi * material) + material);
  loss = (loss * (totalMaterial - totalImp));

  return totalMaterial + loss - totalImp;
}

/**
 * Get updated material cost.
 * @param {*} row 
 */
function getMaterialCost(row, index = 0) {
  return (
    (row['updated_data'][index]['shipment_rate'] || 0) +
    (
      ((row['updated_data'][index]['ipi'] || 0) / 100) *
      (row['updated_data'][index]['material'] || 0)
    ) +
    (row['updated_data'][index]['material'] || 0)
  )
}

const fieldsToUpdate = [
  'credit_tax_material', 'credit_tax_shipment', 'ipi', 'loss', 'material', 'measure',
  'pis_c', 'pis_icms', 'shipment_rate', 'convert', 'consumption_measure'
]

const getUpdatedInputToPutInUpdatedDataField = async (inputObject) => {
  let updatedData = [{}]
  fieldsToUpdate.forEach(el => {
    updatedData[0][el] = inputObject[el];
  })

  return updatedData
}

export {
  getTotalCost,
  getTotalImp,
  getTotalMaterial,
  getMaterialCost
}
