import { Button, Col, Divider, Form, Icon, Input, message, Row, Spin, Switch, Upload } from 'antd'
import React, { Component, Fragment } from 'react'
import { CreateMask, CreatePhoneMask, ValidateEmail } from '../../../../../utils/mask'
import { findAll, findWhere, findWhereMultiple, save } from '../../../../../utils/db_functions'
import { searchAddress, validateCNPJ, validateCPF } from '../../../../../utils/general_functions'
import { Link } from 'react-router-dom'
import Parse from 'parse'
import ContactsFields from '../contacts-fields'
import ClientChooseModal from '../../entities/client-choose-modal'

export default class ClientStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loadingSaveButton: false,
            clients: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const clients = this.props.dataConfigs['client-client'] || await findAll('Client', 99999)

        const dataConfigsArray = await findWhere('Config', 'name', ['proposal_form_notes_cliente'])
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {})

        this.setState({ loading: false, clients, dataConfigs })

        this.props.setDataConfigs({ 'client-client': clients })
    }

    fields = [
        'is_company', 'cnpj', 'state_registration', 'social_reason', 'cpf', 'rg',
        'mother_name', 'name_client', 'cod_cliente', 'cep_client', 'address_client', 'complement_client',
        'neighborhood_client', 'state_client', 'city_client', 'site_client', 'instagram_client', 'cnpj_receita',
    ]

    checkPessoaJuridica(ativo) {
        let companyList = ['cnpj', 'state_registration', 'social_reason']
        let personList = ['cpf', 'rg', 'mother_name']
        let getList = (ativo) => ativo ? personList : companyList

        this.props.updateRequired({ removed: getList(ativo), add: getList(!ativo), type: 'proposal' })
    }

    getClientUpdated = () => {
        let clientData = {}

        for (let fieldIndex = 0; fieldIndex < this.fields.length; fieldIndex++) {
            const field = this.fields[fieldIndex];

            clientData[field] = this.props.getValue(field)
        }

        return clientData

    }

    setClientUpdated = (clientParseObject, deleteClientData = false) => {

        for (let fieldIndex = 0; fieldIndex < this.fields.length; fieldIndex++) {
            const field = this.fields[fieldIndex];

            if (deleteClientData) this.props.updateDataValue(field, null)
            else this.props.updateDataValue(field, clientParseObject.get(field))
        }

    }

    generateNewClientCode = async () => {
        const lastClient = await findWhereMultiple(
            'Client',
            [{ 'field': 'cod_cliente', 'value': null, 'op': 'notEqual' }],
            null, null, null,
            'cod_cliente', 'descending'
        )
        const lastCode = lastClient[0].get('cod_cliente')
        const newNumberCode = (parseInt(lastCode) + 1).toString()
        const repeatZero = 5 - newNumberCode.length
        return `${'0'.repeat(repeatZero) + newNumberCode}`
    }

    updateClientData = async (code) => {
        this.setState({ loadingSaveButton: true })

        const client = await findWhere('Client', 'cod_cliente', [code])

        if (client && client.length) {
            const clientToUpdate = this.getClientUpdated()

            try {
                await save('Client', clientToUpdate, client[0].id)
                message.success('Cliente Salvo')
            } catch (e) {
                message.error('Erro ao savar o cliente')
            }
        }

        this.setState({ loadingSaveButton: false })
    }

    saveClientData = async () => {
        this.setState({ loadingSaveButton: true })

        const clientToUpdate = this.getClientUpdated()

        clientToUpdate['cod_cliente'] = await this.generateNewClientCode()

        try {
            await save('Client', clientToUpdate)
            message.success('Cliente Salvo')
            this.componentDidMount()

        } catch (e) {
            message.error('Erro ao savar o cliente')
        }

        this.props.updateDataValue('cod_cliente', clientToUpdate['cod_cliente'])
        this.setState({ loadingSaveButton: false })
    }

    getAndSetClienInfo = async (attr, value) => {
        const clients = await findWhere('Client', attr, [value])
        if (clients && clients.length) this.setClientUpdated(clients[0])
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading} tip='Carregando as informações...' size='large'>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Pessoa Jurídica">
                                        <Switch
                                            checked={this.props.getValue('is_company')}
                                            onChange={async v => {
                                                this.setClientUpdated(null, true)

                                                await this.props.updateDataValue('is_company', v)
                                                this.checkPessoaJuridica(this.props.getValue('is_company'))
                                            }}
                                            disabled={this.props.disabledFields}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Código do cliente">
                                        <ClientChooseModal
                                            codClient={this.props.getValue('cod_cliente')}
                                            uniqueId={this.props.getValue('is_company') ? this.props.getValue('cnpj') : this.props.getValue('cpf')}
                                            chooseClient={(parseObjectValue) => {
                                                if (parseObjectValue) {
                                                    this.props.updateDataValue('cod_cliente', parseObjectValue.get('cod_cliente'))
                                                    this.setClientUpdated(parseObjectValue)
                                                }
                                            }}
                                            removeClient={() => {
                                                this.props.updateDataValue('cod_cliente', null)
                                                this.setClientUpdated(null, true)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label={this.props.getValue('is_company') ? "CNPJ" : "CPF"} required>
                                        <Input
                                            value={
                                                this.props.getValue('is_company') ?
                                                    CreateMask('xx.xxx.xxx/xxxx-xx', this.props.getValue('cnpj'), 18, true) :
                                                    CreateMask('xxx.xxx.xxx-xx', this.props.getValue('cpf'), 14, true)
                                            }
                                            onChange={event => {
                                                if (this.props.getValue('is_company')) {
                                                    this.props.updateDataValue('cnpj', event.target.value);
                                                    if (validateCNPJ(event.target.value)) document.getElementById('proposal_cnpjIsValid').style.display = 'none'
                                                    else document.getElementById('proposal_cnpjIsValid').style.display = 'block'
                                                } else {
                                                    this.props.updateDataValue('cpf', event.target.value);
                                                    if (validateCPF(event.target.value)) document.getElementById('proposal_cpfIsValid').style.display = 'none'
                                                    else document.getElementById('proposal_cpfIsValid').style.display = 'block'
                                                }
                                            }} placeholder={this.props.getValue('is_company') ? "CNPJ" : "CPF"} size="large" disabled={this.props.disabledFields}
                                            onBlur={event => {
                                                this.props.getValue('is_company') ?
                                                    this.getAndSetClienInfo('cnpj', event.target.value) :
                                                    this.getAndSetClienInfo('cpf', event.target.value)
                                            }} />
                                        {this.props.getValue('is_company') ?
                                            <span style={{ color: 'red', display: 'none', marginTop: '-10px' }} id='proposal_cnpjIsValid'>CNPJ invalido</span> :
                                            <span style={{ color: 'red', display: 'none', marginTop: '-10px' }} id='proposal_cpfIsValid'>CPF invalido</span>
                                        }
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label={this.props.getValue('is_company') ? "Inscrição Estadual" : "RG"}>
                                        <Input
                                            value={
                                                this.props.getValue('is_company') ?
                                                    this.props.getValue('state_registration') :
                                                    this.props.getValue('rg')
                                            }
                                            onChange={event => {
                                                this.props.getValue('is_company') ?
                                                    this.props.updateDataValue('state_registration', event.target.value) :
                                                    this.props.updateDataValue('rg', event.target.value)
                                            }}
                                            placeholder={this.props.getValue('is_company') ? "Inscrição Estadual" : "RG"}
                                            size="large" disabled={this.props.disabledFields}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={this.props.getValue('is_company') ? "Nome empresarial" : "Nome"}>
                                        <Input
                                            value={
                                                this.props.getValue('is_company') ?
                                                    this.props.getValue('social_reason') :
                                                    this.props.getValue('name_client')
                                            }
                                            onChange={event => {
                                                this.props.getValue('is_company') ?
                                                    this.props.updateDataValue('social_reason', event.target.value) :
                                                    this.props.updateDataValue('name_client', event.target.value)
                                            }}
                                            placeholder={this.props.getValue('is_company') ? "Nome empresarial" : "Nome"}
                                            size="large" disabled={this.props.disabledFields}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label={this.props.getValue('is_company') ? "Nome de fantasia" : "Nome da Mãe"}>
                                        <Input
                                            value={
                                                this.props.getValue('is_company') ?
                                                    this.props.getValue('name_client') :
                                                    this.props.getValue('mother_name')
                                            }
                                            onChange={event => {
                                                this.props.getValue('is_company') ?
                                                    this.props.updateDataValue('name_client', event.target.value) :
                                                    this.props.updateDataValue('mother_name', event.target.value)
                                            }}
                                            placeholder={this.props.getValue('is_company') ? "Nome de fantasia" : "Nome da Mãe"}
                                            size="large" disabled={this.props.disabledFields}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Empresa solicitante">
                                        <Input
                                            value={this.props.getValue('requesting_company')}
                                            onChange={event => this.props.updateDataValue('requesting_company', event.target.value)} placeholder="Empresa solicitante" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Form.Item label="CEP">
                                        <Input
                                            value={CreateMask('xx.xxx-xxx', this.props.getValue('cep_client'), 10, true)}
                                            onChange={event => this.props.updateDataValue('cep_client', event.target.value)}
                                            onBlur={async event => {
                                                this.setState({ loading: true })
                                                const address = await searchAddress(event.target.value)
                                                if (address) {
                                                    this.props.updateDataValue('address_client', address.logradouro);
                                                    this.props.updateDataValue('complement_client', address.complemento);
                                                    this.props.updateDataValue('neighborhood_client', address.bairro);
                                                    this.props.updateDataValue('state_client', address.uf.toUpperCase());
                                                    this.props.updateDataValue('city_client', address.localidade);
                                                }
                                                this.setState({ loading: false })
                                            }}
                                            placeholder="CEP" size="large" disabled={this.props.disabledFields}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Endereço">
                                        <Input
                                            value={this.props.getValue('address_client')}
                                            onChange={event => this.props.updateDataValue('address_client', event.target.value)} placeholder="Endereço" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label="Número">
                                        <Input
                                            value={this.props.getValue('address_number_client')}
                                            onChange={event => this.props.updateDataValue('address_number_client', event.target.value)} placeholder="Número" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label="Complemento">
                                        <Input
                                            value={this.props.getValue('complement_client')}
                                            onChange={event => this.props.updateDataValue('complement_client', event.target.value)} placeholder="Complemento" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Bairro">
                                        <Input
                                            value={this.props.getValue('neighborhood_client')}
                                            onChange={event => this.props.updateDataValue('neighborhood_client', event.target.value)} placeholder="Bairro" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label="Estado">
                                        <Input
                                            value={this.props.getValue('state_client')}
                                            onChange={event => this.props.updateDataValue('state_client', event.target.value)} placeholder="Estado" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label="Cidade">
                                        <Input
                                            value={this.props.getValue('city_client')}
                                            onChange={event => this.props.updateDataValue('city_client', event.target.value)} placeholder="Cidade" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Site/Instagram">
                                        <Input
                                            value={this.props.getValue('site_client') || this.props.getValue('instagram_client')}
                                            onChange={event => this.props.updateDataValue('site_client', event.target.value)} placeholder="Site" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={
                                            <span>
                                                CNPJ Receita (<a href='http://servicos.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp' target='blank'>Acesse aqui</a>)
                                            </span>
                                        }
                                    >
                                        <div style={{ width: '100%' }}>
                                            {this.props.getValue('cnpj_receita') ?
                                                <div>
                                                    <div style={{ float: 'left', width: '80%', lineHeight: '40px' }}>
                                                        <Link to={this.props.getValue('cnpj_receita').url} target="_blank">Consulta receita.pdf</Link>
                                                    </div>
                                                    <div style={{ float: 'left', width: '20%', lineHeight: '40px', cursor: 'pointer', textAlign: 'center', display: this.props.disabledFields ? 'none' : 'block' }} onClick={() => this.props.updateDataValue('cnpj_receita', null)}><Icon type="delete" /></div>
                                                    <div style={{ clear: 'both' }} />
                                                </div> :
                                                <Upload
                                                    // @ts-ignore
                                                    beforeUpload={async (file, fileList) => {
                                                        if (file.type === 'application/pdf') {
                                                            let fileName = new Date().getTime() + '.pdf'
                                                            this.props.updateDataValue('cnpj_receita', new Parse.File(fileName, file))
                                                        } else {
                                                            message.error('Arquivo inválido! Selecione um arquivo em pdf.')
                                                        }
                                                    }}
                                                    // @ts-ignore
                                                    onRemove={(file) => this.props.updateDataValue('cnpj_receita', null)}
                                                    fileList={[]}
                                                    style={{ width: '100%' }}
                                                    accept='.pdf'
                                                >
                                                    <Button disabled={this.props.disabledFields}>
                                                        <Icon type="upload" /> Selecionar documento
                                                    </Button>
                                                </Upload>
                                            }
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row><br />
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            if (this.props.getValue('cod_cliente'))
                                                this.updateClientData(this.props.getValue('cod_cliente'))
                                            else this.saveClientData()
                                        }}
                                        loading={this.state.loadingSaveButton}
                                    >
                                        {this.props.getValue('cod_cliente') ? 'Atualizar' : 'Salvar'} dados do cliente
                                    </Button>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <div className='note-box'>
                                <div className='note-title'>Instruções:</div>
                                <div>{
                                    ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_cliente) || [])
                                        .map(paragraph => <p>{paragraph}</p>)
                                }</div>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="Contato da proposta">
                                <Input
                                    value={this.props.getValue('contact_prop')}
                                    onChange={event => this.props.updateDataValue('contact_prop', event.target.value)} placeholder="Contato da proposta" size="large" disabled={this.props.disabledFields} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Cargo">
                                <Input
                                    value={this.props.getValue('cargo_prop')}
                                    onChange={event => this.props.updateDataValue('cargo_prop', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Telefone">
                                <Input value={CreatePhoneMask(this.props.getValue('phone_prop'))} onChange={event => this.props.updateDataValue('phone_prop', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Celular">
                                <Input value={CreatePhoneMask(this.props.getValue('phone_cel_prop'))} onChange={event => this.props.updateDataValue('phone_cel_prop', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Whatsapp">
                                <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_prop'))} onChange={event => this.props.updateDataValue('phone_whatsapp_prop', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <
                                // @ts-ignore
                                Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_prop') || '')}>
                                <Input
                                    value={this.props.getValue('email_prop')}
                                    onChange={event => this.props.updateDataValue('email_prop', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <h2>Contato Comercial</h2>
                    <p>Preencha pelo menos um dos contatos</p>

                    {
                        Date.parse(this.props.getValue('createdAt')) > Date.UTC(2022, 5, 2) ? // Data da atualização da lógica dos campos de contatos
                            <ContactsFields
                                contactKey='client'
                                value={this.props.getValue('clientContacts')}
                                updateContacts={value => this.props.updateDataValue('clientContacts', value)}
                            />
                            :
                            <Fragment>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de suprimentos">
                                            <Input
                                                value={this.props.getValue('contact_supri')}
                                                onChange={event => this.props.updateDataValue('contact_supri', event.target.value)} placeholder="Contato de suprimentos" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_supri')}
                                                onChange={event => this.props.updateDataValue('cargo_supri', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_supri'))} onChange={event => this.props.updateDataValue('phone_supri', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_supri'))} onChange={event => this.props.updateDataValue('phone_cel_supri', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_supri'))} onChange={event => this.props.updateDataValue('phone_whatsapp_supri', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_supri') || '')}>
                                            <Input
                                                value={this.props.getValue('email_supri')}
                                                onChange={event => this.props.updateDataValue('email_supri', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de engenharia">
                                            <Input
                                                value={this.props.getValue('contact_eng')}
                                                onChange={event => this.props.updateDataValue('contact_eng', event.target.value)} placeholder="Contato de engenharia" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_eng')}
                                                onChange={event => this.props.updateDataValue('cargo_eng', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_eng'))} onChange={event => this.props.updateDataValue('phone_eng', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_eng'))} onChange={event => this.props.updateDataValue('phone_cel_eng', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_eng'))} onChange={event => this.props.updateDataValue('phone_whatsapp_eng', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_eng') || '')}>
                                            <Input
                                                value={this.props.getValue('email_eng')}
                                                onChange={event => this.props.updateDataValue('email_eng', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de gerência">
                                            <Input
                                                value={this.props.getValue('contact_management')}
                                                onChange={event => this.props.updateDataValue('contact_management', event.target.value)} placeholder="Contato de gerência" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_management')}
                                                onChange={event => this.props.updateDataValue('cargo_management', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_management'))} onChange={event => this.props.updateDataValue('phone_management', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_management'))} onChange={event => this.props.updateDataValue('phone_cel_management', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_management'))} onChange={event => this.props.updateDataValue('phone_whatsapp_management', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_management') || '')}>
                                            <Input
                                                value={this.props.getValue('email_management')}
                                                onChange={event => this.props.updateDataValue('email_management', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Fragment>
                    }
                </Spin>
            </div>
        )
    }

}