import React from 'react'
import { Row, Button, Divider, Input, Form as FormElement, Col, notification, Modal, Icon } from 'antd'
import Parse from 'parse';
import { getUnityFormule, getTotalFormule } from '../../../utils/unity'
import { getTotalCost, getMaterialCost } from '../../supplies/inputs/inputs'
import '../../styles/component_kit.css'
import { parseLocaleCurrencyToFloat } from '../../../components/module/form/Form'
import { getConvertionFormulas } from '../shared/component-mounting-instalation-functions';
const staticValuesSelect = {}

let inputPrices = {}
let teoricMassConfigs = [];
let convertionFormulas = []

const getProductionCost = (Form, module = FormComponentKit) => {
  return (Form.getNumberValue('size_hh') * getManHourValue(Form, Form.state.formRef.unity_value_hh)) + getTotalAllCompositions(Form, module)
}

const removeCompositionRow = (FormModule, blockCompositionIndex, rowCompositionIndex, moduleConfig) => {
  const compositionList = getDinamicsValues(FormModule.formRef, FormModule, moduleConfig)
  compositionList[blockCompositionIndex].rows.splice(rowCompositionIndex, 1);
  applyCompositionsChange(FormModule, moduleConfig, compositionList)
}

const removeCompositionBlock = (FormModule, blockCompositionIndex, moduleConfig) => {
  const compositionList = getDinamicsValues(FormModule.formRef, FormModule, moduleConfig)
  compositionList.splice(blockCompositionIndex, 1);
  applyCompositionsChange(FormModule, moduleConfig, compositionList)
}

const applyCompositionsChange = (FormModule, moduleConfig, compositionList) => {
  // this function will parse the database structure to formRef struct because formRef.
  let formRef = parseDataToSave(FormModule.formRef, FormModule);
  formRef.vinculated_component = (typeof formRef.vinculated_component !== "string") && undefined

  formRef = {
    ...formRef,
    ...compositionList.reduce((acc, value, index) => { // transforma o array em attr direto no formRef
      return {
        ...acc,
        [`description_${index}_${index}`]: value.description,
        ...value.rows.reduce((acc, v, index_row) => {
          let obj = {}
          for (let key in v) {
            obj[`${key}_${index}_${index_row}`] = v[key];
          }
          return { ...acc, ...obj }
        }, {})
      }
    }, {})
  }
  moduleConfig.data.component_description = compositionList.map((v) => {
    return {
      rows: v.rows.map(() => renderComponentDescriptionRow)
    }
  })
  FormModule.setState({ formRef, invalidForm: false })
}

const renderComponentDescriptionRow = {
  render: (Form, index, list, k, objectEdit, FormModule) => {
    // k is the blockCompositionIndex and index is the index row of block
    let spec_attr = 'specification_' + k + '_' + index
    if (!staticValuesSelect[spec_attr])
      staticValuesSelect[spec_attr] = {
        "title": "Especificação",
        "key": spec_attr,
        "type": "relation-old",
        "options": [],
        "relation-select": {
          "class": "Input",
          "label": (obj) => `${obj.code} - ${obj.material_description}`,
          "value": "objectId",
          "key": "objectId",
          searchFields: [{ type: 'text', key: 'code' }, { type: 'text', key: 'description' }]

        },
        "element-attr": { "required": true, mode: "default" }
      }
    let unit_attr = 'unity_' + k + '_' + index;
    if (!staticValuesSelect[unit_attr])
      staticValuesSelect[unit_attr] = { type: 'select', key: `${unit_attr}`, title: 'Unidade', options: [], optionConfig: { name: 'unidades' }, loaded: false }

    return (
      <Row gutter={20} key={'ref-row-' + index}>
        {Form.getElementByTypeSchema(staticValuesSelect[spec_attr], 4, objectEdit, 0)}
        {Form.getElementByTypeSchema({
          type: "render", key: 'show_spec' + index, render: (row, form, node) => {
            return (
              <div>
                <Button shape="circle" disabled={!form.state.formRef[spec_attr]} icon="search" size='large' onClick={() => {
                  let query = new Parse.Query(Parse.Object.extend(staticValuesSelect[spec_attr]["relation-select"].class))
                  query.equalTo('objectId', form.state.formRef[spec_attr])
                  query.first(null).then(response => {
                    // call modal to show data
                    let data = response.toJSON()
                    showModalInput(data);
                  })

                }} />
              </div>
            )
          }
        }, 1, null, 0)}
        {Form.getElementByTypeSchema(staticValuesSelect[unit_attr], 3, objectEdit, 0)}
        {Form.getElementByTypeSchema({ type: "number", title: 'Quantidade', key: 'amount_component_' + k + '_' + index, 'element-attr': { required: false } }, 2, objectEdit, 0)}
        {Form.getElementByTypeSchema({ type: "number", title: 'Largura', key: 'width_component_' + k + '_' + index, 'element-attr': { required: false } }, 2, objectEdit, 0)}
        {Form.getElementByTypeSchema({ type: "number", title: 'Espessura', key: 'thickness_component_' + k + '_' + index, 'element-attr': { required: false } }, 2, objectEdit, 0)}
        {Form.getElementByTypeSchema({ type: "number", title: 'Comprimento', key: 'length_component_' + k + '_' + index, 'element-attr': { required: false } }, 2, objectEdit, 0)}
        {/* {Form.getElementByTypeSchema({ type: "number", title: 'Conversão', key: 'convert_component_' + k + '_' + index, 'element-attr': {required: false} }, 2, objectEdit, 0)} */}
        {Form.getElementByTypeSchema({
          type: "render", title: 'Conversão', key: 'convert_component_' + k + '_' + index, render: () => {

            return (
              <div>
                <Input disabled ref={'convert_component_' + k + '_' + index} size="large" value={getConvert({ block: k, row: index }, FormModule)} />
              </div>
            )
          }
        }, 2, null, 0)}
        {Form.getElementByTypeSchema({
          type: "render", title: 'Total de Consumo', key: 'total_consumer_' + k + '_' + index, render: () => {

            return (
              <div>
                <Input disabled size="large" value={getTotalConsumption({ block: k, row: index }, FormModule).toFixed(5)} />
              </div>
            )
          }
        }, 2, null, 0)}
        {Form.getElementByTypeSchema({
          type: "render", title: 'Total', key: 'total_description_' + index, render: () => {
            return (
              <div>
                <Input disabled size="large" value={getTotalRow({ block: k, row: index }, FormModule).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
              </div>
            )
          }
        }, 3, null, 0)}
        <Col span={1}>
          <FormElement.Item label={<div>&nbsp;</div>}>
            <Button shape="circle" disabled={list.length === 1} icon="close" size='large' onClick={event => {
              Modal.confirm({
                title: 'Confirme para excluir esse item da composição',
                okType: 'danger',
                cancelText: 'Cancelar',
                okText: 'Excluir',
                onOk: () => {
                  // list.splice(index, 1);
                  removeCompositionRow(FormModule, k, index, FormComponentKit)
                  // remove values of row
                  // Form.forceUpdate()
                }
              })

            }} />
          </FormElement.Item>
        </Col>
      </Row>
    )
  }
}

const getTotalConsumption = ({ block, row }, FormModule) => {
  let values = getValuesToConsumption({ block, row }, FormModule)
  if (values.spec && values.unity) {
    let r = getUnityFormule(values.spec.get('measure'), values.unity, convertionFormulas)(values);
    return (r === Infinity) ? 0 : r;
  }

  return 0;
}

/**
 * Busca o valor da conversão do insumo selecionado para mostrar no input.
 * @param {*} param0 
 * @param {*} FormModule 
 */
const getConvert = ({ block, row }, FormModule) => {

  if (FormModule.getRelationObjectValue(`specification_${block}_${row}`) && FormModule.getRelationObjectValue(`specification_${block}_${row}`)['attributes']['convert'])
    return FormModule.getRelationObjectValue(`specification_${block}_${row}`)['attributes']['convert'].toFixed(2)

  return 1
}

const getTotalComposition = ({ composition, cIndex }, FormModule) => {
  return composition.rows.reduce((t, row, index) => {
    return t += getTotalRow({ block: cIndex, row: index }, FormModule)
  }, 0)
}

const getTotalAllCompositions = (FormModule, module = FormComponentKit) => {
  let r = module.data.component_description.reduce((a, v, index) => {
    return a += v.rows.reduce((ar, r, ir) => ar += getTotalRow({ block: index, row: ir }, FormModule), 0)
  }, 0)
  return r
}

const getTotalRow = ({ block, row }, FormModule) => {
  let values = getValuesToConsumption({ block, row }, FormModule)
  let spec = values.spec
  let unity = FormModule.getNodeValue(`unity_${block}_${row}`)
  if (spec) {
    values = { ...values, totalConsumo: getUnityFormule(spec.attributes.measure, unity, convertionFormulas)(values) }
    let r = getTotalFormule(spec.attributes.measure, unity)(values)
    return (r === Infinity) ? 0 : r
  }
  return 0
}

const getValuesToConsumption = ({ block, row }, FormModule) => {
  return {
    amount: (FormModule.getNumberValue(`amount_component_${block}_${row}`) || 0),
    width: (FormModule.getNumberValue(`width_component_${block}_${row}`) || 1),
    thickness: (FormModule.getNumberValue(`thickness_component_${block}_${row}`) || 1),
    length: (FormModule.getNumberValue(`length_component_${block}_${row}`) || 1),
    convert: (FormModule.getRelationObjectValue(`specification_${block}_${row}`) && FormModule.getRelationObjectValue(`specification_${block}_${row}`)['attributes']['convert']) || 1,
    totalCost: (FormModule.getRelationObjectValue(`specification_${block}_${row}`) && getTotalCost(FormModule.getRelationObjectValue(`specification_${block}_${row}`)['attributes'])) || 1,
    unity: FormModule.getNodeValue(`unity_${block}_${row}`),
    spec: FormModule.getRelationObjectValue(`specification_${block}_${row}`)
  }
}

const getValuesToConsumptionByRow = (row, specs) => {
  return {
    amount: row[`amount_component`] || 0,
    width: row[`width_component`] || 1,
    thickness: row[`thickness_component`] || 1,
    length: row[`length_component`] || 1,
    convert: (specs[row[`specification`]] && specs[row[`specification`]]['attributes']['convert']) || 1,
    unity: row[`unity`],
    spec: specs[row[`specification`]]
  }
}

const saveNewComponenteKit = async (data, FormModule) => {
  let node = FormModule.getNodesByKey('unity_value_hh')
  let Extension = new Parse.Object(FormModule.props.module.form.module)
  let PointerObject = new Parse.Object(node['relation-select'].class);
  PointerObject.id = data[node.key];
  Extension.set('unity_value_hh', PointerObject);
  data[node.key] = PointerObject
  data['updatedBy'] = Parse.User.current()

  // Check if component image has set
  // if (data.component_image) {
  //   let fileResponse = await data.component_image.save();
  //   Extension.set('component_image', fileResponse);
  //   delete data.component_image
  // }

  Extension.save(data).then(saved => FormModule.afterSave()).catch(err => {
    notification.error({
      message: err.message
    })
  })
}

const updateComponenteKit = (data, objectId, FormModule) => {
  let node = FormModule.getNodesByKey('unity_value_hh')
  let Query = new Parse.Query(FormModule.props.module.form.module)
  let PointerObject = new Parse.Object(node['relation-select'].class);
  PointerObject.id = data[node.key];

  data[node.key] = PointerObject
  Query.get(objectId).then(async ObjectParse => {
    ObjectParse.set('unity_value_hh', PointerObject);
    ObjectParse.set('updatedBy', Parse.User.current())

    // Remover vinculated_component se undefined
    if (!data.vinculated_component) {
      ObjectParse.unset('vinculated_component')
      ObjectParse.save()
      data['vinculated_component'] = null
    }

    // Check if component image has set
    // if (data.component_image) {
    //   let fileResponse = await data.component_image.save();
    //   ObjectParse.set('component_image', fileResponse);
    //   delete data.component_image
    // }

    ObjectParse.save(data, {}).then(r => {
      FormModule.finishEdit()
      FormComponentKit.dataToEdit = null;
    }).catch(err => {
      notification.error({
        message: err.message
      })
    })
  })
}

const getValues = (FormModule) => {
  let refs = FormModule.getRefWithFormItem(false)
  let obj = {}
  for (let item in refs) {
    let value = refs[item].props.value
    if (item === 'relation_component') {

    }
    obj[item] = value;
  }
  return obj;
}

const getDinamicsValues = (_, FormModule, FormComponentKit) => {
  let refs = FormModule.getRefWithFormItem(false)
  let values = Object.keys(getValues(FormModule)).filter(v => {
    return ((v.match(/[0-9]/g) || []).length === 2) ? v : false;
  }).reduce((a, v) => {
    return { ...a, [v]: refs[v].props.value }
  }, {})
  return FormComponentKit.data.component_description.map((v, index) => {
    return {
      "description": values["description_" + index + "_" + index],
      rows: v.rows.map((_r, index_row) => {
        let id = FormModule.state.formRef[`specification_${index}_${index_row}`]
        if (refs.hasOwnProperty(`specification_${index}_${index_row}`)) {
          let origin_measure = (refs[`specification_${index}_${index_row}`].props.node['_parseObjectOptionsList'] || []).filter(v => v.id === id)
          if (origin_measure.length > 0)
            origin_measure = origin_measure[0].get('measure')

          return {
            ...['specification', 'unity', 'amount_component', 'length_component', 'width_component', 'thickness_component', 'convert_component'].reduce((a, v) => {
              return { ...a, [v]: refs[`${v}_${index}_${index_row}`].props.value }
            }, {}),
            origin_measure
          }
        }

        return null;
      })
    }
  })
}

const parseDataToSave = (_, FormModule) => {
  // get ParseObject of vinculated_component
  let refs = FormModule.getRefWithFormItem(false)
  return Object.keys(getValues(FormModule)).filter(v => {
    return (v.match(/[0-9]|_slider|_input/g)) ? false : v;
  }).reduce((a, k) => {
    let value = refs[k].props.value
    return { ...a, [k]: value }
  }, {})
}
const valueCompositionByRow = (row, specs) => {
  let spec = specs[row.specification]
  if (spec) {
    let r = getTotalConsumptionByRow(row, specs) * (getTotalCost(spec.toJSON()))
    return (r === Infinity) ? 0 : r
  }
  return 0
}

const getTotalConsumptionByRow = (row, specs) => {
  if (specs[row.specification] && row.unity) {
    let r = getUnityFormule(specs[row.specification].get('measure'), row.unity, convertionFormulas)(getValuesToConsumptionByRow(row, specs));
    return (r === Infinity) ? 0 : r;
  }

  return 0;
}
const calculatePriceRow = (row, inputs) => {

  if (row) {
    if (row.hasOwnProperty('vinculated_component')) {
      return calculatePriceRow(row.vinculated_component, inputs) * (row.fact_multiple || 1)
    }
    if (Object.keys(inputs) === 0) return 0
    let allCompositions = (row.descriptions || []).reduce((a, d) => {
      return a += (d.rows || []).reduce((t, r) => {
        t += valueCompositionByRow(r, inputs)
        return t
      }, 0)
    }, 0)
    let total_hand = row.size_hh * (row.hasOwnProperty('unity_value_hh') ? row.unity_value_hh.value : 1)
    let total_c = (total_hand + allCompositions)
    let price_table = calcSpecialPercent(row.pis_conf + row.custo_comer + row.margin_contri, total_c, 0)
    return parseLocaleCurrencyToFloat(price_table)
  } else return 0

}
/**
 * Retorna os valores do preço vinculado e da margem vinculada
 * @param {*} form 
 * @param {String ('number' || 'percent')} type 
 */
const calcVinculatedMargin = (form, type, state) => {
  let objList = (form.getNodesByKey('vinculated_component')['_parseObjectOptionsList'] || []).filter(v => v.id === form.getNodeValue('vinculated_component'))
  if (!objList.length) return 0;
  //let comp = objList[0].toJSON()
  let marginApplied = form.parseLocaleCurrencyToFloat(priceIndustry(form, true))
  let value = (priceRelation(form, state) * (form.getNumberValue('fact_multiple') || 1)).toFixed(2)
  let percent = ((priceRelation(form, state) * (form.getNumberValue('fact_multiple') || 1) - marginApplied) * 100 / marginApplied).toFixed(2)
  return parseFloat((type === 'number') ? value : percent)
}

/*
const calcVinculatedMarginByRow = (row, type) => {
  if (!row.vinculated_component) return 0;
  let comp = row.vinculated_component
  let marginApplied = row.price_margin_applied
  let value = (comp.price_industry * (row.fact_multiple || 1))
  let percent = ((comp.price_industry * (row.fact_multiple || 1) - marginApplied) * 100 / marginApplied)
  return parseFloat((type === 'number') ? value : percent)
}
*/


/**
 * Retorna o valor do preço tabela fábrica e margem atribuída e verifica se existe componente vinculado
 * @param {*} form
 * @param {Boolean} margin // Para o margem atribuída deve-se passar true para não ser considerado o preço de componente vinculado
 */
const priceIndustry = (form, margin, module = FormComponentKit) => {
  let priceR = priceRelation(form, form.state)
  if (!margin && form.refs.price_relation && form.parseLocaleCurrencyToFloat(priceR) > 0) {
    return priceR
  } else if (form.refs.price_margin_applied && form.refs.price_margin_applied.input.value) {
    return calcSpecialPercent(form.getNumberValue('pis_conf') + form.getNumberValue('custo_comer') + form.getNumberValue('margin_contri'), form.parseLocaleCurrencyToFloat(getProductionCost(form, module)), 0)
  }
  return 0;
}

/**
 * Retorna o valor da porcentagem 'embutida'
 * @param {Float} percent // Valor da porcentagem a ser aplicada no valor do inputValue
 * @param {Float} inputValue // Valor de um determinado campo
 * @param {Integer} total // Caso seja pra mostrar o valor total da % embutida, basta passar 0 como total, se não vai retornar apenas a diferença da % do valor total.
 */
const calcSpecialPercent = (percent, inputValue, total = inputValue) => {
  return (inputValue / ((100 - percent) / 100) - total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

/**
 * Retorna o valor dos campo margem zero
 * @param {*} form 
 */
const calcMargin = (form) => {
  return (form.parseLocaleCurrencyToFloat(getProductionCost(form)) / 100 * form.getNumberValue('margin_contri')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

const getManHourValue = (Form, id) => {
  let f = (Form.getNodesByKey('unity_value_hh')._parseObjectOptionsList || []).filter((v) => v.id === id)
  return (f.length > 0) ? f[0].get("value") : 0
}

const priceRelation = (form, state) => {
  let value = 0
  if (form.getNodesByKey('vinculated_component')) {
    let search = (form.getNodesByKey('vinculated_component')._parseObjectOptionsList || []).filter(v => v.id === state.formRef.vinculated_component);
    if (search.length > 0) {
      value = calculatePriceRow(search[0].toJSON(), inputPrices) * (state.formRef.fact_multiple || 1)
    }
  }
  return value
}

const FormComponentKit = {
  fields: [
    [{
      title: "Tipo de Componente",
      key: "group",
      type: "select",
      options: [],
      optionConfig: { name: 'tipo_componente' },
      "element-attr": { "required": true, mode: 'default' }
    },
    { type: 'select', key: 'add_category', title: 'Categoria do Adicional', options: [], optionConfig: { name: 'componente_adicional' }, loaded: false },
    {
      "title": "Acabamento",
      "key": "finishing",
      "type": "relation-old",
      "options": [],
      "relation-select": {
        "class": "Finishing",
        "label": f => `${f.code} - ${f.description}`,
        "value": "objectId",
        "key": "objectId",
        orderBy: "code"
      },
      "element-attr": { "required": false, "mode": 'default' }
    },
    // {
    //   type: 'image',
    //   key: 'component_image',
    //   title: 'Imagem do componente',
    //   className: 'upload-image-user',
    //   'element-attr': { required: false }
    // },
    // { type: 'select', key: 'colors_pattern', title: 'Padrão de Cores', options: [], optionConfig: { name: 'padrao_cores' }, loaded: false, 'element-attr': { required: false, mode: 'multiple' } },
    {
      type: 'select', key: 'performance', title: 'Desempenho da Folha', options: [], optionConfig: { name: 'performance' }, loaded: false,
      'element-attr': {
        disabled: Form => {
          let disabled = ['Marco', 'Ferragem', 'Fechadura', 'Acabamento', 'Alizar'].includes(Form.state.formRef.group)
          if (disabled && Form.state.formRef.performance) Form.clearValue('performance')
          return disabled
        },
        required: Form => {
          return (Form.state.formRef.group === 'Folha')
        },

      }
    },
    {
      type: 'select', key: 'marco_code', title: 'Código Marco', options: [], optionConfig: { name: 'marco_code' }, loaded: false, 'element-attr': {
        disabled: Form => {
          let disabled = ['Folha', 'Ferragem', 'Fechadura', 'Acabamento', 'Alizar'].includes(Form.state.formRef.group)
          if (disabled && Form.state.formRef.marco_code) Form.clearValue('marco_code')
          return disabled
        },
        required: Form => {
          return (Form.state.formRef.group === 'Marco')
        },
      }
    },
    {
      type: 'select', key: 'select_ferragem', title: 'Tipo de Ferragem', options: [], optionConfig: { name: 'tipo_ferragem' }, loaded: false,
      'element-attr': {
        disabled: Form => {
          let disabled = ['Folha', 'Marco'].includes(Form.state.formRef.group)
          if (disabled && Form.state.formRef.select_ferragem) Form.clearValue('select_ferragem')
          return disabled
        },
        required: Form => {
          return (Form.state.formRef.group === 'Ferragem')
        },

      }
    },
    { type: 'number', key: 'fact_multiple', title: 'Fator Multiplicador', loaded: false, default: 1, 'element-attr': { required: false, disabled: true } },
    {
      "title": "Vincular a um Componente",
      "key": "vinculated_component",
      "type": "relation-search",
      "options": [],
      "relation-select": {
        "class": "ComponenteKit",
        "label": "product_code",
        "value": "objectId",
        "key": "objectId"
      },
      "element-attr": { "required": false, "mode": 'default' }
    },
    {
      "title": "Valor unitário HH",
      "key": "unity_value_hh",
      "type": "relation-old",
      "required": true,
      "options": [],
      "relation-select": {
        "class": "ManHour",
        "label": obj => obj.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        "value": "objectId",
        "key": "objectId"
      },
      loadFirstValue: true,
      "element-attr": { "required": false, "mode": 'default' }
    },]
  ],
  "submit": {
    "collection": "ComponenteKit"
  },
  "className": "componente-kit-module",
  "schema": [{
    title: 'Código',
    key: 'product_code',
    sorter: (a, b) => a.product_code.localeCompare(b.product_code),
    align: 'center',
    width: '20%',
    dataIndex: 'product_code',
    type: 'text'
  }, {
    title: 'Tipo',
    key: 'group',
    dataIndex: 'group',
    width: '6%',
    align: 'center',
    type: 'text'
  }, {
    title: 'Descrição',
    key: 'description_component',
    sorter: (a, b) => a.description_component.localeCompare(b.description_component),
    dataIndex: 'description_component',
    type: 'text'
  }, {
    title: 'HH',
    key: 'unity_value_hh',
    align: 'center',
    width: '7%',
    dataIndex: 'HH',
    type: 'text',
    render: (_, row) => `${(row.size_hh || 0).toFixed(2).replace('.', ',')}`
  }, {
    title: 'Margem',
    key: 'margin_contri',
    align: 'center',
    width: '5%',
    dataIndex: 'Margem',
    type: 'text',
    render: (_, row) => `${row.margin_contri || 0}%`
  }, {
    title: 'Preço',
    key: 'price_industry',
    dataIndex: 'price_industry',
    align: 'center',
    width: '9%',
    type: 'render',
    "relation-select": {
      key: ['unity_value_hh', 'vinculated_component', 'finishing']
    },
    render: (_, row) => {
      return calculatePriceRow(row, inputPrices).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  }],
  "title-module": "Componente Kit",
  "topBarAction": ({ module }) =>
    <Button
      style={{ float: 'right' }}
      onClick={() => updateInputValues()}
    >
      Atualizar preços de insumos
    </Button>,
  "title-navigation":
    <div>
      <Icon type="tool" /> Componente de Kit <span style={{ fontSize: 'small', fontWeight: 'lighter', marginLeft: '50px' }}>Atualização de preços: <span id='updateInputDateId'></span></span>
    </div>,
  "module": "ComponenteKit",
  "router-base": "/panel/componente-kit",
  data: {
    component_description: [{
      rows: [renderComponentDescriptionRow]
    }]
  },
  dataToEdit: null,
  FormComponent: (Form, Module) => {
    if (!FormComponentKit.dataToEdit && Form.props.objectEdit) { // load data to edit and format values to fill inputs.
      let ParseQuery = new Parse.Query(new Parse.Object.extend(Module.collection))
      ParseQuery.get(Form.props.objectEdit).then(response => {
        FormComponentKit.dataToEdit = response.toJSON()
        if (FormComponentKit.dataToEdit.vinculated_component)
          FormComponentKit.dataToEdit.vinculated_component = FormComponentKit.dataToEdit.vinculated_component.objectId

        FormComponentKit.dataToEdit = {
          ...FormComponentKit.dataToEdit,
          ...FormComponentKit.dataToEdit.descriptions.reduce((acc, value, index) => { // transforma o array em attr direto no formRef
            return {
              ...acc,
              [`description_${index}_${index}`]: value.description,
              ...value.rows.reduce((acc, v, index_row) => {
                let obj = {}
                for (let key in v) {
                  obj[`${key}_${index}_${index_row}`] = v[key];
                }
                return { ...acc, ...obj }
              }, {})
            }
          }, {})
        }
        FormComponentKit.data.component_description = FormComponentKit.dataToEdit.descriptions.map((v) => {
          return {
            rows: v.rows.map(() => renderComponentDescriptionRow)
          }
        })
        if (FormComponentKit.dataToEdit.unity_value_hh)
          FormComponentKit.dataToEdit.unity_value_hh = FormComponentKit.dataToEdit.unity_value_hh.objectId

        if (FormComponentKit.dataToEdit.finishing)
          FormComponentKit.dataToEdit.finishing = FormComponentKit.dataToEdit.finishing.objectId
        Form.setState(() => {
          return {
            formRef: FormComponentKit.dataToEdit
          }
        })
      })
      // return false;
    }

    let nodeSelectAdd = Form.getNodesByKey('add_category')
    //let nodePerformanceAdd = Form.getNodesByKey('performance_add')
    let nodeFactMultiple = Form.getNodesByKey('fact_multiple')
    let swtichValue = !Form.getNodeValue('is_add');
    let vinculatedValue = !Form.getNodeValue('vinculated_component');

    nodeSelectAdd['element-attr'] = { ...nodeSelectAdd['element-attr'] || {}, disabled: swtichValue, required: !swtichValue };
    // nodePerformanceAdd['element-attr'] = { ...nodePerformanceAdd['element-attr'] || {}, disabled: swtichValue, required: !swtichValue, mode: 'multiple' };
    nodeFactMultiple['element-attr'] = { ...nodeFactMultiple['element-attr'] || {}, disabled: vinculatedValue, required: !vinculatedValue }
    const teoricMassName = function (group) {
      switch (group) {
        case 'Folha':
          return 'Massa Teórica (Kg/m²)'
        case 'Marco':
        case 'Alizar':
          return 'Massa Teórica (Kg/m)'
        default:
          return 'Massa Teórica'
      }
    }(Form.getNodeValue('group'));

    const teoricWeightCalc = function (group) {
      switch (group) {
        case 'Marco':
        case 'Alizar':
          return (2 * Form.getNumberValue('height_component') + Form.getNumberValue('width_component')) / 1000 * Form.getNumberValue('teoric_mass')
        default:
          return ((Form.getNumberValue('height_component') / 1000) * (Form.getNumberValue('width_component') / 1000) * Form.getNumberValue('teoric_mass'))
      }
    }(Form.getNodeValue('group'));

    const priceMarginZero = Form.parseLocaleCurrencyToFloat(calcSpecialPercent(Form.getNumberValue('pis_conf') + Form.getNumberValue('custo_comer'), Form.parseLocaleCurrencyToFloat(getProductionCost(Form)), 0))

    return (
      <div>
        <Row key='row-1' gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({ title: "Cadastro do Componente", key: "header-information", type: "header", className: "headers" })}
          {Form.getElementByTypeSchema(Form.getNodesByKey('group'), 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "text", title: 'Descrição do Componente', key: 'description_component' }, 13, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "text", title: 'Código do Produto', key: 'product_code', max: 20, valid: { check: (form) => (form.getNodeValue('product_code') || '').length <= 20 } }, 6, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-2" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema(Form.getNodesByKey('marco_code'), 4, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('performance'), 4, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('finishing'), 7, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "number", title: 'Altura (mm)', key: 'height_component', required: Form => (['Folha', 'Marco', 'Alizar'].includes(Form.state.formRef.group) && !Form.state.formRef.is_add), 'element-attr': { disabled: Form.state.formRef.is_add } }, 3, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "number", title: 'Largura (mm)', key: 'width_component', required: Form => (['Folha', 'Marco', 'Alizar'].includes(Form.state.formRef.group) && !Form.state.formRef.is_add), 'element-attr': { disabled: Form.state.formRef.is_add } }, 3, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "number", title: 'Espessura (mm)', key: 'fill_componente', required: Form => (['Folha', 'Marco', 'Alizar'].includes(Form.state.formRef.group) && !Form.state.formRef.is_add), 'element-attr': { disabled: Form.state.formRef.is_add } }, 3, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-4" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({
            type: "render", key: 'volume', render: (_, form, node) => {
              return <Input disabled size="large" ref="volume" value={((form.getNumberValue('height_component') / 1000) * (form.getNumberValue('width_component') / 1000) * (form.getNumberValue('fill_componente') / 1000)).toFixed(4)} placeholder="Volume Embalagem (m³)" node={node} />
            }, title: 'Volume Embalagem (m³)'
          }, 4, FormComponentKit.dataToEdit, 0)}

          {Form.getElementByTypeSchema({
            type: "render", key: 'teoric_mass', render: (_, form, node) => {
              let value =
                Form.state.formRef.group ?
                  teoricMassConfigs.find(element => {
                    // Folha
                    if (Form.state.formRef.group === 'Folha') {
                      return (
                        (
                          (Form.state.formRef.product_code || '').includes(element.folha_code_s) ||
                          (Form.state.formRef.product_code || '').includes(element.folha_code_u)
                        ) &&
                        (element.performance.includes(Form.state.formRef.performance || ''))
                      )
                    } else if (Form.state.formRef.group === 'Marco') {
                      return (
                        (
                          ((element.marco_code_s || []).find(el => (Form.state.formRef.product_code || '').includes(el)) ? true : false) ||
                          ((element.marco_code_u || []).find(el => (Form.state.formRef.product_code || '').includes(el)) ? true : false)
                        ) &&
                        // ((Form.state.formRef.marco_code || '') === element.marco_code) &&
                        ((Form.state.formRef.fill_componente || '') === element.marco_espessura)
                        // && (element.performance.includes(Form.state.formRef.performance || ''))
                      )
                    } else if (Form.state.formRef.group === 'Alizar') {
                      return (
                        ((element.alizar_code_product || []).find(el => (Form.state.formRef.product_code || '').includes(el)) ? true : false)
                        // && ((Form.state.formRef.fill_componente || '') === element.alizar_espessura)
                        // && (element.performance.includes(Form.state.formRef.performance || ''))
                      )
                    } else {
                      return false;
                    }
                  })
                  : { teoric_mass: 0 };
              return (
                <Input
                  disabled size="large" ref="teoric_mass"
                  value={value && value.teoric_mass}
                  placeholder={teoricMassName} node={node}
                />
              );
            }, title: teoricMassName
          }, 4, FormComponentKit.dataToEdit, 0)}

          {/* {Form.getElementByTypeSchema({ type: "number", title: teoricMassName, key: 'teoric_mass', 'element-attr': { disabled: Form.state.formRef.is_add, required: !Form.state.formRef.is_add } }, 4, FormComponentKit.dataToEdit, 0)} */}
          {Form.getElementByTypeSchema({
            type: "render", key: 'teoric_weight', render: (_, form, node) => {
              return <Input disabled size="large" ref="teoric_weight" value={teoricWeightCalc.toFixed(2)} placeholder="Peso Teórico (Kg)" node={node} />
            }, title: 'Peso teórico (Kg)'
          }, 4, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-3" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({ type: 'switch', key: 'is_add', title: 'Componente é Adicional ?', 'element-attr': { required: false } }, 4, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('add_category'), 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "number", title: 'Peso (Em Kg)', key: 'weight_add', 'element-attr': { disabled: !Form.state.formRef.is_add, required: Form.state.formRef.is_add } }, 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "number", title: 'Altura (mm)', key: 'height_add', 'element-attr': { disabled: !Form.state.formRef.is_add, required: false } }, 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: 'tag-list', key: 'width_add', title: 'Larguras (mm)', 'element-attr': { type: 'number', disabled: !Form.state.formRef.is_add, required: false } }, 5, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-5" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({ key: "space3", type: "white-space" }, 20)}
        </Row>

        <Row key="row-6" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({ title: "Composição de Insumos", key: "header-information", type: "header", className: "headers ant-col-5" })}
          {Form.getElementByTypeSchema({
            type: "render", key: 'btn_plus', render: (_, form, node) => {
              return <Button shape="circle" icon="plus" className="button-plus" size="large" node={node} onClick={event => {
                FormComponentKit.data.component_description.push({
                  rows: [renderComponentDescriptionRow]
                })
                Form.forceUpdate()
              }} />
            }
          }, 1, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-7" gutter={20} className="row-form-item">
          {FormComponentKit.data.component_description.map((cd, k, l) => {
            return (
              <div key={k}>
                <Row gutter={20} key={k}>
                  {Form.getElementByTypeSchema({ type: "text", title: 'Descrição da Composição', key: 'description_' + k + '_' + k }, 5, FormComponentKit.dataToEdit, 0)}
                  <Col span={1}>
                    <FormElement.Item label={<div>&nbsp;</div>}>
                      <Button shape="circle" disabled={l.length === 1} icon="close" size='large' onClick={event => {
                        Modal.confirm({
                          title: 'Confirme para excluir essa composição',
                          okType: 'danger',
                          cancelText: 'Cancelar',
                          okText: 'Excluir',
                          onOk: () => {
                            removeCompositionBlock(Form, k, FormComponentKit)
                            // l.splice(k, 1);
                            // Form.forceUpdate()
                          }
                        })
                      }} />
                    </FormElement.Item>
                  </Col>
                  {Form.getElementByTypeSchema({
                    type: "render", key: 'composition-price', render: (_, form, node) => {
                      return <Input disabled size="large" placeholder="Preço Total da composição" node={node} value={getTotalComposition({ composition: cd, cIndex: k }, Form).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                    }, title: 'Preço Total da composição'
                  }, 6, null, 0)}
                </Row>
                <Row gutter={20}>
                  {cd.rows.map((r, v, list) => r.render(Form, v, list, k, FormComponentKit.dataToEdit, Form))}
                </Row>
                <Divider>
                  <Button shape="circle" icon="plus" size='large' onClick={event => {
                    FormComponentKit.data.component_description[k].rows.push({ ...renderComponentDescriptionRow })
                    Form.forceUpdate()
                  }} />
                </Divider>
              </div>
            )
          })}
        </Row>
        <Row key="row-8" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({ title: "Composição de Preços", key: "header-information", type: "header", className: "headers" })}
          {Form.getElementByTypeSchema({
            type: "render", key: 'total_primary_mat', render: (_, form, node) => {
              return <Input disabled size="large" value={getTotalAllCompositions(form).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} placeholder="Total de Matéria prima" node={node} />
            }, title: 'Total de matéria prima'
          }, 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ type: "number", title: 'Quantidade HH', key: 'size_hh' }, 3, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('unity_value_hh'), 3, FormComponentKit.dataToEdit, 0)}
          {/* {Form.getElementByTypeSchema({ type: "number", title: 'Valor unitário HH', key: 'unity_value_hh' }, 3, FormComponentKit.dataToEdit, 0)} */}
          {Form.getElementByTypeSchema({
            type: "render", key: 'total_hand', render: (_, form, node) => {
              return <Input disabled size="large" placeholder="Total mão de obra" node={node} value={(form.getNumberValue('size_hh') * getManHourValue(Form, Form.state.formRef.unity_value_hh)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
            }, title: 'Total mão de obra'
          }, 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ key: "space4", type: "white-space" }, 1)}
          {Form.getElementByTypeSchema({
            type: "render", key: 'production_cost', render: (_, form, node) => {
              return <Input ref="production_cost" disabled size="large" value={(getProductionCost(form)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} placeholder="Custo de produção" node={node} />
            }, title: 'Custo de produção'
          }, 5, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-9" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({
            type: "input-slide",
            title: 'ICMS (18%) + Federal (6,29%)',
            key: 'pis_conf',
            config: {
              show: {
                style: {
                  display: 'none'
                }
              }
            },
            'element-attr': { required: false },
            arrange: (v, s, form) => {
              return calcSpecialPercent(v, form.parseLocaleCurrencyToFloat(getProductionCost(form)))
            }
          }, 10, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({ key: "space5", type: "white-space" }, 4)}
          {Form.getElementByTypeSchema({
            type: "input-slide", title: 'Custo Fixo + Comercial', key: 'custo_comer', 'element-attr': { required: false },
            config: {
              show: {
                style: {
                  display: 'none'
                }
              }
            }, arrange: (v, s, form) => {
              return calcSpecialPercent(v, form.parseLocaleCurrencyToFloat(getProductionCost(form)))
            }
          }, 10, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-10" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({
            type: "input-slide",
            config: {
              show: {
                style: {
                  display: 'none'
                }
              }
            }, title: 'Margem de Contribuição', key: 'margin_contri', 'element-attr': { required: false }, arrange: (v, s, form) => {
              return calcMargin(form)
            }
          }, 10, null, 0)}
          {Form.getElementByTypeSchema({ key: "space6", type: "white-space" }, 4)}
          {Form.getElementByTypeSchema({
            type: "render", key: 'price_margin_zero', render: (_, form, node) => {
              return (
                <div>
                  <Input disabled size="large" ref="price_margin_zero" value={priceMarginZero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} placeholder="Preço Margem Zero" node={node} />
                  <div>ICMS (18%) + Federal (6,29%): {(priceMarginZero * (Form.getNumberValue('pis_conf') / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                  <div>Custo Fixo + Comercial: {(priceMarginZero * (Form.getNumberValue('custo_comer') / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                </div>
              )
            }, title: 'Preço Margem Zero'
          }, 5, FormComponentKit.dataToEdit, 0)}
          {Form.getElementByTypeSchema({
            type: "render", key: 'price_margin_applied', render: (_, form, node) => {
              return (
                <div>
                  <Input disabled ref="price_margin_applied" size="large" value={priceIndustry(form, true)} placeholder="Preço Margem Atribuída" node={node} />
                  <div>ICMS (18%) + Federal (6,29%): {(form.parseLocaleCurrencyToFloat(priceIndustry(form, true)) * (Form.getNumberValue('pis_conf') / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                  <div>Custo Fixo + Comercial: {(form.parseLocaleCurrencyToFloat(priceIndustry(form, true)) * (Form.getNumberValue('custo_comer') / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                  <div>Margem de Contribuição: {(form.parseLocaleCurrencyToFloat(priceIndustry(form, true)) * (Form.getNumberValue('margin_contri') / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                </div>
              )
            }, title: 'Preço Margem Atribuída'
          }, 5, FormComponentKit.dataToEdit, 0)}
        </Row>
        <Row key="row-11" gutter={20} className="row-form-item">
          {Form.getElementByTypeSchema({ title: "Precificação Final", key: "header-information", type: "header", className: "headers" })}
          {Form.getElementByTypeSchema(Form.getNodesByKey('vinculated_component'), 6, null, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('fact_multiple'), 3, null, 0)}
          {Form.getElementByTypeSchema({
            type: "render", key: 'margin-relation', render: (state, form, node) => {
              return <Input disabled size="large" placeholder="Margem Vinculada" node={node} value={`${calcVinculatedMargin(form, 'percent', state)}%`} />
            }, title: 'Margem Vinculada'
          }, 3, null, 0)}
          {Form.getElementByTypeSchema({
            'element-attr': { required: false },
            type: "render", key: 'price_relation', render: (state, form, node) => {
              return <Input disabled size="large" ref="price_relation" placeholder="Preço Vinculado" node={node}
                value={priceRelation(form, state).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              />
            }, title: 'Preço Vinculado'
          }, 5, null, 0)}
          {Form.getElementByTypeSchema({ key: "space7", type: "white-space" }, 1)}
          {Form.getElementByTypeSchema({
            type: "render", key: 'price_industry', render: (state, form, node) => {
              return <Input disabled size="large" ref="price_industry" placeholder="Preço Tabela Fábrica" node={node} value={(state.formRef.vinculated_component) ? priceRelation(form, state).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : priceIndustry(form)} />
            }, title: 'Preço Tabela Fábrica'
          }, 5, null, 0)}
        </Row>
      </div>
    )
  },
  onClickActionEdit: row => {
    FormComponentKit.dataToEdit = null;
  },
  ParseEditableObject: ComponentKitObject => {
    return ComponentKitObject;
  },
  SubmitForm: (FormRef, ObjectEdit, ObjectId, FormModule) => {
    let objectWithValues = parseDataToSave(FormRef, FormModule)
    let dinamicsValues = getDinamicsValues(FormRef, FormModule, FormComponentKit)
    objectWithValues = { ...objectWithValues, descriptions: dinamicsValues, width_add: FormRef.width_add }

    objectWithValues.price_margin_applied = FormModule.parseLocaleCurrencyToFloat(objectWithValues.price_margin_applied)
    objectWithValues.price_margin_zero = FormModule.parseLocaleCurrencyToFloat(objectWithValues.price_margin_zero)
    objectWithValues.price_industry = FormModule.parseLocaleCurrencyToFloat(objectWithValues.price_industry)
    objectWithValues.price_relation = FormModule.parseLocaleCurrencyToFloat(objectWithValues.price_relation)
    objectWithValues.production_cost = FormModule.parseLocaleCurrencyToFloat(objectWithValues.production_cost)
    objectWithValues.volume = parseFloat(objectWithValues.volume)
    objectWithValues.teoric_weight = parseFloat(objectWithValues.teoric_weight)
    objectWithValues.weight_add = parseFloat(objectWithValues.weight_add)

    let refs = FormModule.getRefWithFormItem(false);
    let invalid = false;
    for (let key in refs) {
      // checking if exist empty required inputs.
      if (!FormModule.nodeIsValid(FormModule.refs[key].props.node) || (!FormModule.nodeHasEmptyValue(FormModule.refs[key].props.value || FormModule.state.formRef[key]) && FormModule.nodeIsRequired(FormModule.refs[key].props.node))) {
        notification.error({ message: FormModule.refs[key].props.node.errorMessage || `Valor inválido no campo: ${FormModule.refs[key].props.node.title}`, duration: 5 })
        invalid = true;
        break;
      }
    }
    // set vinculated component on withValues
    const vcNode = FormModule.getNodesByKey('vinculated_component')

    objectWithValues.vinculated_component = vcNode['_parseObjectOptionsList'] && FormModule.getNodesByKey('vinculated_component')['_parseObjectOptionsList'].find(v => v.id === FormRef.vinculated_component)
    objectWithValues.finishing = new Parse.Object('Finishing', { objectId: objectWithValues.finishing })
    if (invalid) return false;
    if (ObjectEdit) return updateComponenteKit(objectWithValues, ObjectId, FormModule);

    return saveNewComponenteKit(objectWithValues, FormModule)
  },
  ListWillMount: async (_) => {
    loadInputs(prices => {
      inputPrices = prices;
      this.forceUpdate()
    })

    convertionFormulas = await getConvertionFormulas()
    _.forceUpdate()
  },
  FormWillMount: () => {
    loadInputs(prices => inputPrices = prices);
    loadTeoricMass(res => teoricMassConfigs = res);
  }
}
const loadInputs = (callback = function () { }) => {
  let q = new Parse.Query('Input')
  q.limit(1000)
  q.include('unity_value_hh')
  q.find({
    success: res => {
      document.getElementById('updateInputDateId').innerHTML = '';
      let inputPrices = res.reduce((a, v) => {
        if (v.get('input_destiny') === 'ind_mat' && v.get('is_price_base')) {
          updateInputDate = v.get('updated_data')[0]['date_of_update_data'];
        }

        a[v.id] = v
        return a
      }, {});
      document.getElementById('updateInputDateId').innerHTML = updateInputDate.toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' });
      callback(inputPrices)
    }
  })
}

const loadTeoricMass = (callback = function () { }) => {
  let q = new Parse.Query('TeoricMassConfigs')
  q.limit(1000)
  q.find({
    success: res => {
      let teoricMass = res.map(el => el.toJSON());
      callback(teoricMass);
    }
  });
}

const showModalInput = data => {
  Modal.info({
    width: '50%',
    title: (
      <div><h2>Detalhes do insumo - Cod. {data.code}</h2></div>
    ),
    className: 'remove-margin',
    content: (
      <div>
        <FormElement className="modal-insumo">
          <Row type="flex" justify="space-between">
            <Col span={16}>
              <FormElement.Item label="Descrição">
                <Input disabled={true} size="large" value={data.material_description} />
              </FormElement.Item>
            </Col>
            <Col span={7}>
              <FormElement.Item label="Fornecedor">
                <Input disabled={true} size="large" value={data.provider} />
              </FormElement.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={4}>
              <FormElement.Item label="Unidade Compra">
                <Input disabled={true} size="large" value={data.measure} />
              </FormElement.Item>
            </Col>
            <Col span={4}>
              <FormElement.Item label="Unidade Consumo">
                <Input disabled={true} size="large" value={data.consumption_measure} />
              </FormElement.Item>
            </Col>
            <Col span={4}>
              <FormElement.Item label="Conversão">
                <Input disabled={true} size="large" value={data.convert || 0} />
              </FormElement.Item>
            </Col>
            <Col span={5}>
              <FormElement.Item label="Custo de aquisição">
                <Input disabled={true} size="large" value={getMaterialCost(data).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
              </FormElement.Item>
            </Col>
            <Col span={5}>
              <FormElement.Item label="Preço final">
                <Input disabled={true} size="large" value={getTotalCost(data).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
              </FormElement.Item>
            </Col>
          </Row>
        </FormElement>
      </div>
    )
  })
}

let updateInputDate = '';
const updateInputValues = () => {
  Modal.confirm({
    title: 'Tem certeza que deseja atualizar os preços?',
    content: 'Essa ação pode demorar um pouco e não poderá ser desfeita.',
    cancelText: "Cancelar",
    onOk() {
      let Input = new Parse.Query('Input').limit(1000);

      return Input.find()
        .then(inputs => {
          let date = new Date();
          inputs.forEach(async (input, i) => {
            if (input.get('input_destiny') === 'ind_mat' && input.get('is_price_base')) {
              let input_json = input.toJSON();
              input_json.date_of_update_data = date;
              input.set('updated_data', [input_json]);
            }
          });

          return Parse.Object.saveAll(inputs).then(() => window.location.reload());
        })
        .catch(e => console.log(e));
    },
  });
}

export default FormComponentKit;

export {
  calcVinculatedMargin,
  calcSpecialPercent,
  priceIndustry,
  calcMargin,
  getDinamicsValues,
  parseDataToSave,
  getValues,
  updateComponenteKit,
  saveNewComponenteKit,
  getTotalConsumption,
  getTotalRow,
  getTotalComposition,
  getTotalAllCompositions,
  getValuesToConsumption,
  getManHourValue,
  getProductionCost,
  getConvert,
  inputPrices,
  calculatePriceRow,
  loadInputs,
  priceRelation,
  removeCompositionBlock,
  removeCompositionRow,
  applyCompositionsChange,
  showModalInput
}
