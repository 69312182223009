import React from 'react'

const providerContactFields = (props) => {
    return [
        [
            {
                'title': 'Contato de compra',
                'key': 'purchase_contact',
                'type': 'select',
                'options': [
                    { label: 'Selecione', value: null },
                    { label: 'Vendedor', value: 'salesman' },
                    { label: 'Representante', value: 'representative' },
                    { label: 'Supervisor', value: 'supervisor' },
                    { label: 'Financeiro', value: 'financial' },
                    { label: 'Qualidade', value: 'quality' },
                    { label: 'Gerente de vendas', value: 'manager' },
                    { label: 'Diretor', value: 'director' },
                    { label: 'Logística', value: 'logistics' },
                ],
                'colSize': 6,
                'element-attr': { mode: 'default' }
            }
        ],
        [
            {
                "title": "Cargo",
                "key": "func_salesman_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Vendedor</div>,
            },
            {
                "title": "Nome",
                "key": "func_salesman_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_salesman_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_salesman_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_salesman_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_salesman_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_representative_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Representante</div>,
            },
            {
                "title": "Nome",
                "key": "func_representative_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_representative_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_representative_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_representative_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_representative_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_supervisor_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Supervisor</div>,
            },
            {
                "title": "Nome",
                "key": "func_supervisor_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_supervisor_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_supervisor_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_supervisor_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_supervisor_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_financial_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Financeiro</div>,
            },
            {
                "title": "Nome",
                "key": "func_financial_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_financial_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_financial_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_financial_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_financial_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_quality_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Qualidade</div>,
            },
            {
                "title": "Nome",
                "key": "func_quality_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_quality_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_quality_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_quality_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_quality_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_manager_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Gerente de vendas</div>,
            },
            {
                "title": "Nome",
                "key": "func_manager_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_manager_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_manager_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_manager_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_manager_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_director_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Diretor</div>,
            },
            {
                "title": "Nome",
                "key": "func_director_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_director_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_director_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_director_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_director_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Cargo",
                "key": "func_logistics_office",
                "type": "render",
                "element-attr": { required: false },
                render: () => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>Logística</div>,
            },
            {
                "title": "Nome",
                "key": "func_logistics_name",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "func_logistics_city",
                "type": "text",
                "element-attr": { required: false }
            },
            {
                "title": "Telefone",
                "key": "func_logistics_phone",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "Celular",
                "key": "func_logistics_mobile",
                "type": "text",
                "element-attr": { required: false },
                "maskToValue": { phoneMask: true }
            },
            {
                "title": "E-mail",
                "key": "func_logistics_email",
                "type": "email",
                "element-attr": { required: false }
            },
        ],
    ]
}

export default providerContactFields