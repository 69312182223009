import React from 'react'
import { Icon } from 'antd'
import PriceListForm from './components/price_list_form';
import Parse from 'parse';

var salesChannel = [];
var instalations = [];

export default {
  "submit": {
    "collection": "PriceList"
  },
  ListWillMount: async (module) => {
    let salesChannelQuery = new Parse.Query('SalesChannel').limit(1000);
    salesChannel = await salesChannelQuery.find();

    let instalationQuery = new Parse.Query('Instalation');
    instalations = await instalationQuery.find();

    module.loadTableContent();
  },
  "schema": [
    {
      title: 'Canal de Vendas',
      key: 'sales_channel_inside_form',
      dataIndex: 'sales_channel_inside_form',
      width: '200px',
      type: 'render',
      render: (saleChannelId) => {
        let saleChannel = salesChannel.find(saleChannel => saleChannelId === saleChannel.id);
        return saleChannel && saleChannel.get('name');
      }
    },
    {
      title: 'Desempenho',
      key: 'performance',
      dataIndex: 'performance',
      type: 'text',
    },
    {
      title: 'Altura',
      key: 'height',
      dataIndex: 'height',
      width: '60px',
      type: 'text',
    },
    {
      title: 'Modelo',
      key: 'model',
      dataIndex: 'model',
      width: '100px',
      type: 'text',
    },
    {
      title: 'Tipologia',
      key: 'typology',
      dataIndex: 'typology',
      type: 'text',
    },
    {
      title: 'Acabamento',
      key: 'finishing',
      dataIndex: 'finishing',
      type: 'text',
    },
    {
      title: 'Fechadura',
      key: 'door_lock',
      dataIndex: 'door_lock',
      type: 'text',
    },
    {
      title: 'Instalação',
      key: 'instalation',
      dataIndex: 'instalation',
      type: 'render',
      render: (instalation) => {
        let instalationFound = instalations.find(el => el.id === instalation.objectId);
        if (instalationFound) {
          if (instalationFound.get('name').toLowerCase().includes('multidoor')) return 'Com instalação';
          else return 'Sem instalação';
        }
        return '';
      }
    },
  ],
  "title-module": "Tabela de Preços",
  "title-navigation": <div><Icon type="table" /> Tabela de Preços</div>,
  "module": "PriceList",
  "router-base": "/panel/tabela-precos",
  EntireFormComponent: (Module, routerProps) => {
    return (
      <div>
        <PriceListForm module={Module} routerProps={routerProps} />
      </div>
    )
  },
}