import { Col, Form, Modal, notification, Row, Select } from "antd";
import { setAlizFinishingEqualMarco } from "../../../suport-files/proposal-functions";
import React, { Fragment } from "react"
import { defaultMarcoMaterials } from "../../../../../../utils/defaultDataSource.js"

const productStepMarco = ({
    blockIndex,
    index,
    getProductValue,
    updateProductData,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    filterComponentBySheetFinishing,
    _this,
}) => {

    const getMarcoThicknessInfo = (id, attr) => {
        if (!id) return ''

        const marcoThickness = (_this.state.marcoThicknessMatriz || []).find(el => el.id === id)

        if (!marcoThickness) return ''

        return marcoThickness.get(attr)
    }

    const checkAndGetMarcoComponent = (id) => {
        const thickness = (_this.state.marcoThicknessMatriz || []).find(el => el.id === id)
        if (!thickness) return null

        const marcoComponent = (_this.state.kitsComponents['comp_marco'] || []).find(el => el.get('fill_componente').toString() === thickness.get('marco_thickness').toString())
        return marcoComponent && marcoComponent.id
    }

    const setMarcoThickness = (wallThicknessId) => {

        const thicknessMarco = checkAndGetMarcoComponent(wallThicknessId)

        if (wallThicknessId && !thicknessMarco) {
            notification.error({
                message: 'Marco inexistente',
                description: 'Contacte o gestor do sistema para cadastro dessa dimensão de marco na Ficha Técnica',
                duration: 5
            })
        }

        updateProductData(
            'thickness_marco',
            index,
            thicknessMarco || null,
        )
        _this.updateProductData('finishing_marco', blockIndex, index, null)
        _this.updateProductData('pattern_marco', blockIndex, index, null)
    }

    const setNullToArrayOfFields = (array, prodIndex) => {
        for (let fieldIndex = 0; fieldIndex < array.length; fieldIndex++) {
            const field = array[fieldIndex];
            updateProductData(field, prodIndex, null)
        }
    }

    const checkIfUserChooseMarcoAndAlizar = () => {
        let hasAlreadyChoosed = false;
        [
            'wall_thickness',
            'finishing_marco', 'marco_option_finishing', 'pattern_marco',
            'finishing_marco_passagem', 'marco_passagem_option_finishing', 'pattern_marco_passagem',
            'fixed_aliz', 'finishing_aliz', 'fixed_aliz_option_finishing', 'pattern_aliz',
            'adjustable_aliz', 'finishing_aliz_adjustable', 'adjustable_aliz_option_finishing', 'pattern_aliz_adjustable',
        ].forEach(field => {
            if (getProductValue(field)) hasAlreadyChoosed = true
        })

        return hasAlreadyChoosed;
    }

    return (
        <Fragment>
            <h2>Cadastro de Marco</h2>

            <Row gutter={12} className="chunk">
                <Row gutter={24}>
                    <Col span={8}>

                        <Form.Item label="Material">
                            <Select
                                value={getProductValue('marco_material')}
                                onChange={value => {
                                    if (checkIfUserChooseMarcoAndAlizar()) {
                                        Modal.confirm({
                                            title: 'Alterar material do marco',
                                            content: 'Essa alteração apagará os marcos e alizares já selecionados. Deseja continuar?',
                                            okText: 'Sim',
                                            cancelText: 'Cancelar',
                                            onOk() {
                                                updateProductData('marco_material', index, value)
                                                setNullToArrayOfFields(
                                                    [
                                                        'wall_thickness',
                                                        'finishing_marco', 'marco_option_finishing', 'pattern_marco',
                                                        'finishing_marco_passagem', 'marco_passagem_option_finishing', 'pattern_marco_passagem',
                                                        'fixed_aliz', 'finishing_aliz', 'fixed_aliz_option_finishing', 'pattern_aliz',
                                                        'adjustable_aliz', 'finishing_aliz_adjustable', 'adjustable_aliz_option_finishing', 'pattern_aliz_adjustable',
                                                    ],
                                                    index
                                                )

                                                // Disabled alizar options if not wood material
                                                if (value !== 'Madeira') {
                                                    setNullToArrayOfFields([
                                                        'aliz_options', 'aliz_options_01',
                                                        'aliz_fixed_section', 'aliz_adjustable_section',
                                                    ], index)
                                                }
                                            },
                                        })
                                    } else updateProductData('marco_material', index, value)
                                }}
                                placeholder="Material" size="large"
                                disabled={_this.props.disabledFields}
                            >

                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    defaultMarcoMaterials
                                        .map(el => {
                                            return (
                                                <Select.Option value={el.value}>{el.label}</Select.Option>
                                            );
                                        })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Espessura Parede" required>
                            <Select
                                value={getProductValue('wall_thickness')}
                                onChange={value =>
                                    updateProductData(
                                        'wall_thickness',
                                        index,
                                        value,
                                        setMarcoThickness(value)
                                    )}
                                placeholder="Espessura Parede" size="large"
                                disabled={_this.props.disabledFields}
                            >

                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    (_this.state.marcoThicknessMatriz || [])
                                        .map(wallThickness => wallThickness.toJSON())
                                        .filter(wallThickness => wallThickness.material === getProductValue('marco_material'))
                                        .sort((a, b) => (a['wall_thickness'] > b['wall_thickness']) ? 1 : ((b['wall_thickness'] > a['wall_thickness']) ? -1 : 0))
                                        .map(wallThickness => {
                                            return (
                                                <Select.Option value={wallThickness['objectId']}>{wallThickness['wall_thickness']}</Select.Option>
                                            );
                                        })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Espessura Marco'>
                            <div style={{ fontWeight: 'bold' }}>
                                {getMarcoThicknessInfo(getProductValue('wall_thickness'), 'marco_thickness')}
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Alizar Regulável'>
                            <div style={{ fontWeight: 'bold' }}>
                                {getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz')}
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Marco Passagem'>
                            <div style={{ fontWeight: 'bold' }}>
                                {getMarcoThicknessInfo(getProductValue('wall_thickness'), 'marco_passagem')}
                            </div>
                        </Form.Item>
                    </Col>
                </Row>

                <h3>Marco Básico</h3>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Acabamento" required>
                            <Select
                                value={
                                    getProductValue('finishing_marco') && getProductValue('marco_option_finishing') ?
                                        `${getProductValue('finishing_marco')}__${getProductValue('marco_option_finishing')}`
                                        : getProductValue('finishing_marco')
                                }
                                onChange={async value => {
                                    updateProductData('finishing_marco', index, (value || '').includes("__") ? value.split("__")[0] : value)
                                    updateProductData('marco_option_finishing', index, (value || '').includes("__") ? value.split("__")[1] : null)
                                    updateProductData('pattern_marco', index, null)

                                    if (value && (getProductValue('aliz_fixed_section') || getProductValue('aliz_adjustable_section'))) {
                                        const newFinishingMethodologyAliz = ((_this.state.alizMatriz || []).find(el => el.get('alizar_finishing_new_logic')))
                                        setAlizFinishingEqualMarco(
                                            _this.state.alizMatriz,
                                            _this.state.kitsComponents['comp_marco'],
                                            (value || '').includes("__") ? value.split("__")[0] : value,
                                            (value || '').includes("__") ? value.split("__")[1] : null,
                                            { fixed: getProductValue('aliz_fixed_section'), adjustable: getProductValue('aliz_adjustable_section') },
                                            getProductValue('aliz_options_02'),
                                            (ids) => {
                                                if (getProductValue('aliz_options') !== false) {
                                                    updateProductData('fixed_aliz', index, ids.fixed.includes('__') ? ids.fixed.split('__')[0] : ids.fixed)
                                                    updateProductData('finishing_aliz', index, ids.fixed.includes('__') ? ids.fixed.split('__')[0] : ids.fixed)
                                                    updateProductData('fixed_aliz_option_finishing', index, ids.fixed.includes('__') ? ids.fixed.split('__')[1] : null)
                                                    updateProductData('pattern_aliz', index, null)
                                                }

                                                if (getProductValue('aliz_options_01') !== false) {
                                                    updateProductData('adjustable_aliz', index, ids.adjustable && ids.adjustable.includes('__') ? ids.adjustable.split('__')[0] : ids.adjustable)
                                                    updateProductData('finishing_aliz_adjustable', index, ids.adjustable && ids.adjustable.includes('__') ? ids.adjustable.split('__')[0] : ids.adjustable)
                                                    updateProductData('adjustable_aliz_option_finishing', index, ids.adjustable && ids.adjustable.includes('__') ? ids.adjustable.split('__')[1] : null)
                                                    updateProductData('pattern_aliz_adjustable', index, null)
                                                }
                                            },
                                            (newFinishingMethodologyAliz || false),
                                            getMarcoThicknessInfo(getProductValue('wall_thickness'), 'adjustable_aliz'),
                                        )
                                    }
                                }}
                                placeholder="Acabamento" size="large" disabled={_this.props.disabledFields}
                            >
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    filterComponentBySheetFinishing(
                                        _this.state.kitsComponents['comp_folha'],
                                        getProductValue('finishing_sheet'),
                                        _this.state.kitsComponents['comp_marco'],
                                        _this.props.finishings,
                                        getProductValue('marco_material') !== 'Madeira' ? [] : _this.props.matrizFinishing,
                                    )
                                        .filter(el => {
                                            // Filter marco by height and thickness
                                            let thickness = _this.state.kitsComponents['comp_marco'].find(marco => marco.id === getProductValue('thickness_marco'));
                                            thickness = thickness && thickness.get('fill_componente');
                                            return (
                                                (el.get('height_component') || '').toString() === getProductValue('prod_height_marco') &&
                                                el.get('fill_componente') === thickness
                                            )
                                        })

                                        .filter(el => el.get('finishing'))
                                        .map(el => {
                                            const finishing = _this.props.finishings.find(finishing => finishing.finishingId === el.get('finishing').id) || null
                                            return {
                                                ...finishing,
                                                marcoFinishing: el.id
                                            }
                                        }).filter(el => el)
                                        .sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
                                        .map(el => <Select.Option value={el.marcoFinishing}>{el.code} - {el.description}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Padrão de cor" required>
                            <Select
                                value={getProductValue('pattern_marco')}
                                onChange={value => {
                                    updateProductData('pattern_marco', index, value)

                                    if (value && (getProductValue('aliz_fixed_section') || getProductValue('aliz_adjustable_section'))) {
                                        Modal.confirm({
                                            title: 'Alterar padrão dos alizares',
                                            content: 'Deseja alterar o padrão de cor dos alizares conforme o padrão de cor do marco?',
                                            okText: 'Sim',
                                            cancelText: 'Não',
                                            onOk() {
                                                if (getProductValue('aliz_options') !== false) updateProductData('pattern_aliz', index, value)
                                                if (getProductValue('aliz_options_01') !== false) updateProductData('pattern_aliz_adjustable', index, value)
                                            }
                                        })
                                    }
                                }}
                                placeholder="Padrão de cor" size="large" disabled={_this.props.disabledFields}>
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    (getPatternOfFinishingsComponent(getProductValue('finishing_marco'), _this.state.kitsComponents['comp_marco'], _this.props.finishings))
                                        .map(el => {
                                            return (
                                                <Select.Option value={el.objectId}>{el.pattern}</Select.Option>
                                            );
                                        })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Row>
        </Fragment>
    )
}

export default productStepMarco