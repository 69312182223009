import React from 'react'
import { Icon, Tag } from 'antd'
export default {
  "fields": [
    [
      {
        "title": "Descrição",
        "key": "description",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="tags" />}
      },
      {
        type: 'select',
        key: 'usage_types',
        title: 'Usos',
        options: [],
        optionConfig: { name: 'usage' },
        loaded: false,
        colSize: 12,
        'element-attr': {
          required: true,
          mode: 'multiple'
        }
      }
    ]
  ],
  "submit": {
    "collection": "Occupation"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Descrição",
      "key": "description",
      "dataIndex": "description",
      "type": "text",
      className: "description"
    },
    {
      title: 'Usos',
      key: 'usage',
      dataIndex: 'usage_types',
      render: v => {
        return (v || [])
          .map((usage,i) => <Tag key={i}>{usage}</Tag>)
      }
    }
  ],
  "title-navigation": <div><Icon type="shop" /> Ocupação</div>,
  "title-module": "Ocupação",
  "module": "Occupation",
  "router-base": "/panel/ocupacao"
}