import { Col, Divider, Form, Row, Select } from "antd";
import { isACompatibleSheetFinishing } from "../../../../suport-files/proposal-functions.jsx";
import React, { Fragment } from "react"

const productStepMarcoPassagemNewFinishingLogic = ({
    blockIndex,
    index,
    getProductValue,
    updateProductData,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    filterComponentBySheetFinishing,
    _this,
}) => {

    const getMarcoPassagemFinishingOptions = () => {
        const marcoPassagem = _this.state.kitsComponents['comp_marco_passagem']
            .find(el => el.id === getProductValue('finishing_marco_passagem'))

        if (!marcoPassagem) return []

        return marcoPassagem.get('finishing_options_inputs') || []
    }

    return (
        <Fragment>
            <Row gutter={12} className="chunk">
                <h3>Marco de Passagem</h3>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Espessura">
                            <Select
                                value={getProductValue('finishing_marco_passagem')}
                                onChange={async value => {
                                    updateProductData('finishing_marco_passagem', index, value)
                                    updateProductData('marco_passagem_option_finishing', index, null)
                                    updateProductData('pattern_marco_passagem', index, null)
                                }}
                                placeholder="Espessura" size="large" disabled={_this.props.disabledFields}
                            >
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    [
                                        ...new Set(
                                            _this.state.kitsComponents['comp_marco_passagem']
                                                .filter(el => {
                                                    // Filter marco passagem by height
                                                    return ((el.get('height_component') || '').toString() === getProductValue('prod_height_marco'))
                                                })
                                                .filter(el => el)
                                                .map(el => {
                                                    return {
                                                        thickness: el.get('fill_componente'),
                                                        id: el.id
                                                    }
                                                })
                                        )
                                    ]
                                        .sort((a, b) => (a.thickness > b.thickness) ? 1 : ((b.thickness > a.thickness) ? -1 : 0))
                                        .map(el => <Select.Option value={el.id}>{el.thickness}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Acabamento">
                            <Select
                                value={getProductValue('marco_passagem_option_finishing')}
                                onChange={async value => {
                                    updateProductData('marco_passagem_option_finishing', index, value)
                                    updateProductData('pattern_marco_passagem', index, null)
                                }}
                                placeholder="Acabamento" size="large" disabled={_this.props.disabledFields}
                            >
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    getMarcoPassagemFinishingOptions()
                                        .map(el => {
                                            const finishing = _this.props.finishings.find(finishing => finishing.finishingId === el.finishing) || null
                                            if (finishing) {
                                                return {
                                                    ...finishing,
                                                    marcoFinishing: el.id
                                                }
                                            } else return null
                                        })
                                        .filter(el => el)
                                        .filter(el => isACompatibleSheetFinishing(el.finishingId, _this.props.matrizFinishing, getProductValue('sheet_option_finishing')))
                                        .sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
                                        .map(el => <Select.Option value={el.finishingId}>{el.code} - {el.description}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Padrão de cor">
                            <Select
                                value={getProductValue('pattern_marco_passagem')}
                                onChange={value => {
                                    updateProductData('pattern_marco_passagem', index, value)
                                }}
                                placeholder="Padrão de cor" size="large" disabled={_this.props.disabledFields}>
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    getPatternOfAFinishing(getProductValue('marco_passagem_option_finishing'), _this.props.finishings)
                                        .map(el => {
                                            return (
                                                <Select.Option value={el.objectId}>{el.pattern}</Select.Option>
                                            );
                                        })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Row>

            <Divider dashed={true} />
        </Fragment>
    )
}

export default productStepMarcoPassagemNewFinishingLogic