import { Icon, Input, Table, Button, List, notification, Tag } from "antd"
import React from "react"
import { getInputsByProvidersOfOrders, generateInputOrderQuote } from "./suport-files/input-client-report-functions";
import { columns, getOrders } from "./suport-files/order-table-configs";

let orderSearchValue
let loadingOrderTable = false
let loadingProviderTable = false
let ordersDataSource = []

export default {
    formTabs: [
        {
            title: "Informações gerais",
            fields: [
                [
                    {
                        title: "Nome",
                        type: "text",
                        key: "name",
                        colSize: 8,
                        "element-attr": { required: true },
                    },
                    {
                        title: "Descrição",
                        type: "text",
                        key: "description",
                        colSize: 16,
                        "element-attr": { required: true },
                    },
                ],
                [
                    {
                        title: "Pedidos",
                        type: "render",
                        render: (_, form) => {
                            return (
                                <div>
                                    <Table
                                        columns={
                                            columns({
                                                orders: _.formRef.ordersSelected,
                                                ordersSelected: _.formRef.ordersSelected,
                                                addOrder: (order) => {
                                                    _.formRef.ordersSelected = [...(_.formRef.ordersSelected || []), order]
                                                    form.state.invalidForm = false
                                                    form.forceUpdate()
                                                },
                                                removeOrder: (orderToRemove) => {
                                                    _.formRef.ordersSelected = _.formRef.ordersSelected.filter(order => order.order_code !== orderToRemove.order_code)
                                                    form.state.invalidForm = false
                                                    form.forceUpdate()
                                                },
                                            })
                                        }
                                        dataSource={_.formRef.ordersSelected}
                                        locale={{ emptyText: "Nenhum resultado encontrado." }}
                                        size='small'
                                    />
                                </div>
                            )
                        }
                    }
                ],
                [
                    {
                        key: "ordersSelected",
                        type: "text",
                        colSize: 1,
                        "element-attr": {
                            style: { display: "none" },
                            required: false,
                        },
                    },
                    {
                        key: "providers",
                        type: "text",
                        colSize: 1,
                        "element-attr": {
                            style: { display: "none" },
                            required: false,
                        },
                    },
                ],
            ]
        },
        {
            title: "Adicionar Pedidos",
            fields: [
                [
                    {
                        title: "Pesquisar pedidos",
                        type: "render",
                        colSize: 6,
                        render: (_, form) => {

                            return (
                                <div>
                                    <Input
                                        size="large"
                                        placeholder="Pesquisar pedidos"
                                        onPressEnter={async (e) => {
                                            e.preventDefault()

                                            loadingOrderTable = true
                                            form.forceUpdate()

                                            ordersDataSource = await getOrders(orderSearchValue)

                                            loadingOrderTable = false
                                            form.forceUpdate()
                                        }}
                                        onChange={(e) => {
                                            orderSearchValue = e.target.value
                                            form.forceUpdate()
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        type: "render",
                        colSize: 6,
                        render: (_, form) => {
                            return (
                                <div>
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={async () => {
                                            loadingOrderTable = true
                                            form.forceUpdate()

                                            ordersDataSource = await getOrders(orderSearchValue)

                                            loadingOrderTable = false
                                            form.forceUpdate()
                                        }}
                                    >Pesquisar</Button>
                                </div>
                            )
                        }
                    },
                ],
                [
                    {
                        type: "render",
                        render: (_, form) => {
                            return (
                                <div>
                                    <Table
                                        columns={
                                            columns({
                                                orders: ordersDataSource,
                                                ordersSelected: _.formRef.ordersSelected,
                                                addOrder: (order) => {
                                                    _.formRef.ordersSelected = [...(_.formRef.ordersSelected || []), order]
                                                    form.state.invalidForm = false
                                                    form.forceUpdate()
                                                },
                                                removeOrder: (orderToRemove) => {
                                                    _.formRef.ordersSelected = _.formRef.ordersSelected.filter(order => order.order_code !== orderToRemove.order_code)
                                                    form.state.invalidForm = false
                                                    form.forceUpdate()
                                                },
                                            })
                                        }
                                        dataSource={ordersDataSource}
                                        loading={loadingOrderTable}
                                        locale={{ emptyText: "Nenhum resultado encontrado." }}
                                        size='small'
                                    />
                                </div>
                            )
                        }
                    }
                ],
            ],
        },
        {
            title: "Lista de Fornecedores",
            fields: [
                [
                    {
                        type: 'render',
                        render: (_, form) => {
                            return (
                                <Button
                                    size="large"
                                    type="primary"
                                    loading={loadingProviderTable}
                                    onClick={async () => {
                                        loadingProviderTable = true
                                        form.forceUpdate()

                                        const inputsByProviders = await getInputsByProvidersOfOrders(_.formRef.ordersSelected)
                                        _.formRef.providers = inputsByProviders
                                        form.state.invalidForm = false

                                        loadingProviderTable = false
                                        form.forceUpdate()
                                    }}
                                >Gerar / Atualizar</Button>
                            )
                        }
                    }
                ],
                [
                    {
                        type: "render",
                        render: (_, form) => {
                            const providers = _.formRef.providers

                            return (
                                <Table
                                    locale={{ emptyText: "Nenhum resultado encontrado." }}
                                    columns={[{ title: 'Fornecedores', dataIndex: 'provider', key: 'provider' }]}
                                    dataSource={providers}
                                    pagination={false}
                                    loading={loadingProviderTable}
                                    expandedRowRender={(provider) => {
                                        return (
                                            <div>
                                                <List
                                                    locale={{ emptyText: "Nenhum resultado encontrado." }}
                                                    dataSource={provider.inputs || []}
                                                    renderItem={input => {
                                                        return (
                                                            <List.Item key={input.details.code} >
                                                                <List.Item.Meta title={input.details.material_description} description={`Total: ${Number.isInteger(input.totalConsumo) ? input.totalConsumo : (input.totalConsumo || 0).toFixed(2)} ${input.origin_measure}`} />
                                                            </List.Item>
                                                        )
                                                    }}
                                                />

                                                <div style={{ textAlign: "center" }}>
                                                    <Button
                                                        icon="file-protect"
                                                        style={{ margin: "auto", marginTop: "30px" }}
                                                        type="primary"
                                                        onClick={async () => {
                                                            notification.open({
                                                                message: 'Gerando a cotação',
                                                                description: 'A cotação está sendo criada, aguarde...',
                                                                key: 'generatingInputOrderQuote',
                                                                duration: 0,
                                                                placement: 'topLeft',
                                                                icon: <Icon type="loading" />
                                                            });

                                                            try {
                                                                const inputOrderQuoteCode = await generateInputOrderQuote(provider)
                                                                notification.close('generatingInputOrderQuote');
                                                                notification.success({
                                                                    message: 'Cotação gerada com sucesso.',
                                                                    description: `O código da cotação é o ${inputOrderQuoteCode}. Acesse o módulo cotação para editar.`,
                                                                    placement: 'topLeft',
                                                                });
                                                            } catch (e) {
                                                                notification.close('generatingInputOrderQuote');
                                                                notification.error({
                                                                    message: 'Erro ao gerar a cotação.',
                                                                    description: `Verifique todos os dados e tente novamente.`,
                                                                    placement: 'topLeft',
                                                                });
                                                            }

                                                        }}
                                                    >Gerar cotação</Button>
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                            )
                        }
                    }
                ]
            ],
        }
    ],
    submit: { collection: "InputClientReport" },
    schema: [
        {
            title: "Nome",
            key: "name",
            dataIndex: "name",
            type: "text",
            width: "150px"
        },
        {
            title: "Pedidos",
            key: "ordersSelected",
            dataIndex: "ordersSelected",
            type: "render",
            render: (orders) => (orders || []).map(order => <Tag style={{ margin: 5 }} key={order.objectId}>{order.order_code} {order.keyWord}</Tag>)
        },
    ],
    "title-module": 'Programação Insumos',
    "title-navigation": <div><Icon type="dropbox" /> Programação Insumos</div>,
    module: "InputClientReport",
    "router-base": "/panel/programacao-insumos"
}