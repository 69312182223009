import React, { Component } from 'react';
import { notification, message } from 'antd';
import { calculatePrice } from '../../utils/proposal_calcs';
import { findById, findWhere, findWhereMultiple, runCloudFunction, save } from '../../utils/db_functions';
import ChecklistView from './checklistView';

export default class ChecklistContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proposal: null,
            products: [{}],
            productsValues: [{}],
            cks: [], // Lista dos Componentes do bd
            kits: [], // Lista dos kits do bd
            check_configs: [], // Lista das configurações de checklist
            form: {},
            saveLoading: false,
            modalVisible: true,
            proposalId: null,
            formLoading: false,
            modalSucessVisible: false,
            listOfChecklists: [],
            checklistChoosed: null,
            printLoadingButton: false,
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        if (this.props['router-props'].location.state) {
            this.setState({ modalVisible: false, proposalId: this.props['router-props'].location.state })
            this.fillForm(this.props['router-props'].location.state)
        }
    }

    kitComponents = [
        'finishing_sheet',
        'thickness_marco',
        'conj_fechadura',
        'hardware_door_lock',
        'fixed_aliz',
        'adjustable_aliz',
    ];

    /**
     * Get attr from kits
     * @param {array} kits 
     * @param {array} attr 
     * @returns Array uniq of all attr ids
     */
    getAttrFromKits = (kits, attr) => {
        const attrArrayOfArray = kits.map(kit => attr.map(el => kit[el]));
        const attrArray = attrArrayOfArray.flat();
        return [...new Set(attrArray.filter(el => el))];
    }

    /**
     * Preenche o checklist com os dados da proposta.
     * @param {String} id Id da proposta.
     */
    fillForm = async (id) => {
        this.setState({ formLoading: true });
        // Busca checklist no bd
        let form_filled = await findWhereMultiple('ExternalDatas', [{ field: 'module', value: 'Checklist', op: 'equalTo' }, { field: 'data.proposalId', value: id, op: 'equalTo' }]);
        // Preencher form
        if (!form_filled.length) form_filled = this.state.form;
        else form_filled = form_filled[0].get('data');
        // Busca proposta no banco de acordo com o objectId informado
        const proposalId = id;
        const proposal = await findById('Proposal', proposalId, ['sales_channel', 'instalation']);
        if (proposal) {
            let productsValues = proposal.get('baseFinalPriceAndAmount').unitValues.flat();
            let products = proposal.get('product_blocks').map(block => block.products).flat().filter(el => (proposal.get('checklist_itens') || []).includes(el.project_code));
            // Busca todos os kits do bd
            const kits = await findWhere('Kit', 'objectId', products.map(el => el['product_code']));
            // Busca todas as configurações de cheklist do bd
            const checkListsFromKits = this.getAttrFromKits(products, ['profile_performance']);
            const check_configs = await findWhereMultiple('ChecklistConfigs', [{ 'field': 'performances', 'value': checkListsFromKits, 'op': 'containedIn' }]);
            // Busca todos os componentes do kit do bd
            const cksFromKits = this.getAttrFromKits(products, this.kitComponents);
            const cks = await findWhere('ComponenteKit', 'objectId', cksFromKits, ['finishing']);
            let index = 0;
            for (const product of products) {
                // Check if has instalation
                const hasInstalation = ((proposal.get('instalation') && proposal.get('instalation').get('name').includes('Multidoor Service')) || false);
                // Calculate price
                products[index]['price'] = await calculatePrice(product, product.discount, false, proposal.get('sales_channel').get('value'), hasInstalation);
                index++;
            }
            this.setState({
                proposal: proposal,
                products, productsValues, cks, kits, check_configs,
                form: { ...form_filled, proposalId },
                formLoading: false,
                modalVisible: false,
            });
        } else {
            this.setState({ formLoading: false })
            notification.error({ message: 'Alguma coisa deu errada, verifique código e tente novamente.', duration: 5 })
        }
    }

    /**
     * Retorna um componente.
     * @param {String} id Id do componente.
     * @returns Componente.
     */
    getComponent = (id) => {
        let component = this.state.cks.find(el => el.id === id);
        return component && component.toJSON();
    }

    /**
     * Retorna um kit.
     * @param {String} code Código do kit.
     * @returns Kit.
     */
    getKit = (code) => {
        let kit = this.state.kits.find(el => el.id === code);
        return kit && kit.toJSON();
    }

    /**
     * Retorna uma configuração de checklist.
     * @param {String} code Código da performance.
     * @returns Configuração do checklist.
     */
    getChecklistConfigs = (code) => {
        let check_configs = this.state.check_configs.find(el => (el.get('performances') || []).includes(code));
        return check_configs && check_configs.toJSON();
    }

    /**
     * Salva os dados do form para a table do bd responsável por arquivar dados externos
     * @param {String} proposalId
     */
    saveChecklist = async (proposalId) => {
        this.setState({ saveLoading: true });
        const dataToSave = {
            module: 'Checklist',
            data: this.state.form,
        };
        let dataCreated;
        const ext_datas = await findWhereMultiple('ExternalDatas', [{ field: 'module', value: 'Checklist', op: 'equalTo' }, { field: 'data.proposalId', value: proposalId, op: 'equalTo' }]);
        dataCreated = await save('ExternalDatas', dataToSave, ext_datas.length ? ext_datas[0].id : null);
        if (dataCreated) {
            notification.success({ message: 'Dados salvos com sucesso.', duration: 5 });
            if (!this.props['router-props'].location.state) {
                document.getElementById('checklist').style.backgroundColor = 'white';
                document.getElementById('checklist').style.padding = '0px 0px';
                document.getElementById('checklist-card').style.margin = '0px 0px';
                window.print();
                this.setState({ modalSucessVisible: true, saveLoading: false });
            } else this.setState({ saveLoading: false });
        } else {
            notification.error({ message: 'Alguma coisa deu errada, verifique os valores e tente novamente.', duration: 5 });
            this.setState({ saveLoading: false });
        }
    }

    /**
     * Imprime o pdf do checklist
     */
    printPDF = async () => {
        message.loading('Gerando pdf, aguarde um momento.');
        this.setState({ printLoadingButton: true });
        let htmlString = document.getElementById('checklist-card').outerHTML;
        htmlString = htmlString
            .replace('<img src="/static/media/logo.431bde4b.svg" alt="Logo Senai" width="300">', `<img src="${process.env.REACT_APP_PUBLIC}/assets/images/logo.svg" alt="Logo Multidoor" width="300">`)
            .replace('<img src="/static/media/checklist_footer.499dd6db.jpg" alt="Madeira é Agro" width="80%" style="margin-left: 10%; margin-top: -50px;">', `<img src="${process.env.REACT_APP_PUBLIC}/assets/images/checklist_footer.jpg" alt="Madeira é Agro" width="80%" style="margin-left: 10%; margin-top: -50px;">`)
            .replace('<button type="button" class="ant-btn ant-btn-primary" style="width: 400px;"><span>Salvar Checklist</span></button>', '')
            .replace('<button type="button" class="ant-btn ant-btn-ghost" style="width: 400px; margin-left: 10px;"><span>Imprimir em pdf</span></button>', '');
        const response = await runCloudFunction('generatePdfFromHtml', { htmlToRender: htmlString, path: `pdf_checklist/${this.state.proposal.get('proposal_code')}.pdf` });
        if (response.code === 200) {
            window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank');
            this.setState({ printLoadingButton: false });
        } else {
            this.setState({ printLoadingButton: false });
            message.error(response.response);
        }
    }

    render() {
        return (
            <ChecklistView
                // Attr
                proposal={this.state.proposal}
                products={this.state.products}
                form={this.state.form}
                saveLoading={this.state.saveLoading}
                modalVisible={this.state.modalVisible}
                proposalId={this.state.proposalId}
                formLoading={this.state.formLoading} s
                modalSucessVisible={this.state.modalSucessVisible}
                printLoadingButton={this.state.printLoadingButton}
                // Functions
                fillForm={(id) => this.fillForm(id)}
                getKit={(code) => this.getKit(code)}
                getComponent={(id) => this.getComponent(id)}
                getChecklistConfigs={(code) => this.getChecklistConfigs(code)}
                saveChecklist={(id) => this.saveChecklist(id)}
                printPDF={() => this.printPDF()}
                updateState={(object) => this.setState(object)}
            />
        );
    }
}