const surveyQuestionsAndAnswers = [
    {
        question: {
            key: 'howToKnowMultidoor',
            label: 'Onde você conheceu a Multidoor?',
        },
        answers: [
            {
                key: 'Feiras e eventos',
                label: 'Feiras e eventos',
            },
            {
                key: 'Portifólio de obras',
                label: 'Portifólio de obras',
            },
            {
                key: 'Especificação técnica',
                label: 'Especificação técnica',
            },
            {
                key: 'Indicação de amigo',
                label: 'Indicação de amigo',
            },
            {
                key: 'Pesquisa na internet',
                label: 'Pesquisa na internet',
            },
        ]
    },
    {
        question: {
            key: 'whoIndicated',
            label: 'Quem indicou a Multidoor para o seu projeto?',
        },
        answers: [
            {
                key: 'Arquitetura | Projeto',
                label: 'Arquitetura | Projeto',
            },
            {
                key: 'Engenharia | Construção',
                label: 'Engenharia | Construção',
            },
            {
                key: 'Suprimentos | Compras',
                label: 'Suprimentos | Compras',
            },
            {
                key: 'Consultoria técnica',
                label: 'Consultoria Técnica',
            },
            {
                key: 'Diretoria | Proprietário',
                label: 'Diretoria | Proprietário',
            },
        ]
    },
    {
        question: {
            key: 'whatMostRelevant',
            label: 'O que achou mais relevante no site Multidoor?',
        },
        answers: [
            {
                key: 'Desempenho',
                label: 'Desempenho',
            },
            {
                key: 'Modelos',
                label: 'Modelos',
            },
            {
                key: 'Acessibilidade',
                label: 'Acessibilidade',
            },
            {
                key: 'Acústica',
                label: 'Acústica',
            },
            {
                key: 'BIM',
                label: 'BIM',
            },
        ]
    },
    {
        question: {
            key: 'whatMostImportant',
            label: 'O que é mais importante para sua compra de portas?',
        },
        answers: [
            {
                key: 'Marca',
                label: 'Marca',
            },
            {
                key: 'Certificação de produto',
                label: 'Certificação de produto',
            },
            {
                key: 'Menor Preço',
                label: 'Menor Preço',
            },
            {
                key: 'Prazo de entrega',
                label: 'Prazo de entrega',
            },
            {
                key: 'Condição de pagamento',
                label: 'Condição de pagamento',
            },
        ]
    },
    {
        question: {
            key: 'doorImportance',
            label: 'Qual a importância da porta no seu empreendimento?',
        },
        answers: [
            {
                key: 'Atender aos requisitos de projeto',
                label: 'Atender aos requisitos de projeto',
            },
            {
                key: 'Proporcionar a vedação dos ambientes',
                label: 'Proporcionar a vedação dos ambientes',
            },
            {
                key: 'Exercer outras funções como isolamento Acústico',
                label: 'Exercer outras funções como isolamento Acústico',
            },
            {
                key: 'Compatibilizar com a ambientação',
                label: 'Compatibilizar com a ambientação',
            },
            {
                key: 'Garantir maior segurança e conforto',
                label: 'Garantir maior segurança e conforto',
            },
        ]
    },
    {
        question: {
            key: 'whichItemWouldYouCutCosts',
            label: 'Qual item você reduziria custos em favor das portas internas?',
        },
        answers: [
            {
                key: 'Janelas e portas externas',
                label: 'Janelas e portas externas',
            },
            {
                key: 'Pisos e revestimentos',
                label: 'Pisos e revestimentos',
            },
            {
                key: 'Louças e metais',
                label: 'Louças e metais',
            },
            {
                key: 'Armários planejados',
                label: 'Armários planejados',
            },
            {
                key: 'Móveis e decoração',
                label: 'Móveis e decoração',
            },
        ]
    },
    {
        question: {
            key: 'attendance',
            label: 'Como foi o atendimento técnico-comercial da Multidoor?',
        },
        answers: [
            {
                key: 'Ajudou revisar a especificação do projeto',
                label: 'Ajudou revisar a especificação do projeto',
            },
            {
                key: 'Alertou para os requisitos das normas ABNT',
                label: 'Alertou para os requisitos das normas ABNT',
            },
            {
                key: 'Esclareceu minhas dúvidas sobre portas',
                label: 'Esclareceu minhas dúvidas sobre portas',
            },
            {
                key: 'Uma verdadeira consultoria de portas',
                label: 'Uma verdadeira consultoria de portas',
            },
        ]
    },
    {
        question: {
            key: 'multidoorExperience',
            label: 'Como você qualifica sua experiencia inicial com a Multidoor?',
        },
        answers: [
            {
                key: 'Excelente',
                label: 'Excelente',
            },
            {
                key: 'Boa',
                label: 'Boa',
            },
            {
                key: 'Ruim',
                label: 'Ruim',
            },
            {
                key: 'Péssima',
                label: 'Péssima',
            },
        ]
    },
]

/*
{
    question: {
        key: '',
        label: '',
    },
    answers: [
        {
            key: '',
            label: '',
        },
    ]
}
*/

export {
    surveyQuestionsAndAnswers,
}