const contractModel = `
        <b>INSTRUMENTO PARTICULAR DE CONTRATO DE PRESTAÇÃO DE SERVIÇOS, INDUSTRIALIZAÇÃO DE PRODUTOS E OUTRAS AVENÇAS</b><br />

        Por este instrumento particular, as partes adiante qualificadas ajustam entre si a execução de serviços,
        industrialização de produtos e outras avenças, mediante as condições e cláusulas constantes neste contrato,
        o qual abrange as CONDIÇÕES COMERCIAIS DO CONTRATO, as CONDIÇÕES GERAIS DO CONTRATO, o(s) PEDIDO(S) DE FORNECIMENTO(S),
        o CRONOGRAMA FÍSICO-FINANCEIRO, o PEDIDO CONSOLIDADO e ANEXOS, os quais compõem inseparavelmente o presente instrumento,
        sendo estes reciprocamente outorgados e aceitos.<br />

        <b>CONDIÇÕES COMERCIAIS DO CONTRATO</b><br />
        
        <b>CLÁUSULA PRIMEIRA - PARTES CONTRATANTES:</b></br>
        
        %CLIENTE_DADOS%<br />
        
        %MULTIDOOR_DADOS%<br />
        
        <b>CLÁUSULA SEGUNDA - LOCAL DA OBRA:</b><br />

        %OBRA_DADOS%<br />
        
        <b>CLÁUSULA TERCEIRA – DA NATUREZA E DO OBJETO DO CONTRATO:</b><br />
        
        A <strong>CONTRATADA</strong>, pelo presente CONTRATO, se compromete, perante a <strong>CONTRATANTE</strong>,
        a fornecer portas de madeira (produtos e serviços) denominados de SISTEMA PORTA PRONTA,
        discriminado(s) no(s) PEDIDO(S) DE FORNECIMENTO, bem como o direito de uso da marca MULTIDOOR®,
        nos termos e condições ora ajustados, sendo que a matéria-prima, os insumos e os serviços
        próprios e/ou adquiridos de terceiros necessários para a industrialização dos produtos sob
        encomenda serão fornecidos pela <strong>CONTRATANTE</strong> e dedutíveis dos valores do CONTRATO.<br />

        <b>CLÁUSULA QUARTA - DOS PREÇOS E DO VALOR DO CONTRATO:</b><br />

        Durante a execução do CONTRATO de fornecimento de SISTEMA PORTA PRONTA, as partes acordam
        que as quantidades e os preços unitários serão aquelas estipuladas no(s) PEDIDO(S) DE
        FORNECIMENTO, sendo o valor do presente CONTRATO na ordem de %PREÇO%.<br />
        
        4.1 - Fica desde já esclarecido e ajustado que as quantidades e preços baseiam-se nos projetos e
        detalhes de arquitetura e poderão sofrer ajustes após a emissão do PEDIDO CONSOLIDADO.<br />

        <b>CLÁUSULA QUINTA – DOS PREÇOS UNITÁRIOS E TARIFAÇÃO ADICIONAL:</b><br />
        
        5.1 - Os preços unitários constantes no PEDIDO DE FORNECIMENTO são os referenciais para produção
        integral dos lotes programados no Cronograma de cada etapa da obra. Qualquer pedido adicional ou
        parcial dos lotes previstos será considerado como um aditivo, cujos preços unitários para produção
        em pequenas quantidades são acrescidos de 50% até 100% do valor base inicial.<br />

        5.2 - Todas as alterações e adicionais apurados na execução do PROJETO EXECUTIVO serão aplicadas no
        PEDIDO CONSOLIDADO de acordo com a TABELA DE TARIFAÇÃO ADICIONAL para os produtos constantes no
        ANEXO I e Tabela de preços vigente para os demais produtos.<br />

        5.3 - A <strong>CONTRATANTE</strong>, por sua vez, se compromete a efetuar o pagamento dos adicionais apresentados após
        a conclusão do PEDIDO CONSOLIDADO.<br />

        <b>CLÁUSULA SEXTA – DA FORMA E DOS PRAZOS PARA PAGAMENTO:</b><br />
        
        6.1 – Os pagamentos serão realizados por fase de execução do CONTRATO, nos seguintes termos:<br />
            
        6.1.1 - SERVIÇOS DE ENGENHARIA: correspondentes à consultoria, assessoria técnica e desenvolvimento
        do PROJETO EXECUTIVO de SISTEMA PORTA PRONTA, a <strong>CONTRATANTE</strong> pagará à <strong>CONTRATADA</strong> o valor de %PREÇO_SERVIÇO%,
        dividido em parcela(s) conforme a seguir: <br />

        %CRONOGRAMA_SERVIÇOS% <br />
            
        6.1.2 – INDUSTRIALIZAÇÃO SOB ENCOMENDA: correspondentes a fabricação dos produtos KIT PORTA PRONTA, a <strong>CONTRATANTE</strong>
        pagará à <strong>CONTRATADA</strong> o valor de %PREÇO_INDUSTRIALIZAÇÃO%, dividido em parcela(s) conforme a seguir: <br />
            
        %CRONOGRAMA_INDUSTRIALIZAÇÃO% <br />
            
        6.1.3 - SERVIÇOS DE INSTALAÇÃO: correspondentes a Mão de obra, equipamentos e materiais para instalação
        dos KITS PORTA PRONTA, a <strong>CONTRATANTE</strong> pagará à <strong>CONTRATADA</strong> o valor de %PREÇO_INSTALAÇÃO%, dividido em
        parcela(s) conforme a seguir: <br />
            
        %CRONOGRAMA_INSTALAÇÃO% <br />

        6.2 - Os materiais e serviços prestados pela <strong>CONTRATADA</strong> serão representadas por notas fiscais, faturas, duplicatas
        ou boletos bancários, emitidos para pagamento na respectiva rede bancária nos prazos previstos e contratados, de
        acordo com o CRONOGRAMA FISICO-FINANCEIRO. <br />

        6.3 - A falta ou o atraso dos pagamentos em relação aos seus respectivos vencimentos ensejará a aplicação do disposto
        na CLÁUSULA OITAVA da presente CONDIÇÕES COMERCIAIS DO CONTRATO, bem como o que dispuser as CONDIÇÕES GERAIS DO CONTRATO,
        sem prejuízo da faculdade de rescisão do CONTRATO.<br />
        
        6.4 - O não pagamento no vencimento acarretará ao CONTRATANTE a perda de possíveis descontos financeiros especificados,
        e a suspensão imediata dos SERVIÇOS.<br />
        
        <b>CLÁUSULA SÉTIMA - PRAZOS DE EXECUÇÃO DOS SERVIÇOS:</b><br />
        
        O presente CONTRATO vigorará a partir da data da sua assinatura e se desenvolverá pelo período necessário à execução
        dos serviços contratados, de acordo com o CRONOGRAMA de prazos de execução previamente estabelecido. <br />
        
        <b>CRONOGRAMA DE PRAZOS DE EXECUÇÃO:</b><br />

        %CRONOGRAMA_EXECUÇÃO%<br />
        
        7.1 – Os prazos da <strong>CONTRATADA</strong> tem início a partir do cumprimento da obrigação inicial da <strong>CONTRATANTE</strong>
        em relação ao PEDIDO TECNICO.<br />
        
        7.2 – Caso a <strong>CONTRATANTE</strong> atrase por mais de 30 (trinta) dias o PEDIDO TECNICO, os prazos da <strong>CONTRATADA</strong>
        serão objeto de uma repactuação em função da sua programação de produção.<br />

        7.3 – A <strong>CONTRATADA</strong> poderá promover ajustes nos lotes de produção motivada por pendências do
        PEDIDO TECNICO com o objetivo de garantir o cronograma pactuado no CONTRATO.<br />
        
        <b>CLÁUSULA OITAVA – DAS PENALIDADES:</b><br />
        
        8.1 - Deixando a <strong>CONTRATANTE</strong> de pagar os valores firmados no presente CONTRATO nos respectivos
        vencimentos, os títulos emitidos em decorrência e de acordo com este CONTRATO ficarão sujeitos
        ao acréscimo de multa de 2% (dois por cento) sobre o valor do débito em atraso, atualização monetária
        pelo IGPM e juros de 1% (um por cento) ao mês.<br />

        8.2 - Caso a <strong>CONTRATANTE</strong> cancele o CONTRATO, após a elaboração do PEDIDO TÉCNICO, deverá
        arcar com o montante de 10% (dez por cento) do valor do CONTRATO a titulo de ressarcimento
        dos custos dos serviços de engenharia realizados.<br />
        
        <b>CLÁUSULA NONA – DOS TRIBUTOS:</b><br />
        
        9.1 - Para efeitos de recolhimento do imposto municipal, mais precisamente do Imposto
        Sobre Serviços de Qualquer Natureza - ISS, quando incidente, deverá a <strong>CONTRATADA</strong>
        observar a legislação municipal da localidade da obra. Os impostos inclusos e a
        alíquota vigente são: 5% (cinco por cento) de ISS, 17% (Dezessete por cento) de ICMS,
        1% (um por cento) de FECOEP e 0% (zero por cento) de IPI.<br />
        
        9.2 - A fim de restabelecer o equilíbrio contratual, quaisquer impostos, emolumentos,
        contribuições fiscais ou parafiscais, taxas e encargos sociais que vierem a ser criados após
        a assinatura do presente CONTRATO, bem como elevação de alíquotas vigentes, serão de
        responsabilidade do <strong>CONTRATANTE</strong> e a ele repassados os respectivos valores à época do faturamento.<br />
        
        <b>CLÁUSULA DÉCIMA - DO CONSENTIMENTO E DA REPRESENTAÇAO, DO FORO E DAS CONDIÇÕES GERAIS</b><br />
        
        10.1 – A <strong>CONTRATANTE</strong> e a <strong>CONTRATADA</strong> declaram que seus subscritores têm poderes bastantes para constituir
        direitos e obrigações em nome das empresas contratantes.<br />

        10.2 – Fica eleito o foro da comarca de Maceió/AL, pelo que concordam expressamente as partes, para
        dirimir quaisquer avenças relativas a este negócio jurídico.<br />

        10.3 - E, por estarem assim justos e contratados declaram as presentes partes conhecer e anuir com
        todas as disposições constantes no presente CONTRATO, sendo este composto das CONDIÇÕES COMERCIAIS
        DO CONTRATO, das CONDIÇÕES GERAIS DO CONTRATO, PEDIDO(S) DE FORNECIMENTO(S), CRONOGRAMA FISICO-FINANCEIRO,
        PEDIDO CONSOLIDADO e dos ANEXOS, celebrando o presente instrumento em 03 (três) vias de igual teor e forma,
        para que produza seus jurídicos e legais efeitos, na presença de 02 (duas) testemunhas, infra-assinadas.<br />
        
        Maceió/AL, %DATA_EMISSÃO%.<br />

        <strong>CONTRATANTE</strong><br />

        <strong>CONTRATADA</strong><br />

        TESTEMUNHAS:<br />
    `

export default contractModel