const React = require("react");
const { default: ReactQuill } = require("react-quill");

const letterRemarksFields = (modules, formats) => [
    [
        {
            title: 'Carta Padrão',
            key: 'default_paper',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.default_paper || { "text": "<h1>Carta Padrão</h1>" }}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'default_paper')
                        }}
                    />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('default_paper', module.state.formRef.default_paper)
                return true;
            }
        }
    ],
    [
        {
            title: 'Observações Pagamento',
            key: 'payment_observation',
            type: 'textarea'
        }
    ],
    [
        {
            title: 'Mensagem Padrão - Proposta tipo Especificação',
            key: 'especification_type_message',
            type: 'textarea'
        }
    ],
    [
        {
            "title": "Logomarca Quadro de Soluções",
            "key": "logo",
            "type": "image",
            "colSize": 5,
            "className": "",
            "element-attr": { "required": false }
        },
        {
            "title": "Logomarca proposta",
            "key": "logo_proposal",
            "type": "image",
            "className": "",
            "colSize": 5,
            "element-attr": { "required": false }
        },
    ],
]

export default letterRemarksFields