import React from "react";
import { Icon, Button, notification } from "antd";
import Parse from "parse";
import {
  filterSalesChannelList,
  getInputsFromProvider,
} from "../input-order-price-quote/components/input-order-price-quote-functions";
import generalInfoFields from "./components/general-info-fields";
import inputsRelationFields from "./components/inputs-relation-fiels";
import totalsFields from "./components/totals-fields";
import { findAll } from "../../../utils/db_functions";
import { moneyToNumber } from "../../../utils/general_functions";
import {
  calculateInputOrderIPI,
  calculateInputOrderTotal,
  getDeliveries,
} from "./components/input-order-functions";

let inputs = [];
let salesChannels = [];
let providers = [];
let deliveries = [];
let clients = [];

const generateFields = () => {
  return [
    {
      title: "Informações gerais",
      fields: generalInfoFields({
        providers,
        clients,
        deliveries: deliveries,
        salesChannels: salesChannels,
        getInputsFromProvider: async (providerId) => {
          inputs = await getInputsFromProvider(providerId);
          return inputs;
        },
      }),
    },
    {
      title: "Relação de insumos",
      fields: inputsRelationFields({
        inputs: inputs,
      }),
    },
    {
      title: "Totais",
      fields: totalsFields(),
    },
  ];
};

export default {
  formTabs: generateFields(),
  FormHasMounted: async (_) => {
    if (salesChannels.length === 0)
      salesChannels = await filterSalesChannelList();
    if (deliveries.length === 0) deliveries = await getDeliveries();
    if (providers.length === 0)
      providers = await findAll("InputProvider", 1000);
    if (clients.length === 0) clients = await findAll("Client", 2000);

    _.module.form.formTabs = generateFields();

    const providerId = _.state.formRef && _.state.formRef.provider;
    if (providerId) {
      inputs = await getInputsFromProvider(providerId);
      _.props.module.form.formTabs[1].fields[0][0].fields[0][0].options = inputs;
    }

    _.forceUpdate();
  },
  submit: { collection: "InputOrder" },
  ListWillMount: async (_) => {
    salesChannels = await filterSalesChannelList();
    deliveries = await getDeliveries();
    providers = await findAll("InputProvider", 1000);
    clients = await findAll("Client", 2000);

    _.forceUpdate();
  },
  schema: [
    {
      title: "Número",
      key: "order_code",
      dataIndex: "order_code",
      width: "100px",
      type: "text",
    },
    {
      title: "Fornecedor",
      key: "provider",
      dataIndex: "provider",
      width: "150px",
      type: "render",
      render: (v) => {
        const provider = providers.find((el) => el.id === v.objectId);
        return provider && provider.get("name");
      },
    },
    {
      title: "Valor R$",
      key: "total_value_with_shipment",
      dataIndex: "inputs",
      width: "120px",
      type: "render",
      render: (inputs, form) => {
        const { shipment, discount } = form;
        const total = calculateInputOrderTotal({ inputs });
        const ipi = calculateInputOrderIPI({ inputs, discount, total });
        return (
          total + (shipment || 0) - (discount || 0) + (ipi || 0) || 0
        ).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: "Data de entrega",
      key: "delivery_conditions",
      dataIndex: "delivery_conditions",
      width: "100px",
      type: "render",
      render: (_, row) => {
        if (row.delivery_conditions)
          return new Date(row.delivery_conditions.iso).toLocaleString("pt-BR", {
            timeZone: "America/Maceio",
            day: "numeric",
            month: "numeric",
            year: "2-digit",
          });
        return "";
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "80px",
      type: "render",
      render: (v) => {
        return (v || []).map((el) => {
          let color = null;

          switch (el) {
            case "Programado":
              color = "yellow";
              break;

            case "Pagamento efetuado":
              color = "orange";
              break;

            case "Faturado":
              color = "green";
              break;

            case "Recebido":
              color = "blue";
              break;

            case "Cancelado":
              color = "red";
              break;

            default:
              color = null;
          }

          return (
            <div
              key={el}
              style={{
                backgroundColor: color,
                width: "25px",
                height: "25px",
                margin: "auto",
                marginBottom: "5px",
                borderRadius: "50%",
              }}
            />
          );
        });
      },
    },
    {
      title: "Emissão",
      key: "emission",
      dataIndex: "emission",
      align: "center",
      width: "75px",
      render: (_, order) => {
        return (
          <Button
            title="Emitir pedido"
            type="ghost"
            shape="circle"
            icon="printer"
            onClick={() => {
              notification.open({
                message: "Gerando/Atualizando o pdf do pedido de compra",
                description:
                  "O pdf do pedido de compra está sendo criado/atualizado, aguarde...",
                key: "generatingInputOrder",
                duration: 0,
                placement: "topLeft",
                icon: <Icon type="loading" />,
              });

              Parse.Cloud.run("generateInputOrder", { id: order.objectId })
                .then((response) => {
                  notification.close("generatingInputOrder");
                  notification.success({
                    message: "Pedido emitido",
                    description:
                      "Pedido emitido com sucesso, click no botão de PDF para visualizar o pedido.",
                    placement: "topLeft",
                  });
                  window.open(
                    process.env.REACT_APP_PUBLIC + "/" + response.url,
                    "_blank"
                  );
                })
                .catch(() => {
                  notification.close("generatingInputOrder");
                  notification.error({
                    message: "Erro ao gerar/atualizar o pdf.",
                    description: "Alguma coisa deu errado, tente novamente.",
                    duration: 5,
                  });
                });
            }}
          />
        );
      },
    },
    {
      title: "PDF",
      key: "pdf",
      dataIndex: "pdf",
      align: "center",
      width: "75px",
      render: (_, order) => {
        const pdfName = `${
          process.env.REACT_APP_PUBLIC
        }/pdf_input_order/pedido-compra-${order.order_code}.pdf`;

        return (
          <Button
            title="Visualizar pedido em PDF"
            type="default"
            shape="circle"
            icon="file-pdf"
            onClick={() => {
              window.open(pdfName, "_blink");
            }}
          />
        );
      },
    },
    {
      title: "NF",
      key: "nf",
      dataIndex: "nf",
      align: "center",
      width: "75px",
      render: (_, order) => {
        let pdfName = "-";
        if (_) pdfName = _.url;

        return (
          <Button
            title="Visualizar Nota Fiscal"
            type="default"
            disabled={!_}
            shape="circle"
            icon="file-pdf"
            onClick={() => {
              window.open(pdfName, "_blink");
            }}
          />
        );
      },
    },
    {
      title: "Observação",
      key: "obs",
      dataIndex: "obs",
      width: "150px",
      type: "text",
    },
  ],
  defaultCurrentPage: "last",
  tableFooter: () => {
    let statusArray = [
      "Programado",
      "Pagamento efetuado",
      "Faturado",
      "Recebido",
      "Cancelado",
    ];

    return (
      <div style={{ clear: "both", paddingBottom: 20 }}>
        {statusArray.map((el) => {
          let color = null;

          switch (el) {
            case "Programado":
              color = "yellow";
              break;

            case "Pagamento efetuado":
              color = "orange";
              break;

            case "Faturado":
              color = "green";
              break;

            case "Recebido":
              color = "blue";
              break;

            case "Cancelado":
              color = "red";
              break;

            default:
              color = null;
          }

          return (
            <div style={{ float: "left", marginRight: 30 }}>
              <div
                key={el}
                style={{
                  float: "left",
                  backgroundColor: color,
                  width: "15px",
                  height: "15px",
                  margin: "auto",
                  marginBottom: "5px",
                  marginRight: 5,
                  borderRadius: "50%",
                }}
              />{" "}
              {el}
            </div>
          );
        })}
      </div>
    );
  },
  "title-module": "Pedido de Compra",
  "title-navigation": (
    <div>
      <Icon type="file-ppt" /> Pedido de Compra
    </div>
  ),
  module: "InputOrder",
  "router-base": "/panel/pedido-compra",
};
