import React from 'react';
import { Icon } from 'antd';
import OrderMapTable from './entities/order-map-table';

export default {
    "fields": [
        [
            {
                title: "Nome",
                key: "order_map_name",
                type: "text",
                colSize: 8,
                "element-attr": { required: true }
            },
            {
                title: "Descrição",
                key: "order_map_description",
                type: "text",
                colSize: 16,
                "element-attr": { required: false }
            },
        ],
        [
            {
                key: 'order_map_render',
                type: 'render',
                render: (_, form) => {
                    return (
                        <OrderMapTable
                            orderMap={_.formRef.order_map}
                            updateOrderMap={order_map => {
                                _.formRef.order_map = order_map
                                form.state.invalidForm = false
                                form.forceUpdate()
                            }}
                        />
                    )
                }
            }
        ],
        [{ key: 'order_map' }],
    ],
    "submit": {
        "collection": "OrderMap",
    },
    "schema": [
        {
            "title": "Nome",
            "key": "order_map_name",
            "dataIndex": "order_map_name",
            "type": "text",
        },
    ],
    "title-module": "Mapa de Pedidos",
    "title-navigation": <div><Icon type="table" /> Mapa de Pedidos</div>,
    "module": "OrderMap",
    "router-base": "/panel/order-map"
}