const pointerFields = {
    tech_assistance: 'TeachAssistance',
    instalation: 'Instalation',
    tech_manager: 'TechManager',
    mobility_work: 'MobilityWork',
    reference: 'Proposal',
    work_stage: 'WorkStage',
    prevision_buy: 'PrevisionBuy',
    type_work: 'TypeWork',
    team_mob: 'TeamMob',
    sales_channel: 'SalesChannel'
}

export default pointerFields