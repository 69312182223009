import React from 'react';
import { Icon } from 'antd';

export default {
    "fields": [
        [
            {
                title: 'Assunto',
                key: 'subject',
                type: 'text',
                colSize: 24,
                beforeSave: async (Form, ParseObject, node) => {
                    await ParseObject.set('type', 'measuring-equipament')
                    return true;
                }
            },
        ],
        [
            {
                key: 'files',
                type: 'files'
            }
        ],
    ],
    "submit": {
        "collection": "IndustrialMaintenenceDocs"
    },
    "schema": [
        {
            "title": "Assunto",
            "key": "subject",
            "dataIndex": "subject",
            "type": "text"
        },
    ],
    "title-module": "Equipamentos de medição",
    "title-navigation": <div><Icon type="dashboard" /> Equipamentos de medição</div>,
    "module": "IndustrialMaintenenceDocs",
    "router-base": "/panel/equipamentos-medicao",
    'filterList': () => {
        return {
            'field': 'type',
            'value': 'measuring-equipament',
        }
    }
}

