import { Modal } from "antd";
import Parse from 'parse'
import { findAll, findById, findWhereMultiple } from "../../../utils/db_functions"
import { getTotalCost } from "../../supplies/inputs/inputs"
import { calculateManHour } from "../fixed-cost/man-hour/man-hour"
import { getTotalFormule, getUnityFormule } from "../../../utils/unity"
import { roundTo } from "../../../utils/mathFunctions";

const getValuesToConsumption = (valueObj, input) => {
    return {
        amount: valueObj.amount_component || 0,
        width: valueObj.width_component || 1,
        thickness: valueObj.thickness_component || 1,
        length: valueObj.length_component || 1,
        convert: input.convert || 1,
        totalCost: getTotalCost(input) || 1,
        unity: valueObj.unity,
        spec: valueObj.specification
    }
}

const calculateUnitCost = (input, valueObj, convertionFormulas = null) => {

    let finalValue = 0;
    if (input) {
        let values = getValuesToConsumption(valueObj, input);
        let unity = valueObj.unity;
        let r = getTotalFormule(input.measure, unity)({ ...values, totalConsumo: getUnityFormule(input.measure, unity, convertionFormulas)(values) });
        finalValue = (r === Infinity) ? 0 : r;
    }

    return finalValue;
}

const calculateSubTotalCost = (rows, inputs, convertionFormulas = null) => {
    return (rows || []).reduce((prev, current) => {
        const input = inputs.find((input) => input.id === current.specification)
        return prev += calculateUnitCost(input && input.toJSON(), current, convertionFormulas);
    }, 0);
}

const calculateTotalCost = (compositions, inputs, convertionFormulas = null) => {
    return (compositions || []).reduce((prev, current) => {
        return prev + calculateSubTotalCost(current.rows, inputs, convertionFormulas);
    }, 0);
}

const calculateProductionCost = ({ descriptions, size_hh, ref_unity_value_hh }, inputs, convertionFormulas = null) => {
    const manHourValue = ref_unity_value_hh

    return calculateTotalCost(descriptions, inputs, convertionFormulas) + (manHourValue * size_hh);
}

const calculateTotal = (component, marginZero = false, inputs, convertionFormulas = null) => {

    const prodCost = calculateProductionCost(component, inputs, convertionFormulas);
    let taxes = component.pis_conf + component.custo_comer + (marginZero ? 0 : component.margin_contri);

    return roundTo((prodCost / (1 - (taxes / 100))), 2);
}

const calculateLocalManHour = async (manHour) => {

    const fixedCost = manHour.get('fixed_cost')
    const manHourValue = parseFloat(((await calculateManHour(fixedCost && fixedCost.id) || 0) * (manHour.get('multiplier') || 1)).toFixed(2))
    return manHourValue

}

const calculateTotalManHour = async (manHourValue, qtd) => {

    const manHourTotal = parseFloat((manHourValue * (qtd || 0)).toFixed(2))
    return manHourTotal

}

const getComponentTotalPrice = async (componentId, inputs, convertionFormulas = null, componentKit = null, module = 'ComponenteKit') => {
    let componentParseObject = componentKit || await findById(module, componentId, ['unity_value_hh'])
    if (!componentParseObject) return 0
    componentParseObject.set('ref_unity_value_hh', componentParseObject.get('unity_value_hh').get('calculatedTotal'))
    const component = componentParseObject.toJSON();

    let total = calculateTotal(component, false, inputs, convertionFormulas);

    if (component.vinculated_component) {
        const vinculatedComponentParseObject = await findById(module, typeof component.vinculated_component === 'object' ? component.vinculated_component.objectId : component.vinculated_component);
        const vinculatedPrice = await calculateTotal(vinculatedComponentParseObject.toJSON(), false, inputs);
        total = vinculatedPrice * (component.fact_multiple || 1);
    }

    return roundTo(total, 2);
}

const getInputs = async (updateInputDateId) => {
    let inputs = await findAll('Input', 30000, null, null, 'code')
    // getLastUpdatedDate(updateInputDateId, inputs)
    return inputs;
}

const getLastUpdatedDate = (updateInputDateId, inputs) => {
    let updateInputDate = inputs[0].get('updated_data')[0]['date_of_update_data'];
    for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
        const element = inputs[inputIndex];
        const dateOfUpdated = element.get('updated_data') && element.get('updated_data')[0] && element.get('updated_data')[0]['date_of_update_data']
        if (
            (element.get('input_destiny') === 'ind_mat' && element.get('is_price_base')) &&
            ((dateOfUpdated && new Date(dateOfUpdated).getTime()) > new Date(updateInputDate).getTime())
        ) updateInputDate = element.get('updated_data')[0]['date_of_update_data'];
    }
    document.getElementById(updateInputDateId).innerHTML = updateInputDate.toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' })
    document.getElementById('seeDateUpdateInputDateId').innerHTML = ''
}

const updateInputValues = (inputs) => {
    Modal.confirm({
        title: 'Tem certeza que deseja atualizar os preços?',
        content: 'Essa ação pode demorar um pouco e não poderá ser desfeita.',
        cancelText: "Cancelar",
        onOk: async () => {
            let localInputs = [...inputs];
            let date = new Date();
            for (let index = 0; index < localInputs.length; index++) {
                let input = localInputs[index];
                if (input.get('input_destiny') === 'ind_mat' && input.get('is_price_base')) {
                    let input_json = input.toJSON();
                    input_json.date_of_update_data = date;
                    await input.set('updated_data', [input_json]);
                }
            }
            Parse.Object.saveAll(localInputs).then(() => window.location.reload());
        },
    });
}

const getManHours = async (isInstalation = false) => {
    let manHours = isInstalation ? await findWhereMultiple('ManHour', [{ 'field': 'description', 'value': 'SERVICE', 'op': 'like' }]) : await findAll('ManHour')
    for (let manHourIndex = 0; manHourIndex < manHours.length; manHourIndex++) {
        const element = manHours[manHourIndex];
        const calculatedTotal = await calculateLocalManHour(element);
        manHours[manHourIndex].set('calculatedTotal', calculatedTotal)
    }
    return manHours
}

const formTabs = (generalInfoFields, inputCompositionFields, priceCompositionFields, finalPricingFields) => {
    let formTabsArray = []

    if (generalInfoFields) formTabsArray.push({ title: 'Informações gerais', fields: generalInfoFields })
    if (inputCompositionFields) formTabsArray.push({ title: 'Composição de insumos', fields: inputCompositionFields })
    if (priceCompositionFields) formTabsArray.push({ title: 'Composição de preço', fields: priceCompositionFields })
    if (finalPricingFields) formTabsArray.push({ title: 'Preço final', fields: finalPricingFields })

    return formTabsArray
}

const getConvertionFormulas = async () => {
    const formulas = await findAll('ConvertionConfigs')
    return formulas;
}

export {
    getValuesToConsumption,
    calculateUnitCost,
    calculateSubTotalCost,
    calculateTotalCost,
    calculateProductionCost,
    calculateTotal,
    calculateLocalManHour,
    calculateTotalManHour,
    getComponentTotalPrice,
    getInputs,
    getLastUpdatedDate,
    updateInputValues,
    getManHours,
    formTabs,
    getConvertionFormulas,
}