import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd'
import React, { Component } from 'react'
import { CreateMask } from '../../../../utils/mask'

export default class OrderGeneratorPortionForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentPortion: null,
            paymentRef: null,
            paymentDueDate: null,
            paymentValue: null,
            paymentBilling: null,
            paymentCnpj: null,
        }
    }

    componentDidMount = async () => {
        if (this.props.form) {
            this.setState({
                paymentPortion: this.props.form.paymentPortion,
                paymentRef: this.props.form.paymentRef,
                paymentDueDate: this.props.form.paymentDueDate,
                paymentValue: this.props.form.paymentValue,
                paymentBilling: this.props.form.paymentBilling,
                paymentCnpj: this.props.form.paymentCnpj,
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevState) !== JSON.stringify(this.state))
            this.props.updatePortionForm(this.state)
    }

    render() {
        return (
            <div>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label='Parcela'>
                            <InputNumber
                                style={{ width: '100%' }}
                                placeholder='Parcela'
                                step={1}
                                value={this.state.paymentPortion}
                                onChange={e => this.setState({ paymentPortion: e })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label='Referência'>
                            <Select
                                style={{ width: '100%' }}
                                placeholder='Referência'
                                value={this.state.paymentRef}
                                onChange={e => this.setState({ paymentRef: e })}
                            >
                                {[
                                    { label: 'Selecione', value: '' },
                                    { label: 'Projeto', value: 'Projeto' },
                                    { label: 'Matéria Prima', value: 'Matéria Prima' },
                                    { label: 'Industrialização', value: 'Industrialização' },
                                    { label: 'Instalação', value: 'Instalação' },
                                ].map((paymentRef, index) => {
                                    return <Select.Option value={paymentRef['value']} key={`paymentRef${index}`}>{paymentRef['label']}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label='Vencimento'>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder='Vencimento'
                                value={this.state.paymentDueDate}
                                onChange={e => this.setState({ paymentDueDate: e })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label='Valor'>
                            <InputNumber
                                style={{ width: '100%' }}
                                placeholder='Valor'
                                value={this.state.paymentValue}
                                formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                parser={value => {
                                    let decimals = value.split(',')[1]
                                    if (decimals && decimals.length > 2) {
                                        decimals = decimals.substring(0, 2)
                                        value = `${value.split(',')[0]},${decimals}`
                                    }
                                    return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
                                }}
                                onChange={e => this.setState({ paymentValue: e })}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label='Faturamento'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='Faturamento'
                                value={this.state.paymentBilling}
                                onChange={e => this.setState({ paymentBilling: e.target.value })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label='CNPJ'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='CNPJ'
                                value={CreateMask('xx.xxx.xxx/xxxx-xx', this.state.paymentCnpj, 18, true)}
                                onChange={e => this.setState({ paymentCnpj: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        )
    }

}