import { Modal, Select, Spin } from "antd";
import React, { Component } from "react";
import { save } from "../../../../utils/db_functions";

export default class ProposalStatusClass extends Component {

    constructor(props) {
        super(props)
        this.state = {
            proposal: this.props.proposal,
            loading: false,
            statusModal: false,
            status: this.props.proposal.proposal_management_status,
            statusColor: null,
        }
    }

    componentDidMount() {
        if (this.props.orderVersionProposal) {
            this.setStatusOrderColor(this.props.proposal, this.props.orderVersionProposal)
        } else this.setStatusColor()
    }

    setStatusColor() {
        this.setState({ statusColor: 'orange' })

        if (
            (this.state.proposal.orderGenerated && !this.state.status) ||
            this.state.status === 'PedidoGerado'
        )
            this.setState({
                statusColor: 'blue',
                status: 'PedidoGerado',
            })

        if (
            this.state.proposal.proposal_management_status === 'Cancelada' ||
            this.state.status === 'Cancelada'
        )
            this.setState({
                statusColor: 'red',
                status: 'Cancelada',
            })

        if (
            this.state.proposal.proposal_management_status === 'Substituida' ||
            this.state.status === 'Substituida'
        )
            this.setState({
                statusColor: 'black',
                status: 'Substituida',
            })
    }

    async updateStatus(proposalId, status) {
        this.setState({ loading: true })

        let proposalToUpdate = { proposal_management_status: status }
        if (status !== 'Aprovada' && status !== 'PedidoGerado') proposalToUpdate['orderGenerated'] = false

        await save('Proposal', proposalToUpdate, proposalId)

        this.setStatusColor()
        this.setState({ loading: false, statusModal: false })
    }

    setStatusOrderColor(proposal, orderVersionProposal) {
        const orderVersion = parseInt(orderVersionProposal.proposal_code.slice(-2), 10)
        const proposalVersion = parseInt(proposal.proposal_code.slice(-2), 10)
        let color = 'blue'

        if (proposalVersion < orderVersion) color = 'lightblue'
        else if (proposalVersion > orderVersion) color = 'darkblue'

        this.setState({ statusColor: color })
    }

    render() {


        return (
            <div>
                <div
                    onClick={() => this.setState({ statusModal: true })}
                    style={{
                        backgroundColor: this.state.statusColor,
                        width: '20px', height: '20px',
                        margin: 'auto', borderRadius: '50%',
                        cursor: 'pointer',
                    }}
                />
                <Modal
                    title={`Atualizar status da proposta ${this.state.proposal.proposal_code}`}
                    visible={this.state.statusModal}
                    onOk={() => this.updateStatus(this.state.proposal.objectId, this.state.status)}
                    onCancel={() => this.setState({ statusModal: false })}
                >
                    <Spin spinning={this.state.loading}>
                        <Select
                            placeholder='Status'
                            onChange={e => {
                                this.setState({
                                    status: e,
                                    proposal: { ...this.state.proposal, proposal_management_status: e }
                                })
                            }}
                            value={this.state.status}
                            style={{ width: '100%' }}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            <Select.Option value='Ativa'>Ativa</Select.Option>
                            <Select.Option value='PedidoGerado'>Pedido gerado</Select.Option>
                            <Select.Option value='Negociação'>Negociação Trimestre</Select.Option>
                            <Select.Option value='NegociaçãoSemestre'>Negociação Semestre</Select.Option>
                            <Select.Option value='EspecificaçãoBIM'>Especificação BIM</Select.Option>
                            <Select.Option value='Concorrência'>Concorrência de obra</Select.Option>
                            <Select.Option value='Aprovada'>Aprovada</Select.Option>
                            <Select.Option value='Parada'>Parada</Select.Option>
                            <Select.Option value='Cancelada'>Cancelada</Select.Option>
                            <Select.Option value='Substituida'>Substituida</Select.Option>
                        </Select>
                    </Spin>
                </Modal>
            </div>
        );
    }

}