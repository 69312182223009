import React from "react";
import { Icon, Button, notification, Popover, Dropdown, Menu } from "antd";
import Parse from "parse";
import Auth from "../../../auth/Auth";
import { roundTo } from "../../../utils/mathFunctions";
import generalInfoFields from "./components/general-info-fields";
import invoicingFields from "./components/invoicing-fields";
import deliveryDeadlinesFields from "./components/delivery-deadlines-fields";
import contractFields from "./components/contract-fields";
import {
  findAll,
  findById,
  getCurrentUser,
  save,
} from "../../../utils/db_functions";
import contactsFields from "./components/contacts-fields";
import ButtonGroup from "antd/lib/button/button-group";
import TechDocsStatusModal from "./entities/tech-docs-status-modal";

// Auth
let auth = null;
let discountLimit = 1000000;
let invoicingCompanies = {
  "Matéria Prima": [],
  Projeto: [],
  Industrialização: [],
  Instalação: [],
};

let formTabs = [
  {
    title: "Informações gerais",
    fields: generalInfoFields(auth, discountLimit),
  },
  {
    title: "Faturamento",
    fields: invoicingFields(invoicingCompanies),
  },
  {
    title: "Cronograma",
    fields: deliveryDeadlinesFields(),
  },
  {
    title: "Contatos",
    fields: contactsFields(),
  },
  {
    title: "Contrato",
    fields: contractFields(),
  },
];

let OrderComponent = {
  formTabs: formTabs,
  data: {
    totalDiscountedValue: 0,
    proposalLink: "",
  },
  FormHasMounted: async (_) => {
    const user = await getCurrentUser();
    auth = new Auth(user);
    auth["_financialDiscountPermission"] = false;
    auth.init(async () => {
      // Get discounts confits
      await getDiscountLimit();
      _.forceUpdate();
    });
  },
  onClickActionEdit: () => {
    OrderComponent.data = {
      totalDiscountedValue: 0,
      proposalLink: "",
    };
  },
  ListWillMount: async (_) => {
    const inputProviders = await findAll("InputProvider", 1000);
    invoicingCompanies["Matéria Prima"] = inputProviders.map(
      (inputProvider) => {
        return {
          label: `${inputProvider.get("name")} - ${inputProvider.get("cnpj")}`,
          value: inputProvider.id,
        };
      }
    );

    const billingCompanies = await findAll("BillingCompany");
    invoicingCompanies["Projeto"] = billingCompanies.map((billingCompany) => {
      return {
        label: `${billingCompany.get("name")} - ${billingCompany.get("cnpj")}`,
        value: billingCompany.id,
      };
    });
    invoicingCompanies["Industrialização"] = invoicingCompanies["Projeto"];
    invoicingCompanies["Instalação"] = invoicingCompanies["Projeto"];

    _.forceUpdate();
  },
  submit: {
    collection: "Order",
  },
  sortBy: "order_code",
  defaultCurrentPage: "last",
  schema: [
    {
      title: "Pedido",
      key: "order_code",
      dataIndex: "order_code",
      type: "text",
      width: "150px",
    },
    {
      title: "Cliente",
      key: "proposal",
      dataIndex: "proposal",
      type: "text",
      render: (proposal) => JSON.parse(proposal).name_client,
    },
    {
      title: "Obra",
      key: "work",
      dataIndex: "proposal",
      type: "render",
      render: (proposal) => JSON.parse(proposal).name_work,
    },
    {
      title: "Documentos",
      key: "docs_actions",
      dataIndex: "docs_actions",
      type: "render",
      align: "center",
      width: "130px",
      render: (_, order) => {
        const proposal = JSON.parse(order.proposal);

        const dropdownPDFsMenu = (
          <Menu>
            <Menu.Item
              onClick={() => {
                let pdfName = `${process.env.REACT_APP_PUBLIC
                  }/pdf_contract/contrato-${proposal.name_client.replace(
                    "/",
                    ""
                  )}-${order.order_code}-Multidoor.pdf`.replace(/\s/g, "-");

                window.open(pdfName, "_blank");
              }}
            >
              Contrato
            </Menu.Item>

            <Menu.Item
              onClick={() => {
                // Alteração no padrão do nome do arquivo conforme solicitado pelo cliente
                let lastUpdatedDate = new Date(proposal.updatedAt);
                let changeNameDate = new Date(2020, 8, 19);
                let pdfName = `${process.env.REACT_APP_PUBLIC
                  }/pdf_input_report/${(proposal.name_client || "").replace(
                    "/",
                    ""
                  )}-${proposal.cod_cliente}-${proposal.proposal_code
                  }.pdf`.replace(/\s/g, "-");
                if (lastUpdatedDate > changeNameDate)
                  pdfName = `${process.env.REACT_APP_PUBLIC
                    }/pdf_input_report/${(proposal.name_client || "").replace(
                      "/",
                      ""
                    )}-${proposal.proposal_code}-Multidoor.pdf`.replace(
                      /\s/g,
                      "-"
                    );

                window.open(pdfName, "_blank");
              }}
            >
              Relatório de insumos
            </Menu.Item>
          </Menu>
        );

        return (
          <ButtonGroup>
            <Popover content="Pedido">
              <Button
                type="primary"
                icon="file-pdf"
                onClick={() => {
                  let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_order/${(
                    proposal.name_client || ""
                  ).replace("/", "")}-${order.order_code
                    }-Multidoor.pdf`.replace(/\s/g, "-");

                  window.open(
                    pdfName,
                    "_blink"
                  );
                }}
              />
            </Popover>

            <Popover content="Visualizar pasta do cliente">
              <Button
                icon="folder-open"
                onClick={() => {
                  window.open(`/pasta-cliente/${order.objectId}`, "_blink");
                }}
              />
            </Popover>

            <Popover content="PDFs">
              <Dropdown
                overlay={dropdownPDFsMenu}
                placement="bottomCenter"
                trigger={["click"]}
              >
                <Button icon="container" />
              </Dropdown>
            </Popover>
          </ButtonGroup>
        );
      },
    },
    {
      title: "Gerar / Atualizar PDF",
      key: "objectId",
      dataIndex: "objectId",
      align: "center",
      width: "80px",
      render: (id, row) => {
        return (
          <Button
            title="Gerar/Atualizar Pedido em PDF"
            disabled={
              !row.order_date || !(row.installments && row.installments.length)
            }
            type="primary"
            shape="circle"
            icon="file-protect"
            onClick={async () => {
              notification.open({
                message: "Gerando/Atualizando o pdf do pedido",
                description:
                  "O pdf do pedido está sendo criado/atualizado, aguarde...",
                key: "generatingOrder",
                duration: 0,
                placement: "topLeft",
                icon: <Icon type="loading" />,
              });

              if (row.proposalRef) {
                let proposalRefInfo = await findById(
                  "Proposal",
                  row.proposalRef.objectId,
                  ["sales_channel"]
                );
                proposalRefInfo.set("key", row.proposalRef.objectId);
                const proposalRefInfoJson = proposalRefInfo.toJSON();

                const orderToUpdate = {
                  proposal: JSON.stringify(proposalRefInfoJson),
                  payment_conditions: proposalRefInfoJson.payment_conditions,
                  discount: proposalRefInfoJson.discount,
                  signal: proposalRefInfoJson.signal,
                  installmentsNum: proposalRefInfoJson.installmentsNum,
                  installmentsPer: proposalRefInfoJson.installmentsPer,
                  comercialConditions: proposalRefInfoJson.sales_channel.comercialConditions.replace(
                    "a Proposta",
                    "o Pedido"
                  ),
                  technicalConditions: proposalRefInfoJson.sales_channel.technicalConditions.replace(
                    "a Proposta",
                    "o Pedido"
                  ),
                  conditions: proposalRefInfoJson.sales_channel.conditions
                    .replace("esta Proposta", "este Pedido")
                    .replace("a Proposta", "o Pedido"),
                  total: roundTo(proposalRefInfoJson.final_value, 2),
                  notificationTime: new Date().getTime(),
                };
                await save("Order", orderToUpdate, id);
              }

              Parse.Cloud.run("generateOrder", { id: id })
                .then(async (response) => {
                  notification.close("generatingOrder");
                  notification.success({
                    message: "Pedido gerado/atualizado com sucesso.",
                    description:
                      "Acesse o pdf do pedido clicando no ícone de pdf.",
                    placement: "topLeft",
                  });
                  window.open(
                    process.env.REACT_APP_PUBLIC + "/" + response.url,
                    "_blank"
                  );

                  // Enable pdf button
                  await save(
                    "Order",
                    { pdf: true, emissionDate: Date.now() },
                    id
                  );
                })
                .catch(() => {
                  notification.close("generatingOrder");
                  notification.error({
                    message: "Erro ao gerar/atualizar o pdf.",
                    description: "Alguma coisa deu errado, tente novamente.",
                    duration: 5,
                  });
                });
            }}
          />
        );
      },
    },
    {
      title: "Gerar Contrato",
      key: "generate_contract",
      dataIndex: "generate_contract",
      align: "center",
      width: "80px",
      render: (_, row) => {
        return (
          <Button
            title="Gerar PDF do Contrato"
            disabled={!row.order_date}
            type="primary"
            shape="circle"
            icon="file-protect"
            onClick={() => {
              notification.open({
                message: "Gerando/Atualizando o pdf do contrato",
                description:
                  "O pdf do contrato está sendo criado/atualizado, aguarde...",
                key: "generatingContract",
                duration: 0,
                placement: "topLeft",
                icon: <Icon type="loading" />,
              });

              Parse.Cloud.run("generateContract", { id: row.objectId })
                .then((response) => {
                  notification.close("generatingContract");
                  notification.success({
                    message: "Contrato gerado/atualizado com sucesso.",
                    description:
                      "Acesse o pdf do contrato clicando no ícone de pdf.",
                    placement: "topLeft",
                  });

                  notification.success({
                    message: "Contrato gerado com sucesso.",
                    description: "Clique no ícone do pdf para consultá-lo.",
                  });
                  window.open(
                    process.env.REACT_APP_PUBLIC + "/" + response.url,
                    "_blank"
                  );
                })
                .catch(() => {
                  notification.close("generatingContract");
                  notification.error({
                    message: "Erro ao gerar o contrato",
                    description: "Alguma coisa deu errado, tente novamente.",
                    duration: 5,
                  });
                });
            }}
          />
        );
      },
    },
    {
      title: "PTC",
      key: "techDocsStatus",
      dataIndex: "techDocsStatus",
      align: "center",
      width: "50px",
      render: (techDocsStatus, row) => {
        return (
          <TechDocsStatusModal
            techDocsStatus={techDocsStatus && techDocsStatus.ptc}
            orderId={row.objectId}
            techDocType="ptc"
          />
        );
      },
    },
    {
      title: "PE",
      key: "techDocsStatus",
      dataIndex: "techDocsStatus",
      align: "center",
      width: "50px",
      render: (techDocsStatus, row) => {
        return (
          <TechDocsStatusModal
            techDocsStatus={techDocsStatus && techDocsStatus.pe}
            orderId={row.objectId}
            techDocType="pe"
          />
        );
      },
    },
    {
      title: "Prod",
      key: "techDocsStatus",
      dataIndex: "techDocsStatus",
      align: "center",
      width: "50px",
      render: (techDocsStatus, row) => {
        return (
          <TechDocsStatusModal
            techDocsStatus={techDocsStatus && techDocsStatus.prod}
            orderId={row.objectId}
            techDocType="prod"
          />
        );
      },
    },
    {
      title: "Checklist",
      key: "techDocsStatus",
      dataIndex: "techDocsStatus",
      align: "center",
      //   width: "50px",
      render: (techDocsStatus, row) => {
        return (
          <TechDocsStatusModal
            techDocsStatus={techDocsStatus && techDocsStatus.checklist}
            orderId={row.objectId}
            techDocType="checklist"
          />
        );
      },
    },
    {
      title: "Satisfação",
      key: "techDocsStatus",
      dataIndex: "techDocsStatus",
      align: "center",
      //   width: "50px",
      render: (techDocsStatus, row) => {
        return (
          <TechDocsStatusModal
            techDocsStatus={techDocsStatus && techDocsStatus.survey}
            orderId={row.objectId}
            techDocType="survey"
          />
        );
      },
    },
    {
      title: "Pedido com pendência?",
      key: "order_status",
      dataIndex: "order_status",
      align: "center",
      render: (_, row) => {
        let color = "green";

        if (row.emissionDate) {
          const now = Date.now();
          const difference = Math.abs(now - row.emissionDate);
          const days = difference / (1000 * 3600 * 24);
          if (
            days >= 7 &&
            (!row.dataSourcePaymentConditions ||
              !row.dataSourcePaymentConditions.length) &&
            (!row.dataSourceFinancialSchedule ||
              !row.dataSourceFinancialSchedule.length)
          )
            color = "red";
        }

        return (
          <div
            style={{
              backgroundColor: color,
              width: "20px",
              height: "20px",
              margin: "auto",
              borderRadius: "50%",
            }}
          />
        );
      },
    },
  ],
  "title-module": "Gerar Pedido",
  "title-navigation": (
    <div>
      <Icon type="book" /> Pedidos
    </div>
  ),
  module: "Order",
  "router-base": "/panel/pedido",
};

/**
 * Get discount limit.
 */
const getDiscountLimit = async () => {
  auth["_financialDiscountPermission"] = false;
  // Get discounts confits
  auth["_financialDiscountPermission"] = auth.hasAction([
    "*",
    "ProposalFinancialDiscount",
  ]);
  auth["_groups"] = auth.ACL.groups;

  let discountsConfigs = await findAll("DiscountsConfigs", null, ["group"]);

  // Get discount limits
  let discountsLimits = auth["_groups"].map((el) => {
    let elDiscountLimit = discountsConfigs.find((child_el) => {
      return (child_el.get("group") && child_el.get("group").id) === el.id;
    });
    return elDiscountLimit && elDiscountLimit.get("financial_limit");
  });
  if (discountsLimits.length > 0) {
    let localDiscountLimit = discountsLimits.reduce((a, b) => {
      return Math.max(a, b);
    });
    discountLimit = localDiscountLimit;
  }

  // Set discount configs
  formTabs.forEach((tab, tabIndex) => {
    tab.fields.forEach((fieldParent, fieldParentIndex) => {
      fieldParent.forEach((fieldChild, fieldChildIndex) => {
        if (fieldChild["key"] === "commercial_discount") {
          formTabs[tabIndex].fields[fieldParentIndex][fieldChildIndex][
            "element-attr"
          ]["disabled"] = auth ? !auth._financialDiscountPermission : true;
          formTabs[tabIndex].fields[fieldParentIndex][fieldChildIndex][
            "element-attr"
          ]["max"] = discountLimit;
        }
      });
    });
  });
};

export default OrderComponent;
