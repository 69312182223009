import React, { Component } from 'react';
import { Card, Col, Divider, Form, Input, Row, Button, Table, Modal, message, Popover } from 'antd';
import { findById, save } from '../../../utils/db_functions';
import { CreatePhoneMask } from '../../../utils/mask';
import ButtonGroup from 'antd/lib/button/button-group';
import "./qualification-survey.css"

export default class CustomerProposalRequest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            surveyId: null,
            survey: null,

            form: {},

            addDoorModal: false,
            doorInfo: null,
            isDoorToUpdateIndex: null,
            requiredDoorInfoFields: [
                'code', 'qtd', 'model',
                'dimensions', 'tipology', 'thickness',
                'finishing_sheet', 'finishing_marco'
            ],

            requiredFields: [
                'customer',
                'cnpj_cpf',
                'ie_rg',
                'address',
                'city_state',
                'responsible',
                'phone',
                'city_state_work',
            ],

            loadingButton: false,
        }
    }

    componentDidMount = async () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const surveyId = urlParams.get('surveyId')
        if (!surveyId) window.location.href = `https://minhamultidoor.com.br`
        else {
            const survey = await findById('QualificationSurvey', surveyId)
            this.setState({ surveyId, survey, form: survey.get('proposalRequest') || {} })
        }
    }

    addDoor = () => {
        let formIsValid = true;
        for (let requiredFieldsIndex = 0; requiredFieldsIndex < this.state.requiredDoorInfoFields.length; requiredFieldsIndex++) {
            const field = this.state.requiredDoorInfoFields[requiredFieldsIndex];
            if (!this.state.doorInfo) formIsValid = false
            else if (!this.state.doorInfo[field]) formIsValid = false
        }

        if (!formIsValid) {
            message.error('Todos os campos são obrigatórios')
        } else {
            let doorTable = this.state.form.doorTable || []
            if (this.state.isDoorToUpdateIndex || this.state.isDoorToUpdateIndex === 0) {
                doorTable[this.state.isDoorToUpdateIndex] = this.state.doorInfo
            } else {
                doorTable.push(this.state.doorInfo)
            }

            this.setState({ form: { ...this.state.form, doorTable }, addDoorModal: false, doorInfo: null, isDoorToUpdateIndex: null })
        }
    }

    removeDoor = (index) => {
        let doorTable = this.state.form.doorTable

        doorTable.splice(index, 1)

        this.setState({ form: { ...this.state.form, doorTable } })
    }

    saveProposalRequest = async () => {
        this.setState({ loadingButton: true })
        let formIsValid = true;
        for (let requiredFieldsIndex = 0; requiredFieldsIndex < this.state.requiredFields.length; requiredFieldsIndex++) {
            const field = this.state.requiredFields[requiredFieldsIndex];
            if (!this.state.form) formIsValid = false
            else if (!this.state.form[field]) formIsValid = false
        }

        if (!formIsValid) {
            message.error('Preencha todos os campos obrigatórios')
        } else {
            await save('QualificationSurvey', { proposalRequest: this.state.form }, this.state.surveyId)

            Modal.confirm({
                title: 'Obrigado!',
                content: 'Agradecemos pelo cadastro!',
                cancelText: 'Fechar',
                iconType: 'like',
            })
        }

        this.setState({ loadingButton: false })
    }

    render() {

        const columns = [
            {
                title: 'Cód',
                dataIndex: 'code',
                key: 'code',
                fixed: 'left',
            },
            {
                title: 'Qtd',
                dataIndex: 'qtd',
                key: 'qtd',
            },
            {
                title: 'Modelo',
                dataIndex: 'model',
                key: 'model',
            },
            {
                title: 'Dimensões',
                dataIndex: 'dimensions',
                key: 'dimensions',
            },
            {
                title: 'Tipologia',
                dataIndex: 'tipology',
                key: 'tipology',
            },
            {
                title: 'Espessura parede',
                dataIndex: 'thickness',
                key: 'thickness',
                align: 'center',
            },
            {
                title: 'Acabamento folha',
                dataIndex: 'finishing_sheet',
                key: 'finishing_sheet',
            },
            {
                title: 'Acabamento Marco/Alizar',
                dataIndex: 'finishing_marco',
                key: 'finishing_marco',
            },
            {
                title: 'Ações',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record, index) => {
                    return (
                        <ButtonGroup>
                            <Popover content='Editar'>
                                <Button
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({
                                            addDoorModal: true,
                                            doorInfo: record,
                                            isDoorToUpdateIndex: index,
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Deletar'>
                                <Button
                                    icon='delete'
                                    onClick={() => {
                                        let _this = this
                                        Modal.confirm({
                                            iconType: 'exclamation-circle',
                                            title: 'Tem certeza que deseja exlcuir essa porta?',
                                            content: 'Essa ação não poderá ser desfeita',
                                            onOk() {
                                                _this.removeDoor(index)
                                            },
                                            okText: 'Sim',
                                            cancelText: 'Não, deixa pra lá'
                                        })
                                    }}
                                />
                            </Popover>
                        </ButtonGroup>
                    )
                }
            },
        ]

        return (
            <div className="app-login" style={{ padding: '5vh', position: 'relative', height: '100vh' }}>

                <div style={{ maxHeight: '100%' }}>

                    <Card style={{ width: '100%', maxWidth: '1000px', margin: 'auto', marginBottom: '50px', height: '90vh', overflowY: 'auto' }} id='checklist-card'>

                        <h2>Cadastro para atendimento</h2>
                        <h4>Preencha os dados abaixo para dar continuidade à sua solicitação</h4>
                        Multidoor 4.0
                        <Divider />

                        <div>

                            <div>
                                <div className='label'>Obra</div>
                                <div>{this.state.survey && this.state.survey.get('work')}</div>

                                <Divider />

                                <Form.Item label="Nome" required>
                                    <Input
                                        placeholder='Informe o seu nome ou Razão Social'
                                        value={this.state.form.customer}
                                        onChange={e => this.setState({ form: { ...this.state.form, customer: e.target.value } })}
                                    />
                                </Form.Item>

                                <Row gutter={24}>

                                    <Col span={12}>
                                        <Form.Item label='CNPJ/CPF' required>
                                            <Input
                                                placeholder='Informe o CNPJ ou o CPF'
                                                value={this.state.form.cnpj_cpf}
                                                onChange={e => this.setState({ form: { ...this.state.form, cnpj_cpf: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label='Inscrição Estadual/RG' required>
                                            <Input
                                                placeholder='Informe a Inscrição Estadual ou o RG'
                                                value={this.state.form.ie_rg}
                                                onChange={e => this.setState({ form: { ...this.state.form, ie_rg: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={24}>

                                    <Col span={16}>
                                        <Form.Item label='Endereço' required>
                                            <Input
                                                placeholder='Informe o Endereço'
                                                value={this.state.form.address}
                                                onChange={e => this.setState({ form: { ...this.state.form, address: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label='Cidade/Estado' required>
                                            <Input
                                                placeholder='Informe a cidade e o estado'
                                                value={this.state.form.city_state}
                                                onChange={e => this.setState({ form: { ...this.state.form, city_state: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={24}>

                                    <Col span={8}>
                                        <Form.Item label='Responsável' required>
                                            <Input
                                                placeholder='Informe o nome do contato responsável'
                                                value={this.state.form.responsible}
                                                onChange={e => this.setState({ form: { ...this.state.form, responsible: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label='Contato' required>
                                            <Input
                                                placeholder='Informe o contato'
                                                value={CreatePhoneMask(this.state.form.phone)}
                                                onChange={e => this.setState({ form: { ...this.state.form, phone: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label='Cidade/Estado da obra' required>
                                            <Input
                                                placeholder='Informe a cidade e o estado da obra'
                                                value={this.state.form.city_state_work}
                                                onChange={e => this.setState({ form: { ...this.state.form, city_state_work: e.target.value } })}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </div>

                            <div className='label qualification-survey-lgpd-box'>
                                Os dados pessoais coletados neste formulário são para fins de
                                atendimento pela Multidoor, em retorno à solicitação efetuada
                                pelo titular dos dados, nos termos do inciso V do art. 7° da
                                LGPD
                            </div>

                            <Divider />

                            <h4>Quadro geral de portas</h4>
                            <div className='label' style={{ color: '#5a5a5a', marginTop: '0px' }}>
                                Seu preenchimento agiliza a elaboração do orçamento
                            </div><br />

                            <Table
                                dataSource={this.state.form.doorTable}
                                columns={columns}
                                locale={{ emptyText: 'Sem portas' }}
                                scroll={{ x: 1000 }}
                                pagination={false}
                            />

                            <Button
                                type='primary'
                                style={{ marginTop: '10px' }}
                                onClick={() => this.setState({ addDoorModal: true })}
                            >Adicionar porta</Button>
                        </div>

                        <Divider />

                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => this.saveProposalRequest()}
                                loading={this.state.loadingButton}
                            >Salvar / Atualizar</Button>
                        </div>

                    </Card>

                </div>

                <Modal
                    title='Adicionar/atualizar porta'
                    visible={this.state.addDoorModal}
                    onCancel={() => this.setState({ addDoorModal: false, doorInfo: null, isDoorToUpdateIndex: null, })}
                    cancelText='Cancelar'
                    onOk={() => this.addDoor()}
                    okText='Adicionar/Atualizar'
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label='Código' required>
                                <Input
                                    placeholder='Informe o código'
                                    value={this.state.doorInfo && this.state.doorInfo.code}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, code: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label='Qtd' required>
                                <Input
                                    step={1}
                                    placeholder='Informe a qtd'
                                    value={this.state.doorInfo && this.state.doorInfo.qtd}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, qtd: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Modelo' required>
                                <Input
                                    placeholder='Informe o modelo'
                                    value={this.state.doorInfo && this.state.doorInfo.model}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, model: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Tipologia' required>
                                <Input
                                    placeholder='Informe a tipologia'
                                    value={this.state.doorInfo && this.state.doorInfo.tipology}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, tipology: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Dimensões' required>
                                <Input
                                    placeholder='Informe as dimensões'
                                    value={this.state.doorInfo && this.state.doorInfo.dimensions}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, dimensions: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Espessura parede' required>
                                <Input
                                    placeholder='Informe a espessura da parede'
                                    value={this.state.doorInfo && this.state.doorInfo.thickness}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, thickness: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Acabamento folha' required>
                                <Input
                                    placeholder='Informe o acabamento da folha'
                                    value={this.state.doorInfo && this.state.doorInfo.finishing_sheet}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, finishing_sheet: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Acabamento marco/aliz' required>
                                <Input
                                    placeholder='Informe o acabamento do marco/aliz'
                                    value={this.state.doorInfo && this.state.doorInfo.finishing_marco}
                                    onChange={e => this.setState({ doorInfo: { ...this.state.doorInfo, finishing_marco: e.target.value } })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>

            </div>
        )
    }

}