import conditionsContent from "./conditions-content"

const defaultProposalData = {
    consultant: null,
    consult_phones: null,
    consult_whatsapp: null,
    consult_email: null,
    consult_repre_is_company: null,
    consult_repre_cod: null,
    consult_repre_cnpj: null,
    consult_repre_cpf: null,
    consult_repre: null,
    consult_repre_phone: null,
    consult_repre_email: null,
    consult_repre_whatsapp: null,
    consult_repre_empresa: null,
    consult_repre_contato: null,
    consult_contact: null,
    name_client: null,
    cep_client: null,
    address_client: null,
    complement_client: null,
    neighborhood_client: null,
    state_client: null,
    city_client: null,
    is_company: true,
    cnpj: null,
    cnpj_is_valide: false,
    state_registration: null,
    social_reason: null,
    cpf: null,
    cpf_is_valid: false,
    rg: null,
    mother_name: null,
    proposal_request: null,

    contact_prop: null,
    cargo_prop: null,
    phone_prop: null,
    email_prop: null,
    contact_supri: null,
    cargo_supri: null,
    phone_supri: null,
    email_supri: null,
    contact_eng: null,
    cargo_eng: null,
    phone_eng: null,
    email_eng: null,
    contact_management: null,
    cargo_management: null,
    phone_management: null,
    email_management: null,

    contact_obra_compras: null,
    cargo_obra_compras: null,
    phone_obra_compras: null,
    email_obra_compras: null,
    contact_obra_eng: null,
    cargo_obra_eng: null,
    phone_obra_eng: null,
    email_obra_eng: null,
    contact_obra_management: null,
    cargo_obra_management: null,
    phone_obra_management: null,
    email_obra_management: null,

    contact_project_arq: null,
    cargo_project_arq: null,
    phone_project_arq: null,
    email_project_arq: null,
    contact_project_espec: null,
    cargo_project_espec: null,
    phone_project_espec: null,
    email_project_espec: null,
    contact_project_compat: null,
    cargo_project_compat: null,
    phone_project_compat: null,
    email_project_compat: null,

    name_work: null,
    responsible_work: null,
    cep_work: null,
    address_work: null,
    complement_work: null,
    neighborhood_work: null,
    state_work: null,
    city_work: null,
    ocupation: null,
    usage: null,
    performance_level: null,
    fsc: false,
    instalation: null,
    local_work: null,
    team_mob: null,
    delivery: null,
    type_work: null,
    type_work_new: null,
    order_contact: null,
    order_phone: null,
    order_email: null,
    financial_contact: null,
    financial_phone: null,
    financial_email: null,
    warehouse_contact: null,
    warehouse_phone: null,
    warehouse_email: null,
    quality_contact: null,
    quality_phone: null,
    quality_email: null,
    installation_contact: null,
    installation_phone: null,
    installation_email: null,
    mobility_work: null,
    tech_manager: null,
    tech_assistance: null,
    team_mobilization: null,
    abridge_installation: true,
    keyWord: "",
    provider: null,
    project_especs: null,
    retrofitPackage: null,
    constructionPackage: null,
    sales_channel_inside_form: null,
    product_blocks: [
        {
            title: '',
            description: '',
            products: [{
                product_hash: null,
                project_code: null,
                product: null,
                env: null,
                profile_performance: null,
                performance_add: null,
                typology: null,
                amount: null,
                product_code: null,
                width_sheet: null,
                height_sheet: null,
                finishing_sheet: null,
                pattern_sheet: null,
                width_marco: null,
                height_marco: null,
                thickness_marco: null,
                finishing_marco: null,
                pattern_marco: null,
                sheet_width_aliz: null,
                sheet_height_aliz: null,
                finishing_aliz: null,
                pattern_aliz: null,
                fixed_aliz: null,
                adjustable_aliz: null,
                marco_add_passage: null,
                marco_add_seteira: null,
                marco_add_flag_panel: null,
                marco_add_flag_panel_glass: null,
                fixing_substrate: null,
                hardware_door_lock: null,
                conj_fechadura: null,
                hardware_cylinder: null,
                hardware_garrison: null,
                hardware_door_handle: null,
                lock_puller: null,
                hardware_puller: null,
                lock_quantity: null,
                hardware_bar_anti_panic: null,
                hardware_aerial_spring: null,
                hardware_accessories: null,
                hardware_accessories_02: null,
                hardware_accessories_03: null,
                hardware_addons: null,
                hardware_quantity: null,
                prod_height: null,
                prod_height_marco: null,
                prod_width: null,
                wall_panel: [
                    {
                        panel_wall_type: null,
                        panel_wall_dimensions: null,
                        panel_wall_module: null,
                        panel_wall_finishing: null,
                        panel_wall_pattern: null,
                        panel_wall_instalation: null,
                    }
                ],
                observation: ""
            }]
        }
    ],
    conditionsTitle: conditionsContent.title,
    conditions: conditionsContent.text,
    discountValidity: null,
    m20: null,
    m30: null,
    m60: null,
    m90: null,
    ignoreDeliveryRule: null,
    additionInformationHistoric: [],
}

export default defaultProposalData