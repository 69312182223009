import React from 'react';
import logo from '../../images/logo.svg';
import img_footer from '../../images/assets/checklist_footer.jpg';
import { Card, Button, Input, Modal, Affix, Spin } from 'antd';
import { dateToString } from '../../utils/general_functions';
import './checklistStyle.css'

const ChecklistView = (props) => {
    // kits que serão renderizados no checklist
    let productsToRender = props.products.map((product, i) => {
        let kit = props.getKit(product.product_code);
        let folha = props.getComponent(product.finishing_sheet);
        let marco = props.getComponent(product.thickness_marco);
        let fechadura = props.getComponent(product.conj_fechadura || product.hardware_door_lock);
        let alizar_fixo = props.getComponent(product.fixed_aliz);
        let alizar_regulavel = props.getComponent(product.adjustable_aliz);
        let check_configs = props.getChecklistConfigs(product.profile_performance);
        return (
            <div key={i} style={{ marginBottom: '70px', pageBreakInside: 'avoid' }}>

                <table className='checklistTable'>
                    <tbody>
                        <tr className='checklistYellowBg' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            <td style={{ width: '5%' }}>ITEM</td>
                            <td>DESEMPENHO</td>
                            <td>FOLHA</td>
                            <td style={{ width: '55%' }}>PRODUTO</td>
                        </tr>
                        <tr style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            <td style={{ width: '5%', fontSize: 'smaller' }}>{product && product.project_code}</td>
                            <td>{product && product.profile_performance}</td>
                            <td>{folha && folha.width_component}x{folha && folha.height_component}x{folha && folha.fill_componente} mm</td>
                            <td style={{ width: '55%' }}>{kit && kit.name}</td>
                        </tr>
                    </tbody>
                </table>

                <table className='checklistTable' style={{ marginBottom: '10px' }}>
                    <tbody>
                        <tr style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            <td className='checklistYellowBg' style={{ width: '40%' }} colSpan='2'>REQUISITOS E CARACTERISTICAS</td>
                            <td className='checklistYellowBg' style={{ width: '20%' }}>SISTEMA MULTIDOOR</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`fornecedor_0_${i}`]: e.target.value } })} value={props.form[`fornecedor_0_${i}`]} placeholder='FORNECEDOR 2' type='text' style={{ fontWeight: 'bold' }} className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`fornecedor_1_${i}`]: e.target.value } })} value={props.form[`fornecedor_1_${i}`]} placeholder='FORNECEDOR 3' type='text' style={{ fontWeight: 'bold' }} className='gray' /></td>
                        </tr>
                        {/* Kit porta */}
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '5%', transform: 'rotate(270deg)', fontWeight: 'bold' }} rowSpan='7'>KIT PORTA</td>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>NIVEL DE DESEMPENHO</td>
                            <td>{check_configs && check_configs.kit_performance}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_performance_0_${i}`]: e.target.value } })} value={props.form[`kit_performance_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_performance_1_${i}`]: e.target.value } })} value={props.form[`kit_performance_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>VUP - VIDA ÚTIL DE PROJETO</td>
                            <td>{check_configs && check_configs.kit_vup}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_vup_0_${i}`]: e.target.value } })} value={props.form[`kit_vup_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_vup_1_${i}`]: e.target.value } })} value={props.form[`kit_vup_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>CICLOS DE ABERTURA E FECHAMENTO</td>
                            <td>{check_configs && check_configs.kit_cicle}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_cicle_0_${i}`]: e.target.value } })} value={props.form[`kit_cicle_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_cicle_1_${i}`]: e.target.value } })} value={props.form[`kit_cicle_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>TRÁFEGO DE USO</td>
                            <td>{check_configs && check_configs.kit_traffic}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_traffic_0_${i}`]: e.target.value } })} value={props.form[`kit_traffic_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_traffic_1_${i}`]: e.target.value } })} value={props.form[`kit_traffic_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>OCUPAÇÃO</td>
                            <td>{check_configs && check_configs.kit_occupation}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_occupation_0_${i}`]: e.target.value } })} value={props.form[`kit_occupation_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_occupation_1_${i}`]: e.target.value } })} value={props.form[`kit_occupation_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>USO- ANEXO K NBR 15930-2</td>
                            <td>{check_configs && check_configs.kit_use}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_use_0_${i}`]: e.target.value } })} value={props.form[`kit_use_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_use_1_${i}`]: e.target.value } })} value={props.form[`kit_use_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>DESEMPENHO ABNT NBR 15930-2</td>
                            <td>{check_configs && check_configs.kit_performance_abnt}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_performance_abnt_0_${i}`]: e.target.value } })} value={props.form[`kit_performance_abnt_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`kit_performance_abnt_1_${i}`]: e.target.value } })} value={props.form[`kit_performance_abnt_1_${i}`]} type='text' /></td>
                        </tr>
                        {/* Folha */}
                        <tr className='gray'>
                            <td style={{ width: '5%', transform: 'rotate(270deg)', fontWeight: 'bold' }} rowSpan='8'>FOLHA</td>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>CLASSIFICAÇÃO DA FOLHA DA PORTA</td>
                            <td>{check_configs && check_configs.folha_classification}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_classification_0_${i}`]: e.target.value } })} value={props.form[`folha_classification_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_classification_1_${i}`]: e.target.value } })} value={props.form[`folha_classification_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>MASSA DA FOLHA - KG/M2</td>
                            <td>{folha && folha.teoric_mass}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_teoric_mass_0_${i}`]: e.target.value } })} value={props.form[`folha_teoric_mass_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_teoric_mass_1_${i}`]: e.target.value } })} value={props.form[`folha_teoric_mass_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>ESPESSURA DA FOLHA - mm</td>
                            <td>{folha && folha.fill_componente}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_fill_componente_0_${i}`]: e.target.value } })} value={props.form[`folha_fill_componente_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_fill_componente_1_${i}`]: e.target.value } })} value={props.form[`folha_fill_componente_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>CAPA DA FOLHA</td>
                            <td>{check_configs && check_configs.folha_cape}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_cape_0_${i}`]: e.target.value } })} value={props.form[`folha_cape_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_cape_1_${i}`]: e.target.value } })} value={props.form[`folha_cape_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>QUADRO E NÚCLEO</td>
                            <td>{check_configs && check_configs.folha_frame_core}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_frame_core_0_${i}`]: e.target.value } })} value={props.form[`folha_frame_core_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_frame_core_1_${i}`]: e.target.value } })} value={props.form[`folha_frame_core_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>PORTA ENGENHEIRADA - ADESIVO</td>
                            <td>{check_configs && check_configs.folha_engenheirada}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_engenheirada_0_${i}`]: e.target.value } })} value={props.form[`folha_engenheirada_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_engenheirada_1_${i}`]: e.target.value } })} value={props.form[`folha_engenheirada_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>ACABAMENTO DA PORTA</td>
                            <td>{folha && folha.finishing.description}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_finishing_0_${i}`]: e.target.value } })} value={props.form[`folha_finishing_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_finishing_1_${i}`]: e.target.value } })} value={props.form[`folha_finishing_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>BORDAS PP - POLIPROPILENO - mm</td>
                            <td>{check_configs && check_configs.folha_bordas}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_bordas_0_${i}`]: e.target.value } })} value={props.form[`folha_bordas_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`folha_bordas_1_${i}`]: e.target.value } })} value={props.form[`folha_bordas_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        {/* Marco */}
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '5%', transform: 'rotate(270deg)', fontWeight: 'bold' }} rowSpan='7'>MARCO</td>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>CLASSIFICAÇÃO DO MARCO DA PORTA</td>
                            <td>{check_configs && check_configs.marco_classification}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_classification_0_${i}`]: e.target.value } })} value={props.form[`marco_classification_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_classification_1_${i}`]: e.target.value } })} value={props.form[`marco_classification_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>ESPESSURA DO MARCO - mm</td>
                            <td>{check_configs && check_configs.marco_thickness}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_thickness_0_${i}`]: e.target.value } })} value={props.form[`marco_thickness_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_thickness_1_${i}`]: e.target.value } })} value={props.form[`marco_thickness_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>CONSTRUÇÃO ENGENHEIRADA</td>
                            <td>{check_configs && check_configs.marco_construction}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_construction_0_${i}`]: e.target.value } })} value={props.form[`marco_construction_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_construction_1_${i}`]: e.target.value } })} value={props.form[`marco_construction_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>MARCO ENGENHEIRADO - ADESIVO</td>
                            <td>{check_configs && check_configs.marco_engenheirado}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_engenheirado_0_${i}`]: e.target.value } })} value={props.form[`marco_engenheirado_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_engenheirado_1_${i}`]: e.target.value } })} value={props.form[`marco_engenheirado_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>ACABAMENTO MARCO+ALIZARES</td>
                            <td>{marco && marco.finishing.description}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_finishing_0_${i}`]: e.target.value } })} value={props.form[`marco_finishing_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_finishing_1_${i}`]: e.target.value } })} value={props.form[`marco_finishing_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>AMORTECEDOR - VEDAÇÃO</td>
                            <td>{check_configs && check_configs.marco_buffer}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_buffer_0_${i}`]: e.target.value } })} value={props.form[`marco_buffer_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`marco_buffer_1_${i}`]: e.target.value } })} value={props.form[`marco_buffer_1_${i}`]} type='text' /></td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>ALIZARES - largura mm</td>
                            <td>{(alizar_fixo && alizar_fixo.fill_componente) || (alizar_regulavel && alizar_regulavel.fill_componente)}
                                &nbsp;
                                {
                                    (product && product.aliz_options === 'fixed_adjustable') ? '(fixo+regulável)' :
                                        (product && product.aliz_options === 'fixed') ? '(fixo)' :
                                            (product && product.aliz_options === 'adjustable') ? '(regulável)' : ''
                                }
                            </td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`alizar_0_${i}`]: e.target.value } })} value={props.form[`alizar_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`alizar_1_${i}`]: e.target.value } })} value={props.form[`alizar_1_${i}`]} type='text' /></td>
                        </tr>
                        {/* Ferragens */}
                        <tr className='gray'>
                            <td style={{ width: '5%', transform: 'rotate(270deg)', fontWeight: 'bold' }} rowSpan='5'>FERRAGENS</td>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>FECHADURA - padrão desempenho</td>
                            <td>{fechadura && fechadura.description_component}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`fechadura_0_${i}`]: e.target.value } })} value={props.form[`fechadura_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`fechadura_1_${i}`]: e.target.value } })} value={props.form[`fechadura_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>DOBRADIÇAS - Aço Inox AISI 304 - mm</td>
                            <td>{check_configs && check_configs.ferragem_dob}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`dobradica_0_${i}`]: e.target.value } })} value={props.form[`dobradica_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`dobradica_1_${i}`]: e.target.value } })} value={props.form[`dobradica_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>VAIVÉM</td>
                            <td>{check_configs && check_configs.ferragem_vaivem}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`vaivem_0_${i}`]: e.target.value } })} value={props.form[`vaivem_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`vaivem_1_${i}`]: e.target.value } })} value={props.form[`vaivem_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>PIVOTANTE</td>
                            <td>{check_configs && check_configs.ferragem_pivot}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`pivot_0_${i}`]: e.target.value } })} value={props.form[`pivot_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`pivot_1_${i}`]: e.target.value } })} value={props.form[`pivot_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        <tr className='gray'>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>CORRER</td>
                            <td>{check_configs && check_configs.ferragem_correr}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`correr_0_${i}`]: e.target.value } })} value={props.form[`correr_0_${i}`]} type='text' className='gray' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`correr_1_${i}`]: e.target.value } })} value={props.form[`correr_1_${i}`]} type='text' className='gray' /></td>
                        </tr>
                        {/* Preço */}
                        <tr style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 'medium' }}>
                            <td className='checklistYellowBg' style={{ width: '50%' }} colSpan='2'>Custo de aquisição inicial do Kit Porta</td>
                            <td className='checklistYellowBg' style={{ width: '20%' }}>{(product.price || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`value_0_${i}`]: e.target.value } })} value={props.form[`value_0_${i}`]} type='text' /></td>
                            <td><input onChange={e => props.updateState({ form: { ...props.form, [`value_1_${i}`]: e.target.value } })} value={props.form[`value_1_${i}`]} type='text' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    });

    return (
        <div id='checklist' className="app-login" style={{ padding: '50px 0px', position: 'relative', height: 'unset' }}>
            <div style={{ maxHeight: '100%' }}>
                {/* Modal para o cliente colocar o código */}
                <Modal
                    title="Informe o código"
                    visible={props.modalVisible}
                    closable={false}
                    maskClosable={false}
                    footer={[
                        <Button
                            type='primary'
                            loading={props.formLoading}
                            onClick={() => props.fillForm(props.proposalId)}
                            disabled={!props.proposalId}
                        >Ok</Button>
                    ]}
                >
                    <Input type='text' placeholder='Cole aqui o código' onChange={e => props.updateState({ proposalId: e.target.value })} />
                </Modal>
                {/* Modal de sucesso no envio */}
                <Modal
                    title="Checklist enviado"
                    visible={props.modalSucessVisible}
                    closable={false}
                    maskClosable={false}
                    footer={[
                        <a href='http://www.multidoor.com.br'>
                            <Button>Fechar</Button>
                        </a>
                    ]}
                >
                    <p>A Multidoor agradece pelo preenchimento do Checklist.</p>
                </Modal>
                {/* Checklist */}
                <Spin spinning={props.formLoading}>
                    <div style={{ width: '1000px', margin: 'auto' }}>
                        <Card style={{ width: '100%', marginBottom: '50px' }} id='checklist-card'>
                            <img src={logo} alt="Logo Senai" width='300' />
                            <div style={{ float: 'right', marginLeft: '3.40cm' }}>
                                <table className='checklistTable' style={{ width: '300px', fontSize: '10px' }}>
                                    <tbody>
                                        <tr><td><strong>Palavra chave:</strong></td><td>{props.proposal && props.proposal.get('keyWord')}</td></tr>
                                        <tr><td><strong>No da Proposta:</strong></td><td>{props.proposal && props.proposal.get('proposal_code')}</td></tr>
                                        <tr><td><strong>Data emissão:</strong></td><td>{props.proposal && dateToString(props.proposal.get('updatedAt'))}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ clear: 'both' }} />
                            <table className='checklistTableGray'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '60%', padding: '5px' }}><strong>Cliente</strong>: {props.proposal && props.proposal.get('name_client')}</td>
                                        <td style={{ width: '40%', padding: '5px' }}><strong>Contato</strong>: {props.proposal && (props.proposal.get('contact_prop') || '')}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '60%', padding: '5px' }}><strong>Obra</strong>: {props.proposal && props.proposal.get('name_work')}</td>
                                        <td style={{ width: '40%', padding: '5px' }}><strong>E-mail</strong>: {props.proposal && (props.proposal.get('email_prop') || '')}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '60%', padding: '5px' }}><strong>Cidade/Estado</strong>: {props.proposal && props.proposal.get('city_work')}/{props.proposal && props.proposal.get('state_work')}</td>
                                        <td style={{ width: '40%', padding: '5px' }}><strong>Telefone</strong>: {props.proposal && (props.proposal.get('phone_prop') || '')}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ width: '100%', backgroundColor: '#f4d240ff', textAlign: 'center', padding: '10px' }}>
                                <h2 style={{ marginBottom: '-5px' }}><strong>CHECKLIST DE PORTAS</strong></h2>
                                <strong>ANEXO M - ABNT NBR 15930-2: 2018</strong>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center', padding: '10px' }}>
                                <strong style={{ color: 'red' }}>Preencha e compare o desempenho dos produtos para sua melhor compra de valor</strong>
                            </div>
                            {productsToRender}
                            <img src={img_footer} alt="Madeira é Agro" width='80%' style={{ marginLeft: '10%', marginTop: '-50px' }} />
                            <Affix offsetBottom={0}>
                                <div className='no-print' style={{ textAlign: 'center', backgroundColor: 'white', padding: '10px' }}>
                                    <Button loading={props.saveLoading} style={{ width: '400px' }} onClick={() => props.saveChecklist(props.proposalId)} type='primary'>Salvar Checklist</Button>
                                    <Button loading={props.printLoadingButton} style={{ width: '400px', marginLeft: '10px' }} onClick={() => props.printPDF()} type='ghost'>Imprimir em pdf</Button>
                                </div>
                            </Affix>
                        </Card>
                    </div>
                </Spin>
            </div>
        </div>
    );

}

export default ChecklistView;