import React from 'react';
import { Icon, Tag } from 'antd';
import { doorLockComposition, doorLockType, doorLockTypology } from './suport-files/door-locks-select-options';

const getLabelFromValue = (array, value) => {
  const element = array.find(el => el.value === value)
  return element && element.label
}

export default {
  "fields": [
    [
      {
        type: 'select',
        key: 'typology',
        title: 'Tipologias',
        options: [],
        optionConfig: { name: 'typology', arrayWithId: true },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
      },
    ],
    [
      {
        type: 'select',
        key: 'doorLockTypology',
        title: 'Fechadura',
        options: doorLockTypology,
        loaded: false,
        "element-attr": {
          required: true
        },
        colSize: 8,
      },
      {
        type: 'select',
        key: 'doorLockType',
        title: 'Tipo de fechadura',
        options: doorLockType,
        loaded: false,
        "element-attr": {
          required: true
        },
        colSize: 4,
      },
      {
        type: 'select',
        key: 'doorLockComposition',
        title: 'Composição de fechadura',
        options: doorLockComposition,
        loaded: false,
        "element-attr": {
          mode: "multiple",
          required: true
        },
        colSize: 8,
      },
      {
        type: 'tag-list',
        key: 'doorLockCodes',
        title: 'Código componente p/ busca',
        "element-attr": {
          required: true,
        },
        colSize: 4,
      },
    ],
  ],
  submit: { collection: "MatrizFech" },
  className: "config-component",
  schema: [
    {
      "title": "Fechadura",
      "key": "doorLockTypology",
      "type": "text",
      render: row => getLabelFromValue(doorLockTypology, row.doorLockTypology)
    },
    {
      "title": "Tipo de Fechadura",
      "key": "doorLockType",
      "type": "text",
      render: row => getLabelFromValue(doorLockType, row.doorLockType)
    },
    {
      title: 'Composição de Fechadura',
      key: 'doorLockComposition',
      type: 'text',
      render: row => {
        return (row.doorLockComposition || []).map((item, key) => (<Tag key={key}>{getLabelFromValue(doorLockComposition, item)}</Tag>))
      }

    }
  ],
  "title-navigation": <div><Icon type="key" /> Matriz de Fechadura</div>,
  "title-module": "Matriz de Fechadura",
  "module": "MatrizFech",
  "router-base": "/panel/matriz-fechaduras"
}