import React, { Component, Fragment } from "react";
import {
    Avatar,
    Button,
    Col,
    Form,
    Input,
    List,
    Modal,
    notification,
    Popover,
    Row,
} from "antd";
import Parse from "parse";
import {
    findAll,
    getCurrentUser,
    save,
} from "../../../../utils/db_functions";
import "../../../commercial/manage-proposal/manage-proposal.css"

export default class CommentModal extends Component {
    state = {
        visible: false,
        isToEdit: false,

        orderId: this.props.orderId,
        comment: null,
        historic: this.props.historic,

        saveLoading: false,
        saveDisabled: true,

        hasChanged: false,

        users: [],

        commentFormModal: false,
    };

    componentWillReceiveProps = (nextProps) => {
        this.setState({ historic: nextProps.historic });
    };

    showModal = async () => {
        const parseUsers = await findAll('User')
        const users = parseUsers.map(user => {
            return {
                id: user.id,
                name: user.get('name'),
                img: `${Parse.serverURL}/files/${Parse.applicationId}/${user.get('photo')}`
            }
        })

        this.setState({
            visible: true,
            isToEdit: false,
            users
        });

    };

    hideModal = () => {
        // this.props.reRenderList(this.state.historic);

        this.setState({
            visible: false,
            comment: null,
            saveLoading: false,
            saveDisabled: true,
        });

        if (this.state.hasChanged) window.location.reload();
    };

    setForm = (f, v) => {
        let formHistoric = {
            orderId: this.state.orderId,
            comment: this.state.comment,
            saveDisabled: this.state.saveDisabled,
        };
        if (f === "comment") formHistoric.comment = v;

        if (formHistoric.comment) formHistoric.saveDisabled = false;
        else formHistoric.saveDisabled = true;

        this.setState({
            comment: formHistoric.comment,
            saveDisabled: formHistoric.saveDisabled,
        });
    };

    editComment = (comment) => {
        this.setState({
            commentFormModal: true,
            comment: comment,
            isToEdit: true,
            hasChanged: true,
        });
    };

    deleteComment = async () => {
        let historic = this.state.historic || [];
        historic.splice(0, 1);
        historic = historic.sort((a, b) =>
            a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        await save(
            "Order",
            { historic },
            this.state.orderId
        );
        notification.success({
            message: "Remoção realizada",
            description: "Histórico removido com sucesso.",
            duration: 5,
        });

        this.setState({
            comment: null,
            saveLoading: false,
            saveDisabled: true,
            historic: historic,
            isToEdit: false,
            hasChanged: true,
        });
    };

    saveComment = async () => {
        this.setState({ saveLoading: true });

        const user = await getCurrentUser();
        let historicToPush = {
            comment: this.state.comment,
            author: user.get("name"),
            date: Date.now(),
        };

        let historic = this.state.historic || [];
        if (this.state.isToEdit) historic.splice(0, 1);
        historic.push({ ...historicToPush });
        historic = historic.sort((a, b) =>
            a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        await save(
            "Order",
            { historic },
            this.state.orderId
        )

        if (this.state.isToEdit) {
            notification.success({
                message: "Alteração realizada",
                description: "Histórico alterado com sucesso.",
                duration: 5,
            });
        } else {
            notification.success({
                message: "Registro realizado",
                description: "Histórico registrado com sucesso.",
                duration: 5,
            });
        }

        this.setState({
            comment: null,
            saveLoading: false,
            saveDisabled: true,
            historic: historic,
            isToEdit: false,
            hasChanged: true,
            commentFormModal: false
        });
    };

    renderComment = (comment, index) => {

        const date = new Date(comment.date).toLocaleString("pt-BR")

        return (
            <Row gutter={24}>
                <Col
                    span={4}
                    style={{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        paddingTop: '20px',
                    }}
                >
                    <Avatar
                        style={{ verticalAlign: 'middle' }}
                        size="large"
                        src={this.findUserImg(comment.author)}
                    >
                        {
                            !this.findUserImg(comment.author) ? (comment.author && comment.author[0].toUpperCase()) : ''
                        }
                    </Avatar>
                </Col>

                <Col span={20}>
                    <Row>
                        <span className="manager-proposal-author-comment">{comment.author}</span>
                        <span className="manager-proposal-date-comment">{date}</span>
                    </Row>

                    <Row>
                        {comment.comment}
                    </Row>

                    {
                        index === 0 && Parse.User.current().get("name") === comment.author ?
                            (
                                <Row style={{ fontSize: '12px' }}>
                                    <span
                                        className="link-style"
                                        onClick={() =>
                                            this.editComment(comment.comment)
                                        }
                                    >
                                        Editar
                                    </span>&nbsp; &nbsp;

                                    <span
                                        className="link-style"
                                        style={{ color: 'red' }}
                                        onClick={() => {
                                            let _this = this;
                                            Modal.confirm({
                                                title: "Tem certeza que deseja exlcuir esse comentário do histórico?",
                                                content: "Essa ação não poderá ser desfeita",
                                                cancelText: 'Cancelar',
                                                okText: 'Sim',
                                                onOk() {
                                                    _this.deleteComment();
                                                },
                                            });
                                        }}
                                    >
                                        Excluir
                                    </span>
                                </Row>
                            ) :
                            null
                    }
                </Col>
            </Row>
        )
    }

    findUserImg = (userName) => {
        const user = this.state.users.find(user => user.name === userName)
        return user && user.img
    }

    render() {
        return (
            <Fragment>
                <Popover content='Histórico do pedido'>
                    <Button
                        icon="unordered-list"
                        onClick={async () => this.showModal()}
                    />
                </Popover>

                <Modal
                    title="Histórico do pedido"
                    visible={this.state.visible}
                    onCancel={this.hideModal}
                    footer={[
                        <Button
                            type="primary"
                            icon="form"
                            onClick={() => this.setState({ commentFormModal: true })}
                        >
                            Adicionar novo comentário
                        </Button>
                    ]}
                >

                    <div className='manager-proposal-historic-modal'>
                        <List
                            className="comment-list"
                            itemLayout="horizontal"
                            dataSource={this.state.historic || []}
                            renderItem={(item, index) => (
                                <List.Item>
                                    {this.renderComment(item, index)}
                                </List.Item>
                            )}
                            locale={{ emptyText: 'Sem histórico' }}
                        />
                    </div>
                </Modal>

                <Modal
                    title='Comentário'
                    visible={this.state.commentFormModal}
                    onCancel={() => {
                        this.setState({
                            commentFormModal: false,
                            comment: null,
                            saveLoading: false,
                            saveDisabled: true,
                            isToEdit: false,
                            hasChanged: true,
                        })
                    }}
                    footer={[
                        <Button
                            type="primary"
                            loading={this.state.saveLoading}
                            disabled={this.state.saveDisabled}
                            onClick={this.saveComment}
                        >
                            Salvar comentário
                        </Button>
                    ]}
                >
                    <Form.Item label={"Descreva o histórico do pedido"} required>
                        <Input.TextArea
                            style={{ width: "100%", marginBottom: "10px" }}
                            rows={4}
                            placeholder="Histórico do pedido"
                            onChange={(e) => this.setForm("comment", e.target.value)}
                            value={this.state.comment}
                        />
                    </Form.Item>
                </Modal>
            </Fragment>
        );
    }
}
