import { Badge, Col, Drawer, Icon, Row } from 'antd'
import React, { Component } from 'react'
import { findAll, getCurrentUser } from '../../utils/db_functions.js'
import "./NotificationCenter.css"

export default class NotificationCenter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userEmail: null,
            notificationCollapsed: false,
            hasNotification: false,

            ordersNotifications: [],
        }
    }

    componentDidMount = async () => {
        const user = await getCurrentUser()
        this.setState({ userEmail: user.get('username') })

        await this.getNotifications()

        this.interval = setInterval(this.getNotifications, 600000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getNotifications = async () => {
        await this.getOrderNotification();
    }

    getOrderNotification = async () => {
        const notificationCenter = JSON.parse(localStorage.getItem('notificationCenter') || "[]")
        const userNotificationData = notificationCenter.find(el => el.user === this.state.userEmail)
        const lastOrderNotificationTime = (userNotificationData && userNotificationData.notificationTime && userNotificationData.notificationTime.order) || 0

        let orders = await findAll('Order', 5, null, null, 'notificationTime', 'descending')
        orders = (orders || []).filter(order => order.get('notificationTime'))
        this.setState({
            ordersNotifications: orders.map((order) => {
                const proposal = JSON.parse(order.get('proposal'))
                const workName = proposal.name_work
                const pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_order/${(proposal.name_client || '').replace('/', '')}-${order.get('order_code')}-Multidoor.pdf`.replace(/\s/g, '-');
                return {
                    icon: "alert",
                    title: "Pedido fechado!",
                    content: () => {
                        return (
                            <div>
                                {order.get('order_code')}<br />
                                Obra: {workName}<br />
                                <a href={pdfName} target='blank'>Acessar o pdf</a>
                            </div>
                        )
                    }
                }
            })
        })

        if (orders.length) {
            if (orders[0].get('notificationTime') > lastOrderNotificationTime)
                this.setState({ hasNotification: true })
        }
    }

    setNewNotificationTime = (module) => {
        const notificationCenter = JSON.parse(localStorage.getItem('notificationCenter') || "[]")
        const userNotificationData = notificationCenter.find(el => el.user === this.state.userEmail)
        let notificationCenterUpdated = [];

        if (!userNotificationData) {
            notificationCenterUpdated = notificationCenter;
            notificationCenterUpdated.push({
                user: this.state.userEmail,
                notificationTime: { [module]: new Date().getTime() }
            })
        } else {
            notificationCenterUpdated = notificationCenter.map(notification => {
                if (notification.user === this.state.userEmail)
                    notification['notificationTime'][module] = new Date().getTime()

                return notification
            })
        }

        localStorage.setItem('notificationCenter', JSON.stringify(notificationCenterUpdated))
        this.setState({ hasNotification: false })
    }

    renderNotificationCard = (notification) => {
        return (
            <div className='notificationCenterCard'>
                <Row gutter={24}>
                    <Col span={5}>
                        <div className='notificationCenterCardIcon'>
                            <Icon style={{ fontSize: 'x-large' }} type={notification.icon} />
                        </div>
                    </Col>
                    <Col span={19}>
                        <strong>{notification.title}</strong>
                        {notification.content()}
                    </Col>
                </Row>
            </div>
        )
    }

    render() {

        return (
            <div style={{ float: 'right', }}>
                <Badge dot={this.state.hasNotification} style={{ marginRight: '25px', width: '12px', height: '12px' }}>
                    <Icon
                        className="trigger"
                        style={{ fontSize: '20px' }}
                        type='bell'
                        onClick={() => {
                            this.setNewNotificationTime("order")
                            this.setState({ notificationCollapsed: true })
                        }}
                    />
                </Badge>

                <Drawer
                    title="Central de notificações"
                    placement="right"
                    width={400}
                    closable={false}
                    onClose={() => this.setState({ notificationCollapsed: false })}
                    visible={this.state.notificationCollapsed}
                >
                    {this.state.ordersNotifications.map(orderNotification => this.renderNotificationCard(orderNotification))}
                </Drawer>
            </div>
        )

    }

}