import { Button, Divider, Dropdown, Form, Icon, InputNumber, Menu, Modal, notification, Table } from 'antd';
import React, { Component } from 'react'
import { findWhere, findWhereMultiple, save } from '../../../../utils/db_functions';
import { defaultPaymentConditions } from '../../../../utils/defaultDataSource';
import { roundTo } from '../../../../utils/mathFunctions';
import Parse from 'parse'
import OrderGeneratorPortionForm from './order-generator-portion-form';
import moment from 'moment';
import OrderGeneratorScheduleForm from './order-generator-schedule-form';
import { transformClientContactsFieldsInArray, transformObraContactsFieldsInArray, transformProjectContactsFieldsInArray } from '../suport-files/proposal-functions';

export default class OrderGeneratorClass extends Component {
    constructor(props) {
        super(props)
        this.state = {
            proposal: this.props.proposal,

            orderSimulationModal: false,
            orderForm: {
                discount: 0,
                dataSourcePaymentConditions: [],
                dataSourceFinancialSchedule: defaultPaymentConditions((this.props.proposal.baseFinalPriceAndAmount || {}).finalAmount),
            },

            orderSimulationPortionFormModal: false,
            portionForm: {
                paymentPortion: null,
                paymentRef: null,
                paymentDueDate: null,
                paymentValue: null,
                paymentBilling: null,
                paymentCnpj: null,
            },
            portionIndexToUpdate: null,

            orderSimulationScheduleFormModal: false,
            scheduleForm: {
                weeks: null,
                activity: null,
                responsibility: null,
                kits: null,
                lote: null,
                expected_date: null,
                accomplished_date: null,
            },
            scheduleIndexToUpdate: null,
        }
    }

    async generateOrder() {

        notification.open({
            message: 'Gerando pedido',
            description: 'O pedido está sendo gerado, aguarde...',
            key: 'generatingOrder',
            duration: 0,
            placement: 'topLeft',
            icon: <Icon type="loading" />
        });

        const orderFounded = await findWhere('Order', 'proposal_code', [this.state.proposal.proposal_code])

        if (orderFounded.length) {
            notification.close('generatingOrder');
            notification.error({
                message: 'Erro ao gerar o pedido',
                description: 'Esse pedido já foi gerado',
                placement: 'topLeft',
            });
        } else {
            let order_code = ''

            const yearOrders = await findWhereMultiple('Order', [{ 'field': 'order_code', 'value': `PFM-${new Date().getFullYear()}`, 'op': 'like' }], null, null, null, 'order_code', 'descending')

            if (!yearOrders.length) order_code = `PFM-${new Date().getFullYear()}-0001`
            else {
                let lastCode = yearOrders[0].get('order_code')
                let newNumberCode = (parseInt(lastCode.split('-')[2]) + 1).toString();
                let repeatZero = 4 - newNumberCode.length;
                order_code = `PFM-${new Date().getFullYear()}-${'0'.repeat(repeatZero) + newNumberCode}`;
            }

            var proposalToRef = Parse.Object.extend("Proposal");
            var pointerToProposal = new proposalToRef();
            pointerToProposal.id = this.state.proposal.objectId;

            const order = {
                'order_code': order_code,
                'proposal': JSON.stringify(this.state.proposal),
                'payment_conditions': this.state.proposal.payment_conditions,
                'discount': this.state.proposal.discount,
                'signal': this.state.proposal.signal,
                'installmentsNum': this.state.proposal.installmentsNum,
                'installmentsPer': this.state.proposal.installmentsPer,
                'comercialConditions': this.state.proposal.sales_channel.comercialConditions.replace('a Proposta', 'o Pedido'),
                'technicalConditions': this.state.proposal.sales_channel.technicalConditions.replace('a Proposta', 'o Pedido'),
                'conditions': this.state.proposal.sales_channel.conditions.replace('esta Proposta', 'este Pedido').replace('a Proposta', 'o Pedido'),
                'total': roundTo(this.state.proposal.final_value, 2),
                'dataSourceFinancialSchedule': defaultPaymentConditions(this.state.proposal.baseFinalPriceAndAmount.finalAmount),
                'proposalRef': pointerToProposal,
                'clientContacts': this.state.proposal.clientContacts || transformClientContactsFieldsInArray(this.state.proposal),
                'workContacts': this.state.proposal.workContacts || transformObraContactsFieldsInArray(this.state.proposal),
                'projectContacts': this.state.proposal.projectContacts || transformProjectContactsFieldsInArray(this.state.proposal),
                'instalationContacts': this.state.proposal.instalationContacts || [{ contact: 'Pedido Técnico' }, { contact: 'Serviços vão da porta' }, { contact: 'Gerencia' }],
                'financesContacts': [{ contact: 'Financeiro' }, { contact: 'Gestão da Qualidade' }, { contact: 'Almoxarifado' }, { contact: 'SESMT' }, { contact: 'NF-e' }]
            }

            await save('Order', order)

            notification.close('generatingOrder');
            notification.success({
                message: 'Pedido gerado com sucesso.',
                description: 'Acesse o módulo de pedido para consultá-lo.',
                placement: 'topLeft',
            })
            Parse.Cloud.run('generateInputReport', { id: this.state.proposal.objectId, orderCode: order_code })

            await save('Proposal', { 'orderGenerated': true, proposal_management_status: 'PedidoGerado' }, this.state.proposal.objectId)
        }
    }

    clearPortionForm = () => {
        this.setState({
            portionForm: {
                paymentPortion: null,
                paymentRef: null,
                paymentDueDate: null,
                paymentValue: null,
                paymentBilling: null,
                paymentCnpj: null,
            },
            portionIndexToUpdate: null,
        })
    }

    clearScheduleForm = () => {
        this.setState({
            scheduleForm: {
                weeks: null,
                activity: null,
                responsibility: null,
                kits: null,
                lote: null,
                expected_date: null,
                accomplished_date: null,
            },
            scheduleIndexToUpdate: null,
        })
    }

    async generateSimulatedOrder() {
        notification.open({
            message: 'Gerando simulação do pedido',
            description: 'A simulação está sendo gerada, aguarde...',
            key: 'generatingSimulatedOrder',
            duration: 0,
            placement: 'topLeft',
            icon: <Icon type="loading" />
        })

        const order = {
            'order_code': `${this.state.proposal.proposal_code}-simulacao`,
            'proposal': JSON.stringify(this.state.proposal),
            'proposalFromOrder': { key: this.state.proposal.objectId },
            'dataSourcePaymentConditions': this.state.orderForm.dataSourcePaymentConditions,
            'discount': this.state.proposal.discount,
            'commercial_discount': this.state.orderForm.discount,
            'signal': this.state.proposal.signal,
            'installmentsNum': this.state.proposal.installmentsNum,
            'installmentsPer': this.state.proposal.installmentsPer,
            'comercialConditions': this.state.proposal.sales_channel.comercialConditions.replace('a Proposta', 'o Pedido'),
            'technicalConditions': this.state.proposal.sales_channel.technicalConditions.replace('a Proposta', 'o Pedido'),
            'conditions': this.state.proposal.sales_channel.conditions.replace('esta Proposta', 'este Pedido').replace('a Proposta', 'o Pedido'),
            'total': roundTo(this.state.proposal.final_value, 2),
            'dataSourceFinancialSchedule': this.state.orderForm.dataSourceFinancialSchedule,
            'order_date': { iso: new Date() }
        }

        try {
            console.log({ order })
            const response = await Parse.Cloud.run('generateSimulateOrder', { order: JSON.stringify(order) })

            window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')

            notification.close('generatingSimulatedOrder');
            notification.success({
                message: 'Simulação gerada com sucesso.',
                description: 'Acesse o pdf na guia ao lado.',
                placement: 'topLeft',
            })
        } catch (e) {
            notification.close('generatingSimulatedOrder');
            notification.error({
                message: 'Erro ao gerar a simulação.',
                description: e.message,
                placement: 'topLeft',
            })
        }
    }

    render() {

        const dropdownOrderMenu = (
            <Menu>
                <Menu.Item
                    onClick={async () => await this.generateOrder()}
                >
                    Gerar pedido
                </Menu.Item>

                <Menu.Item
                    onClick={() => this.setState({ orderSimulationModal: true })}
                >
                    Simular pedido
                </Menu.Item>
            </Menu>
        )

        return (
            <div>
                <Dropdown overlay={dropdownOrderMenu} placement='bottomCenter' trigger={['click']}>
                    <Button title="Gerar pedido" type="primary" icon="menu" />
                </Dropdown>

                <Modal
                    visible={this.state.orderSimulationModal}
                    width={1000}
                    title='Simular pedido'
                    onCancel={() => {
                        this.setState({ orderSimulationModal: false })
                        this.clearPortionForm()
                        this.clearScheduleForm()
                    }}
                    onOk={async () => {
                        await this.generateSimulatedOrder()
                        this.setState({ orderSimulationModal: false })
                        this.clearPortionForm()
                        this.clearScheduleForm()
                    }}
                >
                    <Form.Item label='Desconto'>
                        <InputNumber
                            placeholder='Desconto'
                            style={{ width: '40%' }}
                            formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            parser={value => {
                                let decimals = value.split(',')[1]
                                if (decimals && decimals.length > 2) {
                                    decimals = decimals.substring(0, 2)
                                    value = `${value.split(',')[0]},${decimals}`
                                }
                                return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
                            }}
                            onChange={e => this.setState({ orderForm: { ...this.state.orderForm, discount: e } })}
                        />
                    </Form.Item>

                    <div style={{ fontSize: 'larger', fontWeight: 'bold', marginTop: '40px', marginBottom: '40px' }}>
                        Total Geral com desconto: {(roundTo(this.state.proposal.final_value, 2) - this.state.orderForm.discount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </div>

                    <Divider>Condições de Pagamento</Divider>
                    <Table
                        size='small'
                        columns={[
                            { title: 'Parcela', dataIndex: 'paymentPortion', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            { title: 'Referência', dataIndex: 'paymentRef', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            {
                                title: 'Vencimento', dataIndex: 'paymentDueDate', render: (value) => {
                                    return <span style={{ fontSize: 'smaller' }}>{value ? moment(value).format('DD/MM/YY') : null}</span>
                                }
                            },
                            { title: 'Valor', dataIndex: 'paymentValue', render: (value) => <span style={{ fontSize: 'smaller' }}>{value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span> },
                            { title: 'Faturamento', dataIndex: 'paymentBilling', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            { title: 'CNPJ', dataIndex: 'paymentCnpj', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            {
                                title: 'Ação',
                                key: 'action',
                                render: (text, record, index) => (
                                    <div>
                                        <span
                                            onClick={async () => {
                                                await this.setState({ portionForm: record, portionIndexToUpdate: index })
                                                this.setState({ orderSimulationPortionFormModal: true })
                                            }}
                                            className='link-style'
                                        >
                                            Editar
                                        </span>
                                        &ensp;
                                        <span
                                            onClick={async () => {
                                                let dataSourcePaymentConditions = this.state.orderForm.dataSourcePaymentConditions
                                                dataSourcePaymentConditions.splice(index, 1)
                                                this.setState({ orderForm: { ...this.state.orderForm, dataSourcePaymentConditions: dataSourcePaymentConditions } })
                                            }}
                                            className='link-style'
                                            style={{ color: 'red' }}
                                        >
                                            Deletar
                                        </span>
                                    </div>
                                ),
                            }
                        ]}
                        locale={{ emptyText: 'Informe ao menos uma parcela' }}
                        dataSource={this.state.orderForm.dataSourcePaymentConditions}
                    />
                    <Button
                        style={{ marginTop: '20px', marginBottom: '40px' }}
                        type='primary'
                        onClick={() =>
                            this.setState({ orderSimulationPortionFormModal: true })
                        }
                    >Adicionar parcela</Button>

                    <Divider>Prazos de entrega</Divider>
                    <Table
                        size='small'
                        columns={[
                            { title: 'Semanas', dataIndex: 'weeks', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            { title: 'Serviço', dataIndex: 'activity', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            { title: 'Responsabilidade', dataIndex: 'responsibility', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            { title: 'Kits', dataIndex: 'kits', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            { title: 'Lote', dataIndex: 'lote', render: (value) => <span style={{ fontSize: 'smaller' }}>{value}</span> },
                            {
                                title: 'Início', dataIndex: 'expected_date', render: (value) => {
                                    return <span style={{ fontSize: 'smaller' }}>{value ? moment(value).format('DD/MM/YY') : null}</span>
                                }
                            },
                            {
                                title: 'Término', dataIndex: 'accomplished_date', render: (value) => {
                                    return <span style={{ fontSize: 'smaller' }}>{value ? moment(value).format('DD/MM/YY') : null}</span>
                                }
                            },
                            {
                                title: 'Ação',
                                key: 'action',
                                render: (text, record, index) => (
                                    <div>
                                        <span
                                            onClick={async () => {
                                                await this.setState({ scheduleForm: record, scheduleIndexToUpdate: index })
                                                this.setState({ orderSimulationScheduleFormModal: true })
                                            }}
                                            className='link-style'
                                        >
                                            Editar
                                        </span>
                                        &ensp;
                                        <span
                                            onClick={async () => {
                                                let dataSourceFinancialSchedule = this.state.orderForm.dataSourceFinancialSchedule
                                                dataSourceFinancialSchedule.splice(index, 1)
                                                this.setState({ orderForm: { ...this.state.orderForm, dataSourceFinancialSchedule: dataSourceFinancialSchedule } })
                                            }}
                                            className='link-style'
                                            style={{ color: 'red' }}
                                        >
                                            Deletar
                                        </span>
                                    </div>
                                ),
                            }
                        ]}
                        locale={{ emptyText: 'Informe ao menos um prazo' }}
                        dataSource={this.state.orderForm.dataSourceFinancialSchedule}
                    />
                    <Button
                        style={{ marginTop: '20px', marginBottom: '40px' }}
                        type='primary'
                        onClick={() =>
                            this.setState({ orderSimulationScheduleFormModal: true })
                        }
                    >Adicionar prazo</Button>
                </Modal>

                <Modal
                    visible={this.state.orderSimulationPortionFormModal}
                    title='Parcela'
                    width={700}
                    onCancel={() => {
                        this.setState({ orderSimulationPortionFormModal: false })
                        this.clearPortionForm()
                    }}
                    onOk={async () => {
                        let dataSourcePaymentConditions = this.state.orderForm.dataSourcePaymentConditions
                        this.state.portionIndexToUpdate !== null ?
                            dataSourcePaymentConditions[this.state.portionIndexToUpdate] = this.state.portionForm :
                            dataSourcePaymentConditions.push(this.state.portionForm)

                        await this.setState({
                            orderForm: {
                                ...this.state.orderForm,
                                dataSourcePaymentConditions: dataSourcePaymentConditions,
                            },
                            orderSimulationPortionFormModal: false
                        })

                        this.clearPortionForm()
                    }}
                >
                    {
                        this.state.orderSimulationPortionFormModal ?
                            <OrderGeneratorPortionForm
                                updatePortionForm={portionForm => this.setState({ portionForm })}
                                form={this.state.portionForm}
                            /> :
                            null
                    }
                </Modal>

                <Modal
                    visible={this.state.orderSimulationScheduleFormModal}
                    title='Prazo'
                    width={700}
                    onCancel={() => {
                        this.setState({ orderSimulationScheduleFormModal: false })
                        this.clearScheduleForm()
                    }}
                    onOk={async () => {
                        let dataSourceFinancialSchedule = this.state.orderForm.dataSourceFinancialSchedule
                        this.state.scheduleIndexToUpdate !== null ?
                            dataSourceFinancialSchedule[this.state.scheduleIndexToUpdate] = this.state.scheduleForm :
                            dataSourceFinancialSchedule.push(this.state.scheduleForm)

                        await this.setState({
                            orderForm: {
                                ...this.state.orderForm,
                                dataSourceFinancialSchedule: dataSourceFinancialSchedule,
                            },
                            orderSimulationScheduleFormModal: false
                        })

                        this.clearScheduleForm()
                    }}
                >
                    {
                        this.state.orderSimulationScheduleFormModal ?
                            <OrderGeneratorScheduleForm
                                updateScheduleForm={scheduleForm => this.setState({ scheduleForm })}
                                form={this.state.scheduleForm}
                            /> :
                            null
                    }
                </Modal>
            </div>
        )
    }
}