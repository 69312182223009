import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Spin } from 'antd'
import React, { Component } from 'react'
import { findAll, findWhere, findWhereMultiple, getCurrentUser } from '../../../../../utils/db_functions'
import { calculateTotals } from '../../suport-files/proposal-functions'
import moment from 'moment'
import pt_BR from 'antd/es/locale-provider/pt_BR'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default class PaymentStep extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loadingMsg: null,
            auth: this.props.auth,
            salesChannels: [],
            user: null,
            totalPrice: 0,
            temporary: {
                additionInformation: '',
            },
            discountsConfigs: 100,
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const user = await getCurrentUser()

        const discountConfigs = this.props.dataConfigs['payment-discountConfigs'] || await findAll('DiscountsConfigs')
        let discountsLimits = this.props.auth['_groups'].map(el => {
            let elDiscountLimit = discountConfigs.find(child_el => {
                return child_el.get('group').id === el.id;
            })
            return elDiscountLimit && elDiscountLimit.get('commercial_limit');
        })
        const discountsConfigs = discountsLimits.reduce((a, b) => Math.max(a, b))

        const dataConfigsArray = await findWhere('Config', 'name', ['proposal_form_notes_condições'])
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {})

        this.setState({ loadingMsg: 'Carregando canais de vendas...' })
        let salesChannels =
            this.props.dataConfigs['product-salesChannels'] ||
            await findWhereMultiple(
                'SalesChannel',
                [
                    { field: 'disabled', value: false, op: 'equalTo', logicOp: '$and' },
                    { field: 'customer_type', value: !this.props.getValue('is_company') ? 'pf' : 'pj', op: 'equalTo', logicOp: '$and' },
                ]
            )

        if (salesChannels && salesChannels.length) {
            salesChannels = salesChannels[0].get('automatic_choose_rules')
        }

        this.setState({ loadingMsg: 'Calculando preço total...', user, discountsConfigs, dataConfigs })

        const productBlocks = this.props.getValue('product_blocks')

        const { totalPrice } = await calculateTotals(productBlocks, this.props.inputs, this.props.formulas, null, (this.props.getValue('generalInfo') && this.props.getValue('generalInfo')['isMultipleCotation']))

        this.setState({ loading: false, loadingMsg: null, totalPrice, salesChannels })

        this.props.setDataConfigs({
            'payment-discountConfigs': discountConfigs,
            'product-salesChannels': salesChannels,
        })
    }

    render() {
        return (
            <div style={{ paddingTop: '40px' }}>
                <Row gutter={24}>
                    <Col span={16}>
                        <Spin spinning={this.state.loading} size='large' tip={this.state.loadingMsg || 'Carregando as informações...'}>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item className="space" label={'Desconto'}>
                                        <Select
                                            disabled={this.props.disabledFields}
                                            value={this.props.getValue('discount_description')}
                                            onChange={value => this.props.updateDataValue('discount_description', value)}
                                            placeholder="Desconto" size="large"
                                        >
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            <Select.Option value='Promocional'>Promocional</Select.Option>
                                            <Select.Option value='Especial'>Especial</Select.Option>
                                            <Select.Option value='Fidelidade'>Fidelidade</Select.Option>
                                            <Select.Option value='Matéria Prima'>Matéria Prima</Select.Option>
                                            <Select.Option value='Antecipado 100%'>Antecipado 100%</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item className="space" label={'Desconto %'}>
                                        <InputNumber
                                            disabled={this.props.disabledFields || !(this.props.auth && this.props.auth['_commercialDiscountPermission'])}
                                            placeholder="Desconto"
                                            onChange={value => this.props.updateDataValue('discount', value)}
                                            value={this.props.getValue("discount")}
                                            size="large"
                                            type='number'
                                            max={this.state.discountsConfigs}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item className="space" label={'Desconto R$'}>
                                        <InputNumber
                                            disabled={this.props.disabledFields || !(this.props.auth && this.props.auth['_commercialDiscountPermission'])}
                                            placeholder="Desconto R$"
                                            value={this.props.getValue('generalInfo') && this.props.getValue('generalInfo')['discountTotalValue']}
                                            onChange={async value => {
                                                const generalInfo = {
                                                    ...this.props.getValue('generalInfo'),
                                                    discountTotalValue: value
                                                }
                                                await this.props.updateDataValue('generalInfo', generalInfo)
                                            }}

                                            size="large"
                                            type='number'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item className="space" label={'Validade'}>
                                        <DatePicker
                                            onChange={value => this.props.updateDataValue('discountValidity', value.toString())}
                                            size="large"
                                            placeholder="Validade"
                                            format="DD/MM/YYYY"
                                            value={moment(this.props.getValue("discountValidity")).isValid() ? moment(this.props.getValue("discountValidity")) : null}
                                            locale={pt_BR}
                                            showToday={false}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Spin>

                        <Row gutter={24}>
                            <Col span={24} >
                                <h2 style={{ marginBottom: 20 }}>Observações gerais</h2>
                            </Col>
                            <Col span={24}>
                                <ReactQuill
                                    value={this.props.getValue("generalObservation") || ''}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': [1, 2, 3, false] }],
                                            ['bold', 'italic', 'underline', 'link', { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                                        ],
                                    }}
                                    formats={[
                                        'header',
                                        'bold', 'italic', 'underline', 'link', 'align',
                                        'list', 'bullet'
                                    ]}
                                    onChange={event => this.props.updateDataValue('generalObservation', event)}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <div className='note-box'>
                            <div className='note-title'>Instruções:</div>
                            <div>{
                                ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_condições) || [])
                                    .map(paragraph => <p>{paragraph}</p>)
                            }</div>
                        </div>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={24}>
                    <Col span={24} >
                        <h2 style={{ marginBottom: 20 }}>Informações Complementares</h2>
                    </Col>
                    <Col span={24}>
                        <Form.Item className="space" label='Informação Complementar'>
                            <Input
                                // disabled 
                                size="large"
                                onChange={event => {
                                    let localState = this.state;
                                    localState.temporary.additionInformation = event.target.value;
                                    this.setState({ temporary: localState.temporary });
                                }}
                                value={this.state.temporary.additionInformation}
                                placeholder="Informação Complementar"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Button
                            disabled={!this.state.temporary.additionInformation}
                            type='primary'
                            size='large'
                            onClick={() => {
                                let localState = this.state;
                                let historic = this.props.getValue('additionInformationHistoric') || [];
                                historic.unshift({
                                    comment: this.state.temporary.additionInformation,
                                    author: this.state.user.get('name'),
                                    date: Date.now(),
                                });
                                this.props.updateDataValue('additionInformationHistoric', historic);
                                localState.temporary.additionInformation = '';
                                this.setState({ temporary: localState.temporary });
                            }}
                        >
                            Adicionar informação ao histórico
                        </Button>
                    </Col>
                </Row><br /><br />

                {
                    this.props.getValue('additionInformationHistoric') &&
                    this.props.getValue('additionInformationHistoric')
                        .map((comment, index) => {
                            return (
                                <Row key={`additionalInformation_${index}`} style={{ backgroundColor: (index % 2 !== 0 ? 'white' : '#f2f2f2'), padding: 20 }}>
                                    {comment.author}<br />
                                    <span style={{ fontSize: 'smaller' }}>{comment.comment}</span><br />
                                    {new Date(comment.date).toLocaleDateString('pt-BR') + ' ' + new Date(comment.date).toLocaleTimeString()}
                                </Row>
                            );
                        })
                }
            </div>
        )
    }
}