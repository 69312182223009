import React from 'react'
import { Icon, Switch } from 'antd'
export default {
  "fields": [
    [
      {
        "title": "Nome",
        "key": "name",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="tags" />}
      },
      {
        "title": "Valor",
        "key": "value",
        "type": "number",
        "element-attr": { "required": false, "prefix": <Icon type="tags" />, min: 0, max: 100}
      },
      {
        "title": "Instalação NÃO inclusa no valor do KIT",
        "key": "abridge_installation",
        "type": "switch",
        "element-attr": { "required": false }
      }
    ]
  ],
  "submit": {
    "collection": "Instalation"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Nome",
      "key": "name",
      "dataIndex": "name",
      "type": "text",
      className: "name"
    },
    {
      title: "Valor",
      key: 'value',
      dataIndex: 'value',
      type: "number",
      render: r => `${r}%`
    },
    {
      title: "Instalação NÃO inclusa",
      key: 'abridge_installation',
      dataIndex: 'abridge_installation',
      type: "switch",
      render: r => <Switch defaultChecked={r} disabled />
    }
  ],
  "title-navigation": <div><Icon type="tool" /> Instalação</div>,
  "title-module": "Instalação",
  "module": "Instalation",
  "router-base": "/panel/instalacao"
}

