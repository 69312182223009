import React from 'react'
import InputCompositionTable from '../entities/input-composition-table'
import InputFinishingTable from '../entities/input-finishing-table';

const inputCompositionFields = (props) => {

    return [
        [
            {
                title: 'Código do Produto',
                key: 'product_code_in_input_composition_fields',
                type: "render",
                colSize: 8,
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {_.formRef.product_code}
                        </div>
                    );
                },
            }
        ],
        [
            {
                key: 'descriptions_render',
                type: 'render',
                title: 'Composição dos insumos',
                render: (_, form) => {
                    return (
                        <InputCompositionTable
                            inputGroups={_.formRef.descriptions}
                            convertionFormulas={props.convertionFormulas}
                            calculateUnitCost={props.calculateUnitCost}
                            calculateSubTotalCost={(v, inputs) => (props.calculateSubTotalCost(v, inputs) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            updateDescriptions={descriptions => {
                                _.formRef.descriptions = descriptions
                                form.state.invalidForm = false
                                form.forceUpdate()
                            }}
                        />
                    )
                }
            }
        ],
        [{ key: 'descriptions' }],
        [
            {
                key: 'finishing_options_inputs_render',
                type: 'render',
                title: 'Insumos - Opções de acabamento',
                render: (_, form) => {
                    return (
                        <InputFinishingTable
                            inputFinishingGroups={_.formRef.finishing_options_inputs}
                            convertionFormulas={props.convertionFormulas}
                            calculateUnitCost={props.calculateUnitCost}
                            calculateSubTotalCost={(v, inputs) => (props.calculateSubTotalCost(v, inputs) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            updateDescriptions={finishing_options_inputs => {
                                _.formRef.finishing_options_inputs = finishing_options_inputs
                                form.state.invalidForm = false
                                form.forceUpdate()
                            }}
                        />
                    )
                }
            }
        ],
        [{ key: 'finishing_options_inputs' }],
    ]
}

export default inputCompositionFields;