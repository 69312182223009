import { Button, Col, InputNumber, message, Modal, Popover, Row } from "antd";
import React, { Component, Fragment } from "react";
import { findById, save } from "../../../../utils/db_functions";

export default class MarginSimulationModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            marginSimulationModal: false,
            analiticValueData: null,
            marginSimulationData: null,
            loadingButton: false,
        }
    }

    componentDidMount = async () => { }

    getValuesFromProposal = async () => {
        const proposal = await findById('Proposal', this.props.proposal.objectId)

        if (!proposal) return message.error('Algo deu errado.')

        const generalInfo = proposal.get('generalInfo')

        if (!generalInfo.analiticValueData) return message.info('É necessário gerar o pdf dessa proposta novamente para utilizar o simulador de margens.')
        else {
            const analiticValueData = generalInfo.analiticValueData
            const marginSimulationData =
                generalInfo.marginSimulationData ||
                {
                    totalOrder: parseFloat((proposal.get('final_value') || 0).toFixed(2)),
                    productsInvoicing: 0,
                    clientInputsInvoicing: 0,
                    servicesInvoicing: 0,
                }

            this.setState({
                marginSimulationModal: true,
                analiticValueData,
                marginSimulationData,
            })
        }
    }

    calculateArrayValue = (array) => {
        return array.reduce((prev, current) => prev += (current || 0), 0)
    }

    save = async () => {
        const proposalId = this.props.proposal.objectId

        try {

            await save('Proposal', { generalInfo: { ...this.props.proposal.generalInfo, marginSimulationData: this.state.marginSimulationData } }, proposalId)
            message.success('Dados da simulação salvos com sucesso')
            this.setState({ marginSimulationModal: false })

        } catch (error) {
            message.error('Erro ao salvar os dados da simulação de margem')
        }
    }

    render() {

        return (
            <Fragment>
                <Popover content='Simulação de margem'>
                    <Button
                        icon='dollar'
                        onClick={() => this.getValuesFromProposal()}
                    />
                </Popover>

                <Modal
                    title='Relatório de margem do pedido'
                    visible={this.state.marginSimulationModal}
                    onCancel={() => this.setState({ marginSimulationModal: false })}
                    cancelText='Fechar'
                    onOk={async () => {
                        this.setState({ loadingButton: true })
                        await this.save()
                        this.setState({ loadingButton: false })
                    }}
                    okText='Salvar'
                    width={800}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Row gutter={24}>
                            <Col span={12}><strong>DESCRIÇÃO</strong></Col>
                            <Col span={6}><strong>VALOR</strong></Col>
                            <Col span={6}><strong>PERCENTUAL</strong></Col>
                        </Row>

                        <Row gutter={24} style={{ backgroundColor: 'yellow' }}>
                            <Col span={12}><strong>Total do pedido</strong></Col>
                            <Col span={6}>
                                <InputNumber
                                    size="small"
                                    style={{ width: '100%' }}
                                    formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => {
                                        let decimals = value.split(',')[1]
                                        if (decimals && decimals.length > 2) {
                                            decimals = decimals.substring(0, 2)
                                            value = `${value.split(',')[0]},${decimals}`
                                        }
                                        return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
                                    }}
                                    value={this.state.marginSimulationData && this.state.marginSimulationData.totalOrder}
                                    onChange={e => this.setState({ marginSimulationData: { ...this.state.marginSimulationData, totalOrder: e } })}
                                />
                            </Col>
                            <Col span={6}><strong>100%</strong></Col>
                        </Row><br />

                        <Row gutter={24} style={{ backgroundColor: 'lightGrey' }}>
                            <Col span={12}><strong>FATURAMENTO</strong></Col>
                            <Col span={6}>
                                <strong>
                                    {
                                        this.calculateArrayValue([
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.productsInvoicing) || 0),
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.servicesInvoicing) || 0),
                                        ]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    }
                                </strong>
                            </Col>
                            <Col span={6}>
                                <strong>
                                    {
                                        ((
                                            this.calculateArrayValue([
                                                ((this.state.marginSimulationData && this.state.marginSimulationData.productsInvoicing) || 0),
                                                ((this.state.marginSimulationData && this.state.marginSimulationData.servicesInvoicing) || 0),
                                            ]) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)
                                        ) * 100).toFixed(2)
                                    }%
                                </strong>
                            </Col>
                        </Row>
                        {
                            [
                                { label: 'Produtos (1)', value: 'productsInvoicing', color: null },
                                { label: 'Matéria Prima Cliente', value: 'clientInputsInvoicing', color: 'dodgerBlue' },
                                { label: 'Serviços (2)', value: 'servicesInvoicing', color: null },
                            ].map(el => {
                                return (
                                    <Row gutter={24} style={{ color: el.color || null }}>
                                        <Col span={12}>{el.label}</Col>
                                        <Col span={6}>
                                            <InputNumber
                                                size="small"
                                                style={{ width: '100%' }}
                                                formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                parser={value => {
                                                    let decimals = value.split(',')[1]
                                                    if (decimals && decimals.length > 2) {
                                                        decimals = decimals.substring(0, 2)
                                                        value = `${value.split(',')[0]},${decimals}`
                                                    }
                                                    return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
                                                }}
                                                value={this.state.marginSimulationData && this.state.marginSimulationData[el.value]}
                                                onChange={e => this.setState({ marginSimulationData: { ...this.state.marginSimulationData, [el.value]: e } })}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            {
                                                ((
                                                    this.state.marginSimulationData && this.state.marginSimulationData[el.value]
                                                    / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)
                                                ) * 100).toFixed(2)
                                            }%
                                        </Col>
                                    </Row>
                                )
                            })
                        }<br />

                        <Row gutter={24} style={{ backgroundColor: 'lightGrey' }}>
                            <Col span={12}><strong>CUSTOS DE PRODUTOS</strong></Col>
                            <Col span={6}>
                                <strong>
                                    {
                                        this.calculateArrayValue([
                                            ((this.state.analiticValueData && this.state.analiticValueData.productCost_inputs) || 0),
                                            ((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0),
                                        ]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    }
                                </strong>
                            </Col>
                            <Col span={6}>
                                <strong>
                                    {((
                                        this.calculateArrayValue([
                                            ((this.state.analiticValueData && this.state.analiticValueData.productCost_inputs) || 0),
                                            ((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0),
                                        ]) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)
                                    ) * 100).toFixed(2)}%
                                </strong>
                            </Col>
                        </Row>
                        {
                            [
                                {
                                    label: 'Insumos',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.productCost_inputs) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.productCost_inputs) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                    color: null
                                },
                                {
                                    label: 'Total HH',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                    color: null
                                },
                                {
                                    label: 'Impostos (base (1))',
                                    value: (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                    percent: 0.2395,
                                    color: 'red'
                                },
                            ].map(el => {
                                return (
                                    <Row gutter={24} style={{ color: el.color || null }}>
                                        <Col span={12}>{el.label}</Col>
                                        <Col span={6}>{(el.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
                                        <Col span={6}>{(el.percent * 100).toFixed(2)}%</Col>
                                    </Row>
                                )
                            })
                        }<br />

                        <Row gutter={24} style={{ backgroundColor: 'lightGrey' }}>
                            <Col span={12}><strong>CUSTOS DE SERVIÇOS</strong></Col>
                            <Col span={6}>
                                <strong>
                                    {
                                        this.calculateArrayValue([
                                            ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs) || 0),
                                            ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0),
                                        ]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    }
                                </strong>
                            </Col>
                            <Col span={6}>
                                <strong>
                                    {((
                                        this.calculateArrayValue([
                                            ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs) || 0),
                                            ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0),
                                        ]) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)
                                    ) * 100).toFixed(2)}%
                                </strong>
                            </Col>
                        </Row>
                        {
                            [
                                {
                                    label: 'Insumos',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                    color: null
                                },
                                {
                                    label: 'Total HH',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                    color: null
                                },
                                {
                                    label: 'Impostos (base (2))',
                                    value: (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                    percent: 0.1095,
                                    color: 'red'
                                },
                            ].map(el => {
                                return (
                                    <Row gutter={24} style={{ color: el.color || null }}>
                                        <Col span={12}>{el.label}</Col>
                                        <Col span={6}>{(el.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
                                        <Col span={6}>{(el.percent * 100).toFixed(2)}%</Col>
                                    </Row>
                                )
                            })
                        }<br />

                        <Row gutter={24} style={{ backgroundColor: 'lightGrey' }}>
                            <Col span={12}><strong>TOTAL CUSTO FIXO</strong></Col>
                            <Col span={6}>
                                <strong>
                                    {
                                        this.calculateArrayValue([
                                            (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) * 0.10),
                                            ((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0),
                                            ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0)
                                        ]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    }
                                </strong>
                            </Col>
                            <Col span={6}>
                                <strong>
                                    {((
                                        this.calculateArrayValue([
                                            (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) * 0.10),
                                            ((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0),
                                            ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0)
                                        ]) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)
                                    ) * 100).toFixed(2)}%
                                </strong>
                            </Col>
                        </Row>
                        {
                            [
                                {
                                    label: 'Rateio custo fixo',
                                    value: (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) * 0.10),
                                    percent: 0.10,
                                },
                                {
                                    label: 'HH- produtos',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.productCost_hh) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                },
                                {
                                    label: 'HH- serviços',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                },
                            ].map(el => {
                                return (
                                    <Row gutter={24}>
                                        <Col span={12}>{el.label}</Col>
                                        <Col span={6}>{(el.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
                                        <Col span={6}>{(el.percent * 100).toFixed(2)}%</Col>
                                    </Row>
                                )
                            })
                        }<br />

                        <Row gutter={24} style={{ backgroundColor: 'lightGrey' }}>
                            <Col span={12}><strong>RESULTADO DO PEDIDO</strong></Col>
                            <Col span={6}>
                                <strong>
                                    {(
                                        (this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0
                                    ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </strong>
                            </Col>
                            <Col span={6}><strong></strong></Col>
                        </Row>
                        {
                            [
                                {
                                    label: 'Frete',
                                    value: ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0),
                                    percent: (((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0) / ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 1)),
                                },
                                {
                                    label: 'Total sem frete',
                                    value: ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0),
                                    percent: 1,
                                    isBold: true,
                                },
                                {
                                    label: 'Insumos',
                                    value: this.calculateArrayValue(
                                        [
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                        ]
                                    ),
                                    percent: this.calculateArrayValue(
                                        [
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                        ]
                                    ) / (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0)),
                                },
                                {
                                    label: 'Total custo fixo',
                                    value: this.calculateArrayValue(
                                        [
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh)
                                        ]
                                    ),
                                    percent: this.calculateArrayValue(
                                        [
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh)
                                        ]
                                    ) / (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0)),
                                },
                                {
                                    label: 'TOTAL CUSTO DIRETO',
                                    value: this.calculateArrayValue(
                                        [
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh)
                                        ]
                                    ),
                                    percent: this.calculateArrayValue(
                                        [
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh)
                                        ]
                                    ) / (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0)),
                                    isBold: true,
                                },
                                {
                                    label: 'Impostos',
                                    value: this.calculateArrayValue(
                                        [
                                            (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                            (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                        ]
                                    ),
                                    percent: this.calculateArrayValue(
                                        [
                                            (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                            (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                        ]
                                    ) / (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0)),
                                    color: 'red',
                                },
                                {
                                    label: 'Total custos',
                                    value: this.calculateArrayValue(
                                        [
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh),
                                            (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                            (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                        ]
                                    ),
                                    percent: this.calculateArrayValue(
                                        [
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                            ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                            (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                            (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh),
                                            (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                            (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                        ]
                                    ) / (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0)),
                                },
                            ].map(el => {
                                return (
                                    <Row gutter={24} style={{ color: el.color, fontWeight: el.isBold ? 'bold' : 'normal' }}>
                                        <Col span={12}>{el.label}</Col>
                                        <Col span={6}>{(el.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
                                        <Col span={6}>{(el.percent * 100).toFixed(2)}%</Col>
                                    </Row>
                                )
                            })
                        }

                        <Row gutter={24} style={{ backgroundColor: 'lightGreen' }}>
                            <Col span={12}><strong>Margem de contribuição</strong></Col>
                            <Col span={6}>
                                <strong>
                                    {(
                                        ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0) -
                                        this.calculateArrayValue(
                                            [
                                                (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                                (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                                ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                                (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                                (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh),
                                                (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                                (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                            ]
                                        )
                                    ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </strong>
                            </Col>
                            <Col span={6}>
                                <strong>
                                    {(
                                        (
                                            (
                                                ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0) -
                                                this.calculateArrayValue(
                                                    [
                                                        (this.state.analiticValueData && this.state.analiticValueData.productCost_inputs),
                                                        (this.state.analiticValueData && this.state.analiticValueData.serviceCost_inputs),
                                                        ((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) * 0.10),
                                                        (this.state.analiticValueData && this.state.analiticValueData.productCost_hh),
                                                        (this.state.analiticValueData && this.state.analiticValueData.serviceCost_hh),
                                                        (this.state.marginSimulationData && this.state.marginSimulationData['productsInvoicing']) * 0.2395,
                                                        (this.state.marginSimulationData && this.state.marginSimulationData['servicesInvoicing']) * 0.1095,
                                                    ]
                                                )
                                            ) / (((this.state.marginSimulationData && this.state.marginSimulationData.totalOrder) || 0) - ((this.state.analiticValueData && this.state.analiticValueData.proposalDelivery) || 0))
                                        ) * 100
                                    ).toFixed(2)}%
                                </strong>
                            </Col>
                        </Row>

                    </div>
                </Modal>
            </Fragment>
        )

    }

}