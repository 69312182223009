import { Col, DatePicker, Form, Input, Row } from 'antd'
import React, { Component } from 'react'

export default class OrderGeneratorScheduleForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            weeks: null,
            activity: null,
            responsibility: null,
            kits: null,
            lote: null,
            expected_date: null,
            accomplished_date: null,
        }
    }

    componentDidMount = async () => {
        if (this.props.form) {
            this.setState({
                weeks: this.props.form.weeks,
                activity: this.props.form.activity,
                responsibility: this.props.form.responsibility,
                kits: this.props.form.kits,
                lote: this.props.form.lote,
                expected_date: this.props.form.expected_date,
                accomplished_date: this.props.form.accomplished_date,
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevState) !== JSON.stringify(this.state))
            this.props.updateScheduleForm(this.state)
    }

    render() {
        return (
            <div>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label='Semanas'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='Semanas'
                                value={this.state.weeks}
                                onChange={e => this.setState({ weeks: e.target.value })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item label='Serviço'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='Serviço'
                                value={this.state.activity}
                                onChange={e => this.setState({ activity: e.target.value })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item label='Responsabilidade'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='Responsabilidade'
                                value={this.state.responsibility}
                                onChange={e => this.setState({ responsibility: e.target.value })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label='Kits'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='Kits'
                                value={this.state.kits}
                                onChange={e => this.setState({ kits: e.target.value })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label='Lote'>
                            <Input
                                style={{ width: '100%' }}
                                placeholder='Lote'
                                value={this.state.lote}
                                onChange={e => this.setState({ lote: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label='Início'>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder='Início'
                                value={this.state.expected_date}
                                onChange={e => this.setState({ expected_date: e })}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label='Término'>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder='Término'
                                value={this.state.accomplished_date}
                                onChange={e => this.setState({ accomplished_date: e })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        )
    }

}