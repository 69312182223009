import React from 'react'
import { Icon, Row, Button, Divider, Table, message, notification, Tag, Tabs } from 'antd'
import Chart from 'react-google-charts'
import Parse from 'parse'

import Auth from '../../../auth/Auth'

let usageTypes = [];

const CommercialManagementComponent = {
  "fields": [
    [
      {
        "title": "Descrição",
        "key": "description",
        "type": "text",
        "element-attr": { "required": true }
      },
      {
        type: 'multi-select',
        key: 'months',
        title: 'Meses',
        options: [
          { label: 'Todos', value: 'all' }, { label: 'Janeiro', value: 'jan' }, { label: 'Fevereiro', value: 'fev' }, { label: 'Março', value: 'mar' },
          { label: 'Abril', value: 'abr' }, { label: 'Maio', value: 'mai' }, { label: 'Junho', value: 'jun' },
          { label: 'Julho', value: 'jul' }, { label: 'Agosto', value: 'ago' }, { label: 'Setembro', value: 'set' },
          { label: 'Outubro', value: 'out' }, { label: 'Novembro', value: 'nov' }, { label: 'Dezembro', value: 'dez' }
        ],
        loaded: false,
        'element-attr': { required: true }
      },
      {
        type: 'select',
        key: 'year',
        title: 'Ano',
        options: [
          { label: '2020', value: '2020' }, { label: '2021', value: '2021' }, { label: '2022', value: '2022' }, { label: '2023', value: '2023' }, { label: '2024', value: '2024' },
          { label: '2025', value: '2025' }, { label: '2026', value: '2026' }, { label: '2027', value: '2027' }, { label: '2028', value: '2028' }, { label: '2029', value: '2029' },
          { label: '2030', value: '2030' }, { label: '2031', value: '2031' }, { label: '2032', value: '2032' }, { label: '2033', value: '2033' }, { label: '2034', value: '2034' },
          { label: '2035', value: '2035' }, { label: '2036', value: '2036' }, { label: '2037', value: '2037' }, { label: '2038', value: '2038' }, { label: '2039', value: '2039' },
          { label: '2040', value: '2040' }, { label: '2041', value: '2041' }, { label: '2042', value: '2042' }, { label: '2043', value: '2043' }, { label: '2044', value: '2044' },
          { label: '2045', value: '2045' }, { label: '2046', value: '2046' }, { label: '2047', value: '2047' }, { label: '2048', value: '2048' }, { label: '2049', value: '2049' },
        ],
        loaded: false,
        'element-attr': { required: true }
      },
    ]
  ],
  "submit": {
    "collection": "ComercialManagement"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Descrição",
      "key": "description",
      "dataIndex": "description",
      "type": "text",
      className: "description"
    },
    {
      title: "Meses",
      key: "months",
      dataIndex: "months",
      type: "render",
      render: (months, row) => {
        return months.map((month, i) => <Tag key={i}>{month === 'all' ? 'todos' : month}</Tag>)
      }
    },
    {
      "title": "Ano",
      "key": "year",
      "dataIndex": "year",
      "type": "text",
    }
  ],
  "title-navigation": <div><Icon type="bar-chart" /> Relatórios - Comercial</div>,
  "title-module": "Relatórios - Comercial",
  "module": "ComercialManagement",
  "router-base": "/panel/relatorios-comercial",
  data: {
    tables: [],
    newTables: [],
  },
  FormComponent: (Form, Module) => {
    if (!CommercialManagementComponent.dataToEdit && Form.props.objectEdit) { // load data to edit and format values to fill inputs
      let ParseQuery = new Parse.Query(new Parse.Object.extend(Module.collection))
      ParseQuery.get(Form.props.objectEdit).then(response => {
        CommercialManagementComponent.dataToEdit = response.toJSON()
        CommercialManagementComponent.data = CommercialManagementComponent.dataToEdit.data

        Form.setState(() => {
          return {
            formRef: CommercialManagementComponent.dataToEdit
          }
        })
      })
    }

    return (
      <div>
        <Row key="row-1" gutter={24} className="row-form-item">
          {Form.getElementByTypeSchema(Form.getNodesByKey('description'), 8, CommercialManagementComponent.dataToEdit, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('months'), 10, CommercialManagementComponent.dataToEdit, 0)}
          {Form.getElementByTypeSchema(Form.getNodesByKey('year'), 6, CommercialManagementComponent.dataToEdit, 0)}
        </Row>

        <Row key="row-2" gutter={24} className="row-form-item">
          {Form.getElementByTypeSchema({
            type: "render", key: 'btn_plus', className: 'btn-plus-no-title', render: (_, form, node) => {
              return <Button node={node} size='large' loading={loadingButton} onClick={() => {
                generateReport(form)
              }}>Gerar/Atualizar relatório</Button>
            }
          }, 6, CommercialManagementComponent.dataToEdit, 0)}
        </Row>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas</Divider>
        <Table style={{ width: '1550px' }} size="small" bordered columns={columns[1]} dataSource={CommercialManagementComponent.data['tables'][5]} />

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas emitidas - quantidade</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[0]} dataSource={CommercialManagementComponent.data['tables'][0]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(0, 'tables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(0, 'tables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas emitidas - número de Kits</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[0]} dataSource={CommercialManagementComponent.data['tables'][1]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(1, 'tables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(1, 'tables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas emitidas - Valor em R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['tables'][2]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(2, 'tables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(2, 'tables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas ativas - Valor R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['tables'][3]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(3, 'tables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(3, 'tables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas canceladas - Valor R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['tables'][4]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(4, 'tables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(4, 'tables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Especificação BIM - Valor R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['newTables'] && CommercialManagementComponent.data['newTables'][0]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(0, 'newTables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(0, 'newTables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Negociação até 180 dias - Valor R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['newTables'] && CommercialManagementComponent.data['newTables'][2]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(2, 'newTables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(2, 'newTables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Compra acima 180 dias - Valor R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['newTables'] && CommercialManagementComponent.data['newTables'][3]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(3, 'newTables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(3, 'newTables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>

        <Divider orientation='left' style={{ marginTop: '80px' }}>Propostas aprovadas - Valor R$</Divider>
        <Tabs style={{ width: '1550px' }}>
          <Tabs.TabPane key="1" tab={<span><Icon type="database" />Tabela</span>}>
            <Table size="small" bordered pagination={false} columns={columns[2]} dataSource={CommercialManagementComponent.data['newTables'] && CommercialManagementComponent.data['newTables'][4]} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={<span><Icon type="pie-chart" />Gráfico Pizza</span>}>
            <Chart width={'100%'} height={'500px'} chartType="PieChart" loader={<div>Carregando gráfico</div>} data={pieChartData(4, 'newTables')} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={<span><Icon type="bar-chart" />Gráfico Barras</span>}>
            <Chart width={'100%'} height={'500px'} chartType="Bar" loader={<div>Carregando gráfico</div>} data={barChartData(4, 'newTables')} options={{ vAxis: { minvalue: 0 } }} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  },
  FormWillMount: () => {
    let tables = [];
    const newArray = ['1', '2', '3', '4', '5'];
    newArray.forEach(() => {
      tables.push(
        [
          ...usageTypes.map((type, i) => {
            return { key: (i + 1).toString(), type }
          }),
          { key: (usageTypes.length + 1).toString(), type: 'Total' }
        ]
      )
    });
    CommercialManagementComponent.data = { tables, newTables: tables }
  },
  ListWillMount: () => {
    let query = new Parse.Query('Config')
    query.equalTo('name', 'usage')
    query.find().then(usages => {
      usageTypes = usages[0].get('value');
    })
  },
  SubmitForm: (FormRef, ObjectEdit, ObjectId, FormModule) => {
    let objectWithValues = parseDataToSave(FormRef, FormModule)
    let dinamicsValues = CommercialManagementComponent.data
    objectWithValues = { ...objectWithValues, data: dinamicsValues }
    let refs = FormModule.getRefWithFormItem(false)
    let invalid = false;
    for (let key in refs) {
      // checking if exist empty required inputs.
      if (!FormModule.nodeIsValid(FormModule.refs[key].props.node) || (!FormModule.nodeHasEmptyValue(FormModule.refs[key].props.value || FormModule.state.formRef[key]) && FormModule.nodeIsRequired(FormModule.refs[key].props.node))) {
        notification.error({
          message: 'Erro ao salvar',
          description: FormModule.refs[key].props.node.errorMessage || `Valor inválido no campo: ${FormModule.refs[key].props.node.title}`,
          duration: 5
        })
        invalid = true;
        break;
      }
    }

    let data = objectWithValues
    if (invalid) return false;
    if (ObjectEdit) return updateCommercialManagement(objectWithValues, ObjectId, FormModule)

    let Extension = new Parse.Object(FormModule.props.module.form.module)
    data['updatedBy'] = Parse.User.current()
    data['createdBy'] = Parse.User.current()
    Extension.save(data).then(saved => {
      FormModule.afterSave()
    }).catch(err => {
      notification.error({
        message: 'Erro ao salvar',
        description: err.message
      })
    })
  },
  onClickActionEdit: row => {
    CommercialManagementComponent.dataToEdit = null
  },
}

let columns = [
  [
    { title: 'Tipo', dataIndex: 'type', key: 'type' },
    { title: 'Ano', dataIndex: 'year', key: 'year' },
    { title: 'Janeiro', dataIndex: 'jan', key: 'jan' },
    { title: 'Fevereiro', dataIndex: 'fev', key: 'fev' },
    { title: 'Março', dataIndex: 'mar', key: 'mar' },
    { title: 'Abril', dataIndex: 'abr', key: 'abr' },
    { title: 'Maio', dataIndex: 'mai', key: 'mai' },
    { title: 'Junho', dataIndex: 'jun', key: 'jun' },
    { title: 'Julho', dataIndex: 'jul', key: 'jul' },
    { title: 'Agosto', dataIndex: 'ago', key: 'ago' },
    { title: 'Setembro', dataIndex: 'set', key: 'set' },
    { title: 'Outubro', dataIndex: 'out', key: 'out' },
    { title: 'Novembro', dataIndex: 'nov', key: 'nov' },
    { title: 'Dezembro', dataIndex: 'dez', key: 'dez' },
  ],
  [
    { title: 'Cliente', dataIndex: 'name_client', key: 'name_client', width: '300px' },
    { title: 'Obra', dataIndex: 'name_work', key: 'name_work', width: '300px' },
    { title: 'Número da proposta', dataIndex: 'proposal_code', key: 'proposal_code' },
    { title: 'Primeira emissão', dataIndex: 'firstCreatedAt', key: 'firstCreatedAt' },
    { title: 'Canal de vendas', dataIndex: 'sales_channel', key: 'sales_channel' },
    {
      title: 'Status', dataIndex: 'proposal_management_status', key: 'proposal_management_status',
      render: (v) => {
        return v || 'Ativa';
      }
    },
    {
      title: 'Previsão', dataIndex: 'prevision_buy', key: 'prevision_buy', width: '120px',
      filters: [
        { text: 'IMEDIATA', value: 'IMEDIATA' }, { text: 'TRIMESTRE', value: 'TRIMESTRE' }, { text: 'SEMESTRE', value: 'SEMESTRE' }, { text: 'ANO', value: 'ANO' },
        { text: '+1 ANO', value: '+1 ANO' }, { text: '+2 ANOS', value: '+2 ANOS' }, { text: 'TESTE', value: 'TESTE' }
      ],
      onFilter: (value, record) => (record.prevision_buy || '').indexOf(value) === 0,
    },
    { title: 'Data atualização', dataIndex: 'lastIssue', key: 'lastIssue' },
    { title: 'Alterado por', dataIndex: 'updatedBy', key: 'updatedBy' },
    {
      title: 'Tipo', dataIndex: 'usage', key: 'usage', width: '120px',
      filters: usageTypes.map(el => {
        return { text: el, value: el }
      }),
      onFilter: (value, record) => record.usage.indexOf(value) === 0,
    },
    {
      title: 'PDF', dataIndex: 'pdf', key: 'pdf',
      render: (value, row) => {

        // Alteração no padrão do nome do arquivo conforme solicitado pelo cliente
        let updatedDate = row.updatedAt.split('/');
        let lastUpdatedDate = new Date(updatedDate[2], updatedDate[1], updatedDate[0]);
        let changeNameDate = new Date(2020, 8, 19);
        let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(row.name_client || '').replace('/', '')}-cod_cliente-${row.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(row.name_client || '').replace('/', '')}-${row.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        return (
          <Button title="Visualizar proposta em PDF" type="primary" shape="circle" icon="file-pdf"
            onClick={() => {
              let auth = new Auth(Parse.User.current())
              auth.init(() => {
                if (auth.hasAction(['viewProposalPDF-proposal', '*'])) window.open(pdfName, '_blink')
                else message.error('Você não tem permissão para acessar esse documento')
              })
            }}
          />
        )
      }
    },
  ],
  [
    { title: 'Tipo', dataIndex: 'type', key: 'type' },
    { title: 'Ano', dataIndex: 'year', key: 'year', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Janeiro', dataIndex: 'jan', key: 'jan', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Fevereiro', dataIndex: 'fev', key: 'fev', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Março', dataIndex: 'mar', key: 'mar', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Abril', dataIndex: 'abr', key: 'abr', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Maio', dataIndex: 'mai', key: 'mai', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Junho', dataIndex: 'jun', key: 'jun', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Julho', dataIndex: 'jul', key: 'jul', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Agosto', dataIndex: 'ago', key: 'ago', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Setembro', dataIndex: 'set', key: 'set', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Outubro', dataIndex: 'out', key: 'out', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Novembro', dataIndex: 'nov', key: 'nov', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { title: 'Dezembro', dataIndex: 'dez', key: 'dez', render: e => (e || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
  ],
]

let loadingButton = false

function generateReport(Form) {
  // Update tables columns data
  let tables = [];
  let newTables = [];
  const newArray = ['1', '2', '3', '4', '5'];
  newArray.forEach(() => {
    tables.push(
      [
        ...usageTypes.map((type, i) => {
          return { key: (i + 1).toString(), type }
        }),
        { key: (usageTypes.length + 1).toString(), type: 'Total' }
      ]
    );

    newTables.push(
      [
        ...usageTypes.map((type, i) => {
          return { key: (i + 1).toString(), type }
        }),
        { key: (usageTypes.length + 1).toString(), type: 'Total' }
      ]
    );
  });
  CommercialManagementComponent.data = { tables, newTables }

  if (!(Form.refs.months.props.value && Form.refs.months.props.value.length)) message.error('Informe os meses')
  else if (!Form.refs.year.props.value) message.error('Informe um ano')
  else {
    loadingButton = true
    Form.forceUpdate()

    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    let monthsChoosed = Form.refs.months.props.value
    let year = Form.refs.year.props.value

    // Buscar no bd as propostas do ano escolhido
    let query = new Parse.Query('Proposal').limit(100000)
    query.include('prevision_buy')
    query.include('sales_channel')
    query.include('updatedBy')
    query.startsWith('proposal_code', year)
    query.find().then(res => {
      let report = CommercialManagementComponent.data.tables;
      let newReport = CommercialManagementComponent.data.newTables;

      // Zerando todos as celulas das tabelas
      let array = [0, 1, 2, 3, 4]
      array.forEach(n => {
        report[n].forEach((el, index) => {
          report[n][index]['year'] = 0
          months.forEach((m, i) => report[n][index][months[i]] = 0)
        })

        newReport[n].forEach((el, index) => {
          newReport[n][index]['year'] = 0
          months.forEach((m, i) => newReport[n][index][months[i]] = 0)
        })
      })

      // Calculando os totais
      res = res.sort((a, b) => (a.get('proposal_code') > b.get('proposal_code')) ? 1 : ((b.get('proposal_code') > a.get('proposal_code')) ? -1 : 0))
      // Filtra apenas as propostas geradas
      res = res.filter(el => el.get('pdf'))
      res.forEach((prop, propIndex) => {

        if (propIndex === 0) report[5] = []
        // Verificar se é a última versão da proposta
        let checkFlag = false
        if ((propIndex + 1) !== res.length) {
          if (prop.get('proposal_code').split('-')[1] !== res[propIndex + 1].get('proposal_code').split('-')[1]) checkFlag = true
        } else {
          checkFlag = true
        }

        if (checkFlag) {
          let month = new Date(prop.updatedAt).getMonth()
          if (monthsChoosed.includes('all') || monthsChoosed.includes(months[month])) {
            report[0].forEach((el, i) => {

              if (el.type === prop.get('usage')) {

                // Calcular qtd de propostas emitidas
                report[0][i][months[month]] += 1;
                report[0][i]['year'] += 1;
                report[0][report[0].length - 1][months[month]] += 1;
                report[0][report[0].length - 1]['year'] += 1;

                // Calcular qtd de kits das propostas emitidas
                prop.get('product_blocks').forEach(bl => {
                  bl.products.forEach(prod => {
                    report[1][i][months[month]] += isNaN(prod.amount) ? 0 : prod.amount
                    report[1][i]['year'] += isNaN(prod.amount) ? 0 : prod.amount
                    report[1][report[0].length - 1][months[month]] += isNaN(prod.amount) ? 0 : prod.amount
                    report[1][report[0].length - 1]['year'] += isNaN(prod.amount) ? 0 : prod.amount
                  })
                })
                // Calcular valor das propostas emitidas, ativas e canceladas
                if (prop.get('final_value')) {
                  report[2][i][months[month]] += prop.get('final_value') || 0;
                  report[2][i]['year'] += prop.get('final_value') || 0;
                  report[2][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                  report[2][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  if (
                    ((prop.get('prevision_buy') && prop.get('prevision_buy').get('status')) !== 'TESTE') &&
                    ['Ativa'].includes((prop.get('proposal_management_status') || 'Ativa'))
                  ) {
                    report[3][i][months[month]] += prop.get('final_value') || 0;
                    report[3][i]['year'] += prop.get('final_value') || 0;
                    report[3][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                    report[3][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  } else if (prop.get('proposal_management_status') === 'Cancelada') {
                    report[4][i][months[month]] += prop.get('final_value') || 0;
                    report[4][i]['year'] += prop.get('final_value') || 0;
                    report[4][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                    report[4][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  } else if (prop.get('proposal_management_status') === 'EspecificaçãoBIM') {
                    newReport[0][i][months[month]] += prop.get('final_value') || 0;
                    newReport[0][i]['year'] += prop.get('final_value') || 0;
                    newReport[0][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                    newReport[0][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  } else if (prop.get('proposal_management_status') === 'Negociação') {
                    newReport[2][i][months[month]] += prop.get('final_value') || 0;
                    newReport[2][i]['year'] += prop.get('final_value') || 0;
                    newReport[2][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                    newReport[2][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  } else if (prop.get('proposal_management_status') === 'NegociaçãoSemestre') {
                    newReport[3][i][months[month]] += prop.get('final_value') || 0;
                    newReport[3][i]['year'] += prop.get('final_value') || 0;
                    newReport[3][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                    newReport[3][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  }

                  if (
                    prop.get('proposal_management_status') === 'Aprovada' ||
                    prop.get('proposal_management_status') === 'PedidoGerado' ||
                    prop.get('orderGenerated')
                  ) {
                    newReport[4][i][months[month]] += prop.get('final_value') || 0;
                    newReport[4][i]['year'] += prop.get('final_value') || 0;
                    newReport[4][report[0].length - 1][months[month]] += prop.get('final_value') || 0;
                    newReport[4][report[0].length - 1]['year'] += prop.get('final_value') || 0;
                  }
                }
              }
            })

            // Listar as propostas
            // if(prop.get('prevision_buy')){
            let { name_client, name_work, proposal_code, usage, firstCreatedAt, updatedAt, sales_channel, prevision_buy, updatedBy, /*cod_cliente,*/ proposal_management_status } = prop.toJSON()

            // let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(name_client || '').replace('/', '')}-${cod_cliente}-${proposal_code}.pdf`.replace(/\s/g, '-')
            let propToJson = {
              name_client, name_work, proposal_code, usage,
              'sales_channel': sales_channel ? sales_channel['name'] : null,
              'prevision_buy': prevision_buy ? prevision_buy['status'] : null,
              'proposal_management_status': proposal_management_status,
              'firstCreatedAt': firstCreatedAt ? new Date(firstCreatedAt['iso']).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }) : null,
              'updatedAt': new Date(updatedAt).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }),
              'lastIssue': new Date(updatedAt).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }),
              'updatedBy': updatedBy ? updatedBy['name'] : null,
              // 'pdf': pdfName
            }
            if (!report[5]) report.push([propToJson])
            else report[5].push(propToJson)
            // }
          }
        }
      })

      // Formatando os números das celulas das tabelas
      array.forEach(n => {
        report[n].forEach((el, index) => {
          if (n >= 2) {
            report[n][index]['year'] = report[n][index]['year'].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            months.forEach((m, i) => report[n][index][months[i]] = report[n][index][months[i]].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
          } else {
            report[n][index]['year'] = report[n][index]['year'].toLocaleString('pt-BR', { style: 'decimal' })
            months.forEach((m, i) => report[n][index][months[i]] = report[n][index][months[i]].toLocaleString('pt-BR', { style: 'decimal' }))
          }
        })
      })

      CommercialManagementComponent.data.tables = report;
      CommercialManagementComponent.data.newTables = newReport;
      loadingButton = false
      Form.forceUpdate()
    })
  }
}

let pieChartData = (index, table) => {
  let tableArray = table === 'tables' ? CommercialManagementComponent.data.tables : CommercialManagementComponent.data.newTables;
  let dataArray = ((tableArray && tableArray[index]) || []).filter(el => el.type !== 'Total').map(el => {
    let value = el.year || '0';

    // Formating number
    if (typeof value != 'string') return [el.type, value];
    value = value.replace('R$ ', '');
    value = value.split('.').join('');
    value = value.replace(',', '.');
    return [el.type, parseFloat(value)]
  });
  dataArray.unshift(['label', 'value']);
  return dataArray;
}

let barChartData = (index, table) => {
  let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  let dataArray = months.map((month, i) => {
    let childDataArray = [month];
    let tableArray = table === 'tables' ? CommercialManagementComponent.data.tables : CommercialManagementComponent.data.newTables;
    ((tableArray && tableArray[index]) || []).filter(el => el.type !== 'Total').forEach(el => {
      let value = el[months[i]] || '0';
      // Formating number
      if (typeof value === 'string') {
        value = value.replace('R$ ', '');
        value = value.split('.').join('');
        value = value.replace(',', '.');
        value = parseFloat(value);
      }

      childDataArray.push(value);
    })
    return childDataArray
  });
  dataArray.unshift(['Meses', ...usageTypes]);
  return dataArray;
}

const parseDataToSave = (_, FormModule) => {
  // get ParseObject of vinculated_component
  let refs = FormModule.getRefWithFormItem(false)
  return Object.keys(getValues(FormModule)).filter(v => {
    return (v.match(/[0-9]|_slider|_input/g)) ? false : v;
  }).reduce((a, k) => {
    let value = refs[k].props.value
    return { ...a, [k]: value }
  }, {})
}

const getValues = (FormModule) => {
  let refs = FormModule.getRefWithFormItem(false)
  let obj = {}
  for (let item in refs) {
    let value = refs[item].props.value
    obj[item] = value;
  }
  return obj;
}

const updateCommercialManagement = (data, objectId, FormModule) => {
  let Query = new Parse.Query(FormModule.props.module.form.module)

  Query.get(objectId).then(ObjectParse => {
    ObjectParse.set('updatedBy', Parse.User.current())

    ObjectParse.save(data, {}).then(r => {
      FormModule.finishEdit()
      CommercialManagementComponent.dataToEdit = null;
    }).catch(err => {
      notification.error({
        message: 'Erro ao atualizar',
        description: err.message
      })
    })
  })
}

export default CommercialManagementComponent