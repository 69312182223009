import Parse from 'parse';

/**
 * Classe responsável por buscar os grupos e as permissões de um usuário.
 */
export default class Auth {

  ACL = {
    isAdmin: true,
    loaded: false,
    groups: [],
    permissions: [],
    permissionStringList: []
  }

  constructor(User) {
    this.User = User;
  }

  /**
   * Verifica se um usuário tem permissão para efetuar uma ação.
   * @param {Array} actionList Lista de ações a ser verificada se o usuário tem permissão.
   * @returns {Boolean} Retorna True se o usuário tem permissão e False caso contrário. 
   */
  hasAction(actionList) {
    for(let i = 0; i < actionList.length; i++){
      if(this.ACL.permissionStringList.includes(actionList[i])) return true;
    }
    return false;
  }

  /**
   * Atribui ao usuário seus grupos e permissões. 
   * @param {Function} Callback Função que irá tratar o retorno.
   */
  init(Callback) {
    this.getGroupsAndPermissions(this.User, GroupAndPermissions => {
      this.ACL.groups = GroupAndPermissions.groups
      this.ACL.permissions = GroupAndPermissions.permissions
      this.ACL.permissionStringList = GroupAndPermissions.permissions.map(p => p.get('action')).filter(action => {
        return (!this.ACL.isAdmin && action === '*') ? false : action
      })
      Callback()
    })
  }

  /**
   * Retorna os grupos e permissões de um usuário.
   * @param {Parse} User Usuário.
   * @param {Function} Callback Função que irá tratar o retorno.
   * @returns {Object} Retorna um objeto com os atributos permissions (lista de permissões) e groups (grupos do usuário).
   */
  getGroupsAndPermissions(User, Callback) {
    Parse.Cloud.run('getUserPermission').then(response => {
      this.ACL.loaded = true
      Callback(response)
    }).catch(console.log)
  }

}