import React, { Component } from "react";
import {
  notification,
  message,
  Card,
  Breadcrumb,
  Tooltip,
  Button,
  Icon,
  Steps,
  Form,
  Spin,
} from "antd";
import "./proposal-form.css";
import Parse from "parse";

import EmitentStep from "./proposal-steps/emitent-step";
import ClientStep from "./proposal-steps/client-step";
import WorkStep from "./proposal-steps/work-step";
import ProjectStep from "./proposal-steps/project-step";
import InstalationStep from "./proposal-steps/instalation-step";
import ProductStep from "./proposal-steps/product-step";
import {
  findAll,
  findById,
  findWhere,
  findWhereMultiple,
  save,
} from "../../../../utils/db_functions";
import requiredFields from "../suport-files/required-fields";
import defaultProposalData from "../suport-files/default-proposal-data";
import errorInputMessage from "../suport-files/error-input-message";
import TotalStep from "./proposal-steps/total-step";
import PaymentStep from "./proposal-steps/payment-step";
import Auth from "../../../../auth/Auth";
import pointerFields from "../suport-files/pointer-fields";
import {
  calculateInstalationManagement,
  calculateTotals,
} from "../suport-files/proposal-functions";
import { apiGet } from "../../../../utils/api-functions";

export default class ProposalForm extends Component {
  state = {
    objectEdit: null,
    current: null,
    required: requiredFields,
    data: defaultProposalData,
    errorInputMessage: errorInputMessage,

    disabledFields: false,

    loadingInitialSettings: false,
    doorLocksMatriz: [],
    panelsMatriz: [],
    finishings: [],
    sheetDimension: [],
    alizMatriz: [],
    alizarDataCK: [],
    inputs: [],
    formulas: [],

    dataConfigs: {},
  };

  constructor(props) {
    super(props);
    this.saveProposal = this.saveProposal.bind(this);

    this.auth = new Auth(Parse.User.current());
    this.auth["_discountPerItemPermission"] = false;
    this.auth["_commercialDiscountPermission"] = false;
    this.auth["_removeDobradicaPermission"] = false;
    this.auth.init(() => {
      this.auth["_discountPerItemPermission"] = this.auth.hasAction([
        "*",
        "ProposalDiscountPerItem",
      ]);
      this.auth["_commercialDiscountPermission"] = this.auth.hasAction([
        "*",
        "ProposalCommercialDiscount",
      ]);
      this.auth["_removeDobradicaPermission"] = this.auth.hasAction([
        "*",
        "ProposalRemoveDobradica",
      ]);
      this.auth["_deliveryValueRule"] = this.auth.hasAction([
        "*",
        "ProposalDeliveryValueRule",
      ]);
      this.auth["_groups"] = this.auth.ACL.groups;
      this.forceUpdate();
    });
  }

  componentDidMount = async () => {
    this.setState({ loadingInitialSettings: true });

    if (this.props.routerProps.match.params.objectId) {
      this.loadProposalData(this.props.routerProps.match.params.objectId);
    }

    try {
      this.setState({
        loadingMsg: "Carregando todas as configurações...",
      });
      const [
        sheetDimension,
        finishingsFound,
        matrizFinishing,
        formulas,
      ] = await Promise.all([
        findAll("SheetDimension", 1000),
        apiGet("finishing"),
        findAll("MatrizFinishing", null, ["finishing_sheet"]),
        findAll("ConvertionConfigs"),
      ]);
      const finishings = finishingsFound["finishings"] || [];

      this.setState({
        loadingMsg: "Carregando os insumos para calcular os preços...",
      });
      const inputs = await findAll("Input", 30000, null, null, "code");

      this.setState({
        loadingInitialSettings: false,
        finishings,
        sheetDimension,
        inputs,
        formulas,
        matrizFinishing,
      });

      setTimeout(() => {
        this.setState({ current: 0 });
      }, 200);
    } catch (e) {
      console.log(e);
    }
  };

  async loadProposalData(objectId) {
    let proposalParseObject = await findById("Proposal", objectId);

    // Check if proposal was send to block fields
    let disabledFields = false;
    let emailWasSend = false;
    if (
      proposalParseObject.get("historic") &&
      proposalParseObject.get("historic").length
    ) {
      proposalParseObject.get("historic").forEach((comment) => {
        if (
          comment.comment.includes(
            `${proposalParseObject.get("proposal_code")} enviada para o e-mail`
          ) ||
          comment.comment.includes(
            `${proposalParseObject.get(
              "proposal_code"
            )} enviada para o cliente via whatsapp`
          )
        )
          emailWasSend = true;
      });
    }
    if (emailWasSend || proposalParseObject.get("orderGenerated"))
      disabledFields = true;

    // Update form
    this.setState(
      {
        data: this.parseDataToEdit({ ...proposalParseObject.toJSON() }),
        objectEdit: proposalParseObject,
        disabledFields: disabledFields,
      },
      () => {}
    );
  }

  parseDataToEdit(ProposalObject) {
    for (let key in pointerFields) {
      ProposalObject[key] = ProposalObject[key]
        ? ProposalObject[key].objectId
        : null;
    }
    if (ProposalObject.hasOwnProperty("objectId"))
      delete ProposalObject.objectId;
    return ProposalObject;
  }

  changeStep(step, specificStep = null) {
    this.setState((state) => {
      let stepToGo = state.current + step;
      // Verificar se foi clicado em um passo específico
      if (specificStep || specificStep === 0) stepToGo = specificStep;

      return { current: stepToGo };
    });
  }

  getValue(attr) {
    if (this.state.data[attr] === 0) return 0;

    return this.state.data[attr] || null;
  }

  updateRequired({ removed = [], add = [], type }) {
    let values = new Set(
      [...this.state.required[type]]
        .filter((item) => !removed.includes(item))
        .concat(add)
    );

    let requiredState = this.state.required;
    requiredState[type] = Array.from(values);
    this.setState({ required: requiredState });
  }

  updateDataValue(attr, value, callback = function() {}) {
    this.setState(
      (state) => {
        state.data[attr] = value;
        return state;
      },
      () => {
        this.showTable(this.state.data);
        callback();
      }
    );
  }

  showTable(data) {
    let keys = Object.keys(data);
    keys.reduce((a, v) => {
      if (data[v]) a[v] = data[v];
      return a;
    }, {});
  }

  generateWordKey() {
    let name_client = this.state.data.name_client || "";
    let name_work = this.state.data.name_work || "";
    let client = name_client.substring(
      0,
      name_client.length >= 12 ? 12 : name_client.length
    );
    let work = name_work.substring(
      0,
      name_work.length >= 12 ? 12 : name_work.length
    );
    return work || client ? `${client}-${work}`.toUpperCase() : "";
  }

  render() {
    let objectEdit = this.state.objectEdit;
    return (
      <div>
        <Spin
          spinning={this.state.loadingInitialSettings}
          size="large"
          tip={this.state.loadingMsg || "Carregandos as matrizes..."}
        >
          <Form layout="inline">
            <Card
              title={
                <div style={{ float: "left", width: "100%" }}>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <span
                        onClick={() =>
                          this.props.module.navigateToRouterComponent(`/`)
                        }
                      >
                        {this.props.module.props.form[`title-module`]}
                      </span>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      {objectEdit ? `Editar` : `Cadastrar`}
                    </Breadcrumb.Item>
                  </Breadcrumb>

                  <h2 className="no-mar-pad" style={{ float: "left" }}>
                    <div style={{ float: "left" }}>
                      {this.props.module.props.title}
                    </div>{" "}
                    &nbsp;{this.getValue("proposal_code")}
                  </h2>

                  <div className="right-btns">
                    {this.props.module.props.form.hasOwnProperty(
                      "BeforeSaveButton"
                    )
                      ? this.props.module.props.form["BeforeSaveButton"](this)
                      : ""}
                    <Tooltip
                      visible={this.state.invalidForm}
                      placement="topLeft"
                      title="Preencha todos os campos."
                    >
                      <Button
                        disabled={
                          this.props.routerProps.match.params.objectId
                            ? false
                            : true
                        }
                        id="btnNewVersion"
                        className="btn-creator"
                        htmlType="button"
                        style={{ marginLeft: "10px" }}
                        loading={this.state.newVersionLoading}
                        onClick={() => this.generateNewVersion()}
                      >
                        Gerar nova versão
                      </Button>

                      <Button
                        disabled={this.state.disabledFields}
                        type="primary"
                        id="btnSave"
                        className="btn-creator"
                        htmlType="button"
                        loading={this.state.loading}
                        onClick={() => {
                          this.setState({ loading: true });
                          this.saveProposal();
                        }}
                      >
                        Salvar
                      </Button>

                      {this.props.module.props.form.hasOwnProperty(
                        "AfterSaveButton"
                      )
                        ? this.props.module.props.form["AfterSaveButton"](this)
                        : ""}
                    </Tooltip>
                  </div>
                </div>
              }
              actions={[
                <div />,

                <div />,

                <Button.Group>
                  <Button
                    disabled={this.state.current === 0}
                    onClick={() => this.changeStep(-1)}
                  >
                    <Icon type="left" />
                    Voltar
                  </Button>

                  <Button
                    disabled={this.state.current === 7}
                    onClick={() => this.changeStep(1)}
                  >
                    Avançar <Icon type="right" />
                  </Button>
                </Button.Group>,
              ]}
            >
              <Steps current={this.state.current}>
                {[
                  "Emitente",
                  "Cliente",
                  "Obra",
                  "Projeto",
                  "Instalação",
                  "Produtos",
                  "Totais e Frete",
                  "Condições e Observação",
                ].map((item, index) => {
                  return (
                    <Steps.Step
                      key={item}
                      title={item}
                      onClick={() => this.changeStep(1, index)}
                      style={{ cursor: "pointer" }}
                    />
                  );
                })}
              </Steps>

              {this.state.current === 0 && (
                <EmitentStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  disabledFields={this.state.disabledFields}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 1 && (
                <ClientStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  updateRequired={(obj) => this.updateRequired(obj)}
                  disabledFields={this.state.disabledFields}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 2 && (
                <WorkStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  generateWordKey={() => this.generateWordKey()}
                  disabledFields={this.state.disabledFields}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 3 && (
                <ProjectStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  disabledFields={this.state.disabledFields}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 4 && (
                <InstalationStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  disabledFields={this.state.disabledFields}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 5 && (
                <ProductStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  disabledFields={this.state.disabledFields}
                  required={this.state.required}
                  doorLocksMatriz={this.state.doorLocksMatriz}
                  panelsMatriz={this.state.panelsMatriz}
                  finishings={this.state.finishings}
                  matrizFinishing={this.state.matrizFinishing}
                  sheetDimension={this.state.sheetDimension}
                  alizMatriz={this.state.alizMatriz}
                  alizarDataCK={this.state.alizarDataCK}
                  inputs={this.state.inputs}
                  formulas={this.state.formulas}
                  auth={this.auth}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 6 && (
                <TotalStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  disabledFields={this.state.disabledFields}
                  inputs={this.state.inputs}
                  formulas={this.state.formulas}
                  auth={this.auth}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}

              {this.state.current === 7 && (
                <PaymentStep
                  getValue={(attr) => this.getValue(attr)}
                  updateDataValue={(attr, value) =>
                    this.updateDataValue(attr, value)
                  }
                  disabledFields={this.state.disabledFields}
                  inputs={this.state.inputs}
                  formulas={this.state.formulas}
                  auth={this.auth}
                  dataConfigs={this.state.dataConfigs}
                  setDataConfigs={(config) =>
                    this.setState({ ...this.state.dataConfigs, ...config })
                  }
                />
              )}
            </Card>
          </Form>
        </Spin>
      </div>
    );
  }

  contactClientIsValid() {
    return this.groupIsValid([
      ["contact_prop", "cargo_prop", "phone_prop", "email_prop"],
      ["contact_supri", "cargo_supri", "phone_supri", "email_supri"],
      ["contact_eng", "cargo_eng", "phone_eng", "email_eng"],
      [
        "contact_management",
        "cargo_management",
        "phone_management",
        "email_management",
      ],
    ]);
  }

  contactWorkIsValid() {
    return this.groupIsValid([
      [
        "contact_obra_compras",
        "cargo_obra_compras",
        "phone_obra_compras",
        "email_obra_compras",
      ],
      [
        "contact_obra_eng",
        "cargo_obra_eng",
        "phone_obra_eng",
        "email_obra_eng",
      ],
      [
        "contact_obra_management",
        "cargo_obra_management",
        "phone_obra_management",
        "email_obra_management",
      ],
    ]);
  }

  groupIsValid(groups) {
    return groups.some((group) => {
      return group.every((attr) => this.state.data[attr]);
    });
  }

  async saveProposal() {
    try {
      const proposalFirstObject = { ...this.state.data };

      await this.settingUpRequiredFields();

      let proposalObject = await this.settingUpPointerFields(
        proposalFirstObject
      );

      proposalObject["status"] = "Pronta";
      if (!this.validateInputs()) proposalObject["status"] = "Incompleta";

      const {
        totalPrice,
        totalWeight,
        totalVolume,
        totalQtd,
        unitValues,
      } = await calculateTotals(
        proposalObject["product_blocks"],
        this.state.inputs,
        this.state.formulas,
        null,
        this.getValue("generalInfo") &&
          this.getValue("generalInfo")["isMultipleCotation"]
      );
      proposalObject["baseFinalPriceAndAmount"] = {
        finalPrice: totalPrice,
        finalAmount: totalQtd,
        unitValues,
        finalWeight: totalWeight,
        finalVolume: totalVolume,
      };

      proposalObject = this.getProposalObjectWithInstalationManagement(
        proposalObject,
        totalQtd
      );

      proposalObject["keyWord"] =
        proposalObject["keyWord"] || this.generateWordKey();

      delete proposalObject["all_proposals"];

      if (this.getValue("proposal_code")) {
        let proposal = await findWhere("Proposal", "proposal_code", [
          this.getValue("proposal_code"),
        ]);

        proposalObject["updatedBy"] = Parse.User.current();

        delete proposalObject["firstCreatedAt"];
        delete proposalObject["createdBy"];

        const dataUpdated = await save(
          "Proposal",
          proposalObject,
          proposal[0].id
        );
        if (!dataUpdated) throw new Error();
      } else {
        proposalObject["proposal_code"] = await this.generateProposalCode();
        proposalObject["createdBy"] = Parse.User.current();
        proposalObject["firstCreatedAt"] = new Date();
        const dataCreated = await save("Proposal", proposalObject);

        if (!dataCreated) throw new Error();
      }

      const proposalParseObjectUpdated = await findWhere(
        "Proposal",
        "proposal_code",
        [proposalObject["proposal_code"]]
      );

      message.success("Proposta salva com sucesso!");
      this.setState({
        loading: false,
        data: this.parseDataToEdit({
          ...proposalParseObjectUpdated[0].toJSON(),
        }),
        objectEdit: proposalParseObjectUpdated[0],
      });
    } catch (e) {
      notification.error({
        message: "Alguma coisa deu errado",
        description: "Verifique se os valores foram preenchidos corretamente.",
        duration: 5,
      });
      this.setState({ loading: false });
    }
  }

  async settingUpRequiredFields() {
    /* Configurar required das condições de pagamento : início */
    let addRequired = null;
    let removeRequired = null;

    // Verficiar se a soma Desconto + Sinal é menor 100 para tornar required campo Parcela
    if (
      this.getValue("discount") + this.getValue("signal") < 100 &&
      this.getValue("installmentsNum") === 0
    ) {
      addRequired = ["installmentsNumMoreThanZero"];
      removeRequired = ["installmentsNumEqualZero", "discountPlusSignal"];
    }

    // Verficiar se a soma Desconto + Sinal é igual 100 para tornar required campo Parcela como 0
    if (
      this.getValue("discount") + this.getValue("signal") === 100 &&
      this.getValue("installmentsNum") !== 0
    ) {
      addRequired = ["installmentsNumEqualZero"];
      removeRequired = ["installmentsNumMoreThanZero", "discountPlusSignal"];
    }

    // Verficiar se a soma Desconto + Sinal é maior 100 e informar que deve ser menor que 100
    if (this.getValue("discount") + this.getValue("signal") > 100) {
      addRequired = ["discountPlusSignal"];
      removeRequired = [
        "installmentsNumEqualZero",
        "installmentsNumMoreThanZero",
      ];
    }
    /* Configurar required das condições de pagamento : fim */

    /* Configurar required do CNPJ/CPF : início */
    if (this.getValue("is_company")) {
      addRequired = ["cnpj"];
      removeRequired = ["cpf"];
    } else {
      addRequired = ["cpf"];
      removeRequired = ["cnpj"];
    }
    /* Configurar required do CNPJ/CPF : Fim */

    if (addRequired || removeRequired)
      this.updateRequired({
        add: addRequired,
        removed: removeRequired,
        type: "proposal",
      });
    else
      this.updateRequired({
        removed: [
          "installmentsNumEqualZero",
          "installmentsNumMoreThanZero",
          "discountPlusSignal",
        ],
        type: "proposal",
      });
  }

  async settingUpPointerFields(proposalObject) {
    let object = { ...proposalObject };

    const pointerFieldsArray = Object.keys(pointerFields);

    for (
      let pointerFieldIndex = 0;
      pointerFieldIndex < pointerFieldsArray.length;
      pointerFieldIndex++
    ) {
      const pointerField = pointerFieldsArray[pointerFieldIndex];
      if (proposalObject[pointerField]) {
        const fieldToRef = await Parse.Object.extend(
          pointerFields[pointerField]
        );
        let pointer = await new fieldToRef();
        pointer.id = await proposalObject[pointerField];
        object[pointerField] = pointer;
      }
    }

    return object;
  }

  getProposalObjectWithInstalationManagement(proposalObject, amount) {
    const instalationManagement = calculateInstalationManagement(amount);

    proposalObject["instalation_management"] =
      instalationManagement.instalation_management;
    proposalObject["instalation_management_time"] =
      instalationManagement.instalation_management_time;
    proposalObject["instalation_management_percentual"] =
      instalationManagement.instalation_management_percentual;

    return proposalObject;
  }

  validateInputs() {
    let { proposal, product, specific_products } = this.state.required;
    let proposalValidate = this.validateObject(
      this.state.data,
      proposal,
      product,
      specific_products
    );
    return proposalValidate;
  }

  validateObject(product, required, product_child, specific_products) {
    return required.every((req) => {
      // Verifica se o cnpj foi preenchido com um número válido
      if (
        req === "cnpj" &&
        (this.getValue("cnpj").length < 18 ||
          this.getValue("cnpj") === "00.000.000/0000-00")
      )
        return notification.warning({
          message: "Verifique o CNPJ",
          description: "Informe um CNPJ válido",
          duration: 5,
        });

      // Verifica se o cpf foi preenchido com um número válido
      if (
        req === "cpf" &&
        (this.getValue("cpf").length < 14 ||
          this.getValue("cpf") === "000.000.000-00")
      )
        return notification.warning({
          message: "Verifique o CPF",
          description: "Informe um CPF válido",
          duration: 5,
        });

      // Verifica se pelo menos um contato do cliente foi preenchido
      // if (req === 'name_work' && !this.contactClientIsValid())
      //   return notification.warning({
      //     message: 'Verifique os contatos',
      //     description: 'Preencha um dos contatos do cliente para gerar a proposta',
      //     duration: 5
      //   })

      // Verifica se pelo menos um contato da obra foi preenchido
      // if (req === 'ocupation' && !this.contactWorkIsValid())
      //   return notification.warning({
      //     message: 'Verifique os contatos',
      //     description: 'Preencha um dos contatos da obra para gerar a proposta',
      //     duration: 5
      //   })

      // if (req === 'discount' && (this.getValue("discount") || this.getValue("discount") === 0)) {
      let validateProd = this.state.data.product_blocks.every(
        (blockProductObject) => {
          return blockProductObject.products.every((productObject) => {
            let localProductChild = product_child;
            if (productObject.search_componentKit)
              localProductChild = this.validateRequiredComponents(
                productObject
              );
            return this.validateProductObject(productObject, localProductChild);
          });
        }
      );

      let validateSpecificProd = this.state.data.product_blocks.every(
        (blockProductObject, block_i) => {
          return blockProductObject.products.every((productObject, prod_i) => {
            return this.validateProductObject(
              productObject,
              (
                (specific_products || []).find(
                  (x) => x.block === block_i && x.prod === prod_i
                ) || {}
              ).required
            );
          });
        }
      );

      if (!validateProd || !validateSpecificProd)
        return validateProd && validateSpecificProd;
      // }

      if (product[req] || product[req] === 0) return true;

      return notification.warning({
        message: "Antes de emitir, verifique o seguinte...",
        description: `${
          this.state.errorInputMessage[req]
        } para gerar a proposta`,
        duration: 5,
      });
    });
  }

  validateProductObject(product, required = []) {
    return required.every((req) => {
      if (req === "wall_panel") {
        return (product[req] || []).every((wall) => {
          return this.validateObject(wall, this.state.required.wall_panel);
        });
      }
      if (product[req]) return true;

      return notification.warning({
        message: "Antes de emitir, verifique o seguinte...",
        description: `${
          this.state.errorInputMessage[req]
        } para gerar a proposta`,
        duration: 5,
      });
    });
  }

  async generateProposalCode() {
    const lastProposal = await findAll(
      "Proposal",
      null,
      null,
      null,
      "proposal_code",
      "descending"
    );
    const proposalCodeArray = lastProposal[0].get("proposal_code").split("-");
    const proposalYear = proposalCodeArray[0];
    const proposalCode = proposalCodeArray[1];
    const newProposalCode = parseInt(proposalCode, 10) + 1;

    const newProposalCodeString = newProposalCode.toString();
    const zeros = 4 - newProposalCodeString.length;

    let newProposalFullCode = "";

    if (new Date().getFullYear().toString() === proposalYear)
      newProposalFullCode = `${proposalYear}-${"0".repeat(
        zeros
      )}${newProposalCodeString}-01`;
    else newProposalFullCode = `${new Date().getFullYear().toString()}-0001-01`;

    return newProposalFullCode;
  }

  async generateNewVersionProposalCode(proposalObject) {
    const proposalCodeArray = proposalObject["proposal_code"].split("-");

    const lastProposal = await findWhereMultiple(
      "Proposal",
      [
        {
          field: "proposal_code",
          value: `${proposalCodeArray[0]}-${proposalCodeArray[1]}`,
          op: "like",
        },
      ],
      null,
      null,
      null,
      "proposal_code",
      "descending"
    );

    const proposalFoundCodeArray = lastProposal[0]
      .get("proposal_code")
      .split("-");
    const newProposalCodeNumber = parseInt(proposalFoundCodeArray[2], 10) + 1;

    const newProposalCodeString = `${
      newProposalCodeNumber < 10 ? "0" : ""
    }${newProposalCodeNumber.toString()}`;

    return `${proposalCodeArray[0]}-${
      proposalCodeArray[1]
    }-${newProposalCodeString}`;
  }

  getCodeProdData(blockIndex, productIndex) {
    return (this.state.codeProdData[blockIndex] || {})[productIndex] || [];
  }

  async generateNewVersion() {
    try {
      this.setState({ newVersionLoading: true });

      let proposalObject = this.state.data;

      proposalObject = await this.settingUpPointerFields(proposalObject);
      proposalObject["sales_channel"] = null;
      proposalObject["pdf"] = false;
      proposalObject["orderGenerated"] = false;

      proposalObject["status"] = "Pronta";
      if (!this.validateInputs()) proposalObject["status"] = "Incompleta";

      const {
        totalPrice,
        totalWeight,
        totalVolume,
        totalQtd,
        unitValues,
      } = await calculateTotals(
        proposalObject["product_blocks"],
        this.state.inputs,
        this.state.formulas,
        null,
        this.getValue("generalInfo") &&
          this.getValue("generalInfo")["isMultipleCotation"]
      );
      proposalObject["baseFinalPriceAndAmount"] = {
        finalPrice: totalPrice,
        finalAmount: totalQtd,
        unitValues,
        finalWeight: totalWeight,
        finalVolume: totalVolume,
      };

      proposalObject = this.getProposalObjectWithInstalationManagement(
        proposalObject,
        totalQtd
      );

      proposalObject[
        "proposal_code"
      ] = await this.generateNewVersionProposalCode(proposalObject);

      proposalObject["updatedBy"] = Parse.User.current();

      delete proposalObject["firstCreatedAt"];
      delete proposalObject["createdBy"];
      delete proposalObject["all_proposals"];

      const newProposal = await save("Proposal", proposalObject);

      message.success("Nova versão gerada com sucesso!");
      this.setState({ newVersionLoading: false });

      this.props.routerProps.history.push(
        `/panel/proposta/editar/${newProposal.id}`
      );
      window.location.reload();
    } catch (e) {
      notification.error({
        message: "Alguma coisa deu errado",
        description: "Verifique se os valores foram preenchidos corretamente.",
        duration: 5,
      });
      this.setState({ newVersionLoading: false });
    }
  }

  /**
   * Validate if component required fields was filled.
   * @param {Object} product
   */
  validateRequiredComponents = (product) => {
    let productsRequired = [
      "env",
      "profile_performance",
      "typology",
      "product_code",
      "amount",
    ];

    // Check marco
    if (product.product === "Marco") {
      productsRequired = [
        ...productsRequired,
        "thickness_marco",
        "finishing_marco",
        "pattern_marco",
      ];
    }

    // Check folha
    if (product.product === "Folha") {
      productsRequired = [
        ...productsRequired,
        "finishing_sheet",
        "pattern_sheet",
      ];
    }

    return productsRequired;
  };
}
