import { Button, Icon, Modal } from 'antd'
import React from 'react'
import SatisfactionSurveyReport from './entities/satisfaction-survey-report'
import { surveyQuestionsAndAnswers } from './suport-files/survey-questions'

export default {
    fields: [
        [],
    ],
    submit: { collection: 'CommercialSurvey' },
    schema: [
        {
            title: 'Proposta',
            key: 'proposal',
            dataIndex: 'proposal',
            type: 'text',
            width: '150px',
        },
        {
            title: 'Cliente',
            key: 'client',
            dataIndex: 'client',
            type: 'text',
        },
        {
            title: 'Obra',
            key: 'work',
            dataIndex: 'work',
            type: 'text',
        },
        {
            title: "PDF Proposta",
            key: "proposalName",
            dataIndex: 'proposalName',
            align: 'center',
            render: (proposalName) => {

                return (
                    <Button title="Visualizar proposta em PDF" type="default" shape="circle" icon="file-pdf"
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_PUBLIC}/pdf_proposal/${proposalName}`, '_blink')
                        }}
                    />
                )
            }
        },
        {
            title: "Experiência Multidoor",
            key: "form",
            dataIndex: 'form',
            align: 'center',
            render: (form, survey) => {
                return (
                    <span
                        className='link-style'
                        onClick={() => {
                            Modal.confirm({
                                title: `Cliente: ${survey.client}`,
                                width: 600,
                                iconType: 'message',
                                content:
                                    <div>
                                        <br />

                                        {
                                            surveyQuestionsAndAnswers.map(questionAndAnswers => {
                                                return (
                                                    <div key={questionAndAnswers.question.key}>
                                                        <div><strong>{questionAndAnswers.question.label}</strong></div>
                                                        {form[questionAndAnswers.question.key]}<br /><br />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>,
                            })
                        }}
                    >
                        {form['multidoorExperience'] || '-'}
                    </span>
                )
            }
        }
    ],
    'title-navigation': <div><Icon type="message" /> Pesquisa de atendimento</div>,
    'title-module': 'Pesquisa de atendimento',
    'module': 'CustomerSatisfactionSurvey',
    'router-base': '/panel/pesquisa-satisfacao',
    'topBarAction': () => <SatisfactionSurveyReport />,
}