import React from 'react'
import { Icon, Select, Tag, Input } from 'antd'
import { findWhere } from '../../../utils/db_functions'

let typologies = [];

export default {
  "fields": [
    [
      {
        type: 'select',
        key: 'performances',
        title: 'Desempenho',
        options: [],
        optionConfig: { name: 'performance' },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
        colSize: 6,
      },
      {
        type: 'render',
        key: 'height_marco',
        title: 'Altura do Marco',
        colSize: 6,
        render: (state, module, node) => {
          return (
            <Input
              onChange={event => module.updateRefValue(node, event.target.value, 'height_marco')}
              size="large"
              placeholder='Altura Marco'
              value={state.formRef['height_marco'] || state.formRef['height']}
            />
          )
        },
        beforeSave: (module, parseObject, node) => {
          parseObject.set('height_marco', module.state.formRef.height_marco)
          return true;
        }
      },
      {
        type: 'select',
        key: 'height',
        title: 'Altura da Folha',
        options: [],
        optionConfig: { name: 'alturas_folha' },
        loaded: false,
        "element-attr": {
          mode: 'select',
          required: true
        },
        colSize: 4
      },
      {
        type: 'select',
        key: 'width',
        title: 'Largura',
        options: [],
        optionConfig: { name: 'larguras_folha' },
        loaded: false,
        "element-attr": {
          mode: 'select',
          required: true
        },
        colSize: 4
      },
      {
        type: 'select',
        key: 'width_inactive',
        title: 'Largura folha inativa (kits assimétricos)',
        options: [],
        optionConfig: { name: 'larguras_folha' },
        loaded: false,
        "element-attr": {
          mode: 'select',
          required: false
        },
        colSize: 4
      }
    ],
    [
      {
        "title": "Altura Kit Porta",
        "key": "height_kit",
        "type": "number",
        colSize: 6,
        "element-attr": { "required": true }
      },
      {
        "title": "Largura Kit Porta",
        "key": "width_kit",
        "type": "number",
        colSize: 6,
        "element-attr": { "required": true }
      },
      {
        "title": "Altura Vão da Obra",
        "key": "height_doorway",
        "type": "number",
        colSize: 6,
        "element-attr": { "required": true }
      },
      {
        "title": "Largura Vão da Obra",
        "key": "width_doorway",
        "type": "number",
        colSize: 6,
        "element-attr": { "required": true }
      }
    ],
    [
      {
        type: 'select',
        key: 'typology',
        title: 'Tipologia',
        options: [],
        optionConfig: { name: 'typology', arrayWithId: true },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
        colSize: 8,
        render: (options) => {
          let opt = options.reduce((acc, v) => {
            let type = v.label.split(' ')[0]
            if (!acc[type]) {
              acc[type] = {
                children: []
              }
            }
            acc[type].children.push(v)
            return acc;

          }, {});
          let types = Object.keys(opt)
          return types.map((type, index) => {
            return (
              <Select.OptGroup label={types[index]} key={`group-${index}`}>
                {opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
              </Select.OptGroup>
            )
          })
        }
      },
    ]
  ],
  "submit": {
    "collection": "SheetDimension"
  },
  "className": "config-component",
  ListWillMount: async () => {
    const typologiesParseArray = await findWhere('Config', 'name', ['typology']);
    typologies = typologiesParseArray.length && typologiesParseArray[0].get('array_with_id');
  },
  "schema": [
    {
      "title": "Desempenho",
      "key": "performances",
      "type": "text",
      render: row => (row.performances || []).map((item, key) => (<Tag key={key}>{item}</Tag>))
    },
    {
      title: "Dimensões da Folha (mm)",
      key: 'dimension',
      //dataIndex: 'width',
      type: "render",
      render: r => `${r.width}${r.width_inactive ? '+' + r.width_inactive : ''} x ${r.height}`
    },
    {
      title: "Dimensões Kit Porta (mm)",
      key: 'dimension_kit',
      type: "render",
      render: r => `${r.width_kit} x ${r.height_kit}`
    },
    {
      title: "Dimensões Vão (mm)",
      key: 'dimension_doorway',
      type: "render",
      render: r => `${r.width_doorway} x ${r.height_doorway}`
    },
    {
      title: 'Tipologia',
      key: 'typology',
      dataIndex: 'typology',
      type: 'render',
      render: v => {
        return (v || [])
          .map((usage, i) => {
            const typology = typologies.find(el => el.value === usage)
            return (
              <Tag key={i}>
                {typology && typology.label}
              </Tag>
            )
          })
      }

    }
  ],
  "title-navigation": <div><Icon type="table" /> Matriz de Dimensões</div>,
  "title-module": "Matriz de Dimensões",
  "module": "MatrizDimension",
  "router-base": "/panel/matriz-dimensoes"
}