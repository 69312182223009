import React from 'react';
import { Icon, Tag } from 'antd';
import '../../styles/config.css';

export default {
  "fields": [
    [
      {
        "title": "Nome",
        "key": "name",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="tags" /> }
      },
      {
        "title": "Descrição",
        "key": "description",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="tags" /> }
      }
    ],
    [
      {
        title: 'Lista',
        key: 'value',
        type: 'tag-list',
        "element-attr": {
        }
      }
    ],
    [
      {
          "key": "array_with_id",
          "type": "dynamic-input-list",
          'title': 'Lista com identificador (id)',
          "fields": [
              [
                  {
                      title: 'Id',
                      key: 'value',
                      type: 'text',
                      colSize: '6',
                      "element-attr": { maxLength: 20, required: true }
                  },
                  {
                      title: 'Valor',
                      key: 'label',
                      type: 'text',
                      colSize: '18',
                      "element-attr": { required: true }
                  },
              ]
          ]
      }
    ],
  ],
  "submit": {
    "collection": "Config"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Nome",
      "key": "name",
      "dataIndex": "name",
      "type": "text",
      className: "name"
    },
    {
      "title": "Descrição",
      "key": "description",
      "dataIndex": "description",
      "type": "text",
      className: "name"
    },
    {
      title: 'Valores',
      key: 'value',
      dataIndex: 'value',
      type: 'text',
      render: (_, row) => {
        return (<div>
          {(row.value || []).map((v, i) => <Tag style={{ margin: '5px' }} key={i}>{v}</Tag>)}
          {/* {(row.value || []).length >= 10 && <Tag color="blue" disabled={true} key="more values"><Icon type="eye" /> Ver mais</Tag>} */}
        </div>)
      }
    }
  ],
  "title-module": "Chave-Valor",
  "title-navigation": <div><Icon type="key" /> Chave-Valor</div>,
  "module": "Config",
  "router-base": "/panel/config"
}

