const providerDocFields = (props) => {
    return [
        [
            {
                key: 'docs',
                type: 'files',
                "element-attr": { "required": false }
            }
        ],
    ]
}

export default providerDocFields