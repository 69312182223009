import { Input, Modal, Spin, Table } from 'antd'
import React, { Component } from 'react'
import { findWhereMultiple } from '../../../../utils/db_functions'

export default class InputSelectModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: this.props.modalVisible,
            loading: false,
            inputs: [],
        }
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.modalVisible !== this.state.modal) this.setState({ modal: nextProps.modalVisible })
    }

    searchInputs = async (term) => {
        this.setState({ loading: true })

        const inputsFound = await findWhereMultiple(
            'Input',
            [
                { 'field': 'code', 'value': term, 'op': 'like', logicOp: '$or' },
                { 'field': 'material_description', 'value': term, 'op': 'like', logicOp: '$or' }
            ],
            null, null, null, 'code'
        )

        this.setState({
            inputs: inputsFound.map((input) => {
                return { code: input, material_description: input, key: input.id }
            }),
            loading: false
        })
    }

    render() {
        const columns = [
            {
                title: 'Código',
                dataIndex: 'code',
                width: '150px',
                key: 'code',
                render: input => (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.props.selectInput(input)
                            this.setState({ modal: false, inputs: [] })
                        }}
                    >
                        {input.get('code')}
                    </div>
                )
            },
            {
                title: 'Insumo',
                dataIndex: 'material_description',
                key: 'material_description',
                render: input => (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.props.selectInput(input)
                            this.setState({ modal: false, inputs: [] })
                        }}
                    >
                        {input.get('material_description')}
                    </div>
                )
            },
        ];

        return (
            <Modal
                visible={this.state.modal}
                title='Escolher insumo'
                footer={[]}
                onCancel={() => {
                    this.props.closeModal()
                }}
            >

                <Spin spinning={this.state.loading} tip='Carregando insumos'>

                    <Input.Search
                        style={{ marginBottom: '20px' }}
                        placeholder='Código ou nome do insumo'
                        onSearch={e => {
                            this.searchInputs(e)
                        }}
                    />

                    <Table dataSource={this.state.inputs} columns={columns} locale={{ emptyText: 'Nenhum insumo encontrado' }} />
                </Spin>
            </Modal>
        )
    }

}