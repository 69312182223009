import { Col, Divider, Form, Row, Select } from "antd"
import _ from "lodash"
import { doorLockComposition } from "../../../../../components-and-kits/matriz-fech/suport-files/door-locks-select-options"
import React, { Fragment } from "react"
import { codesOfComposition, filterDoorLockComposition, filterDoorLockType, filterDoorLockTypology, getLabelFromValue } from "../../../../../components-and-kits/matriz-fech/suport-files/door-locks-matriz-functions"

const updateRequiredDoorLockField = (composition, updateRequiredFunction) => {
    if ((composition || '').includes('kit')) {
        updateRequiredFunction('conj_fechadura', false)
        updateRequiredFunction('hardware_door_lock', true)
    } else {
        updateRequiredFunction('hardware_door_lock', false)
        updateRequiredFunction('conj_fechadura', true)
    }
}

const productStepDoorLock = ({
    index,
    getProductValue,
    updateProductData,
    _this,
    updateRequiredFields,
    isRequiredProductComponent,
}) => {
    return (
        <Fragment>
            <h2>Cadastro de Fechadura</h2>

            <Row gutter={24}>
                {
                    [
                        {
                            label: 'Fechadura x Tipologia',
                            valueAttr: 'door_lock_typology',
                            options: filterDoorLockTypology(getProductValue('typology'), _this.state.fechMatriz),
                            clearOptions: ['door_lock_type', 'door_lock_composition', 'conj_fechadura', 'hardware_door_lock', 'hardware_door_handle'],
                            afterChange: (v) => {
                                const doorLockTypeOptions = filterDoorLockType(v, getProductValue('typology'), _this.state.fechMatriz)
                                if (doorLockTypeOptions && doorLockTypeOptions.length === 1) {
                                    updateProductData('door_lock_type', index, doorLockTypeOptions[0].value);

                                    const doorLockCompositionOptions = filterDoorLockComposition(v, doorLockTypeOptions[0].value, getProductValue('typology'), _this.state.fechMatriz)
                                    if (doorLockCompositionOptions && doorLockCompositionOptions.length === 1) {
                                        updateProductData('door_lock_composition', index, doorLockCompositionOptions[0].value)
                                        updateRequiredDoorLockField(doorLockCompositionOptions[0].value, updateRequiredFields)
                                    }
                                }
                            },
                            disabled: () => false
                        },
                        {
                            label: 'Tipo de Fechadura',
                            valueAttr: 'door_lock_type',
                            options: filterDoorLockType(getProductValue('door_lock_typology'), getProductValue('typology'), _this.state.fechMatriz),
                            clearOptions: ['door_lock_composition', 'conj_fechadura', 'hardware_door_lock', 'hardware_door_handle'],
                            afterChange: (v) => {
                                const doorLockCompositionOptions = filterDoorLockComposition(getProductValue('door_lock_typology'), v, getProductValue('typology'), _this.state.fechMatriz)
                                if (doorLockCompositionOptions && doorLockCompositionOptions.length === 1) {
                                    updateProductData('door_lock_composition', index, doorLockCompositionOptions[0].value)
                                    updateRequiredDoorLockField(doorLockCompositionOptions[0].value, updateRequiredFields)
                                }
                            },
                            disabled: () => {
                                const doorLockTypeOptions = filterDoorLockType(getProductValue('door_lock_typology'), getProductValue('typology'), _this.state.fechMatriz)
                                return doorLockTypeOptions && doorLockTypeOptions.length === 1
                            }
                        },
                        {
                            label: 'Composição da Fechadura',
                            valueAttr: 'door_lock_composition',
                            options: filterDoorLockComposition(getProductValue('door_lock_typology'), getProductValue('door_lock_type'), getProductValue('typology'), _this.state.fechMatriz),
                            clearOptions: ['conj_fechadura', 'hardware_door_lock', 'hardware_door_handle'],
                            afterChange: (v) => {
                                updateRequiredDoorLockField(v, updateRequiredFields)
                            },
                            disabled: () => {
                                const doorLockCompositionOptions = filterDoorLockComposition(getProductValue('door_lock_typology'), getProductValue('door_lock_type'), getProductValue('typology'), _this.state.fechMatriz)
                                return doorLockCompositionOptions && doorLockCompositionOptions.length === 1
                            }
                        },
                    ].map(doorLockConfig => {
                        return (
                            <Col span={8}>
                                <Form.Item label={doorLockConfig.label}>
                                    <Select
                                        disabled={_this.props.disabledFields || doorLockConfig.disabled()}
                                        value={getProductValue(doorLockConfig.valueAttr)}
                                        onChange={value => {
                                            updateProductData(doorLockConfig.valueAttr, index, value);

                                            (doorLockConfig.clearOptions || []).forEach(attrToClear => {
                                                updateProductData(attrToClear, index, null)
                                            });

                                            doorLockConfig.afterChange(value)
                                        }}
                                        placeholder={doorLockConfig.label} size="large"
                                    >
                                        {doorLockConfig.options.map(el => {
                                            return (<Select.Option value={el.value}>{el.label}</Select.Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    })
                }
            </Row>

            <Row gutter={24}>
                {(getProductValue('door_lock_composition') || "").includes('kit') ?
                    <Col span={14}>
                        <Form.Item label='Fechadura/Usinagem' required={isRequiredProductComponent('conj_fechadura')}>
                            <Select
                                disabled={_this.props.disabledFields}
                                value={getProductValue('conj_fechadura')}
                                onChange={async value => {
                                    updateProductData('conj_fechadura', index, value)
                                    updateRequiredFields('lock_quantity', !value)
                                    if (!getProductValue('lock_quantity')) updateProductData('lock_quantity', index, 1)
                                }}
                                placeholder="Fechadura" size="large">
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    _.uniqBy((_this.state.doorLocksMatriz || [])
                                        .map(matriz => {
                                            const kitFechaduraMatrizField = _this.props.getValue("is_company") ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
                                            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
                                        })
                                        .flat()
                                        .filter(ferragem => {
                                            return (
                                                (
                                                    ferragem && (
                                                        ferragem.get('group') === 'Conjunto Fechadura' ||
                                                        ferragem.get('group') === 'Kit Fechadura'
                                                    )
                                                )
                                                && codesOfComposition(getProductValue('door_lock_typology'), getProductValue('door_lock_type'), _this.state.fechMatriz).some(v => ferragem.get('product_code').includes(v))
                                            )
                                        })
                                        .map(el => el.toJSON())
                                        .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                        , 'objectId')
                                        .map(el => {
                                            return (
                                                <Select.Option value={el['objectId']}>{el['description_component']}</Select.Option>
                                            );
                                        })
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    :
                    <Col span={14}>
                        <Form.Item label='Fechadura/Usinagem' required={isRequiredProductComponent('hardware_door_lock')}>
                            <Select
                                disabled={_this.props.disabledFields}
                                value={getProductValue('hardware_door_lock')}
                                onChange={async value => {
                                    updateProductData('hardware_door_lock', index, value)
                                    updateRequiredFields('lock_quantity', !value)
                                    if (!getProductValue('lock_quantity')) updateProductData('lock_quantity', index, 1)
                                }}
                                placeholder="Fechadura" size="large"
                            >
                                <Select.Option value={null}>Selecione...</Select.Option>
                                {
                                    _.uniqBy((_this.state.doorLocksMatriz || [])
                                        .map(matriz => {
                                            const kitFechaduraMatrizField = _this.props.getValue("is_company") ? 'component_kit_fechadura' : 'component_kit_fechadura_pf'
                                            return matriz.get(kitFechaduraMatrizField).length ? matriz.get(kitFechaduraMatrizField) : matriz.get('component_kit_fechadura')
                                        })
                                        .flat()
                                        .filter(ferragem => {
                                            return (
                                                ferragem && ferragem.get('group') === 'Fechadura' &&
                                                codesOfComposition(getProductValue('door_lock_typology'), getProductValue('door_lock_type'), _this.state.fechMatriz).some(v => ferragem.get('product_code').includes(v))
                                            )
                                        })
                                        .map(el => el.toJSON())
                                        .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                        , 'objectId')
                                        .map(el => {
                                            return (
                                                <Select.Option value={el['objectId']}>{el['description_component']}</Select.Option>
                                            );
                                        })
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                }

                <Col span={10}>
                    <Form.Item label='Maçaneta/Puxador' required={isRequiredProductComponent('hardware_door_handle')}>
                        <Select
                            disabled={
                                !(
                                    (getLabelFromValue(doorLockComposition, getProductValue('door_lock_composition')) || '').toLowerCase().includes('maçaneta') ||
                                    (getLabelFromValue(doorLockComposition, getProductValue('door_lock_composition')) || '').toLowerCase().includes('puxador')
                                ) ||
                                _this.props.disabledFields
                            }
                            value={getProductValue('hardware_door_handle')}
                            onChange={async value => {
                                updateProductData('hardware_door_handle', index, value)
                            }}
                            placeholder="Maçaneta/Puxador" size="large"
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                _.uniqBy((_this.state.doorLocksMatriz || [])
                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                    .flat()
                                    .filter(ferragem => {
                                        const isMacaneta = (getLabelFromValue(doorLockComposition, getProductValue('door_lock_composition')) || '').includes('Maçaneta')
                                        const isPuxador = (getLabelFromValue(doorLockComposition, getProductValue('door_lock_composition')) || '').includes('Puxador')
                                        const addCategory = isMacaneta ? 'Maçaneta' : (isPuxador ? 'Puxador' : null)

                                        return ferragem && addCategory && ferragem.get('add_category') === addCategory
                                    })
                                    .map(el => el.toJSON())
                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                    , 'objectId'
                                )
                                    .map(el => {
                                        return (
                                            <Select.Option value={el['objectId']}>{el['description_component']}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                {/* <Col span={3}>
                    <Form.Item label="Qtd x Kit" required={isRequiredProductComponent('lock_quantity')}>
                        <InputNumber
                            value={getProductValue('lock_quantity')}
                            onChange={async value => {
                                updateProductData('lock_quantity', index, value)
                            }}
                            placeholder="Qtd"
                            size="large" disabled={_this.props.disabledFields}
                            type='number' />
                    </Form.Item>
                </Col> */}
            </Row>
            <Divider dashed={true} />
        </Fragment >
    )
}

export default productStepDoorLock