import { Button, Col, Form, Icon, Input, Row } from 'antd';
import React, { Component } from 'react'
import ReactListInput from 'react-list-input';
import { CreatePhoneMask, ValidateEmail } from '../../../../utils/mask';

export default class ContactsFields extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: [],

            contactKeys: {
                client: [{ contact: 'Compras' }, { contact: 'Arquitetura' }, { contact: 'Engenharia' }, { contact: 'Outros' }],
                work: [{ contact: 'Engenharia' }, { contact: 'Arquitetura' }, { contact: 'Planejamento' }, { contact: 'Gerencia' }],
                project: [{ contact: 'Arquitetura' }, { contact: 'Especificação' }, { contact: 'Compatibilização' }, { contact: 'Gerencia' }],
                instalation: [{ contact: 'Pedido Técnico' }, { contact: 'Serviços vão da porta' }, { contact: 'Gerencia' }],
            }
        }
    }

    item({ decorateHandle, removable, onChange, onRemove, value }) {

        return (
            <div
                style={{
                    paddingTop: 0,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderColor: '#e8e8e8',
                    clear: 'both',
                }}
            >
                <Row gutter={24}>
                    <Col span={1} style={{ height: '94px', paddingTop: '50px', textAlign: 'center' }}>
                        {decorateHandle(
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icon type="drag" size="large" style={{ cursor: 'move', fontSize: 18, margin: '0 16px' }} />
                            </div>
                        )}

                    </Col>

                    <Col span={21}>
                        <div>
                            <Row gutter={24} className="row-form-item" style={{ clear: 'both' }}>
                                {
                                    [
                                        { key: 'contact', title: 'Contato', size: 4 },
                                        { key: 'cargo', title: 'Cargo', size: 5 },
                                        { key: 'phone', title: 'Telefone', size: 3, isPhone: true },
                                        { key: 'cel', title: 'Celular', size: 3, isPhone: true },
                                        { key: 'whatsapp', title: 'Whatsapp', size: 3, isPhone: true },
                                        { key: 'email', title: 'E-mail', size: 6, validateAttr: ValidateEmail(value['email'] || '') },
                                    ].map(el => {
                                        return (
                                            <Col span={el.size} key={el.key}>
                                                <Form.Item label={el.title} {...el.validateAttr}>
                                                    <Input
                                                        onChange={(event) => onChange({ ...value, [el.key]: event.target.value })}
                                                        placeholder={el.title}
                                                        defaultValue={el.isPhone ? CreatePhoneMask(value[el.key]) : value[el.key]}
                                                        value={el.isPhone ? CreatePhoneMask(value[el.key]) : value[el.key]}
                                                        size="large"
                                                        {...el.attrs}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>

                    </Col>

                    <Col span={2} style={{ height: '94px', paddingTop: '50px', textAlign: 'center' }}>
                        <Button
                            onClick={removable ? onRemove : x => x}
                            shape="circle" icon="delete"
                            type='danger'
                            style={{
                                cursor: removable ? 'pointer' : 'not-allowed',
                            }}
                        ></Button>
                    </Col>
                </Row>

            </div>
        )
    }

    stagingItem({ value, onAdd, canAdd, add, onChange }) {
        return (
            <div
                style={{ marginBottom: 15 }}
            >
                <div
                    style={{
                        marginBottom: 15,
                        paddingLeft: 30,
                        paddingRight: 30,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#e8e8e8',
                    }}
                >

                    <div>
                        <Row gutter={24} className="row-form-item" style={{ clear: 'both' }}>
                            {
                                [
                                    { key: 'contact', title: 'Contato', size: 4 },
                                    { key: 'cargo', title: 'Cargo', size: 5 },
                                    { key: 'phone', title: 'Telefone', size: 3, isPhone: true },
                                    { key: 'cel', title: 'Celular', size: 3, isPhone: true },
                                    { key: 'whatsapp', title: 'Whatsapp', size: 3, isPhone: true },
                                    { key: 'email', title: 'E-mail', size: 6, validateAttr: ValidateEmail(value['email'] || '') },
                                ].map(el => {
                                    return (
                                        <Col span={el.size} key={el.key}>
                                            <Form.Item label={el.title} {...el.validateAttr}>
                                                <Input
                                                    onChange={(event) => onChange({ ...value, [el.key]: event.target.value })}
                                                    placeholder={el.title}
                                                    value={el.isPhone ? CreatePhoneMask(value[el.key]) : value[el.key]}
                                                    size="large"
                                                    {...el.attrs}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>

                    <div style={{ clear: 'both' }} />
                </div>

                <Button
                    onClick={(canAdd && value) ? onAdd : undefined}
                    disabled={!canAdd}
                    type='primary'
                >Adicionar</Button>

            </div>

        )
    }

    render() {
        return <ReactListInput
            initialStagingValue=''
            onChange={value => this.props.updateContacts(value)}
            ItemComponent={this.item.bind(this)}
            maxItems={1000}
            StagingComponent={this.stagingItem.bind(this)}
            value={this.props.value || this.state.contactKeys[this.props.contactKey] || []}
        />
    }

}