import React from 'react'
import { Icon, notification } from 'antd'
import Parse from 'parse'
export default {
	"fields": [
		[
			{
				"title": "Padrão",
				"key": "pattern",
				"type": "text",
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				},
				beforeSave: async (Form, ParseObject, node) => {
					const query = new Parse.Query('PatternColors')
					query.equalTo('pattern', Form.state.formRef.pattern)
					query.notEqualTo('objectId', ParseObject.id)
					const count = await query.count()
					if (count > 0) {
						notification.error({ message: 'Erro ao registrar o padão', description: 'Esse padrão já está registrado.' })
						return false;
					}

					return true;
				}
			}
		],
	],
	submit: {
		collection: "PatternColors"
	},
	className: "config-component",
	schema: [
		{
			title: "Padrão",
			key: "pattern",
			dataIndex: "pattern",
			type: "text",
			className: "name",
			sorter: (a, b) => {
				if (a.pattern > b.pattern) {
					return -1;
				}
				if (b.pattern > a.pattern) {
					return 1;
				}
				return 0;
			}
		},
	],
	"title-navigation": <div><Icon type="bg-colors" /> Padrão de Cores</div>,
	"title-module": "Padrão de Cores",
	"module": "PatternColors",
	"router-base": "/panel/padrao_cores"
}