import React, { Component, Fragment } from 'react';
import { Card, Button, Radio, Divider, message, Modal } from 'antd';
import { findAll } from '../../../../utils/db_functions';
import { surveyQuestionsAndAnswers } from './suport-files/survey-questions';

export default class CustomerSatisfactionSurvey extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            editFormFlag: false,

            disabledFields: false,
            orderName: null,

            form: {
                multidoorExperience: null,
                whatMostRelevant: null,
                processMonitoringByWeeklyStatus: null,
                finalProductExpectation: null,
                instalationProccess: null,
                doorComparative: null,
                techAssistance: null,
                finalSatisfaction: null,
            }
        }
    }

    componentWillMount = async () => {
        if (this.props['router-props'].location.pathname.includes('panel')) {
            this.setState({ editFormFlag: true });
        }
        this.getPcpoSurvey();
    }

    getPcpoSurvey = async () => {
        let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
        serverUrlArray.pop();
        const serverUrl = serverUrlArray.join('/');

        const orderCode = this.props['router-props'].location.search.split('=')[1];
        const response = await fetch(`${serverUrl}/api/pcpo-survey/${orderCode}`, { method: 'GET' });
        const responseJson = await response.json();

        if (responseJson.data) {
            this.setState({
                disabledFields: true,
                form: responseJson.data.form,
            });
        }
    }

    downloadUserManual = async () => {

        let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
        serverUrlArray.pop();
        const serverUrl = serverUrlArray.join('/');

        const order = this.props['router-props'].location.search.split('=')[1];

        if (this.state.disabledFields) {
            this.openDocumentsInNewTabs()
        } else {
            const response = await fetch(`${serverUrl}/api/pcpo-survey`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        orderCode: order,
                        form: this.state.form,
                    })
                });
            const responseJson = await response.json();

            if (responseJson.code !== 200) message.error('Erro ao baixar os documentos, tente novamente!');
            else {
                this.setState({ disabledFields: true });
                this.openDocumentsInNewTabs()
            }
        }
    }

    openDocumentsInNewTabs = async () => {
        const docsGroups = await findAll('OrderDocs', 200)
        const docs = (docsGroups || []).map(docGroup => docGroup.get('docs')).flat().filter(doc => doc && doc.sendWithPcpoSurvey)

        for (let docIndex = 0; docIndex < docs.length; docIndex++) {
            const document = docs[docIndex];
            if (document.doc) window.open(document.doc._url, '_blank');
        }
    }

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            <div className="app-login" style={{ padding: '5vh', position: 'relative', height: '100vh' }}>

                <div style={{ maxHeight: '100%' }}>

                    <Card style={{ width: '100%', maxWidth: '1000px', margin: 'auto', marginBottom: '50px', height: '90vh', overflowY: 'auto' }} id='checklist-card'>

                        <h2>Pesquisa de satisfação final do processo da jornada do cliente</h2>
                        <h4>Responda as oito perguntas e baixe o Manual do usuário</h4>
                        Multidoor 4.0
                        <Divider />

                        <div>

                            {surveyQuestionsAndAnswers.map(questionAndAnswers => {
                                return (
                                    <Fragment key={questionAndAnswers.question.key}>
                                        <h3>{questionAndAnswers.question.label}</h3>
                                        <Radio.Group disabled={this.state.disabledFields} onChange={(e) => this.setState({ form: { ...this.state.form, [questionAndAnswers.question.key]: e.target.value } })} value={this.state.form && this.state.form[questionAndAnswers.question.key]}>
                                            {
                                                questionAndAnswers.answers.map(el => {
                                                    return <Radio style={radioStyle} value={el.key}>{el.label}</Radio>
                                                })
                                            }
                                        </Radio.Group>
                                        <Divider />
                                    </Fragment>
                                )
                            })}

                            {this.state.disabledFields ?
                                <Button
                                    type='primary'
                                    disabled={Object.values(this.state.form).includes(null)}
                                    onClick={() => this.downloadUserManual()}
                                >
                                    Baixar documentos
                                </Button> :
                                <Button
                                    type='primary'
                                    disabled={Object.values(this.state.form).includes(null)}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Obrigado!',
                                            content: 'Agradecemos mais uma vez pela preferência e esperamos sempre atendê-los com excelência para em breve estarmos juntos em outro projeto de sucesso.',
                                            cancelText: 'Cancelar',
                                            okText: 'Baixar documentos',
                                            onOk: () => this.downloadUserManual(),
                                            iconType: 'like',
                                        })
                                    }}
                                >
                                    Enviar pesquisa
                                </Button>
                            }

                        </div>

                    </Card>

                </div>

            </div>
        )
    }

}