import React from 'react';
import { Icon, Button, Modal, Divider } from 'antd';
import generalInfoFields from './components/general-info-fields';
import inputCompositionFields from './components/input-composition-fields';
import { findById, findWhere } from '../../../utils/db_functions';
import priceCompositionFields from './components/price-composition-fields';
import finalPricingFields from './components/final-pricing-fields';
import {
    calculateLocalManHour, calculateProductionCost, calculateSubTotalCost,
    calculateTotal, calculateTotalCost, calculateTotalManHour, calculateUnitCost,
    formTabs, getConvertionFormulas, getInputs, getLastUpdatedDate, getManHours, updateInputValues
} from '../shared/component-mounting-instalation-functions';
import MassChangeModal from './entities/mass-changes-modal';

let localVinculatedPrice = 0
let inputs = [];
let manHours = [];
let convertionFormulas = []

export default {
    formTabs: formTabs(
        generalInfoFields(),
        inputCompositionFields({
            convertionFormulas: convertionFormulas,
            calculateSubTotalCost: (rows, inputs) => calculateSubTotalCost(rows, inputs, convertionFormulas),
            calculateUnitCost: (input, valueObj) => calculateUnitCost(input, valueObj, convertionFormulas),
        }),
        priceCompositionFields({
            manHours: manHours,
            calculateTotalCost: (compositions) => calculateTotalCost(compositions, inputs, convertionFormulas),
            calculateProductionCost: (component) => calculateProductionCost(component, inputs, convertionFormulas),
            calculateTotal: (component, marginZero) => calculateTotal(component, marginZero, inputs, convertionFormulas),
            calculateLocalManHour: (manHour) => calculateLocalManHour(manHour),
            calculateTotalManHour: (manHourValue, qtd) => calculateTotalManHour(manHourValue, qtd),
        }),
        finalPricingFields({
            manHours: manHours,
            localVinculatedPrice: localVinculatedPrice,
            calculateTotal: (component, marginZero) => calculateTotal(component, marginZero, inputs, convertionFormulas),
        })
    ),
    ListWillMount: async (_) => {
        inputs = await getInputs('updateInputDateId')
        manHours = await getManHours()
        convertionFormulas = await getConvertionFormulas()
        _.forceUpdate()
    },
    FormHasMounted: async (_) => {

        if (!inputs.length) inputs = await getInputs('updateInputDateId')
        // getLastUpdatedDate('updateInputDateId', inputs)
        if (!manHours.length) manHours = await getManHours()
        if (!convertionFormulas.length) convertionFormulas = await getConvertionFormulas()

        // Calculate vinculated component
        localVinculatedPrice = 0;
        const componentToCheckVinculatedComponent = _.state.formRef.objectId ? await findById('ComponenteKit', _.state.formRef.objectId) : null
        if (componentToCheckVinculatedComponent && componentToCheckVinculatedComponent.get('vinculated_component')) {
            const vinculatedComponent = await findById('ComponenteKit', componentToCheckVinculatedComponent.get('vinculated_component').id)
            const manHour = (manHours || []).find((manHour) => manHour.id === _.state.formRef.unity_value_hh)
            localVinculatedPrice = calculateTotal({ ...vinculatedComponent.toJSON(), ref_unity_value_hh: manHour && manHour.get('calculatedTotal') }, false, inputs, convertionFormulas)

            _.state.formRef.vinculated_component_name = vinculatedComponent.get('description_component')
        };

        _.module.form.formTabs[1] = {
            title: 'Composição de insumos',
            fields: inputCompositionFields({
                convertionFormulas: convertionFormulas,
                calculateSubTotalCost: (rows, inputs) => calculateSubTotalCost(rows, inputs, convertionFormulas),
                calculateUnitCost: (input, valueObj) => calculateUnitCost(input, valueObj, convertionFormulas),
            })
        }
        _.module.form.formTabs[2] = {
            title: 'Composição de preço',
            fields: priceCompositionFields({
                manHours: manHours,
                calculateTotalCost: (compositions) => calculateTotalCost(compositions, inputs, convertionFormulas),
                calculateProductionCost: (component) => calculateProductionCost(component, inputs, convertionFormulas),
                calculateTotal: (component, marginZero) => calculateTotal(component, marginZero, inputs, convertionFormulas),
                calculateLocalManHour: (manHour) => calculateLocalManHour(manHour),
                calculateTotalManHour: (manHourValue, qtd) => calculateTotalManHour(manHourValue, qtd),
            })
        }
        _.module.form.formTabs[3] = {
            title: 'Preço final',
            fields: finalPricingFields({
                manHours: manHours,
                localVinculatedPrice: localVinculatedPrice,
                calculateTotal: (component, marginZero) => calculateTotal(component, marginZero, inputs, convertionFormulas),
            })
        }

        _.forceUpdate();
    },
    submit: {
        collection: 'ComponenteKit'
    },
    schema: [
        {
            title: 'Código',
            key: 'product_code',
            dataIndex: 'product_code',
            width: '220px',
            type: 'text',
        },
        {
            title: 'Tipo',
            key: 'group',
            dataIndex: 'group',
            width: '100px',
            type: 'text',
        },
        {
            title: 'Descrição',
            key: 'description_component',
            dataIndex: 'description_component',
            type: 'text',
        },
        {
            title: 'HH',
            key: 'unity_value_hh',
            align: 'center',
            width: '70px',
            dataIndex: 'HH',
            type: 'text',
            render: (_, row) => `${(row.size_hh || 0).toFixed(2).replace('.', ',')}`
        },
        {
            title: 'Margem',
            key: 'margin_contri',
            align: 'center',
            width: '70px',
            dataIndex: 'Margem',
            type: 'text',
            render: (_, row) => `${row.margin_contri || 0}%`
        },
        {
            title: 'Preço',
            key: 'price_industry',
            dataIndex: 'price_industry',
            align: 'center',
            width: '120px',
            type: 'render',
            "relation-select": {
                key: ['vinculated_component']
            },
            render: (_, row) => {
                return (
                    <div
                        className='link-style'
                        onClick={async () => {
                            let total = 0;

                            const manHour = manHours.find((manHour) => manHour.id === row.unity_value_hh.objectId)

                            if (manHour)
                                total = calculateTotal({ ...row, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas)

                            if (row.vinculated_component) {
                                if (manHour)
                                    total = calculateTotal({ ...row.vinculated_component, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas);
                                total = total * (row.fact_multiple || 1);
                            }

                            let finishingOptions = [];
                            const finishingOptionsInputs = row.finishing_options_inputs
                            if (finishingOptionsInputs && finishingOptionsInputs.length) {
                                const finishings = await findWhere('Finishing', 'objectId', finishingOptionsInputs.map(el => el.finishing))
                                for (let finishingIndex = 0; finishingIndex < finishings.length; finishingIndex++) {
                                    const finishing = finishings[finishingIndex];

                                    const finishing_options_inputs = row.finishing_options_inputs.find(el => el.finishing === finishing.id)
                                    const rowWithFinishingInputs = { ...row, descriptions: [...row.descriptions, { description: 'Acabamento', rows: finishing_options_inputs.rows }] }
                                    const finishingTotal = calculateTotal({ ...rowWithFinishingInputs, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas)

                                    finishingOptions.push({ finishing: `${finishing.get('code')} - ${finishing.get('description')}`, finishingTotal })
                                }
                            }

                            Modal.info({
                                title: `Componente: ${row.product_code}`,
                                content: (
                                    <div>
                                        <h3>Preços</h3>

                                        <div className='label'>Componente básico</div>
                                        {(total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}

                                        <Divider />

                                        {finishingOptions.map(finishing => {
                                            return (
                                                <div>
                                                    <div className='label'>{finishing.finishing}</div>
                                                    {(finishing.finishingTotal || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })
                        }}
                    >
                        Ver preços
                    </div>
                )
                // let total = 0;

                // const manHour = manHours.find((manHour) => manHour.id === row.unity_value_hh.objectId)

                // if (manHour) {
                //     total = calculateTotal({ ...row, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas)
                // }

                // if (row.vinculated_component) {
                //     if (manHour)
                //         total = calculateTotal({ ...row.vinculated_component, ref_unity_value_hh: manHour.get('calculatedTotal') }, false, inputs, convertionFormulas);
                //     total = total * (row.fact_multiple || 1);
                // }
                // return (total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
        },
    ],
    'title-module': 'Componente de Kit',
    'topBarAction': ({ module }) =>
        <div style={{ float: 'right' }}>
            <MassChangeModal />
            <Button
                style={{ float: 'right', marginRight: '10px' }}
                onClick={() => updateInputValues(inputs)}
            >
                Atualizar preços
            </Button>
        </div>,
    'title-navigation':
        <div>
            <Icon type="tool" />
            Componente de Kit
            <span style={{ fontSize: 'small', fontWeight: 'lighter', marginLeft: '50px' }}>
                Atualização de preços:&nbsp;
                <span className='link-style' onClick={() => getLastUpdatedDate('updateInputDateId', inputs)} id='seeDateUpdateInputDateId'>Ver data</span>
                <span id='updateInputDateId' />
            </span>
        </div>,
    module: 'ComponenteKit',
    'router-base': '/panel/componente-kit',
}