import { findWhereMultiple } from "../../../../utils/db_functions"

const getComparative = async (form, sheetClass) => {
    const kits = await findWhereMultiple(
        'Kit',
        [
            { 'field': 'performance', 'value': `${form.sheet}-${sheetClass}`, 'op': 'like', logicOp: '$and' },
            { 'field': 'width', 'value': [form.width], 'op': 'containsAll', logicOp: '$and' },
            { 'field': 'height', 'value': form.height, 'op': 'equalTo', logicOp: '$and' },
            { 'field': 'height', 'value': form.height, 'op': 'equalTo', logicOp: '$and' },
            { 'field': 'typology', 'value': [form.typology], 'op': 'containsAll', logicOp: '$and' },

            { 'field': 'code', 'value': 'SL1NNNN', 'op': 'like', logicOp: '$and' },
            { 'field': 'code', 'value': '#', 'op': 'notLike', logicOp: '$and' },
        ], null,
        ['comp_folha', 'comp_marco']
    )

    if (kits.length) {
        const kit = kits[0];

        const sheet = kit.get('comp_folha').find(sheetComponent => sheetComponent.get('finishing').id === form.finishing)
        const marco = kit.get('comp_marco').find(marcoComponent => marcoComponent.get('finishing').id === form.finishing_marco)

        const mounting = kit.get('comp_mounting')
        const instalation = kit.get('instalation_kit')

        return {
            kit: kit && kit._id,
            sheet: sheet && sheet.id,
            marco: marco && marco.id,
            mounting: mounting && mounting.id,
            instalation: instalation && instalation.id,
        }
    } else return null
}

export {
    getComparative,
}