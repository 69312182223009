import React from 'react'
import { Icon, Divider, Button, Row, notification, Input, Modal } from 'antd'
import Parse from 'parse'
import moment from 'moment'


const renderKitsRow = {
	render: (Form, index, list, k, objectEdit, FormModule) => {
		return (
			<div key={'ref-row-' + index} style={{ backgroundColor: (k % 2 === 0 ? '#f2f2f2' : null), padding: '5px' }}>
				<Row gutter={24}>
					{Form.getElementByTypeSchema({ type: "render", title: 'Item', key: `item_${k}_${index}`, render: (_, form, node) => <Input disabled value={k + 1} size='large' /> }, 3, objectEdit, 0)}
					{/* {Form.getElementByTypeSchema({ type: "text", title: 'Item', key: `item_${k}_${index}`, "element-attr": { required: false, value: k + 1, disabled: true } }, 3, objectEdit, 0)} */}
					{Form.getElementByTypeSchema({ type: "text", title: 'Código Projeto', key: `cod_${k}_${index}`, 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "number", title: 'Qtd', key: `qtd_${k}_${index}`, "element-attr": { step: 1, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({
						type: "render", key: 'btn_remove', render: (_, form, node) => {
							return <Button type='danger' size='large' node={node} disabled={userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis)} onClick={() => {
								Modal.confirm({
									title: 'Confirme para excluir esse item',
									okType: 'danger',
									cancelText: 'Cancelar',
									okText: 'Excluir',
									onOk: () => {
										removeItem(FormModule, k, index, ProposalRequestComponent)
									}
								})
							}}>Remover item</Button>
						}
					}, 5, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row gutter={24}>
					<p style={{ marginBottom: 'unset' }}><b>Requisitos do cliente</b></p>
					{Form.getElementByTypeSchema({ type: "text", title: 'Modelo', key: `model_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Dimensões', key: `dim_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Tipologia', key: `tipology_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Espessura parede', key: `espess_parede_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Acabamento folha', key: `finishing_folha_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 4, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Acabamento marco + alizar', key: `finishing_marco_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 4, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Observação', key: `obs_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 4, objectEdit, 0)}
				</Row>

				<Row gutter={24}>
					<p style={{ marginBottom: 'unset' }}><b>Compatibilização Multidoor</b></p>
					{Form.getElementByTypeSchema({ type: "select", title: 'Modelo', key: `mult_model_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: [], optionConfig: { name: 'modelos' } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Largura', key: `mult_dim_width_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: [], optionConfig: { name: 'larguras_folha' } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Altura da Folha', key: `mult_dim_height_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: [], optionConfig: { name: 'alturas_folha' } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Altura Marco', key: `mult_dim_height_marco_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: [], optionConfig: { name: 'alturas_folha' } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Tipologia', key: `mult_tipology_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: [], optionConfig: { name: 'typology', arrayWithId: true } }, 6, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "text", title: 'Espessura parede', key: `mult_espess_parede_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Desempenho da porta', key: `mult_performance_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: [], optionConfig: { name: 'performance' } }, 3, objectEdit, 0)}
				</Row>

				<Row gutter={24}>
					{Form.getElementByTypeSchema({ type: "select", title: 'Acabamento Folha', key: `mult_finishing_folha_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), beforeChange: (_, node, value) => filterPattern('folha', `mult_finishing_folha_${k}_${index}`, value) }, options: finishingFolha }, 5, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Padrão (folha)', key: `mult_pattern_folha_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: (patterns.filter(v => v['finishing'] === patternFolhaFilters[`mult_finishing_folha_${k}_${index}`])[0] || { 'patterns': [] })['patterns'] }, 5, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Acabamento Marco/alizar', key: `mult_finishing_marco_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), beforeChange: (_, node, value) => filterPattern('marco', `mult_finishing_marco_${k}_${index}`, value) }, options: finishingMarcoAlizar }, 5, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Padrão (marco/alizar)', key: `mult_pattern_marco_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: (patterns.filter(v => v['finishing'] === patternMarcoAlizarFilters[`mult_finishing_marco_${k}_${index}`])[0] || { 'patterns': [] })['patterns'] }, 5, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "select", title: 'Fechadura', key: `mult_fechadura_${k}_${index}`, "element-attr": { required: false, disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis) }, options: fechaduras }, 4, objectEdit, 0)}
				</Row>
			</div>
		)
	}
}

const ProposalRequestComponent = {
	"fields": [
		[
			{
				type: 'header',
				title: 'Checklist & Cadastro',
				key: 'checklistHeader'
			},
		],
		[
			{
				type: 'text',
				key: 'cliente',
				title: 'Cliente',
				colSize: 12,
				'element-attr': { required: false }
			},
			{
				type: 'text',
				key: 'cnpj',
				title: 'CNPJ',
				colSize: 6,
				"element-attr": { required: false },
				maskToValue: { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true }
			},
			{
				type: 'text',
				key: 'state_registration',
				title: 'Inscrição Estadual',
				colSize: 6,
			}
		],
		[
			{
				type: 'text',
				key: 'cliente_address',
				title: 'Endereço do Cliente',
				colSize: 12,
				'element-attr': { required: false }
			},
			{
				type: 'text',
				key: 'cliente_city',
				title: 'Cidade',
				colSize: 6,
				"element-attr": { required: false },
			},
			{
				type: 'text',
				key: 'cliente_state',
				title: 'UF',
				colSize: 6,
				"element-attr": { required: false },
			}
		],
		[
			{
				type: 'text',
				key: 'work',
				title: 'Obra',
				colSize: 12,
				'element-attr': { required: false }
			},
			{
				type: 'text',
				key: 'work_city',
				title: 'Cidade da obra',
				colSize: 6,
				"element-attr": { required: false },
			},
			{
				type: 'text',
				key: 'work_state',
				title: 'UF da obra',
				colSize: 6,
				"element-attr": { required: false },
			}
		],
		[
			{
				type: 'text',
				key: 'contact',
				title: 'Contato',
				colSize: 6,
			},
			{
				type: 'email',
				key: 'email',
				title: 'E-mail',
				colSize: 6,
			},
			{
				type: 'text',
				key: 'phone_number',
				title: 'Telefone',
				colSize: 6,
				maskToValue: { phoneMask: true }
			},
			{
				type: 'text',
				key: 'mobile_phone',
				title: 'Celular',
				colSize: 6,
				maskToValue: { phoneMask: true }
			}
		],
		[
			{
				type: 'header',
				title: 'Informações Comerciais',
				key: 'comercialsHeader'
			},
		],
		[
			{
				type: 'select',
				key: 'contact_origin',
				title: 'Origem do contato',
				options: [],
				optionConfig: { name: 'origem_contato' },
				loaded: false,
				colSize: 6,
				'element-attr': { required: false }
			},
			{
				type: 'select',
				key: 'specification',
				title: 'Especificação Multidoor',
				options: [],
				optionConfig: { name: 'especificacao_multidoor' },
				loaded: false,
				colSize: 6,
				'element-attr': { required: false }
			},
			{
				type: 'select',
				key: 'purchase_involvement',
				title: 'Envolvimento na Compra',
				options: [],
				optionConfig: { name: 'envolvimento_compra' },
				loaded: false,
				colSize: 6,
				'element-attr': { required: false }
			},
			{
				type: 'select',
				key: 'purchase_purpose',
				title: 'Objetivo da Proposta',
				options: [],
				optionConfig: { name: 'objetivo_proposta' },
				loaded: false,
				colSize: 6,
				'element-attr': { required: false }
			},
		],
		[
			{
				type: 'header',
				title: 'Informações Técnicas',
				key: 'techHeader'
			},
			{
				title: "Estágio da Obra",
				key: "work_stage",
				type: "pointer",
				options: [],
				"relation-select": {
					"class": "WorkStage",
					"label": "name",
					"value": "objectId",
					"key": "objectId"
				},
				colSize: 5,
				"element-attr": { required: false, "mode": 'default' }
			},
			{
				type: 'select',
				key: 'usage',
				title: 'Tipo de Obra',
				options: [],
				optionConfig: { name: 'usage' },
				loaded: false,
				colSize: 5,
				'element-attr': {
					required: false,
				}
			},
			{
				type: 'select',
				key: 'desempenho_kit',
				title: 'Nível de Desempenho',
				options: [],
				optionConfig: { name: 'desempenho_kit' },
				loaded: false,
				colSize: 4,
				'element-attr': {
					required: false,
				}
			},
			{
				type: 'select',
				key: 'desempenho',
				title: 'Desempenho da Porta',
				options: [],
				optionConfig: { name: 'desempenho' },
				loaded: false,
				colSize: 5,
				'element-attr': {
					required: false, mode: 'multiple'
				}
			},
			{
				type: 'select',
				key: 'requirements',
				title: 'Requisitos Gerais',
				options: [],
				optionConfig: { name: 'requisitos_gerais' },
				loaded: false,
				colSize: 5,
				'element-attr': {
					required: false, mode: 'multiple'
				}
			},
		],
		[
			{
				type: 'header',
				title: 'Documentos',
				key: 'documentsHeader'
			},
			{
				type: 'document',
				key: 'doc_first',
				title: 'Documento 01',
				colSize: 8,
				'element-attr': { required: false }
			},
			{
				type: 'document',
				key: 'doc_second',
				title: 'Documento 02',
				colSize: 8,
				'element-attr': { required: false }
			},
			{
				type: 'document',
				key: 'doc_third',
				title: 'Documento 03',
				colSize: 8,
				'element-attr': { required: false }
			},
		],
		[{ type: 'render', colSize: 24, key: 'divider-all', render: () => <Divider />, className: 'divider-all-row' }],
		[
			{
				type: 'header',
				title: 'Quadro Geral de Portas',
				key: 'solicitationHeader'
			},
		],
		[{ type: 'render', colSize: 24, key: 'divider-all-status', render: () => <Divider />, className: 'divider-all-row' }],
		[
			{
				type: 'header',
				title: 'Status da Solicitação',
				key: 'solicitationStatusHeader'
			},
		],
		[
			{
				type: 'date-picker',
				key: 'status_client',
				title: 'Cliente',
				colSize: 3,
				'element-attr': { required: false }
			},
			{
				type: 'date-picker',
				key: 'status_representative',
				title: 'Representante',
				colSize: 3,
				'element-attr': { required: false }
			},
			{
				type: 'date-picker',
				key: 'status_prior_analysis',
				title: 'Análise prévia',
				colSize: 3,
			},
			{
				key: 'status_prior_analysis_by',
				title: 'Análise prévia feita por',
				type: "text",
				colSize: 5,
				"element-attr": { required: false, "disabled": true }
			},
			{
				type: 'select',
				key: 'status_status',
				title: 'Status',
				options: [
					{ label: 'Análise Prévia', value: 'Análise Prévia' },
					{ label: 'Reprovada', value: 'Reprovada' },
					{ label: 'Aprovada - Proposta emitida', value: 'Aprovada - Proposta emitida' },
					{ label: 'Aprovada - Emitir Proposta', value: 'Aprovada - Emitir Proposta' },
				],
				loaded: false,
				colSize: 4,
			},
			{
				key: 'status_status_by',
				title: 'Status atualizado por',
				type: "text",
				colSize: 5,
				"element-attr": { required: false, "disabled": true }
			},
			{
				type: 'date-picker',
				key: 'status_status_date',
				title: 'Data aprovação/reprovação',
				colSize: 3,
				'element-attr': { required: false }
			},
			{
				type: 'date-picker',
				key: 'status_proposal',
				title: 'Emitir Proposta',
				colSize: 3,
				'element-attr': { required: false }
			},
			{
				type: 'select',
				key: 'status_failed_obs',
				title: 'Motivo da reprovação',
				options: [],
				optionConfig: { name: 'motivo_reprovacao' },
				colSize: 5,
				'element-attr': { required: false }
			}
		],
		[
			{
				title: "Cliente",
				key: "requester_client",
				type: "select",
				options: [
					{ label: 'Site Multidoor', value: 'Site Multidoor' },
					{ label: 'E-mail Comercial', value: 'E-mail Comercial' },
				],
				colSize: 12,
				"element-attr": { required: false, "mode": 'default' }
			},
			{
				title: "Representante",
				key: "requester_representative",
				type: "pointer",
				options: ['Cliente: site Multidoor', 'Cliente: e-mail Comercial'],
				"relation-select": {
					"class": "TradeRepresentative",
					"label": "consult_repre",
					"value": "objectId",
					"key": "objectId"
				},
				colSize: 12,
				"element-attr": { required: false, "mode": 'default' }
			},
		],
		[
			{
				type: 'header',
				title: 'Propostas emitidas',
				key: 'proposalsHeader'
			},
		],
	],
	data: {
		kits: [{
			rows: [renderKitsRow]
		}]
	},
	FormComponent: (Form, Module) => {
		if (!ProposalRequestComponent.dataToEdit && Form.props.objectEdit) { // load data to edit and format values to fill inputs.
			let ParseQuery = new Parse.Query(new Parse.Object.extend(Module.collection))
			ParseQuery.get(Form.props.objectEdit).then(response => {
				ProposalRequestComponent.dataToEdit = response.toJSON()

				ProposalRequestComponent.dataToEdit = {
					...ProposalRequestComponent.dataToEdit,
					...ProposalRequestComponent.dataToEdit.kits.reduce((acc, value, index) => { // transforma o array em attr direto no formRef
						return {
							...acc,
							...value.rows.reduce((acc, v, index_row) => {
								let obj = {}
								for (let key in v) {
									obj[`${key}_${index}_${index_row}`] = v[key];
								}
								return { ...acc, ...obj }
							}, {})
						}
					}, {})
				}

				ProposalRequestComponent.data.kits = ProposalRequestComponent.dataToEdit.kits.map((v, i) => {
					return {
						rows: v.rows.map(row => {
							// filtrar patterns no select se esse campo ainda não foi definido pelo usuárioi
							if (row.mult_finishing_folha && !row.mult_pattern_folha) filterPattern('folha', `mult_finishing_folha_${i}_0`, row.mult_finishing_folha)
							if (row.mult_finishing_marco && !row.mult_pattern_marco) filterPattern('marco', `mult_finishing_marco_${i}_0`, row.mult_finishing_marco)

							return renderKitsRow
						})
					}
				})

				if (ProposalRequestComponent.dataToEdit.work_stage)
					ProposalRequestComponent.dataToEdit.work_stage = ProposalRequestComponent.dataToEdit.work_stage.objectId

				// Transformar as datas do formato Date em moment
				for (let value in ProposalRequestComponent.dataToEdit) {
					if (value.includes('status_') && ProposalRequestComponent.dataToEdit[value] && typeof ProposalRequestComponent.dataToEdit[value] === 'object')
						ProposalRequestComponent.dataToEdit[value] = moment(ProposalRequestComponent.dataToEdit[value]['iso'])
				}

				// Buscar propostas emitidas para essa solicitação
				let requestProposalCode = ProposalRequestComponent.dataToEdit.proposal_request_code
				let query = new Parse.Query('Proposal').limit(100000)
				query.contains('proposal_request', requestProposalCode)
					.find().then(res => {
						proposalsArray = res

						Form.setState(() => {
							return {
								formRef: ProposalRequestComponent.dataToEdit
							}
						})
					})
			})
		}

		// Calcular quantidade total de kits
		let calculateTotalKits = () => {
			setTimeout(() => {
				let total = 0
				let qtdArray = []
				for (let property in Form.refs) {
					if (property.includes('qtd_') && !property.includes('form_item')) {
						qtdArray.push(Form.refs[property])
					}
				}
				qtdArray = qtdArray.map(value => value.props.value)
				qtdArray = qtdArray.filter(value => value)

				qtdArray.forEach(value => total += value)
				if (document.getElementById('proposalRequestTotalKits')) document.getElementById('proposalRequestTotalKits').innerHTML = total
			}, 500)

		}

		return (
			<div>
				<Row key="row-1" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('checklistHeader'), 24, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-1a" gutter={24} className="row-form-item">
					<h3>Dados Gerais</h3>
				</Row>

				<Row key="row-2" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('cliente'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 12, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('cnpj'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('state_registration'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-3" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('cliente_address'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 12, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('cliente_city'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('cliente_state'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-4" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('work'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 12, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('work_city'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('work_state'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-5" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('contact'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('email'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('phone_number'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('mobile_phone'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 6, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-6" gutter={24} className="row-form-item">
					<h3 style={{ marginTop: '20px' }}>Informações Comerciais</h3>
				</Row>

				<Row key="row-7" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('contact_origin'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('specification'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('purchase_involvement'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true } }, 6, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('purchase_purpose'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true } }, 6, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-8" gutter={24} className="row-form-item">
					<h3 style={{ marginTop: '20px' }}>Informações Técnicas</h3>
				</Row>

				<Row key="row-9" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('work_stage'), 5, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('usage'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true } }, 5, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('desempenho_kit'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true } }, 4, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('desempenho'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true, mode: 'multiple' } }, 5, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('requirements'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: true, mode: 'multiple' } }, 5, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-9a" gutter={24} className="row-form-item">
					<h3 style={{ marginTop: '20px' }}>Documentos</h3>
				</Row>

				<Row key="row-9b" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('doc_first'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 8, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('doc_second'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 8, ProposalRequestComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ ...Form.getNodesByKey('doc_third'), 'element-attr': { disabled: userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis), required: false } }, 8, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-10" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('divider-all'), 24, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-11" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('solicitationHeader'), 24, ProposalRequestComponent.dataToEdit, 0)}
					{calculateTotalKits()}
					<h3 key='total'>Total de kits = <span id='proposalRequestTotalKits' /></h3>
				</Row>

				<Row key="row-11a" gutter={24} className="row-form-item">
					{(ProposalRequestComponent.data.kits || []).map((cd, k, l) => {
						return (
							<Row key={k}>
								{cd.rows.map((r, v, list) => r.render(Form, v, list, k, ProposalRequestComponent.dataToEdit, Form))}
							</Row>
						)
					})}
				</Row>

				<Row key="row-12" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({
						type: "render", key: 'btn_plus', className: 'btn-plus-no-title', render: (_, form, node) => {
							return <Button node={node} size='large' disabled={userIsRepresentative && (ProposalRequestComponent.dataToEdit && ProposalRequestComponent.dataToEdit.status_prior_analysis)} onClick={event => {
								ProposalRequestComponent.data.kits.push({
									rows: [renderKitsRow]
								})
								Form.forceUpdate()
							}}>Adicionar kit</Button>
						}
					}, 5, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-13" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('divider-all-status'), 24, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-14" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('solicitationStatusHeader'), 24, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				{!userIsRepresentative ?
					<Row key="row-15" gutter={24} className="row-form-item">
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_client'), 4, ProposalRequestComponent.dataToEdit, 0)}
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_representative'), 4, ProposalRequestComponent.dataToEdit, 0)}
						{Form.getElementByTypeSchema(
							{
								...Form.getNodesByKey('status_prior_analysis'),
								'element-attr': {
									disabled: priorAnalysisUserPermission ? false : true, required: false,
									beforeChange: (_, node, value) => {
										ProposalRequestComponent.dataToEdit['status_prior_analysis_by'] = priorAnalysisUserPermission
									}
								}
							}, 4, ProposalRequestComponent.dataToEdit, 0
						)}
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_prior_analysis_by'), 4, ProposalRequestComponent.dataToEdit, 0)}
						{Form.getElementByTypeSchema(
							{
								...Form.getNodesByKey('status_status'),
								'element-attr': {
									disabled: statusUserPermission ? false : true, required: false,
									beforeChange: (_, node, value) => {
										ProposalRequestComponent.dataToEdit['status_status_by'] = statusUserPermission
									}
								}
							}, 4, ProposalRequestComponent.dataToEdit, 0
						)}
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_status_by'), 4, ProposalRequestComponent.dataToEdit, 0)}
					</Row> : null
				}

				{!userIsRepresentative ?
					<Row key="row-16" gutter={24} className="row-form-item">
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_status_date'), 4, ProposalRequestComponent.dataToEdit, 0)}
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_proposal'), 4, ProposalRequestComponent.dataToEdit, 0)}
						{Form.getElementByTypeSchema(Form.getNodesByKey('status_failed_obs'), 16, ProposalRequestComponent.dataToEdit, 0)}
					</Row> : null
				}

				{!userIsRepresentative ?
					<Row key="row-17" gutter={24} className="row-form-item">
						<h3 style={{ marginTop: '20px' }}>Solicitante</h3>
					</Row> : null
				}

				{!userIsRepresentative ?
					<Row key="row-18" gutter={24} className="row-form-item">
						{Form.getElementByTypeSchema(Form.getNodesByKey('requester_client'), 12, ProposalRequestComponent.dataToEdit, 0)}
						{Form.getElementByTypeSchema(Form.getNodesByKey('requester_representative'), 12, ProposalRequestComponent.dataToEdit, 0)}
					</Row> : null
				}

				{userIsRepresentative ?
					<Row key="row-19" gutter={24} className="row-form-item">
						{(ProposalRequestComponent.dataToEdit ? ((ProposalRequestComponent.dataToEdit.status_status || '').includes('Aprovada') ? 'APROVADA' : ((ProposalRequestComponent.dataToEdit.status_status || '').includes('Reprovada') ? `REPROVADA - ${ProposalRequestComponent.dataToEdit.status_failed_obs}` : 'EM ANÁLISE')) : 'EM ANÁLISE')}
					</Row> : null
				}

				<Row key="row-20" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('divider-all-status'), 24, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<Row key="row-21" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('proposalsHeader'), 24, ProposalRequestComponent.dataToEdit, 0)}
				</Row>

				<ul>
					{
						proposalsArray.map((res, i) => {
							// Alteração no padrão do nome do arquivo conforme solicitado pelo cliente
							let lastUpdatedDate = res.get('updatedAt')
							let changeNameDate = new Date(2020, 8, 19)
							let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(res.get('name_client') || '').replace('/', '')}-${res.get('cod_cliente')}-${res.get('proposal_code')}.pdf`.replace(/\s/g, '-')
							if (lastUpdatedDate > changeNameDate) pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(res.get('name_client') || '').replace('/', '')}-${res.get('proposal_code')}-Multidoor.pdf`.replace(/\s/g, '-')

							if (res.get('pdf'))
								return (
									<li key={i}>
										<a target='blank' href={pdfName}>{res.get('proposal_code')}</a>
									</li>
								)
							return null
						})
					}
				</ul>
			</div>
		)
	},
	onClickActionEdit: row => {
		ProposalRequestComponent.dataToEdit = null
	},
	SubmitForm: (FormRef, ObjectEdit, ObjectId, FormModule) => {
		let node = FormModule.getNodesByKey('work_stage')
		let objectWithValues = parseDataToSave(FormRef, FormModule)
		let dinamicsValues = getDinamicsValues(FormRef, FormModule, ProposalRequestComponent)
		objectWithValues = { ...objectWithValues, kits: dinamicsValues, width_add: FormRef.width_add }
		let refs = FormModule.getRefWithFormItem(false)
		let invalid = false;
		for (let key in refs) {
			// checking if exist empty required inputs.
			if (!FormModule.nodeIsValid(FormModule.refs[key].props.node) || (!FormModule.nodeHasEmptyValue(FormModule.refs[key].props.value || FormModule.state.formRef[key]) && FormModule.nodeIsRequired(FormModule.refs[key].props.node))) {
				notification.error({ message: FormModule.refs[key].props.node.errorMessage || `Valor inválido no campo: ${FormModule.refs[key].props.node.title}`, duration: 5 })
				invalid = true;
				break;
			}
		}

		let data = objectWithValues
		if (invalid) return false;
		if (ObjectEdit) return updateComponenteKit(objectWithValues, ObjectId, FormModule)

		let Extension = new Parse.Object(FormModule.props.module.form.module)
		let PointerObject = new Parse.Object(node['relation-select'].class)
		PointerObject.id = data[node.key];
		Extension.set('work_stage', PointerObject);
		data[node.key] = PointerObject
		data['updatedBy'] = Parse.User.current()
		data['createdBy'] = Parse.User.current()

		// Transformar as datas do formato moment em Date
		for (let value in data) {
			if (moment.isMoment(data[value])) data[value] = moment(data[value]).toDate()
		}
		generateProposalCode().then(res => {
			data['proposal_request_code'] = res
			Extension.save(data).then(saved => {
				Parse.Cloud.run('generateProposalRequest', { id: saved.id })
				FormModule.afterSave()
			}).catch(err => {
				notification.error({
					message: 'Erro ao gerar a solicitação',
					description: err.message
				})
			})
		})
	},
	FormWillMount: () => {
		ProposalRequestComponent.data = {
			kits: [{
				rows: [renderKitsRow]
			}]
		}

		// Checar se usuário é representante
		let user_query = new Parse.Query('User')
		user_query.equalTo('objectId', Parse.User.current().id).first()
			.then(res => {
				res.relation('group').query().find(groups => {
					groups.forEach(group => {
						if (group.get('name').toLowerCase().includes('representante')) userIsRepresentative = true

						// Checar se usuário tem permissão para realizar análise prévia ou atualizar status
						group.relation('permission').query().limit(1000).find(permissions => {
							return permissions.forEach(permission => {
								if (permission.get('action') === 'ProposalRequestPriorAnalysis') priorAnalysisUserPermission = res.get('name')
								if (permission.get('action') === 'ProposalRequestStatus') statusUserPermission = res.get('name')
							})
						})
					})
					return this.forceUpdate()
				})
			})
	},
	ListWillMount: () => {
		// Trazer acabamentos do bd
		loadFinishing(finishing => {
			let finishing_folha_array = []
			let finishing_marco_alizar_array = []
			for (var property in finishing) {
				if (finishing[property].get('component_finishing') && finishing[property].get('component_finishing').includes('Folha da porta')) finishing_folha_array.push({ label: finishing[property].get('description'), value: finishing[property].get('description') })
				if (finishing[property].get('component_finishing') && finishing[property].get('component_finishing').includes('Marco e alizares')) finishing_marco_alizar_array.push({ label: finishing[property].get('description'), value: finishing[property].get('description') })
			}
			finishingFolha = finishing_folha_array
			finishingMarcoAlizar = finishing_marco_alizar_array

			// Trazer todos os padrões de acabamento
			loadPatternData()

			// Checar se usuário é representante
			let user_query = new Parse.Query('User')
			user_query.equalTo('objectId', Parse.User.current().id).first()
				.then(res => {
					res.relation('group').query().find(groups => {
						groups.forEach(group => {
							if (group.get('name').toLowerCase().includes('representante')) userIsRepresentative = true
						})
						return this.forceUpdate()
					})
				})

		})

		if (ProposalRequestComponent && ProposalRequestComponent.dataToEdit) ProposalRequestComponent.dataToEdit['status_prior_analysis'] = null
	},
	submit: {
		collection: "ProposalRequest"
	},
	className: "config-component",
	schema: [
		{
			title: 'Número',
			key: 'proposal_request_code',
			dataIndex: 'proposal_request_code',
			type: 'text'
		},
		{
			title: 'Cliente',
			key: 'cliente',
			dataIndex: 'cliente',
			type: 'text'
		},
		{
			title: 'Obra',
			key: 'work',
			dataIndex: 'work',
			type: 'text'
		},
		{
			title: 'Cidade',
			key: 'work_city',
			dataIndex: 'work_city',
			type: 'text'
		},
		{
			title: "Estado",
			key: "work_state",
			dataIndex: 'work_state',
			type: 'text'
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status_status',
			type: 'render',
			render: v => {
				if (!v) return "Aguardando análise"
				return v
			}
		},
		{
			title: 'Emitente',
			key: 'createdBy',
			dataIndex: 'createdBy',
			type: 'render',
			render: v => {
				if (v) return v['name']
				return ''
			}
		}
	],
	"title-navigation": <div><Icon type="paper-clip" /> Solicitação de Proposta</div>,
	"title-module": "Solicitação de Proposta",
	"module": "ProposalRequest",
	"router-base": "/panel/solicitacao-proposta",
	"filterByUser": true
}

const getDinamicsValues = (_, FormModule, ProposalRequestComponent) => {
	let refs = FormModule.getRefWithFormItem(false)
	return ProposalRequestComponent.data.kits.map((v, index) => {
		return {
			rows: v.rows.map((_r, index_row) => {
				if (refs.hasOwnProperty(`cod_${index}_${index_row}`)) {
					return {
						...[
							'cod', 'qtd',
							'model', 'dim', 'tipology', 'espess_parede', 'finishing_folha', 'finishing_marco', 'obs',
							'mult_model', 'mult_dim_width', 'mult_dim_height', 'mult_dim_height_marco', 'mult_tipology', 'mult_espess_parede', 'mult_performance',
							'mult_finishing_folha', 'mult_pattern_folha', 'mult_finishing_marco', 'mult_pattern_marco', 'mult_fechadura'
						].reduce((a, v) => {
							return { ...a, [v]: refs[`${v}_${index}_${index_row}`].props.value }
						}, {})
					}
				}

				return null;
			})
		}
	})
}

const parseDataToSave = (_, FormModule) => {
	// get ParseObject of vinculated_component
	let refs = FormModule.getRefWithFormItem(false)
	return Object.keys(getValues(FormModule)).filter(v => {
		return (v.match(/[0-9]|_slider|_input/g)) ? false : v;
	}).reduce((a, k) => {
		let value = refs[k].props.value
		return { ...a, [k]: value }
	}, {})
}

const getValues = (FormModule) => {
	let refs = FormModule.getRefWithFormItem(false)
	let obj = {}
	for (let item in refs) {
		let value = refs[item].props.value
		obj[item] = value;
	}
	return obj;
}

const updateComponenteKit = (data, objectId, FormModule) => {
	let node = FormModule.getNodesByKey('work_stage')
	let Query = new Parse.Query(FormModule.props.module.form.module)
	let PointerObject = new Parse.Object(node['relation-select'].class)
	PointerObject.id = data[node.key]

	data[node.key] = PointerObject
	Query.get(objectId).then(ObjectParse => {
		ObjectParse.set('work_stage', PointerObject);
		ObjectParse.set('updatedBy', Parse.User.current())

		// Transformar as datas do formato moment em Date
		for (let value in data) {
			if (moment.isMoment(data[value])) data[value] = moment(data[value]).toDate()
		}
		ObjectParse.save(data, {}).then(r => {
			Parse.Cloud.run('generateProposalRequest', { id: r.id })
			FormModule.finishEdit()
			ProposalRequestComponent.dataToEdit = null;
		}).catch(err => {
			notification.error({
				message: 'Erro ao gerar a solicitação',
				description: err.message
			})
		})
	})
}

const removeItem = (FormModule, blockCompositionIndex, rowCompositionIndex, moduleConfig) => {
	let kits = getDinamicsValues(FormModule.formRef, FormModule, moduleConfig)
	kits.splice(blockCompositionIndex, 1)
	applyCompositionsChange(FormModule, moduleConfig, kits)
}

const applyCompositionsChange = (FormModule, moduleConfig, compositionList) => {
	// this function will parse the database structure to formRef struct because formRef.
	let formRef = parseDataToSave(FormModule.formRef, FormModule);
	formRef.kits = (typeof formRef.kits !== "string") && undefined

	formRef = {
		...formRef,
		...compositionList.reduce((acc, value, index) => { // transforma o array em attr direto no formRef
			return {
				...acc,
				...value.rows.reduce((acc, v, index_row) => {
					let obj = {}
					for (let key in v) {
						obj[`${key}_${index}_${index_row}`] = v[key];
					}
					return { ...acc, ...obj }
				}, {})
			}
		}, {})
	}

	moduleConfig.data.kits = compositionList.map((v) => {
		return {
			rows: v.rows.map(() => renderKitsRow)
		}
	})

	FormModule.setState({ formRef, invalidForm: false })
}

async function generateProposalCode() {
	const currentYear = new Date().getFullYear();
	const _query = new Parse.Query('ProposalRequest').limit(100000)

	// Listar as propostas do ano corrente
	_query.startsWith('proposal_request_code', `S-${currentYear.toString()}`)
	let code = await _query.find().then(res => {
		let arrayProposalsId = []
		// Listar os códigos das propostas do ano corrente
		res.forEach(proposal => { arrayProposalsId.push(proposal.get('proposal_request_code')) })
		// Ordenar os códigos para ver o último gerado
		arrayProposalsId = arrayProposalsId.sort()
		// Pegar o último gerado e gerar o próximo
		let code = 1
		if (arrayProposalsId.length !== 0) {
			let proposalCode = arrayProposalsId[arrayProposalsId.length - 1]
			let proposalCodeArray = proposalCode.split('-')
			code = proposalCodeArray[2] / 1 + 1
		}
		const repeatZero = 4 - code.toString().length;
		return `S-${currentYear}-${'0'.repeat(repeatZero) + code.toString()}`
	})
	return code
}

const loadFinishing = (callback = function () { }) => {
	let q = new Parse.Query('Finishing')
	q.limit(1000)
	q.find({
		success: res => {
			let finishing = res.reduce((a, v) => {
				a[v.id] = v
				return a
			}, {})
			callback(finishing)
		}
	})
}

const loadPatternData = () => {
	let query = new Parse.Query('Finishing')
	query.find().then(response => {
		let patternsData = []
		response.forEach(finishing => {
			finishing.relation('pattern_color').query().find().then(res => {
				patternsData.push({
					finishing: finishing.get('description'),
					patterns: res.map(v => { return { label: v.get('pattern'), value: v.get('pattern') } })
				})
				patterns = patternsData
			})
		})
	})
}

// Filtrar padrões de acabamento
let filterPattern = (flag, finishing, finishingValue) => {
	if (flag === 'folha')
		patternFolhaFilters[finishing] = finishingValue

	if (flag === 'marco')
		patternMarcoAlizarFilters[finishing] = finishingValue
}

// Lista de padrões de acabamento para o select
let patterns = []

// Lista de acabamentos da folha para o select
let finishingFolha = []
let patternFolhaFilters = {}

// Lista de acabamentos do marco para o select
let finishingMarcoAlizar = []
let patternMarcoAlizarFilters = {}

// Lista de fechaduras para o select
let fechaduras = [
	{ label: 'Conj Fechadura', value: 'Conj Fechadura' }, { label: 'Kit PCD', value: 'Kit PCD' }, { label: 'Usinagem CNC', value: 'Usinagem CNC' },
	{ label: 'Fechadura TN', value: 'Fechadura TN' }, { label: 'Fechadura TR', value: 'Fechadura TR' }, { label: 'Fechadura Correr', value: 'Fechadura Correr' },
	{ label: 'Fechadura Magnética', value: 'Fechadura Magnética' }, { label: 'Fechadura Auxiliar', value: 'Fechadura Auxiliar' }, { label: 'Fechadura Digital', value: 'Fechadura Digital' },
]

let proposalsArray = []
let userIsRepresentative = false
let priorAnalysisUserPermission = null
let statusUserPermission = null

export default ProposalRequestComponent