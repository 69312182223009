import React, { Component } from "react";
import { Input, Button, Icon, InputNumber } from 'antd';
import ReactListInput from 'react-list-input';

/**
 * Classe que renderiza um input dinamico.
 */
export default class CityUtils extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: [],
      called: false
    }
  }

  componentWillReceiveProps(props) {
    this.setState(() => {
      return { value: props.value || [] }
    })
  }
  
  handleItemEdited(value, defaultMethod) {
    defaultMethod(value)
    if (this.props.alreadyEdited && !this.state.called) {
        this.setState(() => ({called: true}), () => this.props.alreadyEdited())
    }
  }

  Item({ decorateHandle, removable, onChange, onRemove, value }) {
  
    return (
      <div style={{ display: 'flex', paddingLeft: '10%', margin: "16px 0 " }}>
        <Input size="large" value={value.name} onChange={(event) => this.handleItemEdited({ ...value, name: event.target.value === "" ? value.name : event.target.value }, onChange)} placeholder="Nome da Cidade" />
        <InputNumber style={{ margin: '0 16px' }} size="large" value={value.size} placeholder="Distancia Real" onChange={(size) => this.handleItemEdited({ ...value, size: size === "" ? 0 : size }, onChange)} type='number' />
        {decorateHandle(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Icon type="drag" size="large" style={{ cursor: 'move', fontSize: 18, margin: '0 16px' }} /></div>)}
        
        <Button
          size="large"
          onClick={removable ? onRemove : x => x}
          style={{
            cursor: removable ? 'pointer' : 'not-allowed',
            color: removable ? 'black' : 'gray',
            flex: 'none'
          }}
          icon="close"
          shape="circle" />
      </div>
    )
  }

  StagingItem({ value, onAdd, canAdd, add, onChange }) {
  
    return (
      <div style={{ display: 'flex' }}>
        <Input size="large" value={value.name} onChange={(event) => this.handleItemEdited({ ...value, name: event.target.value }, onChange)} placeholder="Nome da Cidade" />
        <InputNumber style={{ margin: '0 16px' }} size="large" value={value.size} placeholder="Distancia Real" onChange={(size) => this.handleItemEdited({ ...value, size }, onChange)} type='number' />

        <Button style={{ flex: 'none', marginLeft: '16px' }}
          onClick={canAdd ? onAdd : undefined}
          disabled={(!value.name || !value.size)}
          icon="plus"
          shape="circle"
          size="large" />
      </div>
    )
  }

  onChange(value) {
    this.setState(() => {
      return { value }
    }, () => {
      this.props.onChange(value)
    })
  }

  render() {
    return (
      <ReactListInput
        initialStagingValue=''
        onChange={value => this.onChange(value)}
        ItemComponent={this.Item.bind(this)}
        maxItems={1000}
        StagingComponent={this.StagingItem.bind(this)}
        value={this.state.value}
      />
    )
  }
}
