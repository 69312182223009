import React from 'react'
import { Icon } from 'antd'
import ProposalsByTradeRepresentative from './components/proposals-by-trade-representative'
import { findById, getCurrentUser } from '../../../utils/db_functions';

const representativeObjectId = 'hhIqaQ06s2'

export default {
	"fields": [
		[
			{
				type: 'text',
				key: 'consult_repre',
				title: 'Nome',
				colSize: 12,
				'element-attr': { required: true }
			},
			{
				type: 'text',
				key: 'consult_repre_cpf',
				title: 'CPF',
				colSize: 6,
				"element-attr": { required: false },
				maskToValue: { mask: "xxx.xxx.xxx-xx", onlyNumber: true }
			},
			{
				type: 'text',
				key: 'consult_repre_cnpj',
				title: 'CNPJ',
				colSize: 6,
				"element-attr": { required: false },
				maskToValue: { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true }
			},
		],
		[
			{
				type: 'email',
				key: 'consult_repre_email',
				title: 'E-mail',
				"element-attr": { required: false },
				colSize: 8,
			},
			{
				type: 'text',
				key: 'consult_repre_phone',
				title: 'Telefone',
				colSize: 6,
				"element-attr": { required: false },
				maskToValue: { phoneMask: true }
			},
			{
				type: 'text',
				key: 'consult_repre_whatsapp',
				title: 'WhatsApp',
				colSize: 6,
				"element-attr": { required: false },
				maskToValue: { phoneMask: true }
			},
			{
				type: 'switch',
				key: 'consult_repre_external',
				title: 'Representante Externo?',
				colSize: 4,
				"element-attr": { required: false }
			}
		],
		(form) => <ProposalsByTradeRepresentative form={form} />
	],
	submit: {
		collection: "TradeRepresentative"
	},
	className: "",
	schema: [
		{
			title: 'Representante',
			key: 'consult_repre',
			dataIndex: 'consult_repre',
			type: 'text'
		},
		{
			title: 'E-mail',
			key: 'consult_repre_email',
			dataIndex: 'consult_repre_email',
			type: 'text'
		},
		{
			title: 'Telefone',
			key: 'consult_repre_phone',
			dataIndex: 'consult_repre_phone',
			type: 'text'
		},
		{
			title: 'WhatsApp',
			key: 'consult_repre_whatsapp',
			dataIndex: 'consult_repre_whatsapp',
			type: 'text'
		}
	],
	"title-navigation": <div><Icon type="team" /> Representantes</div>,
	"title-module": "Representantes",
	"module": "TradeRepresentative",
	"router-base": "/panel/representantes",
	'filterList': async () => {

		const userParseObject = await getCurrentUser()
		const userParseObjectWithRelations = await findById('User', userParseObject.id, null, ['group'])
		const user = userParseObjectWithRelations.toJSON()

		const representativeGroup = user['group'].find(group => group.objectId === representativeObjectId)

		if (!representativeGroup) return null

		return {
			'field': 'consult_repre_email',
			'value': user['username'],
		}
	}
}