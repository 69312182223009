import React from "react";
import { Icon } from "antd";

export default {
  fields: [
    [
      {
        type: "text",
        key: "type",
        title: "Tipo do serviço",
        colSize: 12,
        "element-attr": {
          required: true,
        },
      },
      {
        type: "number",
        key: "basic_aditional_percent",
        title: "Valor adicional BASIC (%)",
        colSize: 6,
        "element-attr": { required: true },
      },
      {
        type: "number",
        key: "gold_aditional_percent",
        title: "Valor adicional GOLD (%)",
        colSize: 6,
        "element-attr": { required: true },
      },
    ],
    [
      {
        title: "Quadro",
        key: "architectural_service_table",
        type: "dynamic-input-list",
        fields: [
          [
            {
              title: "Plano de serviços",
              key: "services_plan",
              type: "text",
              colSize: 12,
              "element-attr": { required: false },
            },
            {
              title: "Basic",
              key: "basic",
              type: "text",
              colSize: 4,
              "element-attr": { required: false },
            },
            {
              title: "Gold",
              key: "gold",
              type: "text",
              colSize: 4,
              "element-attr": { required: false },
            },
            {
              title: "Platinum",
              key: "platinum",
              type: "text",
              colSize: 4,
              "element-attr": { required: false },
            },
          ],
        ],
        "element-attr": { required: false },
      },
    ],
  ],
  submit: {
    collection: "ArchitecturalServices",
  },
  className: "config-component",
  schema: [
    {
      title: "Tipo",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Adicional BASIC (%)",
      key: "basic_aditional_percent",
      dataIndex: "basic_aditional_percent",
    },
    {
      title: "Adicional GOLD (%)",
      key: "gold_aditional_percent",
      dataIndex: "gold_aditional_percent",
    },
  ],
  "title-navigation": (
    <div>
      <Icon type="form" /> Serviços de arquitetura
    </div>
  ),
  "title-module": "Serviços de arquitetura",
  module: "ArchitecturalServices",
  "router-base": "/panel/architectural-services",
};
