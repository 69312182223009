const errorInputMessage = {
    'consultant': 'Preencha o nome do consultor',
    'consult_phones': 'Preencha o telefone do consultor',
    'consult_whatsapp': 'Preencha o WhatsApp do consultor',
    'consult_email': 'Preencha o email do consultor',
    'consult_repre': 'Preencha o campo Representante na aba Emitente',
    'consult_repre_phone': 'Preencha o campo telefone do Representante na aba Emitente',
    'consult_repre_email': 'Preencha o campo email do Representante na aba Emitente',
    'consult_repre_whatsapp': 'Preencha o campo whatsapp do Representante na aba Emitente',
    'consult_contact': 'Preencha o contato do consultor',
    'request_contact_origin': 'Preencha a origem do contato na aba Emitente',
    'request_specification': 'Preencha a especificação Multidoor na aba Emitente',
    'request_purchase_involvement': 'Preencha o envolvimento na compra na aba Emitente',
    'request_purchase_purpose': 'Preencha o objetivo da proposta na aba Emitente',
    'request_usage': 'Preencha o tipo de obra na aba Emitente',
    'request_desempenho_kit': 'Preencha o nível de desempenho na aba Emitente',
    'request_desempenho': 'Preencha o desempenho da porta na aba Emitente',
    'request_requirements': 'Preencha os requisitos gerais na aba Emitente',
    'proposal_code': "Preencha o Código da proposta",
    'cnpj': "Preencha o CNPJ do Cliente",
    'cpf': "Preencha o CPF do Cliente",
    'name_client': "Preencha o Nome do Cliente",
    'prop_num': "Preencha o Número da Proposta",
    'name_work': "Preencha o nome da obra",
    'responsible_work': "Preencha o responsável",
    'work_stage': "Preencha o estágio da obra",
    'prevision_buy': "Preencha a previsão da compra",
    'ocupation': "Preencha a ocupação",
    'performance_level': "Preencha o nível de desempenho",
    'instalation': "Preencha a instalação",
    'local_work': "Preencha o local da obra",
    'delivery': "Preencha a entrega",
    'type_work': "Preencha o tipo de instalação",
    'mobility_work': "Preencha a mobilidade na obra",
    'tech_manager': "Preencha o responsável técnico",
    'tech_assistance': "Preencha a assistência técnica",
    'team_mob': "Preencha a mobilização da equipe",
    'product': 'Preencha o produto',
    'env': 'Preencha o ambiente',
    'profile_performance': "Preencha o perfil de desempenho nos produtos da proposta",
    'typology': "Preencha a tipologia",
    'product_code': "Preencha o código do produto",
    'amount': "Preencha a quantidade do produto",
    'width_sheet': "Preencha a largura da folha",
    'height_sheet': "Preencha a altura da folha",
    'finishing_sheet': "Preencha o acabamento da folha",
    'pattern_sheet': "Preencha o padrão de cor da folha",
    'finishing_sheet_inactive': "Preencha a folha folha inativa",
    'width_marco': "Preencha a largura do marco",
    'height_marco': "Preencha a altura do marco",
    'thickness_marco': "Preencha a espessura da parede",
    'wall_thickness': "Preencha a espessura da parede",
    'finishing_marco': "Preencha o acabamento do marco",
    'pattern_marco': "Preencha o padrão de cor do marco",
    'finishing_aliz': "Preencha o acabamento do alizar",
    'pattern_aliz': "Preencha o padrão de cor do alizar",
    'fixed_aliz': "Preencha o alizar fixo",
    'adjustable_aliz': "Preencha o alizar regulável",
    'lock_quantity': "Preencha a quantidade de fechadura",
    'hardware_door_handle': "Preencha a maçaneta/puxador",
    'hardware_puller': "Preencha o puxador",
    'hardware_aerial_spring': "Preencha a mola aérea",
    'hardware_accessories': "Preencha os acessórios de ferragens",
    'hardware_accessories_02': "Preencha os acessórios de ferragens",
    'hardware_accessories_03': "Preencha os acessórios de ferragens",
    'hardware_quantity': "Preencha a quantidade de ferragens",
    'hardware_puller_amount': "Preencha a quantidade de ferragens",
    'hardware_bar_anti_panic_amount': "Preencha a quantidade de ferragens",
    'hardware_aerial_spring_amount': "Preencha a quantidade de ferragens",
    'hardware_accessories_amount': "Preencha a quantidade de ferragens",
    'hardware_accessories_amount_02': "Preencha a quantidade de ferragens",
    'hardware_accessories_amount_03': "Preencha a quantidade de ferragens",
    'wall_panel': "Preencha o painel parede",
    'panel_wall_type': "Preencha o tipo do painel parede",
    'panel_wall_dimensions': "Preencha o módulo do painel parede",
    'panel_wall_module': "Preencha o módulo do painel parede",
    'panel_wall_finishing': "Preencha o acabamento do painel parede",
    'panel_wall_pattern': "Preencha o padrão do painel parede",
    'panel_wall_instalation': "Preencha a instalação do painel parede",
    'payment_conditions': 'Preencha a condição de pagamento',
    'discount': "Preencha o desconto",
    'signal': "Preencha o sinal",
    'discountPlusSignal': "A soma do desconto mais o sinal deve ser menor de 100%",
    'installmentsNum': "Preencha o número de parcelas",
    'installmentsNumMoreThanZero': "O número de parcelas deve ser maior que zero",
    'installmentsNumEqualZero': "O número de parcelas deve ser zero",
    'm20': 'Preencha a informação do frete',
    'm30': 'Preencha a informação do frete',
    'm60': 'Preencha a informação do frete',
    'm90': 'Preencha a informação do frete',
}

export default errorInputMessage