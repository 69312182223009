import React from "react";
import {
  calculateInputOrderIPI,
  calculateInputOrderTotal,
} from "./input-order-functions";

const totalsFields = (props) => {
  return [
    [
      {
        title: "Valor frete",
        key: "shipment",
        type: "money",
        colSize: 4,
        "element-attr": { required: false },
      },
      {
        title: "Desconto",
        key: "discount",
        type: "money",
        colSize: 4,
        "element-attr": { required: false },
      },
      {
        title: "Total IPI",
        key: "ipi",
        type: "render",
        colSize: 4,
        render: (_) => {
          const { inputs, discount } = _.formRef;
          const total = calculateInputOrderTotal({ inputs });
          const ipi = calculateInputOrderIPI({ inputs, discount, total });

          return (
            <div style={{ fontWeight: "bold", lineHeight: "40px" }}>
              {(ipi || 0).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          );
        },
        "element-attr": { required: false },
      },
      {
        title: "Valor total",
        key: "total_value",
        type: "render",
        colSize: 6,
        render: (_) => {
          const { inputs, discount, shipment } = _.formRef;
          const total = calculateInputOrderTotal({ inputs });
          const ipi = calculateInputOrderIPI({ inputs, discount, total });

          return (
            <div style={{ fontWeight: "bold", lineHeight: "40px" }}>
              {(
                total + (shipment || 0) - (discount || 0) + (ipi || 0) || 0
              ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
            </div>
          );
        },
        "element-attr": { required: false },
      },
      {
        type: "document",
        key: "nf",
        colSize: 6,
        title: "Nota Fiscal",
        "element-attr": { required: false },
      },
    ],
  ];
};

export default totalsFields;
