import React from "react";
import { Table, Button, Modal } from "antd";
import { getCurrentUser } from "../../../../utils/db_functions";

const getCostArrayToUpdateHistoric = async (Form) => {
  let costsRecorded = Form.state.formRef.industry_cost_history || [];
  const user = await getCurrentUser();
  const costToPush = {
    material: Form.state.formRef.material,
    shipment_rate: Form.state.formRef.shipment_rate,
    shipment_rate_percent: Form.state.formRef.shipment_rate_percent,
    ipi: Form.state.formRef.ipi,
    user: user.get("name"),
    date: Date.now(),
  };
  if (costsRecorded.length) {
    const lastCost = costsRecorded[costsRecorded.length - 1];
    if (
      lastCost.material !== Form.state.formRef.material ||
      lastCost.shipment_rate !== Form.state.formRef.shipment_rate ||
      lastCost.shipment_rate_percent !==
        Form.state.formRef.shipment_rate_percent ||
      lastCost.ipi !== Form.state.formRef.ipi
    )
      costsRecorded.push(costToPush);
  } else costsRecorded.push(costToPush);

  return costsRecorded;
};

const renderCostHistoricTable = (historic, form) => {
  const columns = [
    {
      title: "Preço",
      dataIndex: "material",
      key: "material",
      render: (v) =>
        (v || 0).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      width: "20%",
    },
    {
      title: "Frete",
      dataIndex: "shipment_rate",
      key: "shipment_rate",
      render: (v, row) => {
        const shipment_rate_percent =
          ((row.shipment_rate_percent || 0) / 100) * row.material;

        return ((v || 0) + shipment_rate_percent).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
      width: "20%",
    },
    {
      title: "IPI",
      dataIndex: "ipi",
      key: "ipi",
      width: "20%",
      render: (v) => (v || 0).toFixed(2),
    },
    { title: "Atualizado por", dataIndex: "user", key: "user", width: "20%" },
    {
      title: "Atualizado em",
      dataIndex: "date",
      key: "date",
      width: "20%",
      render: (v) =>
        new Date(v).toLocaleString("pt-BR", {
          timeZone: "America/Maceio",
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
    },
  ];

  const dataSource = (historic || []).sort((a, b) => a.date - b.date).reverse();

  return (
    <div>
      <h3>Evolução dos preços</h3>
      <br />

      <Button
        type="danger"
        size="larger"
        onClick={() => {
          Modal.confirm({
            title: "Tem certeza que deseja apagar o histórico?",
            content: "Essa ação não poderá ser desfeita",
            onOk() {
              form.state.formRef.industry_cost_history = null;
              form.forceUpdate();
            },
            okText: "Sim",
            cancelText: "Não, deixa pra lá",
          });
        }}
      >
        Apagar histórico
      </Button>
      <br />
      <br />

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 400 }}
        locale={{ emptyText: "Sem histórico" }}
      />
    </div>
  );
};

const calculateInputTax = (tax, module, nodeValueAdditionalString = "") => {
  return (
    (module.getNumberValue(tax) / 100) *
    ((module.getNodeValue(`credit_tax_shipment${nodeValueAdditionalString}`) &&
      module.getNumberValue(`shipment_rate${nodeValueAdditionalString}`)) +
      (module.getNodeValue(`credit_tax_material${nodeValueAdditionalString}`) &&
        module.getNumberValue(`material${nodeValueAdditionalString}`)))
  );
};

export {
  getCostArrayToUpdateHistoric,
  renderCostHistoricTable,
  calculateInputTax,
};
