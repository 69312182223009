import {
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Button,
  Input,
} from "antd";
import _ from "lodash";
import React, { Fragment } from "react";

const productStepFerragem = ({
  blockIndex,
  index,
  getProductValue,
  updateProductData,
  _this,
  updateRequiredFields,
  isRequiredProductComponent,
}) => {
  const getAmountField = (field) => {
    switch (field) {
      case "hardware_puller":
        return "hardware_puller_amount";
      case "hardware_bar_anti_panic":
        return "hardware_bar_anti_panic_amount";
      case "hardware_aerial_spring":
        return "hardware_aerial_spring_amount";
      case "hardware_accessories":
        return "hardware_accessories_amount";
      case "hardware_accessories_02":
        return "hardware_accessories_amount_02";
      case "hardware_accessories_03":
        return "hardware_accessories_amount_03";
      default:
        return null;
    }
  };

  const setQtdAndUpdateRequired = (field, value) => {
    const amountField = getAmountField(field);

    updateRequiredFields(amountField, !value);
    if (!getProductValue(amountField)) updateProductData(amountField, index, 1);
  };

  return (
    <Fragment>
      <h2>Cadastro de Ferragens</h2>

      {(getProductValue("option_ferragem") || []).includes(
        "hardware_puller"
      ) ? (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Acessório">
              <Input value="Puxador" placeholder="Puxador" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Puxador">
              <Select
                value={getProductValue("hardware_puller")}
                onChange={async (value) => {
                  updateProductData("hardware_puller", index, value);
                  setQtdAndUpdateRequired("hardware_puller", value);
                }}
                placeholder="Puxador"
                size="large"
                disabled={_this.props.disabledFields}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                {_.uniqBy(
                  (_this.state.doorLocksMatriz || [])
                    .map((matriz) => matriz.get("component_kit_ferragens"))
                    .flat()
                    .filter(
                      (ferragem) =>
                        ferragem &&
                        ferragem.get("add_category") === "Puxador" &&
                        !ferragem.get("description_component").includes(" PCD ")
                    )
                    .map((el) => el.toJSON())
                    .sort((a, b) =>
                      a.description_component > b.description_component
                        ? 1
                        : b.description_component > a.description_component
                        ? -1
                        : 0
                    ),
                  "objectId"
                ).map((el) => {
                  return (
                    <Select.Option value={el["objectId"]}>
                      {el["description_component"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label="Qtd x Kit"
              required={isRequiredProductComponent("hardware_puller_amount")}
            >
              <InputNumber
                value={getProductValue("hardware_puller_amount")}
                max={3}
                step={1}
                onChange={async (value) => {
                  updateProductData("hardware_puller_amount", index, value);
                  if (!value) updateProductData("hardware_puller", index, null);
                }}
                placeholder="Quantidade de puxadores"
                size="large"
                disabled={_this.props.disabledFields}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              style={{ marginTop: "45px", width: "100%" }}
              type="danger"
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens = optionFerragens.filter(
                  (el) => el !== "hardware_puller"
                );
                updateProductData("option_ferragem", index, optionFerragens);

                updateProductData("hardware_puller", index, null);
                updateProductData("hardware_puller_amount", index, null);
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      ) : null}

      {/* Cliente solicitou alterar barra antipanico para puxador PCD em 08/set/2022 */}
      {(getProductValue("option_ferragem") || []).includes(
        "hardware_bar_anti_panic"
      ) ? (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Acessório">
              <Input
                value="Puxador PCD"
                placeholder="Puxador PCD"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Puxador PCD">
              <Select
                value={getProductValue("hardware_bar_anti_panic")}
                onChange={async (value) => {
                  updateProductData("hardware_bar_anti_panic", index, value);
                  setQtdAndUpdateRequired("hardware_bar_anti_panic", value);
                }}
                placeholder="Puxador PCD"
                size="large"
                disabled={_this.props.disabledFields}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                {_.uniqBy(
                  (_this.state.doorLocksMatriz || [])
                    .map((matriz) => matriz.get("component_kit_ferragens"))
                    .flat()
                    .filter(
                      (ferragem) =>
                        ferragem &&
                        ferragem.get("add_category") === "Puxador" &&
                        ferragem.get("description_component").includes(" PCD ")
                    )
                    .map((el) => el.toJSON())
                    .sort((a, b) =>
                      a.description_component > b.description_component
                        ? 1
                        : b.description_component > a.description_component
                        ? -1
                        : 0
                    ),
                  "objectId"
                ).map((el) => {
                  return (
                    <Select.Option value={el["objectId"]}>
                      {el["description_component"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label="Qtd x Kit"
              required={isRequiredProductComponent(
                "hardware_bar_anti_panic_amount"
              )}
            >
              <InputNumber
                value={getProductValue("hardware_bar_anti_panic_amount")}
                max={3}
                step={1}
                onChange={async (value) => {
                  updateProductData(
                    "hardware_bar_anti_panic_amount",
                    index,
                    value
                  );
                  if (!value)
                    updateProductData("hardware_bar_anti_panic", index, null);
                }}
                placeholder="Quantidade de Puxadores PCD"
                size="large"
                disabled={_this.props.disabledFields}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              style={{ marginTop: "45px", width: "100%" }}
              type="danger"
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens = optionFerragens.filter(
                  (el) => el !== "hardware_bar_anti_panic"
                );
                updateProductData("option_ferragem", index, optionFerragens);

                updateProductData("hardware_bar_anti_panic", index, null);
                updateProductData(
                  "hardware_bar_anti_panic_amount",
                  index,
                  null
                );
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      ) : null}

      {(getProductValue("option_ferragem") || []).includes(
        "hardware_aerial_spring"
      ) ? (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Acessório">
              <Input value="Mola Aérea" placeholder="Mola Aérea" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mola Aérea">
              <Select
                value={getProductValue("hardware_aerial_spring")}
                onChange={async (value) => {
                  updateProductData("hardware_aerial_spring", index, value);
                  setQtdAndUpdateRequired("hardware_aerial_spring", value);
                }}
                placeholder="Mola Aérea"
                size="large"
                disabled={_this.props.disabledFields}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                {_.uniqBy(
                  (_this.state.doorLocksMatriz || [])
                    .map((matriz) => matriz.get("component_kit_ferragens"))
                    .flat()
                    .filter(
                      (ferragem) =>
                        ferragem &&
                        ferragem.get("add_category") === "Mola Aérea"
                    )
                    .map((el) => el.toJSON())
                    .sort((a, b) =>
                      a.description_component > b.description_component
                        ? 1
                        : b.description_component > a.description_component
                        ? -1
                        : 0
                    ),
                  "objectId"
                ).map((el) => {
                  return (
                    <Select.Option value={el["objectId"]}>
                      {el["description_component"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label="Qtd x Kit"
              required={isRequiredProductComponent(
                "hardware_aerial_spring_amount"
              )}
            >
              <InputNumber
                value={getProductValue("hardware_aerial_spring_amount")}
                max={3}
                step={1}
                onChange={async (value) => {
                  updateProductData(
                    "hardware_aerial_spring_amount",
                    index,
                    value
                  );
                  if (!value)
                    updateProductData("hardware_aerial_spring", index, null);
                }}
                placeholder="Quantidade de Mola Aérea"
                size="large"
                disabled={_this.props.disabledFields}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              style={{ marginTop: "45px", width: "100%" }}
              type="danger"
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens = optionFerragens.filter(
                  (el) => el !== "hardware_aerial_spring"
                );
                updateProductData("option_ferragem", index, optionFerragens);

                updateProductData("hardware_aerial_spring", index, null);
                updateProductData("hardware_aerial_spring_amount", index, null);
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      ) : null}

      {(getProductValue("option_ferragem") || []).includes(
        "hardware_accessories"
      ) ? (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Acessório">
              <Input
                value="Batedor de Porta"
                placeholder="Batedor de Porta"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Batedor de Porta">
              <Select
                value={getProductValue("hardware_accessories")}
                onChange={async (value) => {
                  updateProductData("hardware_accessories", index, value);
                  setQtdAndUpdateRequired("hardware_accessories", value);
                }}
                placeholder="Acessórios (opção 01)"
                size="large"
                disabled={_this.props.disabledFields}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                {_.uniqBy(
                  (_this.state.doorLocksMatriz || [])
                    .map((matriz) => matriz.get("component_kit_ferragens"))
                    .flat()
                    .filter(
                      (ferragem) =>
                        ferragem && ferragem.get("add_category") === "Batedor"
                    )
                    .map((el) => el.toJSON())
                    .sort((a, b) =>
                      a.description_component > b.description_component
                        ? 1
                        : b.description_component > a.description_component
                        ? -1
                        : 0
                    ),
                  "objectId"
                ).map((el) => {
                  return (
                    <Select.Option value={el["objectId"]}>
                      {el["description_component"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label="Qtd x Kit"
              required={isRequiredProductComponent(
                "hardware_accessories_amount"
              )}
            >
              <InputNumber
                value={getProductValue("hardware_accessories_amount")}
                max={3}
                step={1}
                onChange={async (value) => {
                  updateProductData(
                    "hardware_accessories_amount",
                    index,
                    value
                  );
                  if (!value)
                    updateProductData("hardware_accessories", index, null);
                }}
                placeholder="Quantidade Acessórios"
                size="large"
                disabled={_this.props.disabledFields}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              style={{ marginTop: "45px", width: "100%" }}
              type="danger"
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens = optionFerragens.filter(
                  (el) => el !== "hardware_accessories"
                );
                updateProductData("option_ferragem", index, optionFerragens);

                updateProductData("hardware_accessories", index, null);
                updateProductData("hardware_accessories_amount", index, null);
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      ) : null}

      {(getProductValue("option_ferragem") || []).includes(
        "hardware_accessories_02"
      ) ? (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Acessório">
              <Input
                value="Visor-Olho Mágico"
                placeholder="Visor-Olho Mágico"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Visor-Olho Mágico">
              <Select
                value={getProductValue("hardware_accessories_02")}
                onChange={async (value) => {
                  updateProductData("hardware_accessories_02", index, value);
                  setQtdAndUpdateRequired("hardware_accessories_02", value);
                }}
                placeholder="Acessórios (opção 02)"
                size="large"
                disabled={_this.props.disabledFields}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                {_.uniqBy(
                  (_this.state.doorLocksMatriz || [])
                    .map((matriz) => matriz.get("component_kit_ferragens"))
                    .flat()
                    .filter(
                      (ferragem) =>
                        ferragem &&
                        ferragem.get("add_category") === "Olho mágico"
                    )
                    .map((el) => el.toJSON())
                    .sort((a, b) =>
                      a.description_component > b.description_component
                        ? 1
                        : b.description_component > a.description_component
                        ? -1
                        : 0
                    ),
                  "objectId"
                ).map((el) => {
                  return (
                    <Select.Option value={el["objectId"]}>
                      {el["description_component"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label="Qtd x Kit"
              required={isRequiredProductComponent(
                "hardware_accessories_amount_02"
              )}
            >
              <InputNumber
                value={getProductValue("hardware_accessories_amount_02")}
                max={3}
                step={1}
                onChange={async (value) => {
                  updateProductData(
                    "hardware_accessories_amount_02",
                    index,
                    value
                  );
                  if (!value)
                    updateProductData("hardware_accessories_02", index, null);
                }}
                placeholder="Quantidade Acessórios"
                size="large"
                disabled={_this.props.disabledFields}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              style={{ marginTop: "45px", width: "100%" }}
              type="danger"
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens = optionFerragens.filter(
                  (el) => el !== "hardware_accessories_02"
                );
                updateProductData("option_ferragem", index, optionFerragens);

                updateProductData("hardware_accessories_02", index, null);
                updateProductData(
                  "hardware_accessories_amount_02",
                  index,
                  null
                );
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      ) : null}

      {(getProductValue("option_ferragem") || []).includes(
        "hardware_accessories_03"
      ) ? (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Acessório">
              <Input
                value="Fecho de Segurança"
                placeholder="Fecho de Segurança"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Fecho de Segurança">
              <Select
                value={getProductValue("hardware_accessories_03")}
                onChange={async (value) => {
                  updateProductData("hardware_accessories_03", index, value);
                  setQtdAndUpdateRequired("hardware_accessories_03", value);
                }}
                placeholder="Acessórios (opção 03)"
                size="large"
                disabled={_this.props.disabledFields}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                {_.uniqBy(
                  (_this.state.doorLocksMatriz || [])
                    .map((matriz) => matriz.get("component_kit_ferragens"))
                    .flat()
                    .filter(
                      (ferragem) =>
                        ferragem &&
                        ferragem.get("add_category") === "Fecho de segurança"
                    )
                    .map((el) => el.toJSON())
                    .sort((a, b) =>
                      a.description_component > b.description_component
                        ? 1
                        : b.description_component > a.description_component
                        ? -1
                        : 0
                    ),
                  "objectId"
                ).map((el) => {
                  return (
                    <Select.Option value={el["objectId"]}>
                      {el["description_component"]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label="Qtd x Kit"
              required={isRequiredProductComponent(
                "hardware_accessories_amount_03"
              )}
            >
              <InputNumber
                value={getProductValue("hardware_accessories_amount_03")}
                max={3}
                step={1}
                onChange={async (value) => {
                  updateProductData(
                    "hardware_accessories_amount_03",
                    index,
                    value
                  );
                  if (!value)
                    updateProductData("hardware_accessories_03", index, null);
                }}
                placeholder="Quantidade Acessórios"
                size="large"
                disabled={_this.props.disabledFields}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              style={{ marginTop: "45px", width: "100%" }}
              type="danger"
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens = optionFerragens.filter(
                  (el) => el !== "hardware_accessories_03"
                );
                updateProductData("option_ferragem", index, optionFerragens);

                updateProductData("hardware_accessories_03", index, null);
                updateProductData(
                  "hardware_accessories_amount_03",
                  index,
                  null
                );
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      ) : null}

      <div style={{ textAlign: "center" }}>
        {[
          { label: "Puxador", value: "hardware_puller" },
          { label: "Puxador PCD", value: "hardware_bar_anti_panic" },
          { label: "Mola Aérea", value: "hardware_aerial_spring" },
          { label: "Batedor de Porta", value: "hardware_accessories" },
          { label: "Olho mágico", value: "hardware_accessories_02" },
          { label: "Fecho de segurança", value: "hardware_accessories_03" },
        ].map((ferragemElement) => {
          return (
            <Button
              style={{ marginTop: "20px", marginRight: "5px" }}
              disabled={(getProductValue("option_ferragem") || []).includes(
                ferragemElement.value
              )}
              onClick={() => {
                let optionFerragens = getProductValue("option_ferragem") || [];
                optionFerragens.push(ferragemElement.value);
                updateProductData("option_ferragem", index, optionFerragens);
              }}
            >
              Adicionar {ferragemElement.label}
            </Button>
          );
        })}
      </div>

      <Divider dashed={true} />
    </Fragment>
  );
};

export default productStepFerragem;
