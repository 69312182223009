const surveyQuestionsAndAnswers = [
    {
        question: {
            key: 'whatMostRelevant',
            label: 'Em relação ao atendimento técnico, o que foi mais relevante?',
        },
        answers: [
            {
                key: 'Revisão do projeto com arquiteto especialista',
                label: 'Revisão do projeto com arquiteto especialista',
            },
            {
                key: 'Vistoria na obra com assessoria de engenharia',
                label: 'Vistoria na obra com assessoria de engenharia',
            },
            {
                key: 'Documentos para execução do vão da porta',
                label: 'Documentos para execução do vão da porta',
            },
            {
                key: 'Esclarecimentos e detalhes do Pedido Técnico',
                label: 'Esclarecimentos e detalhes do Pedido Técnico',
            },
        ]
    },
    {
        question: {
            key: 'processMonitoringByWeeklyStatus',
            label: 'O acompanhamento dos processos através do Status semanal do Pedido foi:',
        },
        answers: [
            {
                key: 'Esclarecedor',
                label: 'Esclarecedor',
            },
            {
                key: 'Profissional',
                label: 'Profissional',
            },
            {
                key: 'Satisfatório',
                label: 'Satisfatório',
            },
            {
                key: 'Preocupante',
                label: 'Preocupante',
            },
        ]
    },
    {
        question: {
            key: 'instalationProccess',
            label: 'Como foi o processo de instalação das portas na sua obra?',
        },
        answers: [
            {
                key: 'Ocorreu após cumprido o checklist de instalação',
                label: 'Ocorreu após cumprido o checklist de instalação',
            },
            {
                key: 'Tranquilo conforme previsto nos documentos',
                label: 'Tranquilo conforme previsto nos documentos',
            },
            {
                key: 'Foi iniciado mesmo com pendencias de checklist',
                label: 'Foi iniciado mesmo com pendencias de checklist',
            },
            {
                key: 'Tumultuado devido aos serviços paralelos na obra',
                label: 'Tumultuado devido aos serviços paralelos na obra',
            },
        ]
    },
    {
        question: {
            key: 'doorComparative',
            label: 'Qual o comparativo do processo das portas com outros serviços contratados na obra?',
        },
        answers: [
            {
                key: 'Foi mais objetivo e profissional',
                label: 'Foi mais objetivo e profissional',
            },
            {
                key: 'Similar aos demais fornecedores',
                label: 'Similar aos demais fornecedores',
            },
            {
                key: 'Exigiu mais envolvimento dos profissionais',
                label: 'Exigiu mais envolvimento dos profissionais',
            },
            {
                key: 'Provocou muitas interferências na construção',
                label: 'Provocou muitas interferências na construção',
            },
        ]
    },
    {
        question: {
            key: 'techAssistance',
            label: 'Houve assistência técnica durante a instalação?',
        },
        answers: [
            {
                key: 'Não - tudo ocorreu normalmente',
                label: 'Não - tudo ocorreu normalmente',
            },
            {
                key: 'Sim - devido a revisão de projeto',
                label: 'Sim - devido a revisão de projeto',
            },
            {
                key: 'Sim - devido a qualidade do produto entregue',
                label: 'Sim - devido a qualidade do produto entregue',
            },
            {
                key: 'Sim - devido a avarias de terceiros na obra',
                label: 'Sim - devido a avarias de terceiros na obra',
            },
        ]
    },
    {
        question: {
            key: 'finalProductExpectation',
            label: 'O produto final Multidoor atendeu a sua expectativa?',
        },
        answers: [
            {
                key: 'Correspondeu aos documentos e projetos',
                label: 'Correspondeu aos documentos e projetos',
            },
            {
                key: 'Superou a expectativa geral existente',
                label: 'Superou a expectativa geral existente',
            },
            {
                key: 'Faltou um acompanhamento mais presente',
                label: 'Faltou um acompanhamento mais presente',
            },
            {
                key: 'Ficou a desejar - não atendeu a expectativa',
                label: 'Ficou a desejar - não atendeu a expectativa',
            },
        ]
    },
    {
        question: {
            key: 'finalSatisfaction',
            label: 'Qual a sua satisfação final com os produtos Multidoor?',
        },
        answers: [
            {
                key: 'Pretende comprar para novos projetos',
                label: 'Pretende comprar para novos projetos',
            },
            {
                key: 'Indicará sempre como boa referencia',
                label: 'Indicará sempre como boa referencia',
            },
            {
                key: 'Indiferente - tudo ficou dentro do previsto',
                label: 'Indiferente - tudo ficou dentro do previsto',
            },
            {
                key: 'Não indica - ficou abaixo da expectativa',
                label: 'Não indica - ficou abaixo da expectativa',
            },
        ]
    },
    {
        question: {
            key: 'multidoorExperience',
            label: 'Como você qualifica no geral a sua experiencia com a jornada do cliente Multidoor?',
        },
        answers: [
            {
                key: 'Excelente',
                label: 'Excelente',
            },
            {
                key: 'Boa',
                label: 'Boa',
            },
            {
                key: 'Ruim',
                label: 'Ruim',
            },
            {
                key: 'Péssima',
                label: 'Péssima',
            },
        ]
    },
]

/*
{
    question: {
        key: '',
        label: '',
    },
    answers: [
        {
            key: '',
            label: '',
        },
    ]
}
*/

export {
    surveyQuestionsAndAnswers,
}