import React from 'react'
import { Icon, Divider, Select, Tag } from 'antd'

export default {
	formTabs: [
		{
			title: "Informações gerais",
			fields: [
				[
					{
						type: 'select',
						key: 'performances',
						title: 'Desempenho',
						options: [],
						optionConfig: { name: 'performance' },
						loaded: false,
						colSize: 6,
						"element-attr": {
							mode: 'multiple',
							required: true
						},
					},
				],

				[
					{
						type: 'render',
						render: () => {
							return (
								<Divider>Kit Porta</Divider>
							)
						}
					},
				],
				[
					{
						title: "Nível de desempenho",
						key: "kit_performance",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Vida Útil de Projeto",
						key: "kit_vup",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Cíclo Abertura-Fechamento",
						key: "kit_cicle",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Tráfego de uso",
						key: "kit_traffic",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
				],
				[
					{
						title: "Ocupação",
						key: "kit_occupation",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Uso",
						key: "kit_use",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Desempenho ABNT",
						key: "kit_performance_abnt",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					}
				],

				[
					{
						type: 'render',
						render: () => {
							return (
								<Divider>Folha</Divider>
							)
						}
					},
				],
				[
					{
						title: "Classificação",
						key: "folha_classification",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Capa",
						key: "folha_cape",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Quadro e Núcleo",
						key: "folha_frame_core",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Porta engenheirada - adesivo",
						key: "folha_engenheirada",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Bordas PP - Polipropileno - mm",
						key: "folha_bordas",
						type: "text",
						colSize: 4,
						"element-attr": { required: true }
					}
				],

				[
					{
						type: 'render',
						render: () => {
							return (
								<Divider>Marco</Divider>
							)
						}
					},
				],
				[
					{
						title: "Classificação",
						key: "marco_classification",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Espessura",
						key: "marco_thickness",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Construção engenheirada",
						key: "marco_construction",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Marco engenheirado - Adesivo",
						key: "marco_engenheirado",
						type: "text",
						colSize: 5,
						"element-attr": { required: true }
					},
					{
						title: "Amortecedor - Vedação",
						key: "marco_buffer",
						type: "text",
						colSize: 4,
						"element-attr": { required: true }
					}
				],

				[
					{
						type: 'render',
						render: () => {
							return (
								<Divider>Ferragens</Divider>
							)
						}
					},
				],
				[
					{
						title: "Dobradiças",
						key: "ferragem_dob",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Vaivém",
						key: "ferragem_vaivem",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Pivotante",
						key: "ferragem_pivot",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
					{
						title: "Correr",
						key: "ferragem_correr",
						type: "text",
						colSize: 6,
						"element-attr": { required: true }
					},
				],
			]
		},
		{
			title: "Instruções para proposta",
			fields: [
				[
					{
						type: 'render',
						key: 'performance_render',
						title: 'Desempenho',
						colSize: 6,
						"element-attr": { required: false },
						render: (_) => {
							return (
								<div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
									{_.formRef.performance}
								</div>
							);
						}
					},
				],
				[
					{
						type: 'dynamic-input-list',
						key: 'proposal_notes',
						fields: [
							[
								{
									type: 'select',
									key: 'typology',
									title: 'Tipologia',
									options: [],
									optionConfig: { name: 'typology', arrayWithId: true },
									loaded: false,
									"element-attr": {
										mode: 'multiple',
										required: true,
									},
									render: (options) => {
										let opt = options.reduce((acc, v) => {
											let type = v.label.split(' ')[0]
											if (!acc[type]) {
												acc[type] = {
													children: []
												}
											}
											acc[type].children.push(v)
											return acc;
										}, {});
										let types = Object.keys(opt)
										return types.map((type, index) => {
											return (
												<Select.OptGroup label={types[index]} key={`group-${index}`}>
													{opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
												</Select.OptGroup>
											)
										})
									}
								},
							],
							[
								{
									title: 'Instruções',
									key: 'notes',
									type: 'textarea',
									'element-attr': { required: true, rows: 2 },
								},
							]
						]
					}
				]
			]
		}
	],
	submit: {
		collection: "ChecklistConfigs"
	},
	schema: [
		{
			"title": "Desempenho",
			"key": "performances",
			"type": "text",
			render: row => (row.performances || []).map((item, key) => (<Tag key={key}>{item}</Tag>))
		},
		{
			title: "Nível de Desempenho",
			key: "kit_performance",
			dataIndex: "kit_performance",
			type: "text",
		},
		{
			title: "Vida Útil",
			key: "kit_vup",
			dataIndex: "kit_vup",
			type: "text",
		},
		{
			title: "Cíclos",
			key: "kit_cicle",
			dataIndex: "kit_cicle",
			type: "text",
		},
		{
			title: "Tráfego",
			key: "kit_traffic",
			dataIndex: "kit_traffic",
			type: "text",
		},
		{
			title: "Ocupação",
			key: "kit_occupation",
			dataIndex: "kit_occupation",
			type: "text",
		},
	],
	"title-navigation": <div><Icon type="table" /> Checklist Especificação</div>,
	"title-module": "Checklist Especificação",
	"module": "ChecklistConfigs",
	"router-base": "/panel/checklist-configs",

}