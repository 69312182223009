import { Modal, Input, Button, Table, Spin } from 'antd'
import React, { Component } from 'react'
import { findWhereMultiple } from '../../../../utils/db_functions'

export default class VinculatedModalClass extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            loading: false,
            components: [],
        }
    }

    searchComponents = async (term) => {
        this.setState({ loading: true })

        const componentsFound = await findWhereMultiple(
            'ComponenteKit',
            [
                { 'field': 'product_code', 'value': term, 'op': 'like', logicOp: '$or' },
                { 'field': 'description_component', 'value': term, 'op': 'like', logicOp: '$or' }
            ],
            null, null, null, 'product_code'
        )

        this.setState({
            components: componentsFound.map((component) => {
                return { code: component, component: component, key: component.id }
            }),
            loading: false
        })
    }

    render() {
        const columns = [
            {
                title: 'Código',
                dataIndex: 'code',
                key: 'code',
                render: component => <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        this.props.setVinculatedComponent(component)
                        this.setState({ modalVisible: false })
                    }}
                >{component.get('product_code')}</div>
            },
            {
                title: 'Componente',
                dataIndex: 'component',
                key: 'component',
                render: component => <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        this.props.setVinculatedComponent(component)
                        this.setState({ modalVisible: false })
                    }}
                >{component.get('description_component')}</div>
            },
        ];

        return (
            <div>
                <Button size='large' style={{ width: '100%' }} onClick={() => this.setState({ modalVisible: true })}>Escolher componente</Button>

                <Modal
                    title='Pesquise por um componente'
                    visible={this.state.modalVisible}
                    footer={[
                        <Button onClick={() => this.setState({ modalVisible: false })}>Cancelar</Button>
                    ]}
                    onCancel={() => this.setState({ modalVisible: false })}
                >
                    <Spin spinning={this.state.loading} tip='Carregando componentes'>

                        <Input.Search
                            style={{ marginBottom: '20px' }}
                            placeholder='Código ou nome do componente'
                            onSearch={e => {
                                this.searchComponents(e)
                            }}
                        />

                        <Table dataSource={this.state.components} columns={columns} locale={{ emptyText: 'Nenhum componente encontrado' }} />
                    </Spin>
                </Modal>
            </div >
        )
    }
}