import React, { Component, Fragment } from 'react'
import {
  notification, message, Card, Breadcrumb, Tooltip, Button,
  Icon, Row, Col, Form, Switch, Divider,
  Select, Collapse, Menu, Dropdown, InputNumber, Modal, Spin
} from 'antd'
import '../../proposal/components/proposal-form.css'
import _ from 'lodash'
import Parse from 'parse'
import { calculatePriceRow } from '../../../components-and-kits/component-kit/component_kit [OLD]'
import objectHash from 'object-hash'
import 'react-quill/dist/quill.snow.css';
import Auth from '../../../../auth/Auth';

let inputPrices = {};
export default class PriceListForm extends Component {

  state = {
    objectEdit: null,
    objectId: null,
    current: 0,
    required: {
      proposal: [
        'description',
        'performance_level',
        'instalation',
      ],
      product: [
        'env',
        'profile_performance',
        'typology',
        'product_code',
        'finishing_sheet',
        'pattern_sheet',
        'thickness_marco',
        'finishing_marco',
        'pattern_marco',
      ],
      wall_panel: [
        'panel_wall_module',
        'panel_wall_finishing',
        'panel_wall_pattern',
        'panel_wall_instalation'
      ],
      specific_products: []
    },
    data: {
      description: null,
      performance_level: null,
      instalation: null,
      sales_channel_inside_form: null,
      product_blocks: [
        {
          title: '',
          description: '',
          products: [{
            product_hash: null,
            project_code: null,
            product: null,
            env: null,
            profile_performance: null,
            performance_add: null,
            typology: null,
            amount: null,
            product_code: null,
            width_sheet: null,
            height_sheet: null,
            finishing_sheet: null,
            pattern_sheet: null,
            width_marco: null,
            height_marco: null,
            thickness_marco: null,
            finishing_marco: null,
            pattern_marco: null,
            sheet_width_aliz: null,
            sheet_height_aliz: null,
            finishing_aliz: null,
            pattern_aliz: null,
            fixed_aliz: null,
            adjustable_aliz: null,
            marco_add_passage: null,
            marco_add_seteira: null,
            marco_add_flag_panel: null,
            marco_add_flag_panel_glass: null,
            fixing_substrate: null,
            hardware_door_lock: null,
            conj_fechadura: null,
            hardware_cylinder: null,
            hardware_garrison: null,
            hardware_door_handle: null,
            lock_puller: null,
            hardware_puller: null,
            lock_quantity: null,
            hardware_bar_anti_panic: null,
            hardware_aerial_spring: null,
            hardware_accessories: null,
            hardware_accessories_02: null,
            hardware_accessories_03: null,
            hardware_addons: null,
            hardware_quantity: null,
            prod_height: null,
            prod_height_marco: null,
            prod_width: null,
            wall_panel: [
              {
                panel_wall_module: null,
                panel_wall_finishing: null,
                panel_wall_pattern: null,
                panel_wall_instalation: null,
              }
            ],
          }]
        }
      ],
    },
    dataConfigs: {
      instalationData: [],
      regionsData: [],
      workStageData: [],
      previsionBuyData: [],
      providerData: [],
      finishingData: [],
      patternsData: [],
      occupation: [],
      client: [],
      representative: [],
      sheetDimension: [],
      proposalRequest: [],
      kits: [],
      users: [],
      discountsConfigs: 100,
      salesChannels: [],
      inputPrices: {},
      doorLocksMatriz: [],
    },
    codeProdData: {

    },
    codeComponentKit: {

    },
    codeProdDataRelation: {},
    alizarDataCK: [],
    errorInputMessage: {
      'performance_level': "Preencha o nível de desempenho",
      'instalation': "Preencha a instalação",
      'product': 'Preencha o produto',
      'env': 'Preencha o ambiente',
      'profile_performance': "Preencha o perfil de desempenho nos produtos da proposta",
      'typology': "Preencha a tipologia",
      'product_code': "Preencha o código do produto",
      'amount': "Preencha a quantidade do produto",
      'width_sheet': "Preencha a largura da folha",
      'height_sheet': "Preencha a altura da folha",
      'finishing_sheet': "Preencha o acabamento da folha",
      'pattern_sheet': "Preencha o padrão de cor da folha",
      'width_marco': "Preencha a largura do marco",
      'height_marco': "Preencha a altura do marco",
      'thickness_marco': "Preencha a espessura da parede",
      'finishing_marco': "Preencha o acabamento do marco",
      'pattern_marco': "Preencha o padrão de cor do marco",
      'finishing_aliz': "Preencha o acabamento do alizar",
      'pattern_aliz': "Preencha o padrão de cor do alizar",
      'fixed_aliz': "Preencha o alizar fixo",
      'adjustable_aliz': "Preencha o alizar regulável",
      'lock_quantity': "Preencha a quantidade de fechadura",
      'hardware_puller': "Preencha o puxador",
      'hardware_aerial_spring': "Preencha a mola aérea",
      'hardware_accessories': "Preencha os acessórios de ferragens",
      'hardware_accessories_02': "Preencha os acessórios de ferragens",
      'hardware_accessories_03': "Preencha os acessórios de ferragens",
      'hardware_quantity': "Preencha a quantidade de ferragens",
      'hardware_puller_amount': "Preencha a quantidade de ferragens",
      'hardware_bar_anti_panic_amount': "Preencha a quantidade de ferragens",
      'hardware_aerial_spring_amount': "Preencha a quantidade de ferragens",
      'hardware_accessories_amount': "Preencha a quantidade de ferragens",
      'hardware_accessories_amount_02': "Preencha a quantidade de ferragens",
      'hardware_accessories_amount_03': "Preencha a quantidade de ferragens",
      'wall_panel': "Preencha o painel parede",
      'panel_wall_module': "Preencha o módulo do painel parede",
      'panel_wall_finishing': "Preencha o acabamento do painel parede",
      'panel_wall_pattern': "Preencha o padrão do painel parede",
      'panel_wall_instalation': "Preencha a instalação do painel parede",
    },
    finishingDataRelation: {

    },
    finishingFromKit: {

    },
    loadingForm: true,

    // Dados do select do nome do bloco e da descrição do bloco
    addBlocoName: '',
    addBlocoDescription: '',

    // Kits with components to filter selects
    componentsKit: {},

    // Kits select to view
    selectedKits: {},

  }

  constructor(props) {
    super(props)
    this.saveProposal = this.saveProposal.bind(this)

    this.auth = new Auth(Parse.User.current())
    this.auth._discountPerItemPermission = false;
    this.auth._commercialDiscountPermission = false;
    this.auth.init(() => {
      this.auth._discountPerItemPermission = this.auth.hasAction(['*', 'ProposalDiscountPerItem']);
      this.auth._commercialDiscountPermission = this.auth.hasAction(['*', 'ProposalCommercialDiscount']);
      this.auth._deliveryValueRule = this.auth.hasAction(['*', 'ProposalDeliveryValueRule']);
      this.auth._groups = this.auth.ACL.groups;
      this.forceUpdate()
    })
  }

  componentWillMount() {

    this.loadKitsAndComponents()
      .then(res => {
        this.loadConfigData([
          'alturas_folha', 'larguras_folha', 'ocupation', 'desempenho_kit', 'instalation', 'local_work',
          'delivery', 'usage', 'desempenho', 'requisitos_gerais',
          'tipo_componente', 'kit_amb', 'typology', 'performance', 'perfil_proposal', 'panel_for_glass',
          'substrato_proposal', 'component_model', 'bloco_nome', 'bloco_descricao', 'modelos'
        ], pData => {
          this.setState(() => {
            let localDataConfig = pData
            localDataConfig['kits'] = res
            return { dataConfigs: localDataConfig }
          }, () => {
            this.loadInstalationData();
            this.loadInstalationSelectsData();
            this.loadProviderData();
            this.loadFinishingData();
            this.loadPatternData();
            this.loadPerformanceData();
            this.loadWidthAlizData();
            this.loadOccupation();
            this.loadSheetDimension();
            this.loadUsers();
            this.loadSalesChannels();
            this.loadInputPrices();
            this.loadDoorLocksMatriz();
            this.loadAlizMatriz();
            this.getAlizarComponent();

            if (this.props.routerProps.match.params.objectId) {
              this.loadProposalData(this.props.routerProps.match.params.objectId);
            } else {
              this.setState({ loadingForm: false });
            }
          })
        })
      })
  }

  async applyBlocks() {

    let itens = [];
    for (const block of this.state.data.product_blocks) for (const e of block.products) itens.push(e);

    let count = 0;
    for (const block of this.state.data.product_blocks) {

      for (const product of block.products) {

        const kit = this.state.dataConfigs.kits.find(el => el.id === product.product_code);
        await this.getAllComponentsFromKit(kit, count, itens.length === (count + 1));
        count++;

      }

    }

  }

  loadProposalData(objectId) {
    let Query = new Parse.Query('PriceList')
    Query.get(objectId).then(objectData => {

      this.setState({
        data: this.parseDataToEdit({ ...objectData.toJSON() }),
        objectEdit: objectData,
      }, () => {
        // TODO: load data
        this.applyBlocks()
      })

    })
  }

  parseDataToEdit(ProposalObject) {
    let hashtable = this.getObjectToConvert();
    for (let key in hashtable) {
      ProposalObject[key] = ProposalObject[key] ? ProposalObject[key].objectId : null;
    }
    if (ProposalObject.hasOwnProperty('objectId')) delete ProposalObject.objectId
    return ProposalObject;
  }

  render() {
    let objectEdit = this.state.objectEdit

    // Get all performance
    let arrayOfPerformance = [...[].concat(...Object.values(this.state.dataConfigs.performanceData || {}))];
    arrayOfPerformance = [...new Set(arrayOfPerformance)];

    // Get all door locks
    let arrayOfDoorLocks = (this.state.dataConfigs.doorLocksMatriz || []).map(matriz => matriz.get('component_kit_fechadura')).flat();
    arrayOfDoorLocks = arrayOfDoorLocks.map(el => el.get('description_component'));
    arrayOfDoorLocks = [...new Set(arrayOfDoorLocks)];

    // let generalProductIndex = 0
    return (
      <div>
        <Spin spinning={this.state.loadingForm} tip={this.state.loadingMsg || "Carregando as informações..."} size="large">
          <Form layout="inline">
            <Card
              title={
                <div style={{ float: "left", width: "100%" }}>
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={() => this.props.module.navigateToRouterComponent(`/`)}>
                      {this.props.module.props.form[`title-module`]}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{objectEdit ? `Editar` : `Cadastrar`}</Breadcrumb.Item>
                  </Breadcrumb>
                  <h2 className="no-mar-pad" style={{ float: "left" }}>
                    <div style={{ float: "left" }}>
                      {this.props.module.props.title}
                    </div> &nbsp;{this.getValue("proposal_code")}
                  </h2>
                  <div className="right-btns">
                    {(this.props.module.props.form.hasOwnProperty('BeforeSaveButton')) ? this.props.module.props.form['BeforeSaveButton'](this) : ''}
                    <Tooltip visible={this.state.invalidForm} placement="topLeft" title="Preencha todos os campos.">
                      <Button

                        type="primary"
                        id="btnSave"
                        className="btn-creator"
                        htmlType="button"
                        loading={this.state.loading}
                        onClick={() => {
                          this.setState({ loading: true })
                          this.saveProposal()
                        }}>
                        Salvar
                      </Button>
                      {(this.props.module.props.form.hasOwnProperty('AfterSaveButton')) ? this.props.module.props.form['AfterSaveButton'](this) : ''}
                    </Tooltip>
                  </div>
                </div>
              }
            >
              {
                this.state.dataConfigs.performanceData ?
                  <div>
                    <Row gutter={24}>
                      <Col span={4}>
                        <Form.Item label="Desempenho" >
                          <Select
                            // mode="multiple"
                            showSearch
                            value={this.getValue('performance')}
                            onChange={value => this.updateDataValue('performance', value)}
                            placeholder="Desempenho" size="large"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                              (arrayOfPerformance || []).map((el_performance) => {
                                return <Select.Option key={el_performance} value={el_performance}>{el_performance}</Select.Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item label="Altura" >
                          <Select
                            // mode="multiple"
                            showSearch
                            value={this.getValue('height')}
                            onChange={value => this.updateDataValue('height', value)}
                            placeholder="Altura" size="large"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(this.state.dataConfigs['alturas_folha'] || []).map(v => {
                              return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item label="Modelo" >
                          <Select
                            value={this.getValue('model')}
                            onChange={value => this.updateDataValue('model', value)}
                            placeholder="Modelo" size="large"

                            className="input-block-title"
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(this.state.dataConfigs['modelos'] || []).map(v => {
                              return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Tipologia" >
                          <Select
                            // mode="multiple"
                            showSearch
                            value={this.getValue('typology')}
                            onChange={value => this.updateDataValue('typology', value)}
                            placeholder="Tipologia" size="large"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                              (this.state.dataConfigs.typology || []).map((el_typology) => {
                                return <Select.Option key={el_typology} value={el_typology}>{el_typology}</Select.Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Acabamento" >
                          <Select
                            // mode="multiple"
                            showSearch
                            value={this.getValue('finishing')}
                            onChange={value => this.updateDataValue('finishing', value)}
                            placeholder="Acabamento" size="large"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                              (this.state.dataConfigs.finishingData || []).map((el_finishing) => {
                                return <Select.Option key={el_finishing.get('code')} value={`${el_finishing.get('code')} - ${el_finishing.get('description')}`}>{`${el_finishing.get('code')} - ${el_finishing.get('description')}`}</Select.Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>

                    </Row>
                    <Row gutter={24}>
                      <Col span={9}>
                        <Form.Item label="Fechadura" >
                          <Select
                            // mode="multiple"
                            showSearch
                            value={this.getValue('door_lock')}
                            onChange={value => this.updateDataValue('door_lock', value)}
                            placeholder="Fechadura" size="large"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            <Select.Option value='Sem fechadura'>Sem fechadura</Select.Option>
                            {
                              (arrayOfDoorLocks || []).sort().map((el_door_lock) => {
                                return <Select.Option key={el_door_lock} value={el_door_lock}>{el_door_lock}</Select.Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item label="Canal de vendas" >
                          <Select
                            value={this.getValue('sales_channel_inside_form')}
                            onChange={value => this.updateDataValue('sales_channel_inside_form', value)}
                            placeholder="Canal de vendas" size="large"
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(this.state.dataConfigs.salesChannels || []).filter(el => !el.get('disabled')).map(ParseSalesChannel => {
                              return <Select.Option key={ParseSalesChannel.id} value={ParseSalesChannel.id}>{ParseSalesChannel.get('name')}</Select.Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item label="Nível de desempenho" >
                          <Select value={this.getValue('performance_level')} onChange={value => this.updateDataValue('performance_level', value)} placeholder="Nível de desempenho" size="large">
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {this.renderConfigsOption(null, Object.keys(this.state.dataConfigs.performanceData))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item label="Instalação" >
                          <Select
                            value={this.getValue('instalation')}
                            onChange={value => {
                              this.updateDataValue('instalation', value);
                            }}
                            placeholder="Instalação" size="large"
                          >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(this.state.dataConfigs.instalationData || []).map(ParseInstalation => {
                              return <Select.Option key={ParseInstalation.id} value={ParseInstalation.id}>{ParseInstalation.get('name')}</Select.Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider dashed />
                    {
                      this.state.dataConfigs.inputPrices ?
                        <div>
                          {(this.state.data.product_blocks || []).map((block, blockIndex, blockList) => {
                            return (
                              <div key={blockIndex} className="block-product">
                                {/* <div className="block-product-title">
                            <Select
                              value={block.title || `Bloco ${blockIndex + 1}`}
                              onChange={(value) => {
                                if (value === 'inputOption') {
                                  let _this = this;
                                  Modal.confirm({
                                    title: 'Novo item',
                                    content: <Input onChange={e => _this.setState({ addBlocoName: e.target.value })} />,
                                    okText: 'Adicionar',
                                    okType: 'primary',
                                    cancelText: 'Cancelar',
                                    onOk() {
                                      let itens = _this.state.dataConfigs.bloco_nome
                                      let name = _this.state.addBlocoName
                                      itens.push(name || `Novo item`)
                                      _this.setState({
                                        dataConfigs: { ..._this.state.dataConfigs, bloco_nome: itens },
                                        addBlocoName: '',
                                      })
                                      _this.updateTitleProductBlock(name, blockIndex)
                                    }
                                  })
                                } else this.updateTitleProductBlock(value, blockIndex)
                              }}
                              placeholder="Titulo do bloco" size="large"
                             
                              className="input-block-title"
                            >
                              <Select.Option value={null}>Selecione...</Select.Option>
                              {(this.state.dataConfigs['bloco_nome'] || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                              })}
                              <Select.Option value='inputOption'>
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                  <Button style={{ flex: 'none', display: 'block', width: '100%' }} type='primary'>Novo item</Button>
                                </div>
                              </Select.Option>
                            </Select>

                            <Select
                              value={block.description}
                              onChange={(value) => {
                                if (value === 'inputOption') {
                                  let _this = this;
                                  Modal.confirm({
                                    title: 'Novo item',
                                    content: <Input onChange={e => _this.setState({ addBlocoDescription: e.target.value })} />,
                                    okText: 'Adicionar',
                                    okType: 'primary',
                                    cancelText: 'Cancelar',
                                    onOk() {
                                      let itens = _this.state.dataConfigs.bloco_descricao
                                      let name = _this.state.addBlocoDescription
                                      itens.push(name || `Novo item`)
                                      _this.setState({
                                        dataConfigs: { ..._this.state.dataConfigs, bloco_descricao: itens },
                                        addBlocoDescription: '',
                                      })
                                      _this.updateDescriptionProductBlock(name, blockIndex)
                                    }
                                  })
                                } else this.updateDescriptionProductBlock(value, blockIndex)
                              }}
                              placeholder="Descrição do bloco" size="large"
                             
                              className="input-block-title"
                            >
                              <Select.Option value={null}>Selecione...</Select.Option>
                              {(this.state.dataConfigs['bloco_descricao'] || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                              })}
                              <Select.Option value='inputOption'>
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                  <Button style={{ flex: 'none', display: 'block', width: '100%' }} type='primary'>Novo item</Button>
                                </div>
                              </Select.Option>
                            </Select>

                            <Button className="remove-block-button" disabled={blockList.length === 1} type="danger" icon="close" onClick={() => this.removeBlock(blockIndex)}>
                              Remover Bloco
                            </Button>
                          </div> */}
                                <Collapse
                                  // defaultActiveKey={['0']} 
                                  accordion
                                  onChange={(e) => {
                                    let localSelectedKits = this.state.selectedKits;
                                    localSelectedKits[blockIndex] = e;
                                    this.setState({ selectedKits: localSelectedKits });
                                  }}
                                >
                                  {
                                    block.products.map((v, productBlock, list) => {
                                      // if (productBlock === 0 && blockIndex === 0) generalProductIndex = 0
                                      // else generalProductIndex += 1

                                      let index = productBlock;
                                      const getProductValue = (attr) => {
                                        return this.getProductValue(attr, blockIndex, productBlock)
                                      }
                                      // const updateProductData = _.debounce((attr, index, value, callback) => {
                                      const updateProductData = (attr, index, value, callback) => {
                                        this.updateProductData(attr, blockIndex, index, value, callback)

                                        // Apagar padrão de cores caso exista 
                                        if (attr === 'finishing_sheet' && getProductValue('pattern_sheet')) this.updateProductData('pattern_sheet', blockIndex, index, null)
                                        if (attr === 'finishing_marco' && getProductValue('pattern_marco')) this.updateProductData('pattern_marco', blockIndex, index, null)

                                        // Apagar fechadura, maçaneta e conjunto de fechadura
                                        if (attr === 'option_fechadura' && (value.includes('conj_fechadura') || value.includes('kit_pcd'))) {
                                          if (getProductValue('hardware_door_lock')) this.updateProductData('hardware_door_lock', blockIndex, index, null)
                                          if (getProductValue('hardware_door_handle')) this.updateProductData('hardware_door_handle', blockIndex, index, null)
                                        }
                                        if (attr === 'option_fechadura' && (value.includes('usinagem') || value.includes('fechadura_'))) {
                                          if (getProductValue('conj_fechadura')) this.updateProductData('conj_fechadura', blockIndex, index, null)
                                        }

                                        // Tornar required qtd de fechadura e ferragens caso seja escolhida um modelo
                                        let arrayFerr = ['conj_fechadura', 'hardware_door_lock', 'hardware_puller', 'hardware_bar_anti_panic', 'hardware_aerial_spring', 'hardware_accessories', 'hardware_accessories_02', 'hardware_accessories_03', 'hardware_door_handle']
                                        let arrayFerrQtd = ['lock_quantity', 'lock_quantity', 'hardware_puller_amount', 'hardware_bar_anti_panic_amount', 'hardware_aerial_spring_amount', 'hardware_accessories_amount', 'hardware_accessories_amount_02', 'hardware_accessories_amount_03', 'lock_quantity']
                                        let requiredState = this.state.required
                                        // Caso o modelo de fechadura ou ferragem tenha sido escolhido, ou seja, o campo required deve ser adicionado
                                        if (arrayFerr.includes(attr) && value) {
                                          // Verificar se já existe required para item especifico da proposta
                                          // Caso já exista: adicionar o campo ao required do item especifco
                                          // Caso não exista: criar item especifico com o campo required
                                          if (requiredState.specific_products.find(x => x.block === blockIndex && x.prod === index)) {
                                            let req_i = requiredState.specific_products.findIndex(x => x.block === blockIndex && x.prod === index)
                                            if (!requiredState.specific_products[req_i].required.includes(arrayFerrQtd[arrayFerr.findIndex(x => x === attr)])) {
                                              requiredState.specific_products[req_i].required.push(arrayFerrQtd[arrayFerr.findIndex(x => x === attr)])
                                            }
                                          }
                                          else {
                                            requiredState.specific_products.push({ block: blockIndex, prod: index, required: [arrayFerrQtd[arrayFerr.findIndex(x => x === attr)]] })
                                          }
                                          // A linha abaixo é apenas para inserir o valor 1 a Qtd x Kit por padrão na ferragen escolhida 
                                          this.updateProductData(arrayFerrQtd[arrayFerr.findIndex(x => x === attr)], blockIndex, index, 1)
                                        }
                                        // Caso o modelo de fechadura ou ferragem não tenha sido escolhido, ou seja, o campo do form é null e o required deve ser removido
                                        else if ((arrayFerr.includes(attr) && !value)) {
                                          // Verificar se já existe required para item especifico da proposta
                                          // Caso já exista: remover o campo do required do item especifco
                                          if (requiredState.specific_products.find(x => x.block === blockIndex && x.prod === index)) {
                                            let req_i = requiredState.specific_products.findIndex(x => x.block === blockIndex && x.prod === index)
                                            if (requiredState.specific_products[req_i].required.includes(arrayFerrQtd[arrayFerr.findIndex(x => x === attr)])) {
                                              let req_field_i = requiredState.specific_products[req_i].required.findIndex(x => x === arrayFerrQtd[arrayFerr.findIndex(y => y === attr)])
                                              requiredState.specific_products[req_i].required.splice(req_field_i, 1)
                                            }
                                          }
                                          // A linha abaixo é apenas para remover o valor 1 da Qtd x Kit na ferragen que foi removida 
                                          this.updateProductData(arrayFerrQtd[arrayFerr.findIndex(x => x === attr)], blockIndex, index, null)
                                        }
                                        this.setState({ required: requiredState })

                                        // Apagar acabamento e padrão de cor dos alizares quando escolhida a opção de combinação
                                        if (attr === 'aliz_options') {
                                          if (!(value || '').includes('fixed')) {
                                            this.updateProductData('fixed_aliz', blockIndex, index, null,
                                              this.updateProductData('finishing_aliz', blockIndex, index, null, () => {
                                                this.updateProductData('pattern_aliz', blockIndex, index, null)
                                              })
                                            )
                                          }

                                          if (!(value || '').includes('adjustable')) {
                                            this.updateProductData('adjustable_aliz', blockIndex, index, null,
                                              this.updateProductData('finishing_aliz_adjustable', blockIndex, index, null, () => {
                                                this.updateProductData('pattern_aliz_adjustable', blockIndex, index, null)
                                              })
                                            )
                                          }
                                        }

                                        // Apagar acabamento e padrão de cor dos alizares quando escolhida a opção de combinação 01
                                        if (attr === 'aliz_options_01') {
                                          if (!(value || '').includes('fixed')) {
                                            this.updateProductData('fixed_aliz', blockIndex, index, null,
                                              this.updateProductData('finishing_aliz', blockIndex, index, null, () => {
                                                this.updateProductData('pattern_aliz', blockIndex, index, null)
                                              })
                                            )
                                          }

                                          if (!(value || '').includes('adjustable')) {
                                            this.updateProductData('adjustable_aliz', blockIndex, index, null,
                                              this.updateProductData('finishing_aliz_adjustable', blockIndex, index, null, () => {
                                                this.updateProductData('pattern_aliz_adjustable', blockIndex, index, null)
                                              })
                                            )
                                          }
                                        }

                                        // Apagar acabamento e padrão de cor dos alizares quando escolhida a opção de combinação 02
                                        if (attr === 'aliz_options_02') {
                                          if (!(value || '').includes('fixed')) {
                                            this.updateProductData('fixed_aliz', blockIndex, index, null,
                                              this.updateProductData('finishing_aliz', blockIndex, index, null, () => {
                                                this.updateProductData('pattern_aliz', blockIndex, index, null)
                                              })
                                            )
                                          }

                                          if (!(value || '').includes('adjustable')) {
                                            this.updateProductData('adjustable_aliz', blockIndex, index, null,
                                              this.updateProductData('finishing_aliz_adjustable', blockIndex, index, null, () => {
                                                this.updateProductData('pattern_aliz_adjustable', blockIndex, index, null)
                                              })
                                            )
                                          }
                                        }
                                      }

                                      // const kit = this.getCodeProdData(blockIndex, index).find(kit => kit.id === v.product_code)
                                      const kit = this.state.dataConfigs.kits.find(el => el.id === getProductValue('product_code'));

                                      let finishingFixed = _.get(this.state.codeProdDataRelation, [blockIndex, productBlock, 'comp_ali'], []).find(c => c.id === getProductValue('fixed_aliz'))
                                      if (finishingFixed && finishingFixed.get('finishing')) finishingFixed = finishingFixed.get('finishing').get('code') + ' - ' + finishingFixed.get('finishing').get('description')
                                      else finishingFixed = ""

                                      let finishingAdjustable = _.get(this.state.codeProdDataRelation, [blockIndex, productBlock, 'comp_ali'], []).find(c => c.id === getProductValue('adjustable_aliz'))
                                      if (finishingAdjustable && finishingAdjustable.get('finishing')) finishingAdjustable = finishingAdjustable.get('finishing').get('code') + ' - ' + finishingAdjustable.get('finishing').get('description')
                                      else finishingAdjustable = ""

                                      let conjFechaComp = _.get(this.state.codeProdDataRelation, [blockIndex, productBlock, 'conj_fecha'], []).find(c => c.id === getProductValue('conj_fechadura'))
                                      if (conjFechaComp && conjFechaComp.get('finishing')) {
                                        conjFechaComp = conjFechaComp.get('finishing').get('code') + ' - ' + conjFechaComp.get('finishing').get('description')
                                      } else {
                                        conjFechaComp = ""
                                      }

                                      // get thickness marco
                                      let allThicknessMarco = ((this.state.componentsKit[getProductValue('product_code')] && this.state.componentsKit[getProductValue('product_code')]['comp_marco']) || []);
                                      let thicknessMarco = allThicknessMarco.find(el => el.id === getProductValue('thickness_marco'));
                                      return (

                                        <Collapse.Panel
                                          key={index}
                                          header={
                                            <div style={{ height: '20px', lineHeight: '20px' }}>
                                              <div style={{ float: 'left', width: '200px' }}>
                                                <b>Preço: </b>{(this.getTotalPrice(blockIndex, productBlock, getProductValue('unit_discount'), kit) * (getProductValue('amount') || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                              </div>
                                              <div style={{ float: 'left' }}>
                                                <b>Descrição: </b>{kit && kit.get('name')}
                                              </div>
                                              <div style={{ float: 'left', marginLeft: '20px' }}>
                                                <b>Dim. folha: </b>
                                                {getProductValue('prod_width')}
                                                {getProductValue('prod_width') && getProductValue('prod_height') ? 'x' : ''}
                                                {getProductValue('prod_height')}
                                              </div>
                                              <div style={{ float: 'left', marginLeft: '20px' }}>
                                                <b>Espes. parede: </b>{thicknessMarco && thicknessMarco.get('fill_componente')}
                                              </div>
                                              <div style={{ float: 'left', marginLeft: '20px' }}>
                                                <b>Seção Aliz.: </b>{(getProductValue('aliz_fixed_section') || getProductValue('aliz_adjustable_section') || '  ').slice(1, -1)}
                                              </div>
                                              {/* {kit && <span><Tag color="gray" style={{ display: 'inline', marginLeft: 16 }}>{kit && kit.get('name')}</Tag></span>} */}
                                              <Dropdown trigger={['click']} overlay={
                                                <Menu>
                                                  <Menu.Item key="1"
                                                    onClick={(event) => {
                                                      this.copyProductTo(v, blockIndex)
                                                      event.domEvent.stopPropagation()
                                                    }}>
                                                    <Icon type="copy" />Copiar
                                                  </Menu.Item>
                                                  <Menu.Item key="2"
                                                    disabled={list.length === 1}
                                                    onClick={event => {
                                                      event.domEvent.stopPropagation()
                                                      this.removeProduct(blockIndex, index);
                                                    }}>
                                                    <Icon type="close" />Remover
                                                  </Menu.Item>
                                                </Menu>
                                              }>
                                                <Button onClick={(event) => event.stopPropagation()} icon="ellipsis" style={{ marginLeft: 8, float: 'right', margin: '-5px 16px' }} shape="circle" />
                                              </Dropdown>
                                            </div>
                                          }
                                        >
                                          {
                                            this.state.selectedKits[blockIndex.toString()] !== index.toString() ?
                                              <div style={{ marginTop: 10, marginBottom: 10, margin: 'auto' }}>
                                                <Spin spinning={true} tip={`Carregando as informações deste item...`} style={{ marginTop: 10 }} />
                                              </div> :
                                              <Fragment>
                                                <Row gutter={24}>
                                                  <Col span={6}>
                                                    <Form.Item label="Altura Marco">
                                                      <Select value={getProductValue('prod_height_marco')} onChange={value => updateProductData('prod_height_marco', index, value)} placeholder="Altura Marco" size="large" >
                                                        {this.renderConfigsOption('alturas_folha')}
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={6}>
                                                    <Form.Item label="Altura da Folha">
                                                      <Select value={getProductValue('prod_height')} onChange={value => updateProductData('prod_height', index, value)} placeholder="Altura da Folha" size="large" >
                                                        {this.renderConfigsOption('alturas_folha')}
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={6}>
                                                    <Form.Item label="Largura">
                                                      <Select value={getProductValue('prod_width')} onChange={value => updateProductData('prod_width', index, value)} placeholder="Largura" size="large" >
                                                        {this.renderConfigsOption('larguras_folha')}
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={6}>
                                                    <Form.Item label="Desconto (%)">
                                                      <Select
                                                        disabled={!this.auth._discountPerItemPermission}
                                                        placeholder="Desconto (%)"
                                                        size="large"
                                                        value={getProductValue('unit_discount')}
                                                        onChange={value => updateProductData('unit_discount', index, value)}
                                                      >
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        <Select.Option value={0.05}>5%</Select.Option>
                                                        <Select.Option value={0.10}>10%</Select.Option>
                                                        <Select.Option value={0.15}>15%</Select.Option>
                                                        <Select.Option value={0.20}>20%</Select.Option>
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>

                                                <Row gutter={24}>

                                                  <Col span={5}>
                                                    <Form.Item label="Ambiente" >
                                                      <Select
                                                        value={getProductValue('env')}
                                                        onChange={value => updateProductData('env', index, value)}
                                                        placeholder="Ambiente" size="large"
                                                      >
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        {this.renderConfigsOption('kit_amb')}
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={5}>
                                                    <Form.Item label="Perfil de Desempenho" >
                                                      <Select
                                                        value={getProductValue('profile_performance')} onChange={value => updateProductData('profile_performance', index, value)} placeholder="Perfil de Desempenho" size="large">
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        {/*this.renderConfigsOption('performance')*/}
                                                        {this.renderConfigsOption(
                                                          null,
                                                          (this.state.dataConfigs.performanceData[this.state.data.performance_level] || []).filter(v => {
                                                            // Filtrar perfil de desempenho de acordo com o ambiente
                                                            return (getProductValue('env') &&
                                                              ((getProductValue('env').includes('Molh') && v.includes("RU")) ||
                                                                (getProductValue('env').includes('Exterior') && v.includes("PXM")) ||
                                                                (getProductValue('env').includes('Seco')))
                                                            )
                                                          })
                                                        )}
                                                      </Select>

                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={9}>
                                                    <Form.Item label="Tipologia" >
                                                      <Select value={getProductValue('typology')} onChange={value => updateProductData('typology', index, value)} placeholder="Tipologia" size="large">
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        {
                                                          getProductValue('profile_performance') && getProductValue('prod_width') && getProductValue('prod_height') ?
                                                            this.renderTypology(this.state.dataConfigs.typology, getProductValue('profile_performance'), getProductValue('prod_width'), getProductValue('prod_height'), (getProductValue('prod_height_marco') || getProductValue('prod_height')))
                                                            : null
                                                        }
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={5}>
                                                    <Form.Item label="Modelo" >
                                                      <Select
                                                        value={getProductValue('model')}
                                                        onChange={value => updateProductData('model', index, value)}
                                                        placeholder="Modelo" size="large"

                                                        className="input-block-title"
                                                      >
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        {(this.state.dataConfigs['modelos'] || []).map(v => {
                                                          return <Select.Option value={v} key={v}>{v}</Select.Option>
                                                        })}
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>
                                                </Row>

                                                <Row gutter={24}>

                                                  <Col span={24}>
                                                    <Form.Item label="Selecionar Kit" >
                                                      <Select value={getProductValue('product_code')} onChange={value => {
                                                        if (getProductValue('product_code')) {
                                                          // Modal indicando que os dados serão removidos
                                                          let _this = this
                                                          Modal.confirm({
                                                            title: 'Tem certeza que deseja alterar o kit?',
                                                            content: 'Esta alteração apagará os dados do produto selecionado.',
                                                            onOk() {
                                                              if (value) {
                                                                const kit = _this.state.dataConfigs.kits.find(el => el.id === value);
                                                                _this.getAllComponentsFromKit(kit);
                                                              }

                                                              _this.updateProductData(
                                                                'product_code',
                                                                blockIndex,
                                                                index,
                                                                value,
                                                              )

                                                              // Limpar os dados selecionados anteriormente
                                                              let localForm = _this.state.data
                                                              let dontChangeAttr = ['amount', 'env', 'env_obra', 'product_code', 'prod_height', 'prod_height_marco', 'prod_width', 'profile_performance', 'project_code', 'typology', 'model']
                                                              Object.keys(_this.state.data.product_blocks[blockIndex].products[index]).forEach(key => {
                                                                if (!dontChangeAttr.includes(key)) {
                                                                  if (key === 'wall_panel')
                                                                    localForm.product_blocks[blockIndex].products[index][key] = [
                                                                      {
                                                                        "panel_wall_module": null,
                                                                        "panel_wall_finishing": null,
                                                                        "panel_wall_pattern": null,
                                                                        "panel_wall_instalation": null
                                                                      }
                                                                    ]
                                                                  else
                                                                    localForm.product_blocks[blockIndex].products[index][key] = null
                                                                }
                                                              })
                                                              _this.setState({ data: localForm })

                                                            },
                                                          })
                                                        } else {
                                                          const kit = this.state.dataConfigs.kits.find(el => el.id === value);
                                                          this.getAllComponentsFromKit(kit);

                                                          this.updateProductData(
                                                            'product_code',
                                                            blockIndex,
                                                            index,
                                                            value,
                                                          )
                                                        }

                                                      }} placeholder="Código do kit" size="large">
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        {
                                                          this.state.dataConfigs.kits
                                                            .filter(el => {
                                                              return (
                                                                el.get('typology').includes(getProductValue('typology')) &&
                                                                el.get('kit_environment').includes(getProductValue('env')) &&
                                                                el.get('width').includes(getProductValue('prod_width').toString()) &&
                                                                el.get('height') === getProductValue('prod_height').toString() &&
                                                                (el.get('height_marco') || el.get('height')) === ((getProductValue('prod_height_marco') && getProductValue('prod_height_marco').toString()) || getProductValue('prod_height').toString()) &&
                                                                el.get('performance') === getProductValue('profile_performance') &&
                                                                el.get('name').toLowerCase().includes((getProductValue('model') || '').toLowerCase()) &&
                                                                !el.get('unavailable')
                                                              )
                                                            })
                                                            .map(el => {
                                                              return (
                                                                <Select.Option value={el.id}>{el.get('code')} - {el.get('name')}</Select.Option>
                                                              );
                                                            })
                                                        }
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                  {/* <Col span={4}>
                                              <Form.Item label="Quantidade" >
                                                <InputNumber
                                                  value={getProductValue('amount')}
                                                  onChange={value => updateProductData('amount', index, value)}
                                                  placeholder="Quantidade"
                                                  size="large"
                                                  type='number'
                                                 
                                                />
                                              </Form.Item>
                                            </Col> */}
                                                </Row>

                                                <Row gutter={24}>

                                                  <Col span={4}>
                                                    <Form.Item label="Inserir apenas componente?">
                                                      <Switch checked={getProductValue('search_componentKit')} onChange={value => updateProductData('search_componentKit', index, value, () => {
                                                        this.clearProduct(blockIndex, index)
                                                      })} />
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={4}>
                                                    <Form.Item label="Tipo de Componente">
                                                      <Select disabled={!getProductValue('search_componentKit')} value={getProductValue('product')}
                                                        onChange={value => {
                                                          this.clearProduct(blockIndex, index);
                                                          updateProductData('product', index, value);
                                                        }}
                                                        placeholder="Produto" size="large">
                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                        {this.renderConfigsOption('tipo_componente')}
                                                      </Select>
                                                    </Form.Item>
                                                  </Col>

                                                </Row>

                                                <Divider dashed={true} />

                                                <div>

                                                  {!getProductValue('search_componentKit') || getProductValue('product') === 'Folha' ?
                                                    <Fragment>
                                                      <h2>Cadastro da folha da Porta</h2>

                                                      <Row gutter={12} className="size chunk">
                                                        <h3>Porta básica</h3>
                                                        <Col span={9}>
                                                          <Form.Item label="Acabamento" >
                                                            <Select value={getProductValue('finishing_sheet')} onChange={value => updateProductData('finishing_sheet', index, value)} placeholder="Acabamento" size="large">
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                _.uniqBy(
                                                                  (
                                                                    (this.state.componentsKit[getProductValue('product_code')] && this.state.componentsKit[getProductValue('product_code')]['comp_folha']
                                                                    ) || [])
                                                                    .filter(el => {
                                                                      // Filter sheet by height and width
                                                                      return (
                                                                        el.get('height_component').toString() === getProductValue('prod_height') &&
                                                                        el.get('width_component').toString() === getProductValue('prod_width')
                                                                      )
                                                                    })
                                                                    .map(el => el.toJSON())
                                                                  , 'finishing'
                                                                )
                                                                  .filter(el => el.finishing)
                                                                  .sort((a, b) => (a.finishing.code > b.finishing.code) ? 1 : ((b.finishing.code > a.finishing.code) ? -1 : 0))
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.finishing.code} - {el.finishing.description}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>


                                                        <Col span={9}>
                                                          <Form.Item label="Padrão de cor" >
                                                            <Select value={getProductValue('pattern_sheet')} onChange={value => updateProductData('pattern_sheet', index, value)} placeholder="Padrão de cor" size="large">
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                (
                                                                  (getProductValue('finishing_sheet') && this.state.componentsKit[getProductValue('product_code')]) ?
                                                                    this.state.dataConfigs.patternsData.find(el => el.finishingId ===
                                                                      this.state.componentsKit[getProductValue('product_code')]['comp_folha']
                                                                        .find(el => el.id === getProductValue('finishing_sheet'))
                                                                        .get('finishing').id
                                                                    )
                                                                    : { patterns: [] }
                                                                )['patterns']
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.id}>{el.get('pattern')}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                        <Col span={6}>
                                                          <Form.Item label="Folha inativa (kits assimétricos)">
                                                            <Select value={getProductValue('finishing_sheet_inactive')} onChange={value => updateProductData('finishing_sheet_inactive', index, value)} placeholder="Folha inativa" size="large">
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                (
                                                                  (this.state.componentsKit[getProductValue('product_code')] && this.state.componentsKit[getProductValue('product_code')]['comp_folha_inativa']
                                                                  ) || [])
                                                                  .filter(el => {
                                                                    // Filter sheet by height
                                                                    return (
                                                                      el.get('height_component').toString() === getProductValue('prod_height')
                                                                    );
                                                                  })
                                                                  .map(el => el.toJSON())
                                                                  .filter(el => {
                                                                    let sheetChoosed = this.state.componentsKit[getProductValue('product_code')]['comp_folha'].find(el => el.id === getProductValue('finishing_sheet'));
                                                                    return el.finishing && el.finishing.objectId === (sheetChoosed && sheetChoosed.get('finishing').id);
                                                                  })
                                                                  .sort((a, b) => (a.width_component > b.width_component) ? 1 : ((b.width_component > a.width_component) ? -1 : 0))
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.width_component}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>

                                                      <Divider dashed={true} />
                                                    </Fragment> : null
                                                  }

                                                  {!getProductValue('search_componentKit') || getProductValue('product') === 'Marco' ?
                                                    <Fragment>
                                                      <h2>Cadastro de Marco</h2>

                                                      <Row gutter={12} className="chunk">
                                                        <h3>Marco Básico</h3>

                                                        <Col span={8}>

                                                          <Form.Item label="Espessura Parede" >
                                                            <Select
                                                              value={getProductValue('thickness_marco')}
                                                              onChange={value =>
                                                                updateProductData(
                                                                  'thickness_marco',
                                                                  index,
                                                                  value,
                                                                  this.updateProductData(
                                                                    'finishing_marco', blockIndex, index, null,
                                                                    this.updateProductData('pattern_marco', blockIndex, index, null)
                                                                  )
                                                                )}
                                                              placeholder="Espessura Parede" size="large"

                                                            >

                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                _.uniqBy(
                                                                  (
                                                                    (this.state.componentsKit[getProductValue('product_code')] && this.state.componentsKit[getProductValue('product_code')]['comp_marco']
                                                                    ) || [])
                                                                    .map(el => el.toJSON())
                                                                  , 'fill_componente'
                                                                )
                                                                  .sort((a, b) => (a.fill_componente > b.fill_componente) ? 1 : ((b.fill_componente > a.fill_componente) ? -1 : 0))
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.fill_componente}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                          <Form.Item label="Acabamento" >
                                                            <Select value={getProductValue('finishing_marco')} onChange={value => updateProductData('finishing_marco', index, value)} placeholder="Acabamento" size="large">
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                _.uniqBy(
                                                                  (
                                                                    (this.state.componentsKit[getProductValue('product_code')] && this.state.componentsKit[getProductValue('product_code')]['comp_marco']
                                                                    ) || [])
                                                                    .filter(el => {
                                                                      // Filter marco by height and thickness
                                                                      let thickness = this.state.componentsKit[getProductValue('product_code')]['comp_marco'].find(marco => marco.id === getProductValue('thickness_marco'));
                                                                      thickness = thickness && thickness.get('fill_componente');
                                                                      return (
                                                                        el.get('height_component').toString() === getProductValue('prod_height_marco') &&
                                                                        el.get('fill_componente') === thickness
                                                                      )
                                                                    })
                                                                    .map(el => el.toJSON())
                                                                    .filter(el => el.finishing)
                                                                  , 'finishing'
                                                                )
                                                                  .sort((a, b) => (a.finishing.code > b.finishing.code) ? 1 : ((b.finishing.code > a.finishing.code) ? -1 : 0))
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.finishing.code} - {el.finishing.description}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                          <Form.Item label="Padrão de cor" >
                                                            <Select value={getProductValue('pattern_marco')} onChange={value => updateProductData('pattern_marco', index, value)} placeholder="Padrão de cor" size="large">
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                (
                                                                  (getProductValue('finishing_marco') && this.state.componentsKit[getProductValue('product_code')]) ?
                                                                    this.state.dataConfigs.patternsData.find(el => el.finishingId ===
                                                                      this.state.componentsKit[getProductValue('product_code')]['comp_marco']
                                                                        .find(el => el.id === getProductValue('finishing_marco'))
                                                                        .get('finishing').id
                                                                    )
                                                                    : { patterns: [] }
                                                                )['patterns']
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.id}>{el.get('pattern')}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>

                                                      <Divider dashed={true} />
                                                    </Fragment> : null
                                                  }

                                                  {!getProductValue('search_componentKit') || getProductValue('product') === 'Alizar' ?
                                                    <Fragment>
                                                      <Row gutter={12} className="chunk">
                                                        <h2>Cadastro de Alizares</h2>
                                                      </Row>

                                                      <Row gutter={12}>
                                                        <Col span={8}>
                                                          <Form.Item label="Alizar Fixo + Regulável">
                                                            <Select
                                                              value={getProductValue('aliz_options')}
                                                              onChange={value => updateProductData('aliz_options', index, value)}
                                                              placeholder="Opções de combinações possíveis" size="large"
                                                              disabled={(getProductValue('aliz_options_01') || getProductValue('aliz_options_02') ? true : false)}
                                                            >
                                                              <Select.Option value=''>Selecione...</Select.Option>
                                                              <Select.Option value='fixed_adjustable'>Fixo + Regulavel</Select.Option>
                                                              <Select.Option value='fixed'>Só Fixo</Select.Option>
                                                              <Select.Option value='adjustable'>Só Regulável</Select.Option>
                                                              <Select.Option value='without'>Sem alizar</Select.Option>
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                        <Col span={8}>
                                                          <Form.Item label="Alizar c/Bandeira Falsa">
                                                            <Select
                                                              value={getProductValue('aliz_options_01')}
                                                              onChange={value => updateProductData('aliz_options_01', index, value)}
                                                              placeholder="Opções de combinações possíveis" size="large"
                                                              disabled={(getProductValue('aliz_options') || getProductValue('aliz_options_02') ? true : false)}
                                                            >
                                                              <Select.Option value=''>Selecione...</Select.Option>
                                                              <Select.Option value='fixed_band'>Fixo + Bandeira Falsa</Select.Option>
                                                              <Select.Option value='adjustable_band'>Regulável + Bandeira Falsa</Select.Option>
                                                              <Select.Option value='fixed-adjustable-band'>Fixo + Regulável + Bandeira Falsa</Select.Option>
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                        <Col span={8}>
                                                          <Form.Item label="Alizar c/ Painel Parede">
                                                            <Select
                                                              value={getProductValue('aliz_options_02')}
                                                              onChange={value => updateProductData('aliz_options_02', index, value)}
                                                              placeholder="Opções de combinações possíveis" size="large"
                                                              disabled={(getProductValue('aliz_options') || getProductValue('aliz_options_01') ? true : false)}
                                                            >
                                                              <Select.Option value=''>Selecione...</Select.Option>
                                                              <Select.Option value='adjustable_panel'>Regulável + Painel Parede 1 face</Select.Option>
                                                              <Select.Option value='without_panel'>Painel Parede 2 faces</Select.Option>
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>

                                                      <Row gutter={24}>
                                                        <Col span={3}>
                                                          <Form.Item label="Seção">
                                                            <Select
                                                              value={getProductValue('aliz_fixed_section')}
                                                              onChange={value => updateProductData('aliz_fixed_section', index, value)}
                                                              placeholder="Seção" size="large"
                                                              disabled={
                                                                (
                                                                  !(getProductValue('aliz_options') && getProductValue('aliz_options').includes('fixed')) &&
                                                                  !getProductValue('aliz_options_01') &&
                                                                  !(getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('fixed'))
                                                                )}
                                                            >
                                                              <Select.Option value=''>Selecione...</Select.Option>
                                                              {
                                                                [...new Set(
                                                                  (this.state.dataConfigs.alizMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performance').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology')) &&
                                                                        matriz.get('sheet_width').includes(getProductValue('prod_width')) &&
                                                                        matriz.get('sheet_height') === getProductValue('prod_height')
                                                                    )
                                                                    .map(matriz => {
                                                                      return matriz.get('section');
                                                                    })
                                                                    .flat()
                                                                    .sort()
                                                                )]
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={`-${el}x`}>{el}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Col span={16}>
                                                          <Form.Item label={`Alizar Fixo${(getProductValue('aliz_options_01') && getProductValue('aliz_options_01').includes('fixed')) ? ' c/ Bandeira falsa' : ''}`}>

                                                            <Select value={getProductValue('fixed_aliz')}
                                                              disabled={
                                                                (
                                                                  !(getProductValue('aliz_options') && getProductValue('aliz_options').includes('fixed')) &&
                                                                  !getProductValue('aliz_options_01') &&
                                                                  !(getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('fixed'))
                                                                )}
                                                              onChange={
                                                                value => updateProductData(
                                                                  'fixed_aliz',
                                                                  index,
                                                                  value,
                                                                  this.updateProductData('finishing_aliz', blockIndex, index, value, () => {
                                                                    this.updateProductData('pattern_aliz', blockIndex, index, null)
                                                                  })
                                                                )}
                                                              placeholder="Alizar Fixo" size="large">
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                _.uniqBy((this.state.dataConfigs.alizMatriz || [])
                                                                  .filter(
                                                                    matriz => matriz.get('performance').includes(getProductValue('profile_performance')) &&
                                                                      matriz.get('typology').includes(getProductValue('typology')) &&
                                                                      matriz.get('sheet_width').includes(getProductValue('prod_width')) &&
                                                                      matriz.get('sheet_height') === getProductValue('prod_height')
                                                                  )
                                                                  .map(matriz => matriz.get('component_kit'))
                                                                  .flat()
                                                                  .map(el => el.toJSON())
                                                                  .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                  .filter(el =>
                                                                    (getProductValue('aliz_fixed_section') || '').slice(1, -1) === el.fill_componente.toString() &&
                                                                    el.description_component.toLowerCase().includes('fix') &&
                                                                    (getProductValue('aliz_options_01') && getProductValue('aliz_options_01').includes('fix') ?
                                                                      el.description_component.toLowerCase().includes('bandeira') :
                                                                      !el.description_component.toLowerCase().includes('bandeira')
                                                                    )
                                                                  )
                                                                  , 'objectId')
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Col span={5}>
                                                          <Form.Item label="Padrão de cor" >
                                                            <Select value={getProductValue('pattern_aliz')} onChange={value => updateProductData('pattern_aliz', index, value)} placeholder="Padrão de cor" size="large"
                                                              disabled={
                                                                (
                                                                  !(getProductValue('aliz_options') && getProductValue('aliz_options').includes('fixed')) &&
                                                                  !getProductValue('aliz_options_01') &&
                                                                  !(getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('fixed'))
                                                                )
                                                              }
                                                            >
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                (
                                                                  (getProductValue('fixed_aliz') && this.state.componentsKit[getProductValue('product_code')]) ?
                                                                    this.state.dataConfigs.patternsData.find(el => el.finishingId ===
                                                                      (((this.state.alizarDataCK.find(el => el.id === getProductValue('fixed_aliz')))
                                                                        && (this.state.alizarDataCK.find(el => el.id === getProductValue('fixed_aliz'))).get('finishing')) || { 'id': '' })['id'])
                                                                    : { patterns: [] }
                                                                )['patterns']
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.id}>{el.get('pattern')}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                      </Row>
                                                      <Row gutter={24}>
                                                        <Col span={3}>
                                                          <Form.Item label="Seção">
                                                            <Select
                                                              value={getProductValue('aliz_adjustable_section')}
                                                              onChange={value => updateProductData('aliz_adjustable_section', index, value)}
                                                              placeholder="Seção" size="large"
                                                              disabled={
                                                                (
                                                                  !(getProductValue('aliz_options') && getProductValue('aliz_options').includes('adjustable')) &&
                                                                  !getProductValue('aliz_options_01') &&
                                                                  !(getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('adjustable'))
                                                                )}
                                                            >
                                                              <Select.Option value=''>Selecione...</Select.Option>
                                                              {
                                                                [...new Set(
                                                                  (this.state.dataConfigs.alizMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performance').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology')) &&
                                                                        matriz.get('sheet_width').includes(getProductValue('prod_width')) &&
                                                                        matriz.get('sheet_height') === getProductValue('prod_height')
                                                                    )
                                                                    .map(matriz => {
                                                                      return matriz.get('section');
                                                                    })
                                                                    .flat()
                                                                    .sort()
                                                                )]
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={`-${el}x`}>{el}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                        <Col span={16}>
                                                          <Form.Item label={`Alizar Regulável${(getProductValue('aliz_options_01') && getProductValue('aliz_options_01').includes('adjustable')) ? ' c/ Bandeira falsa' : ''}`}>

                                                            <Select
                                                              value={getProductValue('adjustable_aliz')}
                                                              disabled={
                                                                (
                                                                  !(getProductValue('aliz_options') && getProductValue('aliz_options').includes('adjustable')) &&
                                                                  !getProductValue('aliz_options_01') &&
                                                                  !(getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('adjustable'))
                                                                )}
                                                              onChange={
                                                                value => updateProductData(
                                                                  'adjustable_aliz',
                                                                  index,
                                                                  value,
                                                                  this.updateProductData('finishing_aliz_adjustable', blockIndex, index, value, () => {
                                                                    this.updateProductData('pattern_aliz_adjustable', blockIndex, index, null)
                                                                  })
                                                                )
                                                              }
                                                              placeholder="Alizar Regulável" size="large"
                                                            >
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                _.uniqBy((this.state.dataConfigs.alizMatriz || [])
                                                                  .filter(
                                                                    matriz => matriz.get('performance').includes(getProductValue('profile_performance')) &&
                                                                      matriz.get('typology').includes(getProductValue('typology')) &&
                                                                      matriz.get('sheet_width').includes(getProductValue('prod_width')) &&
                                                                      matriz.get('sheet_height') === getProductValue('prod_height')
                                                                  )
                                                                  .map(matriz => matriz.get('component_kit'))
                                                                  .flat()
                                                                  .map(el => el.toJSON())
                                                                  .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                  .filter(el => (getProductValue('aliz_adjustable_section') || '').slice(1, -1) === el.fill_componente.toString() &&
                                                                    el.description_component.toLowerCase().includes('regul') &&
                                                                    (getProductValue('aliz_options_01') && getProductValue('aliz_options_01').includes('adj') ?
                                                                      el.description_component.toLowerCase().includes('bandeira') :
                                                                      !el.description_component.toLowerCase().includes('bandeira')
                                                                    )
                                                                  )
                                                                  , 'objectId')
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                        <Col span={5}>
                                                          <Form.Item label="Padrão de cor" >
                                                            <Select value={getProductValue('pattern_aliz_adjustable')} onChange={value => updateProductData('pattern_aliz_adjustable', index, value)} placeholder="Padrão de cor" size="large"
                                                              disabled={
                                                                (
                                                                  !(getProductValue('aliz_options') && getProductValue('aliz_options').includes('adjustable')) &&
                                                                  !getProductValue('aliz_options_01') &&
                                                                  !(getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('adjustable'))
                                                                )
                                                              }
                                                            >
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                (
                                                                  (getProductValue('adjustable_aliz') && this.state.componentsKit[getProductValue('product_code')]) ?
                                                                    this.state.dataConfigs.patternsData.find(el => el.finishingId ===
                                                                      (((this.state.alizarDataCK.find(el => el.id === getProductValue('adjustable_aliz'))) &&
                                                                        (this.state.alizarDataCK.find(el => el.id === getProductValue('adjustable_aliz'))).get('finishing')) || { 'id': '' })['id'])
                                                                    : { patterns: [] }
                                                                )['patterns']
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.id}>{el.get('pattern')}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                      </Row>

                                                      <Divider dashed={true} />
                                                    </Fragment> : null
                                                  }

                                                  {
                                                    getProductValue('product') === 'Painel' ||
                                                      (!getProductValue('search_componentKit') &&
                                                        ((getProductValue('aliz_options') && getProductValue('aliz_options').includes('panel')) ||
                                                          (getProductValue('aliz_options_01') && getProductValue('aliz_options_01').includes('panel')) ||
                                                          (getProductValue('aliz_options_02') && getProductValue('aliz_options_02').includes('panel'))))
                                                      ?
                                                      <Fragment>
                                                        <Row gutter={24}>
                                                          <h2>
                                                            Cadastro do Painel Parede
                                                            <Button icon="plus" shape="circle" size="large" style={{ marginLeft: 16 }} onClick={() => this.addNewWallPanel(blockIndex, index)} />
                                                          </h2>

                                                          {v.wall_panel.map((wallPanel, wallPanelIndex, wallPanelList) => {

                                                            const getWallPanelValue = (attr, index, wallPanelIndex) => {
                                                              return this.getWallPanelValue(attr, blockIndex, index, wallPanelIndex)
                                                            }

                                                            const updateWallValue = (attr, index, value) => {
                                                              return this.updateWallValue(attr, blockIndex, index, wallPanelIndex, value)
                                                            }

                                                            return (
                                                              <Fragment>
                                                                <Row gutter={24} key={wallPanelIndex}>
                                                                  <Col span={12}>
                                                                    <Form.Item label="Módulo">
                                                                      <Select
                                                                        value={getWallPanelValue('panel_wall_module', index, wallPanelIndex)}
                                                                        onChange={value => {
                                                                          updateWallValue('panel_wall_module', index, value)
                                                                          // Atualizar o acabamento do painel com base no valor escolhido no módulo
                                                                          this.getAllObjectsOfRelation(blockIndex, index, 'comp_marco_add').forEach(snap => {
                                                                            if (snap.id === value) {
                                                                              updateWallValue('panel_wall_finishing', index, snap.get('finishing').id)
                                                                            }
                                                                          })
                                                                          // Alterar qtd caso seja escolhido um módulo
                                                                          if (value) updateWallValue('wall_quantity', index, 1)
                                                                          else {
                                                                            updateWallValue('panel_wall_finishing', index, null)
                                                                            updateWallValue('panel_wall_pattern', index, null)
                                                                            updateWallValue('fixing_substrate', index, null)
                                                                            updateWallValue('wall_quantity', index, null)
                                                                          }
                                                                        }}
                                                                        placeholder="Módulo" size="large"
                                                                      >
                                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                                        {
                                                                          (
                                                                            (this.state.componentsKit[getProductValue('product_code')] && this.state.componentsKit[getProductValue('product_code')]['comp_marco_add']
                                                                            ) || [])
                                                                            .map(el => el.toJSON())
                                                                            .filter(el => el.description_component)
                                                                            .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                            .map(el => {
                                                                              return (
                                                                                <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                              );
                                                                            })
                                                                        }
                                                                      </Select>
                                                                    </Form.Item>
                                                                  </Col>
                                                                  <Col span={4}>
                                                                    <Form.Item label="Padrão de cor">
                                                                      <Select value={getWallPanelValue('panel_wall_pattern', index, wallPanelIndex)} onChange={value => updateWallValue('panel_wall_pattern', index, value)} placeholder="Padrão de cor" size="large">
                                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                                        {
                                                                          (
                                                                            (getWallPanelValue('panel_wall_module', index, wallPanelIndex) && this.state.componentsKit[getProductValue('product_code')]) ?
                                                                              this.state.dataConfigs.patternsData.find(el => el.finishingId ===
                                                                                this.state.componentsKit[getProductValue('product_code')]['comp_marco_add']
                                                                                  .find(el => el.id === getWallPanelValue('panel_wall_module', index, wallPanelIndex))
                                                                                  .get('finishing').id
                                                                              )
                                                                              : { patterns: [] }
                                                                          )['patterns']
                                                                            .map(el => {
                                                                              return (
                                                                                <Select.Option value={el.id}>{el.get('pattern')}</Select.Option>
                                                                              );
                                                                            })
                                                                        }
                                                                      </Select>
                                                                    </Form.Item>
                                                                  </Col>
                                                                  <Col span={4}>
                                                                    <Form.Item label="Substrato de Fixação">
                                                                      <Select value={getWallPanelValue('fixing_substrate', index, wallPanelIndex)} onChange={value => updateWallValue('fixing_substrate', index, value)} placeholder="Substrato de Fixação" size="large">
                                                                        <Select.Option value={null}>Selecione...</Select.Option>
                                                                        {this.renderConfigsOption('substrato_proposal')}
                                                                      </Select>
                                                                    </Form.Item>
                                                                  </Col>
                                                                  <Col span={2}>
                                                                    <Form.Item label="Quantidade">
                                                                      <InputNumber
                                                                        value={getWallPanelValue('wall_quantity', index, wallPanelIndex)}
                                                                        min={1}
                                                                        onChange={value => {
                                                                          updateWallValue('wall_quantity', index, value)
                                                                          // Apagar valor do módulo caso tenha apagado valor da quantidade
                                                                          if (!value) {
                                                                            updateWallValue('panel_wall_module', index, null)
                                                                            updateWallValue('panel_wall_finishing', index, null)
                                                                            updateWallValue('panel_wall_pattern', index, null)
                                                                            updateWallValue('fixing_substrate', index, null)
                                                                          }
                                                                        }}
                                                                        placeholder="Quantidade"
                                                                        size="large"
                                                                        type='number' />
                                                                    </Form.Item>
                                                                  </Col>
                                                                  <Col span={2}>
                                                                    <Form.Item label="Remover">
                                                                      <Button icon="close" shape="circle" size="large" onClick={() => this.removeWallPanel(blockIndex, index, wallPanelIndex)} />
                                                                    </Form.Item>
                                                                  </Col>
                                                                </Row>
                                                              </Fragment>
                                                            );
                                                          })}
                                                        </Row>
                                                        <Divider dashed={true} />
                                                      </Fragment> : null
                                                  }

                                                  {!getProductValue('search_componentKit') || getProductValue('product') === 'Fechadura' ?
                                                    <Fragment>
                                                      <h2>Cadastro de Fechadura</h2>

                                                      <Row gutter={24}>
                                                        <Col span={4}>
                                                          <Form.Item label="Opção de Fechadura">
                                                            <Select

                                                              value={getProductValue('option_fechadura')}
                                                              onChange={value => updateProductData('option_fechadura', index, value)}
                                                              placeholder="Opção de Fechadura" size="large"
                                                            >
                                                              <Select.Option value='conj_fechadura'>Conj Fechadura</Select.Option>
                                                              <Select.Option value='kit_pcd'>Kit PCD</Select.Option>
                                                              <Select.Option value='usinagem'>Usinagem CNC</Select.Option>
                                                              <Select.Option value='fechadura_tn'>Fechadura TN</Select.Option>
                                                              <Select.Option value='fechadura_tr'>Fechadura TR</Select.Option>
                                                              <Select.Option value='fechadura_correr'>Fechadura Correr</Select.Option>
                                                              <Select.Option value='fechadura_magnetica'>Fechadura Magnética</Select.Option>
                                                              <Select.Option value='fechadura_auxiliar'>Fechadura Auxiliar</Select.Option>
                                                              <Select.Option value='fechadura_digital'>Fechadura Digital</Select.Option>
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                        {getProductValue('conj_fechadura') || getProductValue('option_fechadura') === 'conj_fechadura' || getProductValue('option_fechadura') === 'kit_pcd' ?
                                                          <Col span={10}>
                                                            <Form.Item label='Fechadura/Usinagem'>
                                                              <Select

                                                                value={getProductValue('conj_fechadura')}
                                                                onChange={value => updateProductData('conj_fechadura', index, value)}
                                                                placeholder="Fechadura" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_fechadura'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('group') === 'Conjunto Fechadura')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId').filter(el => {
                                                                      return (
                                                                        !(getProductValue('option_fechadura') || '').toLowerCase().includes('kit_pcd') ||
                                                                        (
                                                                          (getProductValue('option_fechadura') || '').toLowerCase().includes('kit_pcd') &&
                                                                          el.description_component.toLowerCase().includes('pcd')
                                                                        )
                                                                      )
                                                                    })
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>

                                                            </Form.Item>
                                                          </Col>
                                                          :
                                                          <Col span={10}>
                                                            <Form.Item label='Fechadura/Usinagem'>
                                                              <Select

                                                                value={getProductValue('hardware_door_lock')}
                                                                onChange={value => updateProductData('hardware_door_lock', index, value)}
                                                                placeholder="Fechadura" size="large"
                                                              >
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_fechadura'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('group') === 'Fechadura')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId').filter(el => {
                                                                      let optionFech = (getProductValue('option_fechadura') || '').toLowerCase();
                                                                      return (
                                                                        (optionFech.includes('_tn') && el.description_component.toLowerCase().includes('-tn ')) ||
                                                                        (optionFech.includes('_tr') && el.description_component.toLowerCase().includes('-tr ')) ||
                                                                        (optionFech.includes('correr') && el.description_component.toLowerCase().includes('correr')) ||
                                                                        (optionFech.includes('usinagem') && el.description_component.toLowerCase().includes('usinagem cnc')) ||
                                                                        (optionFech.includes('magnetica') && el.description_component.toLowerCase().includes('magnética')) ||
                                                                        (optionFech.includes('auxiliar') && el.description_component.toLowerCase().includes('auxiliar')) ||
                                                                        (optionFech.includes('digital') && el.description_component.toLowerCase().includes('digital'))
                                                                      )
                                                                    })
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>

                                                            </Form.Item>
                                                          </Col>
                                                        }

                                                        <Col span={7}>
                                                          <Form.Item label='Maçaneta/Puxador'>
                                                            <Select
                                                              disabled={(getProductValue('option_fechadura') || '').toLowerCase().includes('conj_') || (getProductValue('option_fechadura') || '').toLowerCase().includes('usinagem')}
                                                              value={getProductValue('hardware_door_handle')}
                                                              onChange={value => updateProductData('hardware_door_handle', index, value)}
                                                              placeholder="Maçaneta/Puxador" size="large"
                                                            >
                                                              <Select.Option value={null}>Selecione...</Select.Option>
                                                              {
                                                                _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                  .filter(
                                                                    matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                      matriz.get('typology').includes(getProductValue('typology'))
                                                                  )
                                                                  .map(matriz => matriz.get('component_kit_ferragens'))
                                                                  .flat()
                                                                  .filter(ferragem => (ferragem.get('add_category') === 'Maçaneta' || ferragem.get('add_category') === 'Puxador'))
                                                                  .map(el => el.toJSON())
                                                                  .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                  , 'objectId'
                                                                )
                                                                  .filter(el => {
                                                                    let optionFech = (getProductValue('option_fechadura') || '').toLowerCase();
                                                                    return !(optionFech.includes('_tr') || optionFech.includes('correr'));
                                                                  })
                                                                  .map(el => {
                                                                    return (
                                                                      <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                    );
                                                                  })
                                                              }
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>

                                                        <Col span={3}>
                                                          <Form.Item label="Qtd x Kit">
                                                            <InputNumber
                                                              value={getProductValue('lock_quantity')}
                                                              onChange={value => updateProductData('lock_quantity', index, value)}
                                                              placeholder="Qtd"
                                                              size="large"
                                                              type='number' />
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>
                                                      <Divider dashed={true} />
                                                    </Fragment> : null
                                                  }

                                                  {!getProductValue('search_componentKit') || getProductValue('product') === 'Ferragem' ?
                                                    <Fragment>
                                                      <h2>Cadastro de Ferragens</h2>

                                                      <Row gutter={24}>
                                                        <Col span={24}>
                                                          <Form.Item label="Opção de Ferragem">
                                                            <Select

                                                              value={getProductValue('option_ferragem')}
                                                              onChange={value => {
                                                                updateProductData('option_ferragem', index, value)

                                                                // Remover os valores das ferragens quando a opção for deletada
                                                                if (getProductValue('option_ferragem')) {
                                                                  if (getProductValue('option_ferragem').length > value.length) {
                                                                    let ferragemToDelete = getProductValue('option_ferragem').filter(el => !value.includes(el))
                                                                    updateProductData(`${ferragemToDelete[0]}`, index, null)
                                                                    updateProductData(`${ferragemToDelete[0]}_amount`, index, value)
                                                                  }
                                                                }
                                                              }}
                                                              placeholder="Opção de Ferragem" size="large"
                                                              mode="multiple"
                                                            >
                                                              <Select.Option value='hardware_puller'>Puxador PCD</Select.Option>
                                                              <Select.Option value='hardware_bar_anti_panic'>Barra antipânico</Select.Option>
                                                              <Select.Option value='hardware_aerial_spring'>Mola Aérea</Select.Option>
                                                              <Select.Option value='hardware_accessories'>Batedor de Porta</Select.Option>
                                                              <Select.Option value='hardware_accessories_02'>Olho mágico</Select.Option>
                                                              <Select.Option value='hardware_accessories_03'>Fecho de segurança</Select.Option>
                                                            </Select>
                                                          </Form.Item>
                                                        </Col>
                                                      </Row>

                                                      <Row gutter={24}>
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_puller') ?
                                                          <Col span={10}>
                                                            <Form.Item label="Puxador PCD">
                                                              <Select value={getProductValue('hardware_puller')} onChange={value => updateProductData('hardware_puller', index, value)} placeholder="Puxador PCD" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('add_category') === 'Puxador')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId'
                                                                  )
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_puller') ?
                                                          <Col span={2}>
                                                            <Form.Item label="Qtd x Kit">
                                                              <InputNumber value={getProductValue('hardware_puller_amount')}
                                                                onChange={value => {
                                                                  updateProductData('hardware_puller_amount', index, value)
                                                                  if (!value) updateProductData('hardware_puller', index, null)
                                                                }}
                                                                placeholder="Quantidade de puxadores" size="large"
                                                              />
                                                            </Form.Item>
                                                          </Col> : null
                                                        }

                                                        {(getProductValue('option_ferragem') || []).includes('hardware_bar_anti_panic') ?
                                                          <Col span={10}>
                                                            <Form.Item label="Barra antipânico">
                                                              <Select value={getProductValue('hardware_bar_anti_panic')} onChange={value => updateProductData('hardware_bar_anti_panic', index, value)} placeholder="Barra antipânico" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('add_category') === 'Barra Antipânico')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId'
                                                                  )
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_bar_anti_panic') ?
                                                          <Col span={2}>
                                                            <Form.Item label="Qtd x Kit">
                                                              <InputNumber value={getProductValue('hardware_bar_anti_panic_amount')}
                                                                onChange={value => {
                                                                  updateProductData('hardware_bar_anti_panic_amount', index, value)
                                                                  if (!value) updateProductData('hardware_bar_anti_panic', index, null)
                                                                }}
                                                                placeholder="Quantidade de Barra antipânico" size="large" type='number'
                                                              />
                                                            </Form.Item>
                                                          </Col> : null
                                                        }

                                                        {(getProductValue('option_ferragem') || []).includes('hardware_aerial_spring') ?
                                                          <Col span={10}>
                                                            <Form.Item label="Mola Aérea">
                                                              <Select value={getProductValue('hardware_aerial_spring')} onChange={value => updateProductData('hardware_aerial_spring', index, value)} placeholder="Mola Aérea" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('add_category') === 'Mola Aérea')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId'
                                                                  )
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_aerial_spring') ?
                                                          <Col span={2}>
                                                            <Form.Item label="Qtd x Kit">
                                                              <InputNumber value={getProductValue('hardware_aerial_spring_amount')}
                                                                onChange={value => {
                                                                  updateProductData('hardware_aerial_spring_amount', index, value)
                                                                  if (!value) updateProductData('hardware_aerial_spring', index, null)
                                                                }}
                                                                placeholder="Quantidade de Mola Aérea" size="large" type='number'
                                                              />
                                                            </Form.Item>
                                                          </Col> : null
                                                        }

                                                        {(getProductValue('option_ferragem') || []).includes('hardware_accessories') ?
                                                          <Col span={10}>
                                                            <Form.Item label="Batedor de Porta">
                                                              <Select value={getProductValue('hardware_accessories')} onChange={value => updateProductData('hardware_accessories', index, value)} placeholder="Acessórios (opção 01)" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('add_category') === 'Batedor')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId'
                                                                  )
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_accessories') ?
                                                          <Col span={2}>
                                                            <Form.Item label="Qtd x Kit">
                                                              <InputNumber value={getProductValue('hardware_accessories_amount')}
                                                                onChange={value => {
                                                                  updateProductData('hardware_accessories_amount', index, value)
                                                                  if (!value) updateProductData('hardware_accessories', index, null)
                                                                }}
                                                                placeholder="Quantidade Acessórios" size="large" type='number'
                                                              />
                                                            </Form.Item>
                                                          </Col> : null
                                                        }

                                                        {(getProductValue('option_ferragem') || []).includes('hardware_accessories_02') ?
                                                          <Col span={10}>
                                                            <Form.Item label="Visor-Olho Mágico">
                                                              <Select value={getProductValue('hardware_accessories_02')} onChange={value => updateProductData('hardware_accessories_02', index, value)} placeholder="Acessórios (opção 02)" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('add_category') === 'Olho mágico')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId'
                                                                  )
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_accessories_02') ?
                                                          <Col span={2}>
                                                            <Form.Item label="Qtd x Kit">
                                                              <InputNumber value={getProductValue('hardware_accessories_amount_02')}
                                                                onChange={value => {
                                                                  updateProductData('hardware_accessories_amount_02', index, value)
                                                                  if (!value) updateProductData('hardware_accessories_02', index, null)
                                                                }}
                                                                placeholder="Quantidade Acessórios" size="large" type='number'
                                                              />
                                                            </Form.Item>
                                                          </Col> : null
                                                        }

                                                        {(getProductValue('option_ferragem') || []).includes('hardware_accessories_03') ?
                                                          <Col span={10}>
                                                            <Form.Item label="Fecho de Segurança">
                                                              <Select value={getProductValue('hardware_accessories_03')} onChange={value => updateProductData('hardware_accessories_03', index, value)} placeholder="Acessórios (opção 03)" size="large">
                                                                <Select.Option value={null}>Selecione...</Select.Option>
                                                                {
                                                                  _.uniqBy((this.state.dataConfigs.doorLocksMatriz || [])
                                                                    .filter(
                                                                      matriz => matriz.get('performances').includes(getProductValue('profile_performance')) &&
                                                                        matriz.get('typology').includes(getProductValue('typology'))
                                                                    )
                                                                    .map(matriz => matriz.get('component_kit_ferragens'))
                                                                    .flat()
                                                                    .filter(ferragem => ferragem.get('add_category') === 'Fecho de segurança')
                                                                    .map(el => el.toJSON())
                                                                    .sort((a, b) => (a.description_component > b.description_component) ? 1 : ((b.description_component > a.description_component) ? -1 : 0))
                                                                    , 'objectId'
                                                                  )
                                                                    .map(el => {
                                                                      return (
                                                                        <Select.Option value={el.objectId}>{el.description_component}</Select.Option>
                                                                      );
                                                                    })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                        {(getProductValue('option_ferragem') || []).includes('hardware_accessories_03') ?
                                                          <Col span={2}>
                                                            <Form.Item label="Qtd x Kit">
                                                              <InputNumber value={getProductValue('hardware_accessories_amount_03')}
                                                                onChange={value => {
                                                                  updateProductData('hardware_accessories_amount_03', index, value)
                                                                  if (!value) updateProductData('hardware_accessories_03', index, null)
                                                                }}
                                                                placeholder="Quantidade Acessórios" size="large" type='number'
                                                              />
                                                            </Form.Item>
                                                          </Col> : null
                                                        }
                                                      </Row>

                                                      <Divider dashed={true} />
                                                    </Fragment> : null
                                                  }
                                                </div>

                                              </Fragment>

                                          }
                                        </Collapse.Panel>

                                      )

                                    })
                                  }

                                </Collapse>

                                <div className="add-product-button">
                                  <Button title="Adicionar um novo produto a este bloco." icon="plus" type="primary" onClick={event => {
                                    this.setState(state => {
                                      state.data.product_blocks[blockIndex].products.push({
                                        wall_panel: [
                                          {
                                            panel_wall_module: null,
                                            panel_wall_finishing: null,
                                            panel_wall_pattern: null,
                                            panel_wall_instalation: null,
                                          }
                                        ]
                                      })
                                      return {
                                        ...state,
                                      }
                                    })
                                  }}>Adicionar Produto</Button>
                                </div>
                              </div>
                            )
                          })}
                        </div> : null
                    }

                    {/* <Divider>
                      <Button title="Adicionar um novo bloco." icon="plus" onClick={() => this.createNewProductBlock()}>
                        Adicionar um novo bloco
                      </Button>
                    </Divider> */}

                  </div>
                  : null
              }
            </Card>
          </Form>
        </Spin>
      </div>
    )
  }

  getInstalation() {
    return (this.state.dataConfigs.instalationData || []).find(val => val.id === this.state.data.instalation) || null
  }

  getValue(attr) {
    if (this.state.data[attr] === 0) return 0

    return this.state.data[attr] || null
  }

  getProductValue(attr, productBlock, productIndex) {
    return this.state.data.product_blocks[productBlock].products[productIndex][attr]
  }

  getTotalProduct(pBlock, productIndex) {
    const getId = inp => this.getProductValue(inp, pBlock, productIndex)
    const qtdTypology = this.getProductValue('typology', pBlock, productIndex) && parseInt(this.getProductValue('typology', pBlock, productIndex).split('')[1])

    const compKits = [
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_marco', getId('finishing_marco')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_marco_add', getId('marco_add_flag_panel')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_marco_add', getId('marco_add_flag_panel_glass')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_marco_add', getId('marco_add_passage')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_marco_add', getId('marco_add_seteira')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_ali', getId('finishing_aliz')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_ali', getId('finishing_aliz_adjustable')),
    ]

    // Multiplicar o valor do componente folha pela quantidade de folhas que a tipologia selecionada possui.
    for (let i = 0; i < qtdTypology; i++) {
      compKits.push(this.getComponentKitOfProductInput(pBlock, productIndex, 'comp_folha', getId('finishing_sheet')))
    }

    const fechaKits = [
      this.getComponentKitOfProductInput(pBlock, productIndex, 'conj_fecha', getId('conj_fechadura')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_fecha', getId('hardware_door_lock')),
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_mac', getId('hardware_door_handle')),
    ]

    const ferKits = [
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_pux', getId('hardware_puller')) || null,
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_bar_ap', getId('hardware_bar_anti_panic')) || null,
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_mol', getId('hardware_aerial_spring')) || null,
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_bat', getId('hardware_accessories')) || null,
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_olh', getId('hardware_accessories_02')) || null,
      this.getComponentKitOfProductInput(pBlock, productIndex, 'fer_fech_seg', getId('hardware_accessories_03')) || null,
    ]

    const kit = ((this.state.codeProdData[pBlock] || {})[productIndex] || []).find(v => v.id === getId('product_code'));
    // let percents = 0;
    let mountingValue = 0;
    let instalationValue = 0;
    if (kit) {

      // Cálculo de montagem
      let comp_mounting = kit.get('comp_mounting');
      if (comp_mounting) {
        let mountingJson = comp_mounting.toJSON();
        delete mountingJson.vinculated_component;
        mountingValue = calculatePriceRow(mountingJson, inputPrices);
      }

      // Cálculo de instalação
      let instalation_kit = kit.get('instalation_kit');
      if (instalation_kit && this.getInstalation().get('name').includes('Multidoor Service')) {
        let instalationJson = instalation_kit.toJSON();
        delete instalationJson.vinculated_component;
        instalationValue = calculatePriceRow(instalationJson, inputPrices);
      }

    }

    const calcBlockKits = (list, multiple = 1) => {
      return list.reduce((acc, value) => acc += (value) ? calculatePriceRow(value.toJSON(), inputPrices) : 0, 0) * multiple
    }
    return calcBlockKits(compKits) +
      calcBlockKits(fechaKits, this.getProductValue('lock_quantity', pBlock, productIndex)) +
      calcBlockKits(ferKits, this.getProductValue('hardware_quantity', pBlock, productIndex))
      + mountingValue + instalationValue
  }

  /**
   * Calculate final price of the item.
   * @param     {number}    pBlock 
   * @param     {number}    productIndex 
   * @param     {number}    discount 
   * @param     {number}    kit 
   * @param     {boolean}    withoutSalesChannel
   * @returns 
   */
  getTotalPrice(pBlock, productIndex, discount, kit, withoutSalesChannel = false) {
    // Get sales channel additional/discount
    const salesChannel = (this.state.dataConfigs.salesChannels || []).find(el => el.id === this.state.data.sales_channel_inside_form);
    let salesChannelAdd = 1;
    if (salesChannel && !withoutSalesChannel) salesChannelAdd = (1 + (salesChannel.get('value') / 100));

    // Get component, transform to json and remove vinculated component
    const getComponent = (kitId, component, componentChoosed) => {
      let componentToReturn = ((this.state.componentsKit[kitId] && this.state.componentsKit[kitId][component]) || []).find(el => el.id === item[componentChoosed]);
      if (!componentToReturn) return null;
      componentToReturn = componentToReturn.toJSON();
      if (componentToReturn.hasOwnProperty('vinculated_component')) delete componentToReturn.vinculated_component;
      return componentToReturn;
    }

    // Get door lock, transform to json and remove vinculated component
    const getDoorLock = (doorLock, arrayOfDoorLock) => {
      let componentToReturn = arrayOfDoorLock.find(el => el.id === doorLock);
      if (!componentToReturn) return null;
      componentToReturn = componentToReturn.toJSON();
      if (componentToReturn.hasOwnProperty('vinculated_component')) delete componentToReturn.vinculated_component;
      return componentToReturn;
    }

    // Get total with discount and sales channel
    const getTotal = (component) => {
      let totalOfComponent = calculatePriceRow(component, this.state.dataConfigs.inputPrices);

      // Add sales channel 
      totalOfComponent = parseFloat((totalOfComponent * salesChannelAdd).toFixed(2));

      // Get unit discount
      totalOfComponent = parseFloat((totalOfComponent * (1 - (discount || 0))).toFixed(2));

      if (isNaN(totalOfComponent)) return 0;

      return totalOfComponent;
    }

    let total = 0;

    // Get item components
    const item = this.state.data.product_blocks[pBlock]['products'][productIndex];

    // Get kit id
    const kitId = item && item.product_code;
    if (!kitId) return 0;

    // Get price of the mounting
    let mounting = (this.state.componentsKit[kitId] && this.state.componentsKit[kitId]['comp_mounting']);
    if (mounting && !item.search_componentKit) {
      mounting = mounting.toJSON();
      delete mounting.vinculated_component;
      total += (mounting ? getTotal(mounting) : 0);
    }

    // Get price of the sheet
    const sheet = getComponent(kitId, 'comp_folha', 'finishing_sheet');
    total += ((sheet ? getTotal(sheet) : 0) * kit.get('sheet_qtd'));

    // Get price of the marco
    const marco = getComponent(kitId, 'comp_marco', 'finishing_marco');
    total += (marco ? getTotal(marco) : 0);

    // Get price of the alizares
    const ali_f = getComponent(kitId, 'comp_ali', 'finishing_aliz');
    total += (ali_f ? getTotal(ali_f) : 0);
    const ali_r = getComponent(kitId, 'comp_ali', 'finishing_aliz_adjustable');
    total += (ali_r ? getTotal(ali_r) : 0);

    if (this.state.dataConfigs.doorLocks) {
      // Get price of the fechadura
      const fecha = getDoorLock(item['conj_fechadura'], this.state.dataConfigs.doorLocks.fechaduras);
      total += ((fecha ? getTotal(fecha) : 0) * (item.lock_quantity || 0));
      const conj_fecha = getDoorLock(item['hardware_door_lock'], this.state.dataConfigs.doorLocks.fechaduras);
      total += ((conj_fecha ? getTotal(conj_fecha) : 0) * (item.lock_quantity || 0));

      // Get price of the fechadura
      const pux = getDoorLock(item['hardware_puller'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((pux ? getTotal(pux) : 0) * (item.hardware_puller_amount || 0));
      const mac = getDoorLock(item['hardware_door_handle'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((mac ? getTotal(mac) : 0) * (item.lock_quantity || 0));
      const bar = getDoorLock(item['hardware_bar_anti_panic'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((bar ? getTotal(bar) : 0) * (item.hardware_bar_anti_panic_amount || 0));
      const mol = getDoorLock(item['hardware_aerial_spring'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((mol ? getTotal(mol) : 0) * (item.hardware_aerial_spring_amount || 0));
      const bat = getDoorLock(item['hardware_accessories'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((bat ? getTotal(bat) : 0) * (item.hardware_accessories_amount || 0));
      const olh = getDoorLock(item['hardware_accessories_02'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((olh ? getTotal(olh) : 0) * (item.hardware_accessories_amount_02 || 0));
      const fech_seg = getDoorLock(item['hardware_accessories_03'], this.state.dataConfigs.doorLocks.ferragens);
      total += ((fech_seg ? getTotal(fech_seg) : 0) * (item.hardware_accessories_amount_03 || 0));
    }

    // Get price of the instalation
    let instalation = (this.state.componentsKit[kitId] && this.state.componentsKit[kitId]['instalation_kit']);
    if (instalation && this.getInstalation().get('name').includes('Multidoor Service') && !item.search_componentKit) {
      instalation = instalation.toJSON();
      delete instalation.vinculated_component;
      total += (instalation ? getTotal(instalation) : 0);
    }

    return total;
  }

  /**
   * Função que altera as variáveis required
   * @param {*} param0 
   */
  updateRequired({ removed = [], add = [], type }) {
    let values = new Set([...this.state.required[type]].filter(item => !removed.includes(item)).concat(add))

    let requiredState = this.state.required
    requiredState[type] = Array.from(values)
    this.setState({ required: requiredState })
  }

  updateDataValue(attr, value, callback = function () { }) {
    this.setState(state => {
      state.data[attr] = value
      return state;
    }, () => {
      this.showTable(this.state.data)
      callback(this.state)
    })
  }

  updateProductData(attr, blockIndex, productIndex, value, callback = function () { }) {

    let flagToContinue = false

    // Limpar kit caso altere valor de campos que são pré requisitos
    if (['env', 'typology', 'profile_performance', 'prod_width', 'prod_height'].includes(attr) && this.getProductValue('product_code', blockIndex, productIndex)) {
      let kitAttr = ''
      if (attr === 'env') kitAttr = 'kit_environment'
      if (attr === 'profile_performance') kitAttr = 'performance'
      if (attr === 'prod_width') kitAttr = 'width'
      if (attr === 'prod_height') kitAttr = 'height'

      if (!this.state.codeProdData[blockIndex][productIndex].length || !this.state.codeProdData[blockIndex][productIndex][0].get(kitAttr).includes(value)) {
        let _this = this
        // Modal indicando que os dados serão removidos
        Modal.confirm({
          title: 'Tem certeza que deseja fazer essa alteração?',
          content: 'Esta alteração apagará os dados do produto selecionado.',
          onOk() {
            let localForm = _this.state.data
            let dontChangeAttr = ['amount', 'env', 'env_obra', 'prod_height', 'prod_height_marco', 'prod_width', 'profile_performance', 'project_code', 'typology']
            Object.keys(_this.state.data.product_blocks[blockIndex].products[productIndex]).forEach(key => {
              if (!dontChangeAttr.includes(key)) {
                if (key === 'wall_panel')
                  localForm.product_blocks[blockIndex].products[productIndex][key] = [
                    {
                      "panel_wall_module": null,
                      "panel_wall_finishing": null,
                      "panel_wall_pattern": null,
                      "panel_wall_instalation": null
                    }
                  ]
                else
                  localForm.product_blocks[blockIndex].products[productIndex][key] = null
              }
            })
            _this.setState({ data: localForm })
            _this.setState(state => {
              let product = state.data.product_blocks[blockIndex].products[productIndex];
              product[attr] = value;

              // registrando a espessura do marco separadamente
              if (attr === 'thickness_marco')
                product['thickness_marco_value'] =

                  product.product_hash = _this.productHash(product);
              return state
            }, () => {
              callback()
              _this.showTable(_this.state.data.product_blocks[blockIndex].products[productIndex])
            })
          }
        })
      } else {
        flagToContinue = true
      }
    } else {
      flagToContinue = true
    }

    if (flagToContinue) {
      this.setState(state => {
        let product = state.data.product_blocks[blockIndex].products[productIndex];
        product[attr] = value;

        // registrando a espessura do marco separadamente
        if (attr === 'thickness_marco')
          product['thickness_marco_value'] =

            product.product_hash = this.productHash(product);
        return state
      }, () => {
        callback()
        this.showTable(this.state.data.product_blocks[blockIndex].products[productIndex])
      })

    }

  }

  showTable(data) {
    let keys = Object.keys(data);
    keys.reduce((a, v) => {
      if (data[v]) a[v] = data[v]
      return a;
    }, {})
  }

  contactClientIsValid() {
    return this.groupIsValid([
      ['contact_prop', 'cargo_prop', 'phone_prop', 'email_prop'],
      ['contact_supri', 'cargo_supri', 'phone_supri', 'email_supri'],
      ['contact_eng', 'cargo_eng', 'phone_eng', 'email_eng'],
      ['contact_management', 'cargo_management', 'phone_management', 'email_management']
    ])
  }

  contactWorkIsValid() {
    return this.groupIsValid([
      ['contact_obra_compras', 'cargo_obra_compras', 'phone_obra_compras', 'email_obra_compras'],
      ['contact_obra_eng', 'cargo_obra_eng', 'phone_obra_eng', 'email_obra_eng'],
      ['contact_obra_management', 'cargo_obra_management', 'phone_obra_management', 'email_obra_management']
    ])
  }

  groupIsValid(groups) {
    return groups.some(group => {
      return group.every(attr => this.state.data[attr])
    })
  }

  loadConfigData(loadList = [], callback = function () { }) {
    let query = new Parse.Query('Config');
    query.containedIn('name', loadList)
    query.find().then(response => {
      let projectData = response.reduce((acc, value) => {
        acc[value.get('name')] = value.get('value')
        return acc;
      }, {})
      callback(projectData)
    })
  }

  renderConfigsOption(name, renderList = []) {
    return (this.state.dataConfigs[name] || renderList).map(v => {
      return <Select.Option value={v} key={v}>{v}</Select.Option>
    })
  }

  loadInstalationData() {
    let query = new Parse.Query('Instalation')
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            instalationData: response
          }
        }
      })
    });
  }

  loadProviderData() {
    let query = new Parse.Query('Provider')
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            providerData: response
          }
        }
      })
    });
  }

  loadFinishingData() {
    let query = new Parse.Query('Finishing')
    query.ascending('code')
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            finishingData: response
          }
        }
      })
    });
  }

  loadPatternData() {
    let query = new Parse.Query('Finishing')
    query.find().then(response => {
      let patternsData = []
      response.forEach(finishing => {
        finishing.relation('pattern_color').query().find().then(res => {
          patternsData.push({ finishingId: finishing.id, patterns: res })
          this.setState(state => {
            return {
              dataConfigs: {
                ...state.dataConfigs,
                patternsData: patternsData
              }
            }
          })
        })
      })
    });
  }

  loadInstalationSelectsData() {
    let loadList = [
      { col: 'TypeWork', save: 'typeWorkData' },
      { col: 'MobilityWork', save: 'mobilityWorkData' },
      { col: 'TechManager', save: 'techManagerData' },
      { col: 'TeachAssistance', save: 'techAssistanceData' },
      { col: 'TeamMob', save: 'teamMobData' }
    ]

    let querys = loadList.map(obj => {
      let query = new Parse.Query(obj.col)
      return query.find()
    })

    Promise.all(querys).then(res => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            ...res.reduce((acc, value, index) => ({ ...acc, [loadList[index].save]: value }), {})
          }
        }
      })
    })
  }

  loadPerformanceData() {
    let query = new Parse.Query('ProfilePerformance')
    query.find().then(response => {
      let localProfilePerformances = {}
      response.forEach(snap => {
        localProfilePerformances[snap.get('profile_performance')] = snap.get('performance_level')
      })
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            performanceData: localProfilePerformances
          }
        }
      })
    });
  }

  loadWidthAlizData() {
    let query = new Parse.Query('WidthAliz').limit(500)
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            widthAliz: response
          }
        }
      })
    })
  }

  loadOccupation() {
    let query = new Parse.Query('Occupation')
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            occupation: response
          }
        }
      })
    })
  }

  async getAllComponentsFromKit(kit, item = null, isLastItem = true) {

    if (kit && !this.state.componentsKit[kit.id]) {
      const relationsList = [
        'comp_folha', 'comp_folha_inativa', 'comp_marco', 'comp_marco_add'
      ];

      let components = [];
      for (const key of relationsList) {
        const query = kit.relation(key).query();
        query.include('finishing');
        query.include('unity_value_hh');
        let queryToAddInArray = await query.find();
        components.push(queryToAddInArray);
      }

      const kitWithComponents = {
        'comp_folha': components[0] || [],
        'comp_folha_inativa': components[1] || [],
        'comp_marco': components[2] || [],
        'comp_marco_add': components[3] || [],
        'comp_mounting': kit.get('comp_mounting'),
        'instalation_kit': kit.get('instalation_kit')
      };

      let componentsKit = this.state.componentsKit;
      componentsKit[kit.id] = kitWithComponents;

      this.setState({
        componentsKit,
      });
    }

    this.setState({
      loadingMsg: item ? `Carregando as informações do item ${item + 1}, aguarde...` : 'Carregando as informações dos itens...',
      loadingForm: !isLastItem
    });
  }

  renderTypology(options = [], profilePerformance, width, height, height_marco) {
    let sheetDimension = this.state.dataConfigs.sheetDimension.filter(v => {
      return (
        v.get('performances') || []).includes(profilePerformance) &&
        v.get('width') === width && v.get('height') === height &&
        (v.get('height_marco') || v.get('height')) === height_marco
    })
    let typology = []
    sheetDimension.forEach(v => typology.push(v.get('typology')))
    typology = _.uniq(typology.flat())

    return typology.map((type, index) => {
      return (
        <Select.Option key={index} value={type}>{type}</Select.Option>
      )
    })
  }

  getAlizarComponent() {
    let Query = new Parse.Query('ComponenteKit').limit(15000);
    Query.equalTo('group', 'Alizar');
    Query.find().then(compoenentKits => {
      this.setState({
        alizarDataCK: compoenentKits
      })
    })
  }

  getAllObjectsOfRelation(blockIndex, productIndex, key) {
    return _.get(this.state.codeProdDataRelation, [blockIndex, productIndex, key], [])
  }

  parseProductToSave(productObject) {
    let hashtable = this.getObjectToConvert();
    for (let key in hashtable) {
      if (productObject[key]) {
        let ParseObject = new Parse.Object(hashtable[key]);
        ParseObject.id = productObject[key];
        productObject[key] = ParseObject;
      }
    }

    return productObject;
  }

  getObjectToConvert() {
    return {
      type_work: 'TypeWork',
      mobility_work: 'MobilityWork',
      tech_manager: 'TechManager',
      tech_assistance: 'TeachAssistance',
      team_mob: 'TeamMob',
      instalation: 'Instalation',
      work_stage: 'WorkStage',
      prevision_buy: 'PrevisionBuy'
    }
  }

  async saveProposal() {
    let objectId = this.props.routerProps.match.params.objectId || this.state.objectId;

    let ProposalObject = new Parse.Object('PriceList');

    if (objectId) {
      ProposalObject.id = objectId;
    }

    let ProposalObjectToSave = this.parseProductToSave({ ...this.state.data });

    for (let key in ProposalObjectToSave) {
      ProposalObject.set(key, ProposalObjectToSave[key])
    }

    ProposalObject.set('updatedBy', Parse.User.current());
    if (ProposalObject.get('firstCreatedAt') && ProposalObject.get('firstCreatedAt')['iso']) ProposalObject.set('firstCreatedAt', new Date(ProposalObject.get('firstCreatedAt')['iso']));

    ProposalObject.save().then(priceListSaved => {
      message.success("Tabela salva com sucesso!");
      this.setState({ loading: false, objectId: priceListSaved.id });
    }).catch((err) => {
      notification.error({ message: 'Alguma coisa deu errada, verifique se os valores foram preenchidos corretamente.', duration: 5 });
      this.setState({ loading: false });
    });
  }

  getComponentKitOfProductInput(block, productIndex, key, id) {
    return _.get(this.state.codeProdDataRelation, [block, productIndex, key], []).find(v => v.id === id) || null
  }

  updateDescriptionProductBlock(newDescription, blockIndex) {
    let data = { ...this.state.data };
    let block = this.state.data.product_blocks[blockIndex];
    block.description = newDescription;

    this.setState(state => {
      return {
        ...state,
        data
      }
    })
  }

  updateTitleProductBlock(newTitle, blockIndex) {
    let data = { ...this.state.data };
    let block = this.state.data.product_blocks[blockIndex];
    block.title = newTitle;
    data.product_blocks[blockIndex] = block;
    this.setState(state => {
      return {
        ...state,
        data
      }
    })
  }

  createNewProductBlock() {
    let blocks = [...this.state.data.product_blocks, {
      title: "", products: [{
        wall_panel: [
          {
            panel_wall_module: null,
            panel_wall_finishing: null,
            panel_wall_pattern: null,
            panel_wall_instalation: null,
          }
        ]
      }]
    }];
    let data = { ...this.state.data, product_blocks: blocks };
    this.setState(state => {
      return {
        ...state,
        data
      }
    })
  }

  copyProductTo(product, toBlock) {
    let blocks = [...this.state.data.product_blocks[toBlock].products, { ...product }];
    this.setState(state => {
      state.data.product_blocks[toBlock].products = blocks;
      return { ...state };
    });
  }

  removeProduct(blockIndex, productIndex) {
    this.setState(state => {
      let products = [...state.data.product_blocks[blockIndex].products]
      products.splice(productIndex, 1);
      state.data.product_blocks[blockIndex].products = products;
      return state;
    }, () => this.applyBlocks())
  }

  removeBlock(blockIndex) {
    this.setState(state => {
      let blocks = [...state.data.product_blocks];
      _.remove(blockIndex)
      blocks.splice(blockIndex, 1);
      state.data.product_blocks = blocks;
      return state;
    })
  }

  clearProduct(blockIndex, productIndex) {
    this.setState(state => {
      let product = state.data.product_blocks[blockIndex].products[productIndex];
      product = {
        ...product,
        finishing_sheet: null,
        pattern_sheet: null,
        thickness_marco: null,
        finishing_marco: null,
        pattern_marco: null,
        marco_add_flag_panel: null,
        marco_add_flag_panel_glass: null,
        marco_add_passage: null,
        marco_add_seteira: null,
        aliz_options: null,
        aliz_options_01: null,
        aliz_options_02: null,
        model_aliz: null,
        finishing_aliz: null,
        pattern_aliz: null,
        finishing_aliz_adjustable: null,
        pattern_aliz_adjustable: null,
        fixed_aliz: null,
        adjustable_aliz: null,
        option_fechadura: null,
        lock_door_lock: null,
        conj_fechadura: null,
        hardware_door_lock: null,
        lock_door_handle: null,
        lock_quantity: null,
        hardware_puller: null,
        hardware_bar_anti_panic: null,
        hardware_aerial_spring: null,
        hardware_accessories: null,
        hardware_accessories_02: null,
        hardware_accessories_03: null,
        hardware_quantity: null,
        hardware_door_handle: null,
        panel_wall_module: null,
        panel_wall_finishing: null,
        panel_wall_pattern: null,
        fixing_substrate: null,
        wall_quantity: null,
        // product_code: null
      }
      state.data.product_blocks[blockIndex].products[productIndex] = product
      return state;
    });
  }

  productHash(productObject) {
    if (productObject.hasOwnProperty('product_hash'))
      delete productObject.product_hash

    return objectHash(productObject)
  }

  addNewWallPanel(blockIndex, productIndex) {
    this.setState(state => {
      let _state = { ...state };
      _state.data.product_blocks[blockIndex].products[productIndex].wall_panel.push({
        panel_wall_module: null,
        panel_wall_finishing: null,
        panel_wall_pattern: null,
        panel_wall_instalation: null,
      })
      return _state;
    })
  }

  removeWallPanel(blockIndex, productIndex, wallPanelIndex) {
    let _state = { ...this.state };
    let panels = [..._state.data.product_blocks[blockIndex].products[productIndex].wall_panel];
    panels.splice(wallPanelIndex, 1)
    _state.data.product_blocks[blockIndex].products[productIndex].wall_panel = panels;
    this.setState(_state)
  }

  getWallPanelValue(attr, blockIndex, productIndex, wallPanelIndex) {
    if (this.state.data.product_blocks[blockIndex].products[productIndex]) {
      if (attr === 'panel_wall_finishing' || attr === 'panel_wall_pattern' || attr === 'panel_wall_module' || attr === 'fixing_substrate' || attr === 'wall_quantity') {
        return this.state.data.product_blocks[blockIndex].products[productIndex].wall_panel[wallPanelIndex][attr]
      }
    }
  }

  updateWallValue(attr, blockIndex, productIndex, wallPanelIndex, value) {
    let _state = { ...this.state };
    _state.data.product_blocks[blockIndex].products[productIndex].wall_panel[wallPanelIndex][attr] = value;
    this.setState(_state);
  }

  getCodeProdData(blockIndex, productIndex) {
    return (this.state.codeProdData[blockIndex] || {})[productIndex] || []
  }

  loadSheetDimension() {
    let query = new Parse.Query('SheetDimension').limit(1000000)
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            sheetDimension: response
          }
        }
      })
    })
  }

  loadUsers() {
    let userConected = new Parse.User.current()
    let query = new Parse.Query('User')
    query.find().then(response => {
      response = response.filter(el => el.get('commercial_consultant') || (el.get('name') === userConected.get('name')))
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            users: response
          }
        }
      })
    })
  }

  loadSalesChannels() {
    let query = new Parse.Query('SalesChannel')
    query.find().then(response => {
      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            salesChannels: response
          }
        }
      })
    })
  }

  loadInputPrices() {
    let query = new Parse.Query('Input').limit(1000);
    query.include('unity_value_hh');
    query.find().then(res => {
      let inputPrices = res.reduce((a, v) => {
        a[v.id] = v
        return a
      }, {});

      this.setState(state => {
        return {
          dataConfigs: {
            ...state.dataConfigs,
            inputPrices: inputPrices
          }
        }
      })
    })
  }

  async loadDoorLocksMatriz() {
    const doorLocksMatrizQuery = new Parse.Query('MatrizDoorLocks');
    let doorLocksMatriz = await doorLocksMatrizQuery.find();

    let fechaduras = [];
    let ferragens = [];

    let index = 0;
    for (const matriz of doorLocksMatriz) {
      if (!Array.isArray(matriz.get('component_kit_fechadura'))) {
        const matrizFechaduraRelationQuery = matriz.relation('component_kit_fechadura').query();
        const matrizFechaduraRelation = await matrizFechaduraRelationQuery.find();
        doorLocksMatriz[index].set('component_kit_fechadura', matrizFechaduraRelation);
        fechaduras = fechaduras.concat(matrizFechaduraRelation);
      }

      if (!Array.isArray(matriz.get('component_kit_ferragens'))) {
        const matrizFerragensRelationQuery = matriz.relation('component_kit_ferragens').query();
        const matrizFerragensRelation = await matrizFerragensRelationQuery.find();
        doorLocksMatriz[index].set('component_kit_ferragens', matrizFerragensRelation);
        ferragens = ferragens.concat(matrizFerragensRelation);
      }

      index++;
    }

    fechaduras = [...new Map(fechaduras.map(item => [item.id, item])).values()];
    ferragens = [...new Map(ferragens.map(item => [item.id, item])).values()];

    this.setState(state => {
      return {
        dataConfigs: {
          ...state.dataConfigs,
          doorLocksMatriz: doorLocksMatriz,
          doorLocks: {
            fechaduras: fechaduras,
            ferragens: ferragens,
          }
        }
      }
    });
  }

  async loadAlizMatriz() {
    const alizMatrizQuery = new Parse.Query('MatrizAliz').limit(300);
    let alizMatriz = await alizMatrizQuery.find();

    let index = 0;
    for (const matriz of alizMatriz) {
      if (!Array.isArray(matriz.get('component_kit'))) {
        const componentKitRelationQuery = matriz.relation('component_kit').query();
        const componentKitRelation = await componentKitRelationQuery.find();
        alizMatriz[index].set('component_kit', componentKitRelation);
      }

      index++;
    }

    this.setState(state => {
      return {
        dataConfigs: {
          ...state.dataConfigs,
          alizMatriz: alizMatriz
        }
      }
    });
  }

  async loadKitsAndComponents() {
    let queryKit = new Parse.Query('Kit').limit(5000);
    queryKit.include(['comp_mounting.unity_value_hh']);
    queryKit.include(['instalation_kit.unity_value_hh']);
    let response = await queryKit.find();
    return response;
  }

  /**
   * Validate if component required fields was filled.
   * @param {Object} product 
   */
  validateRequiredComponents = (product) => {

    let productsRequired = [
      'env',
      'profile_performance',
      'typology',
      'product_code',
      'amount',
    ]

    // Check marco
    if (product.product === 'Marco') {
      productsRequired = [
        ...productsRequired,
        'thickness_marco',
        'finishing_marco',
        'pattern_marco',
      ]
    }

    // Check folha
    if (product.product === 'Folha') {
      productsRequired = [
        ...productsRequired,
        'finishing_sheet',
        'pattern_sheet',
      ]
    }

    return productsRequired;

  }

}
