import { Button, Col, Form, InputNumber, message, Modal, Popover, Row, Select } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React, { Component } from 'react'
import { findById } from '../../../../utils/db_functions'
import InputSelectModal from './input-select-modal'

export default class InputCompositionModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: this.props.modalVisible,
            form: this.props.form || {},

            inputSelectModal: false,
        }
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.modalVisible !== this.state.modal) this.setState({ modal: nextProps.modalVisible })

        if (nextProps.form !== this.state.form) {
            let input = null
            if (nextProps.form.specification) {
                input = await findById('Input', nextProps.form.specification)
            }
            this.setState({ form: nextProps.form, input })
        }
    }

    render() {
        return (
            <div>

                <Modal
                    visible={this.state.modal}
                    title='Adicionar/Editar insumo'
                    okText='Salvar'
                    cancelText='Cancelar'
                    onOk={async () => {
                        if (
                            !this.state.form.specification ||
                            !this.state.form.unity ||
                            !this.state.form.amount_component
                        ) {
                            message.error('O insumo e os campos Unidade e Quantidade são obrigatórios')
                        } else {
                            await this.props.addUpdateInput(this.state.form)
                            this.props.closeModal()
                        }
                    }}
                    onCancel={() => {
                        this.props.closeModal()
                    }}
                >

                    <Row gutter={24}>
                        <Col span={18}>
                            <div style={{ lineHeight: '32px' }}>

                                {
                                    !this.state.input ?
                                        <div
                                            className='link-style prevent-break-line'
                                            onClick={() => this.setState({ inputSelectModal: true })}
                                        >
                                            Escolher insumo
                                        </div>
                                        :
                                        <div className='prevent-break-line'>
                                            {this.state.input && this.state.input.get('material_description')}
                                        </div>
                                }
                            </div>
                        </Col>

                        <Col span={6}>
                            {
                                this.state.input ?
                                    <div style={{ textAlign: 'center' }}>
                                        <ButtonGroup>
                                            <Popover content='Trocar insumo'>
                                                <Button
                                                    icon='edit'
                                                    onClick={() => this.setState({ inputSelectModal: true })}
                                                />
                                            </Popover>

                                            <Popover content='Deletar insumo'>
                                                <Button
                                                    icon='delete'
                                                    onClick={() => {
                                                        let _this = this
                                                        Modal.confirm({
                                                            iconType: 'exclamation-circle',
                                                            title: 'Tem certeza que deseja excluir esse insumo?',
                                                            content: 'Essa ação não poderá ser desfeita',
                                                            onOk() {
                                                                _this.setState({
                                                                    input: null,
                                                                    form: { ..._this.state.form, specification: null }
                                                                })
                                                            },
                                                            okText: 'Sim',
                                                            cancelText: 'Não, deixa pra lá'
                                                        })
                                                    }}
                                                />
                                            </Popover>
                                        </ButtonGroup>
                                    </div>
                                    :
                                    ''
                            }
                        </Col>
                    </Row>
                    <Row gutter={24}>

                        <Col span={16}>
                            <Form.Item label='Unidade'>
                                <Select
                                    value={this.state.form.unity}
                                    onChange={e => {
                                        this.setState({ form: { ...this.state.form, unity: e } })
                                    }}
                                    style={{ width: '100%' }}
                                    placeholder='Unidade'
                                >
                                    {
                                        this.props.unitySelectArray.map(unity => {
                                            return (
                                                <Select.Option value={unity} key={unity}>
                                                    {unity}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Quantidade'>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    step={1}
                                    placeholder='Quantidade'
                                    value={this.state.form.amount_component}
                                    onChange={e => {
                                        this.setState({ form: { ...this.state.form, amount_component: e } })
                                    }}
                                />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Largura'>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    step={1}
                                    placeholder='Largura'
                                    value={this.state.form.width_component}
                                    onChange={e => {
                                        this.setState({ form: { ...this.state.form, width_component: e } })
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Espessura'>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    step={1}
                                    placeholder='Espessura'
                                    value={this.state.form.thickness_component}
                                    onChange={e => {
                                        this.setState({ form: { ...this.state.form, thickness_component: e } })
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Comprimento'>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    step={1}
                                    placeholder='Comprimento'
                                    value={this.state.form.length_component}
                                    onChange={e => {
                                        this.setState({ form: { ...this.state.form, length_component: e } })
                                    }}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Modal>

                <InputSelectModal
                    modalVisible={this.state.inputSelectModal}
                    closeModal={() => this.setState({ inputSelectModal: false })}
                    selectInput={input => {
                        this.setState({
                            input,
                            inputSelectModal: false,
                            form: { ...this.state.form, specification: input.id }
                        })
                    }}
                />
            </div>
        )
    }

}