const React = require("react");
const { default: ReactQuill } = require("react-quill");

const proposalConditionsFields = (modules, formats, conditions) => [
    [
        {
            title: 'Condições Comerciais - Título',
            key: 'comercialConditionsTitle',
            type: 'text'
        }
    ],
    [
        {
            title: 'Condições Comerciais - Texto',
            key: 'comercialConditions',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.comercialConditions || conditions.text_comercial}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'comercialConditions')
                        }} />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('comercialConditions', module.state.formRef.comercialConditions)
                return true;
            }

        }
    ],
    [
        {
            title: 'Condições Comerciais - Texto simplificado',
            key: 'comercialConditionsSimplified',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.comercialConditionsSimplified || ''}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'comercialConditionsSimplified')
                        }} />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('comercialConditionsSimplified', module.state.formRef.comercialConditionsSimplified)
                return true;
            }

        }
    ],
    [
        {
            title: 'Condições Técnicas - Título',
            key: 'technicalConditionsTitle',
            type: 'text'
        }
    ],
    [
        {
            title: 'Condições Técnicas - Texto',
            key: 'technicalConditions',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.technicalConditions}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'technicalConditions')
                        }} />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('technicalConditions', module.state.formRef.technicalConditions)
                return true;
            }

        }
    ],
    [
        {
            title: 'Condições Gerais - Título',
            key: 'conditionsTitle',
            type: 'text'
        }
    ],
    [
        {
            title: 'Condições Gerais - Texto',
            key: 'conditions',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.conditions || conditions.text}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'conditions')
                        }} />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('conditions', module.state.formRef.conditions)
                return true;
            }

        }
    ],
]

export default proposalConditionsFields