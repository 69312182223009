
// Modules
const Parse = require('parse');

const operators = {
    'containedIn': '$in',
    'containsAll': '$all',
    'notEqual': '$ne',
    'greatherThan': '$gt',
    'lessThan': '$lt',
};

/**
 * Get all data from a module in database
 * @param {string} module 
 * @param {number} limit 
 * @param {array} includes
 * @param {array} relations
 * @param {string} fieldToSort
 * @param {string} sortType
 * @returns Array of all data
 */
const findAll = async (module, limit = 100, includes = null, relations = null, fieldToSort = null, sortType = null) => {
    const query = new Parse.Query(module).limit(limit || 1000);
    if (fieldToSort) {
        if (sortType === 'descending') query.descending(fieldToSort)
        else query.ascending(fieldToSort);
    }
    (includes || []).forEach(includesEl => query.include(includesEl));
    const data = await query.find();
    let dataToReturn = []

    const relationsArray = (relations || []);
    if (relationsArray.length) dataToReturn = await findRelation(data, relationsArray)
    else dataToReturn = data
    return dataToReturn;
}

/**
 * Get a specifique data in database
 * @param {string} module 
 * @param {string} id 
 * @param {array} includes
 * @param {array} relations
 * @returns ParseObject
 */
const findById = async (module, id, includes = null, relations = null) => {
    try {
        const query = new Parse.Query(module);
        (includes || []).forEach(includesEl => query.include(includesEl))
        const data = await query.get(id)
        let dataToReturn = data

        const relationsArray = (relations || []);
        if (relationsArray.length) dataToReturn = await findOneDataRelations(data, relationsArray)

        return dataToReturn
    } catch (error) {
        console.error(error.message)
        return null
    }
}

/**
 * Get a specifique data with conditions in database
 * @param {string} module 
 * @param {string} field 
 * @param {array} values 
 * @param {array} includes
 * @param {array} relations
 * @param {string} fieldToSort
 * @param {string} sortType
 * @param {number} limit
 * @returns Array of all data
 */
const findWhere = async (module, field, values, includes = null, relations = null, fieldToSort = null, sortType = null, limit = 100) => {
    const query = new Parse.Query(module).containedIn(field, values).ascending(field);
    if (fieldToSort) {
        if (sortType === 'descending') query.descending(fieldToSort)
        else query.ascending(fieldToSort);
    }
    (includes || []).forEach(includesEl => query.include(includesEl));
    const data = await query.limit(limit).find();
    let dataToReturn = []

    const relationsArray = (relations || []);
    if (relationsArray.length) dataToReturn = await findRelation(data, relationsArray)
    else dataToReturn = data

    return dataToReturn;
}

/**
 * Get a specifique data with multiple conditions in database
 * @param {string} module 
 * @param {array} conditions ex.: [{'field': 'field', 'value': 'value', 'op': 'equalTo', logicOp: '$and'}]
 * @param {array} includes
 * @param {array} relations
 * @param {number} limit
 * @param {string} fieldToSort
 * @param {string} sortType
 * @returns Array of all data
 */
const findWhereMultiple = async (module, conditions, includes = null, relations = null, limit = null, fieldToSort = null, sortType = null) => {
    const query = new Parse.Query(module).limit(limit || 100)
    if (fieldToSort) {
        if (sortType === 'descending') query.descending(fieldToSort)
        else query.ascending(fieldToSort);
    }
    query['_where'] = {};

    conditions.forEach(condition => {
        if (condition.isDateType)
            condition.value = {
                __type: "Date",
                iso: new Date(condition.value).toISOString(),
            }

        if (condition.op === 'equalTo') query['_where'][condition.logicOp || '$and'] = [...(query['_where'][condition.logicOp || '$and'] || []), { [condition.field]: condition.value }];
        else if (condition.op === 'like') query['_where'][condition.logicOp || '$and'] = [...(query['_where'][condition.logicOp || '$and'] || []), { [condition.field]: { $regex: condition.value, $options: 'i' } }];
        else if (condition.op === 'notLike') query['_where'][condition.logicOp || '$and'] = [...(query['_where'][condition.logicOp || '$and'] || []), { [condition.field]: { $regex: `^((?!${condition.value}).)*$`, $options: 'i' } }];
        else query['_where'][condition.logicOp || '$and'] = [...(query['_where'][condition.logicOp || '$and'] || []), { [condition.field]: { [operators[condition.op]]: condition.value } }];
    });

    (includes || []).forEach(includesEl => query.include(includesEl));
    const data = await query.find();
    let dataToReturn = []

    const relationsArray = (relations || []);
    if (relationsArray.length) dataToReturn = await findRelation(data, relationsArray)
    else dataToReturn = data

    return dataToReturn;
}

/**
 * Find a relation of several parse Objects
 * @param {array} data 
 * @param {array} relationfieldsArray 
 * @param {array} includes 
 * @returns array of parse objects related
 */
const findRelation = async (data, relationfieldsArray, includes = null) => {
    try {
        let dataToReturn = []
        for (let indexData = 0; indexData < data.length; indexData++) {
            let dataChild = data[indexData].clone()
            dataChild._id = data[indexData].id

            for (let indexRelation = 0; indexRelation < relationfieldsArray.length; indexRelation++) {
                const relationQuery = data[indexData].relation(relationfieldsArray[indexRelation]).query()
                const relationData = await relationQuery.find()
                dataChild.set(relationfieldsArray[indexRelation], relationData)
            }

            // dataChild.id = data[indexData].id
            dataToReturn.push(dataChild)
        }
        return dataToReturn;
    } catch (e) {
        console.log(e.message)
        return null
    }
}

/**
 * Find a relation of one parse Object
 * @param {object} data 
 * @param {array} relationfieldsArray 
 * @param {array} includes 
 * @returns array of parse objects related
 */
const findOneDataRelations = async (data, relationfieldsArray, includes = null) => {
    try {
        let dataChild = data.clone()

        for (let indexRelation = 0; indexRelation < relationfieldsArray.length; indexRelation++) {
            const relationQuery = data.relation(relationfieldsArray[indexRelation]).query()
            const relationData = await relationQuery.find()
            dataChild.set(relationfieldsArray[indexRelation], relationData)
        }

        return dataChild
    } catch (e) {
        console.log(e.message)
        return null
    }
}

/**
 * Save data in db
 * @param {string} module 
 * @param {object} data 
 * @param {string} idToUpdate 
 * @returns Data created
 */
const save = async (module, data, idToUpdate = null) => {
    let ObjectParse = new Parse.Object(module);
    ObjectParse.set('updatedBy', Parse.User.current());
    if (idToUpdate) ObjectParse.id = idToUpdate;
    try {
        const dataCreated = await ObjectParse.save(data);
        return dataCreated;
    } catch (error) { return null; }
}

/**
 * Save data in db 
 * @param {object} dataArray
 * @returns Data created
 */
const saveMany = async (dataArray) => {
    try {
        await Parse.Object.saveAll(dataArray);
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

/**
 * Delete data in db
 * @param {string} module
 * @param {string} idToDelete 
 * @returns Boolean: Was removed
 */
const remove = async (module, idToDelete) => {
    const query = new Parse.Query(module);
    const data = await query.get(idToDelete);
    try {
        await data.destroy({});
        return true
    } catch (error) { return false; }
}

/**
 * Run cloud function
 * @param {string} cloudFunction 
 * @param {object} object 
 * @returns object with code and reponse. ex.: {code: 200, response: {}}
 */
const runCloudFunction = async (cloudFunction, object) => {
    try {
        const response = await Parse.Cloud.run(cloudFunction, object);
        return {
            code: 200,
            response,
        };
    } catch (error) {
        return {
            code: 400,
            response: error,
        }
    };
}

/**
 * Get current connect user
 * @returns parse object
 */
const getCurrentUser = () => {
    // @ts-ignore
    return new Parse.User.current();
}

export {
    findAll,
    findById,
    findWhere,
    findWhereMultiple,
    findRelation,
    save,
    saveMany,
    remove,
    runCloudFunction,
    getCurrentUser,
}