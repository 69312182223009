import { Col, Modal, Row, Tree, Button, Form, Input, Icon } from 'antd'
import React, { Component } from 'react'
import { getCurrentUser } from '../../../../utils/db_functions'
import Parse from "parse"
import '../client-folder.css'

export default class OrderFolderTree extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentUser: null,

            docList: this.props.docList,

            folderIndexArray: [],

            folderOptionModal: false,
            folderName: null,

            isToUpdateFolderFlag: null,
            folderDocModal: false,
            folderDocFormName: '',

            docModal: false,
            docInfo: null,

            isToUpdateDocFlag: null,

            linkDocModal: false,
            linkDoc: null,
            linkDocName: null,

            fileDocModal: false,
            fileDoc: null,
            fileDocToUpdate: null,
            fileDocName: null,
        }
    }

    componentDidMount = async () => {
        const user = await getCurrentUser()
        this.setState({ user: user.get('name') })
    }

    componentWillReceiveProps = (nexProps) => {
        if (nexProps.docList !== this.state.docList)
            this.setState({ docList: nexProps.docList })
    }

    renderDocElement = (docElement) => {
        if (docElement.doc_description) {
            return (
                <Tree.TreeNode
                    switcherIcon={
                        (docElement.docs || []).length ? <Icon type="caret-right" /> : null
                    }
                    title={docElement.doc_description}
                    key={`__folder__${docElement.doc_description}`}
                >

                    {(docElement.docs || []).map(el => this.renderDocElement(el))}

                </Tree.TreeNode>
            )
        } else {

            const docName = docElement.docName || (docElement.file && docElement.file.name)

            return (
                <Tree.TreeNode
                    title={docName}
                    key={docName}
                    isLeaf
                />
            )
        }
    }

    addUpdateAndDeleteFolder = ({ deleteFolder }) => {
        const folderIndexArray = [...this.state.folderIndexArray].slice(2)

        let actualIndex = 0
        let actualIndexValue = folderIndexArray[0]

        const updateElement = (array) => {

            return array.map((element, index) => {

                if (index.toString() === actualIndexValue) {
                    if ((actualIndex + 1) === folderIndexArray.length) {
                        if (deleteFolder) element = null
                        else if (this.state.isToUpdateFolderFlag)
                            element.doc_description = this.state.folderDocFormName
                        else
                            element.docs = [
                                ...(element.docs || []),
                                {
                                    doc_description: this.state.folderDocFormName,
                                    docs: [],
                                }
                            ]
                        actualIndexValue = null
                    } else {
                        actualIndex++
                        actualIndexValue = folderIndexArray[actualIndex]
                        element.docs = updateElement(element.docs || [])
                    }
                }

                return element
            }).filter(el => el)
        }

        let docList = [...this.state.docList]
        if (folderIndexArray.length > 0)
            docList = updateElement(docList)
        else
            docList = [
                ...docList,
                {
                    doc_description: this.state.folderDocFormName,
                    docs: [],
                }
            ]

        this.props.updateDocList(docList)

        this.setState({
            folderDocModal: false, folderDocFormName: null, isToUpdateFolderFlag: false,
            folderOptionModal: false, folderName: null,
        })
    }

    addUpdateAndDeleteDoc = ({ deleteDoc, isALink }) => {
        const folderIndexArray = [...this.state.folderIndexArray].slice(2)

        let actualIndex = 0
        let actualIndexValue = folderIndexArray[0]

        const updateElement = (array) => {

            return array.map((element, index) => {

                if (index.toString() === actualIndexValue) {
                    if ((actualIndex + 1) === folderIndexArray.length) {
                        if (deleteDoc) {
                            element = null
                        }
                        else if (this.state.isToUpdateDocFlag) {
                            const file = this.state.fileDocToUpdate ?
                                new Parse.File(this.state.fileDocName.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), this.state.fileDocToUpdate) :
                                isALink ?
                                    {
                                        // name: this.state.linkDocName,
                                        url: this.state.linkDoc,
                                    } :
                                    {
                                        ...element.file,
                                        // [element.file._name ? '_name' : 'name']: this.state.fileDocName,
                                    }

                            element = {
                                file,
                                localPath: element.localPath,
                                docName: isALink ? this.state.linkDocName : this.state.fileDocName,
                                userAndDate: {
                                    user: this.state.user,
                                    date: new Date().toLocaleDateString('pt-BR')
                                },
                                isALink: isALink || false
                            }

                            if (this.state.fileDocToUpdate) element['localPath'] = window.URL.createObjectURL(this.state.fileDocToUpdate)
                        } else {
                            const file = this.state.fileDocToUpdate ?
                                new Parse.File(this.state.fileDocName.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), this.state.fileDocToUpdate) :
                                isALink ?
                                    {
                                        // name: this.state.linkDocName,
                                        url: this.state.linkDoc,
                                    } :
                                    {
                                        // name: this.state.fileDocName,
                                        url: '',
                                    }

                            let elementToAdd = {
                                file,
                                docName: isALink ? this.state.linkDocName : this.state.fileDocName,
                                userAndDate: {
                                    user: this.state.user,
                                    date: new Date().toLocaleDateString('pt-BR')
                                },
                                isALink: isALink || false
                            }
                            if (this.state.fileDocToUpdate) elementToAdd['localPath'] = window.URL.createObjectURL(this.state.fileDocToUpdate)

                            element.docs = [
                                ...(element.docs || []),
                                elementToAdd,
                            ]
                        }
                        actualIndexValue = null
                    } else {
                        actualIndex++
                        actualIndexValue = folderIndexArray[actualIndex]
                        element.docs = updateElement(element.docs || [])
                    }
                }

                return element
            }).filter(el => el)
        }

        let docList = [...this.state.docList]
        docList = updateElement(docList)
        if (folderIndexArray.length > 0) {
            docList = updateElement(docList)
        } else {
            const file = this.state.fileDocToUpdate ?
                new Parse.File(this.state.fileDocName.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), this.state.fileDocToUpdate) :
                isALink ?
                    { url: this.state.linkDoc } :
                    { url: '' }

            let elementToAdd = {
                file,
                docName: isALink ? this.state.linkDocName : this.state.fileDocName,
                userAndDate: {
                    user: this.state.user,
                    date: new Date().toLocaleDateString('pt-BR')
                },
                isALink: isALink || false
            }
            if (this.state.fileDocToUpdate) elementToAdd['localPath'] = window.URL.createObjectURL(this.state.fileDocToUpdate)
            docList = [
                ...docList,
                elementToAdd
            ]
        }

        this.props.updateDocList(docList)

        this.setState({
            isToUpdateDocFlag: false,
            linkDocModal: false, linkDocName: null, linkDoc: null,
            fileDocModal: false, fileDocName: null, fileDoc: null, fileDocToUpdate: null,
            docModal: false, docInfo: null,
            folderOptionModal: false, folderName: null,
        })
    }

    getDocInfo = () => {
        const folderIndexArray = [...this.state.folderIndexArray].slice(2)

        let elementToReturn = null

        let actualIndex = 0
        let actualIndexValue = folderIndexArray[0]

        const getElement = (array) => {

            array.forEach((element, index) => {

                if (index.toString() === actualIndexValue) {
                    if ((actualIndex + 1) === folderIndexArray.length) {
                        elementToReturn = element
                        actualIndexValue = null
                    } else {
                        actualIndex++
                        actualIndexValue = folderIndexArray[actualIndex]
                        getElement(element.docs || [])
                    }
                }
            })
        }

        let docList = [...this.state.docList]
        getElement(docList)

        return elementToReturn
    }

    formatDocName = (docName) => {
        const docNameArray = (docName || '').split('_')
        if (docNameArray.length > 1) {
            docNameArray.shift()
            docName = docNameArray.join('_')
        }
        return docName
    }

    dragAndDrop = (info) => {

        const dropKey = info.node.props.eventKey
        const dragKey = info.dragNode.props.eventKey

        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                const itemKey = item.doc_description ?
                    (`__folder__${item.doc_description}`)
                    :
                    (item.docName || (item.file && item.file.name))

                if (itemKey === key) {
                    return callback(item, index, arr)
                }
                if (item.docs) {
                    return loop(item.docs, key, callback)
                }
            })
        }

        const docList = [...this.state.docList].map(el => {
            if (!el.docs) el.docs = []
            return el
        })

        if (!info.dropToGap) {
            // Find dragObject
            let dragObj;
            loop(docList, dragKey, (item, index, arr) => {
                arr.splice(index, 1)
                dragObj = item
            })

            loop(docList, dropKey, item => {
                item.docs = item.docs || []
                item.docs.push(dragObj)
            })
        }

        this.setState({ docList: docList })

        this.props.updateDocList(docList)
    }

    render() {

        return (
            <div>

                <Tree.DirectoryTree
                    expandAction='false'
                    blockNode
                    multiple
                    defaultExpandAll
                    onSelect={async (e, i) => {
                        if (e[0].includes('__folder__')) {
                            const folderIndexArray = (i.node.props.pos).split('-')

                            this.setState({
                                folderOptionModal: true,
                                folderName: i.node.props.title,
                                folderIndexArray,
                            })
                        } else {
                            const folderIndexArray = (i.node.props.pos).split('-')

                            await this.setState({ folderIndexArray })

                            const docInfo = this.getDocInfo()

                            this.setState({ docModal: true, docInfo })
                        }
                    }}
                    draggable
                    onDrop={this.dragAndDrop}
                >

                    <Tree.TreeNode
                        switcherIcon={<Icon type="caret-right" />}
                        title={this.props.parenteFolder}
                        key={`__folder__${this.props.parenteFolder}`}
                    >

                        {
                            (this.state.docList || []).map(docElement => {
                                return this.renderDocElement(docElement)
                            })
                        }

                    </Tree.TreeNode>

                </Tree.DirectoryTree>

                <Modal
                    visible={this.state.folderOptionModal}
                    title={`Pasta: ${this.state.folderName}`}
                    onCancel={() => this.setState({ folderOptionModal: false, folderName: null })}
                    footer={[]}
                    width={600}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Button
                                block icon='folder-open'
                                onClick={() => this.setState({ folderDocModal: true })}
                            >Nova pasta</Button>
                        </Col>

                        <Col span={8}>
                            <Button
                                block icon='file'
                                onClick={() => this.setState({
                                    fileDocModal: true,
                                    fileDocName: null,
                                    fileDoc: null,
                                    isToUpdateDocFlag: false,
                                })}
                            >Novo documento</Button>
                        </Col>

                        <Col span={8}>
                            <Button
                                block icon='link'
                                onClick={() => this.setState({ linkDocModal: true })}
                            >
                                Novo link
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Button
                                block icon='edit'
                                disabled={this.state.folderName === this.props.parenteFolder}
                                onClick={() => {
                                    this.setState({
                                        folderDocModal: true,
                                        folderDocFormName: this.state.folderName,
                                        isToUpdateFolderFlag: true,
                                    })
                                }}
                            >Editar nome</Button>
                        </Col>

                        <Col span={12}>
                            <Button
                                block icon='delete' type='danger'
                                disabled={this.state.folderName === this.props.parenteFolder}
                                onClick={() => {
                                    let _this = this
                                    Modal.confirm({
                                        iconType: 'exclamation-circle',
                                        title: 'Tem certeza que deseja exlcuir essa pasta e todo seu conteúdo?',
                                        content: 'Essa ação não poderá ser desfeita',
                                        onOk() {
                                            _this.addUpdateAndDeleteFolder({ deleteFolder: true })
                                        },
                                        okText: 'Sim',
                                        cancelText: 'Não, deixa pra lá'
                                    })
                                }}
                            >Excluir pasta</Button>
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    visible={this.state.folderDocModal}
                    title='Criar/Atualizar pasta'
                    onCancel={() => this.setState({ folderDocModal: false, folderDocFormName: null, isToUpdateFolderFlag: false })}
                    cancelText='Cancelar'
                    onOk={() => this.addUpdateAndDeleteFolder({ deleteFolder: false })}
                    okText='Salvar'
                >
                    <Form.Item label='Nome da pasta'>
                        <Input
                            value={this.state.folderDocFormName}
                            onChange={e => this.setState({ folderDocFormName: e.target.value })}
                            size='large'
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Modal>

                <Modal
                    visible={this.state.linkDocModal}
                    title='Adicionar/Atualizar Link'
                    onCancel={() => this.setState({ linkDocModal: false, linkDocName: null, linkDoc: null, isToUpdateDocFlag: false })}
                    cancelText='Cancelar'
                    onOk={() => this.addUpdateAndDeleteDoc({ deleteDoc: false, isALink: true, })}
                    okText='Salvar'
                    zIndex={1100}
                >
                    <Form.Item label='Nome do arquivo'>
                        <Input
                            value={this.state.linkDocName}
                            onChange={e => this.setState({ linkDocName: e.target.value })}
                            size='large'
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item label='Link do arquivo'>
                        <Input
                            value={this.state.linkDoc}
                            onChange={e => this.setState({ linkDoc: e.target.value })}
                            size='large'
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Modal>

                <Modal
                    visible={this.state.docModal}
                    title='Documento'
                    onCancel={() => this.setState({ docModal: false, docInfo: null })}
                    footer={[
                        <Button
                            onClick={() => {
                                const redirectToLocalPath =
                                    this.state.docInfo && this.state.docInfo.file && this.state.docInfo.file._name

                                const url = redirectToLocalPath ? this.state.docInfo.localPath : this.state.docInfo.file.url

                                window.open(url, '_blank').focus();
                            }}
                            type='primary'
                        >
                            Visualizar/Baixar
                        </Button>,

                        <Button
                            icon='edit'
                            onClick={() => {
                                if (this.state.docInfo && this.state.docInfo.isALink) {
                                    this.setState({
                                        linkDocModal: true,
                                        linkDocName: this.state.docInfo && this.state.docInfo.docName,
                                        linkDoc: this.state.docInfo && this.state.docInfo.file && this.state.docInfo.file.url,
                                        isToUpdateDocFlag: true,
                                    })
                                } else {
                                    this.setState({
                                        fileDocModal: true,
                                        fileDocName: ((this.state.docInfo && this.state.docInfo.docName) || (this.state.docInfo.file._name || this.state.docInfo.file.name)),
                                        fileDoc: this.state.docInfo && this.state.docInfo.file,
                                        isToUpdateDocFlag: true,
                                    })
                                }
                            }}
                        >
                            Editar
                        </Button>,

                        <Button
                            icon='delete'
                            type='danger'
                            onClick={() => {
                                let _this = this
                                Modal.confirm({
                                    iconType: 'exclamation-circle',
                                    title: 'Tem certeza que deseja exlcuir esse documento?',
                                    content: 'Essa ação não poderá ser desfeita',
                                    onOk() {
                                        _this.addUpdateAndDeleteDoc({ deleteDoc: true, isALink: null })
                                    },
                                    okText: 'Sim',
                                    cancelText: 'Não, deixa pra lá'
                                })
                            }}
                        >
                            Excluir documento
                        </Button>,
                    ]}
                >
                    <div className='order-folder-doc-label'><strong>Nome:</strong></div>
                    <div>
                        {
                            (this.state.docInfo && this.state.docInfo.docName) ||
                            (this.state.docInfo && this.state.docInfo.file && (this.state.docInfo.file._name || this.state.docInfo.file.name))
                        }
                    </div>

                    <div className='order-folder-updated-by'>Editado por:</div>
                    <div className='order-folder-user-updated-by-info'>{this.state.docInfo && this.state.docInfo.userAndDate.user}</div>
                    <div className='order-folder-user-updated-by-date'>{this.state.docInfo && this.state.docInfo.userAndDate.date}</div>
                </Modal>

                <Modal
                    visible={this.state.fileDocModal}
                    title='Adicionar/Atualizar Documento'
                    onCancel={() => this.setState({
                        fileDocModal: false,
                        fileDocName: null,
                        fileDoc: null,
                        fileDocToUpdate: null,
                        isToUpdateDocFlag: false,
                    })}
                    cancelText='Cancelar'
                    onOk={() => this.addUpdateAndDeleteDoc({ deleteDoc: false, isALink: false, })}
                    okText='Salvar'
                    zIndex={1100}
                >
                    <Form.Item label='Nome do arquivo'>
                        <Input
                            value={this.state.fileDocName}
                            onChange={e => this.setState({ fileDocName: e.target.value })}
                            size='large'
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item label='Arquivo'>
                        <Button
                            block
                            onClick={() => document.getElementById('order-folder_file_form_input').click()}
                            icon='file'
                        >
                            Inserir/Atualizar arquivo
                        </Button>
                        <input
                            type='file'
                            id='order-folder_file_form_input'
                            onChange={(e) => {
                                this.setState({ fileDocToUpdate: e.target.files[0] })
                            }}
                            style={{ display: 'none' }}
                        />
                    </Form.Item>

                    <div className='order-folder-file-name-label'>Arquivo inserido:</div>
                    <div>
                        {
                            (this.state.fileDocToUpdate && this.state.fileDocToUpdate.name) ||
                            (this.state.fileDoc && (this.state.fileDoc._name || this.state.fileDoc.name)) || ''
                        }
                    </div>
                </Modal>

                <Modal
                    visible={this.state.linkDocModal}
                    title='Adicionar/Atualizar Link'
                    onCancel={() => this.setState({ linkDocModal: false, linkDocName: null, linkDoc: null, isToUpdateDocFlag: false })}
                    cancelText='Cancelar'
                    onOk={() => this.addUpdateAndDeleteDoc({ deleteDoc: false, isALink: true, })}
                    okText='Salvar'
                    zIndex={1200}
                >
                    <Form.Item label='Nome do arquivo'>
                        <Input
                            value={this.state.linkDocName}
                            onChange={e => this.setState({ linkDocName: e.target.value })}
                            size='large'
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item label='Link do arquivo'>
                        <Input
                            value={this.state.linkDoc}
                            onChange={e => this.setState({ linkDoc: e.target.value })}
                            size='large'
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Modal>

            </div>
        )

    }

}