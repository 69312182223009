import { Icon, Input } from 'antd';
import React from 'react';
import Parse from 'parse';

// Staffing values
let staffing;
let staffingTotal = (installments) => (((staffing && staffing.get('total')) || 0) * (installments || 1));

export default {
    'formTabs': [
        {
            title: 'Informações gerais',
            fields: [
                [
                    {
                        title: 'Referência (ano-mês)',
                        key: 'ref',
                        type: 'text',
                        colSize: 8,
                        'element-attr': { required: true }
                    }
                ]
            ]
        },
        {
            title: 'Custos - Folha',
            fields: [
                [
                    {
                        title: 'Quadro de pessoal',
                        key: 'staffing',
                        type: 'pointer',
                        colSize: 4,
                        options: [],
                        'relation-select': {
                            class: 'Staffing',
                            label: 'ref',
                            value: 'objectId',
                            key: 'objectId'
                        },
                        'element-attr': {
                            required: false, mode: 'default',
                            afterChange: async (_) => {
                                staffing = await getStaffing(_.state.formRef.staffing);
                                _.state.formRef.staffing_value_total = await calculateStaffingValueTotal(staffing, _.state.formRef);
                                _.forceUpdate();
                            }
                        },
                    },
                    {
                        title: 'Colaboradores',
                        key: 'staffing_total',
                        type: 'render',
                        colSize: 3,
                        render: () => {
                            return (
                                <Input disabled size='large' value={staffing && staffing.get('staffing_total')} />
                            )
                        }
                    },
                    {
                        title: 'Parcelas',
                        key: 'staffing_installments',
                        type: 'number',
                        colSize: 2,
                        'element-attr': { required: false, step: 1 },
                    },
                ],
                [
                    {
                        title: 'Total geral - Mensal',
                        key: 'value_unit',
                        type: 'render',
                        colSize: 6,
                        render: (_) => {
                            let valueUnit = ((staffing && staffing.get('total')) || 0) + ((sheetCostTotal(_.formRef.sheet_costs) || 0) / 12);
                            return (
                                <Input disabled size='large' value={(valueUnit || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                            )
                        }
                    },
                    {
                        title: 'Total geral - Anual',
                        key: 'value_total',
                        type: 'render',
                        colSize: 6,
                        render: (_) => {
                            let valueTotal = (staffingTotal(_.formRef.staffing_installments) || 0) + (sheetCostTotal(_.formRef.sheet_costs) || 0);
                            return (
                                <Input disabled size='large' value={(valueTotal || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                            )
                        }
                    },
                ],
                [
                    {
                        title: 'Outros',
                        type: 'dynamic-input-list',
                        key: 'sheet_costs',
                        fields: [
                            [
                                {
                                    title: 'Descrição',
                                    key: 'description',
                                    type: 'text',
                                    colSize: 17,
                                    'element-attr': { required: true },
                                },
                                {
                                    title: 'Percentual (%)',
                                    key: 'percent',
                                    type: 'number',
                                    colSize: 4,
                                },
                                {
                                    title: 'Parcelas',
                                    key: 'installments',
                                    type: 'number',
                                    colSize: 3,
                                    'element-attr': { required: false, step: 1 },
                                },
                            ]
                        ]
                    }
                ]
            ]
        },
        {
            title: 'Custos - Diversos',
            fields: [
                [
                    {
                        type: 'dynamic-input-list',
                        key: 'other_costs',
                        fields: [
                            [
                                {
                                    title: 'Descrição',
                                    key: 'description',
                                    type: 'text',
                                    colSize: 15,
                                    'element-attr': { required: true },
                                },
                                {
                                    title: 'Parcelas',
                                    key: 'installments',
                                    type: 'number',
                                    colSize: 3,
                                    'element-attr': { required: true, step: 1 },
                                },
                                {
                                    title: 'Valor unitário',
                                    key: 'value_unit',
                                    type: 'money',
                                    colSize: 6,
                                    'element-attr': { required: true },
                                },
                            ]
                        ]
                    }
                ]
            ]
        },
        {
            title: 'Totais',
            fields: [
                [
                    {
                        title: 'Total Mensal',
                        key: 'total_value_unit',
                        type: 'render',
                        colSize: 6,
                        render: (_) => {
                            const sheetUnit = ((staffing && staffing.get('total')) || 0) + ((sheetCostTotal(_.formRef.sheet_costs) || 0) / 12);
                            const valueUnit = (othersCostTotal(_.formRef.other_costs) / 12) + sheetUnit;
                            return (
                                <Input disabled size='large' value={(valueUnit || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                            )
                        }
                    },
                    {
                        title: 'Total Anual',
                        key: 'total_value_total',
                        type: 'render',
                        colSize: 6,
                        render: (_) => {
                            const sheetTotal = (staffingTotal(_.formRef.staffing_installments) || 0) + (sheetCostTotal(_.formRef.sheet_costs) || 0);
                            const valueTotal = othersCostTotal(_.formRef.other_costs) + sheetTotal;
                            return (
                                <Input disabled size='large' value={(valueTotal || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                            )
                        }
                    },
                ]
            ]
        }
    ],
    'FormHasMounted': (_) => {
        setTimeout(async () => {
            staffing = await getStaffing(_.state.formRef.staffing);
            _.forceUpdate();
        }, 500);
    },
    'submit': { 'collection': 'FixedCost' },
    'schema': [
        {
            title: 'Referência',
            key: 'ref',
            dataIndex: 'ref',
            type: 'text',
            width: 200,
        },
    ],
    'title-module': 'Custo fixo',
    'title-navigation': <div><Icon type='dollar' /> Custo fixo</div>,
    'module': 'FixedCost',
    'router-base': '/panel/custo_fixo',
}

const getStaffing = async (staffingId) => {
    const query = new Parse.Query('Staffing').limit(1000).equalTo('objectId', staffingId);
    const staffingFound = await query.first();
    return staffingFound;
}

const calculateStaffingValueTotal = (staffingLocal, formRef) => {
    return ((staffingLocal && staffingLocal.get('total')) || 0) * (formRef.staffing_installments || 0);
}

const sheetCostTotal = (sheetCostArray, localStaffing = null) => {
    if (!localStaffing) localStaffing = staffing;
    return (sheetCostArray || []).reduce((previous, current) => {
        const total_unit = (((localStaffing && localStaffing.get('total')) || 0) * ((current.percent || 0) / 100) * (current.installments || 0));
        return previous + total_unit;
    }, 0);
}

const othersCostTotal = (othersCostArray) => {
    return (othersCostArray || []).reduce((previous, current) => {
        const total_unit = ((current.value_unit || 0) * (current.installments || 0));
        return previous + total_unit;
    }, 0);
}

export {
    getStaffing,
    calculateStaffingValueTotal,
    sheetCostTotal,
    othersCostTotal,
}