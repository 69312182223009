import React from "react";
import { calculatePQ12 } from "./input-providers-functions";

const pqAvaliationFields = (props) => {
    return [
        [
            {
                key: 'pq_avaliation',
                type: 'dynamic-input-list',
                fields: [
                    [
                        {
                            "title": "NF",
                            "key": `nf`,
                            "type": "text",
                            'colSize': 4,
                            "element-attr": { "required": false }
                        },
                        {
                            "title": "Cumprimento do prazo",
                            "key": `deadline`,
                            "type": "number",
                            'colSize': 5,
                            "element-attr": {
                                "required": false, step: 1, min: 0, max: 10,
                                // afterChange: (_) => {
                                //     calculatePQ12(_.state.value);
                                // }
                            }
                        },
                        {
                            "title": "Atendimento ao pedido",
                            "key": `attendance`,
                            "type": "number",
                            'colSize': 5,
                            "element-attr": {
                                "required": false, step: 1, min: 0, max: 10,
                                // afterChange: (_) => {
                                //     calculatePQ12(_.state.value);
                                // }
                            }
                        },
                        {
                            "title": "Qualidade",
                            "key": `quality`,
                            "type": "number",
                            'colSize': 5,
                            "element-attr": {
                                "required": false, step: 1, min: 0, max: 10,
                                // afterChange: (_) => {
                                //     calculatePQ12(_.state.value);
                                // }
                            }
                        },
                        {
                            "title": "Procedimento adm/financeiro",
                            "key": `adm`,
                            "type": "number",
                            'colSize': 5,
                            "element-attr": {
                                "required": false, step: 1, min: 0, max: 10,
                                // afterChange: (_) => {
                                //     calculatePQ12(_.state.value);
                                // }
                            }
                        }
                    ],
                ],
                "element-attr": { "required": true }
            }
        ],
        [
            {
                "title": "Nota Final",
                "type": "render",
                'colSize': 5,
                "render": (_, node, onChange, value) => {
                    let pqAvaliation = { grade: '0' }
                    if (node.state.formRef.pq_avaliation)
                        pqAvaliation = calculatePQ12(node.state.formRef.pq_avaliation)

                    return <div>{pqAvaliation.grade}</div>
                    // return (
                    //     <InputNumber
                    //         size="large"
                    //         placeholder='Nota Final'
                    //         value={pqAvaliation.grade}
                    //         disabled
                    //     />
                    // );
                }
            },
            {
                "title": "Performance",
                "type": "render",
                'colSize': 5,
                "render": (_, node, onChange, value) => {
                    let pqAvaliation = { performace: '' }
                    if (node.state.formRef.pq_avaliation)
                        pqAvaliation = calculatePQ12(node.state.formRef.pq_avaliation)
                    return <div>{pqAvaliation.performace}</div>
                    // return (
                    //     <Input
                    //         size="large"
                    //         placeholder='Performance'
                    //         value={performace}
                    //         disabled
                    //     />
                    // );
                }
            },
            {
                "title": "Status",
                "type": "render",
                'colSize': 5,
                "render": (_, node, onChange, value) => {
                    let pqAvaliation = { status: '' }
                    if (node.state.formRef.pq_avaliation)
                        pqAvaliation = calculatePQ12(node.state.formRef.pq_avaliation)
                    return <div>{pqAvaliation.status}</div>
                    // return (
                    //     <Input
                    //         size="large"
                    //         placeholder='Status'
                    //         value={status}
                    //         disabled
                    //     />
                    // );
                }
            },
        ],
    ]
}

export default pqAvaliationFields