import React from 'react'
import ProposalForm from './components/proposal-form.js'
import { Icon, Row } from 'antd'
import expandedContentComponent from './entities/expanded-content.jsx'
import { findAll } from '../../../utils/db_functions.js'

const getStatusColorSign = (status) => {
  let statusColor = 'orange';

  if (status === 'PedidoGerado') statusColor = 'blue'
  else if (status === 'Cancelada') statusColor = 'red'
  else if (status === 'Substituida') statusColor = 'black'

  return (
    <div
      style={{
        backgroundColor: statusColor,
        width: '20px', height: '20px',
        margin: 'auto', borderRadius: '50%',
      }}
    />
  )
}

let surveys = []

export default {
  fields: [],
  schema: [
    {
      title: "Proposta",
      key: "proposal_code",
      dataIndex: "proposal_code",
      align: 'center',
      width: '150px',
      type: "text",
      render: (_, proposal) => {
        let newProposalCode = proposal.proposal_code.slice(0, proposal.proposal_code.length - 3);
        return <strong>{newProposalCode} [{proposal.all_proposals.length}]</strong>
      }
    },
    {
      title: "Nome Cliente",
      key: "name_client",
      dataIndex: "name_client",
      align: 'center',
      type: "render",
      render: (v, row) => {
        if (row.is_company) return row.social_reason
        return v
      }
    },
    {
      title: "Nome da obra",
      key: "name_work",
      dataIndex: "name_work",
      align: 'center',
      type: "text",
    },
    {
      title: "Cidade",
      key: "city_work",
      dataIndex: "city_work",
      align: 'center',
      type: "text",
    },
    {
      title: "Kits",
      key: "kits",
      dataIndex: "all_proposals",
      align: "center",
      width: '70px',
      render: (proposals) => {
        const kits = (proposals || []).reduce((prev, current) => {
          return (current.baseFinalPriceAndAmount && current.baseFinalPriceAndAmount.finalAmount) || prev
        }, null)
        return kits || "-"
      }
    },
    {
      title: "Valor",
      key: "Valor",
      dataIndex: "all_proposals",
      align: "center",
      width: '170px',
      render: (proposals) => {
        const value = (proposals || []).reduce((prev, current) => {
          return current.final_value || prev
        }, null)
        return (value && value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) || "-"
      }
    },
    {
      title: "E-mail",
      key: "email",
      dataIndex: "all_proposals",
      align: "center",
      width: '80px',
      render: (proposals) => {
        const proposal = (proposals || []).reduce((prev, current) => {
          return current || prev
        }, null)

        let color = 'red';

        if (proposal.historic && proposal.historic.length) {
          proposal.historic.forEach(comment => {
            if (
              comment.comment.includes(`${proposal.proposal_code} enviada para o e-mail`) ||
              comment.comment.includes(`${proposal.proposal_code} enviada para o cliente via whatsapp`)
            ) color = 'green';
          });
        };

        // Set to blue when generate a order without sent the proposal to client
        if (color === 'red' && proposal.orderGenerated) color = 'blue';

        return (
          <div
            style={{
              backgroundColor: color,
              width: '20px', height: '20px',
              margin: 'auto', borderRadius: '50%'
            }}
          />
        );
      }
    },
    {
      title: "Pesquisa",
      key: "survey",
      dataIndex: "proposal_code",
      align: "center",
      width: '80px',
      render: (proposal_code) => {
        const newProposalCode = proposal_code.slice(0, proposal_code.length - 3)
        const survey = surveys.find(el => el.get('proposal') === newProposalCode)
        let color = survey ? 'green' : 'red'

        return (
          <div
            style={{
              backgroundColor: color,
              width: '20px', height: '20px',
              margin: 'auto', borderRadius: '50%'
            }}
          />
        );
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "all_proposals",
      align: "center",
      width: '80px',
      render: (proposals) => {
        const status = (proposals || []).reduce((prev, current) => {
          return current.proposal_management_status || prev
        }, null)
        return getStatusColorSign(status)
      }
    },
    {
      title: "Data",
      key: "date",
      dataIndex: "all_proposals",
      align: "center",
      width: '100px',
      render: (proposals) => {
        const date = (proposals || []).reduce((prev, current) => {
          return current.updatedAt || prev
        }, null)
        return (date && new Date(date).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })) || "-"
      }
    },
    {
      title: "Alterado por",
      key: "updatedBy",
      dataIndex: "all_proposals",
      align: "center",
      width: '100px',
      render: (proposals) => {
        const updatedBy = (proposals || []).reduce((prev, current) => {
          return current.updatedBy || prev
        }, null)
        const user = users.filter(v => v.id === (updatedBy && updatedBy.objectId))
        return (<div>
          {user && user[0] ? user[0].get('name') : ''}
        </div>)
      }
    },
  ],
  tableFooter: () => {
    let emailArray = ['Enviado', 'Não enviado'];
    let statusArray = ['Em andamento', 'Proposta cancelada', 'Proposta substituida', 'Pedido gerado'];

    return (
      <div>

        <Row>
          <div style={{ clear: 'both', paddingBottom: 20 }}>
            <div style={{ float: 'left', marginRight: 10 }}>
              <b>E-mail: </b>
            </div>
            {
              emailArray.map(el => {
                let color = null

                switch (el) {
                  case 'Enviado':
                    color = 'green'
                    break

                  case 'Não enviado':
                    color = 'red'
                    break

                  default:
                    color = null
                }

                return (
                  <div style={{ float: 'left', marginRight: 30 }}>
                    <div
                      key={el}
                      style={{ float: 'left', backgroundColor: color, width: '15px', height: '15px', margin: 'auto', marginBottom: '5px', marginRight: 5, borderRadius: '50%' }}
                    /> {el}
                  </div>
                )
              })
            }
          </div>
        </Row>
        <Row>
          <div style={{ clear: 'both', paddingBottom: 20 }}>
            <div style={{ float: 'left', marginRight: 10 }}>
              <b>Status: </b>
            </div>
            {
              statusArray.map(el => {
                let color = null

                switch (el) {
                  case 'Em andamento':
                    color = 'orange'
                    break

                  case 'Proposta cancelada':
                    color = 'red'
                    break

                  case 'Proposta substituida':
                    color = 'black'
                    break

                  case 'Pedido gerado':
                    color = 'blue'
                    break

                  default:
                    color = null
                }

                return (
                  <div style={{ float: 'left', marginRight: 30 }}>
                    <div
                      key={el}
                      style={{ float: 'left', backgroundColor: color, width: '15px', height: '15px', margin: 'auto', marginBottom: '5px', marginRight: 5, borderRadius: '50%' }}
                    /> {el}
                  </div>
                )
              })
            }

          </div>
        </Row>
      </div>
    )
  },
  ListWillMount: async (_) => {
    users = await findAll('User')
    surveys = await findAll('CommercialSurvey', 10000)
    _.forceUpdate()
  },
  "title-module": "Propostas",
  "title-navigation": <div><Icon type="file-ppt" /> Proposta</div>,
  "module": "Proposal",
  "submit": { collection: "Proposal" },
  "actionsTable": false,
  "defaultCurrentPage": 'last',
  "sortBy": 'updatedAt',
  "isManagementProposal": true,
  "router-base": "/panel/proposta",
  EntireFormComponent: (Module, routerProps) => {
    return (
      <div>
        <ProposalForm module={Module} routerProps={routerProps} />
      </div>
    )
  },
  expandedRowRender: parentProposal => expandedContentComponent(parentProposal, users, surveys),
  hideCopyBtn: true
}

let users = []