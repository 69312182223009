import React from "react";
import { Icon } from "antd";
import Module from "../module/Module";
import Parse from "parse";

/**
 * Arquivo de módulos configurados.
 */
// Módulo Comercial
import QualificationSurveyModule from "../../modules/commercial/qualification-survey/qualification-survey";
import ProposalRequestModule from "../../modules/commercial/proposal-request/propostal-request.js";
import ProposalModule from "../../modules/commercial/proposal/proposal.js";
import ProposalManagementModule from "../../modules/commercial/manage-proposal/manage-proposal.js";
import PriceListModule from "../../modules/commercial/price-list/price-list";
import ClientsModule from "../../modules/commercial/clients/clients";
import TradeRepresentativeModule from "../../modules/commercial/trade-representative/trade-representative";
import CustomerSatisfactionSurveyModule from "../../modules/commercial/satisfaction-survey/satisfaction-survey";
import CommercialManagementModule from "../../modules/commercial/commercial-management/commercial-management.js";
import OrderModule from "../../modules/commercial/order/order";

// Módulo Gestão do Pedido
import OrderMapModule from "../../modules/order-manager/order-map/order-map";
import OrderFolderModule from "../../modules/order-manager/client-folder/client-folder";

// Módulo Projetos

// Módulo Suprimentos
import InputProviderGroupModule from "../../modules/supplies/providers-groups/input-provider-group.js";
import InputProviderModule from "../../modules/supplies/providers/input-provider.js";
import InputModule from "../../modules/supplies/inputs/inputs.js";
import InputReportsModule from "../../modules/supplies/input-report/input-reports";
import InputClientReportModule from "../../modules/supplies/input-client-report/input-client-report";
import InputOrderPriceQuoteModule from "../../modules/supplies/input-order-price-quote/input-order-price-quote";
import InputOrderModule from "../../modules/supplies/input-order/input-order";
import InputOrderReportModule from "../../modules/supplies/input-order-reports/input-order-report";
import InputQualityAssessmentModule from "../../modules/supplies/input-quality-assessment/input-quality-assessment";

// Módulo Industrial
import MeasuringEquipamentModule from "../../modules/industrial/industrial-maintenence/measuring-equipment/measuring-equipament";
import MachinesAndEquipamentModule from "../../modules/industrial/industrial-maintenence/machines-and-equipment/machines-and-equipament";
import IndustrialFacilitiesModule from "../../modules/industrial/industrial-maintenence/industrial-facilities/industrial-facilities";

// Módulo Service
import TechDocsCustomerSuportModule from "../../modules/service/customer-suport/customer-suport";
import PcpoSatisfactionSurveyModule from "../../modules/service/satisfaction-survey/pcpo/satisfaction-survey";

// Módulo E-learning
import TechDocsAccessibilityModule from "../../modules/e-learning/accessibility/accessibility";
import TechDocsAcousticsModule from "../../modules/e-learning/acoustics/acoustics";
import TechDocsCertificatesModule from "../../modules/e-learning/certificates/certificates";
import TechDocsCteRcdiEnredesModule from "../../modules/e-learning/cte-rcdi-enredes/cte-rcdi-enredes";
import TechDocsMarketingModule from "../../modules/e-learning/marketing/marketing";
import TechDocsMultidoorModule from "../../modules/e-learning/multidoor/multidoor";
import TechDocsPerformanceStandardsModule from "../../modules/e-learning/performance-standards/performance-standards";
import TechDocsTechManualsModule from "../../modules/e-learning/tech-manuals/tech-manuals";
import TechDocsTechStandardsModule from "../../modules/e-learning/tech-standards/tech-standards";
import TechDocsWoodModule from "../../modules/e-learning/wood/wood";

// Módulo Componentes e Kits
import ComponentKitModule from "../../modules/components-and-kits/component-kit/component-kit";
import KitModule from "../../modules/components-and-kits/kit-datasheet/kit";
import MountingKitModule from "../../modules/components-and-kits/mounting-kit/mounting-kit";
import InstalationKitModule from "../../modules/components-and-kits/instalation-kit/instalation-kit";
import MatrizFechModule from "../../modules/components-and-kits/matriz-fech/matriz-fech";
import MatrizDoorLocksModule from "../../modules/components-and-kits/matriz-door-locks/matriz-door-locks";
import StaffingModule from "../../modules/components-and-kits/fixed-cost/staffing/staffing";
import FixedCostModule from "../../modules/components-and-kits/fixed-cost/fixed-cost/fixed-cost";
import ManHourModule from "../../modules/components-and-kits/fixed-cost/man-hour/man-hour";
import MatrizDimensionModule from "../../modules/components-and-kits/matriz-dimensions/matriz-dimension";
import CollectionModule from "../../modules/components-and-kits/finishing/collections/collections";
import PatternColorsModule from "../../modules/components-and-kits/finishing/colors-patterns/colors-patterns";
import FinishingModule from "../../modules/components-and-kits/finishing/finishings/finishings";
import MatrizFinishingModule from "../../modules/components-and-kits/finishing/matriz-finishing/matriz-finishing";
import MatrizAlizModule from "../../modules/components-and-kits/matriz-alizar/matriz-alizar";
import MatrizPanelModule from "../../modules/components-and-kits/matriz-panel/matriz-panel";
import MatrizMarcoThickness from "../../modules/components-and-kits/matriz-marco-thickness/matriz-marco-thickness";
import ChecklistConfigsModule from "../../modules/components-and-kits/checklist-specification/checklist-configs";
import comparativeTableModule from "../../modules/components-and-kits/comparative-table/comparative-table";
import RegionModule from "../../modules/components-and-kits/transportation-and-logistics/transportation-and-logistics.js";
import SalesChannelModule from "../../modules/components-and-kits/sales-channel/sales-channel";

// Módulo Configurações
import ConfigsModule from "../../modules/configuration/key-value/key-value.js";
import InstalationModule from "../../modules/configuration/instalation/instalation/instalation.js";
import OccupationModule from "../../modules/configuration/performance/occupation/occupation.js";
import TypeWorkModule from "../../modules/configuration/instalation/instalation-type/instalation-type.js";
import MobilityWorkModule from "../../modules/configuration/instalation/mobility-work/mobility-work.js";
import TechManagerModule from "../../modules/configuration/instalation/tech-manager/tech-manager.js";
import TechAssistanceModule from "../../modules/configuration/instalation/tech-assistance/tech-assistance.js";
import TeamMobModule from "../../modules/configuration/instalation/team-mob/team-mob.js";
import WorkStageModule from "../../modules/configuration/comercial/work-stage/work-stage";
import BillingCompanyModule from "../../modules/configuration/comercial/billing-companies/billing-companies";
import PrevisionBuyModule from "../../modules/configuration/comercial/previstion-buy/previstion-buy";
import ProviderModule from "../../modules/configuration/comercial/provider/provider";
import PerformanceModule from "../../modules/configuration/performance/performance/performance";
import DiscountsConfigsModule from "../../modules/configuration/configurators/discount/discounts";
import DoorDisplayConfigsModule from "../../modules/configuration/configurators/door-display/door-display";
import FixedObservationConfigsModule from "../../modules/configuration/configurators/fixed-observation/fixed-observation";
import ArchitecturalServicesModule from "../../modules/configuration/configurators/architectural-services/architectural-services";
import ComponentKitImageConfigsModule from "../../modules/configuration/configurators/component-kit-image/component-kit-image";
import TeoricMassConfigsModule from "../../modules/configuration/configurators/teoric-mass/teoric-mass";
import ConvertionConfigsModule from "../../modules/configuration/configurators/convertion/convertion";
import WidthAlizModule from "../../modules/OLDs/width_aliz";

// Módulo Usuários
import FormUsuario from "../../modules/users/manage-users/manage-user.js";
import UserGroupModule from "../../modules/users/user-groups/user-group.js";

// Módulo ABNT IPT
import AbntIptDocsModule from "../../modules/shared/abnt-ipt-docs-module";

/**
 * Renderiza o módulo Usuário.
 * @param {*} props Configurações do módulo: Schema e Form.
 * @returns Módulo Usuário.
 */
let UserModule = (props) => {
  return (
    <Module
      collection={Parse.User}
      schema={FormUsuario.schema}
      permissions={[]}
      actionsTable={true}
      title={
        <div>
          <Icon type="user" /> Usuário
        </div>
      }
      form={FormUsuario}
      url_props={props}
    />
  );
};

/**
 * Renderiza o módulo.
 * @param {*} configs Configurações do módulo: Schema, Form, Pág padrão da lista, filtro e ordenação.
 * @returns Módulo.
 */
let RenderModule = (configs) => {
  return (props) => (
    <Module
      collection={configs.submit.collection}
      schema={configs.schema}
      permissions={[]}
      actionsTable={
        configs["actionsTable"] !== undefined ? configs["actionsTable"] : true
      }
      title={configs["title-navigation"]}
      form={configs}
      url_props={props}
      defaultCurrentPage={configs.defaultCurrentPage} // Página da lista a ser exibida inicialmente
      filterByUser={configs.filterByUser} // Filtrar resultados por usuário que editou o cadastro
      filterList={configs.filterList} // Filtrar resultados por usuário que editou o cadastro
      sortBy={configs.sortBy} // Ordenar lista por campo
      isManagementProposal={configs.isManagementProposal} // Atributo utilizado apenas no módulo Gerenciar Proposta
    />
  );
};

export {
  // Módulo Comercial
  QualificationSurveyModule,
  ProposalRequestModule,
  ProposalModule,
  ProposalManagementModule,
  PriceListModule,
  OrderModule,
  OrderMapModule,
  ClientsModule,
  TradeRepresentativeModule,
  CustomerSatisfactionSurveyModule,
  CommercialManagementModule,
  // Módulo Projetos
  OrderFolderModule,
  // Módulo Suprimentos
  InputProviderGroupModule,
  InputProviderModule,
  InputModule,
  InputReportsModule,
  InputClientReportModule,
  InputOrderPriceQuoteModule,
  InputOrderModule,
  InputOrderReportModule,
  InputQualityAssessmentModule,
  // Módulo Industrial
  MeasuringEquipamentModule,
  MachinesAndEquipamentModule,
  IndustrialFacilitiesModule,
  // Módulo Service
  TechDocsCustomerSuportModule,
  PcpoSatisfactionSurveyModule,
  // Módulo Documentos Técnicos
  TechDocsAccessibilityModule,
  TechDocsAcousticsModule,
  TechDocsCertificatesModule,
  TechDocsCteRcdiEnredesModule,
  TechDocsMarketingModule,
  TechDocsMultidoorModule,
  TechDocsPerformanceStandardsModule,
  TechDocsTechManualsModule,
  TechDocsTechStandardsModule,
  TechDocsWoodModule,
  // Módulo Componentes e Kits
  ComponentKitModule,
  KitModule,
  MountingKitModule,
  InstalationKitModule,
  MatrizFechModule,
  MatrizDoorLocksModule,
  StaffingModule,
  FixedCostModule,
  ManHourModule,
  MatrizDimensionModule,
  CollectionModule,
  PatternColorsModule,
  FinishingModule,
  MatrizFinishingModule,
  MatrizAlizModule,
  MatrizPanelModule,
  MatrizMarcoThickness,
  ChecklistConfigsModule,
  comparativeTableModule,
  RegionModule,
  SalesChannelModule,
  // Módulo Configurações
  ConfigsModule,
  InstalationModule,
  OccupationModule,
  TypeWorkModule,
  MobilityWorkModule,
  TechManagerModule,
  TechAssistanceModule,
  TeamMobModule,
  WorkStageModule,
  BillingCompanyModule,
  PrevisionBuyModule,
  ProviderModule,
  PerformanceModule,
  DiscountsConfigsModule,
  DoorDisplayConfigsModule,
  FixedObservationConfigsModule,
  ArchitecturalServicesModule,
  ComponentKitImageConfigsModule,
  TeoricMassConfigsModule,
  ConvertionConfigsModule,
  WidthAlizModule,
  // Módulo Usuários
  UserModule,
  UserGroupModule,
  // Módulo ABNT IPT
  AbntIptDocsModule,
  RenderModule,
};
