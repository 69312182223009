import { Col, Divider, Form, Input, Row, Select, Spin, Switch } from 'antd'
import React, { Component } from 'react'
import { findAll, findWhere } from '../../../../../utils/db_functions'
import ContactsFields from '../contacts-fields'

export default class InstalationStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            instalationData: [],
            typeWorkData: [],
            mobilityWorkData: [],
            techManagerData: [],
            techAssistanceData: [],
            teamMobData: []
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const instalationData = this.props.dataConfigs['instalation-instalationData'] || await findAll('Instalation')
        const typeWorkData = this.props.dataConfigs['instalation-typeWorkData'] || await findAll('TypeWork')
        const mobilityWorkData = this.props.dataConfigs['instalation-mobilityWorkData'] || await findAll('MobilityWork')
        const techManagerData = this.props.dataConfigs['instalation-techManagerData'] || await findAll('TechManager')
        const techAssistanceData = this.props.dataConfigs['instalation-techAssistanceData'] || await findAll('TeachAssistance')
        const teamMobData = this.props.dataConfigs['instalation-teamMobData'] || await findAll('TeamMob')

        const dataConfigsArray = await findWhere('Config', 'name', ['proposal_form_notes_instalação'])
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {})

        this.setState({
            loading: false, instalationData, typeWorkData, mobilityWorkData,
            techManagerData, techAssistanceData, teamMobData, dataConfigs
        })

        this.props.setDataConfigs({
            'instalation-instalationData': instalationData, 'instalation-typeWorkData': typeWorkData,
            'instalation-mobilityWorkData': mobilityWorkData, 'instalation-techManagerData': techManagerData,
            'instalation-techAssistanceData': techAssistanceData, 'instalation-teamMobData': teamMobData,
        })
    }

    getInstalation() {
        return (this.state.instalationData || []).find(val => val.id === this.props.getValue('instalation')) || null
    }

    verifyInstalation() {
        let instalation = this.getInstalation()
        return !(instalation && instalation.get('name').includes('Multidoor Service'))
    }

    render() {
        return (
            <div style={{ paddingTop: '40px' }}>
                <Spin spinning={this.state.loading} size='large' tip='Carregando as informações...'>
                    <Row gutter={24}>
                        <Col span={16}>
                            <h2>Cadastro de Instalação Multidoor Service</h2>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Tipo de Instalação">
                                        <Select disabled={this.verifyInstalation() || this.props.disabledFields} value={this.props.getValue('type_work')} onChange={value => this.props.updateDataValue('type_work', value)} placeholder="Tipo de Instalação" size="large">
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.typeWorkData || [])
                                                // Filtrar os tipos de instalações do select de acordo com a instalação escolhida
                                                .filter(v => {
                                                    let instalation = (this.state.instalationData || []).find(val => val.id === this.props.getValue('instalation'))
                                                    if (instalation) {
                                                        if (instalation.get('name').includes('B2B') && v.get('name').includes('B2B')) return v

                                                        if (!instalation.get('name').includes('B2B') && !v.get('name').includes('B2B')) return v
                                                    }

                                                    return null
                                                })
                                                .map(ParseInstalation => {
                                                    return <Select.Option key={ParseInstalation.id} value={ParseInstalation.id}>{ParseInstalation.get('name')}</Select.Option>
                                                })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Mobilidade na Obra">
                                        <Select disabled={this.verifyInstalation() || this.props.disabledFields} value={this.props.getValue('mobility_work')} onChange={value => this.props.updateDataValue('mobility_work', value)} placeholder="Mobilidade na Obra" size="large">
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.mobilityWorkData || []).map(ParseInstalation => {
                                                return <Select.Option key={ParseInstalation.id} value={ParseInstalation.id}>{ParseInstalation.get('name')}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Responsável Técnico">
                                        <Select disabled={this.verifyInstalation() || this.props.disabledFields} value={this.props.getValue('tech_manager')} onChange={value => this.props.updateDataValue('tech_manager', value)} placeholder="Responsável Técnico" size="large">
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.techManagerData || []).map(ParseInstalation => {
                                                return <Select.Option key={ParseInstalation.id} value={ParseInstalation.id}>{ParseInstalation.get('name')}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Assistência Técnica">
                                        <Select disabled={this.verifyInstalation() || this.props.disabledFields} value={this.props.getValue('tech_assistance')} onChange={value => this.props.updateDataValue('tech_assistance', value)} placeholder="Assistência Técnica" size="large">
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.techAssistanceData || []).map(ParseInstalation => {
                                                return <Select.Option key={ParseInstalation.id} value={ParseInstalation.id}>{ParseInstalation.get('name')}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Mobilização Equipe">
                                        <Select disabled={this.verifyInstalation() || this.props.disabledFields} value={this.props.getValue('team_mob')} onChange={value => this.props.updateDataValue('team_mob', value)} placeholder="Mobilização Equipe" size="large">
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.teamMobData || []).map(ParseInstalation => {
                                                return <Select.Option key={ParseInstalation.id} value={ParseInstalation.id}>{ParseInstalation.get('name')}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Instalação NÃO inclusa no valor do KIT">
                                        <Switch
                                            checked={this.props.getValue('abridge_installation')}
                                            onChange={v => this.props.updateDataValue('abridge_installation', v)} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24} >
                                <Col span={24}>
                                    <Form.Item label="Observação">
                                        <Input.TextArea
                                            value={this.props.getValue('observation_instalation')}
                                            onChange={event => this.props.updateDataValue('observation_instalation', event.target.value)}
                                            placeholder="Observações"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <div className='note-box'>
                                <div className='note-title'>Instruções:</div>
                                <div>{
                                    ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_instalação) || [])
                                        .map(paragraph => <p>{paragraph}</p>)
                                }</div>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <h2>Contato de instalação</h2>

                    <ContactsFields
                        contactKey='instalation'
                        value={this.props.getValue('instalationContacts')}
                        updateContacts={value => this.props.updateDataValue('instalationContacts', value)}
                    />
                </Spin>
            </div>
        )
    }

}