import React, { Component } from "react";
import { Input, InputNumber, Button, Icon } from 'antd';
import ReactListInput from 'react-list-input'

/**
 * Classe responsável por criar uma lista de inputs editáveis.
 */
export default class EditableTagGroup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: [],
        }
        this.TypeInput = this.props.type === 'number' ? InputNumber : Input
    }

    componentWillReceiveProps(props) {
        this.setState(state => {
            return { value: props.value }
        })
    }

    /**
     * Renderiza a lista de inputs number ou text.
     * @param {ReactListInput} ReactListInput Pacote react-list-input. 
     * @returns Lista de inputs. 
     */
    Item({ decorateHandle, removable, onChange, onRemove, value }) {
        return (
            <div style={{ display: 'flex', paddingLeft: '10%', margin: "16px 0 " }}>
                <this.TypeInput
                    size="large"
                    value={value}
                    onChange={(event) => onChange(this.eventValue(event))}
                    {...this.props.elAttrs}
                />
                {decorateHandle(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Icon type="drag" size="large" style={{ cursor: 'move', fontSize: 18, margin: '0 16px' }} /></div>)}
                <Button
                    size="large"
                    onClick={removable ? onRemove : x => x}
                    style={{
                        cursor: removable ? 'pointer' : 'not-allowed',
                        color: removable ? 'black' : 'gray',
                        flex: 'none'
                    }}
                    icon="close"
                    shape="circle" />
            </div>
        )
    }

    /**
     * Adiciona input a lista.
     * @param {ReactListInput} ReactListInput Pacote react-list-input.
     * @returns Novo input. 
     */
    StagingItem({ value, onAdd, canAdd, add, onChange }) {
        return (
            <div style={{ display: 'flex' }}>
                <this.TypeInput style={{ marginRight: '16px' }}
                    value={value}
                    onChange={(event) => onChange(this.eventValue(event))}
                    placeholder="Novo item"
                    size="large"
                    onKeyPress={event => {
                        if (event.key !== 'Enter' || !event.target.value) return undefined

                        onAdd(event.target.value)
                        event.preventDefault()
                    }}
                    {...this.props.elAttrs}
                />
                <Button style={{ flex: 'none' }}
                    onClick={(canAdd && value) ? onAdd : undefined}
                    disabled={!canAdd}
                    icon="plus"
                    shape="circle"
                    size="large" />
            </div>
        )
    }

    /**
     * Inseri o valor do input no form.
     * @param {*} value Valor a ser inserido no form.
     */
    onChange(value) {
        this.setState(() => {
            return { value }
        }, () => {
            this.props.onChange(value)
        })
    }

    /**
     * Checa se o event do input traz o value direto ou através do target.
     * @param {event} event Evento retornado pelo onChange do input.
     * @returns Value inserido no input.
     */
    eventValue(event) {
        if (event === "") return ""
        if (typeof event === 'object')
            return event.target.value

        return isNaN(event) ? null : event
    }

    /**
     * Renderização do componente.
     */
    render() {
        return (
            <ReactListInput
                initialStagingValue=''
                onChange={value => this.onChange(value)}
                ItemComponent={this.Item.bind(this)}
                maxItems={1000}
                StagingComponent={this.StagingItem.bind(this)}
                value={this.state.value || []}
            />
        )
    }
}
