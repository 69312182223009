const stringFormulas = {
	'formula_1': '( largura / 1000 ) * ( espessura / 1000 ) * ( comprimento / 1000 ) * quantidade * conversão',
	'formula_2': '( ( largura + comprimento ) * 2 / 1000 ) * quantidade * conversão',
	'formula_3': '( ( largura + comprimento ) / 1000 ) * quantidade * conversão',
	'formula_4': 'quantidade * conversão',
	'formula_5': '( largura / 1000 ) * ( comprimento / 1000 ) * quantidade * conversão',
	'formula_6': ' comprimento * quantidade * conversão',
	'formula_7': '( comprimento / 1000) * quantidade * conversão',
}

const getUnityFormule = (unity, converted, formulas = null) => {

	if (formulas) {
		const stringFormula = getStringFormula(formulas, unity, converted)
		if (stringFormula) return convertStringFormulaToMathFunction(stringFormula)
	}

	// x = width, z = length, y = thickness
	switch (`${unity}->${converted}`) {
		case `m³->m³`:
		case 'm³->Kg/m²': // 1 -> m2 = ch/(x * z)
			return ({ amount = 1, width = 1, length = 1, convert = 1, thickness = 1 }) => (width / 1000) * (thickness / 1000) * (length / 1000) * amount * convert

		case 'm->m':
		case 'Kg->m':
			return ({ width = 0, length = 0, amount = 1, convert = 1 }) => ((width + length) * 2 / 1000) * amount * convert

		// case 'Kg->m': // 10 -> m=kg/((kg/m) * 6)
		// 	return ({ width = 1, length = 1, amount = 1, convert = 1 }) => (width + length) * amount * convert / 1000

		case 'Kg->Kg':
		case 'Kg/m²->Kg/m²':
		case 'Litro->Litro':
		case 'Litro/m²->Litro/m²':
		case 'Unidade->Unidade':
		case 'Chapa->Chapa':
		case 'Cento->Cento':
		case 'Par->Par':
		case 'Peça->Peça':
		case 'Conjunto->Conjunto':
		case 'Cartucho->Cartucho':
		case 'Bisnaga->Bisnaga':
		case 'Kit->Kit':
		case 'Cento->Unidade':
		case 'Mil->Unidade':
		case 'Unidade->Peça':
			return ({ amount = 1, convert = 1 }) => amount * convert

		default:
			return ({ amount = 1, width = 1, length = 1, convert = 1 }) => (width / 1000) * (length / 1000) * amount * convert

	}
}

const getTotalFormule = (unity, converted) => {
	switch (`${unity}->${converted}`) {
		case 'Cento->Unidade':
			return ({ amount, totalCost }) => totalCost / 100 * amount

		default:
			return ({ totalConsumo, totalCost }) => totalConsumo * totalCost
	}
}

const getStringFormula = (formulas, unity, converted) => {
	const convertion = formulas.filter(formula => {
		if (formula.get('units').find(el => el.unity === unity && el.converted === converted))
			return true
		return false
	})
	return convertion.length ? convertion[0].get('formula') : null
}

const convertStringFormulaToMathFunction = (formula) => {

	switch (formula) {
		case 'formula_1':
			return ({ amount = 1, width = 1, length = 1, convert = 1, thickness = 1 }) => (width / 1000) * (thickness / 1000) * (length / 1000) * amount * convert
		case 'formula_2':
			return ({ width = 0, length = 0, amount = 1, convert = 1 }) => ((width + length) * 2 / 1000) * amount * convert
		case 'formula_3':
			return ({ width = 0, length = 0, amount = 1, convert = 1 }) => ((width + length) / 1000) * amount * convert
		case 'formula_4':
			return ({ amount = 1, convert = 1 }) => amount * convert
		case 'formula_5':
			return ({ amount = 1, width = 1, length = 1, convert = 1 }) => (width / 1000) * (length / 1000) * amount * convert
		case 'formula_6':
			return ({ amount = 1, length = 1, convert = 1 }) => length * amount * convert
		case 'formula_7':
			return ({ amount = 1, length = 1, convert = 1 }) => (length / 1000) * amount * convert
		default:
			return null
	}
}

export {
	stringFormulas,
	getUnityFormule,
	getStringFormula,
	getTotalFormule
}