import React from 'react'
import { Icon } from 'antd'
export default {
	"fields": [
		[
			{
				"type": 'image',
				"key": 'image',
				"title": 'Imagem',
				'element-attr': { required: true }
			},
		],
		[
			{
				"title": "Descrição",
				"key": "description",
				"type": "text",
				"element-attr": {
					required: true,
				}
			},
		],
		[
			{
				"title": "Componentes",
				"key": "component_kit",
				"type": "relation",
				"options": [],
				"relation-select": {
					"class": "ComponenteKit",
					"label": row => `${row.product_code} - ${row.description_component}`,
					"orderBy": "product_code",
					"value": "objectId",
					"key": "objectId",
					searchFields: ['product_code', 'description_component'],
					where: {}
				},
				"element-attr": { "required": true, "mode": 'multiple' }
			},
		],
	],
	submit: {
		collection: "ComponentKitImageConfigs"
	},
	className: "config-component",
	schema: [
		{
			title: "Imagem",
			key: "image",
			dataIndex: "image",
			width: '80px',
			align: 'center',
			type: "render",
			render: e => {
				if (e) return <img src={e.url} alt='Imagem do componente' width={50} height={50} />
				return '';
			}
		},
		{
			title: "Descrição",
			key: "description",
			dataIndex: "description",
			type: "text",
		},
	],
	"title-navigation": <div><Icon type="file-image" /> Imagem dos Componentes</div>,
	"title-module": "Imagem do Componente",
	"module": "ComponentKitImageConfigs",
	"router-base": "/panel/imagem-componente-configs"
}