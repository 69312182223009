import React from 'react'
import { Icon, notification, Tag } from 'antd'
import Parse from 'parse'

export default {
  "fields": [
    [
      {
        "title": "Nome",
        "key": "name",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="mail" /> }
      },
      {
        "title": "E-mail",
        "key": "username",
        "type": "email",
        "element-attr": { "required": true, "prefix": <Icon type="tag-o" /> },
        beforeSave: async (Form, ParseObject, node) => {
          // Verificar se já foi cadastrado um usuário com o mesmo email
          const query = new Parse.Query('User')
          query.equalTo('username', Form.state.formRef.username)
          query.notEqualTo('objectId', ParseObject.id)
          const count = await query.count()
          if (count > 0) {
            notification.error({
              message: 'Erro ao cadastrar.',
              description: 'Esse email já está registrado.',
            })
            return false;
          }

          let pathArray = window.location.pathname.split('/')
          let lastElement = pathArray[pathArray.length - 1]
          if (lastElement === 'cadastrar') {
            if (!Form.state.formRef.password) {
              notification.error({
                message: `Erro ao cadastrar`,
                description: `Valor inválido no campo: Senha`,
                duration: 5,
              })
              return false
            } else if (!Form.state.formRef.confirmPassword) {
              notification.error({
                message: `Erro ao cadastrar`,
                description: `Valor inválido no campo: Confirmar Senha`,
                duration: 5
              })
              return false
            }
          }

          return true;
        }
      },
      {
        "title": "Imagem do perfil",
        "key": "photo",
        "type": "profile-image",
        "className": "upload-image-user",
        "element-attr": { "required": false }
      }
    ],
    [
      {
        "title": "Telefone",
        "key": "phone",
        "type": "text",
        "maskToValue": { phoneMask: true },
        colSize: 5,
        "element-attr": { "required": true, "prefix": <Icon type="phone" /> }
      },
      {
        "title": "Whatsapp",
        "key": "whatsapp",
        "type": "text",
        "maskToValue": { phoneMask: true },
        colSize: 5,
        "element-attr": { "required": true, "prefix": <Icon type="message" /> }
      },
      {
        title: 'Jornada do cliente',
        key: 'customerJourney',
        type: 'select',
        colSize: 6,
        options: [
          'Comercial B2B', 'Comercial B2C', 'Projetos BIM', 'Projeto Executivo',
          'Serviços B2B', 'Serviços B2C', 'Qualidade', 'Financeiro',
        ].map((step, stepIndex) => {
          return { label: step, value: `step${stepIndex}_${step}` }
        }),
        'element-attr': { required: false }
      },
    ],
    [
      {
        "title": "Apelido",
        "key": "nickname",
        "type": "text",
        colSize: 6,
        "element-attr": { "required": true, "prefix": <Icon type="tag-o" /> }
      },
      {
        "title": "Data de nascimento",
        "type": "date-picker",
        "key": "birth_date",
        colSize: 4,
        "element-attr": { "required": true }
      },
      {
        "type": "switch",
        "title": "Ativo",
        colSize: 2,
        "key": "active"
      },
      {
        "type": "switch",
        "title": "Consultor Comercial",
        colSize: 4,
        "key": "commercial_consultant"
      },
      {
        "type": "white-space"
      }
    ],
    [
      {
        "title": "Cargo",
        "key": "office",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="solution" /> }
      },
      {
        "title": "Grupos de Usuário",
        "key": "group",
        "type": "relation",
        "options": [],
        "relation-select": {
          "class": "Group",
          "label": "name",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['name']
        },
        "element-attr": { "required": true, mode: 'multiple' },
      },
      {
        "type": "white-space"
      }
    ],
    [
      {
        "title": "Senha",
        "key": "password",
        "type": "password",
        "confirmValue": "confirmPassword",
        "errorMessage": "Valor inválido no campo: Senha e Confirmar Senha devem ser iguais",
        "element-attr": { "prefix": <Icon type="lock" /> },
        "required": false
      },
      {
        "title": "Confirmar Senha",
        "key": "confirmPassword",
        // "type": "password",
        "type": "confirm-password",
        "confirmValue": "password",
        "errorMessage": "Valor inválido no campo: Senha e Confirmar Senha devem ser iguais",
        "element-attr": { "prefix": <Icon type="lock" /> },
        "required": false
      },
      {
        "type": "white-space"
      }
    ]
  ],
  "submit": {
    "collection": "User"
  },
  "className": "",
  "schema": [
    {
      "title": "Nome",
      "key": "name",
      "dataIndex": "name",
      "type": "text"
    },
    {
      "title": "Apelido",
      "key": "nickname",
      "dataIndex": "nickname",
      "type": "text"
    },
    {
      "title": "Login",
      "key": "username",
      "dataIndex": "username",
      "type": "text"
    },
    {
      "title": "Grupo",
      "key": "group",
      "dataIndex": "group",
      isRelation: true,
      type: "render",
      render: (v, row) => {
        let arrayOfGroups = []
        row._realResponse['r_group'].forEach(res => {
          arrayOfGroups.push(res.get('name'))
        })
        return arrayOfGroups.sort().map((p, i) => <Tag key={i}>{p}</Tag>)
      }
    },
    {
      title: 'Ativo',
      key: 'active',
      dataIndex: 'active',
      render: (v) => {
        const statusColor = v ? 'green' : 'red'
        return (
          <div
            style={{
              backgroundColor: statusColor,
              width: '20px', height: '20px',
              margin: 'auto', borderRadius: '50%',
            }}
          />
        )
      }
    }
  ],
  "title-module": "Usuários",
  "module": "User",
  "router-base": "/panel/usuarios",
  ParseEditableObject: (_, ParseObject) => {
    let user = ParseObject.toJSON()
    if ('password' in user) delete user.password
    return user;
  }
}
