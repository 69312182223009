const React = require("react");
const { default: ReactQuill } = require("react-quill");

const orderConditionsFields = (modules, formats) => [
    [
        {
            title: 'Condições Gerais Pedido - Título',
            key: 'orderConditionsTitle',
            type: 'text'
        }
    ],
    [
        {
            title: 'Condições Gerais Pedido - Texto',
            key: 'orderConditions',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.orderConditions || ''}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'orderConditions')
                        }} />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('orderConditions', module.state.formRef.orderConditions)
                return true;
            }

        }
    ],
    [
        {
            title: 'Condições Gerais Pedido (sem instalação) - Texto',
            key: 'orderConditionsWithoutInstalation',
            type: 'render',
            render: (state, module, node) => {
                return (
                    <ReactQuill
                        value={state.formRef.orderConditionsWithoutInstalation || ''}
                        modules={modules}
                        formats={formats}
                        onChange={value => {
                            module.updateRefValue(node, value, 'orderConditionsWithoutInstalation')
                        }} />
                )
            },
            beforeSave: (module, parseObject, node) => {
                parseObject.set('orderConditionsWithoutInstalation', module.state.formRef.orderConditionsWithoutInstalation)
                return true;
            }

        }
    ],
]

export default orderConditionsFields