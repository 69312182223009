import React from 'react';
import { Icon, Divider } from 'antd';
import { findAll } from '../../../utils/db_functions';

export default {
    "fields": [
        [
            {
                key: "cod_cliente",
                type: "render",
                colSize: 5,
                "render": (_, Form, node) => {
                    return (
                        <div style={{ fontSize: 'larger' }}>
                            <b>
                                Código: {Form.state.formRef.cod_cliente || ''}
                            </b>
                        </div>
                    )
                },
                beforeSave: async (Form, ParseObject, node) => {

                    // Generate a new code
                    let code = await generateCode();
                    if (!ParseObject.get('cod_cliente')) {
                        await ParseObject.set('cod_cliente', code);
                    }
                    return true;
                }
            }
        ],
        [
            {
                title: "Nome",
                key: "name_client",
                type: "text",
                colSize: 24,
                "element-attr": { required: true }
            }
        ],
        [
            {
                title: "CEP",
                key: "cep_client",
                type: "text",
                colSize: 6,
                "element-attr": { required: true }
            },
            {
                title: "Endereço",
                key: "address_client",
                type: "text",
                colSize: 12,
                "element-attr": { required: true }
            },
            {
                title: "Complemento",
                key: "complement_client",
                type: "text",
                colSize: 6,
                "element-attr": { required: false }
            },
        ],
        [
            {
                title: "Bairro",
                key: "neighborhood_client",
                type: "text",
                colSize: 4,
                "element-attr": { required: true }
            },
            {
                title: "Cidade",
                key: "city_client",
                type: "text",
                colSize: 4,
                "element-attr": { required: true }
            },
            {
                title: "Estado",
                key: "state_client",
                type: "text",
                colSize: 2,
                "element-attr": { required: true }
            },
            {
                title: "Site",
                key: "site_client",
                type: "text",
                colSize: 4,
                "element-attr": { required: false }
            },
            {
                title: "Instagram",
                key: "instagram_client",
                type: "text",
                colSize: 4,
                "element-attr": { required: false }
            },
            {
                title: "CNPJ Receita",
                key: "cnpj_receita",
                type: "document",
                colSize: 6,
                "element-attr": { required: false }
            },
        ],
        [
            {
                title: "Contato",
                key: "contact_client",
                type: "text",
                colSize: 6,
                "element-attr": { required: false }
            },
            {
                title: "Telefone",
                key: "phone_client",
                type: "text",
                colSize: 6,
                "element-attr": { required: false },
                maskToValue: { phoneMask: true }
            },
            {
                title: "E-mail",
                key: "email_client",
                type: "email",
                colSize: 6,
                "element-attr": { required: false }
            },
            {
                title: "Geolocalização",
                key: "coordinates",
                type: "text",
                colSize: 6,
                "element-attr": { required: false }
            },
        ],
        [
            {
                title: 'Pessoa Jurídica',
                key: 'is_company',
                type: 'switch',
                "element-attr": { required: false }
            }
        ],
        [
            {
                type: 'render',
                render: () => <Divider>Dados PJ</Divider>
            },
        ],
        [
            {
                title: 'CNPJ',
                key: 'cnpj',
                type: 'text',
                colSize: 6,
                maskToValue: { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true },
                "element-attr": { required: false }
            },
            {
                title: 'Inscrição Estadual',
                key: 'state_registration',
                type: 'text',
                colSize: 6,
                "element-attr": { required: false }
            },
            {
                title: 'Razão Social',
                key: 'social_reason',
                type: 'text',
                colSize: 12,
                "element-attr": { required: false }
            },
        ],
        [
            {
                type: 'render',
                render: () => <Divider>Dados PF</Divider>
            },
        ],
        [
            {
                title: 'CPF',
                key: 'cpf',
                type: 'text',
                colSize: 6,
                maskToValue: { mask: "xxx.xxx.xxx-xx", onlyNumber: true },
                "element-attr": { required: false }
            },
            {
                title: 'RG',
                key: 'rg',
                type: 'text',
                colSize: 6,
                "element-attr": { required: false }
            },
            {
                title: 'Nome da mãe',
                key: 'mother_name',
                type: 'text',
                colSize: 12,
                "element-attr": { required: false }
            },
        ],
    ],
    "submit": {
        "collection": "Client",
    },
    "schema": [
        {
            "title": "Código",
            "key": "cod_cliente",
            "dataIndex": "cod_cliente",
            "type": "text",
            "width": 150,
        },
        {
            "title": "Nome",
            "key": "name_client",
            "dataIndex": "name_client",
            "type": "text",
        },
        {
            "title": "Cidade",
            "key": "city_client",
            "dataIndex": "city_client",
            "type": "text",
            "width": 150,
        },
        {
            "title": "Estado",
            "key": "state_client",
            "dataIndex": "state_client",
            "type": "text",
            "width": 100,
            "align": "center",
        },
    ],
    "title-module": "Clientes",
    "title-navigation": <div><Icon type="idcard" /> Clientes</div>,
    "module": "Client",
    "router-base": "/panel/clientes"
}

/**
 * Generate a new Code before save.
 */
async function generateCode() {
    const clients = await findAll('Client', null, null, null, 'cod_cliente', 'descending')

    const lastClient = clients[0];

    const code = parseInt(lastClient.get('cod_cliente'), 10) + 1;

    const zeros = 5 - code.toString().length;

    const codeString = '0'.repeat(zeros) + code;

    return codeString;
}