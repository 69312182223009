import { findWhere, save } from "../../../../utils/db_functions"

const { Modal, notification } = require("antd")

// Função para deletar quadro de soluções do banco
const deleteSolutionSquare = (proposal, solutionSquare) => {
    Modal.confirm({
        title: 'Tem certeza que deseja exlcuir esse quadro?',
        content: 'Essa ação não poderá ser desfeita',
        onOk: async () => {
            for (let proposalIndex = 0; proposalIndex < proposal.all_proposals.length; proposalIndex++) {
                const propos = proposal.all_proposals[proposalIndex]

                if (propos.solutionsSquareIn) {
                    for (let solutionSquareIndex = 0; solutionSquareIndex < propos.solutionsSquareIn.length; solutionSquareIndex++) {

                        const sq = propos.solutionsSquareIn[solutionSquareIndex];
                        if (sq.code === solutionSquare.code) {
                            let solutionSquareToPush = propos.solutionsSquareIn
                            solutionSquareToPush.splice(solutionSquareIndex, 1)

                            const proposalToUpdate = await findWhere('Proposal', 'proposal_code', [propos.proposal_code])
                            await save('Proposal', { 'solutionsSquareIn': solutionSquareToPush }, proposalToUpdate[0].id)
                        }
                    }
                }

            }

            notification.success({
                message: 'Quadro excluído com sucesso',
                description: 'Atualize a página para verificar a atualização'
            })
        }
    })
}

export {
    deleteSolutionSquare,
}