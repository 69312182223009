import React from 'react'
import { Icon } from 'antd'
export default {
	"fields": [
		[
			{
				"title": "Nome",
				"key": "name",
				"type": "text",
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				}
			},
			{
				"title": "Valor",
				"key": "value",
				"type": "number",
				"element-attr": {
					required: false,
					prefix: <Icon type="tags" />,
					min: 0,
					max: 200
				}
			}
		]
	],
	submit: {
		collection: "TypeWork"
	},
	className: "config-component",
	schema: [
		{
			title: "Nome",
			key: "name",
			dataIndex: "name",
			type: "text",
			className: "name"
		},
		{
			title: "Valor",
			key: 'value',
			dataIndex: 'value',
			type: "render",
			render: r => `${r}%`
		}
	],
	"title-navigation": <div><Icon type="tool" /> Tipo de Instalação</div>,
	"title-module": "Tipo de Instalação",
	"module": "TypeWork",
	"router-base": "/panel/tipo-obra"
}