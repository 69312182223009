import React from 'react'
import { Icon } from 'antd'

export default {
    "fields": [
        [
            {
                "title": "Nome",
                "key": "name",
                "type": "text",
                "element-attr": { required: true }
            },
            {
                "title": "CNPJ",
                "key": "cnpj",
                "type": "text",
                "element-attr": { required: true },
                maskToValue: { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true }
            },
        ]
    ],
    submit: {
        collection: "BillingCompany"
    },
    schema: [
        {
            title: "Nome",
            key: "name",
            dataIndex: "name",
            type: "text",
        },
        {
            title: "CNPJ",
            key: "cnpj",
            dataIndex: "cnpj",
            type: "text",
        },
    ],
    "title-navigation": <div><Icon type="idcard" /> Empresa para faturamento</div>,
    "title-module": "Empresa para faturamento",
    "module": "BillingCompany",
    "router-base": "/panel/empresa-faturamento"
}