const { convertVisualizationType } = require("./general_functions");

let defaultPaymentConditions = (kitsAmount) => {
  let lotes = Math.round(kitsAmount / 100);
  if (!lotes) lotes = 1;

  const expectedDate = new Date();
  const accomplishedDate = new Date();

  return [
    {
      key: "1",
      weeks: `1`,
      activity: "Formatar Pedido Técnico- PTC",
      responsibility: "Multidoor",
      kits: kitsAmount,
      lote: `1${lotes > 1 ? ` à  ${lotes}` : ""}`,
      expected_date: expectedDate.setDate(expectedDate.getDate() + 7),
      accomplished_date: accomplishedDate.setDate(expectedDate.getDate() + 6),
    },
    {
      key: "2",
      weeks: `2`,
      activity: "Preencher Pedido Técnico",
      responsibility: "Cliente",
      kits: kitsAmount,
      lote: `1${lotes > 1 ? ` à  ${lotes}` : ""}`,
      expected_date: expectedDate.setDate(expectedDate.getDate() + 7),
      accomplished_date: accomplishedDate.setDate(expectedDate.getDate() + 6),
    },
    {
      key: "3",
      weeks: `3`,
      activity: "Liberar Projeto Executivo - PE e processo de Compras",
      responsibility: "Multidoor",
      kits: kitsAmount,
      lote: `1${lotes > 1 ? ` à  ${lotes}` : ""}`,
      expected_date: expectedDate.setDate(expectedDate.getDate() + 7),
      accomplished_date: accomplishedDate.setDate(
        accomplishedDate.getDate() + (lotes > 1 ? lotes * 7 : 7)
      ),
    },
    {
      key: "4",
      weeks: `4${lotes > 1 ? ` | ${3 + lotes}` : ""}`,
      activity: "Fabricar componentes do kit",
      responsibility: "Multidoor",
      kits: kitsAmount,
      lote: `1${lotes > 1 ? ` à  ${lotes}` : ""}`,
      expected_date: expectedDate.setDate(
        expectedDate.getDate() + (lotes > 1 ? lotes * 7 : 7)
      ),
      accomplished_date: accomplishedDate.setDate(
        accomplishedDate.getDate() + (lotes > 1 ? lotes * 7 : 7)
      ),
    },
    {
      key: "5",
      weeks: `${lotes > 1 ? `${3 + lotes + 1} | ${3 + 2 * lotes}` : "5"}`,
      activity: "Montar kits porta pronta",
      responsibility: "Multidoor",
      kits: kitsAmount,
      lote: `1${lotes > 1 ? ` à  ${lotes}` : ""}`,
      expected_date: expectedDate.setDate(
        expectedDate.getDate() + (lotes > 1 ? lotes * 7 : 7)
      ),
      accomplished_date: accomplishedDate.setDate(
        accomplishedDate.getDate() + (lotes > 1 ? lotes * 7 : 7)
      ),
    },
  ];
};

let defaultOrderDocsTypes = [
  {
    path: "/panel/documentos-tecnicos-marketing-vendas",
    label: "Marketing & Vendas",
    filterType: "marketing",
  },
  {
    path: "/panel/documentos-tecnicos-certificados-laudos",
    label: "Certificados & Laudos",
    filterType: "certificates",
  },
  {
    path: "/panel/documentos-tecnicos-manuais-tecnicos",
    label: "Manuais Técnicos",
    filterType: "tech_manuals",
  },
  {
    path: "/panel/documentos-tecnicos-normas-tecnicas",
    label: "Normas Técnicas",
    filterType: "tech_standards",
  },
  {
    path: "/panel/documentos-tecnicos-produtos-multidoor",
    label: "Produtos Multidoor",
    filterType: "multidoor",
  },
  {
    path: "/panel/documentos-tecnicos-norma-desempenho",
    label: "Norma de Desempenho",
    filterType: "performance_standards",
  },
  {
    path: "/panel/documentos-tecnicos-madeira",
    label: "Madeira",
    filterType: "wood",
  },
  {
    path: "/panel/documentos-tecnicos-acustica",
    label: "Acústica",
    filterType: "acoustics",
  },
  {
    path: "/panel/documentos-tecnicos-acessibilidade",
    label: "Acessibilidade",
    filterType: "accessibility",
  },
  {
    path: "/panel/documentos-tecnicos-cte-rcdi-enredes",
    label: "CTE-ENREDES",
    filterType: "cte_rcdi_enredes",
  },
];

const defaultMarcoMaterials = [
  { value: "Madeira", label: "Madeira" },
  { value: "material_1", label: "Aço Galvanizado" },
  { value: "material_2", label: "Aço Inox 304" },
  { value: "material_3", label: "Alumínio" },
  { value: "material_4", label: "PVCWood" },
];

const defaultProposalViewOptions = [
  { value: "Preço Final", label: convertVisualizationType("Preço Final") },
  {
    value: "Detalhado Blocos",
    label: convertVisualizationType("Detalhado Blocos"),
  },
  { value: "Detalhado", label: convertVisualizationType("Detalhado") },
  { value: "Simplificada", label: convertVisualizationType("Simplificada") },
  {
    value: "Cotação múltipla",
    label: convertVisualizationType("Cotação múltipla"),
  },
  { value: "Especificação", label: convertVisualizationType("Especificação") },
];

module.exports = {
  defaultPaymentConditions,
  defaultOrderDocsTypes,
  defaultMarcoMaterials,
  defaultProposalViewOptions,
};
