import React from 'react';
import { Icon } from 'antd';
import '../../../styles/config.css';
import Parse from 'parse';

export default {
  "fields": [
    [
      {
        "title": "Grupo de usuários",
        "key": "group",
        type: "pointer",
        options: [],
        "relation-select": {
          "class": "Group",
          "label": "name",
          "value": "objectId",
          "key": "objectId"
        },
        "element-attr": { "required": true, "mode": 'default' },
        colSize: 8,
      },
      {
        title: 'Limite desconto comercial (%)',
        key: 'commercial_limit',
        type: 'number',
        "element-attr": { "required": false, "min": 0, "max": 100 },
        colSize: 8,
      },
      {
        title: 'Limite desconto financeiro (R$)',
        key: 'financial_limit',
        type: 'money',
        "element-attr": { "required": false, "min": 0 },
        colSize: 8,
      }
    ],
  ],
  "submit": {
    "collection": "DiscountsConfigs"
  },
  "className": "config-component",
  ListWillMount: async () => {
    let group_query = new Parse.Query('Group');
    await group_query.find();
  },
  "schema": [
    {
      "title": "Grupo de usuários",
      "key": "group",
      "dataIndex": "group",
      "type": "text",
      render: (_, row) => _.name
    },
    {
      "title": "Limite desconto comercial (%)",
      "key": "commercial_limit",
      "dataIndex": "commercial_limit",
      "type": "text",
      render: (_, row) => `${_}%`,
      width: 150
    },
    {
      "title": "Limite desconto financeiro (R$)",
      "key": "financial_limit",
      "dataIndex": "financial_limit",
      "type": "text",
      render: (_, row) => `R$ ${_.toFixed(2)}`,
      width: 150
    },
  ],
  "title-module": "Descontos",
  "title-navigation": <div><Icon type="dollar" /> Descontos</div>,
  "module": "DiscountsConfigs",
  "router-base": "/panel/descontos-configs"
}

