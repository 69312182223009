import { Button, Checkbox, Input, message, Modal, notification, Popover } from 'antd'
import Auth from '../../../../auth/Auth'
import React, { Component } from 'react'
import { findById, findWhere, getCurrentUser, save } from '../../../../utils/db_functions'
import { CreatePhoneMask } from '../../../../utils/mask'
import Parse from 'parse'

export default class EmailModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            emailModal: false,
            email: props.email,
            whatsapp: null,
            sendChecklist: false,
            sendToRepresentative: false,
            proposalName: props.proposalName,
            keyWord: props.keyWord,
            proposal_code: props.proposal_code,
            proposalId: props.proposalId,
            historic: props.historic,
            loading: false,
            representative_email: null,
            complementMessage: '',
            sendSurvey: false,
        }
    }

    componentDidMount = async () => {
        const tradeRepresentative = await findWhere('TradeRepresentative', 'objectId', [this.props.representative])
        this.setState({ representative_email: (tradeRepresentative[0] && tradeRepresentative[0].get('consult_repre_external')) ? tradeRepresentative[0].get('consult_repre_email') : null })
    }

    generate_message = (complementMessage, proposalName, sendToRepresentative) => {
        if (complementMessage) complementMessage = `<p>${complementMessage}</p>`;

        let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
        serverUrlArray.pop();
        const serverUrl = serverUrlArray.join('/');

        let unblockedProposalPdf = null
        if (sendToRepresentative) {
            const proposal = this.props.proposal
            const lastUpdatedDate = new Date(proposal.updatedAt);
            const changeNameDate = new Date(2020, 8, 19);

            unblockedProposalPdf = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
            if (lastUpdatedDate > changeNameDate) unblockedProposalPdf = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');
        }

        let proposalLink = !unblockedProposalPdf ?
            `<p><a href='${serverUrl}/api/proposals/download-proposal/${proposalName}?versionSurvey=${this.state.sendSurvey ? 'true' : ''}'>Baixar Proposta de Engenharia de Valor</a></p>` :
            `<p><a href='${unblockedProposalPdf}'>Baixar Proposta de Engenharia de Valor</a></p>`

        if (this.state.sendChecklist) {
            return (
                `
          <p>Prezado cliente,</p>
          
          <p>Você está recebendo uma <strong>Proposta de Engenharia de Valor</strong> para as portas de madeira do seu empreendimento juntamente com um <strong>Checklist de portas</strong> para um comparativo normativo das especificações dos fornecedores. A senha de acesso para visualizar a proposta são os 5 primeiros números do CNPJ/CPF.</p>
        
          ${proposalLink}
                
          <p>Link do checklist: <a href='http://minhamultidoor.com.br/checklist'>Clique aqui</a> para acessar.</p>
          <p>Senha do checklist: ${this.state.proposalId}</p>
          
          ${complementMessage}

          <p>Estamos à disposição para maiores esclarecimentos e eventual revisão na Proposta visando o fechamento do Pedido.</p>
    
          <p>Atenciosamente,</p>
    
          <p><strong>Equipe Multidoor</strong></p>
        `
            )
        } else {
            return (
                `
          <p>Prezado cliente,</p>
          
          <p>Você está recebendo uma <strong>Proposta de Engenharia de Valor</strong> para as portas de madeira do seu empreendimento. A senha de acesso para visualizar a proposta são os 5 primeiros números do CNPJ/CPF.</p>

          ${proposalLink}
          
          ${complementMessage}

          <p>Estamos à disposição para maiores esclarecimentos e eventual revisão na Proposta visando o fechamento do Pedido.</p>

          <p>Atenciosamente,</p>
    
          <p><strong>Equipe Multidoor</strong></p>
        `
            )
        }
    }

    sendEmailModal = async (email, proposalName, keyWord, proposal_code, historic, complementMessage, sendToRepresentative = null) => {
        this.setState({ loading: true })

        // Get sale channel
        const proposal = await findWhere('Proposal', 'proposal_code', [proposal_code], ['sales_channel'])
        const salesChannelId = proposal[0].get('sales_channel').id
        const salesChannel = await findById('SalesChannel', salesChannelId)
        const emailSender = salesChannel.get('email_sender') || 'comercial@multidoor.com.br'

        let params = {
            email: sendToRepresentative || email.join(';'),
            proposalName: proposalName,
            keyWord: keyWord,
            message: this.generate_message(complementMessage, proposalName, sendToRepresentative),
            emailSender: emailSender
        }

        Parse.Cloud.run('sendProposalByEmail', params).then(async () => {
            notification.success({
                message: 'Proposta enviada com sucesso.',
                description: 'Aguarde enquanto o histórico é atualizado'
            })
            this.setState({ emailModal: false })

            // Salvar histórico de envio no banco
            if (!sendToRepresentative) {
                let historicToSet = historic || []
                const user = await getCurrentUser()
                let historicToPush = {
                    comment: `Proposta ${proposal_code} enviada para o e-mail ${email} em ${new Date(Date.now()).toLocaleDateString('pt-BR')} por ${user.get('name')}.`,
                    author: user.get('name'),
                    date: Date.now()
                }
                historicToSet.push(historicToPush)
                let versionOfProposal = proposal_code.split('-')[2]
                let proposalCode = proposal_code.split('-')[0] + '-' + proposal_code.split('-')[1] + '-' + versionOfProposal

                const proposalToUpdate = await findWhere('Proposal', 'proposal_code', [proposalCode])
                await save('Proposal', { 'historic': historicToSet }, proposalToUpdate[0].id)
            }
            this.setState({ loading: false })
        }).catch(() => {
            notification.error({
                message: 'Erro ao enviar a proposta',
                description: 'Verifique os dados e tente novamente.',
                duration: 5
            })
            this.setState({ loading: false })
        })
    }

    /**
     * Generate whatsapp message to send.
     * @param {String} proposalLink pdf proposal link.
     */
    generate_whatsapp_message = (proposalLink, complementMessage) => {
        let messageArray = []
        if (this.state.sendChecklist) {
            messageArray.push(`Prezado cliente,\n\n`);
            messageArray.push(`Você está recebendo uma *Proposta de Engenharia de Valor* para as portas de madeira do seu empreendimento (link abaixo) juntamente com um *Checklist de portas* para um comparativo normativo das especificações dos fornecedores. A senha de acesso para visualizar a proposta são os 5 primeiros números do CNPJ/CPF.\n\n`);
            messageArray.push(`${proposalLink}\n\n`);
            messageArray.push(`Clique no link abaixo para acessar o checklist. Utilize a senha *${this.state.proposalId}* para preenchê-lo.\n\n`);
            messageArray.push(`https://minhamultidoor.com.br/checklist\n\n`);
            if (complementMessage) messageArray.push(`${complementMessage}\n\n`);
            messageArray.push(`Estamos à disposição para maiores esclarecimentos e eventual revisão na Proposta visando o fechamento do Pedido.\n\n`);
            messageArray.push(`Atenciosamente,\n\n`);
            messageArray.push(`*Equipe Multidoor*`);

            return messageArray.join("");
        } else {
            messageArray.push(`Prezado cliente,\n\n`);
            messageArray.push(`Você está recebendo uma *Proposta de Engenharia de Valor* para as portas de madeira do seu empreendimento (link abaixo). A senha de acesso para visualizar a proposta são os 5 primeiros números do CNPJ/CPF.\n\n`);
            messageArray.push(`${proposalLink}\n\n`);
            if (complementMessage) messageArray.push(`${complementMessage}\n\n`);
            messageArray.push(`Estamos à disposição para maiores esclarecimentos e eventual revisão na Proposta visando o fechamento do Pedido.\n\n`);
            messageArray.push(`Atenciosamente,\n\n`);
            messageArray.push(`*Equipe Multidoor*`);

            return messageArray.join("");
        }
    }

    /**
     * Send proposal by whatsapp.
     * @param {String} number whatsapp number.
     * @param {String} proposalLink pdf proposal link.
     * @param {String} proposal_code
     * @param {Array} historic
     */
    sendProposalViaWhatsapp = (number, proposalLink, proposal_code, historic, complementMessage) => {
        // Get only the number of numberstring
        number = number.split("").filter(n => (Number(n) || n === '0')).join("");

        // Create message
        let messageToSend = this.generate_whatsapp_message(proposalLink, complementMessage);
        messageToSend = window.encodeURIComponent(messageToSend);

        // Send message via whatsapp web ou app
        window.open(`https://wa.me/+55${number}?text=${messageToSend}`, '_blank');

        // Salvar histórico de envio no banco
        Modal.confirm({
            title: 'Deseja registrar o envio no histórico?',
            content: 'Clique em sim para confirmar que o whatsapp foi enviado',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: async () => {
                message.info('Salvando registro, aguarde.')
                let historicToSet = historic || [];
                const user = await getCurrentUser()
                let historicToPush = {
                    comment: `Proposta ${proposal_code} enviada para o cliente via whatsapp pelo número ${number} em ${new Date(Date.now()).toLocaleDateString('pt-BR')} por ${user.get('name')}.`,
                    author: user.get('name'),
                    date: Date.now()
                };
                historicToSet.push(historicToPush);
                let versionOfProposal = proposal_code.split('-')[2];
                // if (parseInt(versionOfProposal, 10) < 10) versionOfProposal = '0' + versionOfProposal;
                let proposalCode = proposal_code.split('-')[0] + '-' + proposal_code.split('-')[1] + '-' + versionOfProposal;

                const proposalToUpdate = await findWhere('Proposal', 'proposal_code', [proposalCode])
                await save('Proposal', { 'historic': historicToSet }, proposalToUpdate[0].id)
                window.location.reload()
            }
        })
    }

    render() {
        return (
            <div style={{ float: 'left' }}>
                <Popover content='Enviar proposta'>
                    <Button
                        icon="mail"
                        onClick={async () => {
                            const user = await getCurrentUser()
                            let auth = new Auth(user)
                            auth.init(() => {
                                if (auth.hasAction(['sendProposalByEmail', '*'])) this.setState({ emailModal: true })
                                else message.error('Você não tem permissão para enviar proposta')
                            })
                        }}
                    />
                </Popover>

                <Modal
                    title='Enviar proposta'
                    width={700}
                    visible={this.state.emailModal}
                    onCancel={() => this.setState({ emailModal: false, loading: false })}
                    footer={[
                        <Button
                            key='send' loading={this.state.loading}
                            icon="mail"
                            onClick={() => {
                                this.sendEmailModal([this.state.email, this.state.representative_email], this.state.proposalName, this.state.keyWord, this.state.proposal_code, this.state.historic, this.state.complementMessage);
                                if (this.state.sendToRepresentative)
                                    this.sendEmailModal([this.state.email, this.state.representative_email], this.state.proposalName, this.state.keyWord, this.state.proposal_code, this.state.historic, this.state.complementMessage, this.props.representativeEmail);
                            }}
                        >Enviar via e-mail</Button>,

                        <Button
                            key='send-by-whatsapp'
                            icon="message"
                            onClick={() => {
                                if (this.state.whatsapp) {
                                    // let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${this.state.proposalName}`;
                                    let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
                                    serverUrlArray.pop();
                                    const serverUrl = serverUrlArray.join('/');
                                    let pdfName = `${serverUrl}/api/proposals/download-proposal/${this.state.proposalName}?versionSurvey=${this.state.sendSurvey ? 'true' : ''}`;

                                    this.sendProposalViaWhatsapp(this.state.whatsapp, pdfName, this.state.proposal_code, this.state.historic, this.state.complementMessage);
                                } else {
                                    message.error('Informe o número do whatsapp');
                                }
                            }}
                            style={{
                                background: '#25D366',
                                color: 'white',
                            }}
                        >Enviar via whatsapp</Button>
                    ]}
                >
                    <Input placeholder='E-mail' value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                    <Input placeholder='Whatsapp (com DDD)' value={CreatePhoneMask(this.state.whatsapp)} onChange={e => this.setState({ whatsapp: e.target.value })} style={{ marginTop: '10px' }} />
                    <Input.TextArea rows={4} placeholder='Mensagem adicional (opcional)' onChange={e => this.setState({ complementMessage: e.target.value })} style={{ marginTop: '10px' }} />
                    <p style={{ marginTop: '20px' }}>
                        <Checkbox
                            checked={this.state.sendChecklist}
                            onChange={e => {
                                this.setState({ sendChecklist: e.target.checked })
                            }}
                        >
                            Enviar link para o checklist
                        </Checkbox>
                    </p>
                    <p style={{ marginTop: '20px' }}>
                        <Checkbox
                            disabled={!this.props.representativeEmail}
                            checked={this.state.sendToRepresentative}
                            onChange={e => {
                                this.setState({ sendToRepresentative: e.target.checked })
                            }}
                        >
                            Enviar com cópia para o representante: {this.props.representativeEmail}
                        </Checkbox>
                    </p>
                    <p style={{ marginTop: '20px' }}>
                        <Checkbox
                            checked={this.state.sendSurvey}
                            onChange={e => {
                                this.setState({ sendSurvey: e.target.checked })
                            }}
                        >
                            Enviar pesquisa para essa versão
                        </Checkbox>
                    </p>
                </Modal>
            </div>
        )
    }
}