import { calculatePriceRow } from '../modules/components-and-kits/component-kit/component_kit [OLD]'
import { findById, findWhere } from './db_functions.js';

const kitComponents = [
    'finishing_sheet',
    'thickness_marco',
    'conj_fechadura',
    'hardware_door_lock',
    'hardware_bar_anti_panic',
    'hardware_aerial_spring',
    'hardware_accessories',
    'hardware_accessories_02',
    'hardware_accessories_03',
    'hardware_addons',
    'hardware_quantity',
    'finishing_aliz',
    'finishing_aliz_adjustable',
];

/**
 * Get component ids from product
 * @param {object} product 
 * @returns array of component ids
 */
const getComponentsFromProduct = (product) => {
    const componentIds = kitComponents.map(component => product[component]).filter(el => el);
    return [...new Set(componentIds)];
}

/**
 * Get input ids from component
 * @param {object} component 
 * @returns array of input ids
 */
const getInputsFromComponent = (component) => {
    const inputIds = (component['descriptions'] || []).map(inputGroup => (inputGroup['rows'] || [])
        .map(input => input['specification']))
        .flat()
        .filter(el => el);
    return [...new Set(inputIds)];
}

/**
 * Transform array of inputs in an inputs object
 * @param {array} inputs 
 * @returns object with inputs like attr
 */
const loadInputPrices = (inputs) => {
    let inputPrices = inputs.reduce((a, v) => {
        a[v.id] = v
        return a
    }, {});
    return inputPrices;
}

/**
 * Get component total price with discount and sales channel
 * @param {object} component 
 * @param {number} salesChannelAdd 
 * @param {number} discount 
 * @param {object} inputs 
 * @param {number} qtd 
 * @returns total price of the component
 */
const getComponentTotalPrice = (component, salesChannelAdd, discount, inputs, qtd = 1) => {
    let totalOfComponent = calculatePriceRow(component, inputs);
    // Add sales channel 
    totalOfComponent = parseFloat((totalOfComponent * salesChannelAdd).toFixed(2));
    // Get unit discount
    totalOfComponent = parseFloat((totalOfComponent * (1 - (discount || 0))).toFixed(2));
    if (isNaN(totalOfComponent)) return 0;
    return totalOfComponent * qtd;
}

/**
   * Calculate final price of the item.
   * @param     {object}        item
   * @param     {number}        discount
   * @param     {boolean}       withoutSalesChannel
   * @param     {number}        salesChannel
   * @param     {boolean}         hasInstalation
   * @param     {array}         components
   * @param     {array}         inputs
   * @returns 
   */
const getTotalPrice = async (item, discount, withoutSalesChannel = false, salesChannel, hasInstalation, components = [], inputs = []) => {
    if (item && Object.keys(item).length > 0) {
        // Get kit
        const kit = await findById('Kit', item.product_code, ['comp_mouting', 'instalation_kit']);
        const kitId = item && item.product_code;
        // Get components and inputs
        // const componentsKit = await getAllComponentsFromKit(kit);
        const inputPrices = loadInputPrices(inputs);
        // Get sales channel additional/discount
        let salesChannelAdd = 1;
        if (salesChannel && !withoutSalesChannel) salesChannelAdd = (1 + (salesChannel / 100));
        // Calculate price
        let total = 0;
        if (!kitId) return 0;
        // Get price of the mounting
        let mounting = (kit && kit.get('comp_mounting'));
        if (mounting && !item.search_componentKit) {
            mounting = mounting.toJSON();
            delete mounting.vinculated_component;
            total += (mounting ? getComponentTotalPrice(mounting, salesChannelAdd, discount, inputPrices) : 0);
        }
        // Get price of the sheet, marco, aliz, fechadura and ferragens
        const componentsToCalculate = [
            'finishing_sheet', 'finishing_marco', 'finishing_aliz', 'finishing_aliz_adjustable', 'conj_fechadura', 'hardware_door_lock',
            'hardware_puller', 'hardware_door_handle', 'hardware_bar_anti_panic', 'hardware_aerial_spring', 'hardware_accessories', 'hardware_accessories_02', 'hardware_accessories_03',
        ];
        const componentsQtd = [
            kit.get('sheet_qtd'), 1, 1, 1, (item.lock_quantity || 0), (item.lock_quantity || 0), (item.hardware_puller_amount || 0), (item.lock_quantity || 0),
            (item.hardware_bar_anti_panic_amount || 0), (item.hardware_aerial_spring_amount || 0), (item.hardware_accessories_amount || 0), (item.hardware_accessories_amount_02 || 0), (item.hardware_accessories_amount_03 || 0),
        ];
        componentsToCalculate.forEach((componentSpecToCalculate, i) => {
            let componentToCalculate = components.find(el => el.id === item[componentSpecToCalculate]);
            if (componentToCalculate) {
                componentToCalculate = componentToCalculate.toJSON();
                delete componentToCalculate.vinculated_component;
                total += getComponentTotalPrice(componentToCalculate, salesChannelAdd, discount, inputPrices, componentsQtd[i]);
            }
        });
        if (hasInstalation) {
            let instalation = (kit && kit.get('instalation_kit'));
            instalation = instalation.toJSON();
            delete instalation.vinculated_component;
            total += (instalation ? getComponentTotalPrice(instalation, salesChannelAdd, discount, inputPrices) : 0);
        }
        return total;
    } else return 0;
}

/**
 * Calculate a component price
 * @param {object} product 
 * @param {number} discound 
 * @param {boolean} withoutSalesChannel 
 * @param {object} salesChannel 
 * @param {boolean} hasInstalation 
 * @returns 
 */
const calculatePrice = async (product, discound, withoutSalesChannel, salesChannel, hasInstalation = false) => {
    // Get components from product
    const componentIds = getComponentsFromProduct(product);
    const components = await findWhere('ComponenteKit', 'objectId', componentIds, ['finishing', 'unity_value_hh']);
    // Get inputs from components
    const inputIds = components.map(component => getInputsFromComponent(component.toJSON())).flat().filter(el => el);
    const uniqueInputIds = [...new Set(inputIds)];
    const inputs = await findWhere('Input', 'objectId', uniqueInputIds, ['unity_value_hh']);
    return await getTotalPrice(product, discound, withoutSalesChannel, salesChannel, hasInstalation, components, inputs);
}

export {
    calculatePrice,
}