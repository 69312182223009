import { Icon, Select } from 'antd'
import React from 'react'
import ComparativeTableComponent from './components/table-component'

export default {
    formTabs: [
        {
            title: 'Dados',
            fields: [
                [
                    {
                        title: 'Descrição',
                        key: 'description',
                        type: 'text',
                        colSize: 12,
                        'element-attr': {
                            required: true,
                        }
                    }
                ],
                [
                    {
                        type: 'dynamic-input-list',
                        key: 'comparativeTables',
                        fields: [
                            [

                                {
                                    title: 'Folha',
                                    key: 'sheet',
                                    type: 'select',
                                    options: [],
                                    optionConfig: {
                                        name: 'performance',
                                    },
                                    loaded: false,
                                    colSize: 4,
                                    render: (performances) => {
                                        return [
                                            ...new Set(
                                                performances.map(performance => {
                                                    return performance.value.split('-')[0].trim()
                                                })
                                            ),
                                        ].map((value, index) => {
                                            return (
                                                <Select.Option key={`performance-${index}`} value={value}>{value}</Select.Option>
                                            )
                                        })
                                    },
                                    'element-attr': {
                                        required: false,
                                    }
                                },
                                {
                                    title: 'Largura',
                                    key: 'width',
                                    type: 'select',
                                    options: [],
                                    optionConfig: {
                                        name: 'larguras_folha',
                                    },
                                    loaded: false,
                                    colSize: 3,
                                    'element-attr': {
                                        required: false,
                                    }
                                },
                                {
                                    title: 'Altura',
                                    key: 'height',
                                    type: 'select',
                                    options: [],
                                    optionConfig: {
                                        name: 'alturas_folha',
                                    },
                                    loaded: false,
                                    colSize: 3,
                                    'element-attr': {
                                        required: false,
                                    }
                                },
                                {
                                    title: 'Acabamento',
                                    key: 'finishing',
                                    type: "pointer",
                                    options: [],
                                    'relation-select': {
                                        class: "Finishing",
                                        label: 'code',
                                        value: "objectId",
                                        key: "objectId",
                                        orderBy: "code"
                                    },
                                    colSize: 4,
                                    'element-attr': {
                                        mode: 'default',
                                        required: false,
                                    }
                                },
                                {
                                    title: 'Marco',
                                    key: 'finishing_marco',
                                    type: "pointer",
                                    options: [],
                                    'relation-select': {
                                        class: "Finishing",
                                        label: 'code',
                                        value: "objectId",
                                        key: "objectId",
                                        orderBy: "code"
                                    },
                                    colSize: 4,
                                    'element-attr': {
                                        mode: 'default',
                                        required: false,
                                    }
                                },
                                {
                                    title: 'Tipologia',
                                    key: 'typology',
                                    type: 'select',
                                    options: [],
                                    optionConfig: {
                                        name: 'typology', arrayWithId: true,
                                    },
                                    loaded: false,
                                    colSize: 6,
                                    'element-attr': {
                                        required: false,
                                    }
                                },
                                // {
                                //     title: 'Fechadura',
                                //     key: 'door_handle',
                                //     type: 'text',
                                //     colSize: 5,
                                //     'element-attr': {
                                //         required: false,
                                //     }
                                // },
                            ]
                        ]
                    }
                ],
            ],
        },
        {
            title: 'Comparativo',
            fields: [
                [
                    {
                        type: 'render',
                        render: (_) => {
                            return <ComparativeTableComponent comparativeTables={_.formRef.comparativeTables} />
                        }
                    }

                ]
            ]
        }
    ],
    submit: {
        collection: 'ComparativeTable',
    },
    schema: [
        {
            title: 'Descrição',
            key: 'description',
            dataIndex: 'description',
        }
    ],
    'title-navigation': <div><Icon type="table" /> Tabela comparativa</div>,
    'title-module': 'Tabela comparativa',
    module: 'ComparativeTable',
    'router-base': '/panel/tabela-comparativa',
}