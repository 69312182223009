import { Button, Col, Form, Input, InputNumber, message, Modal, Popover, Row, Select, Table } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React, { Component, Fragment } from 'react'
import { findWhere } from '../../../../utils/db_functions'
import '../sales-channel.css'

export default class PaymentRules extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rules: this.props.rules,

            rulesModal: false,

            ruleFormModal: false,
            ruleForm: {
                ruleIndex: null,
                rule: null,
                signal: null,
                installmentsQtd: null,
                description: null,
                date: null,
            },

            rulesOptions: [
                { value: 'fabrica', label: 'Fábrica - Cobrança Boleto' },
                { value: 'store_01', label: 'Store - Boleto ou Cheque' },
                { value: 'store_02', label: 'Store - Cartão de crédito com sinal' },
                { value: 'store_03', label: 'Store - Cartão de crédito sem sinal' },
                { value: 'antecipado_01', label: 'Antecipado - prazo entrega normal' },
                { value: 'antecipado_02', label: 'Antecipado - prazo entrega reduzido 50%' },
            ]
        }
    }

    componentDidMount = async () => {
        const rulesOptionsArray = await findWhere('Config', 'name', ['regra_pagamento_canal_de_vendas'])
        const rulesOptions = rulesOptionsArray[0].get('array_with_id')
        this.setState({ rulesOptions })
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.rules !== this.state.rules)
            this.setState({ rules: nextProps.rules })
    }

    saveRule = () => {
        if (
            !this.state.ruleForm.rule ||
            (!this.state.ruleForm.signal && this.state.ruleForm.signal !== 0) ||
            (!this.state.ruleForm.installmentsQtd && this.state.ruleForm.installmentsQtd !== 0) ||
            !this.state.ruleForm.description ||
            !this.state.ruleForm.date
        ) {
            message.error('Preencha todos os campos antes de salvar')
        } else {
            let rules = this.state.rules
            if (this.state.ruleForm.ruleIndex || this.state.ruleForm.ruleIndex === 0) {
                rules[this.state.ruleForm.ruleIndex] = {
                    ...rules[this.state.ruleForm.ruleIndex],
                    rule: this.state.ruleForm.rule,
                    signal: this.state.ruleForm.signal,
                    installmentsQtd: this.state.ruleForm.installmentsQtd,
                    description: this.state.ruleForm.description,
                    date: this.state.ruleForm.date,
                }
            } else {
                rules.push({
                    rule: this.state.ruleForm.rule,
                    signal: this.state.ruleForm.signal,
                    installmentsQtd: this.state.ruleForm.installmentsQtd,
                    description: this.state.ruleForm.description,
                    date: this.state.ruleForm.date,
                })
            }

            this.setState({
                rules,
                ruleFormModal: false,
                ruleForm: {
                    ruleIndex: null,
                    rule: null,
                    signal: null,
                    installmentsQtd: null,
                    description: null,
                    date: null,
                }
            })

            this.props.updateRules(rules)
        }
    }

    render() {

        const rulesColumns = [
            {
                title: 'Regra',
                dataIndex: 'rule',
                key: 'rule',
                align: 'center',
                render: (v) => {
                    const rule = this.state.rulesOptions.find(el => el.value === v)
                    return rule && rule.label
                }
            },
            {
                title: 'Sinal',
                dataIndex: 'signal',
                key: 'signal',
                align: 'center',
                render: (v) => {
                    return `${(v || 0)}%`
                }
            },
            {
                title: 'Parcelas',
                dataIndex: 'installmentsQtd',
                key: 'installmentsQtd',
                align: 'center',
            },
            {
                title: 'Descrição',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Data',
                dataIndex: 'date',
                key: 'date',
                align: 'center',
                render: (v) => v.toLocaleString('pt-BR')
            },
            {
                title: 'Ações',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                render: (v, _, index) => {
                    return (
                        <ButtonGroup>
                            <Popover content='editar'>
                                <Button
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({
                                            ruleFormModal: true,
                                            ruleForm: {
                                                ruleIndex: index,
                                                rule: _.rule,
                                                signal: _.signal,
                                                installmentsQtd: _.installmentsQtd,
                                                description: _.description,
                                                date: _.date,
                                            }
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Excluir'>
                                <Button
                                    icon='delete'
                                    onClick={() => {
                                        let _this = this
                                        Modal.confirm({
                                            iconType: 'exclamation-circle',
                                            title: 'Tem certeza que deseja exlcuir essa regra?',
                                            content: 'Essa ação não poderá ser desfeita',
                                            onOk() {
                                                let rules = _this.state.rules || []
                                                rules.splice(index, 1)
                                                _this.setState({ rules })

                                                // _this.props.updateRules(rules)
                                            },
                                            okText: 'Sim',
                                            cancelText: 'Não, deixa pra lá'
                                        })
                                    }}
                                />
                            </Popover>
                        </ButtonGroup>
                    )
                }
            }
        ];

        return (
            <Fragment className='rules-card-button'>
                <Popover content='Regras de pagamento'>
                    <Button
                        icon='dollar'
                        onClick={() => this.setState({ rulesModal: true })}
                    />
                </Popover>

                <Modal
                    title={`Regras de pagamento - ${this.props.description}`}
                    visible={this.state.rulesModal}
                    onCancel={() => this.setState({ rulesModal: false })}
                    footer={[
                        <Button type='primary' onClick={() => this.setState({ ruleFormModal: true })}>Adicionar nova regra de pagamento</Button>,
                        <Button onClick={() => this.setState({ rulesModal: false })}>Fechar</Button>
                    ]}
                    width={800}
                >
                    <Table
                        columns={rulesColumns}
                        dataSource={this.state.rules}
                        pagination={false}
                        locale={{ emptyText: 'Sem regras' }}
                    />
                </Modal>

                <Modal
                    title='Regra'
                    visible={this.state.ruleFormModal}
                    onCancel={() => {
                        this.setState({
                            ruleFormModal: false,
                            ruleForm: {
                                ruleIndex: null,
                                rule: null,
                                signal: null,
                                installmentsQtd: null,
                                description: null,
                                date: null
                            }
                        })
                    }}
                    cancelText='Cancelar'
                    onOk={() => this.saveRule()}
                    okText='Salvar'
                    width={800}
                >
                    <Row gutter={24}>
                        <Col span={14}>
                            <Form.Item label='Regra'>
                                <Select
                                    value={this.state.ruleForm.rule}
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, rule: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.rulesOptions.map(option => {
                                            return (
                                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label='Sinal (%)'>
                                <InputNumber
                                    value={this.state.ruleForm.signal}
                                    placeholder='Sinal (%)'
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, signal: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label='Parcelas'>
                                <InputNumber
                                    value={this.state.ruleForm.installmentsQtd}
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, installmentsQtd: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Descrição'>
                                <Input
                                    value={this.state.ruleForm.description}
                                    placeholder='Descrição'
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, description: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Data'>
                                <Input
                                    value={this.state.ruleForm.date}
                                    placeholder='Data'
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, date: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Modal>
            </Fragment>
        )

    }

}