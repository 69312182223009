import { Button, Col, Form, Input, InputNumber, message, Modal, Popover, Row, Spin } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React, { Component } from 'react'
import '../sales-channel.css'
import AuthorizedGroups from './authorized-groups'
import PaymentRules from './payment-rules'
import Rules from './rules'

export default class AutomaticChooseRules extends Component {

    constructor(props) {
        super(props)
        this.state = {
            salesChannelRules: this.props.form.formRef.automatic_choose_rules,
            updatingRules: false,

            ruleModal: false,
            ruleForm: {
                description: null,
                value: null,
                ruleIndex: null,
            },
        }
    }

    renderSalesChannel = (item, index) => {
        return (
            <div className='rules-card'>
                <Row gutter={24}>
                    <Col span={20}>
                        <h3>{item.description}</h3>
                        <div className='rules-card-subtitle'>{item.value}%</div>

                        <ButtonGroup>

                            <Rules
                                description={item.description}
                                rules={item.rules || []}
                                updateRules={(rules) => {
                                    this.setState({ updatingRules: true })
                                    let salesChannelRules = this.state.salesChannelRules
                                    salesChannelRules[index].rules = rules
                                    this.setState({ salesChannelRules, updatingRules: false })
                                    this.props.updateRules(salesChannelRules)
                                }}
                            />

                            <PaymentRules
                                description={item.description}
                                rules={item.paymentRules || []}
                                updateRules={(rules) => {
                                    this.setState({ updatingRules: true })
                                    let salesChannelRules = this.state.salesChannelRules
                                    salesChannelRules[index].paymentRules = rules
                                    this.setState({ salesChannelRules, updatingRules: false })
                                    this.props.updateRules(salesChannelRules)
                                }}
                            />

                            <AuthorizedGroups
                                description={item.description}
                                authorizedGroups={item.authorizedGroups || []}
                                updateAuthorizedGroups={(authorizedGroups) => {
                                    this.setState({ updatingRules: true })
                                    let salesChannelRules = this.state.salesChannelRules
                                    salesChannelRules[index].authorizedGroups = authorizedGroups
                                    this.setState({ salesChannelRules, updatingRules: false })
                                    this.props.updateRules(salesChannelRules)
                                }}
                            />

                        </ButtonGroup>

                    </Col>

                    <Col span={4}>
                        <div className='rules-card-button'>
                            <ButtonGroup>
                                <Popover content='Mover para cima'>
                                    <Button
                                        icon='arrow-up'
                                        disabled={index === 0}
                                        onClick={() => {
                                            this.setState({ updatingRules: true })

                                            let salesChannelRules = this.state.salesChannelRules
                                            const current = { ...salesChannelRules[index] }
                                            const prev = { ...salesChannelRules[index - 1] }

                                            salesChannelRules[index - 1] = current
                                            salesChannelRules[index] = prev

                                            this.setState({ salesChannelRules, updatingRules: false })

                                            this.props.updateRules(salesChannelRules)
                                        }}
                                    ></Button>
                                </Popover>

                                <Popover content='Mover para baixo'>
                                    <Button
                                        icon='arrow-down'
                                        disabled={index === (this.state.salesChannelRules.length - 1)}
                                        onClick={() => {
                                            this.setState({ updatingRules: true })

                                            let salesChannelRules = this.state.salesChannelRules
                                            const current = { ...salesChannelRules[index] }
                                            const post = { ...salesChannelRules[index + 1] }

                                            salesChannelRules[index + 1] = current
                                            salesChannelRules[index] = post

                                            this.setState({ salesChannelRules, updatingRules: false })

                                            this.props.updateRules(salesChannelRules)
                                        }}
                                    ></Button>
                                </Popover>

                                <Popover content='Editar regra'>
                                    <Button
                                        icon='edit'
                                        onClick={() => {
                                            this.setState({
                                                ruleForm: {
                                                    description: item.description,
                                                    value: item.value,
                                                    ruleIndex: index
                                                },
                                                ruleModal: true,
                                            })
                                        }}
                                    ></Button>
                                </Popover>

                                <Popover content='Deletar regra'>
                                    <Button
                                        icon='delete'
                                        onClick={() => {
                                            let _this = this
                                            Modal.confirm({
                                                iconType: 'exclamation-circle',
                                                title: 'Tem certeza que deseja exlcuir essa regra?',
                                                content: 'Essa ação não poderá ser desfeita',
                                                onOk() {
                                                    _this.setState({ updatingRules: true })

                                                    let salesChannelRules = _this.state.salesChannelRules || []
                                                    salesChannelRules.splice(index, 1)
                                                    _this.setState({ salesChannelRules, updatingRules: false })

                                                    _this.props.updateRules(salesChannelRules)
                                                },
                                                okText: 'Sim',
                                                cancelText: 'Não, deixa pra lá'
                                            })
                                        }}
                                    ></Button>
                                </Popover>
                            </ButtonGroup>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    saveRule = () => {
        if (!this.state.ruleForm.description || (!this.state.ruleForm.value && this.state.ruleForm.value !== 0)) {
            message.error('Preencha todos os campos antes de salvar')
        } else {
            this.setState({ updatingRules: true })
            let salesChannelRules = this.state.salesChannelRules

            if (this.state.ruleForm.ruleIndex || this.state.ruleForm.ruleIndex === 0) {
                salesChannelRules[this.state.ruleForm.ruleIndex] = {
                    ...salesChannelRules[this.state.ruleForm.ruleIndex],
                    description: this.state.ruleForm.description,
                    value: this.state.ruleForm.value,
                }
            } else {
                salesChannelRules.push({
                    description: this.state.ruleForm.description,
                    value: this.state.ruleForm.value,
                })
            }

            this.setState({
                salesChannelRules,
                updatingRules: false,
                ruleModal: false,
                ruleForm: { description: null, value: null, ruleIndex: null }
            })

            this.props.updateRules(salesChannelRules)
        }
    }

    render() {
        return (
            <div>
                <Spin tip='Atualizando regras...' spinning={this.state.updatingRules}>
                    {
                        (this.state.salesChannelRules || []).map((item, index) => {
                            return this.renderSalesChannel(item, index)
                        })
                    }

                    <Button
                        className='rules-add-button'
                        type='primary'
                        onClick={() => this.setState({ ruleModal: true })}
                        icon='plus'
                    >
                        Adicionar nova regra
                    </Button>
                </Spin>

                <Modal
                    title='Regra'
                    visible={this.state.ruleModal}
                    onCancel={() => this.setState({ ruleModal: false, ruleForm: { description: null, value: null, ruleIndex: null } })}
                    cancelText='Cancelar'
                    onOk={() => this.saveRule()}
                    okText='Salvar'
                >
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item label='Descrição'>
                                <Input
                                    value={this.state.ruleForm.description}
                                    placeholder='Descrição'
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, description: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Valor (%)'>
                                <InputNumber
                                    value={this.state.ruleForm.value}
                                    placeholder='Valor (%)'
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, value: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }

}