import React from "react"
import { Button } from "antd"

const { findWhereMultiple } = require("../../../../utils/db_functions")

const columns = ({ orders, ordersSelected, addOrder, removeOrder }) => {
    return [
        {
            title: 'Pedido',
            dataIndex: 'order_code',
            key: 'order_code',
        },
        {
            title: 'Cliente',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Palavra Chave',
            dataIndex: 'keyWord',
            key: 'keyWord',
        },
        {
            title: '',
            dataIndex: 'order_code',
            key: 'orderSelected',
            width: '100px',
            align: 'center',
            render: (code) => {
                const orderToAddOrRemove = (orders || []).find(order => order.order_code === code)
                const orderAlreadyAdded = (ordersSelected || []).find(order => order.order_code === code)
                return !orderAlreadyAdded ?
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={() => {
                            addOrder(orderToAddOrRemove)
                        }}
                    >Adicionar</Button>
                    :
                    <Button
                        type="danger"
                        icon="delete"
                        onClick={() => {
                            removeOrder(orderToAddOrRemove)
                        }}
                    >Remover</Button>

            }
        },
    ]
}

const getOrders = async (value) => {
    const orders = await findWhereMultiple(
        "Order",
        [
            { 'field': 'order_code', 'value': value, 'op': 'like', logicOp: '$or' },
            { 'field': 'proposal', 'value': value, 'op': 'like', logicOp: '$or' },
        ], null, null, null, "order_code", "descending"
    )

    return orders.filter(order => {
        const proposal = JSON.parse(order.get("proposal"))
        return (
            order.get("order_code").toLowerCase().includes(value.toLowerCase()) ||
            proposal.name_client.toLowerCase().includes(value.toLowerCase()) ||
            proposal.keyWord.toLowerCase().includes(value.toLowerCase())
        )
    }).map(order => {
        const proposal = JSON.parse(order.get("proposal"))
        return {
            // ...order.toJSON(),
            objectId: order.id,
            proposalRef: order.get("proposalRef").toJSON(),
            order_code: order.get("order_code"),
            customer: proposal.name_client,
            keyWord: proposal.keyWord,
            orderSelected: false,
        }
    })
}

export {
    columns,
    getOrders,
}