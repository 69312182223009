import { Button, Input, InputNumber, Modal, Popover, Table } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React, { Component } from 'react'
import OrderSelectModal from './order-select-modal'
import OrderPerformancesModal from './order-performances-modal'

export default class OrderMapTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orderMap: this.props.orderMap,
            typologies: ['PIM', 'PEM', 'PIA', 'PAR', 'PRF', 'PSH', 'PDS', 'PXM', 'PSG'],
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.orderMap !== this.state.orderMap) this.setState({ orderMap: nextProps.orderMap })
    }

    getOrderData = (order) => {
        return {
            order: order.order_code,
            customer: order.proposalRef && order.proposalRef.name_client,
            work: order.proposalRef && order.proposalRef.name_work,
            kits: order.proposalRef && order.proposalRef.baseFinalPriceAndAmount && order.proposalRef.baseFinalPriceAndAmount.finalAmount,
            products: ((order.proposalRef && order.proposalRef && order.proposalRef.product_blocks) || []).map(block => block.products).flat()
        }
    }

    render() {

        const columns = [
            { title: 'Pedido', dataIndex: 'order', key: 'order', width: 150 },
            { title: 'Cliente', dataIndex: 'customer', key: 'customer' },
            { title: 'Obra', dataIndex: 'work', key: 'work' },
            {
                title: 'Aprovado',
                dataIndex: 'approved', key: 'approved',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <Input
                            value={value}
                            placeholder='Aprovação' style={{ width: 100 }} size='small'
                            onChange={(v) => {
                                const orderMap = this.state.orderMap
                                orderMap[index].approved = v.target.value
                                this.props.updateOrderMap(orderMap)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Entrega',
                dataIndex: 'delivery', key: 'delivery',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <Input
                            value={value}
                            placeholder='Entrega' style={{ width: 100 }} size='small'
                            onChange={(v) => {
                                const orderMap = this.state.orderMap
                                orderMap[index].delivery = v.target.value
                                this.props.updateOrderMap(orderMap)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Projeto',
                dataIndex: 'project', key: 'project',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <Input
                            value={value}
                            placeholder='Projeto' style={{ width: 100 }} size='small'
                            onChange={(v) => {
                                const orderMap = this.state.orderMap
                                orderMap[index].project = v.target.value
                                this.props.updateOrderMap(orderMap)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Fabricação',
                dataIndex: 'manufacturing', key: 'manufacturing',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <InputNumber
                            value={value}
                            step={1} placeholder='Fabricação' style={{ width: 100 }} size='small'
                            onChange={(v) => {
                                const orderMap = this.state.orderMap
                                orderMap[index].manufacturing = v
                                this.props.updateOrderMap(orderMap)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Instalação',
                dataIndex: 'instalation', key: 'instalation',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <InputNumber
                            value={value}
                            step={1} placeholder='Instalação' style={{ width: 100 }} size='small'
                            onChange={(v) => {
                                const orderMap = this.state.orderMap
                                orderMap[index].instalation = v
                                this.props.updateOrderMap(orderMap)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Alizares',
                dataIndex: 'aliz', key: 'aliz',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <InputNumber
                            value={value}
                            step={1} placeholder='Alizar' style={{ width: 100 }} size='small'
                            onChange={(v) => {
                                const orderMap = this.state.orderMap
                                orderMap[index].aliz = v
                                this.props.updateOrderMap(orderMap)
                            }}
                        />
                    )
                }
            },
            { title: 'Kits', dataIndex: 'kits', key: 'kits', align: 'center', width: 100 },
            {
                title: 'Ações',
                dataIndex: 'actions', key: 'actions',
                align: 'center', width: 100,
                render: (value, row, index) => {
                    return (
                        <div>
                            <ButtonGroup>
                                <OrderPerformancesModal products={row.products} />

                                <Popover content='Deletar'>
                                    <Button
                                        icon='delete'
                                        onClick={() => {
                                            let _this = this
                                            Modal.confirm({
                                                iconType: 'exclamation-circle',
                                                title: 'Tem certeza que deseja exlcuir esse pedido?',
                                                content: 'Essa ação não poderá ser desfeita',
                                                onOk() {
                                                    let orderMap = _this.state.orderMap
                                                    orderMap.splice(index, 1)
                                                    _this.props.updateOrderMap(orderMap)
                                                },
                                                okText: 'Sim',
                                                cancelText: 'Não, deixa pra lá'
                                            })
                                        }}
                                    />
                                </Popover>
                            </ButtonGroup>
                        </div>
                    )
                }
            },
        ];

        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={this.state.orderMap}
                    locale={{ emptyText: 'Sem pedidos adicionados' }}
                    pagination={false}
                />

                <OrderSelectModal
                    addOrder={order => {
                        const orderMap = [
                            ...(this.state.orderMap || []),
                            this.getOrderData(order),
                        ]
                        this.props.updateOrderMap(orderMap)
                    }}
                />
            </div >
        )
    }

}