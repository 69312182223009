import React, { Component } from "react";
import { Table, Input, Icon, Button, Modal, Popconfirm } from "antd";
import { CopyObject } from './../../../utils/general_functions'
import Parse from "parse";
import { findWhereMultiple } from "../../../utils/db_functions";

/**
 * Classe de listagem.
 */
export default class ListModule extends Component {

  state = {
    page: 0,
    pageLimit: this.props.module.pageLimit || 16,
    totalResult: 0,
    dataTable: [],
    loadTableContent: true,
    loadingTable: this.props.module.loadingTable || false,
    searchTable: "",
    defaultCurrentPage: this.props.module.defaultCurrentPage || 0,
    defaultCurrentFilter: '',
    users: [],
    filterByUser: this.props.module.filterByUser
  }

  constructor(props) {
    super(props);
    this.Auth = props.auth;
  }

  /**
   * Renderização do componente.
   */
  render() {
    return (
      <div>
        <Input.Search
          onSearch={value => {
            this.setState(() => {
              return {
                page: 0
              }
            }, () => {
              setTimeout(() => this.searchTable(value))
            })

          }}
          onChange={event => {
            let value = event.target.value || ''
            if (!value) this.searchTable('');
            this.setState(() => {
              return {
                searchTable: value
              }
            })
          }}
          placeholder="Pesquisar"
          ref="inputSearch"
          size="large"
          className="search-input"
          value={this.state.searchTable || ''}
          prefix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
        />

        <Table
          bordered
          onChange={this.paginationChange.bind(this)}
          loading={this.state.loadingTable}
          dataSource={this.state.dataTable || []}
          columns={this.props.module.schema.concat(this.state.actionsTable || [])}
          size="middle"
          pagination={{ total: this.state.totalResult, pageSize: this.state.pageLimit, current: (this.state.page + 1) }}
          expandedRowRender={this.props.module.form.expandedRowRender || null}
          locale={{ emptyText: "Nenhum resultado encontrado." }}
          title={this.props.module.form.tableTitle || null}
          footer={this.props.module.form.tableFooter || null}
        />
      </div>
    );
  }

  /**
   * Registra no storage a página da listagem. 
   * @param {*} event Componente Table do antd.
   */
  paginationChange(event) {
    localStorage.setItem(`Pagination:${this.props.module.form.module}`, event.current)
    this.setState(() => {
      return {
        page: event.current - 1
      }
    }, () => {
      this.searchTable(this.state.searchTable)
    })
  }

  /**
   * Calcula o total de registros da listagem.
   * @param {Function} callback Função a ser executada após o cálculo.
   */
  async getTotalItensTable(callback) {
    let _Query = new Parse.Query(this.props.module.collection);

    // Filtrar registro do usuário, caso tenha sido determinado no módulo
    if (this.props.module.filterByUser && this.Auth.hasAction([`${this.props.module.form.module}FilterUser`])) {
      let currentUserId = Parse.User.current().id
      if (this.props.module.filterByUser) _Query.equalTo('createdBy', { "__type": "Pointer", "className": "_User", "objectId": currentUserId })
    }

    // Filtrar registros, caso tenha sido determinado no módulo
    if (this.props.module.filterList && (await this.props.module.filterList())) _Query.equalTo((await this.props.module.filterList())['field'], (await this.props.module.filterList())['value'])

    _Query.count().then(totalResult => {
      this.setState(() => {
        return {
          totalResult
        }
      }, callback)
    })
  }

  componentWillMount = async () => {
    // Pegar todos os usuários do banco e trazer para o campo updatedBy
    let userQuery = new Parse.Query('User')
    userQuery.find().then(users => {
      this.setState({ users: users })
    })

    if (!this.state.defaultCurrentPage || this.state.defaultCurrentPage === 'last') {
      let _Query = new Parse.Query(this.props.module.collection)

      // Filtrar registro do usuário, caso tenha sido determinado no módulo
      if (this.props.module.filterByUser && this.Auth.hasAction([`${this.props.module.form.module}FilterUser`])) {
        let currentUserId = new Parse().Object()
        if (this.props.module.filterByUser) _Query.equalTo('createdBy', { "__type": "Pointer", "className": "_User", "objectId": currentUserId })
      }

      // Filtrar registros, caso tenha sido determinado no módulo
      if (this.props.module.filterList && (await this.props.module.filterList())) _Query.equalTo((await this.props.module.filterList())['field'], (await this.props.module.filterList())['value'])

      let defaultCurrentPage = await _Query.count().then(totalResult => { return totalResult })
      localStorage.setItem(`Pagination:${this.props.module.form.module}`, (defaultCurrentPage / 16))
    } else {
      localStorage.setItem(`Pagination:${this.props.module.form.module}`, (this.state.defaultCurrentPage - 1))
    }

    this.recoveryState()
    this.setDefaultActionsTable()
    this.loadTableContent()
    if (this.props.module.form.hasOwnProperty('ListWillMount')) this.props.module.form.ListWillMount(this)
  }

  componentDidMount() {
    this.getTotalItensTable(() => {
      this.searchTable(localStorage.getItem(`Search:${this.props.module.form.module}`) || "")
    })
  }

  /**
   * Configura as colunas padrões da listage.
   * @param {null}
   */
  setDefaultActionsTable() {
    if (this.props.module.actionsTable === true) {
      let cols = [{
        title: "Data Atualização",
        key: "updatedAt",
        align: 'center',
        sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
        dataIndex: "updatedAt",
        type: "render",
        render: (_, row) => {
          return new Date(row.updatedAt).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })
        },
        width: '160px'
      },
      {
        width: '150px',
        title: 'Alterado por',
        key: 'updatedBy',
        align: 'center',
        render: (text, row, index) => {
          let user = this.state.users.filter(v => v.id === (row.updatedBy && row.updatedBy.objectId))
          return (<div>
            {user && user[0] ? user[0].get('name') : ''}
          </div>)
        }
      }].concat(this.defaultActions());
      if (this.props.addCol) {
        cols = this.props.addCol.concat(cols);
      }
      this.setState({
        actionsTable: cols
      });
    }
  }

  /**
   * Encaminha para determinada rota.
   * @param {String} path Nome da rota do módulo.
   * @param {String} $module Rota básica.
   */
  navigateToRouterComponent(path, $module = this.props.module.form['router-base']) {
    this.props.module.url_props.history.push($module + path)
  }

  /**
   * Retorna a coluna de ações da listagem.
   * @param {null}
   * @returns Array com os dados da coluna de ações.
   */
  defaultActions() {
    if (this.Auth.hasAction([`${this.props.module.form.module}Delete`, `${this.props.module.form.module}Update`, '*'])) {
      return [
        {
          title: "Ações",
          key: "action",
          align: "center",
          className: "actions-col",
          render: (text, row, index) => {
            let btnDel = this.Auth.hasAction([`${this.props.module.form.module}Delete`, "*"]) ? (
              <Button
                icon="delete"
                onClick={() => {
                  this.showDeleteConfirm(row, index)
                  if (this.props.module.form.hasOwnProperty('onClickActionDelete')) this.props.module.form.onClickActionDelete(row)
                }}
              />
            ) : null;
            let btnUpdate = this.Auth.hasAction([`${this.props.module.form.module}Update`, "*"]) ? (
              <Button icon="edit" onClick={() => {
                this.editRow(row)
                if (this.props.module.form.hasOwnProperty('onClickActionEdit')) this.props.module.form.onClickActionEdit(row)
              }} />
            ) : null;
            let btnCopy = !this.props.module.form.hideCopyBtn && this.Auth.hasAction([`${this.props.module.form.module}Copy`, "*"]) ? (
              <Popconfirm
                placement="left"
                title={'Deseja gerar uma cópia?'}
                onConfirm={() => CopyObject(row.key, this.props.module.form.submit.collection, copy => {
                  this.searchTable(this.state.searchTable)
                })}
                okText="Confirmar"
                cancelText="Cancelar">
                <Button icon="copy" />
              </Popconfirm>
            ) : null;
            return (
              <div style={{ textAlign: "center" }}>
                <Button.Group>
                  {btnDel}
                  {btnUpdate}
                  {this.props.module.form.module !== 'User' && btnCopy}
                </Button.Group>
              </div>
            );
          }
        }
      ];
    }
    else if (this.Auth.hasAction([`${this.props.module.form.module}Read`])) {
      return [
        {
          title: "Ações",
          key: "action",
          align: "center",
          className: "actions-col",
          render: (text, row, index) => {
            return (
              <div style={{ textAlign: "center" }}>
                <Button.Group>
                  <Button icon="edit" onClick={() => {
                    this.editRow(row)
                    if (this.props.module.form.hasOwnProperty('onClickActionEdit')) this.props.module.form.onClickActionEdit(row)
                  }} />
                </Button.Group>
              </div>
            );
          }
        }
      ];
    }
    return [];
  }

  /**
   * Carrega os dados da listagem.
   * @param {null}
   */
  loadTableContent() {
    this.setState(
      state => {
        return {
          loadingTable: true,
          dataTable: []
        };
      },
      async () => {
        let _Query = new Parse.Query(this.props.module.collection);

        // Filtrar registro do usuário, caso tenha sido determinado no módulo
        if (this.props.module.filterByUser && this.Auth.hasAction([`${this.props.module.form.module}FilterUser`])) {
          let currentUserId = Parse.User.current().id
          if (this.props.module.filterByUser) _Query.equalTo('createdBy', { "__type": "Pointer", "className": "_User", "objectId": currentUserId })
        }

        // Filtrar registros, caso tenha sido determinado no módulo
        if (this.props.module.filterList && (await this.props.module.filterList())) _Query.equalTo((await this.props.module.filterList())['field'], (await this.props.module.filterList())['value'])

        _Query.count().then(totalResult => {
          // Verificar se o default page foi setado como last e a última página tem o resultado máximo
          let addPage = 0
          if (this.state.defaultCurrentPage === this.state.page && (this.state.pageLimit * this.state.page) === totalResult) addPage = 1

          _Query.include(this.schemaIncludes())
          _Query.limit(this.state.pageLimit)
          _Query.skip(this.state.pageLimit * (this.state.page - addPage))
          if (!!this.props.whereTable) {
            this.props.whereTable.forEach(_where => {
              let value = null;
              if (!_where.flags)
                value = this.props.url_props.match.params[_where.paramUrl];
              else if (_where.flags.type === "Pointer")
                value = {
                  __type: "Pointer",
                  className: [_where.flags.class],
                  objectId: this.props.url_props.match.params[_where.paramUrl]
                };

              _Query.equalTo(_where.attr, value);
            });
          }
          _Query.find(
            {
              success: async (response) => {
                response = await this.getRelations(response)
                if (this.props.module.isManagementProposal) response = await this.setChildrenOfProposals(response)
                response = response.map(item => {
                  return Object.assign({ key: item.id, _realResponse: item }, item.toJSON());
                });
                this.tableContent = response;
                this.setState(state => {
                  return {
                    loadingTable: false,
                    dataTable: response,
                    status: "table"
                  };
                });
              },
              error: err => console.log(err)
            }
          );
        })
      }
    );
  }

  /**
   * Busca os registros relacionados de um campo específico para listar na tabela. 
   * @param {Array} data Registros da listagem.
   * @returns Array da listagem com os registros relacionados.
   */
  async getRelations(data) {
    const relations = this.props.module.schema.filter(s => s.isRelation)

    if (relations.length === 0) return data;

    const promiseRelationList = data.map(row => {
      const querys = relations.map(r => {
        return row.relation(r.key).query().find()
      })
      return Promise.all(querys)
    })

    const responseRelations = await Promise.all(promiseRelationList)

    return data.map((row, index) => {
      relations.forEach((relation, indexRelation) => {
        row[`r_${relation.key}`] = responseRelations[index][indexRelation]
      })
      return row;
    })
  }

  /**
   * Inclui os campos relacionados para busca no bd.
   * @param {null}
   * @returns Array com os campos relacionados.
   */
  schemaIncludes() {
    return this.props.module.schema
      .filter(schema => {
        return (!!schema.relation || schema["relation-select"]) ? schema : false;
      })
      .reduce((a, inc) => {
        return [...a, ...inc['relation-select'].key];
      }, ['updatedBy']);
  }

  /**
   * Busca usuários no bd.
   * @param {String} name Nome do usuário.
   * @param {Function} callback Função executada após a busca no bd.
   * @returns Função callback com os usuários retornados do bd como parâmetro.
   */
  searchUsers(name, callback = function () { }) {
    const query = new Parse.Query('_User')
    query.select(['name'])
    query.matches('name', new RegExp(name, 'i'))
    query.find().then(users => {
      callback(users)
    })

  }

  /**
   * Busca registros no banco.
   * @param {String} value
   */
  searchTable(value = "") {
    if (value.includes('*')) value = value.split('*').join('\\*')

    this.setState(
      () => {
        return {
          loadingTable: true
        };
      },
      () => {
        this.searchUsers(value, users => {
          const idUserList = users.map(user => user.id)
          setTimeout(async () => {
            let $find = { $or: [] };
            let _Query = new Parse.Query(this.props.module.collection);

            // Filtrar registro do usuário, caso tenha sido determinado no módulo
            if (this.props.module.filterByUser && this.Auth.hasAction([`${this.props.module.form.module}FilterUser`])) {
              let currentUserId = Parse.User.current().id
              if (this.props.module.filterByUser) _Query.equalTo('createdBy', { "__type": "Pointer", "className": "_User", "objectId": currentUserId })
            }

            // Filtrar registros, caso tenha sido determinado no módulo
            if (this.props.module.filterList && (await this.props.module.filterList())) _Query.equalTo((await this.props.module.filterList())['field'], (await this.props.module.filterList())['value'])

            // Ordenar resultados
            if (this.props.module.sortBy) {
              if (this.props.module.sortByType === 'descending') _Query.descending(this.props.module.sortBy)
              else _Query.ascending(this.props.module.sortBy)
            }

            _Query.include(this.schemaIncludes());

            _Query.find(allRegisters => {

              this.props.module.schema.forEach(async item => {
                if (item.type === "text") {
                  $find.$or.push({
                    [item.key]: {
                      $regex: value,
                      $options: 'i'
                    }
                  });
                } else if (item.type === "number" && !isNaN(value)) {
                  $find.$or.push({ [item.key]: parseInt(value) });
                } else if (item.type === 'array') {
                  $find.$or.push({
                    [item.key]: {
                      $regex: `\\Q${value}\\E`,
                      $options: 'i'
                    }
                  });
                }

                // Procurar nos campos Relations, caso exista
                if (item.isRelation) {
                  allRegisters.forEach(register => {
                    register.relation(item.key).query().find(snap => {
                      for (let i = 0; i < snap.length; i++) {
                        if (snap[i].get(item.fieldToSearch).toLowerCase().includes(value.toLowerCase())) {
                          return $find.$or.push({ objectId: register.id })
                        }
                      }
                      return null
                    })
                  })
                }

                // Procurar nos campos Pointer único, caso exista
                if (item.isUniquePointer) {
                  allRegisters.forEach(register => {
                    if (register.get(item.key)) {
                      if (register.get(item.key).get(item.fieldToSearch).toLowerCase().includes(value.toLowerCase())) {
                        $find.$or.push({ objectId: register.id })
                      }
                    }
                  })
                }

                // Procurar nos campos relacionados registrados com objectId string, caso exista
                if (item.isObjectIdString) {
                  let query_relation = new Parse.Query(item.relationClass).limit(20000);
                  let allRegistersRelation = await query_relation.find();
                  allRegisters.forEach(register => {
                    if (register.get(item.key)) {
                      let registerFound = allRegistersRelation.find(el => register.get(item.key) === el.id);
                      if (registerFound && registerFound.get(item.fieldToSearch).toLowerCase().includes(value.toLowerCase())) {
                        $find.$or.push({ objectId: register.id })
                      }
                    }
                  })
                }

              });
              // Alexis: Campo objectId removido das buscas, pois ele não aparece nas tabelas
              // $find.$or.push({
              //   objectId: {
              //     $regex: value,
              //     $options: 'i'
              //   }
              // })
              $find.$or.push({
                createdBy: {
                  $in: idUserList
                }
              })
              $find.$or.push({
                updatedBy: {
                  $in: idUserList
                }
              })

              if (value !== '') _Query._where = $find;

              // Filtrar registro por padrão definido no module
              if (this.props.module.form.defaultFilter) {
                _Query._where.$and = [];
                _Query._where.$and.push({
                  [this.props.module.form.defaultFilter.attr]: {
                    $regex: this.props.module.form.defaultFilter.value,
                    $options: 'i'
                  }
                });
              }

              // Verificar se o default page foi setado como last e a última página tem o resultado máximo
              let addPage = 0
              if (this.state.defaultCurrentPage === this.state.page && (this.state.pageLimit * this.state.page) === this.state.totalResult) addPage = 1

              _Query.limit(this.state.pageLimit)
              let skipNumber = this.state.pageLimit * (this.state.page - addPage)
              _Query.skip(skipNumber < 0 ? 0 : skipNumber)
              return _Query.count().then(totalResult => {
                this.setState(() => {
                  return {
                    page: this.state.page || this.state.defaultCurrentPage,
                    totalResult
                  }
                })
                _Query.find({
                  success: async (response) => {
                    response = await this.getRelations(response)
                    if (this.props.module.isManagementProposal) response = await this.setChildrenOfProposals(response)
                    this.normalizeTableRow(response);
                  },
                  error: err => console.log(err)
                });
              })
            })
          })
        })
      }
    );
  }

  /**
   * Configura os dados para renderizar a tabela com a listagem.
   * @param {Array} dataTable Registros da listagem.
   */
  normalizeTableRow(dataTable) {
    let headers = this.props.module.schema.map(schema => {
      return {
        key: schema.key,
        relation: schema.relation || null
      };
    });

    const loadData = (list, index, id, header) => {
      const _Extended = Parse.Object.extend(header.relation.className);
      const Query = new Parse.Query(_Extended);

      Query.get(id, {
        success: async (response) => {
          response = response.map(item => {
            return Object.assign({ key: item.id, _realResponse: item }, item.toJSON());
          });

          this.tableContent = response;
          this.setState(state => {
            return {
              loadingTable: false,
              dataTable: response
            };
          });
        }
      });
    };

    dataTable = dataTable.map((row, index) => {
      const rowJson = row.toJSON();
      let object = {
        rowKey: rowJson.objectId,
        key: rowJson.objectId,
        ...rowJson,
        _realResponse: row
      };

      headers.forEach(header => {
        if (!header.relation) {
          object[header.key] = rowJson[header.key];
        } else {
          if (rowJson[header.key]) {
            object[header.key] = <Icon type="loading" />;
            loadData(
              dataTable,
              index,
              rowJson[header.relation.name].objectId,
              header
            );
          }
        }
      });
      return object;
    });

    this.setState(state => {
      return {
        loadingTable: false,
        dataTable: dataTable,
        defaultCurrentPage: 0,
        defaultCurrentFilter: ''
      };
    });
  }

  /**
   * Edita registro e registra no storage.
   * @param {ParseObjectSubClass} row Registro. 
   */
  editRow(row) {
    if (this.props.onEditRow) this.props.onEditRow(row)
    localStorage.setItem(`Search:${this.props.module.form.module}`, this.state.searchTable)
    localStorage.setItem(`Pagination:${this.props.module.form.module}`, this.state.page)
  }

  /**
   * Renderiza modal de exclusão de registro.
   * @param {ParseObjectSubClass} row Registro.
   * @param {Number} index Index do registro a ser excluído.
   * @returns Modal de exclusão.
   */
  showDeleteConfirm(row, index) {
    Modal.confirm({
      title: "ATENÇÃO",
      content: "Tem certeza que deseja excluir este registro do sistema?",
      okText: "Remover",
      cancelText: "Não, deixa pra lá",
      okType: "danger",
      onOk: () => {
        if (this.props.module.form.submit.collection === `User`)
          return Parse.Cloud.run(`removeUser`, { objectId: row.objectId }).then(response => {
            this.removeElementTableByIndex(index)
          })
        this.removeItemTable(row, index);
      }
    });
  }

  /**
   * Remove item do bd.
   * @param {ParseObjectSubClass} row Registro.
   * @param {Number} index Index do registro a ser excluído.
   */
  async removeItemTable(row, index) {
    let _Query = new Parse.Query(this.props.module.collection);

    // Filtrar registro do usuário, caso tenha sido determinado no módulo
    if (this.props.module.filterByUser && this.Auth.hasAction([`${this.props.module.form.module}FilterUser`])) {
      let currentUserId = Parse.User.current().id
      if (this.props.module.filterByUser) _Query.equalTo('createdBy', { "__type": "Pointer", "className": "_User", "objectId": currentUserId })
    }

    // Filtrar registros, caso tenha sido determinado no módulo
    if (this.props.module.filterList && (await this.props.module.filterList())) _Query.equalTo((await this.props.module.filterList())['field'], (await this.props.module.filterList())['value'])

    _Query.get(row.objectId, {
      success: object => {
        object.destroy({});
        // this.loadTableContent();
        this.removeElementTableByIndex(index)
      }
    });
  }

  /**
   * Remove item da listagem.
   * @param {Number} index Index do registro a ser excluído.
   */
  removeElementTableByIndex(index) {
    let list = [...this.state.dataTable]
    list.splice(index, 1)
    this.setState(state => {
      return {
        dataTable: list
      }
    })
  }

  /**
   * Busca as últimas configurações da listagem no storage e aplicar na listagem atual.
   * @param {null}
   */
  recoveryState() {
    let searchTable = localStorage.getItem(`Search:${this.props.module.form.module}`) || ""
    let page = parseInt(localStorage.getItem(`Pagination:${this.props.module.form.module}`)) || 0
    this.setState(() => {
      return {
        searchTable: searchTable,
        page,
        defaultCurrentPage: page,
        defaultCurrentFilter: searchTable
      }
    }, () => {
      setTimeout(() => {
        this.searchTable(searchTable)
        setTimeout(() => this.clearRecoveredState(), 1000)
      });
    })
  }

  /**
   * Limpar as configurações do storage da listagem.
   * @param {null}
   */
  clearRecoveredState() {
    localStorage.setItem(`Search:${this.props.module.form.module}`, '')
    localStorage.setItem(`Pagination:${this.props.module.form.module}`, 0)

  }

  /**
   * Agrupa as propostas e inseri as versões na proposta pai.
   * @description Este método é especifico para o módulo gerenciamento de propostas.
   * @param {Array} response Lista de registros.
   */
  async setChildrenOfProposals(response) {
    const proposalsCode = [...new Set(response.map(el => `${el.get('proposal_code').split('-')[0]}-${el.get('proposal_code').split('-')[1]}`))]
    const proposals = await findWhereMultiple(
      'Proposal',
      proposalsCode.map(proposalCode => {
        return { 'field': 'proposal_code', 'value': proposalCode, 'op': 'like', logicOp: '$or' }
      }), ['sales_channel'], null, null, 'updatedAt'
    )

    let parentResponse = []
    for (let proposalCodeIndex = 0; proposalCodeIndex < proposalsCode.length; proposalCodeIndex++) {
      const proposalCode = proposalsCode[proposalCodeIndex]
      const proposalsToPop = proposals.filter(el => `${el.get('proposal_code').split('-')[0]}-${el.get('proposal_code').split('-')[1]}` === proposalCode)
      let parentProposal = proposalsToPop[proposalsToPop.length - 1].clone()

      await parentProposal.set('updatedAtDateTime', new Date(proposalsToPop[proposalsToPop.length - 1].get('updatedAt')))

      await parentProposal.set('all_proposals',
        proposalsToPop.map(el => {
          return { ...el.toJSON(), key: el.id }
        })
      )
      parentResponse.push(parentProposal)
    }

    return parentResponse
      .sort((a, b) => (a.get('updatedAtDateTime') > b.get('updatedAtDateTime')) ? 1 : ((b.get('updatedAtDateTime') > a.get('updatedAtDateTime')) ? -1 : 0))
  }

}