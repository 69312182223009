const surveyQuestionsAndAnswers = [
    {
        question: {
            key: 'alreadyKnowMultidoor',
            label: 'Você já conhece ou usou portas Multidoor?',
        },
        answers: [
            {
                key: 'yes_alreadyUsed',
                label: 'Sim, usei em uma obra e reconheço o valor.',
            },
            {
                key: 'yes_knowAnotherUser',
                label: 'Sim, conheço empreendimento com porta Multidoor.',
            },
            {
                key: 'yes_byEvents',
                label: 'Sim, através de eventos setoriais e feiras.',
            },
            {
                key: 'no_butWasIndicated',
                label: 'Não, mas recebi uma indicação com recomendação.',
            },
            {
                key: 'no_butWould',
                label: 'Não, mas gostaria de uma apresentação da empresa.',
            },
        ]
    },
    {
        question: {
            key: 'whatYourBigChallenge',
            label: 'Qual o seu maior desafio no momento?',
        },
        answers: [
            {
                key: 'findProvider',
                label: 'Encontrar um fornecedor de portas qualificado.',
            },
            {
                key: 'toFitSpecification',
                label: 'Adequar a especificação para a norma de portas.',
            },
            {
                key: 'meetThePerformance',
                label: 'Atender o nivel de exigencia do meu projeto.',
            },
            {
                key: 'buyBestDoor',
                label: 'Comprar portas de melhor qualidade.',
            },
            {
                key: 'keepTheBudget',
                label: 'Manter o orçamento da minha obra.',
            },
        ]
    },
    {
        question: {
            key: 'workStandard',
            label: 'Qual o padrão da sua obra?',
        },
        answers: [
            {
                key: 'minimum',
                label: 'Mínimo.',
            },
            {
                key: 'medium',
                label: 'Médio.',
            },
            {
                key: 'mediumHigh',
                label: 'Médio alto.',
            },
            {
                key: 'highStandard',
                label: 'Alto padrão.',
            },
            {
                key: 'highLuxury',
                label: 'Alto luxo.',
            },
        ]
    },
    {
        question: {
            key: 'whatDoorQualityRepresents',
            label: 'O que representa qualidade para as portas do seu projeto?',
        },
        answers: [
            {
                key: 'core',
                label: 'Porta sarrafeada.',
            },
            {
                key: 'wood',
                label: 'Madeira maciça.',
            },
            {
                key: 'alternativeWood',
                label: 'Madeira engenheirada.',
            },
            {
                key: 'heavyDoor',
                label: 'Porta pesada.',
            },
            {
                key: 'normativePerformance',
                label: 'Desempenho normativo.',
            },
        ]
    },
    {
        question: {
            key: 'costBenefit',
            label: 'Como será analisada a relação custo beneficio das portas?',
        },
        answers: [
            {
                key: 'aggregateValue',
                label: 'Pelo melhor valor agregado.',
            },
            {
                key: 'performanceLevel',
                label: 'Pelo nível de desempenho da porta.',
            },
            {
                key: 'lifespan',
                label: 'Pela maior vida útil do produto.',
            },
            {
                key: 'maintenanceCost',
                label: 'Pela menor custo de manutenção.',
            },
            {
                key: 'price',
                label: 'Pelo menor preço de aquisição.',
            },
        ]
    },
    {
        question: {
            key: 'whoIsInvolved',
            label: 'Quem mais está envolvido na definição das portas?',
        },
        answers: [
            {
                key: 'architect',
                label: 'Arquitetura.',
            },
            {
                key: 'engineering',
                label: 'Engenharia da obra.',
            },
            {
                key: 'direction',
                label: 'Diretoria.',
            },
            {
                key: 'owner',
                label: 'Proprietário.',
            },
            {
                key: 'investor',
                label: 'Investidor.',
            },
        ]
    },
    {
        question: {
            key: 'buyPrevision',
            label: 'Qual a sua previsão de compra de portas?',
        },
        answers: [
            {
                key: 'thirtyDays',
                label: 'até 30 dias.',
            },
            {
                key: 'ninetyDays',
                label: '90 dias.',
            },
            {
                key: 'sixMonths',
                label: '6 meses.',
            },
            {
                key: 'OneYear',
                label: '1 ano.',
            },
            {
                key: 'afterOneYear',
                label: '+1 ano.',
            },
        ]
    },
    {
        question: {
            key: 'lastDoorProvider',
            label: 'Quem foi o seu último fornecedor de kit porta pronta?',
        },
        answers: [
            {
                key: 'firstDoorBuyer',
                label: 'Estou comprando portas pela primeira vez.',
            },
            {
                key: 'componentsBuyer',
                label: 'Comprei componentes para montar na obra.',
            },
            {
                key: 'woodWork',
                label: 'Marcenaria especializada.',
            },
            {
                key: 'approvedIndustry',
                label: 'Indústria homologada pela Construtora.',
            },
            {
                key: 'traditionalProvider',
                label: 'Fornecedor tradicional, porém queremos mudar.',
            },
        ]
    },
]

/*
{
    question: {
        key: '',
        label: '',
    },
    answers: [
        {
            key: '',
            label: '',
        },
    ]
}
*/

export {
    surveyQuestionsAndAnswers,
}