import { Col, Divider, Form, Row, Select, Checkbox, InputNumber } from "antd";
import React, { Fragment } from "react";

const productStepPriceEquilization = ({
  index,
  getProductValue,
  updateProductData,
  _this,
}) => {
  return (
    <Fragment>
      <div style={{ background: '#f5f5f5', padding: '10px 20px', borderRadius: '4px', border: '1px solid #d9d9d9' }}>
        <Row gutter={12} className="chunk">
          <h2>Equalização de Preço unitário</h2>
        </Row>

        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="Desconto (%)">
              <Select
                disabled={
                  _this.props.disabledFields ||
                  !(
                    _this.props.auth &&
                    _this.props.auth["_discountPerItemPermission"]
                  )
                }
                placeholder="Desconto (%)"
                size="large"
                value={getProductValue("unit_discount")}
                onChange={async (value) => {
                  updateProductData("unit_discount", index, value);
                }}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                <Select.Option value={0.05}>5%</Select.Option>
                <Select.Option value={0.1}>10%</Select.Option>
                <Select.Option value={0.15}>15%</Select.Option>
                <Select.Option value={0.2}>20%</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Desconto (R$)">
              <InputNumber
                disabled={
                  _this.props.disabledFields ||
                  !(
                    _this.props.auth &&
                    _this.props.auth["_discountPerItemPermission"]
                  )
                }
                placeholder="Desconto (R$)"
                size="large"
                value={getProductValue("unit_discount_value")}
                onChange={async (value) => {
                  updateProductData("unit_discount_value", index, value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label="Adicional (%)">
              <Select
                disabled={
                  _this.props.disabledFields ||
                  !(
                    _this.props.auth &&
                    _this.props.auth["_discountPerItemPermission"]
                  )
                }
                placeholder="Adicional (%)"
                size="large"
                value={getProductValue("unit_additional")}
                onChange={async (value) => {
                  updateProductData("unit_additional", index, value);
                }}
              >
                <Select.Option value={null}>Selecione...</Select.Option>
                <Select.Option value={0.05}>5%</Select.Option>
                <Select.Option value={0.1}>10%</Select.Option>
                <Select.Option value={0.15}>15%</Select.Option>
                <Select.Option value={0.2}>20%</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label="Adicional (R$)">
              <InputNumber
                disabled={
                  _this.props.disabledFields ||
                  !(
                    _this.props.auth &&
                    _this.props.auth["_discountPerItemPermission"]
                  )
                }
                placeholder="Adicional (R$)"
                size="large"
                value={getProductValue("unit_additional_value")}
                onChange={async (value) => {
                  updateProductData("unit_additional_value", index, value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Sem dobradiças">
              <Checkbox
                disabled={
                  _this.props.disabledFields ||
                  !(
                    _this.props.auth &&
                    _this.props.auth["_removeDobradicaPermission"]
                  )
                }
                checked={getProductValue("deleteDobradica")}
                onChange={(value) => {
                  updateProductData(
                    "deleteDobradica",
                    index,
                    value.target.checked
                  );
                }}
              >
                Retirar dobradiças deste item
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        
        Somente para usuário autorizado
      </div>

      <Divider dashed/>
    </Fragment>
  );
};

export default productStepPriceEquilization;
