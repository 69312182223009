import { Col, Divider, Form, Row, Select, Checkbox } from "antd"
import React, { Fragment } from "react"

const productStepSheetNewFinishingLogic = ({
    index,
    getProductValue,
    updateProductData,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    _this,
    isRequiredProductComponent,
}) => {

    return (
        <Fragment>
            <h2>Cadastro da folha da Porta</h2>

            <Row gutter={12} className="size chunk">
                <h3>Porta básica</h3>
                <Col span={9}>
                    <Form.Item label="Acabamento" required>
                        <Select
                            value={
                                getProductValue('finishing_sheet') && getProductValue('sheet_option_finishing') ?
                                    `${getProductValue('finishing_sheet')}__${getProductValue('sheet_option_finishing')}`
                                    : getProductValue('finishing_sheet')
                            }
                            onChange={async value => {
                                updateProductData('finishing_sheet', index, value.includes("__") ? value.split("__")[0] : value)
                                updateProductData('sheet_option_finishing', index, value.includes("__") ? value.split("__")[1] : null)
                                updateProductData('pattern_sheet', index, null)
                            }}
                            placeholder="Acabamento" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                (_this.state.kitsComponents['comp_folha'] || [])
                                    .filter(el => {
                                        // Filter sheet by height and width
                                        return (
                                            (el.get('height_component') || '').toString() === getProductValue('prod_height') &&
                                            (el.get('width_component') || '').toString() === getProductValue('prod_width')
                                        )
                                    })
                                    .filter(el => el.get('finishing_options_inputs'))
                                    .map(el => {
                                        let finishings = []
                                        const finishingIds = el.get('finishing_options_inputs') || []
                                        for (let finishingIndex = 0; finishingIndex < finishingIds.length; finishingIndex++) {
                                            const finishingObject = finishingIds[finishingIndex];
                                            const finishing = _this.props.finishings.find(finishing => finishing.finishingId === finishingObject.finishing) || null
                                            if (finishing)
                                                finishings.push({
                                                    ...finishing,
                                                    sheetFinishing: el.id
                                                })
                                        }
                                        return finishings
                                    })
                                    .flat()
                                    .filter(el => el)
                                    .sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
                                    .map(el => <Select.Option value={`${el.sheetFinishing}__${el.finishingId}`}>{el.code} - {el.description}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>


                <Col span={9}>
                    <Form.Item label="Padrão de cor" required>
                        <Select value={getProductValue('pattern_sheet')} onChange={value => updateProductData('pattern_sheet', index, value)} placeholder="Padrão de cor" size="large" disabled={_this.props.disabledFields}>
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                getPatternOfAFinishing(getProductValue('sheet_option_finishing'), _this.props.finishings)
                                    .map(el => {
                                        return (
                                            <Select.Option value={el.objectId}>{el.pattern}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label="Folha inativa (kits assimétricos)" required={isRequiredProductComponent('finishing_sheet_inactive')}>
                        <Select
                            value={getProductValue('finishing_sheet_inactive')}
                            onChange={async value => {
                                updateProductData('finishing_sheet_inactive', index, value)
                            }}
                            placeholder="Folha inativa" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                (
                                    (_this.state.kitsComponents['comp_folha_inativa'] || []) || [])
                                    .filter(el => {
                                        // Filter sheet by height
                                        return (
                                            (el.get('height_component') || '').toString() === getProductValue('prod_height')
                                        );
                                    })
                                    .map(el => el.toJSON())
                                    .sort((a, b) => (a.width_component > b.width_component) ? 1 : ((b.width_component > a.width_component) ? -1 : 0))
                                    .map(el => {
                                        return (
                                            <Select.Option value={el['objectId']}>{el.width_component}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        <Checkbox
                            disabled={_this.props.disabledFields}
                            onChange={value => updateProductData('sheet_retrofit_additional', index, value.target.checked)}
                            checked={getProductValue('sheet_retrofit_additional')}
                        >
                            Adicional Retrofit
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Divider dashed={true} />
        </Fragment>
    )

}

export default productStepSheetNewFinishingLogic