import { Icon, Modal } from 'antd'
import React from 'react'
import SatisfactionSurveyReport from './entities/satisfaction-survey-report'

export default {
    fields: [
        [],
    ],
    submit: { collection: 'PcpoSurvey' },
    schema: [
        {
            title: 'Pedido',
            key: 'order',
            dataIndex: 'order',
            type: 'text',
            width: '150px',
        },
        {
            title: 'Cliente',
            key: 'client',
            dataIndex: 'client',
            type: 'text',
        },
        {
            title: 'Obra',
            key: 'work',
            dataIndex: 'work',
            type: 'text',
        },
        {
            title: "Experiência Multidoor",
            key: "form",
            dataIndex: 'form',
            align: 'center',
            render: (form, survey) => {
                return (
                    <span
                        className='link-style'
                        onClick={() => {
                            Modal.confirm({
                                title: `Cliente: ${survey.client}`,
                                width: 600,
                                iconType: 'message',
                                content:
                                    <div>
                                        <br />

                                        <div><strong>Experiência Multidoor</strong></div>
                                        {form.multidoorExperience}<br /><br />

                                        <div><strong>Mais relevante no atendimento técnico</strong></div>
                                        {form.whatMostRelevant}<br /><br />

                                        <div><strong>Acompanhamento do processo através do Status semanal</strong></div>
                                        {form.processMonitoringByWeeklyStatus}<br /><br />

                                        <div><strong>Produto final atendeu as expectativas</strong></div>
                                        {form.finalProductExpectation}<br /><br />

                                        <div><strong>Processo de instalação das portas</strong></div>
                                        {form.instalationProccess}<br /><br />

                                        <div><strong>Comparativo do processo das portas com outros serviços na obra</strong></div>
                                        {form.doorComparative}<br /><br />

                                        <div><strong>Houve assistência técnica</strong></div>
                                        {form.techAssistance}<br /><br />

                                        <div><strong>Satisfação final</strong></div>
                                        {form.finalSatisfaction}<br /><br />
                                    </div>,
                            })
                        }}>
                        {form['multidoorExperience'] || '-'}
                    </span>
                )
            }
        }
    ],
    'title-navigation': <div><Icon type="message" /> Pesquisa de satisfação</div>,
    'title-module': 'Pesquisa de satisfação',
    'module': 'PcpoSatisfactionSurvey',
    'router-base': '/panel/pcpo-pesquisa-satisfacao',
    'topBarAction': () => <SatisfactionSurveyReport />,
}