import { Col, Form, Input, InputNumber, Modal, Row, Select, Switch } from "antd"
import React, { Fragment } from "react"

const productStepGeneralInfo = ({
    blockIndex,
    index,
    v,
    getProductValue,
    updateProductData,
    _this,
    updateRequiredFields,
}) => {

    const checkIfIsNecessaryClearKit = (field, index, value, kit) => {

        if (kit) {
            let __this = _this
            Modal.confirm({
                title: 'Tem certeza que deseja realizar essa alteração?',
                content: 'Isso apagará o kit selecionado e todos seus componentes.',
                onOk: async () => {
                    __this.updateProductData(
                        'product_code',
                        blockIndex,
                        index,
                        null,
                    )

                    // Limpar os dados selecionados anteriormente
                    let productBlocks = __this.props.getValue('product_blocks')
                    let dontChangeAttr = ['amount', 'env', 'env_obra', 'product_code', 'prod_height', 'prod_height_marco', 'prod_width', 'profile_performance', 'project_code', 'typology', 'model']
                    Object.keys(productBlocks[blockIndex].products[index]).forEach(key => {
                        if (!dontChangeAttr.includes(key)) {
                            if (key === 'wall_panel')
                                productBlocks[blockIndex].products[index][key] = [
                                    {
                                        "panel_wall_type": null,
                                        "panel_wall_dimensions": null,
                                        "panel_wall_module": null,
                                        "panel_wall_finishing": null,
                                        "panel_wall_pattern": null,
                                        "panel_wall_instalation": null
                                    }
                                ]
                            else
                                productBlocks[blockIndex].products[index][key] = null
                        }
                    })
                    __this.props.updateDataValue('product_blocks', productBlocks)
                    updateProductData(field, index, value)
                    __this.findKits(v)
                },
            })
        } else {
            updateProductData(field, index, value)
            _this.findKits(v)
        }
    }

    return (
        <Fragment>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label="Código de obra">
                        <Input defaultValue={getProductValue('project_code')} onBlur={event => updateProductData('project_code', index, event.target.value)} placeholder="Código de obra" size="large" disabled={_this.props.disabledFields} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Altura Marco">
                        <Select value={getProductValue('prod_height_marco')}
                            onChange={value => {
                                checkIfIsNecessaryClearKit('prod_height_marco', index, value, getProductValue('product_code'))
                            }}
                            placeholder="Altura Marco" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(_this.state.dataConfigs.alturas_folha || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Altura da Folha">
                        <Select value={getProductValue('prod_height')}
                            onChange={value => {
                                checkIfIsNecessaryClearKit('prod_height', index, value, getProductValue('product_code'))
                            }}
                            placeholder="Altura da Folha" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(_this.state.dataConfigs.alturas_folha || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Largura">
                        <Select value={getProductValue('prod_width')}
                            onChange={value => {
                                checkIfIsNecessaryClearKit('prod_width', index, value, getProductValue('product_code'))
                            }}
                            placeholder="Largura" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(_this.state.dataConfigs.larguras_folha || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>

                <Col span={5}>
                    <Form.Item label="Ambiente" required>
                        <Select
                            value={getProductValue('env')}
                            onChange={value => {
                                // checkIfIsNecessaryClearKit('env', index, value, getProductValue('product_code'))
                                updateProductData('env', index, value)
                                _this.findKits(v)
                            }}
                            placeholder="Ambiente" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(_this.state.dataConfigs.kit_amb || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item label="Perfil de Desempenho" required>
                        <Select
                            value={getProductValue('profile_performance')}
                            onChange={value => {
                                // checkIfIsNecessaryClearKit('profile_performance', index, value, getProductValue('product_code'))
                                updateProductData('profile_performance', index, value)
                                _this.findKits(v)
                            }}
                            placeholder="Perfil de Desempenho" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                (_this.state.performanceData[_this.props.getValue('performance_level')] || [])
                                    .filter(v => {
                                        // Filtrar perfil de desempenho de acordo com o ambiente
                                        return (getProductValue('env') &&
                                            ((getProductValue('env').includes('Molh') && v.includes("RU")) ||
                                                (getProductValue('env').includes('Exterior') && v.includes("PXM")) ||
                                                (getProductValue('env').includes('Seco')))
                                        )
                                    })
                                    .map(profilePerformance => {
                                        const index = ['PIM', 'PEM', 'PIA', 'PAR', 'PRF', 'PFA', 'PSH', 'PSG', 'PDS'].findIndex(el => profilePerformance.startsWith(el))
                                        return `${index}__${profilePerformance}`
                                    })
                                    .sort()
                                    .map(v => {
                                        v = v.split('__')
                                        return <Select.Option value={v[1]} key={v}>{v[1]}</Select.Option>
                                    })}
                        </Select>

                    </Form.Item>
                </Col>

                <Col span={9}>
                    <Form.Item label="Tipologia" required>
                        <Select value={getProductValue('typology')}
                            onChange={value => {
                                checkIfIsNecessaryClearKit('typology', index, value, getProductValue('product_code'))
                            }}
                            placeholder="Tipologia" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                getProductValue('profile_performance') && getProductValue('prod_width') && getProductValue('prod_height') ?
                                    _this.renderTypology(getProductValue('profile_performance'), getProductValue('prod_width'), getProductValue('prod_height'), (getProductValue('prod_height_marco') || getProductValue('prod_height')))
                                    : null
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={5}>
                    <Form.Item label="Modelo" required>
                        <Select
                            value={getProductValue('model')}
                            onChange={value => updateProductData('model', index, value)}
                            placeholder="Modelo" size="large"
                            disabled={_this.props.disabledFields}
                            className="input-block-title"
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(_this.state.dataConfigs.modelos || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>

                <Col span={20}>
                    <Form.Item label="Selecionar Kit" required>
                        <Select
                            value={getProductValue('product_code')}
                            onChange={value => {
                                if (getProductValue('product_code')) {
                                    // Modal indicando que os dados serão removidos
                                    let __this = _this
                                    Modal.confirm({
                                        title: 'Tem certeza que deseja alterar o kit?',
                                        content: 'Esta alteração apagará os dados do produto selecionado.',
                                        onOk: async () => {
                                            __this.getAllComponentsFromKit(value);

                                            __this.updateProductData(
                                                'product_code',
                                                blockIndex,
                                                index,
                                                value,
                                            )

                                            // Limpar os dados selecionados anteriormente
                                            let productBlocks = __this.props.getValue('product_blocks')
                                            let dontChangeAttr = ['amount', 'env', 'env_obra', 'product_code', 'prod_height', 'prod_height_marco', 'prod_width', 'profile_performance', 'project_code', 'typology', 'model']
                                            Object.keys(productBlocks[blockIndex].products[index]).forEach(key => {
                                                if (!dontChangeAttr.includes(key)) {
                                                    if (key === 'wall_panel')
                                                        productBlocks[blockIndex].products[index][key] = [
                                                            {
                                                                "panel_wall_type": null,
                                                                "panel_wall_dimensions": null,
                                                                "panel_wall_module": null,
                                                                "panel_wall_finishing": null,
                                                                "panel_wall_pattern": null,
                                                                "panel_wall_instalation": null
                                                            }
                                                        ]
                                                    else
                                                        productBlocks[blockIndex].products[index][key] = null
                                                }
                                            })
                                            __this.props.updateDataValue('product_blocks', productBlocks)
                                        },
                                    })
                                } else {
                                    // const kit = _this.state.kits.find(el => el.id === value)
                                    _this.getAllComponentsFromKit(value)

                                    _this.updateProductData(
                                        'product_code',
                                        blockIndex,
                                        index,
                                        value,
                                    )
                                }

                                // Tornar required o campo de folha inativa
                                if (_this.state.kitsComponents['comp_folha_inativa'].length)
                                    updateRequiredFields("finishing_sheet_inactive")
                                else
                                    updateRequiredFields("finishing_sheet_inactive", true)

                                _this.setState({ loading: false })

                            }}
                            placeholder="Código do kit" size="large" disabled={_this.props.disabledFields}
                        >
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {
                                _this.state.kits
                                    .filter(el => el.get('name').toLowerCase().includes((getProductValue('model') || '').toLowerCase()))
                                    .map(el => {
                                        return (
                                            <Select.Option value={el.id}>{el.get('code')} - {el.get('name')}</Select.Option>
                                        );
                                    })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item label="Quantidade" required>
                        <InputNumber
                            value={getProductValue('amount')}
                            onChange={value => updateProductData('amount', index, value)}
                            placeholder="Quantidade"
                            size="large"
                            type='number'
                            disabled={_this.props.disabledFields}
                            min={0}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>

                <Col span={4}>
                    <Form.Item label="Inserir apenas componente?">
                        <Switch disabled={_this.props.disabledFields} checked={getProductValue('search_componentKit')} onChange={value => updateProductData('search_componentKit', index, value, () => {
                            _this.clearProduct(blockIndex, index)
                        })} />
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item label="Tipo de Componente">
                        <Select disabled={!getProductValue('search_componentKit') || _this.props.disabledFields} value={getProductValue('product')}
                            onChange={value => {
                                _this.clearProduct(blockIndex, index);
                                updateProductData('product', index, value);
                            }}
                            placeholder="Produto" size="large">
                            <Select.Option value={null}>Selecione...</Select.Option>
                            {(_this.state.dataConfigs.tipo_componente || []).map(v => {
                                return <Select.Option value={v} key={v}>{v}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
        </Fragment>
    )
}

export default productStepGeneralInfo