import React from 'react';
import { Icon } from 'antd';

export default {
    "fields": [
        [
            {
                title: 'Assunto',
                key: 'subject',
                type: 'text',
                colSize: 24,
                beforeSave: async (Form, ParseObject, node) => {
                    await ParseObject.set('type', 'industrial-facilities')
                    return true;
                }
            },
        ],
        [
            {
                key: 'files',
                type: 'files'
            }
        ],
    ],
    "submit": {
        "collection": "IndustrialMaintenenceDocs"
    },
    "schema": [
        {
            "title": "Assunto",
            "key": "subject",
            "dataIndex": "subject",
            "type": "text"
        },
    ],
    "title-module": "Instalações industriais",
    "title-navigation": <div><Icon type="api" /> Instalações industriais</div>,
    "module": "IndustrialMaintenenceDocs",
    "router-base": "/panel/instalacoes-industriais",
    'filterList': () => {
        return {
            'field': 'type',
            'value': 'industrial-facilities',
        }
    }
}

