const providerBankingFields = (props) => {
    return [
        [
            {
                type: 'dynamic-input-list',
                key: 'bankings',
                fields: [
                    [
                        {
                            "title": "Banco",
                            "key": "bank",
                            "type": "text",
                            'colSize': 6,
                            "element-attr": { "required": false },
                        },
                        {
                            "title": "Agência",
                            "key": "agency",
                            "type": "text",
                            'colSize': 4,
                            "element-attr": { "required": false },
                        },
                        {
                            "title": "CC",
                            "key": "account",
                            "type": "text",
                            'colSize': 4,
                            "element-attr": { "required": false },
                        },
                        {
                            "title": "Chave PIX",
                            "key": "pix",
                            "type": "text",
                            'colSize': 10,
                            "element-attr": { "required": false },
                        },
                    ]
                ]
            }
        ],
    ]
}

export default providerBankingFields