import React from 'react';
import { Icon, Select, Tag } from 'antd';
import { findWhere } from '../../../utils/db_functions';

let typologies = [];

export default {
  "fields": [
    [
      {
        type: 'select',
        key: 'performances',
        title: 'Desempenho',
        options: [],
        optionConfig: { name: 'performance' },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
        colSize: 12,
      },
      {
        type: 'select',
        key: 'typology',
        title: 'Tipologia',
        options: [],
        optionConfig: { name: 'typology', arrayWithId: true },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
        colSize: 12,
        render: (options) => {
          let opt = options.reduce((acc, v) => {
            let type = v.label.split(' ')[0]
            if (!acc[type]) {
              acc[type] = {
                children: []
              }
            }
            acc[type].children.push(v)
            return acc;

          }, {});
          let types = Object.keys(opt)
          return types.map((type, index) => {
            return (
              <Select.OptGroup label={types[index]} key={`group-${index}`}>
                {opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
              </Select.OptGroup>
            )
          })
        }
      },
    ],
    [
      {
        title: "Painel 6mm",
        key: "panel_6mm",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            $and: [
              {
                $or: [
                  { 'group': 'Painel' },
                ]
              }
            ]
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
    [
      {
        title: "Painel 15mm",
        key: "panel_15mm",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            $and: [
              {
                $or: [
                  { 'group': 'Painel' },
                ]
              }
            ]
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
    [
      {
        title: "Painel 18mm",
        key: "panel_18mm",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            $and: [
              {
                $or: [
                  { 'group': 'Painel' },
                ]
              }
            ]
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
    [
      {
        title: "Painel Steel Frame",
        key: "steel_frame_panel",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            $and: [
              {
                $or: [
                  { 'group': 'Painel' },
                ]
              }
            ]
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
  ],
  "submit": {
    "collection": "MatrizPanel"
  },
  "className": "config-component",
  ListWillMount: async () => {
    const typologiesParseArray = await findWhere('Config', 'name', ['typology']);
    typologies = typologiesParseArray.length && typologiesParseArray[0].get('array_with_id');
  },
  "schema": [
    {
      "title": "Desempenho",
      "key": "performances",
      "type": "text",
      render: row => (row.performances || []).map((item, key) => (<Tag key={key}>{item}</Tag>))
    },
    {
      title: 'Tipologia',
      key: 'typology',
      dataIndex: 'typology',
      type: 'render',
      render: v => {
        return (v || [])
          .map((usage, i) => {
            const typology = typologies.find(el => el.value === usage)
            return (
              <Tag key={i}>
                {typology && typology.label}
              </Tag>
            )
          })
      }

    }
  ],
  "title-navigation": <div><Icon type="windows" /> Matriz de Painéis</div>,
  "title-module": "Matriz de Painéis",
  "module": "MatrizPanel",
  "router-base": "/panel/matriz-paineis"
}