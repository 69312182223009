import { Button, Col, Form, InputNumber, Modal, Row, Select, Table } from 'antd'
import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { kebabfy } from '../../../../utils/general_functions'

export default class Qrp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            qrpModal: false,

            prod_height_marco: null,
            prod_height: null,
            env: null,
            typology: null,
            model: null,

            qrp: null,

            columns: [],
            dataSource: [],
        }
    }

    summaryTableOfDoorsColumns = [
        'Classe', 'PIM', 'PIM RU', 'PEM', 'PEM RU', 'PIA', 'PIA RU',
        'PAR', 'PAR RU', 'PRF', 'PRF RU', 'PSH', 'PDS', 'PXM', 'PSG',
    ]
    summaryTableOfDoorsRows = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6']

    componentDidMount = async () => {


        const qrp = this.summaryTableOfDoorsRows.map(() => this.summaryTableOfDoorsColumns.map(() => 0))
        this.setState({ qrp })

        const columns = this.summaryTableOfDoorsColumns.map((el, index) => {
            let column = { title: el, dataIndex: kebabfy(el), key: kebabfy(el), width: 80, align: 'center' }
            if (index === 0) column['fixed'] = 'left'
            return column
        })

        const dataSource = this.summaryTableOfDoorsRows.map((el, rowIndex) => {
            let defaultPerformances = {}
            this.summaryTableOfDoorsColumns.forEach((performance, columnIndex) => {
                if (performance !== 'Classe') defaultPerformances[kebabfy(performance)] = this.linkToEditQtd(rowIndex, columnIndex - 1)
            })
            return Object.assign({ key: kebabfy(el), classe: el }, defaultPerformances)
        })

        this.setState({
            prod_height_marco: null,
            prod_height: null,
            env: null,
            typology: null,
            model: null,

            columns,
            dataSource,
        })
    }

    linkToEditQtd = (rowIndex, columnIndex) => {
        if (this.state.qrp)
            return (
                <InputNumber
                    size='small'
                    defaultValue={this.state.qrp[rowIndex][columnIndex]}
                    onChange={e => {
                        let qrp = this.state.qrp
                        qrp[rowIndex][columnIndex] = e
                        this.setState({ qrp })
                    }}
                />
            )

        return ''
    }

    renderTypology() {
        let typology = []
        this.props.sheetDimension.forEach(v => typology.push(v.get('typology')))
        typology = _.uniq(typology.flat())

        return typology.map((type, index) => {
            return (
                <Select.Option key={index} value={type}>{type}</Select.Option>
            )
        })
    }

    generateProducts = () => {
        let products = []
        this.state.qrp.forEach((partTwo, partTwoIndex) => {
            partTwo.forEach((partOne, partOneIndex) => {
                if (partOne)
                    products.push({
                        amount: partOne,
                        profile_performance: `${this.summaryTableOfDoorsColumns[partOneIndex + 1]}-${this.summaryTableOfDoorsRows[partTwoIndex]}`,
                        prod_height_marco: this.state.prod_height_marco,
                        prod_height: this.state.prod_height,
                        env: this.state.env,
                        typology: this.state.typology,
                        model: this.state.model,
                        wall_panel: [{
                            panel_wall_type: null,
                            panel_wall_dimensions: null,
                            panel_wall_module: null,
                            panel_wall_finishing: null,
                            panel_wall_pattern: null,
                            panel_wall_instalation: null,
                        }]
                    })
            });
        })
        this.props.generateItens(products)
        this.setState({ qrpModal: false })
    }

    render() {
        return (
            <Fragment>
                <Button
                    style={{ marginLeft: '20px' }}
                    type='primary'
                    onClick={async () => {
                        if (this.props.products.length > 1) {
                            const _this = this
                            Modal.confirm({
                                title: 'Bloco contém mais de um item',
                                content: 'Essa ação irá apagar os itens desse bloco',
                                onOk: async () => {
                                    await _this.componentDidMount()
                                    _this.setState({ qrpModal: true })
                                },
                            })
                        } else {
                            await this.componentDidMount()
                            this.setState({ qrpModal: true })
                        }
                    }}
                >Gerar itens com QRP</Button>

                <Modal
                    title='Quadro Resumo de Portas'
                    visible={this.state.qrpModal}
                    onCancel={() => this.setState({ qrpModal: false })}
                    onOk={() => {
                        this.generateProducts()
                    }}
                    width={1000}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Altura do Marco'>
                                <Select
                                    style={{ width: '100%' }}
                                    value={this.state.prod_height_marco}
                                    onChange={value => this.setState({ prod_height_marco: value })}
                                    placeholder="Altura Marco" size="large"
                                >
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.props.dataConfigs.alturas_folha || []).map(v => {
                                        return <Select.Option value={v} key={v}>{v}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Altura da Folha'>
                                <Select
                                    style={{ width: '100%' }}
                                    value={this.state.prod_height}
                                    onChange={value => this.setState({ prod_height: value })}
                                    placeholder="Altura da Folha" size="large"
                                >
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.props.dataConfigs.alturas_folha || []).map(v => {
                                        return <Select.Option value={v} key={v}>{v}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Ambiente'>
                                <Select
                                    style={{ width: '100%' }}
                                    value={this.state.env}
                                    onChange={value => this.setState({ env: value })}
                                    placeholder="Ambiente" size="large"
                                >
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.props.dataConfigs.kit_amb || []).map(v => {
                                        return <Select.Option value={v} key={v}>{v}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item label='Tipologia'>
                                <Select
                                    style={{ width: '100%' }}
                                    value={this.state.typology}
                                    onChange={value => this.setState({ typology: value })}
                                    placeholder="Tipologia" size="large"
                                >
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {this.renderTypology()}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="Modelo" required>
                                <Select
                                    style={{ width: '100%' }}
                                    value={this.state.model}
                                    onChange={value => this.setState({ model: value })}
                                    placeholder="Modelo" size="large"
                                    className="input-block-title"
                                >
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.props.dataConfigs.modelos || []).map(v => {
                                        return <Select.Option value={v} key={v}>{v}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Table
                        style={{ marginTop: '40px' }}
                        size='small'
                        dataSource={this.state.dataSource}
                        columns={this.state.columns}
                        pagination={false}
                        scroll={{ x: 1000 }}
                    />

                </Modal>

            </Fragment >
        )
    }
}