import { Col, Divider, Form, Input, message, Modal, Row, Select, Spin } from 'antd'
import { surveyQuestionsAndAnswers } from '../../../satisfaction-survey/suport-files/survey-questions'
import React, { Component } from 'react'
import { findAll, findWhere, findWhereMultiple, getCurrentUser } from '../../../../../utils/db_functions'
import { CreatePhoneMask, ValidateEmail } from '../../../../../utils/mask'

export default class EmitentStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            tradeRepresentatives: [],
            users: [],
            proposalRequests: [],
            dataConfigs: {},
            workStageData: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const tradeRepresentatives = this.props.dataConfigs['emitent-tradeRepresentatives'] || await findAll('TradeRepresentative', null, null, null, 'consult_repre')


        const currentUser = await getCurrentUser()
        const users = this.props.dataConfigs['emitent-users'] || await findWhereMultiple('User', [
            { 'field': 'commercial_consultant', 'value': true, 'op': 'equalTo', logicOp: '$or' },
            { 'field': 'name', 'value': currentUser.get('name'), 'op': 'equalTo', logicOp: '$or' },
        ], null, null, null, 'name')

        const proposalRequests = this.props.dataConfigs['emitent-proposalRequest'] || await findAll('ProposalRequest', 10000, null, null, 'proposal_request_code')

        const dataConfigsArray = await findWhere('Config', 'name', [
            'origem_contato', 'especificacao_multidoor', 'envolvimento_compra', 'objetivo_proposta', 'requisitos_gerais', 'desempenho',
            'usage', 'desempenho_kit', 'proposal_form_notes_emitente',
        ])
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {})

        const workStageData = this.props.dataConfigs['emitent-workStageData'] || await findAll('WorkStage')

        this.setState({ tradeRepresentatives, users, proposalRequests, dataConfigs, workStageData, loading: false })
        this.props.setDataConfigs({
            'emitent-tradeRepresentatives': tradeRepresentatives, 'emitent-users': users,
            'emitent-proposalRequests': proposalRequests, 'emitent-workStageData': workStageData
        })

        if (!this.props.getValue('consultant')) {
            this.props.updateDataValue('consultant', currentUser.get('name'))
            this.getConsultant(currentUser.get('name'))
        }
    }

    getConsultant = (consultantName) => {
        const consultantFound = this.state.users.find(consultant => consultant.get('name') === consultantName)

        if (consultantFound) {
            this.props.updateDataValue('consult_phones', consultantFound.get('phone'))
            this.props.updateDataValue('consult_email', consultantFound.get('username'))
            this.props.updateDataValue('consult_whatsapp', consultantFound.get('whatsapp'))
        }
    }

    getRepresentativeInfo(id) {
        const representativeParseObject = this.state.tradeRepresentatives.find(representative => representative.id === id);
        return {
            'consult_repre_is_company': representativeParseObject.get('consult_repre_is_company'),
            'consult_repre_cnpj': representativeParseObject.get('consult_repre_cnpj'),
            'consult_repre_cpf': representativeParseObject.get('consult_repre_cpf'),
            'consult_repre_cod': representativeParseObject.get('consult_repre_cod'),
            'consult_repre': representativeParseObject.get('consult_repre'),
            'consult_repre_phone': representativeParseObject.get('consult_repre_phone'),
            'consult_repre_email': representativeParseObject.get('consult_repre_email'),
            'consult_repre_whatsapp': representativeParseObject.get('consult_repre_whatsapp'),
            'consult_repre_empresa': representativeParseObject.get('consult_repre_empresa'),
            'consult_repre_contato': representativeParseObject.get('consult_repre_contato'),
        }
    }

    updateRepresentativeInfo(id) {
        if (id) {
            const representative = this.getRepresentativeInfo(id);
            if (representative) {
                this.props.updateDataValue('consult_repre_is_company', representative.consult_repre_is_company);
                this.props.updateDataValue('consult_repre_cnpj', representative.consult_repre_cnpj);
                this.props.updateDataValue('consult_repre_cpf', representative.consult_repre_cpf);
                this.props.updateDataValue('consult_repre_cod', representative.consult_repre_cod);
                this.props.updateDataValue('consult_repre', representative.consult_repre);
                this.props.updateDataValue('consult_repre_phone', representative.consult_repre_phone);
                this.props.updateDataValue('consult_repre_email', representative.consult_repre_email);
                this.props.updateDataValue('consult_repre_whatsapp', representative.consult_repre_whatsapp);
                this.props.updateDataValue('consult_repre_empresa', representative.consult_repre_empresa);
                this.props.updateDataValue('consult_repre_contato', representative.consult_repre_contato);
            }
        }
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading} size='large' tip='Carregando as informações...'>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Consultor" required>
                                        <Select
                                            value={this.props.getValue('consultant')}
                                            onChange={value => this.props.updateDataValue('consultant', value)}
                                            onSelect={event => this.getConsultant(event)}
                                            placeholder="Consultor" size="large" disabled={this.props.disabledFields}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value=''>Selecione...</Select.Option>
                                            {(this.state.users || []).map((user, index) => {
                                                return (
                                                    <Select.Option key={index} value={user.get('name')}>{user.get('name')}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Telefone" required>
                                        <Input value={CreatePhoneMask(this.props.getValue('consult_phones'))} onChange={event => this.props.updateDataValue('consult_phones', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <
                                        // @ts-ignore
                                        Form.Item label="E-mail" required  {...ValidateEmail(this.props.getValue('consult_email') || '')}>
                                        <Input value={this.props.getValue('consult_email')} onChange={event => this.props.updateDataValue('consult_email', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="WhatsApp" required>
                                        <Input value={CreatePhoneMask(this.props.getValue('consult_whatsapp'))} onChange={event => this.props.updateDataValue('consult_whatsapp', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Representante" required>
                                        <Select
                                            value={this.props.getValue('consult_repre_id')}
                                            onChange={value => this.props.updateDataValue('consult_repre_id', value)}
                                            onSelect={event => this.updateRepresentativeInfo(event)}
                                            placeholder="Representante" size="large" disabled={this.props.disabledFields}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value=''>Selecione...</Select.Option>
                                            {(this.state.tradeRepresentatives || []).map((client, index) => {
                                                return (
                                                    <Select.Option key={index} value={client.id}>{client.get('consult_repre')}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Solicitação de proposta">
                                        <Select
                                            value={this.props.getValue('proposal_request')}
                                            onChange={value => {
                                                // Campos ligados à solicitação de proposta que deverão ser atualizados automaticamente
                                                let arrayOfFields = [
                                                    'contact_origin', 'specification', 'purchase_involvement', 'purchase_purpose',
                                                    'usage', 'desempenho_kit', 'desempenho', 'requirements'
                                                ]

                                                if (value) {
                                                    const valueObj = JSON.parse(value.toString())

                                                    this.props.updateDataValue('proposal_request', valueObj.proposal_request_code)
                                                    this.props.updateDataValue('proposal_request_code', valueObj.proposal_request_code)

                                                    // Inserir automaticamente os valores vindo da solicitação de proposta
                                                    arrayOfFields.forEach(v => this.props.updateDataValue(`request_${v}`, valueObj[v]))
                                                    this.props.updateDataValue('request_work_stage', (valueObj.work_stage && valueObj.work_stage.objectId))

                                                } else {

                                                    this.props.updateDataValue('proposal_request', null)
                                                    this.props.updateDataValue('proposal_request_code', null)

                                                    // Remover automaticamente os valores vindo da solicitação de proposta
                                                    arrayOfFields.forEach(v => this.props.updateDataValue(`request_${v}`, null))
                                                    this.props.updateDataValue('request_work_stage', null)

                                                }
                                            }}
                                            placeholder="Solicitação de proposta" size="large" disabled={this.props.disabledFields}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Select.Option value=''>Selecione...</Select.Option>
                                            {(this.state.proposalRequests || []).map((value, index) => {
                                                return (
                                                    <Select.Option key={index} value={JSON.stringify(value)}>
                                                        {value.get('proposal_request_code')}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    {this.props.getValue('proposal_request_code') ?
                                        <div style={{ lineHeight: '87px', marginTop: '20px' }}>
                                            <a href={`${process.env.REACT_APP_PUBLIC}/pdf_proposal_request/${this.props.getValue('proposal_request_code')}.pdf`} target='blank'>
                                                {`${this.props.getValue('proposal_request_code')}.pdf`}
                                            </a>
                                        </div> : null
                                    }
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <div className='note-box'>
                                <div className='note-title'>Instruções:</div>
                                <div>{
                                    ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_emitente) || [])
                                        .map(paragraph => <p>{paragraph}</p>)
                                }</div>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <br />
                    <p>
                        <div
                            className='link-style'
                            style={{ textAlign: 'center' }}
                            onClick={async () => {
                                const fullProposalCode = this.props.getValue('proposal_code')
                                if (!fullProposalCode) return message.info('A pesquisa ainda não foi respondida')

                                const proposalCodeArray = fullProposalCode.split('-')
                                proposalCodeArray.pop()
                                const proposalCode = proposalCodeArray.join('-')

                                const surveyArray = await findWhere('CommercialSurvey', 'proposal', [proposalCode])
                                if (!surveyArray || surveyArray.length === 0) return message.info('A pesquisa ainda não foi respondida')

                                const survey = surveyArray[0].toJSON()

                                Modal.confirm({
                                    title: `Pesquisa de atendimento`,
                                    width: 600,
                                    iconType: 'message',
                                    content:
                                        <div>
                                            <br />

                                            {
                                                surveyQuestionsAndAnswers.map(questionAndAnswers => {
                                                    return (
                                                        <div key={questionAndAnswers.question.key}>
                                                            <div><strong>{questionAndAnswers.question.label}</strong></div>
                                                            {survey.form[questionAndAnswers.question.key]}<br /><br />
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>,
                                })
                            }}
                        >Ver respostas da pesquisa de atendimento</div>
                    </p>
                    <br />

                    <p>Informações Comerciais</p>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="Origem do contato" required>
                                <Select
                                    value={this.props.getValue('request_contact_origin')}
                                    onChange={value => this.props.updateDataValue('request_contact_origin', value)}
                                    placeholder="Origem do contato" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.origem_contato || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Especificação Multidoor" required>
                                <Select
                                    value={this.props.getValue('request_specification')}
                                    onChange={value => this.props.updateDataValue('request_specification', value)}
                                    placeholder="Especificação Multidoor" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.especificacao_multidoor || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Envolvimento na Compra" required>
                                <Select
                                    value={this.props.getValue('request_purchase_involvement')}
                                    onChange={value => this.props.updateDataValue('request_purchase_involvement', value)}
                                    placeholder="Envolvimento na Compra" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.envolvimento_compra || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Objetivo da Proposta" required>
                                <Select
                                    value={this.props.getValue('request_purchase_purpose')}
                                    onChange={value => this.props.updateDataValue('request_purchase_purpose', value)}
                                    placeholder="Objetivo da Proposta" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.objetivo_proposta || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <br /><br />
                    <p>Informações Técnicas</p>
                    <Row gutter={24}>
                        <Col span={5}>
                            <Form.Item label="Estágio da Obra">
                                <Select
                                    value={this.props.getValue('request_work_stage')}
                                    onChange={value => this.props.updateDataValue('request_work_stage', value)}
                                    placeholder="Estágio da Obra" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.state.workStageData || []).map((ParseWorkStage, regIndex) => {
                                        return (
                                            <Select.Option key={regIndex} value={ParseWorkStage.id}>{ParseWorkStage.get('name')}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Tipo de Obra" required>
                                <Select
                                    value={this.props.getValue('request_usage')}
                                    onChange={value => this.props.updateDataValue('request_usage', value)}
                                    placeholder="Tipo de Obra" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.usage || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Nível de Desempenho" required>
                                <Select
                                    value={this.props.getValue('request_desempenho_kit')}
                                    onChange={value => this.props.updateDataValue('request_desempenho_kit', value)}
                                    placeholder="Nível de Desempenho" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.desempenho_kit || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Desempenho da Porta" required>
                                <Select
                                    value={this.props.getValue('request_desempenho')}
                                    onChange={value => this.props.updateDataValue('request_desempenho', value)}
                                    placeholder="Desempenho da Porta" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                    mode='multiple'
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.desempenho || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Requisitos Gerais" required>
                                <Select
                                    value={this.props.getValue('request_requirements')}
                                    onChange={value => this.props.updateDataValue('request_requirements', value)}
                                    placeholder="Requisitos Gerais" size="large" disabled={this.props.disabledFields || this.props.getValue('proposal_request')}
                                    mode='multiple'
                                >
                                    <Select.Option value=''>Selecione...</Select.Option>
                                    {(this.state.dataConfigs.requisitos_gerais || []).map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>{value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </div >
        )
    }

}