const providerInputFields = (props) => {

    return [
        [
            {
                type: 'dynamic-input-list',
                key: 'inputs',
                fields: [
                    [
                        {
                            "title": "Insumo",
                            "key": "input",
                            "type": "select",
                            "options": props.inputs.map(input => {
                                return {
                                    label: `${input.get('code')} - ${input.get('material_description')}`,
                                    value: input.id
                                }
                            }),
                            'colSize': 14,
                            "element-attr": { "required": false },
                        }
                    ]
                ]
            }
        ],
    ]
}

export default providerInputFields