import { Button, Col, Divider, Form, Input, InputNumber, Modal, notification, Row, Select } from 'antd'
import React, { Component } from 'react'
import { findAll, findWhere, findWhereMultiple, save } from '../../../../utils/db_functions'
import Parse from 'parse'
import ComponentsToChangeModal from './components-to-change-modal'

export default class MassChangeModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleModal: false,
            typologies: [],
            aplications: [],
            manHours: [],
            loadingButton: false,
            form: {
                code: null
            },
            changeFields: {
                typology: null,
                weight: null,
                aplication: null,
                height: null,
                width: null,
                code: null,
                pis_conf: null,
                custo_comer: null,
                margin_contri: null,
                unity_value_hh: null,
                size_hh: null,
            },
            inputs: [],
            inputsToChange: {
                toChange: null,
                changeTo: null,
                description: null,
                unity: null,
                amount_component: null,
                width_component: null,
                thickness_component: null,
                length_component: null,
            },
            inputsToDelete: [],
            descriptionToChange: {
                toChange: null,
                changeTo: null,
            },

            loadingComponentsToChange: false,
            componentsToChange: [],
        }
    }

    componentDidMount = async () => {
        const typologies = await findWhere('Config', 'name', ['typology'])
        this.setState({
            typologies: typologies.length ?
                typologies[0].get('value').map((typology) => {
                    return { label: typology, value: typology }
                }) : []
        })

        const aplications = await findWhere('Config', 'name', ['performance'])
        this.setState({
            aplications: aplications.length ?
                aplications[0].get('value').map((aplication) => {
                    return { label: aplication, value: aplication }
                }) : []
        })

        const inputs = await findAll('Input', 2000, null, null, 'code')
        this.setState({
            inputs: inputs.length ?
                inputs.map((input) => {
                    return { label: `${input.get('code')} - ${input.get('material_description')}`, value: input.id }
                }) : []
        })

        const manHours = await findAll('ManHour')
        this.setState({
            manHours: manHours.map((manHour) => {
                return {
                    label: manHour.get('description'),
                    value: manHour.id,
                }
            }),
        })
    }

    getComponentsToChange = async () => {
        const instalationComponents = await findWhereMultiple('InstalationKit', [
            { 'field': 'code', 'value': this.state.form.code, 'op': 'like' },
        ], null, null, 500)

        return instalationComponents

    }

    executeMassChanges = async () => {
        this.setState({ loadingButton: true })

        const instalationComponents = await this.getComponentsToChange()

        const instalationComponentIdsAndInputs = instalationComponents.map(el => {
            return {
                id: el.id,
                code: el.get('code'),
                descriptions: el.get('descriptions'),
                description: el.get('description'),
            }
        })

        for (let componentIndex = 0; componentIndex < instalationComponentIdsAndInputs.length; componentIndex++) {
            const component = instalationComponentIdsAndInputs[componentIndex];

            let componentToUpdateObject = {}

            const attrArray = Object.keys(this.state.changeFields)
            for (let attrIndex = 0; attrIndex < attrArray.length; attrIndex++) {
                const attr = this.state.changeFields[attrArray[attrIndex]];

                if (attrArray[attrIndex] === 'code' && attr) {
                    componentToUpdateObject[attrArray[attrIndex]] = component['code'].replace(this.state.form.code, attr)
                } else if (attrArray[attrIndex] === 'unity_value_hh' && attr) {
                    var manHourToRef = Parse.Object.extend("ManHour");
                    var pointerToManHour = new manHourToRef();
                    pointerToManHour.id = attr;
                    componentToUpdateObject[attrArray[attrIndex]] = pointerToManHour
                } else if ((attr && !Array.isArray(attr)) || (Array.isArray(attr) && attr.length > 0))
                    componentToUpdateObject[attrArray[attrIndex]] = attr
            }

            if (this.state.descriptionToChange.toChange) componentToUpdateObject['description'] = this.changeDescription(component)
            if (this.state.inputsToChange.toChange) componentToUpdateObject['descriptions'] = this.changeInputs(component)
            if (this.state.inputsToDelete.length) componentToUpdateObject['descriptions'] = this.deleteInputs(component)

            componentToUpdateObject['updatedBy'] = Parse.User.current()

            await save('InstalationKit', componentToUpdateObject, component.id)
        }

        this.setState({ loadingButton: false })

        this.setState({ visibleModal: false })
        notification['success']({
            message: 'Atualização realizada com sucesso',
            description: 'Os dados dos componentes foram atualizados'
        })
    }

    changeDescription = (component) => {
        const description = component.description.replace(this.state.descriptionToChange.toChange, this.state.descriptionToChange.changeTo)

        return description
    }

    changeInputs = (component) => {
        const descriptions = component.descriptions.map((inputGroup) => {

            if (
                !this.state.inputsToChange.description ||
                (inputGroup.description || "").toLowerCase().includes((this.state.inputsToChange.description || "").toLowerCase())
            ) {
                return {
                    ...inputGroup,
                    rows: (inputGroup.rows || []).map(input => {
                        if (input.specification === this.state.inputsToChange.toChange) {
                            input.specification = this.state.inputsToChange.changeTo
                            if (this.state.inputsToChange.unity) input.unity = this.state.inputsToChange.unity
                            if (this.state.inputsToChange.amount_component) input.amount_component = this.state.inputsToChange.amount_component
                            if (this.state.inputsToChange.width_component) input.width_component = this.state.inputsToChange.width_component
                            if (this.state.inputsToChange.thickness_component) input.thickness_component = this.state.inputsToChange.thickness_component
                            if (this.state.inputsToChange.length_component) input.length_component = this.state.inputsToChange.length_component
                        }
                        return input
                    })
                }
            }

            return inputGroup
        })

        return descriptions
    }

    deleteInputs = (component) => {
        const descriptions = component.descriptions.map((inputGroup) => {

            if (
                !this.state.inputsToChange.description ||
                (inputGroup.description || "").toLowerCase().includes((this.state.inputsToChange.description || "").toLowerCase())
            ) {
                return {
                    ...inputGroup,
                    rows: (inputGroup.rows || []).filter(input => !(this.state.inputsToDelete.includes(input.specification)))
                }
            }

            return inputGroup
        })

        return descriptions
    }

    render() {
        return (
            <div style={{ float: 'left', marginRight: '10px' }}>
                <Button type='primary' onClick={() => this.setState({ visibleModal: true })}>
                    Alterações em massa
                </Button>

                <Modal
                    title='Alteração em massa'
                    visible={this.state.visibleModal}
                    width='800px'
                    onCancel={() => this.setState({ visibleModal: false })}
                    footer={[
                        <Button
                            disabled={!this.state.form.code}
                            type='primary'
                            loading={this.state.loadingButton}
                            onClick={() => {
                                const _this = this
                                Modal.confirm({
                                    title: 'Deseja realmente realizar essa alteração?',
                                    content: 'Verifique todos os campos antes de realizar essa ação, pois ela não poderá ser desfeita',
                                    onOk() {
                                        _this.executeMassChanges()
                                    },
                                })
                            }}
                        >Realizar alterações</Button>,
                        <Button onClick={() => this.setState({ visibleModal: false })}>Cancelar</Button>
                    ]}
                >

                    <Row gutter={24}>

                        <Divider>Campos para busca</Divider>

                        <Col span={12}>
                            <Form.Item label='Código do Produto'>
                                <Input
                                    value={this.state.form.code}
                                    placeholder='Código do produto'
                                    onChange={e => this.setState({ form: { ...this.state.form, code: e.target.value } })}
                                    onBlur={async e => {
                                        await this.setState({ loadingComponentsToChange: true })

                                        const componentsToChange = await this.getComponentsToChange()
                                        this.setState({ componentsToChange, loadingComponentsToChange: false })
                                    }}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    {this.state.loadingComponentsToChange ?
                        <div>Carregando componentes que serão atualizados...</div>
                        :
                        <div>

                            {this.state.componentsToChange.length}&nbsp;
                            {
                                this.state.componentsToChange.length === 1 ?
                                    <span>componente será atualizado.&nbsp;</span>
                                    :
                                    <span>componentes serão atualizados.&nbsp;</span>
                            }
                            <ComponentsToChangeModal componentsToChange={this.state.componentsToChange} />
                        </div>
                    }
                    <br /><br />

                    <Divider>Campos que serão atualizados</Divider>

                    <Row gutter={24} style={{ marginTop: '50px' }}>

                        <Col span={12}>
                            <Form.Item label='Tipologia'>
                                <Select
                                    value={this.state.changeFields.typology}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, typology: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.typologies.map(typology => {
                                        return <Select.Option value={typology.value}>{typology.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Aplicação'>
                                <Select
                                    value={this.state.changeFields.aplication || []}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, aplication: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    mode='multiple'
                                >
                                    {this.state.aplications.map(aplication => {
                                        return <Select.Option value={aplication.value}>{aplication.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>

                        <Col span={5}>
                            <Form.Item label='Peso'>
                                <InputNumber
                                    value={this.state.changeFields.weight}
                                    placeholder='Peso'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, weight: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label='Altura' >
                                <InputNumber
                                    value={this.state.changeFields.height}
                                    placeholder='Altura'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, height: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item label='Larguras'>
                                <Select
                                    value={this.state.changeFields.width || []}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, width: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    mode='multiple'
                                >
                                    <Select.Option value={350}>350</Select.Option>
                                    <Select.Option value={400}>400</Select.Option>
                                    <Select.Option value={450}>450</Select.Option>
                                    <Select.Option value={500}>500</Select.Option>
                                    <Select.Option value={550}>550</Select.Option>
                                    <Select.Option value={600}>600</Select.Option>
                                    <Select.Option value={700}>700</Select.Option>
                                    <Select.Option value={800}>800</Select.Option>
                                    <Select.Option value={900}>900</Select.Option>
                                    <Select.Option value={1000}>1000</Select.Option>
                                    <Select.Option value={1100}>1100</Select.Option>
                                    <Select.Option value={1200}>1200</Select.Option>
                                    <Select.Option value={1300}>1300</Select.Option>
                                    <Select.Option value={1400}>1400</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Código'>
                                <Input
                                    value={this.state.changeFields.code}
                                    placeholder='Código'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, code: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='ICMS + Fed.'>
                                <InputNumber
                                    value={this.state.changeFields.pis_conf}
                                    placeholder='ICMS + Fed'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, pis_conf: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Fixo + Comercial'>
                                <InputNumber
                                    value={this.state.changeFields.custo_comer}
                                    placeholder='Fixo + Comercial'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, custo_comer: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Margem'>
                                <InputNumber
                                    value={this.state.changeFields.margin_contri}
                                    placeholder='Margem'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, margin_contri: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={9}>
                            <Form.Item label='Referência HH'>
                                <Select
                                    value={this.state.changeFields.unity_value_hh}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, unity_value_hh: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.manHours.map(manHour => {
                                        return <Select.Option value={manHour.value} key={manHour.value}>{manHour.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label='Quantidade HH'>
                                <InputNumber
                                    value={this.state.changeFields.size_hh}
                                    placeholder='Quantidade HH'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, size_hh: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '50px' }}>

                        <Divider>Alterar descrição</Divider>

                        <Col span={12}>
                            <Form.Item label='Trecho a ser substituido'>
                                <Input
                                    value={this.state.descriptionToChange.toChange}
                                    placeholder='Trecho a ser substituido'
                                    onChange={e => this.setState({ descriptionToChange: { ...this.state.descriptionToChange, toChange: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Trecho a substituir'>
                                <Input
                                    value={this.state.descriptionToChange.changeTo}
                                    placeholder='Trecho a substituir'
                                    onChange={e => this.setState({ descriptionToChange: { ...this.state.descriptionToChange, changeTo: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '50px' }}>

                        <Divider>Substituição de insumos</Divider>

                        <Col span={24}>
                            <Form.Item label='Isumo a ser substituido'>
                                <Select
                                    value={this.state.inputsToChange.toChange}
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, toChange: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.inputs.map(input => {
                                            return <Select.Option value={input.value} key={`inputToChange_${input.value}`}>{input.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Isumo a substituir'>
                                <Select
                                    value={this.state.inputsToChange.changeTo}
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, changeTo: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.inputs.map(input => {
                                            return <Select.Option value={input.value} key={`inputToChange_${input.value}`}>{input.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Composição'>
                                <Input
                                    value={this.state.inputsToChange.description}
                                    placeholder='Composição (ex.: Quadro)'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, description: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Unidade'>
                                <Select
                                    value={this.state.inputsToChange.unity}
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, unity: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        (this.state.unityArray || []).map(unity => {
                                            return <Select.Option value={unity} key={`unityToChange_${unity}`}>{unity}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Quantidade'>
                                <InputNumber
                                    value={this.state.inputsToChange.amount_component}
                                    placeholder='Quantidade'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, amount_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Largura'>
                                <InputNumber
                                    value={this.state.inputsToChange.width_component}
                                    placeholder='Largura'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, width_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Espessura'>
                                <InputNumber
                                    value={this.state.inputsToChange.thickness_component}
                                    placeholder='Espessura'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, thickness_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Comprimento'>
                                <InputNumber
                                    value={this.state.inputsToChange.length_component}
                                    placeholder='Comprimento'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, length_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Isumos a serem deletados'>
                                <Select
                                    value={this.state.inputsToDelete}
                                    onChange={e => this.setState({ inputsToDelete: e })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode='multiple'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.inputs.map(input => {
                                            return <Select.Option value={input.value} key={`inputToDelete_${input.value}`}>{input.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </div>
        )
    }

}