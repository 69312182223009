import React from 'react';
import { Icon, Button } from 'antd';


export default {
	fields: [],
	submit: {
		collection: "Order"
	},
	sortBy: 'order_code',
	defaultCurrentPage: 'last',
	schema: [
		{
			title: "Número Pedido",
			key: "order_code",
			width: '150px',
			dataIndex: "order_code",
			type: "text",
		},
		{
			title: "Nome cliente",
			key: "name_client",
			dataIndex: "name_client",
			type: "render",
			render: (_, order) => {
				let proposal = JSON.parse(order.proposal);
				return proposal.name_client;
			}
		},
		{
			title: "Obra",
			key: "name_work",
			dataIndex: "name_work",
			type: "render",
			render: (_, order) => {
				let proposal = JSON.parse(order.proposal);
				return proposal.name_work;
			}
		},
		{
			title: "Palavra Chave",
			key: "keyWord",
			dataIndex: "keyWord",
			type: "render",
			render: (_, order) => {
				let proposal = JSON.parse(order.proposal);
				return proposal.keyWord;
			}
		},
		{
			title: "Data de fechamento",
			key: "order_date",
			dataIndex: "order_date",
			type: "render",
			render: (_, row) => {
				if (row.order_date)
					return new Date(row.order_date.iso || null).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })
				return '';
			},
			width: '130px'
		},
		{
			title: "Data de entrega",
			key: "opening",
			dataIndex: "opening",
			type: "render",
			render: (_, order) => {
				let proposal = JSON.parse(order.proposal);
				if (proposal.opening) return new Date(proposal.opening).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })
				return '';
			},
			width: '130px'
		},
		{
			title: "PDF",
			key: "pdf-input",
			dataIndex: 'pdf-input',
			align: 'center',
			width: '100px',
			render: (_, order) => {
				let proposal = JSON.parse(order.proposal);
				// Alteração no padrão do nome do arquivo conforme solicitado pelo cliente
				let lastUpdatedDate = new Date(proposal.updatedAt)
				let changeNameDate = new Date(2020, 8, 19)
				let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_input_report/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-')
				if (lastUpdatedDate > changeNameDate) pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_input_report/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-')

				return (
					<Button title="Visualizar relatório de insumos" disabled={!proposal.pdf} type="default" shape="circle" icon="file-pdf"
						onClick={() => {
							window.open(pdfName, '_blink')
						}}
					/>
				)
			}
		},
	],
	"title-module": 'Relatório de Insumos',
	"title-navigation": <div><Icon type="profile" /> Relatório de Insumos</div>,
	"module": "Order",
	"router-base": "/panel/relatorio-insumos"
}