import { Button, Col, Divider, Form, Input, Modal, notification, Row, Select } from 'antd'
import React, { Component } from 'react'
import { findWhere, findWhereMultiple, save } from '../../../../utils/db_functions'
import Parse from 'parse'
import KitsToChangeModal from './kits-to-change-modal'

export default class MassChangeModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleModal: false,
            tipologies: [],
            loadingButton: false,
            form: {
                code: null,
            },
            changeFields: {
                code: null,
                typology: null,
            },
            descriptionToChange: {
                toChange: null,
                changeTo: null,
            },

            loadingKitsToChange: false,
            kitsToChange: [],
        }
    }

    componentDidMount = async () => {
        const typologyConfigArray = await findWhere('Config', 'name', ['typology'])
        const tipologies = (typologyConfigArray[0] && typologyConfigArray[0].get('value')) || []
        this.setState({
            tipologies: tipologies.map((typology) => {
                return { label: typology, value: typology }
            })
        })
    }

    getKitsToChange = async () => {
        if (!this.state.form.code) return []

        let conditionsArray = [
            { 'field': 'code', 'value': this.state.form.code, 'op': 'like', logicOp: '$and' },
        ]

        let kits = await findWhereMultiple('Kit', conditionsArray, null, null, 20000)

        return kits

    }

    executeMassChanges = async () => {
        this.setState({ loadingButton: true })

        const kits = await this.getKitsToChange()

        const kitIds = kits.map(el => {
            return {
                id: el.id,
                code: el.get('code'),
                name: el.get('name'),
                typology: el.get('typology'),
            }
        })

        for (let kitIndex = 0; kitIndex < kitIds.length; kitIndex++) {
            const kit = kitIds[kitIndex];

            let kitToUpdateObject = {}

            const attrArray = Object.keys(this.state.changeFields)
            for (let attrIndex = 0; attrIndex < attrArray.length; attrIndex++) {
                const attr = this.state.changeFields[attrArray[attrIndex]];

                if (attrArray[attrIndex] === 'code' && attr) {
                    kitToUpdateObject[attrArray[attrIndex]] = kit['code'].replace(this.state.form.code, attr)
                } else if (attr) kitToUpdateObject[attrArray[attrIndex]] = attr
            }

            if (this.state.descriptionToChange.toChange) kitToUpdateObject['name'] = this.changeDescription(kit)

            kitToUpdateObject['updatedBy'] = Parse.User.current()

            await save('Kit', kitToUpdateObject, kit.id)
        }

        this.setState({ loadingButton: false })

        this.setState({ visibleModal: false })
        notification['success']({
            message: 'Atualização realizada com sucesso',
            description: 'Os dados das fichas foram atualizadas'
        })
    }

    changeDescription = (component) => {
        const description = component.name.replace(this.state.descriptionToChange.toChange, this.state.descriptionToChange.changeTo)

        return description
    }

    render() {
        return (
            <div style={{ float: 'left', marginRight: '10px' }}>
                <Button type='primary' onClick={() => this.setState({ visibleModal: true })}>
                    Alterações em massa
                </Button>

                <Modal
                    title='Alteração em massa'
                    visible={this.state.visibleModal}
                    width='800px'
                    onCancel={() => this.setState({ visibleModal: false })}
                    footer={[
                        <Button
                            disabled={!this.state.form.code}
                            type='primary'
                            loading={this.state.loadingButton}
                            onClick={() => {
                                const _this = this
                                Modal.confirm({
                                    title: 'Deseja realmente realizar essa alteração?',
                                    content: 'Verifique todos os campos antes de realizar essa ação, pois ela não poderá ser desfeita',
                                    onOk() {
                                        _this.executeMassChanges()
                                    },
                                })
                            }}
                        >Realizar alterações</Button>,
                        <Button onClick={() => this.setState({ visibleModal: false })}>Cancelar</Button>
                    ]}
                >

                    <Row gutter={24}>

                        <Divider>Campos para busca</Divider>

                        <Col span={12}>
                            <Form.Item label='Código do Produto'>
                                <Input
                                    value={this.state.form.code}
                                    placeholder='Código do produto'
                                    onChange={e => this.setState({ form: { ...this.state.form, code: e.target.value } })}
                                    onBlur={async () => {
                                        await this.setState({ loadingKitsToChange: true })

                                        const kitsToChange = await this.getKitsToChange()
                                        this.setState({ kitsToChange, loadingKitsToChange: false })
                                    }}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    {this.state.loadingKitsToChange ?
                        <div>Carregando fichas que serão atualizadas...</div>
                        :
                        <div>

                            {this.state.kitsToChange.length}&nbsp;
                            {
                                this.state.kitsToChange.length === 1 ?
                                    <span>ficha técnica será atualizada.&nbsp;</span>
                                    :
                                    <span>fichas serão atualizadas.&nbsp;</span>
                            }
                            <KitsToChangeModal kitsToChange={this.state.kitsToChange} />
                        </div>
                    }
                    <br /><br />

                    <Divider>Campos que serão atualizados</Divider>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Código'>
                                <Input
                                    value={this.state.changeFields.code}
                                    placeholder='Código'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, code: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Tipologia'>
                                <Select
                                    value={this.state.changeFields.typology}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, typology: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    mode='multiple'
                                >
                                    {this.state.tipologies.map(typology => {
                                        return <Select.Option value={typology.value}>{typology.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '50px' }}>

                        <Divider>Alterar descrição</Divider>

                        <Col span={12}>
                            <Form.Item label='Trecho a ser substituido'>
                                <Input
                                    value={this.state.descriptionToChange.toChange}
                                    placeholder='Trecho a ser substituido'
                                    onChange={e => this.setState({ descriptionToChange: { ...this.state.descriptionToChange, toChange: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Trecho a substituir'>
                                <Input
                                    value={this.state.descriptionToChange.changeTo}
                                    placeholder='Trecho a substituir'
                                    onChange={e => this.setState({ descriptionToChange: { ...this.state.descriptionToChange, changeTo: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </div>
        )
    }

}