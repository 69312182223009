import { Button, Modal, Select, Spin } from "antd"
import React, { Component } from "react"
import Chart from "react-google-charts"
import { findWhereMultiple } from "../../../../../utils/db_functions"

export default class SatisfactionSurveyReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            year: null,
            reportModal: false,
            loading: false,
            surveyAmount: 0,
            chartData: [
                { question: 'multidoorExperience', title: 'Experiência Multidoor', data: [] },
                { question: 'whatMostRelevant', title: 'Mais relevante no atendimento técnico', data: [] },
                { question: 'processMonitoringByWeeklyStatus', title: 'Acompanhamento do processo através do Status semanal', data: [] },
                { question: 'finalProductExpectation', title: 'Produto final atendeu as expectativas', data: [] },
                { question: 'instalationProccess', title: 'Processo de instalação das portas', data: [] },
                { question: 'doorComparative', title: 'Comparativo do processo das portas com outros serviços na obra', data: [] },
                { question: 'techAssistance', title: 'Houve assistência técnica', data: [] },
                { question: 'finalSatisfaction', title: 'Satisfação final', data: [] },
            ]
        }
    }

    getSurveyByYear = async (year) => {
        this.setState({ loading: true })

        const surveys = await findWhereMultiple('PcpoSurvey', [{ 'field': 'order', 'value': `PFM-${year}-`, 'op': 'like' }])

        // Pegandos as respostas
        const questions = this.state.chartData.map(el => el.question)
        const allAnswers = questions.map(question => {
            const answers = surveys.map(survey => survey.get('form')[`${question}`])
            return {
                question,
                answers: [...new Set(answers)].filter(el => el)
            }
        })

        // Contando as respostas
        const chartData = this.state.chartData.map(chart => {

            const questionAndAnswers = allAnswers.find(el => el.question === chart.question)
            if (questionAndAnswers) {
                let data = []
                questionAndAnswers.answers.forEach(answer => {
                    let count = 0
                    surveys.forEach(survey => {
                        if (survey.get('form')[`${chart.question}`] === answer) count++
                    })
                    data.push([`${answer}`, count])
                })
                chart.data = data
                return chart
            } else return chart
        })

        this.setState({ chartData, loading: false, surveyAmount: (surveys.length || 0) })
    }

    render() {
        return (
            <div>
                <Button
                    onClick={() => this.setState({ reportModal: true })}
                    style={{ marginRight: '10px' }}
                >Gráficos</Button>

                <Modal
                    width={800}
                    visible={this.state.reportModal}
                    title='Relatório de pesquisa de satisfação'
                    onCancel={() => this.setState({ reportModal: false })}
                    footer={[
                        <Button type="primary" onClick={() => this.setState({ reportModal: false })}>Ok</Button>
                    ]}
                >
                    <Spin spinning={this.state.loading} tip='Carregando gráficos...'>
                        <div>
                            <Select
                                style={{ width: '300px', marginTop: '10px', marginBottom: '30px' }}
                                value={this.state.year}
                                onChange={year => {
                                    this.setState({ year })
                                    this.getSurveyByYear(year)
                                }}
                            >
                                {
                                    Array(33).fill(2018).map((year, yearIndex) => {
                                        return <Select.Option key={year + yearIndex} value={year + yearIndex}>{year + yearIndex}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            Pesquisas respondidas no período: <strong>{this.state.surveyAmount}</strong>
                        </div>

                        {
                            this.state.chartData.map((chartData, index) => {
                                return (
                                    <Chart
                                        key={`customerChartKey${index}`}
                                        chartType="PieChart"
                                        options={{
                                            width: 700,
                                            height: 300,
                                            title: chartData.title,
                                            is3D: true,
                                        }}
                                        loader={<div>Carregando gráfico</div>}
                                        data={[
                                            ['Pergunta', 'Resposta'],
                                            ...chartData.data
                                        ]}
                                    />
                                )
                            })
                        }

                    </Spin>

                </Modal>
            </div>
        )
    }
}