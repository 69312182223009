import { Button, Form, Modal, Popover, Select } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import React, { Component } from "react";
import { findById, findWhereMultiple } from "../../../../utils/db_functions";

export default class ProposalChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: this.props.orderId,
      proposalId: this.props.proposalId,
      proposalIdToChange: this.props.proposalId,
      proposalChooseModal: false,
      proposals: [],
      proposal: null,
    };
  }

  componentWillReceiveProps = async (nextProps) => {
    if (nextProps.orderId !== this.state.orderId) {
      if (nextProps.orderId) {
        const order = await findById("Order", nextProps.orderId);
        const proposal = await findById(
          "Proposal",
          order.get("proposalRef").id
        );

        this.setState({
          proposalId: proposal.id,
          proposalIdToChange: proposal.id,
          proposal,
          orderId: nextProps.orderId,
        });
      }
    } else if (nextProps.proposalId !== this.state.proposalId) {
      if (nextProps.proposalId) {
        const proposal = await findById("Proposal", nextProps.proposalId);

        this.setState({
          proposalId: nextProps.proposalId,
          proposalIdToChange: nextProps.proposalId,
          proposal,
        });
      }
    }
  };

  getProposals = async (proposalCode) => {
    const proposalCodeBase = `${proposalCode.split("-")[0]}-${
      proposalCode.split("-")[1]
    }`;

    const proposals = await findWhereMultiple("Proposal", [
      { field: "proposal_code", value: proposalCodeBase, op: "like" },
    ]);

    this.setState({ proposals: proposals || [] });
  };

  render() {
    return (
      <div>
        <ButtonGroup>
          <Button disabled size="large">
            {this.state.proposal
              ? `${this.state.proposal.get(
                  "proposal_code"
                )} | ${this.state.proposal.get("name_client")}`
              : "Carregando proposta..."}
          </Button>

          <Popover content="Alterar proposta">
            <Button
              icon="edit"
              size="large"
              onClick={async () => {
                await this.getProposals(
                  this.state.proposal &&
                    this.state.proposal.get("proposal_code")
                );
                this.setState({ proposalChooseModal: true });
              }}
            />
          </Popover>

          <Popover content="PDF da proposta">
            <Button
              icon="file-pdf"
              size="large"
              onClick={async () => {
                let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(
                  this.state.proposal.get("name_client") || ""
                ).replace("/", "")}-${this.state.proposal.get(
                  "proposal_code"
                )}-Multidoor.pdf`.replace(/\s/g, "-");
                window.open(pdfName, "_blink");
              }}
            />
          </Popover>
        </ButtonGroup>

        <Modal
          title="Proposta"
          visible={this.state.proposalChooseModal}
          onCancel={() => this.setState({ proposalChooseModal: false })}
          cancelText="Cancelar"
          onOk={async () => {
            await this.props.changeProposal(this.state.proposalIdToChange);
            this.setState({ proposalChooseModal: false });
          }}
          okText="Alterar"
        >
          <Form.Item label="Propostas">
            <Select
              style={{ width: "100%" }}
              value={this.state.proposalIdToChange}
              onChange={(e) => this.setState({ proposalIdToChange: e })}
            >
              {this.state.proposals.map((proposal) => {
                var finalPrice = parseFloat(
                  proposal.get("final_value") || 0
                ).toLocaleString("pt-BR");

                return (
                  <Select.Option value={proposal.id}>
                    {proposal.get("proposal_code")} |{" "}
                    {proposal.get("name_client")} | Valor (R$): {finalPrice}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Modal>
      </div>
    );
  }
}
