import React, { Component } from "react";
import { Form, Card, Breadcrumb, Tooltip, Spin, Button, Icon, Row, Col, Input, DatePicker, Switch, Select, Upload, notification, message, Slider, InputNumber, Divider, Table, Modal, Tabs } from 'antd';
import moment from 'moment';
import Parse from "parse";
import EditableTagGroup from './utils/editableTagGroup'
import EditableTagGroupHash from './utils/editableTagGroupHash'
import RelationSelect from './utils/relation'
import _ from 'lodash'
import './form.css';
import { isNumber } from "util";
import { CreateMask, CreatePhoneMask, ValidateEmail } from '../../../utils/mask'
import DynamicInputList from "./utils/dynamicInputList";
import { getFileIcon } from "../../../utils/general_functions";
import { findById } from "../../../utils/db_functions";

/**
 * Classe que cria os formulários.
 */
export default class FormModule extends Component {
	//  todos os parans com o nome "node" se refere a um objeto que está no arquivo de configuração do módulo, em fields.

	state = {
		formRef: {},
		invalidForm: true,
		loadingDataEdit: false,
		loaded: {},

		isOnlyAReadModule: false,
	}

	constructor(props) {
		super(props);

		this.Auth = props.auth;
		this.module = props.module;
		this.editParams = []
		if (this.props.objectEdit) this.editParams = [{}, this.props.objectEdit]
		this.initCache()
	}

	componentWillMount = async () => {
		if (this.props.module.form.hasOwnProperty('FormWillMount')) return this.props.module.form.FormWillMount(this)
		await this.loadEditableData()

		// Ocultar botão de salvar se o módulo for apenas leitura
		this.setState({
			isOnlyAReadModule: (
				this.Auth.hasAction([`${this.module.form.module}Read`, "*"]) && !this.Auth.hasAction([`${this.module.form.module}Create`, "*"]) && !this.Auth.hasAction([`${this.module.form.module}Update`, "*"])
			) ? true : false
		})
		if (this.props.module.form.hasOwnProperty('FormHasMounted')) await this.props.module.form.FormHasMounted(this)
	}

	initCache() {
		if (!window.hasOwnProperty('appCache')) {
			window.appCache = class {
				static config = {}
			}
		}
	}

	/**
	 * Renderiza o componente.
	 */
	render() {

		if (this.props.module.form.hasOwnProperty('ForceStateLoadModule') && this.props.module.form['ForceStateLoadModule'])
			return (<div className="loading-content"><Spin size="large" /></div>)

		return (<div>{this.renderViewForm(...this.editParams)}</div>)
	}

	/**
	 * Submete o formulário.
	 * @param {Object} object Objeto com os atributos ObjectEdit e ObjectId. 
	 */
	submitForm({ objectEdit, objectId }) {
		if (this.module.form['SubmitForm']) return this.module.form['SubmitForm'](this.state.formRef, objectEdit, objectId, this)
		this.saveFormItem({ edit: !!objectEdit })
	}

	/**
	 * Carrega os dados no formulário para edição.
	 * @param {null}
	 */
	loadEditableData = async () => {
		const [objectEdit, objectId] = this.editParams;
		if (objectEdit && !this.state.loadingDataEdit) {
			if (Object.keys(objectEdit).length === 0) {
				const response = await findById(this.module.form.submit.collection, objectId)
				// let _Extended = Parse.Object.extend(this.module.form.submit.collection);
				// let _Query = new Parse.Query(_Extended);
				// _Query.get(objectId).then(response => { // TODO: fix the multiples requests on load editable data
				let editable = (this.props.module.form.hasOwnProperty('ParseEditableObject')) ? this.props.module.form.ParseEditableObject(this, response) : response.toJSON()

				this.setState(() => {
					return {
						loadingDataEdit: true,
						formRef: this.normalizeToEdit(editable, response),
					}
				})
				// })
			}
		}
	}

	/**
	 * Renderiza o formulário de edição preenchido.
	 * @param {ParseObject} objectEdit Objeto Parse com os dados a serem carregados.
	 * @param {String} objectId Id do objeto.
	 * @returns Formulário de edição.
	 */
	renderViewForm(objectEdit = null, objectId = null) { // carrega os cados, caso seja um form de edição, e retorna o formulário preenchido com os dados ou não.

		if (this.props.module.form['_EditRequest']) return false
		if (!this.module.form['FormComponent'] && !this.state.isOnlyAReadModule) {
			if (!objectEdit && !this.Auth.hasAction([`${this.module.form.module}Create`, "*"])) {
				this.navigateToRouterComponent('/block') // redirect to create
				return false
			}
			if (objectEdit && !this.Auth.hasAction([`${this.module.form.module}Update`, `${this.module.form.module}Read`, "*"])) return false // redirect to create


			if (this.module.formElement) return this.module.formElement;
		}
		return (
			<div
				className={this.module.form.className || ""}
				style={{ minWidth: "60%" }}>
				<Form
					layout="vertical"
					onSubmit={event => {
						if (!this.state.isOnlyAReadModule) this.submitForm({ objectEdit, objectId })
						event.preventDefault();
					}}>
					<Card
						title={
							<div style={{ float: "left", width: "100%" }}>
								<Breadcrumb>
									<Breadcrumb.Item onClick={() => this.navigateToRouterComponent(`/`)}>
										{this.module.form[`title-module`]}
									</Breadcrumb.Item>
									<Breadcrumb.Item>{objectEdit ? `Editar` : `Cadastrar`}</Breadcrumb.Item>
								</Breadcrumb>
								<h2 className="no-mar-pad" style={{ float: "left" }}>
									{this.module.title}
								</h2>
								<div className="right-btns">
									{(this.module.form.hasOwnProperty('BeforeSaveButton')) ? this.module.form['BeforeSaveButton'](this) : ''}
									{!this.state.isOnlyAReadModule ?
										<Tooltip visible={this.state.invalidForm} placement="topLeft" title="Preencha todos os campos.">
											<Button
												disabled={this.state.invalidForm}
												type="primary"
												id="btnSave"
												className="btn-creator"
												htmlType="button"
												onClick={() => this.submitForm({ objectEdit, objectId })}>
												Salvar<Icon type="save"
												/>
											</Button>
											{(this.module.form.hasOwnProperty('AfterSaveButton')) ? this.module.form['AfterSaveButton'](this) : ''}
										</Tooltip>
										: null
									}
								</div>
							</div>
						}>
						{(this.module.form['formTabs']) ?
							<Tabs type="card">
								{this.module.form.formTabs.map((tab, tabKey) => {
									return (
										<Tabs.TabPane tab={tab.title} key={tabKey}>
											{this.renderFieldsForm(this.module.form.formTabs[tabKey], objectEdit)}
										</Tabs.TabPane>
									);
								})}
							</Tabs>
							:
							this.renderFieldsForm(this.module.form, objectEdit)
							// (this.module.form['FormComponent'])
							// 	? this.module.form['FormComponent'](this, this.module)
							// 	: this.module.form.fields.map((fieldLine, rowKey) => {
							// 		return (
							// 			<div key={rowKey}>
							// 				<Row key={rowKey} gutter={24} className="row-form-item">
							// 					{fieldLine.map((node, index) => {
							// 						let editable =
							// 							typeof node.edit === "undefined" ? true : node.edit;
							// 						let isCreate = !objectEdit;
							// 						let el = this.getElementByTypeSchema(
							// 							node,
							// 							node.colSize || (24 / this.getSizeCol(fieldLine)),
							// 							objectEdit,
							// 							index
							// 						);

							// 						return isCreate || editable ? el : false;
							// 					})}
							// 				</Row>
							// 			</div>
							// 		);
							// 	})
						}
					</Card>
				</Form>
			</div>
		);
	}

	/**
	 * Renderiza os fields do form
	 * @param {*} form 
	 * @param {*} objectEdit 
	 * @returns 
	 */
	renderFieldsForm(form, objectEdit) {
		return (form['FormComponent'])
			? form['FormComponent'](this, this.module)
			: form.fields.map((fieldLine, rowKey) => {
				return (Array.isArray(fieldLine) ?
					<div key={rowKey}>
						<Row key={rowKey} gutter={24} className="row-form-item">
							{fieldLine.map((node, index) => {
								let editable =
									typeof node.edit === "undefined" ? true : node.edit;
								let isCreate = !objectEdit;
								let el = this.getElementByTypeSchema(
									node,
									node.colSize || (24 / this.getSizeCol(fieldLine)),
									objectEdit,
									index
								);

								return isCreate || editable ? el : false;
							})}
						</Row>
					</div> :
					fieldLine(this)
				);
			})
	}

	/**
	 * Retorna o tamanho da coluna do formulário.
	 * @param {Array} node Lista de fields do documento de configuração.
	 * @returns Tamanho da coluna do formulário.
	 */
	getSizeCol(node) {
		return node.filter(item => {
			return item.type !== "header" || !!item.edit ? item : null;
		}).length;
	}

	/**
	 * Carrega os dados do módulo Configs na lista do campo select.
	 * @param {Object} node Field do documento de configuração com atributo optionConfig.
	 */
	loadContentFromConfigs(node) {
		// configs has been loaded on permission loader
		let configs = this.props.configs[node.optionConfig.name]
		
		if(!node.optionConfig.arrayWithId){
			node.options = (configs ? configs.value : []).map(v => { 
				return { label: v, value: v } 
			})
		} else node.options = (configs ? configs.array_with_id : [])
		
		node.loaded = true;
	}

	/**
	 * Retorna o elemento com base no tipo do nó de configuração do formulário.
	 * @param {Object} node Field do documento de configuração.
	 * @param {Number} size Tamanho do campo do formulário.
	 * @param {Object} value 
	 * @param {Number} index Posição da lista para compor id de campos.
	 * @returns Elemento renderizado conforme o tipo de nó especificado.
	 */
	getElementByTypeSchema(node, size, value = {}, index) {
		let title = node.title ? `${node.title}` : <div>&nbsp;</div>;
		let elAttrs = {};

		if (node.hasOwnProperty('element-attr')) {
			for (let attr in node['element-attr']) {
				if (['beforeChange', 'onChangeOverride', 'afterChange'].includes(attr)) continue;
				let n = node['element-attr'][attr]
				elAttrs[attr] = (typeof n === 'function') ? n(this) : n;
			}
		}

		switch (node.type) {
			case "header":
				return (
					<div span={size} key={node.key} className={node.className || ""}>
						<h2>{node.title}</h2>
					</div>
				);
			case "text":
			case "password":
			case "email":
				let validateAttr = {
					validateStatus: this.validateNodeValue(node),
					help: (this.validateNodeValue(node) === "error") ? node.errorMessage || "" : ""
				}
				if (node.type === 'email') validateAttr = ValidateEmail(this.state.formRef[node.key] || '')

				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} {...validateAttr}>
							{/* <Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} validateStatus={this.validateNodeValue(node)} help={(this.validateNodeValue(node) === "error") ? node.errorMessage || "" : ""}> */}
							<Input
								onChange={event =>
									this.updateRefValue(node, event.target.value)
								}
								ref={node.key}
								placeholder={node.title}
								type={node.type}
								// value={this.state.formRef[node.key] || ""}
								value={
									node.hasOwnProperty('maskToValue') ?
										node.maskToValue['phoneMask'] ? CreatePhoneMask(this.state.formRef[node.key]) : CreateMask(node.maskToValue['mask'], this.state.formRef[node.key], node.maskToValue['mask'].length, node.maskToValue['onlyNumber'])
										: (this.state.formRef[node.key] || '')}
								size="large"
								node={node}
								{...elAttrs}
							/>
						</Form.Item>
					</Col>
				);
			case "textarea":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} validateStatus={this.validateNodeValue(node)} help={(this.validateNodeValue(node) === "error") ? node.errorMessage || "" : ""}>
							<Input.TextArea
								onChange={event =>
									this.updateRefValue(node, event.target.value)
								}
								ref={node.key}
								placeholder={node.title}
								type={node.type}
								value={this.state.formRef[node.key] || ""}
								size="large"
								node={node}
								{...elAttrs}
							/>
						</Form.Item>
					</Col>
				)

			case "money":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							{/* <div className="ant-form-item-control">
								<span className="ant-form-item-children"> */}
							<InputNumber
								min={0}
								ref={node.key}
								onChange={event => this.updateRefValue(node, event)}
								size="large"
								placeholder='R$ 0,00'
								step={node.step || "0.1"}
								node={node}
								//value={node.hasOwnProperty('mask') ? node.mask(this.state.formRef[node.key]) : this.state.formRef[node.key]}
								value={node.hasOwnProperty('maskToValue') ? CreateMask(node.maskToValue['mask'], this.state.formRef[node.key], node.maskToValue['mask'].length, node.maskToValue['onlyNumber']) : this.state.formRef[node.key]}
								{...node["element-attr"]}
								formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
								parser={value => {
									let decimals = value.split(',')[1]
									if (decimals && decimals.length > 2) {
										decimals = decimals.substring(0, 2)
										value = `${value.split(',')[0]},${decimals}`
									}
									return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
								}}
							// formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
							// parser={value => value.replace(/\$\s?|(.*)/g, '')}
							/>
							{/* </span>
							</div> */}
						</Form.Item>
					</Col>
				)

			case "number":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<InputNumber
								min={0}
								ref={node.key}
								onChange={event => this.updateRefValue(node, event)}
								size="large"
								placeholder={node.title}
								step={node.step || "0.1"}
								node={node}
								value={node.hasOwnProperty('maskToValue') ? CreateMask(node.maskToValue['mask'], this.state.formRef[node.key], node.maskToValue['mask'].length, node.maskToValue['onlyNumber']) : this.state.formRef[node.key]}
								type='number'
								{...elAttrs}
							/>
						</Form.Item>
					</Col>
				);

			case "select":
				if (node.hasOwnProperty('optionConfig'))
					this.loadContentFromConfigs(node)

				let opts = (node.render) ? node.render(node.options) : node.options.map((opt, key) => {
					return (
						<Select.Option key={key} value={opt.value} disabled={opt.disabled}>
							{opt.label}
						</Select.Option>
					);
				})

				const copyValue = _.clone(this.state.formRef[node.key])
				let value = this.state.formRef[node.key]

				if (Array.isArray(value)) {
					copyValue.sort((a, b) => a - b)
					value = copyValue
				}

				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Select
								ref={node.key}
								onChange={event => this.updateRefValue(node, event)}
								showSearch
								autoClearSearchValue={false}
								notFoundContent="Não encontrado"
								size="large"
								node={node}
								placeholder={node.title}
								value={value}
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								{...elAttrs}>
								{opts}
							</Select>
						</Form.Item>
					</Col>
				);

			case "date-picker":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<DatePicker
								ref={node.key}
								node={node}
								onChange={event => this.updateRefValue(node, event)}
								size="large"
								placeholder={node.title}
								format="DD/MM/YYYY"
								value={
									this.state.formRef[node.key] ?
										(moment.isMoment(this.state.formRef[node.key]) ? this.state.formRef[node.key] : moment(this.state.formRef[node.key])) :
										null
								}
								{...elAttrs}
							/>
						</Form.Item>
					</Col>
				);

			case "switch":
				if (this.state.formRef[node.key] === undefined) {
					this.setState(() => {
						return {
							formRef: {
								...this.state.formRef,
								[node.key]: node.defaultValue || false
							}
						}
					})
				}
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Switch
								ref={node.key}
								node={node}
								onChange={event => this.updateRefValue(node, event)}
								size="large"
								checkedChildren={<Icon type="check" theme="outlined" />}
								unCheckedChildren={<Icon type="close" theme="outlined" />}
								checked={this.state.formRef[node.key]}
								value={this.state.formRef[node.key]}
								{...elAttrs}
							/>
						</Form.Item>
					</Col>
				);

			case "multi-select":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Select
								ref={node.key}
								node={node}
								onChange={event => this.updateRefValue(node, event)}
								showSearch
								autoClearSearchValue={false}
								notFoundContent="Não encontrado"
								size="large"
								mode="multiple"
								placeholder={node.title}
								value={this.state.formRef[node.key]}
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								{...elAttrs}>
								{node.options.map((opt, key) => {
									return (
										<Select.Option key={key} value={opt.value}>
											{opt.label}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				);

			case "pointer":
			case "relation-old":
				(!node.hasOwnProperty("loadContentRelationOverride")) ? this.loadContentRelation(node, node._ignoreLoad || false) : node.loadContentRelationOverride(this, node)
				let options = node.options
				let list = node['_parseObjectOptionsList']
				let defaultValue = (node.type === 'pointer') ? undefined : []
				if ((!this.state.formRef[node.key] && node['_parseObjectOptionsList']) || node.hasOwnProperty('filter')) {
					if (node.hasOwnProperty('filter') && node.filter) {
						list = node.filter(this, node['_parseObjectOptionsList'])
					}
					options = list.map((item, key) => {
						let data = item.toJSON();
						let label = (typeof node['relation-select'].label === 'function') ? node['relation-select'].label(data) : data[node["relation-select"].label]
						return {
							key: data[node["relation-select"].key],
							label,
							value: data[node["relation-select"].value]
						};
					});
				}

				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Select
								ref={node.key}
								node={node}
								mode="multiple"
								style={{ width: "100%" }}
								placeholder="Selecione..."
								size="large"
								showSearch
								filterOption={(input, option) => (option.props.children || "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
								onChange={event => this.updateRefValue(node, event)}
								value={this.state.formRef[node.key] || defaultValue}
								{...elAttrs}
								notFoundContent="Não encontrado">
								<Select.Option value={null}>
									Selecione...
								</Select.Option>
								{options.map((opt, key) => {
									return (
										<Select.Option key={key} value={opt.value}>
											{opt.label}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				);

			case "relation":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<RelationSelect
								form={this}
								node={node}
								ref={node.key}
								onChange={(event) => this.updateRefValue(node, event.value)}
								value={this.state.formRef[node.key] || []}
							/>
						</Form.Item>
					</Col>
				);

			case "relation-search":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<RelationSearchSelect
								node={node}
								setValue={e => this.updateRefValue(node, e)}
								value={this.state.formRef[node.key] || ''}
								setDataList={e => node["_parseObjectOptionsList"] = e}
							/>
						</Form.Item>
					</Col>
				)

			case "profile-image":
				let previewUrl = ""
				if (this.state.formRef[node.key])
					previewUrl = this.state.formRef[node.key].previewUrl
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Upload
								ref={node.key}
								node={node}
								beforeUpload={(file, fileList) => this.beforeUploadImage(node, file, fileList)}
								className="upload-list-inline"
								{...elAttrs}>
								<div className="image-perfil-file" style={{ 'backgroundImage': `url(${previewUrl})` }}>
									{(!previewUrl) ?
										<Icon type="picture" /> :
										<div className="background-image-file">
											<Icon className="icon-remove-element-image" type="delete" onClick={(event) => this.removeImageElementTypeImage(node, event)} />
										</div>}
								</div>
								<Button className="btn-image-perfil">
									<Icon type="upload" /> Selecionar imagem
								</Button>
							</Upload>
						</Form.Item>
					</Col>
				)


			case "image":
				let previewUrlImage = ""
				if (this.state.formRef[node.key])
					previewUrlImage = this.state.formRef[node.key].previewUrl
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Upload
								ref={node.key}
								node={node}
								beforeUpload={(file, fileList) => this.beforeUploadImage(node, file, fileList)}
								className="upload-list-inline"
								{...elAttrs}>
								<div className="image-perfil-file" style={{ 'backgroundImage': `url(${previewUrlImage})` }}>
									{(!previewUrlImage) ?
										<Icon type="picture" /> :
										<div className="background-image-file">
											<Icon className="icon-remove-element-image" type="delete" onClick={(event) => this.removeImageElementTypeImage(node, event)} />
										</div>}
								</div>
								<Button className="btn-image-perfil">
									<Icon type="upload" /> Selecionar imagem
								</Button>
							</Upload>
						</Form.Item>
					</Col>
				)

			case "document":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Upload
								ref={node.key}
								node={node}
								beforeUpload={(file, fileList) => this.beforeUploadDoc(node, file, fileList)}
								onChange={event => {
									this.updateRefValue(node, new Parse.File(event.file.name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), event.file))
								}}
								value={this.state.formRef[node.key]}
								fileList={[]}
								className="upload-list-inline"
								{...elAttrs}>
								<Button className="btn-image-perfil">
									<Icon type="upload" /> Selecionar documento
								</Button>
							</Upload>
						</Form.Item>
						{this.state.formRef[node.key] ?
							typeof this.state.formRef[node.key].name === 'string' ?
								<Row gutter={24}>
									<Col span={20}>
										<a target='blank' href={this.state.formRef[node.key].url}>
											{(this.state.formRef[node.key].name.substring((this.state.formRef[node.key].name.indexOf('_') + 1),)) || ''}
										</a>
									</Col>
									<Col span={4}><Icon style={{ cursor: 'pointer' }} className="icon-remove-element-image" type="delete" onClick={() => this.updateRefValue(node, null)} /></Col>
								</Row>
								:
								<Row gutter={24}>
									<Col span={20}>
										<span>{this.state.formRef[node.key]._name}</span>
									</Col>
									<Col span={3}><Icon style={{ cursor: 'pointer' }} className="icon-remove-element-image" type="delete" onClick={() => this.updateRefValue(node, null)} /></Col>
								</Row>
							: null
						}
					</Col>
				)

			case "files":
				const columns = [
					{
						title: 'Nome',
						dataIndex: 'name',
						key: 'name',
					},
					{
						title: 'Enviado por',
						dataIndex: 'owner',
						key: 'owner',
						width: '150px',
						align: 'center',
					},
					{
						title: 'Data do envio',
						dataIndex: 'date',
						key: 'date',
						width: '150px',
						align: 'center',
					},
					{
						key: 'action',
						width: '150px',
						align: 'center',
						render: (text, record) => (
							<span
								style={{ cursor: 'pointer', color: 'red' }}
								onClick={() => {
									let _this = this
									Modal.confirm({
										title: 'Tem certeza que deseja exlcuir esse arquivo?',
										content: 'Essa ação não poderá ser desfeita',
										onOk() {
											let fileList = _this.state.formRef[node.key] || [];
											fileList.splice(record.index, 1);
											_this.updateRefValue(node, fileList);
										}
									})
								}}
							>
								Remover
							</span>
						),
					},
				];
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Row>
							<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
								<Button
									onClick={() => document.getElementById(`${this.props.module.collection}_${node.key}`).click()}
								>
									<Icon type="upload" /> Enviar arquivo
								</Button>
								<input
									type='file'
									id={`${this.props.module.collection}_${node.key}`}
									onChange={(e) => {
										// Get file and transform in a parse file
										let fileToPut = new Parse.File(e.target.files[0].name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), e.target.files[0]);

										// Get local path to open file before save
										let localPath = window.URL.createObjectURL(e.target.files[0]);

										// Get user and date
										let currentUser = Parse.User.current();
										let date = new Date();

										// Put file in filelist
										let fileList = this.state.formRef[node.key] || [];
										fileList.push({
											file: fileToPut,
											fileType: e.target.files[0].type,
											localPath: localPath,
											userAndDate: {
												user: currentUser.get('name'),
												date: date.toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }),
											}
										});

										this.updateRefValue(node, fileList);
									}}
									style={{ display: 'none' }}
								/>
							</Form.Item>
						</Row>
						<Row>
							<Table
								locale={{ emptyText: 'Sem arquivos' }}
								columns={columns}
								dataSource={(this.state.formRef[node.key] || []).map((item, index) => {

									// Temporary: show file named as doc
									let file = item.file || item.doc;

									return {
										'name': <a href={!file._source ? file.url : item.localPath} rel="noopener noreferrer" target="_blank">
											<Icon
												type={getFileIcon(item.fileType).type}
												theme="filled"
												style={{ marginRight: 10, color: getFileIcon(item.fileType).color, fontSize: '25px' }}
											/> {file._name || file.name.split('_')[1]}
										</a>,
										'owner': item.userAndDate && item.userAndDate.user,
										'date': item.userAndDate && item.userAndDate.date,
										'index': index
									}
								})}
							/>
						</Row>
					</Col>
				)


			case "white-space":
				return (
					<Col span={size} key={"white-" + index} className={node.className || ""}>
					</Col>
				)

			case "confirm-password":
				return (
					<Col span={size} key={node.key} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} validateStatus={this.validateNodeValue(node)} help={(this.validateNodeValue(node) === "error") ? node.errorMessage || "" : ""}>
							<Input
								ref={node.key}
								node={node}
								onChange={event =>
									this.updateRefValue(node, event.target.value)
								}
								placeholder={node.title}
								type="password"
								value={this.state.formRef[node.key]}
								size="large"
								{...elAttrs}
							/>
						</Form.Item>
					</Col>
				)

			case "input-option":

				return (
					<Col span={size} key={node.key.input} className={node.className || ""}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<div style={{ marginBottom: 16 }}>
								<Input node={node} size="large" ref={node.key.input} onChange={(event) => this.updateRefValue(node, event.target.value, node.key.input)} addonAfter={
									<Select
										ref={node.key.select}
										node={node}
										placeholder='Selecione..'
										onChange={(event) => {
											this.updateRefValue(node, event, node.key.option)
										}}
										value={this.state.formRef[node.key.option]}
										style={node["style-select"] || { width: 80 }}>
										{node.option.map((item, index) => <Select.Option key={item} value={item}>{item}</Select.Option>)}
									</Select>
								}
									value={this.state.formRef[node.key.input]}
									{...elAttrs} />
							</div>
						</Form.Item>
					</Col>
				)

			case "input-slide":
				if (!node.config) node.config = {}
				if (this.state.formRef[node.key] === undefined) {
					this.setState(() => {
						return {
							formRef: {
								...this.state.formRef,
								[node.key]: node.defaultValue || 0
							}
						}
					})
				}
				// FIXME: change middle Input to InputNumber
				return (
					<Col span={size} key={node.key} className={(node.className || "") + " input-slide-col"}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<Input ref={node.key} style={{ display: 'none' }} node={node} size="large" {...node.config.input || {}} required={false} type="number" onChange={event => this.updateRefValue(node, event.target.value)} value={(parseFloat(this.state.formRef[node.key] || 0))} />
							<Slider ref={node.key + "_slider"} node={node} size="large" {...node.config.slider || {}} required={false} onChange={value => this.updateRefValue(node, value)} value={(parseFloat(this.state.formRef[node.key]) || 0)} />
							<Input ref={node.key + "_input"} node={node} size="large" {...node.config.input || {}} max={100} min={0} required={false} type="number" step="0.01" onChange={event => this.updateRefValue(node, event.target.value)} value={(parseFloat(this.state.formRef[node.key]) || 0)} />
							{node.showLastInput ?
								<Input node={node} size="large" {...node.config.show || {}} disabled={true} value={node.arrange(this.state.formRef[node.key], this.state, this) || 0} /> : null
							}
						</Form.Item>
					</Col>
				)

			case "render":
				return (
					<Col span={size} key={node.key} className={(node.className || "") + " render-col"}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							{node.render(this.state, this, node)}
						</Form.Item>
					</Col>
				)

			case "tag-list":
				return (
					<Col span={size} key={node.key} className={(node.className || "") + " tag-list"}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<EditableTagGroup
								{...elAttrs}
								elAttrs={elAttrs}
								onChange={values => this.updateRefValue(node, values)}
								value={this.state.formRef[node.key] || []} node={node} />
						</Form.Item>
					</Col>
				)

			case "tag-list-hash":
				return (
					<Col span={size} key={node.key} className={(node.className || "") + " tag-list"}>
						<Form.Item required={_.get(node, ['element-attr', 'required'], false)} ref={`${node.key}_form_item`} label={title} >
							<EditableTagGroupHash
								{...elAttrs}
								onChange={values => this.updateRefValue(node, values)}
								value={this.state.formRef[node.key] || []} node={node} />
						</Form.Item>
					</Col>
				)

			case "dynamic-input-list":
				return (
					<Col span={size} key={node.key} className={(node.className || "") + " dynamic-input-list"}>
						<Form.Item required={false} ref={`${node.key}_form_item`} >
							{typeof title === 'string' ? <Divider>{title}</Divider> : null}
							<DynamicInputList
								{...elAttrs}
								module={this.props.module}
								configs={this.props.configs}
								onChange={values => this.updateRefValue(node, values)}
								formFields={node.fields}
								value={this.state.formRef[node.key] || []} node={node} />
						</Form.Item>
					</Col>
				)

			default:
				return <div key={node.key} />;

		}
	}

	/**
	 * Valida determinado nó.
	 * @param {Object} node Field do documento de configuração.
	 * @param {null} value 
	 * @returns success ou error.
	 */
	validateNodeValue(node, value) {
		if (node.type === "password" || node.type === "confirm-password") {
			if (this.state.formRef[node.key] === this.state.formRef[node.confirmValue]) {
				return 'success'
			}
			return "error"
		}

		if (node.min && this.getNodeValue(node.key) && node.min > this.getNodeValue(node.key).length) {
			return 'error'
		}

		if (node.max && this.getNodeValue(node.key) && node.max < this.getNodeValue(node.key).length) {
			return 'error'
		}

		if (!this.state.formRef[node.key]) return "success"

		if (node.regex) {
			return (new RegExp(node.regex).test(this.state.formRef[node.key]) ? "success" : "error")
		}

		return "success"
	}

	/**
	 * Carrega os dados relacionados.
	 * @param {Object} node Field relation do documento de configuração.
	 * @param {Boolean} ignoreLoaded Ignorar o carregamento das options.
	 * @todo Resolver multiples requests.
	 */
	loadContentRelation(node, ignoreLoaded = false) {
		if (ignoreLoaded) return false;

		if (node.preventFirstLoad || (node.hasOwnProperty('_inLoad') && node['_inLoad']) || (node.hasOwnProperty('_loaded') && node['_loaded'])) return false;

		if (this.state.loaded[node.key] && !ignoreLoaded) return false;
		let ParseQuery = new Parse.Query(
			new Parse.Object.extend(node["relation-select"].class)
		);

		if (node["relation-select"].hasOwnProperty('where')) {
			let whereQ = { ...node['relation-select'].where }
			for (let attr in whereQ) {
				if (typeof whereQ[attr] === 'function') whereQ[attr] = whereQ[attr](this, ParseQuery)
			}
			ParseQuery._where = whereQ;
		}

		if (node['relation-select'].hasOwnProperty('orderBy')) {
			ParseQuery.ascending(node['relation-select'].orderBy)
		}

		ParseQuery.limit(10000)
		node['_inLoad'] = true;
		ParseQuery.find({
			success: response => {
				node.options = response.map((item, key) => {
					let data = item.toJSON();
					let label = (typeof node['relation-select'].label === 'function') ? node['relation-select'].label(data) : data[node["relation-select"].label]
					return {
						key: data[node["relation-select"].key],
						label,
						value: data[node["relation-select"].value]
					};
				});
				node['_loaded'] = true;
				node["_parseObjectOptionsList"] = response;
				let merge = {}
				if (node.hasOwnProperty('loadFirstValue') && node.loadFirstValue) {
					merge = {
						[node.key]: node.options.length > 0 ? node.options[0].key : null
					}
				}
				this.setState((state) => {
					return {
						formRef: {
							...state.formRef,
							...merge
						},
						loaded: {
							...this.state.loaded,
							[node.key]: true
						}
					}
				}, () => {
					node['_inLoad'] = false;
				})
			},
			error: err => {
				notification.error({
					title: "Erro, ao carregar dados do select.",
					message: err
				});
			}
		});
	}

	/**
	 * Atualiza o state do input no form.
	 * @description Função é chamada pelos elementos do formulário.
	 * @param {Object} node Field do documento de configuração.
	 * @param {String} value Valor a ser inserido no input do form. 
	 * @param {*} key 
	 */
	updateRefValue(node, value, key = null) {
		if (node['element-attr'] && node['element-attr']['beforeChange'] && typeof node['element-attr']['beforeChange'] === 'function')
			node['element-attr']['beforeChange'](this, node, value)

		if (node['element-attr'] && node['element-attr']['onChangeOverride'] && typeof node['element-attr']['onChangeOverride'] === 'function')
			return node['element-attr']['onChangeOverride'](this, node, value)

		let attrFormRef = key ? key : node.key;

		let validForm = false
		this.formSchemaValids().forEach(node => {
			if ((Array.isArray(this.state.formRef[node.key]) && this.state.formRef[node.key].length === 0) || !this.state.formRef[node.key]) {
				validForm = false
			}
		})

		let _form = Object.assign({}, this.state.formRef);
		_form[attrFormRef] = value;
		if (!!node.relation) {
			_form["__value"] = value;
		}

		this.setState(() => {
			return { formRef: _form, invalidForm: validForm }
		}, () => setTimeout(() => {
			if (node['element-attr'] && node['element-attr']['afterChange'] && typeof node['element-attr']['afterChange'] === 'function')
				node['element-attr']['afterChange'](this, node, value)
		})
		);
	}

	/**
	 * Retorna os elementos que serão inseridos no banco.
	 * @param {null}
	 * @returns Lista dos elementos que serão inseridos no banco.
	 */
	formSchemaValids() {
		let fields = this.module.form.formTabs ?
			this.module.form.formTabs.map((el) => el.fields).flat() : this.module.form.fields;
		return fields
			.reduce((acc, value) => {
				return acc.concat(value);
			}, [])
			.filter(item => {
				let _return = true;
				if (['header', 'white-space', 'confirm-password', 'profile-image'].indexOf(item.type) >= 0) {
					_return = false;
				} else if (this.state.objectEdit && item.edit === false) {
					_return = false
				}
				return _return
			});
	}

	/**
	 * Retorna um json com os dados tratados para edição.
	 * @example O componente de data-picker precisa receber um object da lib moment, essa função converte a data do banco para a lib moment
	 * @param {Object} object Field do documento de configuração.
	 * @param {ParseObject} realResponseObject Objeto Parse a ser convertido para json.
	 * @returns Json com os dados tratados para edição.
	 */
	normalizeToEdit(object, realResponseObject) {
		let newStateObject = {
			objectId: object.objectId
		};

		this.formSchemaValids().forEach(node => {
			if (!!node.relation && !node["relation-select"]) {
				newStateObject[node.key] = object[node.key][node.relation.value];
			} else if (node.type === 'pointer' || node.type === 'relation-search') {
				if (object[node.key])
					newStateObject[node.key] = object[node.key].objectId
			} else if (node["relation-select"]) {
				newStateObject[node.key] = [];
				let ItemKeyObject = realResponseObject.get(node.key)
				if (!ItemKeyObject) {
					return this.navigateToRouterComponent('/erro')
				}
				if (ItemKeyObject.query) {
					ItemKeyObject
						.query()
						.find()
						.then(response => {
							this.updateRefValue(node, response)
						}).catch(err => {
							console.log(err)
						})
				}
			} else if (node.type === 'input-option') {
				newStateObject[node.key.input] = object[node.key.input]
				newStateObject[node.key.option] = object[node.key.option]
			} else if (node.type === "date-picker") {
				if (object[node.key])
					newStateObject[node.key] = moment(object[node.key].iso)
			} else if (node.type === 'image' && realResponseObject.get(node.key)) {
				newStateObject[node.key] = {
					url: realResponseObject.get(node.key).url(),
					previewUrl: realResponseObject.get(node.key).url(),
					new: false,
					__type: 'Photo'
				}
			} else {
				if (node.edit !== false) newStateObject[node.key] = object[node.key];
			}
		});

		let profileNodes = this.getNodesByType('profile-image');
		if (profileNodes.length >= 1) {
			profileNodes = profileNodes.map(node => {
				if (realResponseObject.get(node.key)) {
					return {
						[node.key]: {
							url: realResponseObject.get(node.key),
							previewUrl: `${Parse.serverURL}/files/${Parse.applicationId}/${realResponseObject.get(node.key)}`,
							new: false,
							__type: 'Photo'
						}
					}
				}
				return null;
			})
			if (profileNodes[0])
				newStateObject = Object.assign(newStateObject, profileNodes[0])
		}

		return newStateObject;
	}

	/**
	 * Recebe um valor money no formato 'R$ 00,00' e retorna o float do valor.
	 * @param {String} currency Valor em formato money.
	 * @returns Valor no formato float.
	 */
	parseLocaleCurrencyToFloat(currency) {
		return (isNumber(currency)) ? currency : parseFloat(currency.toString().replace(/(R\$)+\s/, '').replace(/\./g, '').replace(/\,/g, '.')); // eslint-disable-line
	}

	/**
	 * Retorna o nó de configuração do modulo com base no tipo.
	 * @param {String} type Tipo.
	 * @returns Lista dos nós de configuração.
	 */
	getNodesByType(type) {
		let fields = this.module.form.formTabs ?
			this.module.form.formTabs.map((el) => el.fields).flat() : this.module.form.fields;
		return fields
			.reduce((acc, value) => {
				return acc.concat(value);
			}, []).filter(node => node.type === type)
	}

	/**
	 * Retorna o nó de configuração do modulo com base na key.
	 * @param {String} key
	 * @returns Lista dos nós de configuração. 
	 */
	getNodesByKey(key) {
		let fields = this.module.form.formTabs ?
			this.module.form.formTabs.map((el) => el.fields).flat() : this.module.form.fields;
		return fields
			.reduce((acc, value) => {
				return acc.concat(value);
			}, []).filter(node => node.key === key)[0] || null
	}

	/**
	 * Verifica se o valor nó é valido.
	 * @param {Object} node Field do documento de configuração.
	 * @returns true ou false.
	 */
	nodeIsValid(node) {
		if (!node) return true
		if (!node.hasOwnProperty('valid')) return true;
		let valid = node.valid.check(this);
		if (!valid && node.valid.error) node.valid.error(this)
		if (valid && node.valid.success) node.valid.success(this)
		return valid
	}

	/**
	 * Verifica se a mudança de um valor de um campo relation foi aplicada.
	 * @param {Object} node Field do documento de configuração.
	 * @returns true ou false. 
	 */
	checkRelationChange(node) {
		return node['_changedValue']
	}

	/**
	 * Valida a submissão do formulário.
	 * @param {null}
	 * @returns true ou false.
	 */
	validateSubmission() {
		let refs = this.getRefWithFormItem(false);
		let invalid = false;
		for (let key in refs) {

			let node = this.getNodesByKey(key)
			if (node && ['switch', 'input-slide'].includes(node.type)) continue; // case swtich go to next key
			// checking if exist empty required inputs.
			if (this.checkRelationChange(node)) {
				notification.error({ message: "Você precisa aplicar as mudanças em alguns campos." })
				return true;
			}

			if (!this.nodeIsValid(node || {}) || (!this.nodeHasEmptyValue(this.refs[key].props.value || this.state.formRef[key]) && this.nodeIsRequired(this.refs[key].props.node)) || this.validateNodeValue(node || {}) === 'error') {
				// console.log(this.nodeIsRequired(this.refs[key].props.node))
				notification.error({ message: node.errorMessage || `Valor inválido no campo: ${this.refs[key].props.node.title}`, duration: 5 })
				invalid = true;
				break;
			}
		}
		return invalid;
	}

	/**
	 * Salva ou edita os dados no bd.
	 * @param {Object} condForm Objeto com parametros edit (para edita uma row no banco) ou create (para cria uma nova row no banco) 
	 */
	saveFormItem(condForm) {
		if (this.validateSubmission()) return false;

		let classRef = this;

		let create = async (callback) => {
			let _Extended = Parse.Object.extend(this.props.module.form.submit.collection);

			let $ParseObject = new _Extended();
			const schemaValids = this.formSchemaValids();
			for (let i = 0; i < schemaValids.length; i++) {
				const node = schemaValids[i];
				if (node.beforeSave) {
					const returnBeforeSave = await node.beforeSave(this, $ParseObject, node)
					if (!returnBeforeSave) return;
				}
				if (node.type === "date-picker") {
					$ParseObject.set(node.key, this.state.formRef[node.key].toDate())
				} else if (node.type === 'pointer' || node.type === 'relation-search') {
					if (this.state.formRef[node.key]) {
						let PointerObject = new Parse.Object(node['relation-select'].class);
						PointerObject.id = this.state.formRef[node.key];
						$ParseObject.set(node.key, PointerObject);
					}
					// else {
					// 	notification.error({ message: `Verifique novamente o campo: ${node.title}` })
					// 	return
					// }
				} else if (node.type === 'number') {
					$ParseObject.set(node.key, parseFloat(this.state.formRef[node.key]))
				} else if (node.type === 'image' && (this.state.formRef[node.key] && this.state.formRef[node.key].file)) {
					$ParseObject.set(node.key, new Parse.File(new Date().getTime(), this.state.formRef[node.key].file))
				} else if (node.type === "input-slide") {
					$ParseObject.set(node.key, parseFloat(this.state.formRef[node.key]))
				} else if (node.type === "money") {
					let val = this.parseLocaleCurrencyToFloat(this.state.formRef[node.key] || "R$ 0,00");
					$ParseObject.set(node.key, val)
				} else if (node.type === "input-option") {
					$ParseObject.set(node.key.input, this.state.formRef[node.key.input])
					$ParseObject.set(node.key.option, this.state.formRef[node.key.option])
				} else if (!node.relation && !node["relation-select"] && node.type !== "render") {
					$ParseObject.set(node.key, this.state.formRef[node.key]);
				} else if (node["relation-select"] && node.type !== 'pointer') {
					let val = this.state.formRef[node.key]
					if (val && val.length > 0) {

						let $RelationObject = new Parse.Relation($ParseObject, node.key);
						$RelationObject.add(this.state.formRef[node.key]);
					}
				} else if (node.type === 'render' && node.isListInput) {
					// Ocasião onde o type render é utilizado para renderizar uma lista de input
					$ParseObject.set(node.key, this.state.formRef[node.key]);
				}
				// });
			}

			let afterSave = () => {
				let _Query = new Parse.Query(this.props.module.collection);
				_Query.count().then(totalResult => {
					localStorage.setItem(`Pagination:${this.props.module.form.module}`, Math.floor(totalResult / 16))
					notification.success({
						message: "Sucesso!",
						description: "Cadastro realizado com succeso!"
					});
					this.navigateToRouterComponent('/')
					if (callback) callback()
				})
			};

			$ParseObject.set('updatedBy', Parse.User.current());

			$ParseObject.save(null, {
				success: parseObject => {
					let profileImage = this.getNodesByType('profile-image');
					if (profileImage.length > 0) {
						let formRef = this.state.formRef[profileImage[0].key];
						if (!formRef) return afterSave();
						let ParseFile = new Parse.File(formRef.file.uid, formRef.file)
						ParseFile.save(null).then(fileSaved => {
							Parse.Cloud.run('saveProfileImage', {
								objectId: parseObject.id,
								name: fileSaved.name()
							}).then(response => {
								afterSave();
							})
						})
						return false;
					}
					return afterSave();
				},
				error: (_object, err = {}) => {
					notification.error({
						message: "Erro de cadastro.",
						description: err.message || "Erro, tente novamente"
					});
				}
			});
		};

		let edit = async (callback = null) => {
			let _Extended = Parse.Object.extend(this.props.module.form.submit.collection);
			let _Query = new Parse.Query(_Extended);
			let relationNodeList = [];

			let finishEdit = objectEdited => {
				message.success("Edição realizada com sucesso!");
				classRef.setState(state => {
					return {
						formRef: {},
						loadingDataEdit: false
					}
				}, () => {
					this.navigateToRouterComponent('/');
					if (callback) callback()
				})
			};

			_Query.get(this.state.formRef.objectId, {
				success: async $ParseObject => {
					const schemaValids = this.formSchemaValids();
					for (let i = 0; i < schemaValids.length; i++) {
						const node = schemaValids[i];

						if (node.beforeSave) {
							const returnBeforeSave = await node.beforeSave(this, $ParseObject, node)
							if (!returnBeforeSave) return;
						}

						if (node.type === "date-picker") {
							$ParseObject.set(node.key, this.state.formRef[node.key].toDate())
						} else if (node.type === 'pointer' || node.type === 'relation-search') {
							if (this.state.formRef[node.key]) {
								let PointerObject = new Parse.Object(node['relation-select'].class);
								PointerObject.id = this.state.formRef[node.key];
								$ParseObject.set(node.key, PointerObject);
							}
							// else {
							// 	notification.error({ message: `Verifique novamente o campo: ${node.title}` })
							// 	return
							// }
						} else if (node.type === 'number') {
							$ParseObject.set(node.key, parseFloat(this.state.formRef[node.key]))
						} else if (node.type === 'image' && this.state.formRef[node.key]) {

							// Se estiver upando uma imagem nova entrará na primeira condição, se não irá buscar no banco a imagem que possuir
							if (this.state.formRef[node.key].file) {
								$ParseObject.set(node.key, new Parse.File(new Date().getTime(), this.state.formRef[node.key].file))
							} else {
								_Query.get(this.state.formRef.objectId, {
									success: parseObject => {
										$ParseObject.set(node.key, parseObject['attributes'][node.key])
									}
								})
							}
						} else if (node.type === "input-slide") {
							$ParseObject.set(node.key, parseFloat(this.state.formRef[node.key]))
						} else if (node.type === "money") {
							let val = this.parseLocaleCurrencyToFloat(this.state.formRef[node.key] || "R$ 0,00");
							$ParseObject.set(node.key, val)
						} else if (node.type === "input-option") {
							$ParseObject.set(node.key.input, this.state.formRef[node.key.input])
							$ParseObject.set(node.key.option, this.state.formRef[node.key.option])
						} else if (!node.relation && !node["relation-select"] && node.type !== "render" && node.type !== 'image') {
							$ParseObject.set(node.key, this.state.formRef[node.key]);
						} else if (node["relation-select"] && node.type !== 'pointer') {
							relationNodeList.push(node);
						} else if (node.type === 'render' && node.isListInput) {
							// Ocasião onde o type render é utilizado para renderizar uma lista de input
							$ParseObject.set(node.key, this.state.formRef[node.key]);
						}
					}

					// faz o update de todos os fields do form que são do tipo relation de forma recursiva
					let removeAllRelationsAndSave = ($parseObjectToEdit, relationNodeObjectList, indexOfNodeList, Callback) => {
						if (relationNodeObjectList.length === indexOfNodeList) {
							Callback()
							return false
						}

						let node = relationNodeObjectList[indexOfNodeList] // node sempre faz referencia a um objeto do .json de configuração do módulo.
						let $parseObjectRelation = $parseObjectToEdit.relation(node.key)
						$parseObjectRelation.query().find().then($ParseResponse => {

							if ($ParseResponse.length > 0) {
								$parseObjectRelation.remove($ParseResponse) // deleta todos os dados da table relation.
							}

							let $parseObjectRelationToSave = this.state.formRef[node.key]

							if ($parseObjectRelationToSave.length > 0)
								$parseObjectRelation.add($parseObjectRelationToSave)

							$parseObjectToEdit.save(null).then($parseSavedResponse => {
								removeAllRelationsAndSave($parseObjectToEdit, relationNodeObjectList, ++indexOfNodeList, Callback)
							})
						})
					}


					if (this.props.module.form.module !== 'User') {
						$ParseObject.set('updatedBy', Parse.User.current());
						$ParseObject.save(null, {
							success: objectEdited => {
								let profileImageNodes = this.getNodesByType('profile-image')
								if (profileImageNodes.length) {
									let profileNode = this.state.formRef[profileImageNodes[0].key]
									if (profileNode.new) {
										let file = new Parse.File(profileNode.file.uid, profileNode.file)
										file.save().then(saved => {
											objectEdited.set(profileImageNodes[0].key, saved.name())
											objectEdited.save(null).then()
										})
									}
								}

								if (relationNodeList.length) {
									removeAllRelationsAndSave(objectEdited, relationNodeList, 0, () => {
										finishEdit(objectEdited)
									});
								} else {
									finishEdit(objectEdited);
								}
							},
							error: (_object, err = {}) => {
								message.error(err.message || "Erro ao tentar editar.")
							}
						});
					} else {
						let formRef = this.state.formRef
						formRef['updatedBy'] = Parse.User.current().id
						formRef['updatedAt'] = new Date()

						let hasFile = this.getNodesByType('profile-image')
						if (hasFile.length > 0) {
							hasFile = hasFile[0];
							if (formRef[hasFile.key] && formRef[hasFile.key].new) {
								let file = new Parse.File(formRef[hasFile.key].file.uid, formRef[hasFile.key].file)
								file.save().then(savedFile => {
									let newFormRef = Object.assign(formRef, {
										photo: savedFile.name()
									})
									Parse.Cloud.run('updateUser', {
										formRef: JSON.stringify(newFormRef),
										module: this.props.module.form.module
									}).then(response => {
										finishEdit(response.ParseObjectSaved);
									})
								})
								return false;
							}
						}
						Parse.Cloud.run('updateUser', {
							formRef: JSON.stringify(formRef),
							module: this.props.module.form.module
						}).then(response => {
							finishEdit(response.ParseObjectSaved);
						})


					}
				},
				error: err => {
					notification.error({
						message: "Erro de edição",
						notification: "Erro ao tentar editar"
					});
				}
			});
		};

		let callbackSubmit = () => { }
		if (condForm.edit) return edit(callbackSubmit);

		create(callbackSubmit);
	}

	/**
	 * Exibe a imagem (preview no componente de imagem)
	 * @param {Object} node Field do documento de configuração.
	 * @param {File} file Arquivo.
	 * @param {FileList} fileList Lista de arquivos.
	 */
	beforeUploadImage(node, file, fileList) {
		let formRef = Object.assign({}, this.state.formRef)
		formRef[node.key] = {
			file: file,
			fileList: fileList,
			previewUrl: URL.createObjectURL(file),
			new: true,
			__type: 'Photo'
		}
		this.setState(state => {
			return {
				formRef
			}
		})

		return false
	}

	/**
	 * Exibe o documento
	 * @param {Object} node Field do documento de configuração.
	 * @param {File} file Arquivo.
	 * @param {FileList} fileList Lista de arquivos.
	 */
	beforeUploadDoc(node, file, fileList) {
		let formRef = Object.assign({}, this.state.formRef)
		formRef[node.key] = {
			file: file,
			fileList: fileList,
			new: true,
		}
		this.setState(state => {
			return {
				formRef
			}
		})

		return false
	}

	/**
	 * Navega para uma rota específica.
	 * @param {String} path caminho para rota.
	 * @param {String} $module Rota básica.
	 */
	navigateToRouterComponent(path, $module = this.props.module.form['router-base']) { // navigate to path
		this.props.module.url_props.history.push($module + path)
	}

	/**
	 * Remove uma imagem de um input do tipo imagem.
	 * @param {Object} node Field do documento de configuração.
	 * @param {Event} event Evento do input do tipo imagem.
	 */
	removeImageElementTypeImage(node, event) {
		// set value of formRef image with value null
		let formRef = Object.assign({}, this.state.formRef)
		formRef[node.key] = null
		this.setState(state => {
			return {
				formRef
			}
		})
		event.preventDefault();
		event.stopPropagation()
		return false
	}

	/**
	 * Retorna o valor de um elemento do formulário.
	 * @param {String} attr Elemento do formulário.
	 * @return Valor do elemento do formulário.
	 */
	getNodeValue(attr) {
		return this.state.formRef[attr] || null;
	}

	/**
	 * Retorna o valor de um componente number.
	 * @description Sempre use pra pegar valores do formRef, se for um number.
	 * @warning Caso tente pegar direto, o valor pode vim undefined.
	 * @param {String} attr Elemento do formulário.
	 * @returns Valor do elemento do formulário.
	 */
	getNumberValue(attr) {
		let node = this.getNodesByKey(attr);
		if (attr && node && node.type === 'money' && this.state.formRef[attr] && typeof this.state.formRef[attr] === 'string') {
			return this.parseLocaleCurrencyToFloat(this.state.formRef[attr])
		}

		return parseFloat(this.state.formRef[attr]) || 0
	}

	/**
	 * Retorna o valor de uma referência a um input.
	 * @param {String} attr Elemento do formulário.
	 * @returns Valor da referência do input.
	 */
	getRefInputValue(attr) {
		return (this.refs[attr] && this.refs[attr].input) ? this.refs[attr].input.value : 0
	}

	/**
	 * Retorna a referencia com as informações do elemento.
	 * @warning Nunca pegar valores dos elementos, sempre pegue do state.
	 * @param {attr} attr Elemento do formulário.
	 * @returns Referencia com as informações do elemento.
	 */
	getRefElement(attr) {
		return (this.refs[attr]) ? this.refs[attr] : {}
	}

	/**
	 * Retorna o valor de uma relation.
	 * @param {String} attr Elemento do formulário.
	 * @returns Objeto referente a relation.
	 */
	getRelationObjectValue(attr) { // return a parseObject with real value
		let attrId = this.state.formRef[attr];
		let node = this.getRefElement(attr)
		if (node.hasOwnProperty('props') && node.props.hasOwnProperty('node') && node.props.node.hasOwnProperty('_parseObjectOptionsList')) {
			return node.props.node['_parseObjectOptionsList'].find(v => v.id === attrId)
		}
		return null
	}

	/**
	 * Retorna as referências de um FormItem.
	 * @param {Boolean} formItem 
	 * @param {Object} object 
	 * @returns Referências de um FormItem.
	 */
	getRefWithFormItem(formItem = false, object = {}) {
		// false return input values
		Object.keys(this.refs).filter(v => v.includes("_form_item") === formItem).forEach(v => object[v] = this.refs[v])
		return object;
	}

	/**
	 * Apaga valor de um input.
	 * @param {String} key Referência ao input.
	 */
	clearValue(key) {
		this.setState(() => {
			return {
				formRef: {
					...this.state.formRef,
					[key]: undefined
				}
			}
		})
	}

	/**
	 * Verifica se um campo é obrigatório de preenchimento.
	 * @param {Object} node Elemento do formulário.
	 * @returns true ou false.
	 */
	nodeIsRequired(node) {
		if (node.hasOwnProperty('required')) return (typeof node.required === 'function') ? node.required(this) : node.required

		if (node.hasOwnProperty('element-attr') && node['element-attr'].hasOwnProperty('required')) return (typeof node['element-attr'].required === 'function') ? node['element-attr'].required(this) : node['element-attr'].required

		return true;
	}

	/**
	 * Verifica se um valor está em branco.
	 * @param {String} value Valor a ser verificado.
	 * @returns true ou false.
	 */
	nodeHasEmptyValue(value) {
		return ((!value && value !== 0) || value.length === 0) ? false : true
	}

	/**
	 * Registra pagination e navega para '/' após salvar dados.
	 * @param {null}
	 */
	afterSave() {
		let _Query = new Parse.Query(this.props.module.collection);
		_Query.count().then(totalResult => {
			localStorage.setItem(`Pagination:${this.props.module.form.module}`, Math.floor(totalResult / 16))
			notification.success({
				message: "Sucesso!",
				description: "Cadastro realizado com succeso!"
			});
			this.navigateToRouterComponent('/')
		})
	}

	/**
	 * Apaga dados do formulário e navega para '/' após a edição.
	 * @param {null}
	 */
	finishEdit() {
		message.success("Edição realizada com sucesso!");
		this.setState(state => {
			return {
				formRef: {},
				loadingDataEdit: false
			}
		})
		this.navigateToRouterComponent('/');
	}

}

/**
 * Recebe um valor money no formato 'R$ 00,00' e retorna o float do valor.
 * @param {String} currency currency Valor em formato money.
 * @returns Valor no formato float.
 */
const parseLocaleCurrencyToFloat = (currency) => {
	return parseFloat(currency.toString().replace(/(R\$)+\s/, '').replace(/\./g, '').replace(/\,/g, '.')); // eslint-disable-line
}

export {
	parseLocaleCurrencyToFloat
}

/**
 * Busca no banco de dados.
 * @param {String} value Id do ojeto no banco.
 * @param {Object} node Elemento do formulário.
 * @param {String} objectId Id do ojeto no banco.
 * @param {Function} callback Função a ser executada após a busca.
 * @returns Promise com os dados retornados.
 */
function searchInDb(value, node, objectId, callback) {
	let query = new Parse.Query(node['relation-select'].class).limit(50000)
	if (objectId) query.equalTo('objectId', value)
	else query.matches(node['relation-select'].label, new RegExp(value, 'i'))
	query.find().then(response => {
		const data = [];
		response.forEach(r => {
			data.push({
				value: r.id,
				label: r.get(node['relation-select'].label),
				obj: r
			});
		});
		callback(data);
	})
}

/**
 * Classe para renderizar um select com campos relacionados.
 */
class RelationSearchSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			dataList: [],
			value: undefined
		}
	}

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.value.length && !this.state.data.length) {
			searchInDb(nextProps.value, this.props.node, nextProps.value, data => {
				let dataList = data.map(v => v.obj)
				this.setState({ data, dataList })
			});
		}
	}

	/**
	 * Filtra options do select.
	 * @param {String} value Valor a ser filtrado.
	 */
	handleSearch = (value) => {
		if (value) {
			searchInDb(value, this.props.node, null, data => {
				let dataList = data.map(v => v.obj)
				this.setState({ data, dataList })
			});
		} else {
			this.setState({ data: [], dataList: [] })
		}
	}

	/**
	 * Registra a option selecionada no formulário.
	 * @param {String} value Valor a ser registrado no campo.
	 */
	handleChange = (value) => {
		this.setState({ value })
		this.props.setValue(value)
		this.props.setDataList(this.state.dataList)
	}

	/**
	 * Renderiza componente.
	 */
	render() {
		return (
			<Select
				showSearch
				value={this.props.value}
				placeholder={this.props.node.title}
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				onSearch={this.handleSearch}
				onChange={this.handleChange}
				notFoundContent={null}
				size="large"
			>
				{this.state.data.map((d, i) => <Select.Option key={i} value={d.value}>{d.label}</Select.Option>)}
			</Select>
		)
	}

}