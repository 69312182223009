import { Icon } from 'antd';
import React from 'react';

export default {
    'fields': [
        [
            {
                title: 'Referência (ano-mês)',
                key: 'ref',
                type: 'text',
                colSize: 8,
                'element-attr': { required: true },
            },
        ],
        [
            {
                "title": "Cesta básica (unitário)",
                "key": "basic_basket_unit",
                "type": "money",
                'colSize': 4,
                "element-attr": {
                    "required": false,
                    afterChange: (_, node, value) => {
                        const staffingArray = _.state.formRef.staffing;
                        const totalBasicBasket = getTotalBasicBasketValue(staffingArray, _.state.formRef.basic_basket_unit);
                        _.state.formRef.basic_basket_total = totalBasicBasket;
                        _.forceUpdate();
                    }
                },
            },
            {
                "title": "Cesta básica (total)",
                "key": "basic_basket_total",
                "type": "money",
                'colSize': 4,
                "element-attr": { "required": false, 'disabled': true },
            },
            {
                "title": "Ajuda de custo (unitário)",
                "key": "subsistence_allowance_unit",
                "type": "money",
                'colSize': 4,
                "element-attr": {
                    "required": false,
                    afterChange: (_, node, value) => {
                        const staffingArray = _.state.formRef.staffing;
                        const totalSubsistenceAllowance = getTotalSubsistenceAllowanceValue(staffingArray, _.state.formRef.subsistence_allowance_unit);
                        _.state.formRef.subsistence_allowance_total = totalSubsistenceAllowance;
                        _.forceUpdate();
                    }
                },
            },
            {
                "title": "Ajuda de custo (Total)",
                "key": "subsistence_allowance_total",
                "type": "money",
                'colSize': 4,
                "element-attr": { "required": false, 'disabled': true },
            },
            {
                "title": "Colaboradores",
                "key": "staffing_total",
                "type": "number",
                'colSize': 3,
                "element-attr": { "required": false, 'disabled': true, 'step': 1 },
            },
            {
                "title": "Valor total",
                "key": "total",
                "type": "money",
                'colSize': 5,
                "element-attr": { "required": false, 'disabled': true },
            },
        ],
        [
            {
                type: 'dynamic-input-list',
                key: 'staffing',
                title: 'Colaboradores',
                fields: [
                    [
                        {
                            "title": "Setor",
                            "key": "sector",
                            "type": "text",
                            'colSize': 4,
                            "element-attr": { required: true },
                        },
                        {
                            "title": "Cargo-Função",
                            "key": "role",
                            "type": "text",
                            'colSize': 6,
                            "element-attr": { required: true },
                        },
                        {
                            "title": "Salário base",
                            "key": "wage",
                            "type": "money",
                            'colSize': 4,
                            "element-attr": { required: true },
                        },
                        {
                            "title": "Insalubridade/Periculosidade",
                            "key": "dangerousness",
                            "type": "money",
                            'colSize': 4,
                            "element-attr": { "required": false },
                        },
                        {
                            "title": "Qtd",
                            "key": "qtd",
                            "type": "number",
                            'colSize': 2,
                            "element-attr": { required: true, 'step': 1 },
                        },
                        {
                            "title": "Cesta?",
                            "key": "basic_basket",
                            "type": "switch",
                            'colSize': 2,
                            "element-attr": { required: false },
                        },
                        {
                            "title": "Ajuda?",
                            "key": "subsistence_allowance",
                            "type": "switch",
                            'colSize': 2,
                            "element-attr": { required: true },
                        },
                    ]
                ],
                'element-attr': {
                    afterChange: (_, node, value) => {
                        const staffingArray = _.state.formRef.staffing;

                        const total = getTotalValue(staffingArray);
                        const totalBasicBasket = getTotalBasicBasketValue(staffingArray, _.state.formRef.basic_basket_unit);
                        const totalSubsistenceAllowance = getTotalSubsistenceAllowanceValue(staffingArray, _.state.formRef.subsistence_allowance_unit);

                        _.state.formRef.total = total;
                        _.state.formRef.basic_basket_total = totalBasicBasket;
                        _.state.formRef.subsistence_allowance_total = totalSubsistenceAllowance;
                        _.state.formRef.staffing_total = staffingArray.reduce((prev, current) => {
                            return prev + (current.qtd || 0);
                        }, 0);
                        _.forceUpdate();
                    }
                }
            }
        ]
    ],
    'submit': { 'collection': 'Staffing' },
    'schema': [
        {
            title: 'Referência',
            key: 'ref',
            dataIndex: 'ref',
            type: 'text',
            width: 200,
        },
        {
            title: 'Qtd',
            key: 'staffing_total',
            dataIndex: 'staffing_total',
            type: 'number',
            width: 100,
            align: 'center',
        },
        {
            title: 'Salários',
            key: 'staffing',
            dataIndex: 'staffing',
            align: 'center',
            width: '170px',
            render: (v) => (getTotalWage(v) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            title: 'Insalubridade/Periculosidade',
            key: 'staffing',
            dataIndex: 'staffing',
            align: 'center',
            width: '170px',
            render: (v) => (getTotalDangerousness(v) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            title: 'Total',
            key: 'total',
            dataIndex: 'total',
            align: 'center',
            render: (v) => (v || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            title: 'Cesta básica',
            key: 'basic_basket_total',
            dataIndex: 'basic_basket_total',
            align: 'center',
            render: (v) => (v || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            title: 'Ajuda de custo',
            key: 'subsistence_allowance_total',
            dataIndex: 'subsistence_allowance_total',
            align: 'center',
            render: (v) => (v || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
    ],
    'title-module': 'Quadro de pessoal',
    'title-navigation': <div><Icon type='team' /> Quadro de pessoal</div>,
    'module': 'Staffing',
    'router-base': '/panel/quadro_pessoal',
}

const getTotalValue = (array) => {
    return (array || []).reduce((previous, current) => {
        const total_unit = (
            (current.wage || 0) +
            (current.dangerousness || 0)
        ) * (current.qtd || 0);
        return previous + total_unit;
    }, 0);
}

const getTotalWage = (array) => {
    return (array || []).reduce((previous, current) => {
        const total_unit = (current.wage || 0) * (current.qtd || 0);
        return previous + total_unit;
    }, 0);
}

const getTotalDangerousness = (array) => {
    return (array || []).reduce((previous, current) => {
        const total_unit = (current.dangerousness || 0) * (current.qtd || 0);
        return previous + total_unit;
    }, 0);
}

const getTotalBasicBasketValue = (array, basicBasket) => {
    return (array || []).reduce((previous, current) => {
        const total_unit = (current.basic_basket ? (basicBasket || 0) * (current.qtd || 0) : 0);
        return previous + total_unit;
    }, 0);
}

const getTotalSubsistenceAllowanceValue = (array, subsistenceAllowance) => {
    return (array || []).reduce((previous, current) => {
        const total_unit = (current.subsistence_allowance ? (subsistenceAllowance || 0) * (current.qtd || 0) : 0);
        return previous + total_unit;
    }, 0);
}