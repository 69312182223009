import React from 'react';
import { Icon, Button } from 'antd';

import { Link } from 'react-router-dom';
import registrationDataFields from './components/registration-data-fields';
import providerContactFields from './components/provider-contact-fields';
import providerBankingFields from './components/provider-banking-fields';
import { getInputOrders, getInputsProviderGroups, getInputsToPutInOptions } from './components/input-providers-functions';
import providerInputFields from './components/provider-input-fields';
import providerDocFields from './components/provider-doc-fields';
import pqAvaliationFields from './components/pq-avaliation-fields';
import inputOrdersFields from './components/input-orders-fields';

let inputs = [];
let providerGroups = [];
let inputOrders = [];

const InputProviderComponent = {
	formTabs: [
		{
			title: 'Dados cadastrais',
			fields: registrationDataFields({ providerGroups }),
		},
		{
			title: 'Contatos',
			fields: providerContactFields(),
		},
		{
			title: 'Dados bancários',
			fields: providerBankingFields(),
		},
		{
			title: 'Relação de insumos',
			fields: providerInputFields({ inputs }),
		},
		{
			title: 'Documentos',
			fields: providerDocFields(),
		},
		{
			title: 'Avaliação PQ12',
			fields: pqAvaliationFields(),
		},
		{
			title: 'Pedidos de compra',
			fields: inputOrdersFields({ inputOrders })
		}
	],
	submit: {
		collection: "InputProvider"
	},
	ListWillMount: async (_) => {
		inputs = await getInputsToPutInOptions()
		providerGroups = await getInputsProviderGroups()
		inputOrders = await getInputOrders()
		_.forceUpdate()
	},
	FormHasMounted: (_) => {

		setTimeout(async () => {

			if (!(inputs && inputs.length)) inputs = await getInputsToPutInOptions()
			if (!(providerGroups && providerGroups.length)) providerGroups = await getInputsProviderGroups()
			if (!(inputOrders && inputOrders.length)) inputOrders = await getInputOrders()

			_.module.form.formTabs = [
				{
					title: 'Dados cadastrais',
					fields: registrationDataFields({ providerGroups }),
				},
				{
					title: 'Contatos',
					fields: providerContactFields(),
				},
				{
					title: 'Dados bancários',
					fields: providerBankingFields(),
				},
				{
					title: 'Relação de insumos',
					fields: providerInputFields({ inputs }),
				},
				{
					title: 'Documentos',
					fields: providerDocFields(),
				},
				{
					title: 'Avaliação PQ12',
					fields: pqAvaliationFields(),
				},
				{
					title: 'Pedidos de compra',
					fields: inputOrdersFields({ inputOrders })
				}
			]
			_.forceUpdate()

		}, 500)
	},
	schema: [
		{
			title: "Grupo",
			key: "provider_group",
			dataIndex: "provider_group",
			width: '150px',
			type: "render",
			isObjectIdString: true,
			fieldToSearch: 'products',
			relationClass: 'InputProviderGroup',
			render: (v) => {
				const group = providerGroups.find(el => el.value === v)
				return group && group.label
			}
		},
		{
			title: "Número",
			key: "code",
			dataIndex: "code",
			width: '70px',
			type: "text",
		},
		{
			title: "Nome",
			key: "name",
			dataIndex: "name",
			type: "text",
		},
		{
			title: "",
			type: "render",
			align: 'center',
			render: (_, row) => {
				// Get all inputs to check the ones is price base
				let inputsInThisProvider = (row.inputs || []).map(inptObjectId => {
					return inputs.find(inpt => inpt.id === inptObjectId.input);
				});

				// Check if provider has inputs that is price base
				let hasPriceBaseInputs = false;
				inputsInThisProvider.forEach(inpt => {
					if (inpt && inpt.get('is_price_base'))
						hasPriceBaseInputs = true
				});

				return (
					<div
						style={{
							backgroundColor: hasPriceBaseInputs ? 'green' : 'red',
							width: '25px', height: '25px',
							marginBottom: '5px',
							borderRadius: '50%',
						}}
					/>
				)
			}
		},
		{
			title: "Cidade",
			key: "city",
			dataIndex: "city",
			type: "text",
		},
		{
			title: "UF",
			key: "state",
			width: '50px',
			dataIndex: "state",
			type: "text",
		},
		{
			title: "CNPJ",
			key: "cnpj",
			dataIndex: "cnpj",
			type: "text",
		},
		{
			title: 'Home Page',
			key: 'home_page',
			dataIndex: 'home_page',
			render: link => {
				if ((link || '').includes('http')) return <Link to={link} target='blank'>{link}</Link>
				return <Link to={`http://${link}`} target='blank'>{link}</Link>
			}
		},
		{
			title: "CNPJ Receita",
			key: "cnpj_receita",
			dataIndex: 'cnpj_receita',
			align: 'center',
			render: (file) => {
				const pdfName = `${file && file.url}`

				return (
					<Button title="Visualizar CNPJ" type="default" disabled={!file} shape="circle" icon="file-pdf"
						onClick={() => {
							window.open(pdfName, '_blink')
						}}
					/>
				)
			}
		},
	],
	"title-navigation": <div><Icon type="idcard" /> Provedores</div>,
	"title-module": "Provedores",
	"module": "InputProvider",
	"router-base": "/panel/provedores"
}

export default InputProviderComponent