import React from 'react'
import { Icon, Button, notification, Menu, Dropdown } from 'antd'
import {
    filterSalesChannelList,
    generateInputOrderPriceQuote,
    getInputsFromProvider,
    transformDataToCreateInputOrder
} from './components/input-order-price-quote-functions'
import generalInfoFields from './components/general-info-fields'
import inputsRelationFields from './components/inputs-relation-fiels'
import totalsFields from './components/totals-fields'
import { findAll } from '../../../utils/db_functions'
import { getDeliveries } from '../input-order/components/input-order-functions'

let inputs = []
let salesChannels = []
let providers = []
let clients = []
let deliveries = []

const generateFields = () => {
    return [
        {
            title: 'Informações gerais',
            fields: generalInfoFields({
                providers,
                clients,
                salesChannels,
                deliveries,
                getInputsFromProvider: async (providerId) => {
                    inputs = await getInputsFromProvider(providerId)
                    return inputs
                }
            })
        },
        {
            title: 'Relação de insumos',
            fields: inputsRelationFields({
                inputs: inputs
            })
        },
        {
            title: 'Totais',
            fields: totalsFields(),
        },
    ]
}

export default {
    formTabs: generateFields(),
    FormHasMounted: async (_) => {
        if (salesChannels.length === 0) salesChannels = await filterSalesChannelList()
        if (providers.length === 0) providers = await findAll('InputProvider', 1000)
        if (clients.length === 0) clients = await findAll('Client', 2000)
        if (deliveries.length === 0) deliveries = await getDeliveries()

        _.module.form.formTabs = generateFields()

        const providerId = _.state.formRef && _.state.formRef.provider
        if (providerId) {
            inputs = await getInputsFromProvider(_.state.formRef && _.state.formRef.provider)
            _.props.module.form.formTabs[1].fields[0][0].fields[0][0].options = inputs
        }

        _.forceUpdate();
    },
    submit: { collection: 'InputOrderPriceQuote' },
    ListWillMount: async (_) => {
        salesChannels = await filterSalesChannelList()
        providers = await findAll('InputProvider', 1000)
        clients = await findAll('Client', 2000)
        deliveries = await getDeliveries()

        _.forceUpdate()
    },
    schema: [
        {
            title: "Cotação",
            key: "cotation_code",
            dataIndex: "cotation_code",
            type: "text",
            width: '130px',
        },
        {
            title: "Fornecedor",
            key: "provider",
            dataIndex: "provider",
            type: "render",
            render: (v) => {
                const provider = providers.find(el => el.id === v.objectId)
                return provider && provider.get('name')
            },
        },
        {
            title: 'Valor R$',
            key: 'total_value_with_shipment',
            dataIndex: 'inputs',
            width: '150px',
            type: 'render',
            render: (v, form) => {
                const total = (v || []).reduce((prev, current) => prev + ((current.total || 0) + (((current.ipi || 0) / 100) * (current.total || 0))), 0);
                return ((total + (form.shipment || 0)) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        },
        {
            title: 'Emissão',
            key: 'emission',
            dataIndex: 'emission',
            align: 'center',
            width: '75px',
            render: (_, cotation) => {
                const dropdownOrderMenu = (
                    <Menu>
                        <Menu.Item
                            onClick={async () => await generateInputOrderPriceQuote(cotation.objectId, true)}
                        >
                            Cotação com preços
                        </Menu.Item>

                        <Menu.Item
                            onClick={async () => await generateInputOrderPriceQuote(cotation.objectId, false)}
                        >
                            Cotação sem preços
                        </Menu.Item>
                    </Menu>
                )

                return (
                    <Dropdown overlay={dropdownOrderMenu} placement='bottomCenter' trigger={['click']}>
                        <Button title="Emitir cotação" type="primary" icon="menu" />
                    </Dropdown>
                )
            }
        },
        {
            title: "PDF",
            key: "pdf",
            dataIndex: "pdf",
            align: 'center',
            width: '75px',
            render: (_, cotation) => {
                const pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_input_order_price_quote/cotacao-precos-insumos-${cotation.cotation_code}.pdf`

                return (
                    <Button title="Visualizar cotação em PDF" type="default" shape="circle" icon="file-pdf"
                        onClick={() => {
                            window.open(pdfName, '_blink')
                        }}
                    />
                )
            }
        },
        {
            title: "Gerar pedido",
            key: "order_generator",
            dataIndex: "order_generator",
            align: 'center',
            width: '75px',
            render: (_, row, index) => {
                return (
                    <Button title="Gerar pedido de compra" type="default" shape="circle" icon="file-protect"
                        onClick={async () => {
                            notification.open({
                                message: 'Gerando o pedido',
                                description: 'O pedido está sendo criado, aguarde...',
                                key: 'generatingOrder',
                                duration: 0,
                                placement: 'topLeft',
                                icon: <Icon type="loading" />
                            });

                            let cotationToSave = await transformDataToCreateInputOrder(row);
                            cotationToSave.save()
                                .then(() => {
                                    notification.close('generatingOrder');
                                    notification.success({
                                        message: `Pedido ${cotationToSave.get('order_code')} gerado com sucesso.`,
                                        description: 'Acesse o módulo de pedido de compra, revise o pedido e gere o pdf.',
                                        placement: 'topLeft',
                                    });
                                })
                                .catch((err) => {
                                    console.log(err);
                                    notification.close('generatingOrder');
                                    notification.error({
                                        message: 'Erro ao gerar o pedido de compra.',
                                        description: 'Alguma coisa deu errado, tente novamente.',
                                        duration: 5,
                                    });
                                });
                        }}
                    />
                )
            }
        },
    ],
    'title-module': 'Cotação de Preços',
    'title-navigation': <div><Icon type='calculator' /> Cotação de Preços</div>,
    module: 'InputOrderPriceQuote',
    'router-base': '/panel/cotacao-precos',
}