import { Icon, Modal, Button, Popover } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React from 'react'
import QualificationSurveyReport from './entities/qualification-survey-report'
import SendQualificationSurvey from './entities/send-qualification-survey'
import { surveyQuestionsAndAnswers } from './suport-files/survey-questions'

export default {
    fields: [
        [],
    ],
    submit: { collection: 'QualificationSurvey' },
    schema: [
        {
            title: 'E-mail',
            key: 'email',
            dataIndex: 'email',
            type: 'text',
        },
        {
            title: 'Obra',
            key: 'work',
            dataIndex: 'work',
            type: 'text',
        },
        {
            title: "",
            key: "form",
            dataIndex: 'form',
            align: 'center',
            render: (form, survey) => {
                return (
                    <ButtonGroup>
                        <Popover content='Pesquisa de qualificação'>
                            <Button
                                icon="unordered-list"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Obra: ${survey.work}`,
                                        width: 600,
                                        iconType: 'message',
                                        cancelText: 'Fechar',
                                        content:
                                            <div>
                                                <br />

                                                {
                                                    surveyQuestionsAndAnswers.map(surveyQuestion => {
                                                        return (
                                                            <div>
                                                                <div><strong>{surveyQuestion.question.label}</strong></div>
                                                                {form[surveyQuestion.question.key]}<br /><br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>,
                                    })
                                }}
                            />
                        </Popover>

                        <Popover content='Solicitação de proposta'>
                            <Button
                                icon='book'
                                onClick={() => {
                                    window.open(`http://minhamultidoor.com.br/solicitacao-proposta-cliente?surveyId=${survey.objectId}`, '_blank')
                                }}
                            />
                        </Popover>
                    </ButtonGroup>
                )
            }
        }
    ],
    'title-navigation': <div><Icon type="message" /> Pesquisa de qualificação</div>,
    'title-module': 'Pesquisa de qualificação',
    'module': 'QualificationSurvey',
    'router-base': '/panel/qualification-survey',
    'topBarAction': () => <div><SendQualificationSurvey /> <QualificationSurveyReport /></div>,
}