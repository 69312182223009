import { Button, Form, Input, InputNumber, Modal, Popover, Table } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import React, { Component } from "react"

export default class PqAvaliationTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            nfs: this.props.nfs,

            avaliationModal: false,
            avaliation: {},

            avaliationFormModal: false,
            avaliationForm: {},
            avaliationIndex: null,
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.nfs !== this.state.nfs)
            this.setState({ nfs: nextProps.nfs })
    }

    calculateAvaliation = () => {
        let arrayOfAvaliationFields = ['deadline', 'attendance', 'quality', 'adm']

        let grade = 0
        arrayOfAvaliationFields.forEach(avaliationField => {
            if (this.state.avaliationForm[avaliationField]) grade += this.state.avaliationForm[avaliationField]
            else grade = 0
        })

        if (grade) {
            let avaliation = { performace: 'Ruim', status: 'NQ' }
            if (grade / 4 >= 9) avaliation = { performace: 'Ótimo', status: 'QP' }
            else if (grade / 4 >= 7) avaliation = { performace: 'Bom', status: 'Q' }
            else if (grade / 4 >= 5) avaliation = { performace: 'Regular', status: 'QR' }

            this.setState({
                avaliationForm: {
                    ...this.state.avaliationForm,
                    performace: avaliation['performace'],
                    status: avaliation['status'],
                    grade: (grade / 4).toFixed(2)
                }
            })
        }
    }

    addUpdateAvaliation = async () => {
        await this.calculateAvaliation()

        let avaliation = this.state.nfs || []

        if (this.state.avaliationIndex || this.state.avaliationIndex === 0) {
            avaliation[this.state.avaliationIndex] = this.state.avaliationForm
        } else {
            avaliation.push(this.state.avaliationForm)
        }

        this.props.updateAvaliation(avaliation)

        this.setState({ avaliationForm: {}, avaliationFormModal: false, avaliationIndex: null })
    }

    removeAvaliation = async (index) => {

        let avaliation = this.state.nfs

        avaliation.splice(index, 1)

        this.props.updateAvaliation(avaliation)
    }

    render() {

        const columns = [
            {
                title: 'NF',
                dataIndex: 'nf',
                key: 'nf',
                fixed: 'left',
            },
            {
                title: 'Nota Final',
                dataIndex: 'grade',
                key: 'grade',
                align: 'center',
            },
            {
                title: 'Performance',
                dataIndex: 'performace',
                key: 'performace',
                align: 'center',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Ações',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                render: (text, record, index) => {
                    return (
                        <ButtonGroup>
                            <Popover content='Avaliação'>
                                <Button
                                    icon='unordered-list'
                                    onClick={() => {
                                        this.setState({
                                            avaliationModal: true,
                                            avaliation: record,
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Editar'>
                                <Button
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({
                                            avaliationFormModal: true,
                                            avaliationForm: record,
                                            avaliationIndex: index,
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Deletar'>
                                <Button
                                    icon='delete'
                                    onClick={() => {
                                        let _this = this
                                        Modal.confirm({
                                            iconType: 'exclamation-circle',
                                            title: 'Tem certeza que deseja exlcuir essa nota?',
                                            content: 'Essa ação não poderá ser desfeita',
                                            onOk() {
                                                _this.removeAvaliation(index)
                                            },
                                            okText: 'Sim',
                                            cancelText: 'Não, deixa pra lá'
                                        })
                                    }}
                                />
                            </Popover>
                        </ButtonGroup>
                    )
                }
            },
        ]

        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={this.state.nfs}
                    locale={{ emptyText: 'Sem notas' }}
                    pagination={false}
                />

                <Button
                    type="primary"
                    onClick={() => this.setState({ avaliationFormModal: true })}
                    style={{ marginTop: "20px" }}
                >Adicionar nota</Button>

                <Modal
                    visible={this.state.avaliationModal}
                    title={`Avaliação PQ12`}
                    onCancel={() => this.setState({ avaliation: {}, avaliationModal: false })}
                    footer={[]}
                >
                    <div className="label">Nota Fiscal</div>
                    <div>{this.state.avaliation.nf}</div>

                    <div className="label">Cumprimento do prazo</div>
                    <div>{(this.state.avaliation.deadline || 0).toFixed(2)}</div>

                    <div className="label">Atendimento ao pedido</div>
                    <div>{(this.state.avaliation.attendance || 0).toFixed(2)}</div>

                    <div className="label">Qualidade</div>
                    <div>{(this.state.avaliation.quality || 0).toFixed(2)}</div>

                    <div className="label">Procedimento adm/financeiro</div>
                    <div>{(this.state.avaliation.adm || 0).toFixed(2)}</div>
                </Modal>

                <Modal
                    visible={this.state.avaliationFormModal}
                    title="Avaliação PQ12"
                    onCancel={() => this.setState({ avaliationForm: {}, avaliationFormModal: false })}
                    cancelText="Cancelar"
                    okText="Salvar"
                    onOk={() => this.addUpdateAvaliation()}
                >
                    <Form.Item label="Nota fiscal">
                        <Input
                            placeholder="Nota fiscal"
                            value={this.state.avaliationForm.nf}
                            onChange={e => this.setState({ avaliationForm: { ...this.state.avaliationForm, nf: e.target.value } })}
                            style={{ width: '100%' }}
                            step={0.01}
                        />
                    </Form.Item>

                    <Form.Item label="Cumprimento do prazo">
                        <InputNumber
                            placeholder="Nota para cumprimento do prazo"
                            value={this.state.avaliationForm.deadline}
                            onChange={e => this.setState({ avaliationForm: { ...this.state.avaliationForm, deadline: e } })}
                            style={{ width: '100%' }}
                            step={0.01}
                        />
                    </Form.Item>

                    <Form.Item label="Atendimento ao pedido">
                        <InputNumber
                            placeholder="Nota para atendimento ao pedido"
                            value={this.state.avaliationForm.attendance}
                            onChange={e => this.setState({ avaliationForm: { ...this.state.avaliationForm, attendance: e } })}
                            style={{ width: '100%' }}
                            step={0.01}
                        />
                    </Form.Item>

                    <Form.Item label="Qualidade">
                        <InputNumber
                            placeholder="Nota para Qualidade"
                            value={this.state.avaliationForm.quality}
                            onChange={e => this.setState({ avaliationForm: { ...this.state.avaliationForm, quality: e } })}
                            style={{ width: '100%' }}
                            step={0.01}
                        />
                    </Form.Item>

                    <Form.Item label="Procedimento adm/financeiro">
                        <InputNumber
                            placeholder="Nota para Procedimento adm/financeiro"
                            value={this.state.avaliationForm.adm}
                            onChange={e => this.setState({ avaliationForm: { ...this.state.avaliationForm, adm: e } })}
                            style={{ width: '100%' }}
                            step={0.01}
                        />
                    </Form.Item>
                </Modal>
            </div>
        )

    }

}