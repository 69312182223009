import React from 'react'
import Parse from 'parse'
import Auth from '../../../../auth/Auth'
import { Button, Modal, Col, Form, Icon, Row, message, Select, notification, Input } from 'antd'
import { findById, getCurrentUser } from '../../../../utils/db_functions'
import { getProposalDates, getSalesChannelPaymentRule } from '../suport-files/proposal-functions'
import { defaultProposalViewOptions } from '../../../../utils/defaultDataSource'


export default class ProposalModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentUserRelatedGroups: [],

            visible: false,
            channel: "",
            salesChannelRuleIndex: null,
            visualizationType: '',
            generatedPDF: false,
            viewProposal: [],
            salesChannel: [],

            checklistItens: [],
            checklistItensChoosed: [],

            paymentConditionsArray: [],
        }
        this.handleClickButton = this.handleClickButton.bind(this)
    }

    /**
     * Altera a visibilidade da modal
     */
    toggleVisible = (callback = null) => {

        if (this.auth['_permission']) {
            this.setState({
                visible: !this.state.visible,
            }, () => callback && callback());
        }
    }

    /**
     * Altera as variáveis m30, m60 e m90 do state
     * @param {*} value 
     * @param {*} type m30, m60 ou m90
     */
    handleChange(value, type) {
        this.setState({
            [type]: value
        })
    }

    /** 🐼
     * Salva a proposta com os valores do frete
     */
    saveProposal = () => {
        if (this.auth['_permission']) {
            let query = new Parse.Query('Proposal');

            let PointerObject = new Parse.Object('SalesChannel');
            PointerObject.id = this.state.channel;

            query.get(this.props.objectId).then(async (proposal) => {

                let emailWasSend = false;
                if (proposal.get('historic') && proposal.get('historic').length) {
                    proposal.get('historic').forEach(comment => {
                        if (
                            comment.comment.includes(`${proposal.get('proposal_code')} enviada para o e-mail`) ||
                            comment.comment.includes(`${proposal.get('proposal_code')} enviada para o cliente via whatsapp`)
                        ) emailWasSend = true;
                    });
                };

                // Get sales channel info
                let paymentData;
                const salesChannel = await findById('SalesChannel', this.state.channel)
                if (salesChannel) {
                    const rules = salesChannel.get('automatic_choose_rules')
                    if (rules && rules.length) {
                        const salesChannelRuleDescription = rules[this.state.salesChannelRuleIndex] && rules[this.state.salesChannelRuleIndex].description
                        paymentData = getSalesChannelPaymentRule([...salesChannel.get('automatic_choose_rules')], salesChannelRuleDescription, this.state.payment_conditions)
                    }
                }

                proposal.set('generalInfo', {
                    ...(proposal.get('generalInfo') || {}),
                    deleteDobradica: this.state.deleteDobradica,
                    paymentData,
                    pdfDates: await getProposalDates(proposal),
                });

                if (!emailWasSend) {
                    proposal.set('sales_channel', PointerObject)
                    proposal.set('salesChannelRuleIndex', this.state.salesChannelRuleIndex)
                    proposal.set('baseFinalPriceAndAmount', this.calculateUnitValuesWithSalesChannel(proposal))
                    proposal.set('visualization_type', this.state.visualizationType)
                    proposal.set('checklist_itens', this.state.checklistItensChoosed)
                    proposal.set('pdf', true)
                    proposal.set('payment_conditions', this.state.payment_conditions)
                    proposal.save().then(async r => {
                        notification.open({
                            message: 'Gerando a proposta',
                            description: 'O pdf da proposta e do relatório analítico estão sendo criados, aguarde...',
                            key: 'generatingProposal',
                            duration: 0,
                            placement: 'topLeft',
                            icon: <Icon type="loading" />
                        });
                        this.toggleVisible()

                        await this.generatePDF(proposal.id)

                        // window.location.reload()

                    }).catch(err => {
                        message.error(`Erro ao gerar a proposta ${err}`, 5);
                    })
                } else {
                    message.error(`Essa proposta já foi enviada para o cliente e não pode ser gerada novamente.`, 5);
                }
            }).catch((err) => {
                message.error(`Erro ao gerar a proposta ${err}`, 5);
            });
        }
    }

    generatePDF = async (id) => {
        await Parse.Cloud.run('generateProposal', { id: id }).then((response) => {
            notification.close('generatingProposal');
            notification.success({
                message: 'Proposta gerada com sucesso',
                description: 'Click no botão de PDF para visualizar a proposta.'
            })
            window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')
        }).catch(() => {
            notification.error({
                message: 'Alguma coisa deu errado',
                description: 'Verifique se as propostas selecionadas foram preenchidas corretamente. (PDF de proposta)',
                duration: 5
            })
            notification.close('generatingProposal');
        })

        await Parse.Cloud.run('generateAnaliticProposal', { id: id }).then((response) => {
            window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')
            notification.close('generatingProposal');
        }).catch(() => {
            notification.error({
                message: 'Alguma coisa deu errado',
                description: 'Verifique se as propostas selecionadas foram preenchidas corretamente. (PDF do relatório analítico)',
                duration: 5
            })
            notification.close('generatingProposal');
        })

        await Parse.Cloud.run('generateCrm', { id: id }).then((response) => {
            // window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')
        }).catch(() => {
            notification.error({
                message: 'Alguma coisa deu errado',
                description: 'Verifique se as propostas selecionadas foram preenchidas corretamente. (PDF do CRM)',
                duration: 5
            })
        })

        await Parse.Cloud.run('generateInputReport', { id: id, orderCode: null }).then(response => {
            // window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')
        }).catch(() => {
            notification.error({
                message: 'Alguma coisa deu errado',
                description: 'Verifique se as propostas selecionadas foram preenchidas corretamente. (PDF de insumo)',
                duration: 5
            })
        })

        this.toggleVisible()
    }

    async handleClickButton() {
        const currentUser = await getCurrentUser()
        const currentUserRelatedGroupQuery = currentUser.relation('group').query()
        const currentUserRelatedGroups = await currentUserRelatedGroupQuery.find()

        this.auth = new Auth(Parse.User.current())
        this.auth['_permission'] = false;
        this.auth['_salesChanelPermission'] = false;
        this.auth['_removeDobradicaPermission'] = false;
        this.auth.init(() => {
            this.auth['_permission'] = this.auth.hasAction(['*', 'GenerateProposal'])
            this.auth['_salesChanelPermission'] = this.auth.hasAction(['*', 'ProposalSalesChannel']);
            this.auth['_removeDobradicaPermission'] = this.auth.hasAction(['*', 'ProposalRemoveDobradica']);

            if (this.auth.hasAction(['*', 'GenerateProposal'])) {
                let query = new Parse.Query('Proposal');
                query.get(this.props.objectId).then(proposal => {

                    let sales = new Parse.Query('SalesChannel');
                    sales.find().then((sCh) => {
                        let config = new Parse.Query('Config');
                        config.equalTo('name', 'view_proposal')
                        config.first().then(config => {
                            if (config) {

                                this.setItensForChecklist(proposal)

                                let emailWasSend = false;
                                if (proposal.get('historic') && proposal.get('historic').length) {
                                    proposal.get('historic').forEach(comment => {
                                        if (
                                            comment.comment.includes(`${proposal.get('proposal_code')} enviada para o e-mail`) ||
                                            comment.comment.includes(`${proposal.get('proposal_code')} enviada para o cliente via whatsapp`)
                                        ) emailWasSend = true;
                                    });
                                };

                                const costumerType = !proposal.get('is_company') ? 'pf' : 'pj'
                                const salesChannelFiltered = sCh.filter(el => {
                                    if (!el.get('customer_type')) return true;
                                    return el.get('customer_type') === costumerType;
                                });

                                const channelParseObject = proposal.get('sales_channel') || this.getSalesChannelAutomaticaly(proposal, [...sCh])
                                let channel = channelParseObject && channelParseObject.id
                                const salesChannelRuleIndex = proposal.get('sales_channel') ? proposal.get('salesChannelRuleIndex') : this.state.salesChannelRuleIndex

                                const paymentConditionsArray = channel && (salesChannelRuleIndex || salesChannelRuleIndex === 0) ?
                                    (
                                        (channelParseObject &&
                                            channelParseObject.get('automatic_choose_rules')[salesChannelRuleIndex]
                                            && channelParseObject.get('automatic_choose_rules')[salesChannelRuleIndex].paymentRules) || []
                                    ) :
                                    this.state.paymentConditionsArray
                                let payment_conditions = null
                                if (proposal.get('payment_conditions'))
                                    payment_conditions = (paymentConditionsArray || []).find(el => el.rule === proposal.get('payment_conditions')) ? proposal.get('payment_conditions') : null;
                                else if (paymentConditionsArray && paymentConditionsArray.length === 1)
                                    payment_conditions = paymentConditionsArray[0].rule

                                if (!channel && ((salesChannelFiltered || []).filter(el => !el.get('disabled'))).length === 1)
                                    channel = salesChannelFiltered.filter(el => !el.get('disabled'))[0].id;

                                this.setState({
                                    currentUserRelatedGroups,
                                    viewProposal: config.get("value"),
                                    visible: true,
                                    salesChannel: [...salesChannelFiltered],
                                    generatedPDF: emailWasSend,
                                    channel,
                                    salesChannelRuleIndex,
                                    paymentConditionsArray,
                                    payment_conditions,
                                    visualizationType: proposal.get('visualization_type'),
                                    deleteDobradica: (proposal.get('generalInfo') && proposal.get('generalInfo')['deleteDobradica']) || false,
                                })
                            }
                        }).catch((err) => {
                            message.error(`Erro ao buscar os tipos de visualização da proposta ${err}`, 5);
                        })
                    }).catch((err) => {
                        message.error(`Erro ao buscar os canais de venda ${err}`, 5);
                    });

                })

            } else message.error('Você não tem permissão para gerar proposta')

        })


    }

    /**
     * Atualiza os valores dos fretes que estão no banco.
     */
    componentWillReceiveProps = () => {
        this.setState(this.props.configs)
    }

    // Checar o valor base final e a quantidade total para determinar automaticamente o canal de vendas
    getSalesChannelAutomaticaly = (proposal, sales_channel) => {
        sales_channel = sales_channel.filter(el => !el.get('disabled'));

        const costumerType = !proposal.get('is_company') ? 'pf' : 'pj'
        sales_channel = sales_channel.filter(el => {
            if (!el.get('customer_type')) return true;
            return el.get('customer_type') === costumerType;
        });

        if (!proposal.get('sales_channel') && proposal.get('baseFinalPriceAndAmount')) {
            let finalPrice = proposal.get('baseFinalPriceAndAmount')['finalPrice'];
            let finalAmount = proposal.get('baseFinalPriceAndAmount')['finalAmount'];

            let sale_found = null;
            for (const saleChannel of sales_channel) {

                const rulesChecked =
                    saleChannel.get('automatic_choose_rules') ?
                        this.checkAutomaticSalesChannelRules(saleChannel.get('automatic_choose_rules'), finalAmount, finalPrice) :
                        this.checkSalesChannelRule(saleChannel.get('rules'), finalAmount, finalPrice)

                if (rulesChecked) {
                    sale_found = saleChannel;
                    break;
                }
            }
            return sale_found;
        } else return null
    }

    /**
     * Cria lista com os Kits para o select do checklist 
     * @param proposal Proposta.
     */
    setItensForChecklist = (proposal) => {
        let products = proposal.get('product_blocks').map(bloco => {
            return bloco.products
        })

        let productsOrder = products.flat().sort((a, b) => (a.amount < b.amount) ? 1 : ((b.amount < a.amount) ? -1 : 0))

        this.setState({ checklistItens: products.flat(), checklistItensChoosed: proposal.get('checklist_itens') })

        if (!this.state.checklistItensChoosed || (this.state.checklistItensChoosed && !this.state.checklistItensChoosed.length))
            this.handleChange([productsOrder[0]['project_code']], 'checklistItensChoosed')
    }

    /**
     * Retorna a lista de preços unitários com o percentual de canal de vendas aplicado.
     * @param proposal Proposta.
     * @returns Lista dos preços unitários
     */
    calculateUnitValuesWithSalesChannel = (proposal) => {
        let localBaseFinalPriceAndAmount = proposal.get('baseFinalPriceAndAmount')

        let salesChannel = this.state.salesChannel.find(el => el.id === this.state.channel).toJSON()
        let percent = salesChannel.value

        localBaseFinalPriceAndAmount['unitValues'] = localBaseFinalPriceAndAmount['unitValues'].map(el => {
            return el.map(child_el => {
                return { ...child_el, value: (child_el.value + (child_el.value / 100 * percent)) }
            })
        })
        return localBaseFinalPriceAndAmount
    }

    /**
     * Check if salesChannel rules
     * @param {Array} rules 
     * @param {Number} qtd 
     * @param {Number} value 
     */
    checkSalesChannelRule = (rules, qtd, value) => {

        if (!rules || !rules.length) return false;

        let check = true;

        for (const rule of rules) {
            if (rule.param === 'qtd' && !rules.find(el => el.param === 'value')) {
                switch (rule.rule) {
                    case '<':
                        if (qtd >= rule.value) check = false;
                        break;
                    case '<=':
                        if (qtd > rule.value) check = false;
                        break;
                    case '=':
                        if (qtd !== rule.value) check = false;
                        break;
                    case '>':
                        if (qtd <= rule.value) check = false;
                        break;
                    case '>=':
                        if (qtd < rule.value) check = false;
                        break;
                    default:
                        break;
                }
            } else if (rule.param === 'value') {
                switch (rule.rule) {
                    case '<':
                        if (value >= rule.value) check = false;
                        break;
                    case '<=':
                        if (value > rule.value) check = false;
                        break;
                    case '=':
                        if (value !== rule.value) check = false;
                        break;
                    case '>':
                        if (value <= rule.value) check = false;
                        break;
                    case '>=':
                        if (value < rule.value) check = false;
                        break;
                    default:
                        break;
                }

            }
        }

        return check;
    }

    checkAutomaticSalesChannelRules = (rules, qtd, value) => {

        for (let ruleIndex = 0; ruleIndex < rules.length; ruleIndex++) {
            const element = rules[ruleIndex];

            if (this.checkSalesChannelRule(element['rules'], qtd, value)) {
                this.setState({ salesChannelRuleIndex: ruleIndex })
                return true
            }
        }

        return false
    }

    // Return proposal view options of choosed sales channel
    getSalesChannelProposalViewOptions = () => {
        const salesChannelChoosed = this.state.salesChannel.find(el => el.id === this.state.channel)

        const proposalViewOptions = defaultProposalViewOptions

        if(
            salesChannelChoosed && 
            salesChannelChoosed.get('proposal_view_default') &&
            !this.state.visualizationType
        ) this.setState({ visualizationType: salesChannelChoosed.get('proposal_view_default') })
        
        if(!salesChannelChoosed || !salesChannelChoosed.get('proposal_view_options')) return proposalViewOptions;
        return proposalViewOptions.filter(el => (salesChannelChoosed.get('proposal_view_options') || []).includes(el.value)) 
    }

    render() {
        return (
            <div>
                <Button title="Gerar pdf de proposta" type="primary" disabled={this.props.status === 'Incompleta'} shape="circle" icon="file-protect" onClick={this.handleClickButton} />
                <Form>
                    <Modal
                        title="Geração de Propostas"
                        visible={this.state.visible && this.auth['_permission']}
                        onCancel={() => this.toggleVisible()}
                        width={800}
                        footer={[
                            <Button onClick={() => this.toggleVisible()}>Cancelar</Button>,
                            <Button
                                type='primary'
                                onClick={this.saveProposal}
                                disabled={
                                    !this.state.channel ||
                                    !(this.state.salesChannelRuleIndex || this.state.salesChannelRuleIndex === 0) ||
                                    !this.state.visualizationType ||
                                    !this.state.payment_conditions
                                }
                            >Gerar proposta</Button>
                        ]}
                    >
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label={'Canal de Vendas'} required>
                                    <Select
                                        disabled={this.state.generatedPDF || !(this.auth && this.auth['_salesChanelPermission'])}
                                        value={this.state.channel}
                                        onChange={(value) => {
                                            this.handleChange(value, 'channel');
                                            this.handleChange(null, 'salesChannelRuleIndex');
                                            this.handleChange(null, 'payment_conditions');
                                        }}
                                        placeholder="Selecione..." size="large" style={{ width: "100%" }}
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {
                                            this.state.salesChannel
                                                .filter(el => !el.get('disabled'))
                                                .map((option) => {
                                                    return <Select.Option key={option.id} value={option.id}>{option.get('name')}</Select.Option>
                                                })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={'Regra'}>
                                    <Select
                                        disabled={this.state.generatedPDF || !(this.auth && this.auth['_salesChanelPermission'])}
                                        value={this.state.salesChannelRuleIndex}
                                        onChange={async (value) => {
                                            if (!value && value !== 0) {
                                                this.setState({
                                                    salesChannelRuleIndex: null,
                                                    payment_conditions: null,
                                                })
                                            } else {
                                                const salesChannelChoosed = this.state.salesChannel.find(saleChannel => saleChannel.id === this.state.channel)
                                                const authorizedGroups = salesChannelChoosed.get('automatic_choose_rules')[value].authorizedGroups
                                                let paymentConditionsArray = []

                                                if (authorizedGroups) {
                                                    if (this.state.currentUserRelatedGroups.find(group => authorizedGroups.includes(group.id))) {
                                                        this.handleChange(value, 'salesChannelRuleIndex')
                                                        paymentConditionsArray = salesChannelChoosed.get('automatic_choose_rules')[value].paymentRules || []
                                                        this.setState({ paymentConditionsArray })
                                                    } else message.error("Usuário sem autorização para esta ação!")
                                                } else {
                                                    this.handleChange(value, 'salesChannelRuleIndex')
                                                    paymentConditionsArray = salesChannelChoosed.get('automatic_choose_rules')[value].paymentRules || []
                                                    this.setState({ paymentConditionsArray })
                                                }

                                                let payment_conditions = null
                                                if (paymentConditionsArray && paymentConditionsArray.length === 1) payment_conditions = paymentConditionsArray[0].rule
                                                this.setState({ payment_conditions })
                                            }

                                        }}
                                        placeholder="Selecione..." size="large"
                                        style={{ width: "100%" }}
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {(
                                            (
                                                this.state.salesChannel &&
                                                this.state.salesChannel.find(saleChannel => saleChannel.id === this.state.channel) &&
                                                this.state.salesChannel.find(saleChannel => saleChannel.id === this.state.channel).get('automatic_choose_rules')
                                            ) || []
                                        ).map((option, i) => {
                                            return <Select.Option key={option.description} value={i}>{option.description} ({option.value}%)</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={'Condição de pgto'} required>
                                    <Select disabled={this.state.generatedPDF || !(this.auth && this.auth['_salesChanelPermission'])} value={this.state.payment_conditions} onChange={(value) => { this.handleChange(value, 'payment_conditions') }} placeholder="Selecione..." size="large" style={{ width: "100%" }} >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {
                                            this.state.paymentConditionsArray
                                                .map((option, index) => <Select.Option key={`payment_condition_${index}`} value={option.rule}>{option.description}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label={'Visualização da Proposta'} required>
                                    <Select disabled={this.state.generatedPDF} value={this.state.visualizationType} onChange={(value) => { this.handleChange(value, 'visualizationType') }} placeholder="Selecione..." size="large" style={{ width: "100%" }} >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {
                                            this.getSalesChannelProposalViewOptions().map(visualizationTypeValue => {
                                                return <Select.Option value={visualizationTypeValue.value}>{visualizationTypeValue.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label='Itens para checklist'>
                                    <Select
                                        disabled={this.state.generatedPDF}
                                        value={this.state.checklistItensChoosed}
                                        onChange={(value) => { this.handleChange(value, 'checklistItensChoosed') }}
                                        placeholder="Selecione..." size="large" style={{ width: "100%" }}
                                        mode='multiple'
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {this.state.checklistItens.map((prod, ind) => {
                                            return <Select.Option key={ind} value={prod.project_code}>{prod.project_code}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label='Código checklist'>
                                    <Input
                                        value={this.props.objectId}
                                        placeholder="Código checklist"
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>
                </Form>
            </div>
        );
    }
}
