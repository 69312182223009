const deliveryDeadlinesFields = () => {
    return [
        [
            {
                "key": "dataSourceFinancialSchedule",
                "type": "dynamic-input-list",
                "fields": [
                    [
                        {
                            'title': 'Semanas',
                            'key': 'weeks',
                            'type': 'text',
                            'colSize': 2,
                            'element-attr': { 'required': false, }
                        },
                        {
                            'title': 'Serviço',
                            'key': 'activity',
                            'type': 'text',
                            'colSize': 6,
                            'element-attr': { 'required': false }
                        },
                        {
                            'title': 'Responsabilidade',
                            'key': 'responsibility',
                            'type': 'text',
                            'colSize': 4,
                            'element-attr': { 'required': false }
                        },
                        {
                            'title': 'Kits',
                            'key': 'kits',
                            'type': 'text',
                            'colSize': 2,
                            'element-attr': { 'required': false }
                        },
                        {
                            'title': 'Lote',
                            'key': 'lote',
                            'type': 'text',
                            'colSize': 2,
                            'element-attr': { 'required': false }
                        },
                        {
                            'title': 'Início',
                            'key': 'expected_date',
                            'type': 'date-picker',
                            'colSize': 4,
                            'element-attr': { 'required': false, 'showToday': false, allowClear: false }
                        },
                        {
                            'title': 'Término',
                            'key': 'accomplished_date',
                            'type': 'date-picker',
                            'colSize': 4,
                            'element-attr': { 'required': false, 'showToday': false, allowClear: false }
                        },
                    ]
                ],
                "element-attr": {
                    "required": true,
                }
            }
        ],
    ]
}

export default deliveryDeadlinesFields