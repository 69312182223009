// ATENÇÃO: Ao excluir ou adicionar itens, verificar o impacto no arquivo door-locks-matriz-functions.js

const doorLockTypology = [
    { label: 'Fechadura trinco normal - TN', value: 'tn' },
    { label: 'Fechadura trinco rolete - TR', value: 'tr' },
    { label: 'Fechadura de Correr - FC', value: 'fc' },
    { label: 'Fechadura Auxiliar - FA', value: 'fa' },
    { label: 'Fechadura Magnética - FM', value: 'fn' },
    { label: 'Barra Antipanico - BA', value: 'ba' },
    { label: 'Fechadura Manutenção - FA', value: 'ft' },
    { label: 'Fechadura Livre-Ocupado - LO', value: 'lo' },
    { label: 'Usinagem CNC Fechadura - UF', value: 'uf' },
]

const doorLockType = [
    { label: 'Mecânica', value: 'mec' },
    { label: 'Magnética', value: 'mag' },
    { label: 'Digital', value: 'dig' },
]

const doorLockComposition = [
    { label: 'Usinagem', value: 'usinagem' },
    { label: 'Fechadura', value: 'fech' },
    { label: 'Fechadura + Maçaneta', value: 'fech_mac' },
    { label: 'Fechadura + Puxador', value: 'fech_pux' },
    { label: 'Kit Fechadura', value: 'kit' },
    { label: 'Kit Fechadura PCD', value: 'kit_pcd' },
    { label: 'Kit Fechadura PSE', value: 'kit_pse' },
    { label: 'LockApp', value: 'kit_lock_app' },
]

export {
    doorLockTypology,
    doorLockType,
    doorLockComposition,
}