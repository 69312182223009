const { doorLockType, doorLockComposition, doorLockTypology } = require("./door-locks-select-options")

const getLabelFromValue = (array, value) => {
    const element = array.find(el => el.value === value)
    return element && element.label
}

const filterDoorLockTypology = (typology, fechMatriz) => {
    return [
        ...new Set(
            (fechMatriz || [])
                .filter(matriz => (matriz.get('typology') || [typology]).includes(typology))
                .map(matriz => matriz.get('doorLockTypology'))
        )
    ].map(el => {
        return {
            value: el,
            label: getLabelFromValue(doorLockTypology, el)
        }
    })
}

const filterDoorLockType = (doorLockTypologyToFilter, typology, fechMatriz) => {
    return [
        ...new Set(
            (fechMatriz || [])
                .filter(matriz =>
                    matriz.get('doorLockTypology') === doorLockTypologyToFilter &&
                    (matriz.get('typology') || [typology]).includes(typology)
                )
                .map(matriz => matriz.get('doorLockType'))
        )
    ].map(el => {
        return {
            value: el,
            label: getLabelFromValue(doorLockType, el)
        }
    })
}

const filterDoorLockComposition = (doorLockTypologyToFilter, doorLockTypeToFilter, typology, fechMatriz) => {
    return [
        ...new Set(
            (fechMatriz || [])
                .filter(matriz => {
                    return matriz.get('doorLockTypology') === doorLockTypologyToFilter &&
                        matriz.get('doorLockType') === doorLockTypeToFilter &&
                        (matriz.get('typology') || [typology]).includes(typology)
                })
                .map(matriz => matriz.get('doorLockComposition'))
                .flat()
        )
    ].map(el => {
        return {
            value: el,
            label: getLabelFromValue(doorLockComposition, el)
        }
    })
}

const codesOfComposition = (doorLockTypologyToFilter, doorLockTypeToFilter, fechMatriz) => {
    return [
        ...new Set(
            (fechMatriz || [])
                .filter(matriz => {
                    return matriz.get('doorLockTypology') === doorLockTypologyToFilter &&
                        matriz.get('doorLockType') === doorLockTypeToFilter
                })
                .map(matriz => matriz.get('doorLockCodes'))
                .flat()
        )
    ]
}

export {
    getLabelFromValue,
    filterDoorLockTypology,
    filterDoorLockType,
    filterDoorLockComposition,
    codesOfComposition,
}