import React from 'react'
import { Button, Modal } from 'antd'
import { findWhereMultiple } from '../../../../utils/db_functions.js'

const generalInfoFields = (props) => {

    const getSheetTeoricMass = async (formRef) => {

        const teoricMass = await findWhereMultiple('TeoricMassConfigs',
            [
                { 'field': 'folha_codes_s', 'value': [(formRef.product_code || '').slice(0, 8)], 'op': 'containsAll', logicOp: '$or' },
                { 'field': 'folha_codes_u', 'value': [(formRef.product_code || '').slice(0, 8)], 'op': 'containsAll', logicOp: '$or' },
                { 'field': 'performance', 'value': [formRef.performance || ''], 'op': 'containsAll', logicOp: '$and' },
            ]
        )

        return teoricMass.length ? teoricMass[0].get('teoric_mass') : 0
    }

    const getMarcoTeoricMass = async (formRef) => {

        const teoricMass = await findWhereMultiple('TeoricMassConfigs',
            [
                { 'field': 'marco_code_s', 'value': [(formRef.product_code || '').slice(0, 10)], 'op': 'containsAll', logicOp: '$or' },
                { 'field': 'marco_code_u', 'value': [(formRef.product_code || '').slice(0, 10)], 'op': 'containsAll', logicOp: '$or' },
                { 'field': 'marco_espessura', 'value': formRef.fill_componente || 0, 'op': 'equalTo', logicOp: '$and' },
            ]
        )

        return teoricMass.length ? teoricMass[0].get('teoric_mass') : 0
    }

    const getAlizarTeoricMass = async (formRef) => {

        const teoricMass = await findWhereMultiple('TeoricMassConfigs',
            [
                { 'field': 'alizar_code_product', 'value': [(formRef.product_code || '').slice(0, 6)], 'op': 'containsAll', logicOp: '$and' },
                { 'field': 'alizar_espessura', 'value': formRef.fill_componente || 0, 'op': 'equalTo', logicOp: '$and' },
            ]
        )

        return teoricMass.length ? teoricMass[0].get('teoric_mass') : 0
    }

    const getTeoricMass = async (formRef) => {

        let teoricMass = 0;
        if (formRef.group === 'Folha') teoricMass = await getSheetTeoricMass(formRef)
        if (formRef.group === 'Marco') teoricMass = await getMarcoTeoricMass(formRef)
        if (formRef.group === 'Alizar') teoricMass = await getAlizarTeoricMass(formRef)

        return teoricMass
    }

    const teoricWeightCalc = (form, group, teoricMass) => {
        switch (group) {
            case 'Marco':
            case 'Alizar':
                return (2 * form.getNumberValue('height_component') + form.getNumberValue('width_component')) / 1000 * (teoricMass || form.getNumberValue('teoric_mass'))
            default:
                return ((form.getNumberValue('height_component') / 1000) * (form.getNumberValue('width_component') / 1000) * (teoricMass || form.getNumberValue('teoric_mass')))
        }
    }

    const teoricWeightCalcWithDim = (height_component = 0, width_component = 0, group, teoricMass = 0) => {
        switch (group) {
            case 'Marco':
            case 'Alizar':
                return (2 * height_component + width_component) / 1000 * teoricMass
            default:
                return ((height_component / 1000) * (width_component / 1000) * teoricMass)
        }
    }

    const teoricMassName = (group) => {
        switch (group) {
            case 'Folha':
                return 'Kg/m²'
            case 'Marco':
            case 'Alizar':
                return 'Kg/m'
            default:
                return ''
        }
    };

    return [
        [
            {
                title: "Tipo de Componente",
                key: "group",
                type: "select",
                colSize: 6,
                options: [],
                optionConfig: { name: 'tipo_componente' },
                "element-attr": {
                    "required": true, mode: 'default',
                },
            },
            { type: "text", title: 'Descrição do Componente', key: 'description_component', colSize: 12, "element-attr": { "required": false }, },
            {
                type: "text", title: 'Código do Produto', key: 'product_code', colSize: 6, max: 20,
                valid: { check: (form) => (form.getNodeValue('product_code') || '').length <= 20 },
                "element-attr": {
                    "required": false,
                },
            }
        ],
        [
            {
                type: 'select',
                key: 'marco_code',
                title: 'Código Marco',
                options: [],
                optionConfig: { name: 'marco_code' },
                loaded: false,
                colSize: 4,
                'element-attr': {
                    disabled: Form => {
                        let disabled = ['Folha', 'Ferragem', 'Fechadura', 'Kit Fechadura', 'Acabamento', 'Alizar'].includes(Form.state.formRef.group)
                        if (disabled && Form.state.formRef.marco_code) Form.clearValue('marco_code')
                        return disabled
                    },
                    required: Form => {
                        return (Form.state.formRef.group === 'Marco')
                    },
                },
            },
            {
                type: 'select',
                key: 'performance',
                title: 'Desempenho da Folha',
                options: [],
                optionConfig: { name: 'performance' },
                loaded: false,
                colSize: 4,
                'element-attr': {
                    disabled: Form => {
                        let disabled = ['Marco', 'Ferragem', 'Fechadura', 'Kit Fechadura', 'Acabamento', 'Alizar'].includes(Form.state.formRef.group)
                        if (disabled && Form.state.formRef.performance) Form.clearValue('performance')
                        return disabled
                    },
                    required: Form => {
                        return (Form.state.formRef.group === 'Folha')
                    },
                }
            },
            {
                title: "Acabamento",
                key: "finishing",
                type: "pointer",
                colSize: 7,
                options: [],
                'relation-select': {
                    class: "Finishing",
                    label: f => `${f.code} - ${f.description}`,
                    value: "objectId",
                    key: "objectId",
                    orderBy: "code"
                },
                "element-attr": { "required": false, "mode": 'default' }
            },
            {
                type: "number", title: 'Altura (mm)', key: 'height_component',
                colSize: 3,
                'element-attr': {
                    disabled: Form => {
                        let disabled = ['Ferragem', 'Fechadura', 'Kit Fechadura', 'Acessórios', 'Conjunto Fechadura'].includes(Form.state.formRef.group)
                        if (disabled && Form.state.formRef.height_component) Form.clearValue('height_component')
                        return disabled
                    },
                    required: false,
                },
            },
            {
                type: "number", title: 'Largura (mm)', key: 'width_component',
                colSize: 3,
                'element-attr': {
                    disabled: Form => {
                        let disabled = ['Ferragem', 'Fechadura', 'Kit Fechadura', 'Acessórios', 'Conjunto Fechadura'].includes(Form.state.formRef.group)
                        if (disabled && Form.state.formRef.width_component) Form.clearValue('width_component')
                        return disabled
                    },
                    required: false,
                },
            },
            {
                type: "number", title: 'Espessura (mm)', key: 'fill_componente',
                colSize: 3,
                'element-attr': {
                    disabled: Form => {
                        let disabled = ['Ferragem', 'Fechadura', 'Kit Fechadura', 'Acessórios', 'Conjunto Fechadura'].includes(Form.state.formRef.group)
                        if (disabled && Form.state.formRef.fill_componente) Form.clearValue('fill_componente')
                        return disabled
                    },
                    required: false,
                },
            },
        ],
        [
            {
                title: 'Volume, massa e peso',
                key: 'volume_mass_weight',
                type: 'render',
                render: (_, form) => {
                    return (
                        <Button
                            icon='search'
                            onClick={async () => {
                                const volume = ((form.getNumberValue('height_component') / 1000) * (form.getNumberValue('width_component') / 1000) * (form.getNumberValue('fill_componente') / 1000)).toFixed(4)
                                const teoricMass = await getTeoricMass(form.state.formRef)
                                const teoricWeight = teoricWeightCalc(form, form.state.formRef.group, teoricMass).toFixed(2)

                                Modal.confirm({
                                    title: 'Volume, massa e peso',
                                    cancelText: 'Fechar',
                                    content: (
                                        <div>
                                            <div className='label'>Volume</div>
                                            <div>{volume}</div>

                                            <div className='label'>Massa</div>
                                            <div>{teoricMass} {teoricMassName(form.state.formRef.group)}</div>

                                            <div className='label'>Peso</div>
                                            <div>{teoricWeight}</div>
                                        </div>
                                    ),
                                })
                            }}
                        >
                            Ver valores
                        </Button>
                    )
                },
                beforeSave: async (module, parseObject) => {
                    const volume = (
                        (parseFloat(parseObject.get('height_component' || 0)) / 1000) *
                        (parseFloat(parseObject.get('width_component' || 0)) / 1000) *
                        (parseFloat(parseObject.get('fill_componente' || 0)) / 1000)
                    ).toFixed(4)

                    const teoricMass = await getTeoricMass(parseObject.toJSON())

                    const teoricWeight = teoricWeightCalcWithDim(
                        parseFloat(parseObject.get('height_component' || 0)),
                        parseFloat(parseObject.get('width_component' || 0)),
                        parseObject.get('group'), teoricMass
                    ).toFixed(2)

                    await parseObject.set('volume', parseFloat(volume))
                    await parseObject.set('teoric_mass', teoricMass)
                    await parseObject.set('teoric_weight', parseFloat(teoricWeight))

                    return true;
                }
            }
        ],
        // [
        //     {
        //         type: "render", key: 'volume', colSize: 4, "element-attr": { "required": false },
        //         render: (_, form, node) => {
        //             return <Input
        //                 size="large" ref="volume"
        //                 value={((form.getNumberValue('height_component') / 1000) * (form.getNumberValue('width_component') / 1000) * (form.getNumberValue('fill_componente') / 1000)).toFixed(4)}
        //                 placeholder="Volume Embalagem (m³)"
        //             />
        //         }, title: 'Volume Embalagem (m³)'
        //     },
        //     {
        //         type: "render", key: 'teoric_mass', colSize: 4, "element-attr": { "required": false },
        //         render: (_, form) => {
        //             return (
        //                 <Input
        //                     size="large"
        //                     value={`${form.state.formRef.teoric_mass} ${teoricMassName(form.state.formRef.group)}`}
        //                     placeholder='Massa Teórica'
        //                 />
        //             );
        //         }, title: 'Massa Teórica',
        //     },
        //     {
        //         type: "render",
        //         key: 'teoric_weight',
        //         title: 'Peso teórico (Kg)',
        //         colSize: 4,
        //         "element-attr": { "required": false },
        //         render: (_, form, node) => {
        //             return <Input
        //                 size="large"
        //                 value={teoricWeightCalc(form, form.state.formRef.group).toFixed(2)}
        //                 placeholder="Peso Teórico (Kg)"
        //             />
        //         },
        //     }
        // ],
        [
            {
                type: 'switch', key: 'is_add', title: 'Componente é Adicional ?', colSize: 4,
                'element-attr': { required: false }
            },
            {
                type: 'select', key: 'add_category', title: 'Categoria do Adicional', colSize: 4, options: [],
                optionConfig: { name: 'componente_adicional' }, loaded: false,
                'element-attr': {
                    disabled: Form => {
                        let disabled = !Form.state.formRef.is_add
                        if (disabled && Form.state.formRef.add_category) Form.clearValue('add_category')
                        return disabled
                    },
                    required: Form => {
                        return (Form.state.formRef.is_add === true)
                    },
                }
            },
            {
                type: "number", title: 'Peso (Em Kg)', key: 'weight_add', colSize: 5,
                'element-attr': {
                    disabled: Form => {
                        let disabled = !Form.state.formRef.is_add
                        if (disabled && Form.state.formRef.weight_add) Form.clearValue('weight_add')
                        return disabled
                    },
                    required: Form => {
                        return (Form.state.formRef.is_add === true)
                    },
                }
            },
            {
                type: "number", title: 'Altura (mm)', key: 'height_add', colSize: 5,
                'element-attr': {
                    disabled: Form => {
                        let disabled = !Form.state.formRef.is_add
                        if (disabled && Form.state.formRef.height_add) Form.clearValue('height_add')
                        return disabled
                    },
                    required: false,
                }
            },
            {
                type: 'tag-list', key: 'width_add', title: 'Larguras (mm)', colSize: 5,
                'element-attr': {
                    disabled: Form => {
                        let disabled = !Form.state.formRef.is_add
                        if (disabled && Form.state.formRef.width_add) Form.clearValue('width_add')
                        return disabled
                    },
                    required: false,
                }
            },
        ],
    ]
}

export default generalInfoFields