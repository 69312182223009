import React from 'react';
import { Icon } from 'antd';
import { stringFormulas } from '../../../../utils/unity';

const formulas = stringFormulas

export default {
  "fields": [
    [
      {
        "title": "Nome conversão",
        "key": "name",
        'colSize': 6,
        type: "text",
        "element-attr": { "required": true },
      },
      {
        "title": "Fórmula",
        "key": "formula",
        'colSize': 18,
        type: "select",
        options: Object.keys(formulas).map(key => {
          return { value: key, label: formulas[key] }
        }),
        "element-attr": { "required": true, "mode": 'default' },
      },
    ],
    [
      {
        key: 'units',
        type: 'dynamic-input-list',
        fields: [
          [
            {
              type: 'select',
              key: 'unity',
              title: 'Unidade base',
              options: [],
              optionConfig: { name: 'unidades' },
              loaded: false,
              "element-attr": {
                mode: 'select',
                required: true
              },
              colSize: 8
            },
            {
              type: 'select',
              key: 'converted',
              title: 'Unidade a converter',
              options: [],
              optionConfig: { name: 'unidades' },
              loaded: false,
              "element-attr": {
                mode: 'select',
                required: true
              },
              colSize: 8
            },
          ]
        ]
      },
    ],
  ],
  "submit": { "collection": "ConvertionConfigs" },
  "schema": [
    {
      "title": "Nome conversão",
      "key": "name",
      "dataIndex": "name",
      "type": "text",
    },
    {
      "title": "Fórmula",
      "key": "formula",
      "dataIndex": "formula",
      "type": "text",
      render: (e) => formulas[e]
    },
  ],
  "title-module": "Conversão",
  "title-navigation": <div><Icon type="swap" /> Conversão</div>,
  "module": "ConvertionConfigs",
  "router-base": "/panel/conversao-configs"
}

