import { Select } from 'antd';
import React from 'react';

const invoicingFields = (invoicingCompanies) => {
    return [
        [
            {
                "key": "dataSourcePaymentConditions",
                "type": "dynamic-input-list",
                "fields": [
                    [
                        {
                            'title': 'Parcela',
                            'key': 'paymentPortion',
                            'type': 'number',
                            'colSize': 2,
                            'element-attr': {
                                'required': false,
                                'step': 1,
                            }
                        },
                        {
                            'title': 'Referência',
                            'key': 'paymentRef',
                            'type': 'select',
                            'options': [
                                { label: 'Selecione', value: '' },
                                { label: 'Projeto', value: 'Projeto' },
                                { label: 'Matéria Prima', value: 'Matéria Prima' },
                                { label: 'Industrialização', value: 'Industrialização' },
                                { label: 'Instalação', value: 'Instalação' },
                            ],
                            'colSize': 4,
                            'element-attr': { 'required': false }
                        },
                        // {
                        //     'title': 'Vencimento',
                        //     'key': 'paymentDueDate',
                        //     'type': 'date-picker',
                        //     'colSize': 4,
                        //     'element-attr': { 'required': false, 'showToday': false }
                        // },
                        {
                            'title': 'Valor',
                            'key': 'paymentValue',
                            'type': 'money',
                            'colSize': 4,
                            'element-attr': { 'required': false }
                        },
                        {
                            'title': 'Faturamento',
                            'key': 'billingCompany',
                            'type': 'select',
                            'colSize': 14,
                            'element-attr': { 'required': false },
                            'options': invoicingCompanies,
                            render: (_, value) => {
                                return (_[`${value.paymentRef}`] || []).map(el => {
                                    return (
                                        <Select.Option key={el.value} value={el.value}>
                                            {el.label}
                                        </Select.Option>
                                    )
                                })
                            }
                        },
                        // {
                        //     'title': 'CNPJ',
                        //     'key': 'paymentCnpj',
                        //     'type': 'text',
                        //     'colSize': 5,
                        //     'element-attr': { 'required': false },
                        //     maskToValue: { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true }
                        // },
                    ]
                ],
                "element-attr": { "required": true, }
            }
        ],
        [
            {
                "type": "render",
                "key": "total_with_discount_render_2",
                "render": (_) => {
                    return (
                        <b>
                            Total Geral da Proposta: {((_.formRef.total || 0) - (_.formRef.commercial_discount || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </b>
                    )
                }
            },
            {
                "type": "render",
                "key": "total_with_discount_render_3",
                "render": (_) => {
                    const total = ((_ && _.formRef && _.formRef.dataSourcePaymentConditions) || [])
                        .reduce((prev, current) => prev += parseFloat(current.paymentValue || '0'), 0)

                    return (
                        <div style={{ textAlign: 'right' }}>
                            <b>
                                Soma das parcelas: {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </b>
                        </div>
                    )
                }
            },
        ],
    ]
}

export default invoicingFields