import { Button, Dropdown, Icon, Menu, Popover, Table } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import { convertVisualizationType } from '../../../../utils/general_functions';
import EmailModal from '../entities/email-modal';
import MarginSimulationModal from '../entities/margin-simulation-modal';
import StatusModal from '../entities/status-modal';

const expandedContentComponent = (parentProposal, salesChannels) => {
    let dataSource = parentProposal.all_proposals.map(proposal => {

        // PDF configurations - start
        let lastUpdatedDate = new Date(proposal.updatedAt);
        let changeNameDate = new Date(2020, 8, 19);

        let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
        serverUrlArray.pop();
        const serverUrl = serverUrlArray.join('/');
        let pdfProtectName = `${serverUrl}/api/proposals/download-proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) pdfProtectName = `${serverUrl}/api/proposals/download-proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        let pdfAnaliticName = `${process.env.REACT_APP_PUBLIC}/pdf_analitic_proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) pdfAnaliticName = `${process.env.REACT_APP_PUBLIC}/pdf_analitic_proposal/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        let pdfInputsName = `${process.env.REACT_APP_PUBLIC}/pdf_input_report/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) pdfInputsName = `${process.env.REACT_APP_PUBLIC}/pdf_input_report/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        let pdfCrmName = `${process.env.REACT_APP_PUBLIC}/pdf_crm/${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) pdfCrmName = `${process.env.REACT_APP_PUBLIC}/pdf_crm/${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        let pdfs = [
            { pdfName: pdfName, pdf: proposal.pdf, description: 'Proposta' },
            { pdfName: pdfProtectName, pdf: proposal.pdf, description: 'Proposta c/ senha' },
            { pdfName: pdfAnaliticName, pdf: proposal.pdf, description: 'Relatório Analítico' },
            { pdfName: pdfInputsName, pdf: proposal.pdf, description: 'Relatório de Insumos' },
            { pdfName: pdfCrmName, pdf: proposal.pdf, description: 'CRM' },
            { pdfName: proposal.cnpj_receita ? proposal.cnpj_receita.url : '', pdf: proposal.cnpj_receita, description: 'CNPJ Receita' },
        ];
        // PDF configurations - end

        // Sales channel configurations
        const localSaleChannel = (proposal.salesChannelRuleIndex || proposal.salesChannelRuleIndex === 0) ?
            (
                (
                    proposal.sales_channel &&
                    proposal.sales_channel.automatic_choose_rules &&
                    proposal.sales_channel.automatic_choose_rules[proposal.salesChannelRuleIndex] &&
                    proposal.sales_channel.automatic_choose_rules[proposal.salesChannelRuleIndex].description
                ) || ''
            ) :
            ((proposal.sales_channel && proposal.sales_channel.name) || '')

        // Proposal Name
        let proposalName = `${(proposal.name_client || '').replace('/', '')}-${proposal.cod_cliente}-${proposal.proposal_code}.pdf`.replace(/\s/g, '-');
        if (lastUpdatedDate > changeNameDate) proposalName = `${(proposal.name_client || '').replace('/', '')}-${proposal.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-');

        return {
            'key': proposal.objectId,
            'proposal_code': proposal,
            'pdf': pdfs,
            'sales_channel': localSaleChannel,
            'kits': proposal.baseFinalPriceAndAmount && proposal.baseFinalPriceAndAmount.finalAmount,
            'final_value': (proposal.final_value && proposal.final_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) || 'Proposta não emitida',
            'email_sent': proposal,
            'status': proposal,
            'usage': proposal.usage,
            'visualization_type': proposal.visualization_type,
            'square-options-check': proposal,
            'actions': { ...proposal, proposalName },
        };
    });

    return (
        <div style={{ margin: '-10px -5px' }}>
            <Table
                dataSource={dataSource}
                columns={[
                    {
                        title: "Versão", key: "proposal_code", dataIndex: "proposal_code", width: '70px', align: 'center',
                        render: (proposal) => {
                            return <Link to={'/panel/gerenciar-proposta/editar/' + proposal.key}>
                                {proposal.proposal_code.slice(-2)}
                            </Link>
                        }
                    },
                    {
                        title: "PDFs", key: "pdf", dataIndex: 'pdf',
                        render: (pdfs) => {
                            const documentLinks = (
                                <Menu style={{ width: '200px' }}>
                                    {
                                        pdfs.map(pdf =>
                                            <Menu.Item>
                                                <Link to={pdf.pdfName} target='blank' style={{ marginBottom: '5px' }}>{pdf.description}</Link>
                                            </Menu.Item>
                                        )
                                    }
                                </Menu>
                            )

                            return (
                                <Dropdown overlay={documentLinks}>
                                    <div className='link-style'>
                                        PDFs <Icon type='down' />
                                    </div>
                                </Dropdown>
                            )
                        }
                    },
                    { title: "Canal de vendas", key: "sales_channel", dataIndex: "sales_channel", align: 'center' },
                    { title: "Kits", key: "kits", dataIndex: "kits", align: 'center' },
                    { title: "Valor R$", key: "final_value", dataIndex: "final_value", align: 'center' },
                    {
                        title: "E-mail", key: "email_sent", dataIndex: 'email_sent', width: '65px', align: 'center',
                        render: (proposal) => {
                            let color = 'red';

                            if (proposal.historic && proposal.historic.length) {
                                proposal.historic.forEach(comment => {
                                    if (
                                        comment.comment.includes(`${proposal.proposal_code} enviada para o e-mail`) ||
                                        comment.comment.includes(`${proposal.proposal_code} enviada para o cliente via whatsapp`)
                                    ) color = 'green';
                                });
                            };

                            return (
                                <div
                                    style={{
                                        backgroundColor: color,
                                        width: '20px', height: '20px',
                                        margin: 'auto', borderRadius: '50%'
                                    }}
                                />
                            );
                        }
                    },
                    {
                        title: "Status", key: "status", dataIndex: 'status', width: '65px', align: 'center',
                        render: (proposal) => {
                            return (
                                <StatusModal
                                    proposal_code={proposal.proposal_code}
                                    status={proposal.proposal_management_status}
                                    proposal={proposal}
                                />
                            )
                        }
                    },
                    { title: "Tipo de Obra", key: "usage", dataIndex: "usage" },
                    { title: 'Visualização da proposta', key: 'visualization_type', dataIndex: 'visualization_type', width: '150px', render: (visualizationType) => convertVisualizationType(visualizationType) },
                    {
                        title: "Ações", key: "actions", dataIndex: 'actions', align: 'center',
                        render: (proposal) => {
                            let keyWord = `${proposal.keyWord} ${proposal.proposal_code}`;
                            if (proposal.pdf) {
                                return (
                                    <div>
                                        <Button.Group>
                                            <EmailModal
                                                proposal={proposal}
                                                email={proposal.email_prop}
                                                proposalName={proposal.proposalName}
                                                keyWord={keyWord}
                                                proposal_code={proposal.proposal_code}
                                                proposalId={proposal.objectId}
                                                historic={proposal.historic}
                                                representative={proposal.consult_repre_id}
                                                representativeEmail={proposal.consult_repre_email}
                                            />

                                            <Link to={{ pathname: '/panel/checklist', state: proposal.objectId }}>
                                                <Popover content='Checklist'>
                                                    <Button
                                                        icon='check'
                                                    />
                                                </Popover>
                                            </Link>

                                            <MarginSimulationModal
                                                proposal={proposal}
                                            />
                                        </Button.Group>
                                    </div>
                                )
                            } else {
                                return '-'
                            }
                        }
                    },
                ]}
                size="middle"
                locale={{ emptyText: "Nenhum resultado encontrado." }}
                pagination={false}
            />
        </div>
    );
}

export default expandedContentComponent