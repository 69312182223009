import { Row } from 'antd';
import React from 'react'

const tableFooterComponent = () => {
    let statusArray = ['Em andamento', 'Proposta cancelada', 'Proposta substituida', 'Pedido gerado'];

    return (
        <div>
            <Row>
                <div style={{ clear: 'both', paddingBottom: 20 }}>
                    <div style={{ float: 'left', marginRight: 10 }}>
                        <b>Status: </b>
                    </div>
                    {
                        statusArray.map(el => {
                            let color = null

                            switch (el) {
                                case 'Em andamento':
                                    color = 'orange'
                                    break

                                case 'Proposta cancelada':
                                    color = 'red'
                                    break

                                case 'Pedido gerado':
                                    color = 'blue'
                                    break

                                case 'Proposta substituida':
                                    color = 'black'
                                    break

                                default:
                                    color = null
                            }

                            return (
                                <div style={{ float: 'left', marginRight: 30 }}>
                                    <div
                                        key={el}
                                        style={{
                                            float: 'left', backgroundColor: color,
                                            width: '15px', height: '15px', lineHeight: '15px',
                                            margin: 'auto', marginBottom: '5px', marginRight: 5,
                                            borderRadius: '50%',
                                            fontWeight: 'bold', fontSize: '10px',
                                            textAlign: 'center',
                                        }}
                                    />
                                    {el}
                                </div>
                            )
                        })
                    }

                </div>
            </Row>
        </div>
    )
}

export default tableFooterComponent