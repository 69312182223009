import React, { Component } from 'react';
import { Card, Button, Radio, Divider, message, Modal } from 'antd';
import { surveyQuestionsAndAnswers } from './suport-files/survey-questions';

export default class CustomerSatisfactionSurvey extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            editFormFlag: false,

            disabledFields: false,
            proposalName: null,

            form: {
                howToKnowMultidoor: null,
                whoIndicated: null,
                whatMostRelevant: null,
                whatMostImportant: null,
                doorImportance: null,
                whichItemWouldYouCutCosts: null,
                attendance: null,
                multidoorExperience: null,
            }
        }
    }

    componentWillMount = async () => {
        if (this.props['router-props'].location.pathname.includes('panel')) {
            this.setState({ editFormFlag: true });
        }
        this.getCommercialSurvey();
    }

    getCommercialSurvey = async () => {
        let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
        serverUrlArray.pop();
        const serverUrl = serverUrlArray.join('/');

        const proposal = this.props['router-props'].location.search.split('=')[1];
        const response = await fetch(`${serverUrl}/api/commercial-survey/${proposal}`, { method: 'GET' });
        const responseJson = await response.json();

        if (responseJson.data) {
            this.setState({
                disabledFields: true,
                form: responseJson.data.form,
                proposalName: responseJson.data.proposalName,
            });
        }
    }

    downloadProposal = async () => {

        let serverUrlArray = process.env.REACT_APP_SERVERURL.split('/');
        serverUrlArray.pop();
        const serverUrl = serverUrlArray.join('/');

        const proposal = this.props['router-props'].location.search.split('=')[1];

        if (this.state.disabledFields) {
            window.location.href = `${serverUrl}/api/proposals/download-proposal/${this.state.proposalName}`;
        } else {
            const response = await fetch(`${serverUrl}/api/commercial-survey`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        proposal: proposal,
                        form: this.state.form,
                    })
                });
            const responseJson = await response.json();

            if (responseJson.code !== 200) message.error('Erro ao baixar a proposta, tente novamente!');
            else {
                this.setState({
                    disabledFields: true,
                    proposalName: responseJson.data.proposalName,
                });
                window.location.href = `${serverUrl}/api/proposals/download-proposal/${responseJson.data.proposalName}`;
            }
        }
    }


    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            <div className="app-login" style={{ padding: '5vh', position: 'relative', height: '100vh' }}>

                <div style={{ maxHeight: '100%' }}>

                    <Card style={{ width: '100%', maxWidth: '1000px', margin: 'auto', marginBottom: '50px', height: '90vh', overflowY: 'auto' }} id='checklist-card'>

                        <h2>Pesquisa de atendimento Técnico-Comercial</h2>
                        <h4>Responda as seis perguntas e baixe a sua Proposta</h4>
                        Multidoor 4.0
                        <Divider />

                        <div>

                            {
                                surveyQuestionsAndAnswers.map(questionAndAnswers => {
                                    return (
                                        <div key={questionAndAnswers.question.key}>
                                            <h3>{questionAndAnswers.question.label}</h3>
                                            <Radio.Group
                                                disabled={this.state.disabledFields}
                                                onChange={(e) => this.setState({ form: { ...this.state.form, [questionAndAnswers.question.key]: e.target.value } })}
                                                value={this.state.form && this.state.form[questionAndAnswers.question.key]}>
                                                {
                                                    questionAndAnswers.answers.map(el => {
                                                        return <Radio style={radioStyle} value={el.key}>{el.label}</Radio>
                                                    })
                                                }
                                            </Radio.Group>

                                            <Divider />
                                        </div>
                                    )
                                })
                            }

                            {this.state.disabledFields ?
                                <Button
                                    type='primary'
                                    disabled={Object.values(this.state.form).includes(null)}
                                    onClick={() => this.downloadProposal()}
                                >
                                    Baixar proposta
                                </Button> :
                                <Button
                                    type='primary'
                                    disabled={Object.values(this.state.form).includes(null)}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Obrigado!',
                                            content: 'Agradecemos pela consulta dos nossos produtos e esperamos dar continuidade na negociação do pedido de portas Multidoor para a sua obra!',
                                            cancelText: 'Cancelar',
                                            okText: 'Baixar proposta',
                                            onOk: () => this.downloadProposal(),
                                            iconType: 'like',
                                        })
                                    }}
                                >
                                    Enviar pesquisa
                                </Button>
                            }
                        </div>

                    </Card>

                </div>

            </div>
        )
    }

}