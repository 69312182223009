const apiRoute = `${process.env.REACT_APP_SERVERURL.split('/').slice(0, -1).join('/')}/api/`

const apiGet = async (route) => {
    const response = await fetch(`${apiRoute}${route}`, {
        method: "GET",
        headers: { "Content-type": "application/json;charset=UTF-8" }
    })
    const responseJson = await response.json()

    if (responseJson['code'] === 200) return responseJson['data']
    return {}
}

export {
    apiGet,
}