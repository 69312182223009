import React from 'react';
import Parse from 'parse';
import { Row, Table, Button, Tag, Icon, notification } from 'antd';
import { moneyToNumber } from '../../../utils/general_functions';

let loadingButton = false;
let inputProviders = [];

const InputOrderReport = {
    fields: [
        [
            {
                title: 'Descrição',
                key: 'description',
                type: 'text',
                'element-attr': { required: true },
            }
        ],
        [
            {
                title: 'Status do pedido',
                key: 'status',
                type: 'select',
                options: [
                    { label: 'Programado', value: 'Programado' },
                    { label: 'Pagamento efetuado', value: 'Pagamento efetuado' },
                    { label: 'Faturado', value: 'Faturado' },
                    { label: 'Recebido', value: 'Recebido' },
                ],
                loaded: false,
                "element-attr": {
                    required: true,
                    mode: 'multiple',
                },
            },
            {
                title: 'Fornecedor/Provedor',
                key: 'providers',
                type: 'select',
                "element-attr": {
                    required: true,
                    mode: 'multiple',
                }
            }
        ],
    ],
    data: {
        inputOrders: [],
    },
    FormComponent: (Form, Module) => {
        if (!inputProviders.length) getInputProviders(Form);

        if (!InputOrderReport.dataToEdit && Form.props.objectEdit) {
            let ParseQuery = new Parse.Query('InputOrderReport')
            ParseQuery.get(Form.props.objectEdit).then(response => {
                InputOrderReport.dataToEdit = response.toJSON();
                InputOrderReport.data = InputOrderReport.dataToEdit.data;

                Form.setState(() => {
                    return {
                        formRef: InputOrderReport.dataToEdit
                    }
                })
            })
        }

        return (
            <div>
                <Row key="row-1" gutter={24} className="row-form-item">
                    {Form.getElementByTypeSchema(Form.getNodesByKey('description'), 24, InputOrderReport.dataToEdit, 0)}
                </Row>
                <Row key="row-2" gutter={24} className="row-form-item">
                    {Form.getElementByTypeSchema(Form.getNodesByKey('status'), 12, InputOrderReport.dataToEdit, 0)}
                    {
                        Form.getElementByTypeSchema(
                            {
                                ...Form.getNodesByKey('providers'),
                                options: inputProviders.map(el => {
                                    return { label: el.get('name'), value: `${el.id}_${el.get('name')}` };
                                }),
                            },
                            12, InputOrderReport.dataToEdit, 0
                        )
                    }
                </Row>
                <Row key="row-3" gutter={24} className="row-form-item">
                    {Form.getElementByTypeSchema({
                        type: "render", key: 'btn_plus', className: 'btn-plus-no-title', render: (_, form, node) => {
                            return (
                                <Button
                                    // node={node} 
                                    size='large'
                                    loading={loadingButton}
                                    onClick={async () => {
                                        await getInputOrders(form);
                                    }}
                                >
                                    Gerar/Atualizar relatório
                                </Button>
                            )
                        }
                    }, 12, InputOrderReport.dataToEdit, 0)}
                </Row>

                <Table
                    locale={{ emptyText: 'Sem pedidos' }}
                    columns={
                        [
                            {
                                title: 'Número',
                                dataIndex: 'order_code',
                                key: 'order_code',
                                sorter: (a, b) =>
                                    (a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 :
                                        ((b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)
                                // (a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")) -
                                // (a.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.order_code.normalize('NFD').replace(/[\u0300-\u036f]/g, "")),
                            },
                            {
                                title: 'Fornecedor/Provedor',
                                dataIndex: 'provider',
                                key: 'provider',
                                width: '400px',
                                render: (provider) => provider
                            },
                            {
                                title: 'Valor',
                                dataIndex: 'inputs',
                                key: 'inputs',
                                width: '200px',
                                align: 'center',
                                render: (v, form) => {
                                    const total = (v || []).reduce((prev, current) => {
                                        const unitPrice = (current.total && isNaN(current.total) ? moneyToNumber(current.total) : current.total) || 0
                                        return prev + (unitPrice + (((current.ipi || 0) / 100) * unitPrice))
                                    }, 0);
                                    return ((total + (form.shipment || 0)) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                }
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                width: '200px',
                                render: (status) => {

                                    return (status || []).map(el => {

                                        let color = null;

                                        switch (el) {
                                            case 'Programado':
                                                color = 'yellow'
                                                break

                                            case 'Pagamento efetuado':
                                                color = 'orange'
                                                break

                                            case 'Faturado':
                                                color = 'green'
                                                break

                                            case 'Recebido':
                                                color = 'blue'
                                                break

                                            case 'Cancelado':
                                                color = 'red'
                                                break

                                            default:
                                                color = null
                                        }

                                        return (
                                            <Tag key={el} color={color} style={{ margin: '5px' }}>
                                                {el}
                                            </Tag>
                                        )
                                    });
                                }
                            },
                            {
                                title: 'Data da entrega',
                                dataIndex: 'delivery_conditions',
                                key: 'delivery_conditions',
                                width: '200px',
                                align: 'center',
                                sorter: (a, b) =>
                                    (a.delivery_conditions > b.delivery_conditions) ? 1 :
                                        (a.delivery_conditions < b.delivery_conditions) ? -1 : 0,
                            },
                            {
                                title: 'Pedido de compra',
                                dataIndex: 'pdf',
                                key: 'pdf',
                                width: '150px',
                                align: 'center',
                            },
                        ]
                    }
                    dataSource={(InputOrderReport.data.inputOrders || []).map((item, index) => {
                        return {
                            'order_code': item.order_code,
                            'provider': item.provider,
                            'shipment': item.shipment,
                            'inputs': item.inputs,
                            'total_value': item.total_value,
                            'status': item.status,
                            'delivery_conditions': item.delivery_conditions,
                            'pdf': <Button
                                title="Visualizar pedido em PDF" type="primary" shape="circle" icon="file-pdf"
                                onClick={() => window.open(item.pdf, '_blink')}
                            />
                        }
                    })}
                >

                </Table>
            </div>
        );
    },
    submit: {
        collection: 'InputOrderReport'
    },
    SubmitForm: (FormRef, ObjectEdit, ObjectId, FormModule) => {
        let objectWithValues = parseDataToSave(FormRef, FormModule);
        let dinamicsValues = InputOrderReport.data;
        objectWithValues = { ...objectWithValues, data: dinamicsValues }
        let refs = FormModule.getRefWithFormItem(false);
        let invalid = false;
        for (let key in refs) {
            // checking if exist empty required inputs.
            if (!FormModule.nodeIsValid(FormModule.refs[key].props.node) || (!FormModule.nodeHasEmptyValue(FormModule.refs[key].props.value || FormModule.state.formRef[key]) && FormModule.nodeIsRequired(FormModule.refs[key].props.node))) {
                notification.error({
                    message: 'Erro ao salvar/atualizar',
                    description: FormModule.refs[key].props.node.errorMessage || `Valor inválido no campo: ${FormModule.refs[key].props.node.title}`, duration: 5
                })
                invalid = true;
                break;
            }
        }

        let data = objectWithValues;
        if (invalid) return false;
        if (ObjectEdit) return updateInputOrderReport(objectWithValues, ObjectId, FormModule)

        let Extension = new Parse.Object(FormModule.props.module.form.module);
        data['updatedBy'] = Parse.User.current();
        data['createdBy'] = Parse.User.current();
        Extension.save(data).then(saved => {
            FormModule.afterSave()
        }).catch(err => {
            notification.error({
                message: 'Erro ao salvar',
                description: err.message
            });
        });
    },
    onClickActionEdit: row => {
        InputOrderReport.dataToEdit = null
    },
    schema: [
        {
            "title": "Descrição",
            "key": "description",
            "dataIndex": "description",
            "type": "text",
        },
        {
            title: 'Programado',
            key: 'programados',
            dataIndex: 'data',
            type: 'render',
            width: '100px',
            align: 'center',
            render: (_) => {
                const total = ((_ && _.inputOrders) || []).reduce((acc, order) => {
                    return acc += ((order.status || []).includes('Programado') ? 1 : 0)
                }, 0)

                return total
            }
        },
        {
            title: 'Pagamento efetuado',
            key: 'pgto_efetuados',
            dataIndex: 'data',
            type: 'render',
            width: '100px',
            align: 'center',
            render: (_) => {
                const total = ((_ && _.inputOrders) || []).reduce((acc, order) => {
                    return acc += ((order.status || []).includes('Pagamento efetuado') ? 1 : 0)
                }, 0)

                return total
            }
        },
        {
            title: 'Faturado',
            key: 'faturado',
            dataIndex: 'data',
            type: 'render',
            width: '100px',
            align: 'center',
            render: (_) => {
                const total = ((_ && _.inputOrders) || []).reduce((acc, order) => {
                    return acc += ((order.status || []).includes('Faturado') ? 1 : 0)
                }, 0)

                return total
            }
        },
        {
            title: 'Recebido',
            key: 'recebido',
            dataIndex: 'data',
            type: 'render',
            width: '100px',
            align: 'center',
            render: (_) => {
                const total = ((_ && _.inputOrders) || []).reduce((acc, order) => {
                    return acc += ((order.status || []).includes('Recebido') ? 1 : 0)
                }, 0)

                return total
            }
        },
    ],
    "title-navigation": <div><Icon type="profile" theme="outlined" /> Relatório de Pedidos</div>,
    "title-module": "Relatório de Pedidos",
    "module": "InputOrderReport",
    "router-base": "/panel/relatorio-pedidos-compra"
}

const getInputProviders = async (Form) => {
    let inputProviderQuery = new Parse.Query('InputProvider').limit(10000);
    inputProviders = await inputProviderQuery.find();
    Form.forceUpdate();
}

const getInputOrders = async (Form) => {
    let providers = Form.state.formRef.providers;
    let status = Form.state.formRef.status;
    loadingButton = true;
    Form.forceUpdate();

    // Get all input orders
    let inputOrdersQuery = new Parse.Query('InputOrder').limit(10000);

    // Remove delivered and canceled status
    inputOrdersQuery.notContainedIn('status', ['Recebido', 'Cancelado']);

    // Providers filter
    if (providers && providers.length) {
        let providersIds = providers.map(el => el.split('_')[0]);
        inputOrdersQuery.containedIn('provider', providersIds);
    }

    // Status filter
    if (status && status.length) inputOrdersQuery.containedIn('status', status);

    let inputOrders = await inputOrdersQuery.find();

    InputOrderReport.data.inputOrders = inputOrders.map(item => {
        const localDate = new Date(item.get('delivery_conditions'));
        const localDateString = `${localDate.getDate() < 10 ? '0' : ''}${localDate.getDate()}/${(localDate.getMonth() + 1) < 10 ? '0' : ''}${localDate.getMonth() + 1}/${localDate.getFullYear()}`
        let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_input_order/pedido-compra-${(item.get('order_code') || '').replace('/', '')}.pdf`.replace(/\s/g, '-')

        let provider = item.get('provider');

        return {
            'order_code': item.get('order_code'),
            'provider': provider && provider.get('name'),
            'total_value': (item.get('total_value') || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            'status': item.get('status'),
            'shipment': item.get('shipment'),
            'inputs': item.get('inputs'),
            'delivery_conditions': localDateString,
            'pdf': pdfName,
        }
    });

    loadingButton = false;
    Form.forceUpdate();
}

let parseDataToSave = (_, FormModule) => {

    const getValues = (localFormModule) => {
        let refs = localFormModule.getRefWithFormItem(false)
        let obj = {}
        for (let item in refs) {
            let value = refs[item].props.value
            obj[item] = value;
        }
        return obj;
    }

    // get ParseObject of vinculated_component
    let refs = FormModule.getRefWithFormItem(false);
    return Object.keys(getValues(FormModule)).filter(v => {
        return (v.match(/[0-9]|_slider|_input/g)) ? false : v;
    })
        .reduce((a, k) => {
            let value = refs[k].props.value;
            return { ...a, [k]: value };
        }, {});

}

const updateInputOrderReport = (data, objectId, FormModule) => {
    let Query = new Parse.Query(FormModule.props.module.form.module)

    Query.get(objectId).then(ObjectParse => {
        ObjectParse.set('updatedBy', Parse.User.current())

        ObjectParse.save(data, {}).then(r => {
            FormModule.finishEdit()
            InputOrderReport.dataToEdit = null;
        }).catch(err => {
            notification.error({
                message: 'Erro ao atualizar o relatório',
                description: err.message
            })
        })
    })
}

export default InputOrderReport;