import React from 'react'
import { Icon, Tag } from 'antd'
import ReactQuill from 'react-quill';

// Modules to React Quil fields
let modules = {
	toolbar: [
		[{ 'header': [1, 2, 3, false] }],
		['bold', 'italic', 'underline', 'link', { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
		[{ 'list': 'ordered' }, { 'list': 'bullet' }]
	],
}
let formats = [
	'header',
	'bold', 'italic', 'underline', 'link', 'align',
	'list', 'bullet'
]

export default {
	"fields": [
		[
			{
				type: 'text',
				key: 'code',
				title: 'Código',
				colSize: 8,
				'element-attr': { required: true }
			},
		],
		[
			{
				type: 'select',
				key: 'performance_level',
				title: 'Perfis de Desempenho',
				options: [],
				optionConfig: { name: 'performance' },
				loaded: false,
				colSize: 24,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
		],
		[
			{
				title: 'Observação fixa',
				key: 'fixed_observation',
				type: 'render',
				render: (state, module, node) => {
					return (
						<ReactQuill
							value={state.formRef.fixed_observation || ""}
							modules={modules}
							formats={formats}
							onChange={value => {
								module.updateRefValue(node, value, 'fixed_observation')
							}}
						/>
					)
				},
				beforeSave: (module, parseObject, node) => {
					parseObject.set('fixed_observation', module.state.formRef.fixed_observation)
					return true;
				}
			}
		]
	],
	submit: {
		collection: "FixedObservationConfigs"
	},
	className: "config-component",
	schema: [
		{
			title: 'Perfis de Desempenho',
			key: 'performance_level',
			dataIndex: 'performance_level',
			render: v => {
				return (v || [])
					.map((performance, i) => <Tag key={i}>{performance}</Tag>)
			}
		},
		{
			title: "Observação fixa",
			key: "fixed_observation",
			dataIndex: "fixed_observation",
			render: obs => {
				return <div dangerouslySetInnerHTML={{ __html: obs }} />
			}
		},
	],
	"title-navigation": <div><Icon type="form" /> Observação fixa</div>,
	"title-module": "Observação fixa",
	"module": "FixedObservationConfigs",
	"router-base": "/panel/fixed-observation-configs"
}