import { message, Table, Button, Modal } from "antd";
import React from "react";
import ProposalModal from "../components/proposal-modal";
import {
  getNameOfAnaliticProposalPdf,
  getNameOfProposalPdf,
} from "../suport-files/proposal-functions";
import OrderGeneratorClass from "./order-generator";
import ProposalStatusClass from "./proposal-status";
import Parse from "parse";
import Auth from "../../../../auth/Auth";
import { Link } from "react-router-dom";
import { remove } from "../../../../utils/db_functions";
import EmailModal from "../../manage-proposal/entities/email-modal";

const expandedContentComponent = (parentProposal, users, surveys) => {
  const orderVersionProposal = parentProposal.all_proposals.find((proposal) => {
    return proposal.orderGenerated || proposal.status === "PedidoGerado";
  });

  return (
    <div style={{ margin: "-10px -5px" }}>
      <Table
        dataSource={parentProposal.all_proposals}
        columns={[
          {
            title: "Versão",
            key: "proposal_code",
            dataIndex: "proposal_code",
            align: "center",
            width: "100px",
            render: (proposalCode) => {
              return proposalCode.slice(-2);
            },
          },
          {
            title: "Gerar Proposta",
            key: "freight",
            dataIndex: "freight",
            align: "center",
            render: (_, row) => {
              return (
                <ProposalModal
                  objectId={row.objectId}
                  freight={{
                    m20: row.m20,
                    m30: row.m30,
                    m60: row.m60,
                    m90: row.m90,
                  }}
                  status={row.status}
                />
              );
            },
          },
          {
            title: "PDF Proposta",
            key: "pdf",
            dataIndex: "pdf",
            align: "center",
            render: (_, proposal) => {
              const pdfName = getNameOfProposalPdf(proposal);

              return (
                <Button
                  title="Visualizar proposta em PDF"
                  disabled={!proposal.pdf}
                  type="default"
                  shape="circle"
                  icon="file-pdf"
                  onClick={() => {
                    let auth = new Auth(Parse.User.current());
                    auth.init(() => {
                      if (auth.hasAction(["viewProposalPDF-proposal", "*"]))
                        window.open(pdfName, "_blink");
                      else
                        message.error(
                          "Você não tem permissão para acessar esse documento"
                        );
                    });
                  }}
                />
              );
            },
          },
          {
            title: "PDF Analítico",
            key: "pdf-analitic",
            dataIndex: "pdf-analitic",
            align: "center",
            render: (_, proposal) => {
              const pdfName = getNameOfAnaliticProposalPdf(proposal);

              return (
                <Button
                  title="Visualizar relatório analítico"
                  disabled={!proposal.pdf}
                  type="default"
                  shape="circle"
                  icon="file-pdf"
                  onClick={() => {
                    let auth = new Auth(Parse.User.current());
                    auth.init(() => {
                      if (
                        auth.hasAction([
                          "viewAnaliticProposalPDF-proposal",
                          "*",
                        ])
                      )
                        window.open(pdfName, "_blink");
                      else
                        message.error(
                          "Você não tem permissão para acessar esse documento"
                        );
                    });
                  }}
                />
              );
            },
          },
          {
            title: "E-mail",
            key: "email_sent",
            dataIndex: "email_sent",
            align: "center",
            width: "100px",
            render: (_, proposal) => {
              let color = "red";

              if (proposal.historic && proposal.historic.length) {
                proposal.historic.forEach((comment) => {
                  if (
                    comment.comment.includes(
                      `${proposal.proposal_code} enviada para o e-mail`
                    ) ||
                    comment.comment.includes(
                      `${
                        proposal.proposal_code
                      } enviada para o cliente via whatsapp`
                    )
                  )
                    color = "green";
                });
              }

              // Set to blue when generate a order without sent the proposal to client
              if (color === "red" && proposal.orderGenerated) color = "blue";

              return (
                <div
                  style={{
                    backgroundColor: color,
                    width: "20px",
                    height: "20px",
                    margin: "auto",
                    borderRadius: "50%",
                  }}
                />
              );
            },
          },
          {
            title: "Pesquisa por versão",
            key: "survey",
            dataIndex: "proposal_code",
            align: "center",
            width: "80px",
            render: (proposal_code) => {
              
              const survey = surveys.find(
                (el) => `${el.get("proposal")}-01` === proposal_code || el.get('proposal') === proposal_code
              );

              return survey ? (
                <div
                  style={{
                    backgroundColor: 'green',
                    width: "20px",
                    height: "20px",
                    margin: "auto",
                    borderRadius: "50%",
                  }}
                />
              ) : '-';
            },
          },
          {
            title: "Status",
            key: "status",
            dataIndex: "status",
            align: "center",
            width: "100px",
            render: (_, proposal) => (
              <ProposalStatusClass
                proposal={proposal}
                orderVersionProposal={orderVersionProposal}
              />
            ),
          },
          {
            title: "Canal de vendas",
            key: "sales_channel",
            dataIndex: "sales_channel",
            align: "center",
            render: (v, row) => {
              if (
                row.salesChannelRuleIndex ||
                row.salesChannelRuleIndex === 0
              ) {
                return (
                  (v &&
                    v.automatic_choose_rules &&
                    v.automatic_choose_rules[row.salesChannelRuleIndex] &&
                    v.automatic_choose_rules[row.salesChannelRuleIndex]
                      .description) ||
                  ""
                );
              } else return v ? v.name : "";
            },
          },
          {
            title: "Gerar Pedido",
            key: "order",
            dataIndex: "order",
            align: "center",
            render: (_, proposal) => (
              <OrderGeneratorClass proposal={proposal} />
            ),
          },
          {
            title: "Data Atualização",
            key: "updatedAt",
            align: "center",
            sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
            dataIndex: "updatedAt",
            render: (_, row) => {
              return new Date(row.updatedAt).toLocaleString("pt-BR", {
                timeZone: "America/Maceio",
                day: "numeric",
                month: "numeric",
                year: "numeric",
              });
            },
            width: "180px",
          },
          {
            width: "180px",
            title: "Alterado por",
            key: "updatedBy",
            align: "center",
            render: (text, row, index) => {
              let user = users.filter(
                (v) => v.id === (row.updatedBy && row.updatedBy.objectId)
              );
              return <div>{user && user[0] ? user[0].get("name") : ""}</div>;
            },
          },
          {
            title: "Ações",
            key: "action",
            align: "center",
            className: "actions-col",
            render: (text, row, index) => {
              // Proposal Name
              let lastUpdatedDate = new Date(row.updatedAt);
              let changeNameDate = new Date(2020, 8, 19);
              let proposalName = `${(row.name_client || "").replace("/", "")}-${
                row.cod_cliente
              }-${row.proposal_code}.pdf`.replace(/\s/g, "-");
              if (lastUpdatedDate > changeNameDate)
                proposalName = `${(row.name_client || "").replace("/", "")}-${
                  row.proposal_code
                }-Multidoor.pdf`.replace(/\s/g, "-");

              return (
                <div style={{ textAlign: "center" }}>
                  <Button.Group>
                    <EmailModal
                      proposal={row}
                      email={row.email_prop}
                      proposalName={proposalName}
                      keyWord={row.keyWord}
                      proposal_code={row.proposal_code}
                      proposalId={row.objectId}
                      historic={row.historic}
                      representative={row.consult_repre_id}
                      representativeEmail={row.consult_repre_email}
                    />

                    <Button
                      icon="delete"
                      onClick={async () => {
                        Modal.confirm({
                          title: "ATENÇÃO",
                          content:
                            "Tem certeza que deseja excluir este registro do sistema?",
                          okText: "Remover",
                          cancelText: "Não, deixa pra lá",
                          okType: "danger",
                          onOk: async () => {
                            const hasDeleted = await remove(
                              "Proposal",
                              row.objectId
                            );
                            if (hasDeleted) window.location.reload();
                          },
                        });
                      }}
                    />

                    <Link to={"/panel/proposta/editar/" + row.objectId}>
                      <Button icon="edit" />
                    </Link>
                  </Button.Group>
                </div>
              );
            },
          },
        ]}
        size="middle"
        locale={{ emptyText: "Nenhum resultado encontrado." }}
        pagination={false}
      />
    </div>
  );
};

export default expandedContentComponent;
