import { defaultProposalViewOptions } from "../../../../utils/defaultDataSource";

const { Icon } = require("antd");
const React = require("react");

const generalInfoFields = [
    [
        {
            "title": "Nome",
            "key": "name",
            "type": "text",
            colSize: 10,
            "element-attr": { "required": true, "prefix": <Icon type="tags" /> }
        },
        // {
        //     "title": "Valor (%)",
        //     "key": "value",
        //     "type": "number",
        //     colSize: 6,
        //     "element-attr": { "required": false, "prefix": <Icon type="tags" />, min: -100, max: 500 }
        // },
        {
            "title": "Tipo de cliente",
            "key": "customer_type",
            "type": "select",
            colSize: 4,
            'loaded': false,
            'options': [
                { label: 'Pessoa Física', value: 'pf' },
                { label: 'Pessoa Jurídica', value: 'pj' },
            ],
            "element-attr": { "required": true }
        },
        {
            type: 'select',
            key: 'email_sender',
            title: 'E-mail envio de proposta',
            options: [],
            optionConfig: { name: 'EMAIL' },
            loaded: false,
            colSize: 6,
            "element-attr": {
                mode: 'default',
                required: false,
            },
        },
        {
            "title": "Desabilitado",
            "key": "disabled",
            "type": "switch",
            colSize: 4,
            "element-attr": { "required": false }
        },
    ],
    [
        {
            "title": "Fornecedor",
            "key": "provider",
            "type": "text",
            colSize: 8,
            "element-attr": { "required": true }
        },
        {
            "title": "Endereço",
            "key": "address",
            "type": "text",
            colSize: 8,
            "element-attr": { "required": true }
        },
        {
            "title": "CEP",
            "key": "address_cep",
            "type": "text",
            colSize: 3,
            "element-attr": { "required": false },
            "maskToValue": { mask: "xxxxx-xxx", onlyNumber: true }
        },
        {
            "title": "Cidade",
            "key": "address_city",
            "type": "text",
            colSize: 3,
            "element-attr": { "required": false }
        },
        {
            "title": "Estado",
            "key": "address_state",
            "type": "text",
            colSize: 2,
            "element-attr": { "required": false }
        },
    ],
    [
        {
            "title": "CNPJ",
            "key": "cnpj",
            "type": "text",
            colSize: 4,
            "element-attr": { "required": true },
            "maskToValue": { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true }
        },
        {
            "title": "Insc. Estadual",
            "key": "insc_estadual",
            "type": "text",
            colSize: 4,
            "element-attr": { "required": true }
        },
        {
            "title": "Insc. Municipal",
            "key": "insc_municipal",
            "type": "text",
            colSize: 4,
            "element-attr": { "required": false }
        },
        {
            "title": "Site",
            "key": "site",
            "type": "text",
            colSize: 4,
            "element-attr": { "required": true }
        },
        {
            "title": "Coordenadas (ex.: -9.58907734150, -35.7313536973)",
            "key": "coordinates",
            "type": "text",
            colSize: 8,
            "element-attr": { "required": false }
        },
    ],
    [
        {
            "title": "Telefone Comercial",
            "key": "phone_number",
            "type": "text",
            colSize: 6,
            "element-attr": { "required": true },
            "maskToValue": { phoneMask: true }
        },
        {
            "title": "E-mail Comercial",
            "key": "email",
            "type": "email",
            colSize: 6,
            "element-attr": { "required": true }
        },
        {
            "title": "Contato Administrativo",
            "key": "contact_adm",
            "type": "text",
            colSize: 6,
            "element-attr": { "required": true },
        },
        {
            "title": "E-mail Administrativo",
            "key": "email_adm",
            "type": "email",
            colSize: 6,
            "element-attr": { "required": true }
        }
    ],
    [
        {
            "title": "Opções de visualização da proposta",
            "key": "proposal_view_options",
            "colSize": 16,
            "type": "select",
            'loaded': false,
            'options': defaultProposalViewOptions,
            "element-attr": { "required": false, "mode": "multiple" }
        },
        {
            "title": "Visualização da proposta (padrão)",
            "key": "proposal_view_default",
            "colSize": 8,
            "type": "select",
            'loaded': false,
            'options': [
                { label: 'Escolha uma opção', value: null },
                ...defaultProposalViewOptions
            ],
            "element-attr": { "required": false }
        },
    ],
]

export default generalInfoFields