import React from 'react'
import { Icon, notification } from 'antd'
import Parse from 'parse'
export default {
	"fields": [
		[
			{
				"title": "Nome da Coleção",
				"key": "name",
				"type": "text",
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				},
				beforeSave: async (Form, ParseObject, node) => {
					const query = new Parse.Query('Collections')
					query.equalTo('name', Form.state.formRef.name)
					query.notEqualTo('objectId', ParseObject.id)
					const count = await query.count()
					if (count > 0) {
						notification.error({ message: 'Erro ao registrar a coleção', description: 'Esse nome já está registrado.' })
						return false;
					}

					return true;
				}
			}
		]
	],
	submit: {
		collection: "Collections"
	},
	className: "config-component",
	schema: [
		{
			title: "Nome da Coleção",
			key: "name",
			dataIndex: "name",
			type: "text",
			className: "name"
		}
	],
	"title-navigation": <div><Icon type="bg-colors" /> Coleções</div>,
	"title-module": "Coleções",
	"module": "Collections",
	"router-base": "/panel/colecoes"
}