import React from 'react'
import { Icon, Tag } from 'antd'
import { findAll } from '../../../../utils/db_functions'

let finishingArray = []

export default {
	"fields": [
		[
			{
				title: "Acabamento folha",
				key: "finishing_sheet",
				type: "pointer",
				options: [],
				colSize: 8,
				"relation-select": {
					"class": "Finishing",
					"label": "code",
					"value": "objectId",
					"key": "objectId"
				},
				"element-attr": { "required": false, "mode": 'default' }
			},
		],
		[
			{
				title: "Acabamentos marcos e alizares",
				key: "finishing_marco_alizar",
				type: "select",
				options: [],
				loaded: false,
				"element-attr": { "required": false, "mode": 'multiple' }
			},
		],
	],
	submit: {
		collection: "MatrizFinishing"
	},
	ListWillMount: async (_) => {
		finishingArray = await findAll("Finishing")
		_.forceUpdate()
	},
	FormHasMounted: async (_) => {
		if (!finishingArray.length) {
			finishingArray = await findAll("Finishing")
		}

		_.module.form.fields[1][0].options = finishingArray.map(finishing => {
			return { label: finishing.get("code"), value: finishing.id }
		})

		_.forceUpdate()
	},
	schema: [
		{
			title: "Acabamento folha",
			key: "finishing_sheet",
			dataIndex: "finishing_sheet",
			type: "render",
			render: (value) => {
				return value && value["code"]
			}
		},
		{
			title: "Acabamentos marcos e alizares",
			key: "finishing_marco_alizar",
			dataIndex: "finishing_marco_alizar",
			type: "render",
			render: (finishings) => {
				return (
					<div>
						{
							finishings.map((finishingId, i) => {
								const finishing = finishingArray.find(el => el.id === finishingId)
								return <Tag style={{ margin: '5px' }} key={i}>{finishing && finishing.get("code")}</Tag>
							})
						}
					</div>
				)
			}
		},
	],
	"title-navigation": <div><Icon type="table" /> Matriz de acabamentos</div>,
	"title-module": "Matriz de acabamentos",
	"module": "MatrizFinishing",
	"router-base": "/panel/matriz-acabamentos",

}