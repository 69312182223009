import { Button, Col, Collapse, Divider, Form, Icon, Modal, Popover, Row, Select } from 'antd'
import React, { Component } from 'react'
import { getStringFormula, getUnityFormule, stringFormulas } from '../../../../utils/unity'
import { findAll, findWhere } from '../../../../utils/db_functions'
import '../component-kit.css'
import { getValuesToConsumption } from '../../shared/component-mounting-instalation-functions'
import ButtonGroup from 'antd/lib/button/button-group'
import InputCompositionModal from './input-composition-modal'
import { getMaterialCost, getTotalCost } from '../../../supplies/inputs/inputs'

export default class InputFinishingTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputFinishingGroups: this.props.inputFinishingGroups,
            inputs: [],
            finishings: [],

            addInputModal: false,
            updateInputModal: false,
            form: {},
            unitySelectArray: [],
            inputIndexToUpdate: null,
        }
    }

    componentDidMount = async () => {
        const inputs = await this.getInputs((this.props.inputFinishingGroups || []).map(inputGroup => inputGroup.rows).flat().map(el => el.specification).filter(el => el))
        const finishings = await findAll("Finishing", null, null, null, "code")

        const unitySelectArray = await findWhere('Config', 'name', ['unidades'])

        this.setState({ inputFinishingGroups: this.props.inputFinishingGroups, inputs, unitySelectArray: unitySelectArray[0].get('value'), finishings })
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.inputFinishingGroups !== this.state.inputFinishingGroups) {
            const inputs = await this.getInputs((nextProps.inputFinishingGroups || []).map(inputGroup => inputGroup.rows).flat().map(el => el.specification).filter(el => el))
            this.setState({ inputFinishingGroups: nextProps.inputFinishingGroups, inputs })
        }
    }

    getInputs = async (inputIds) => {
        const inputs = await findWhere('Input', 'objectId', inputIds)
        return inputs
    }

    getInputById = (inputId) => {
        return this.state.inputs.find(input => input.id === inputId)
    }

    renderInput = (inputParseObject, input, inputGroupIndex, inputIndex) => {

        const inputDataLabelAndValue = [
            { label: 'Unidade', value: 'unity' },
            { label: 'Qtd', value: 'amount_component' },
            { label: 'Largura', value: 'width_component' },
            { label: 'Espessura', value: 'thickness_component' },
            { label: 'Comprimento', value: 'length_component' },
        ]

        let consumer = 0
        let finalValue = 0
        let convert = 0
        if (inputParseObject) {
            let r = getUnityFormule((inputParseObject.get('measure')), input.unity, this.props.convertionFormulas)(getValuesToConsumption(input, inputParseObject.toJSON()))
            consumer = (r === Infinity) ? 0 : r;
            finalValue = this.props.calculateUnitCost(inputParseObject.toJSON(), input)
            convert = inputParseObject.get('convert')
        }

        return (
            <Row gutter={24}>
                <Col span={20}>
                    <Row gutter={24}>
                        <div className='inputCompositionInputTitle'>
                            {inputParseObject && inputParseObject.get('material_description')}
                        </div>
                    </Row>
                    <Row gutter={24}>
                        {
                            inputDataLabelAndValue.map(labelAndValue => {
                                return (
                                    <Col span={3}>
                                        <div className='inputCompositionInputSubtitle'>
                                            {labelAndValue.label}
                                        </div>
                                        <div className='inputCompositionInputSubSubtitle'>
                                            {input[labelAndValue.value]}
                                        </div>
                                    </Col>
                                )
                            })
                        }
                        <Col span={3}>
                            <div className='inputCompositionInputSubtitle'>
                                Conversão
                            </div>
                            <div className='inputCompositionInputSubSubtitle'>
                                {(convert || 0).toFixed(2)}
                            </div>
                        </Col>

                        <Col span={3}>
                            <div className='inputCompositionInputSubtitle'>
                                Consumo
                            </div>
                            <div className='inputCompositionInputSubSubtitle'>
                                {(consumer || 0).toFixed(4)}
                            </div>
                        </Col>

                        <Col span={3}>
                            <div className='inputCompositionInputSubtitle'>
                                Total
                            </div>
                            <div className='inputCompositionInputSubSubtitle'>
                                {(finalValue || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col span={4}>
                    <div className='inputCompositionInputActionButtons'>
                        <ButtonGroup>
                            <Popover content='Informações detalhadas do insumo'>
                                <Button
                                    icon="info-circle"
                                    onClick={() => {
                                        if (inputParseObject) {
                                            const data = inputParseObject.toJSON()

                                            const formula = getStringFormula(this.props.convertionFormulas, data.measure, data.consumption_measure)
                                            const formulaToGetName = this.props.convertionFormulas.find(el => el.get('formula') === formula)

                                            Modal.info({
                                                width: '50%',
                                                title: <div><h2>Detalhes do insumo - Cod. {data.code}</h2></div>,
                                                className: 'remove-margin',
                                                content: (
                                                    <div>
                                                        <Divider />
                                                        <p>Descrição: <b>{data.material_description}</b></p>
                                                        <p>Fornecedor: <b>{data.provider}</b></p>
                                                        <Divider />
                                                        <p>Unidade Compra: <b>{data.measure}</b></p>
                                                        <p>Unidade Consumo: <b>{data.consumption_measure}</b></p>
                                                        <p>Conversão: <b>{data.convert || 0}</b></p>
                                                        <p>
                                                            Fórmula de conversão:
                                                            <Popover content={formula ? stringFormulas[formula] : 'Sem fórmula padrão'}>
                                                                <b>{formulaToGetName ? `${formulaToGetName.get('name')}` : ''}</b>
                                                            </Popover>
                                                        </p>
                                                        <Divider />
                                                        <p>Custo de aquisição: <b>{getMaterialCost(data).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                                                        <p>Preço final: <b>{getTotalCost(data).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                                                        <Divider />
                                                    </div >
                                                )
                                            })
                                        }
                                    }}
                                />
                            </Popover>

                            <Popover content='Editar insumo'>
                                <Button
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({
                                            inputIndexToUpdate: `${inputGroupIndex}_${inputIndex}`,
                                            updateInputModal: true,
                                            form: { ...input }
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Deletar insumo'>
                                <Button
                                    icon='delete'
                                    onClick={() => {
                                        let _this = this
                                        Modal.confirm({
                                            iconType: 'exclamation-circle',
                                            title: 'Tem certeza que deseja excluir esse insumo?',
                                            content: 'Essa ação não poderá ser desfeita',
                                            onOk() {
                                                let inputFinishingGroups = _this.state.inputFinishingGroups
                                                inputFinishingGroups[inputGroupIndex].rows.splice(inputIndex, 1)
                                                _this.props.updateDescriptions(inputFinishingGroups)
                                            },
                                            okText: 'Sim',
                                            cancelText: 'Não, deixa pra lá'
                                        })
                                    }}
                                />
                            </Popover>
                        </ButtonGroup>
                    </div>
                </Col>
                <Divider />
            </Row>
        )
    }

    getFinishingData = (finishingId) => {
        return this.state.finishings.find(el => el.id === finishingId)
    }

    render() {

        return (
            <div>
                <Collapse>
                    {(this.state.inputFinishingGroups || []).map((inputFinishingGroup, inputFinishingGroupIndex) => {
                        const finishing = this.getFinishingData(inputFinishingGroup.finishing)
                        return (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <div className='inputCompositionInputGroupTitle'>
                                            {finishing && `${finishing.get("code")} - ${finishing.get("description")}`}
                                            &#160; &#160; &#160; &#160;
                                            <Popover content='Editar acabamento'>
                                                <Icon
                                                    type='edit'
                                                    onClick={() => {
                                                        let inputGroupFinishing = inputFinishingGroup.finishing
                                                        const _this = this
                                                        Modal.confirm({
                                                            iconType: 'edit',
                                                            title: 'Acabamento',
                                                            content: (
                                                                <div>
                                                                    <Form.Item label='Acabamento'>
                                                                        <Select
                                                                            defaultValue={inputFinishingGroup.finishing}
                                                                            onChange={e => {
                                                                                inputGroupFinishing = e
                                                                            }}
                                                                            style={{ width: '100%' }}
                                                                            placeholder='Acabamento'
                                                                        >
                                                                            {
                                                                                this.state.finishings.map(finishingToChoose => {
                                                                                    return (
                                                                                        <Select.Option value={finishingToChoose.id} key={finishingToChoose.id}>
                                                                                            {`${finishingToChoose.get("code")} - ${finishingToChoose.get("description")}`}
                                                                                        </Select.Option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </div>
                                                            ),
                                                            okText: 'Salvar',
                                                            cancelText: 'Cancelar',
                                                            onOk: () => {
                                                                let inputFinishingGroups = _this.state.inputFinishingGroups
                                                                inputFinishingGroups[inputFinishingGroupIndex].finishing = inputGroupFinishing
                                                                _this.props.updateDescriptions(inputFinishingGroups)
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Popover>
                                            &#160; &#160; &#160; &#160;

                                            <Popover content='Copiar'>
                                                <Icon
                                                    type='copy'
                                                    onClick={() => {
                                                        let inputGroupFinishing = null
                                                        const _this = this
                                                        Modal.confirm({
                                                            iconType: 'copy',
                                                            title: 'Duplicar',
                                                            content: (
                                                                <div>
                                                                    <Form.Item label='Acabamento'>
                                                                        <Select
                                                                            onChange={e => {
                                                                                inputGroupFinishing = e
                                                                            }}
                                                                            style={{ width: '100%' }}
                                                                            placeholder='Acabamento'
                                                                        >
                                                                            {
                                                                                this.state.finishings.map(finishingToChoose => {
                                                                                    return (
                                                                                        <Select.Option value={finishingToChoose.id} key={finishingToChoose.id}>
                                                                                            {`${finishingToChoose.get("code")} - ${finishingToChoose.get("description")}`}
                                                                                        </Select.Option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </div>
                                                            ),
                                                            okText: 'Salvar',
                                                            cancelText: 'Cancelar',
                                                            onOk: () => {
                                                                let inputFinishingGroups = _this.state.inputFinishingGroups
                                                                inputFinishingGroups.push({
                                                                    finishing: inputGroupFinishing,
                                                                    rows: [..._this.state.inputFinishingGroups[inputFinishingGroupIndex].rows]
                                                                })
                                                                _this.props.updateDescriptions(inputFinishingGroups)
                                                            }
                                                        })

                                                    }}
                                                />
                                            </Popover>
                                            &#160; &#160; &#160; &#160;

                                            <Popover content='Deletar'>
                                                <Icon
                                                    type='delete'
                                                    onClick={() => {
                                                        let _this = this
                                                        Modal.confirm({
                                                            iconType: 'exclamation-circle',
                                                            title: 'Tem certeza que deseja excluir esse grupo?',
                                                            content: 'Essa ação não poderá ser desfeita',
                                                            onOk() {
                                                                let inputFinishingGroups = _this.state.inputFinishingGroups
                                                                inputFinishingGroups.splice(inputFinishingGroupIndex, 1)
                                                                _this.props.updateDescriptions(inputFinishingGroups)
                                                            },
                                                            okText: 'Sim',
                                                            cancelText: 'Não, deixa pra lá'
                                                        })
                                                    }}
                                                />
                                            </Popover>
                                        </div>
                                        <div className='inputCompositionInputGroupSubtitle'>
                                            Preço Total da composição do acabamento: {this.props.calculateSubTotalCost(inputFinishingGroup.rows, this.state.inputs)}
                                        </div>
                                    </div>
                                }
                                key={inputFinishingGroupIndex}
                            >
                                {
                                    (inputFinishingGroup.rows || []).map((input, inputIndex) => {
                                        const inputObject = this.getInputById(input.specification)

                                        return <div key={`${inputFinishingGroupIndex}_${inputIndex}`}>{this.renderInput(inputObject, input, inputFinishingGroupIndex, inputIndex)}</div>
                                    })
                                }
                                <Button
                                    icon='plus'
                                    type='primary'
                                    style={{ marginTop: '10px' }}
                                    onClick={() => this.setState({ addInputModal: true, inputIndexToUpdate: `${inputFinishingGroupIndex}_0`, })}
                                >
                                    Adicionar insumo
                                </Button>
                            </Collapse.Panel>
                        )
                    })}
                </Collapse>

                <Button
                    icon='plus'
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                        let inputGroupFinishing = null
                        const _this = this
                        Modal.confirm({
                            iconType: 'edit',
                            title: 'Opção de acabamento',
                            content: (
                                <div>
                                    <Form.Item label='Acabamento'>
                                        <Select
                                            defaultValue={inputGroupFinishing}
                                            onChange={e => {
                                                inputGroupFinishing = e
                                            }}
                                            style={{ width: '100%' }}
                                            placeholder='Acabamento'
                                        >
                                            {
                                                this.state.finishings.map(finishingToChoose => {
                                                    return (
                                                        <Select.Option value={finishingToChoose.id} key={finishingToChoose.id}>
                                                            {`${finishingToChoose.get("code")} - ${finishingToChoose.get("description")}`}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            ),
                            okText: 'Adicionar',
                            cancelText: 'Cancelar',
                            onOk: () => {
                                let inputFinishingGroups = _this.state.inputFinishingGroups || []
                                inputFinishingGroups.push({
                                    finishing: inputGroupFinishing,
                                    rows: []
                                })
                                _this.props.updateDescriptions(inputFinishingGroups)
                            }
                        })
                    }}
                >
                    Adicionar opção de acabamento
                </Button>

                <InputCompositionModal
                    modalVisible={this.state.addInputModal}
                    closeModal={() => {
                        this.setState({
                            addInputModal: false,
                            form: {},
                            inputIndexToUpdate: null,
                        })
                    }}
                    form={this.state.form}
                    unitySelectArray={this.state.unitySelectArray}
                    addUpdateInput={async inputToAdd => {

                        const inputAlreadInInputArray = this.getInputById(inputToAdd.specification)
                        if (!inputAlreadInInputArray && inputToAdd.specification) {
                            const inputs = await this.getInputs([...this.state.inputs.map(input => input.id), inputToAdd.specification])
                            this.setState({ inputs })
                        }

                        let inputFinishingGroups = this.state.inputFinishingGroups
                        inputFinishingGroups[parseInt(this.state.inputIndexToUpdate.split('_')[0])].rows.push(inputToAdd)
                        this.props.updateDescriptions(inputFinishingGroups)
                    }}
                />

                <InputCompositionModal
                    modalVisible={this.state.updateInputModal}
                    closeModal={() => {
                        this.setState({
                            updateInputModal: false,
                            form: {},
                            inputIndexToUpdate: null,
                        })
                    }}
                    form={this.state.form}
                    unitySelectArray={this.state.unitySelectArray}
                    addUpdateInput={async inputToUpdate => {

                        const inputAlreadInInputArray = this.getInputById(inputToUpdate.specification)
                        if (!inputAlreadInInputArray && inputToUpdate.specification) {
                            const inputs = await this.getInputs([...this.state.inputs.map(input => input.id), inputToUpdate.specification])
                            this.setState({ inputs })
                        }

                        let inputFinishingGroups = this.state.inputFinishingGroups
                        inputFinishingGroups[parseInt(this.state.inputIndexToUpdate.split('_')[0])].rows[parseInt(this.state.inputIndexToUpdate.split('_')[1])] = inputToUpdate
                        this.props.updateDescriptions(inputFinishingGroups)
                    }}
                />
            </div>
        )
    }

}