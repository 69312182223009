const { default: contractModel } = require("../../../components-and-kits/sales-channel/suport-files/contract-model")
const React = require("react")
const { default: ReactQuill } = require("react-quill")

// Modulos para os campos de inserção de texto com formatação
let modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'link', { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'color': ['#F00', '#0F0', '#00F', '#000', '#FFF'] }]
    ],
}
let formats = [
    'header',
    'bold', 'italic', 'underline', 'link', 'align',
    'list', 'bullet',
    'color'
]

const contractFields = () => {
    return [
        [
            {
                key: 'contract',
                type: 'render',
                render: (state, module, node) => {
                    const proposal = JSON.parse(state.formRef.proposal || '{}')
                    const salesChannel = proposal.sales_channel
                    return (
                        <ReactQuill
                            value={state.formRef.contract || (salesChannel && salesChannel.defaultContract) || contractModel}
                            modules={modules}
                            formats={formats}
                            onChange={value => {
                                module.updateRefValue(node, value, 'contract')
                            }}
                        />
                    )
                },
                beforeSave: (module, parseObject, node) => {
                    parseObject.set('contract', module.state.formRef.contract)
                    return true;
                }
            }
        ]
    ]
}

export default contractFields