import React from 'react'
import InputCompositionTable from '../../component-kit/entities/input-composition-table'

const inputCompositionFields = (props) => {

    return [
        [
            {
                title: 'Código',
                key: 'product_code_in_input_composition_fields',
                type: "render",
                colSize: 8,
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {_.formRef.code}
                        </div>
                    );
                },
            }
        ],
        [
            {
                key: 'descriptions_render',
                type: 'render',
                title: 'Composição dos insumos',
                render: (_, form) => {
                    return (
                        <InputCompositionTable
                            inputGroups={_.formRef.descriptions}
                            convertionFormulas={props.convertionFormulas}
                            calculateUnitCost={props.calculateUnitCost}
                            calculateSubTotalCost={(v, inputs) => (props.calculateSubTotalCost(v, inputs) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            updateDescriptions={descriptions => {
                                _.formRef.descriptions = descriptions
                                form.state.invalidForm = false
                                form.forceUpdate()
                            }}
                        />
                    )
                }
            }
        ],
        [{ key: 'descriptions' }],
    ]
}

export default inputCompositionFields;