import React, { Component } from 'react';
import { Input, Form, Button, Icon, message, notification } from 'antd';
import logo from '../../images/logo.svg';
import { Link } from 'react-router-dom'
import Parse from 'parse';
import FooterToolbar from 'ant-design-pro/lib/FooterToolbar';
import './Login.css';
import VERSION from '../../environments/version'
import { ValidateEmail } from '../../utils/mask'

/**
 * Classe de Login.
 */
export default class LoginApp extends Component {

    state = {
        loading: false
    }

    /**
     * Renderização do componente Login.
     */
    render() {
        return (
            <div className="app-login">
                <div className="content-app-login">
                    <Form onSubmit={event => { this.makeLogin(event) }}>
                        <div className="image-form">
                            <img src={logo} alt="Logo Senai" />
                            <p style={{ fontSize: "15px", color: "rgba(0,0,0,.45)", margin: '10px 0 0px 0' }}>
                                Multidoor 4.0
                            </p>
                            <p style={{ fontSize: "10px", color: "rgba(0,0,0,.45)", margin: '0px 0 15px 0' }}>
                                {VERSION}
                            </p>
                        </div>
                        <Form.Item {...ValidateEmail(this.state.email || '')}>
                            <Input placeholder="Informe seu E-mail" type="text" required size="large"
                                ref={input => this.email = input}
                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input placeholder="Informe sua Senha" type="password" required size="large"
                                ref={input => this.password = input}
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        </Form.Item>
                        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                            Entrar <Icon type={!this.state.loading ? "login" : "loading"} />
                        </Button>

                        <Link style={{ marginTop: `10px`, float: `left` }} to="/recuperar-senha">Esqueci minha senha</Link>
                    </Form>
                </div>
                <FooterToolbar extra="Created by NTM - SENAI/AL" style={{ background: 'transparent', boxShadow: 'unset', border: 'none' }}>
                </FooterToolbar>
            </div>
        )
    }

    /**
     * Verifica se o usuário está ativo no db para realizar o login no sistema e salva a session no cache do navegador.
     * @param {event} event 
     */
    makeLogin(event) {
        this.setState(state => {
            return { loading: true }
        }, () => {
            Parse.User.logIn(this.email.input.value, this.password.input.value, {
                success: user => {
                    if (user.get("active")) {
                        window.app_user = user
                        window.localStorage.setItem("app_session_token", user.getSessionToken())
                        user.set("ultimo_acesso", new Date())
                        user.save(null, {
                            success: res => {
                                this.showSuccessLoginMessage()
                                if (window.location.pathname === '/')
                                    return this.props.history.push('/panel')

                                return window.location.reload()
                            },
                            error: error => {
                                this.showErrorMesage()
                            }
                        })
                    } else {
                        this.showErrorMesage()
                    }

                },
                error: error => {
                    this.showErrorMesage()
                }
            })
        })
        event.preventDefault()
    }

    /**
     * Function que mostra a mensagem de erro no login.
     */
    showErrorMesage() {
        message.error('Erro: E-mail e/ou senha inválidos.')
        this.setState(state => {
            return { loading: false }
        })
    }

    /**
     * Function que mostra a notificação de sucesso no login.
     */
    showSuccessLoginMessage() {
        notification.open({
            message: 'Bem vindo!',
            description: 'Seja bem vindo ao Multidoor4.0! Sistema de gestão da fábrica desenvolvido pelo Núcleo de Tecnologia Multimídia do SENAI Alagoas.',
            placement: 'bottomRight'
        });
    }
}
