import React from "react"
import AutomaticChooseRules from "../entities/automatic-choose-rules"

const automaticChooseRules = () => [
    [
        {
            key: 'automatic_choose_rules_render',
            type: 'render',
            render: (_, form) => {
                return <AutomaticChooseRules
                    form={_}
                    updateRules={rules => {
                        _.formRef.automatic_choose_rules = rules
                        form.state.invalidForm = false
                        form.forceUpdate()
                    }}
                />
            }
        }
    ],
    [
        {
            key: 'automatic_choose_rules',
        }
    ]
]

export default automaticChooseRules 