import React, { Fragment } from "react";
import { Icon, Tag } from "antd";
import CityUtilsComponent from "../../../utils/citys";

export default {
  formTabs: [
    {
      title: "Dados",
      fields: [
        [
          {
            title: "Nome",
            key: "name",
            type: "text",
            "element-attr": { required: true, prefix: <Icon type="tags" /> },
          },
          {
            title: "Distância Km",
            key: "distance",
            type: "text",
            "element-attr": { required: true },
          },
          // {
          //   title: "Custo fixo gerenciamento",
          //   key: "fixed_cost_management",
          //   type: "money",
          //   colSize: 6,
          //   "element-attr": { required: false },
          // },
        ],
        [
          {
            title: "Van (10m³)",
            key: "twenty",
            type: "money",
            "element-attr": { required: true },
          },
          {
            title: "Caminhão Toco (44m³)",
            key: "thirty",
            type: "money",
            "element-attr": { required: true },
          },
          {
            title: "Caminhão Truck (58m³)",
            key: "sixty",
            type: "money",
            "element-attr": { required: true },
          },
          {
            title: "Carreta (97m³)",
            key: "ninety",
            type: "money",
            "element-attr": { required: true },
          },
        ],
        [
          {
            title: "Cidades",
            key: "citys",
            type: "render",
            isListInput: true,
            render: (State, Form, node) => {
              let update = (value) => {
                Form.setState((state) => ({
                  invalidForm: false,
                  formRef: {
                    ...state.formRef,
                    [node.key]: value,
                  },
                }));
              };
              return (
                <CityUtilsComponent
                  onChange={(listValue) => update(listValue)}
                  value={
                    State.formRef[node.key]
                  } /*alreadyEdited={() => Form.setState((state) => ({...state, invalidForm: false}))}*/
                />
              );
            },
          },
        ],
      ],
    },
    {
      title: "Mobilização",
      fields: [
        [
          {
            key: "mobilization",
            type: "dynamic-input-list",
            fields: [
              [
                {
                  title: "Mobilização",
                  key: "mobilization",
                  type: "select",
                  options: [
                    { label: "B2B", value: "b2b" },
                    { label: "B2C", value: "b2c" },
                  ],
                  colSize: "5",
                  "element-attr": { required: true, mode: "default" },
                },
                {
                  title: "Qtd de kits",
                  key: "kits",
                  type: "number",
                  colSize: "4",
                  "element-attr": { required: true },
                },
                {
                  title: "Valor",
                  key: "value",
                  type: "money",
                  colSize: "7",
                  "element-attr": { required: true },
                },
              ],
            ],
          },
        ],
      ],
    },
    {
      title: 'Custo fixo gerenciamento',
      fields: [
        [
          {
            key: "fixed_cost_management",
            type: "dynamic-input-list",
            fields: [
              [
                {
                  title: "Kits (limite máximo)",
                  key: "kits",
                  type: "number",
                  colSize: "4",
                  "element-attr": { required: true },
                },
                {
                  title: "Instalação (%)",
                  key: "instalation_percent",
                  type: "number",
                  colSize: "3",
                  "element-attr": { required: true },
                },
                {
                  title: "Semanas",
                  key: "instalation_time",
                  type: "number",
                  colSize: "3",
                  "element-attr": { required: true, step: 1 },
                },
                {
                  title: "Adicional local",
                  key: "additional",
                  type: "money",
                  colSize: "7",
                  "element-attr": { required: true },
                },
                {
                  title: "Valor mínimo",
                  key: "minimum",
                  type: "money",
                  colSize: "7",
                  "element-attr": { required: true },
                },
              ],
            ],
          },
        ],
      ],
    }
  ],
  submit: {
    collection: "Region",
  },
  className: "region-component",
  schema: [
    {
      title: "Nome",
      key: "name",
      dataIndex: "name",
      width: "150px",
      type: "text",
    },
    {
      title: "Distância Km",
      key: "distance",
      dataIndex: "distance",
      width: "100px",
      type: "text",
    },
    {
      title: "Van (10m³)",
      key: "twenty",
      dataIndex: "twenty",
      type: "number",
      width: "130px",
      render: (v) => toMoney(v || 0),
    },
    {
      title: "Caminhão Toco (44m³)",
      key: "thirty",
      dataIndex: "thirty",
      type: "number",
      width: "130px",
      render: (v) => toMoney(v || 0),
    },
    {
      title: "Caminhão Truck (58m³)",
      key: "sixty",
      dataIndex: "sixty",
      type: "number",
      width: "130px",
      render: (v) => toMoney(v || 0),
    },
    {
      title: "Carreta (97m³)",
      key: "ninety",
      dataIndex: "ninety",
      type: "number",
      width: "130px",
      render: (v) => toMoney(v || 0),
    },
    {
      title: "Cidades",
      key: "citys",
      dataIndex: "citys",
      render: (regions) => {
        let regionsArray = (regions || []).map((city) => {
          return city.name;
        });
        regionsArray = regionsArray.sort();
        return (regionsArray || []).map((city, i) => {
          return (
            <Fragment>
              <Tag key={i}>{city}</Tag>
              <br />
            </Fragment>
          );
        });
      },
    },
    // {
    //   title: 'Maior Distância',
    //   key: 'distance',
    //   dataIndex: 'citys',
    //   render: regions => <Tag>{ regions.reduce((km, r) => (r.size > km) ? r.size : km , 0) }km</Tag>
    // }
  ],
  "title-module": "Transporte e Logistica",
  "title-navigation": (
    <div>
      <Icon type="environment" /> Transporte e Logistica
    </div>
  ),
  module: "Region",
  "router-base": "/panel/transporte-logistica",
};

const toMoney = (v) =>
  v.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
