import React from 'react'
import { generateCode } from './input-providers-functions';

const registrationDataFields = (props) => {
    return [
        [
            {
                "key": "code",
                "type": "render",
                'colSize': 4,
                "element-attr": { required: false, disabled: true },
                "render": (_, Form, node) => {
                    return (
                        <div style={{ fontSize: 'larger' }}>
                            <b>
                                Número: {Form.state.formRef.code || ''}
                            </b>
                        </div>
                    )
                },
                beforeSave: async (Form, ParseObject, node) => {

                    let code = await generateCode();
                    if (!ParseObject.get('code')) {
                        ParseObject.set('code', code);
                    }
                    return true;
                }
            },
        ],
        [
            {
                "title": "Nome",
                "key": "name",
                "type": "text",
                'colSize': 4,
                "element-attr": { required: true }
            },
            {
                "title": "Razão Social",
                "key": "social_reason",
                "type": "text",
                'colSize': 8,
                "element-attr": { required: true }
            },
            {
                "title": "CNPJ",
                "key": "cnpj",
                "type": "text",
                'colSize': 4,
                "element-attr": { required: true },
                "maskToValue": { mask: "xx.xxx.xxx/xxxx-xx", onlyNumber: true }
            },
            {
                "title": "Inscrição Estadual",
                "key": "state_registration",
                "type": "text",
                'colSize': 4,
                "element-attr": { required: false }
            },
            {
                "title": "Home page",
                "key": "home_page",
                "type": "text",
                'colSize': 4,
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Grupo de provedores",
                "key": "provider_group",
                "type": "select",
                "options": props.providerGroups,
                'colSize': 4,
                "element-attr": { "required": true, 'mode': 'default' },
            },
            {
                "title": "Endereço",
                "key": "address",
                "type": "text",
                'colSize': 6,
                "element-attr": { required: false }
            },
            {
                "title": "Cidade",
                "key": "city",
                "type": "text",
                'colSize': 4,
                "element-attr": { required: false }
            },
            {
                "title": "CEP",
                "key": "cep",
                "type": "text",
                'colSize': 3,
                "element-attr": { required: false },
                "maskToValue": { mask: "xxxxx-xxx", onlyNumber: true }
            },
            {
                "title": "Estado",
                "key": "state",
                "type": "text",
                'colSize': 2,
                "element-attr": { required: false }
            },
            {
                type: 'document',
                key: 'cnpj_receita',
                title: 'CNPJ Receita',
                'colSize': 2,
                'element-attr': { required: false }
            },
        ],
    ]
}

export default registrationDataFields