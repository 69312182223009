import { Button } from 'antd';
import React from 'react'
import VinculatedModalClass from '../entities/vinculated-modal'
import Parse from 'parse';

const finalPricingFields = (props) => {
    return [
        [
            {
                title: 'Código do Produto',
                key: 'product_code_in_final_pricing_fields',
                type: "render",
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {_.formRef.product_code}
                        </div>
                    );
                },
                beforeSave: async (Form, ParseObject, node) => {
                    if (!Form.state.formRef.vinculated_component)
                        await ParseObject.set('vinculated_component', null)
                    else {
                        var vinculatedComponentToRef = Parse.Object.extend("ComponenteKit");
                        var pointerToVinculatedComponent = new vinculatedComponentToRef();
                        pointerToVinculatedComponent.id = Form.state.formRef.vinculated_component;
                        await ParseObject.set('vinculated_component', pointerToVinculatedComponent)
                    }

                    return true;
                }
            }
        ],
        [
            {
                key: 'vinculated_component_button',
                type: 'render',
                options: [],
                colSize: 4,
                render: (_, form) => {

                    return (
                        <div style={{ lineHeight: '40px' }}>
                            {!form.state.formRef.vinculated_component ?
                                <VinculatedModalClass
                                    setVinculatedComponent={async (vinculatedComponentReturned) => {
                                        form.state.formRef.vinculated_component = vinculatedComponentReturned.id
                                        form.state.formRef.vinculated_component_name = vinculatedComponentReturned.get('description_component')

                                        const manHour = (props.manHours || []).find((manHour) => manHour.id === form.state.formRef.unity_value_hh)
                                        if (manHour)
                                            props.localVinculatedPrice = await props.calculateTotal({ ...vinculatedComponentReturned.toJSON(), ref_unity_value_hh: manHour.get('calculatedTotal') });

                                        form.state.invalidForm = false
                                        form.forceUpdate();
                                    }}
                                /> :
                                <Button
                                    size='large'
                                    type='danger'
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        form.state.formRef.vinculated_component = null
                                        form.state.formRef.vinculated_component_name = null
                                        form.state.formRef.fact_multiple = null
                                        props.localVinculatedPrice = null

                                        form.state.invalidForm = false
                                        form.forceUpdate();
                                    }}
                                >Remover componente</Button>
                            }
                        </div>
                    )
                },
            },
            {
                title: 'Fator Multiplicador',
                key: 'fact_multiple',
                type: 'number',
                loaded: false,
                default: 1,
                colSize: 3,
                'element-attr': { required: false },
            },
            {
                title: 'Componente Vinculado',
                key: 'vinculated_component_name',
                type: "render",
                colSize: 10,
                render: (_, form, value) => {
                    return (
                        <div>{form.state.formRef.vinculated_component_name}</div>
                    );
                },
            },
            {
                title: 'Margem Vinculada',
                key: 'margin-relation',
                type: "render",
                colSize: 3,
                render: (form) => {
                    let value = '0';
                    if (form.formRef.vinculated_component) {
                        const manHour = (props.manHours || []).find((manHour) => manHour.id === form.formRef.unity_value_hh)
                        const componentTotal = props.calculateTotal({ ...form.formRef, ref_unity_value_hh: manHour && manHour.get('calculatedTotal') });
                        value = (((props.localVinculatedPrice * (form.formRef.fact_multiple || 1)) - componentTotal) * 100 / componentTotal).toFixed(2)
                    }
                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold' }}>
                            {value}
                        </div>
                    );
                },
            },
            {
                title: 'Preço Vinculado',
                key: 'price_relation',
                type: "render",
                colSize: 3,
                render: (form) => {
                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold' }}>
                            {(props.localVinculatedPrice * (form.formRef.fact_multiple || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    );
                },
                'element-attr': { required: false },
            },
            // {
            //     key: 'vinculated_component',
            //     type: 'pointer',
            //     colSize: 1,
            //     options: [],
            //     'relation-select': {
            //         class: 'ComponenteKit',
            //         label: 'description_component',
            //         value: 'objectId',
            //         key: 'objectId'
            //     },
            //     'element-attr': { required: false, mode: 'default', style: { display: 'none' } }
            // },
        ],
        [
            {
                title: 'Preço Tabela Fábrica',
                key: 'price_industry',
                type: "render",
                colSize: 8,
                render: (form) => {
                    let total = 0;

                    const manHour = (props.manHours || []).find((manHour) => manHour.id === form.formRef.unity_value_hh)
                    if (manHour) {
                        let component = { ...form.formRef }
                        if (form.formRef.finishing_to_calculate_price)
                            component["descriptions"] = [
                                ...component["descriptions"],
                                ...component["finishing_options_inputs"].filter(el => el.finishing === form.formRef.finishing_to_calculate_price)
                            ]

                        total = props.calculateTotal({ ...component, ref_unity_value_hh: manHour.get('calculatedTotal') })
                    }

                    if (form.formRef.vinculated_component)
                        total = (props.localVinculatedPrice * (form.formRef.fact_multiple || 1));

                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold', fontSize: 'larger' }}>
                            {(total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    );
                },
            },
        ]
    ]
}

export default finalPricingFields