import React, { Component } from 'react';
import { Layout, Menu, Icon, Avatar } from 'antd';
import './Panel.css';
import { Route, Link } from 'react-router-dom';
import Parse from 'parse';
import Auth from "../../auth/Auth";
import VERSION from '../../environments/version';
import Checklist from '../../components/checklist/checklistContainer';
import NotificationCenter from '../../components/notification-center/NotificationCenter.js';
import * as Modules from '../share/moduleComponents';
import { sideMenu } from './side-menu';

const { Header, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;

/**
 * Classe do painel.
 */
export default class Panel extends Component {

  state = {
    collapsed: false,
    userImg: null,
  }

  /**
   * Minimiza e maximiza o painel lateral.
   */
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    }, () => this.saveCollapsed());
  }

  /**
   * Salva status do painel no perfil do usuários.
   */
  saveCollapsed() {
    const user = Parse.User.current()

    user.set('collapsed', this.state.collapsed)
    user.save()
  }

  componentWillMount() {
    let collapse = Parse.User.current().get('collapsed') || false
    this.setState({ collapsed: collapse })
  }

  /**
   * Verifica se o usuário tem permissão de acesso ao item do menu e o exibe.
   * @param {*} Item Item do menu a ser exibido.
   * @param {Array} action Lista de permissões para aquele menu
   * @returns Item do menu ou null
   */
  MenuItem(Item, action = []) {
    if (this.Auth.hasAction(action)) return Item;
  }

  renderMenu = (menuData) => {
    if (menuData.isUser) {
      return this.MenuItem(
        <Menu.Item key={menuData.key}>
          <Link to='/panel/usuarios'>
            <Icon type={menuData.icon} />
            Gerenciar usuários
          </Link>
        </Menu.Item>, [menuData.permission || '', '*']
      )
    } else if (menuData.submenus) {
      return this.MenuItem(
        <SubMenu key={menuData.key}
          title={
            <span>
              <Icon type={menuData.icon} theme="outlined" />
              <span>{menuData.title}</span>
            </span>
          }
        >
          {menuData.submenus.map(el => this.renderMenu(el))}
        </SubMenu>, [...menuData.submenus.map(el => el.permission).filter(el => el), '*']
      )
    } else {
      return this.MenuItem(
        (
          <Menu.Item key={menuData.key}>
            <Link to={(menuData.module && menuData.module['router-base']) || '/panel/in-dev'}>
              <Icon type={menuData.icon} />
              {(menuData.module && menuData.module['title-module']) || 'Em desenvolvimento'}
            </Link>
          </Menu.Item>
        ), [menuData.permission || '', '*']
      )
    }
  }

  renderComponent = (menuData) => {
    if (menuData.isUser) {
      return (
        <Route
          path="/panel/usuarios/"
          render={(props) => this.renderRoutedComponent(Modules.UserModule, props)}
        />
      )
    } else if (menuData.submenus) {
      return menuData.submenus.map(el => this.renderComponent(el))
    } else if (menuData.module && menuData.module['router-base']) {
      return (
        <Route
          path={(menuData.module && menuData.module['router-base'])}
          render={(props) => this.renderRoutedComponent(Modules.RenderModule(menuData.module), props)}
        />
      )
    }
  }

  constructor(props, attr) {
    super(props)
    this.User = Parse.User.current()
    this.Auth = new Auth(this.User)
    this.Auth.init((data) => {
      if (this.User.get('photo'))
        this.setState({ userImg: `${Parse.serverURL}/files/${Parse.applicationId}/${this.User.get('photo')}` })
      this.forceUpdate()
    })
  }

  /**
   * Aciona as opções do menu.
   * @param {*} event Evento do menu.
   * @returns Ação da opção escolhida.
   */
  menuClick(event) {
    if (event.key === 'logout') {
      this.logout()
    }
  }

  /**
   * Desconecta usuário do sistema e o encamiha para o Login.
   */
  logout() {
    Parse.User.logOut().then(l => {
      Parse.Session.destroyAll().then(() => {
        window.location.pathname = '/'
      })
    })
  }

  /**
   * Renderiza o componente escolhido no menu.
   * @param {*} __component Componente a ser renderizado.
   * @param {*} props 
   * @returns Componente.
   */
  renderRoutedComponent(__component, props) {
    let Component = __component(props)
    return Component
  }

  /**
   * Renderização do componente Painel
   */
  render() {
    return (
      <Layout className="layout-panel" >

        {/* Menu Lateral */}
        <Sider
          className="sidebar"
          style={{
            overflow: 'auto',
            height: '100vh',
          }}
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}>
          <div className="logo-space">
            <div className="logo"></div>
          </div>

          <Menu
            className="sidebar"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="dark"
            inlineCollapsed={this.state.collapsed}>

            <Menu.Item key="panel">
              <Link to="/panel">
                <Icon type="dashboard" />
                <span>Painel</span>
              </Link>
            </Menu.Item>

            {sideMenu.map(el => {
              return this.renderMenu(el)
            })}

          </Menu>

          {/* Exibição da versão do sistema */}
          <div
            style={{
              position: 'fixed', bottom: '0', fontSize: '10px', color: 'rgba(255, 255, 255, 0.65)',
              width: '250px', textAlign: 'center', padding: '10px', transform: 'translateX(-45%) translateY(-65%) rotate(-90deg)'
            }}
          >{VERSION}</div>
        </Sider>

        <Layout>

          {/* Top Bar */}
          <Header theme="dark" style={{ background: '#fff', padding: 0 }}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={['2']}
              style={{ lineHeight: '63px', float: 'right' }}
              onClick={(event) => this.menuClick(event)}>
              <Menu.SubMenu title={
                <div>
                  <Avatar
                    style={{ verticalAlign: 'middle', marginRight: '10px' }}
                    size="large"
                    src={this.state.userImg}
                  >
                    {
                      !this.state.userImg ? this.User.getUsername()[0].toUpperCase() : ''
                    }
                  </Avatar>
                  {this.User.get("name")}
                </div>
              } >
                <Menu.Item key="logout" ><Icon type="logout" />Sair</Menu.Item>
              </Menu.SubMenu>
            </Menu>
            <NotificationCenter />
          </Header>

          {/* Área de exibição dos Componentes */}
          <Content style={{ margin: '24px 16px', minHeight: 280 }}>

            {sideMenu.map(el => {
              return this.renderComponent(el)
            })}

            <Route
              path="/panel/checklist/"
              render={(props) => <Checklist router-props={props} />}
            />

            <Route
              path="/panel/in-dev/"
              render={(props) => {
                return (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <h2>Em desenvolvimento...</h2>
                  </div>
                )
              }} />
          </Content>
        </Layout>
      </Layout >
    );
  }
}