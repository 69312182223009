import Parse from 'parse'
import { findAll } from '../../../../utils/db_functions'

const generateCode = async () => {

    const query = new Parse.Query('InputProvider').descending('code')
    const lastInputProvider = await query.first()

    if (lastInputProvider) {
        let intCode = parseInt(lastInputProvider.get('code')) + 1
        return `${intCode < 10 ? '00' : intCode < 100 ? '0' : ''}${intCode}`
    } else return '001'
}

const getInputsToPutInOptions = async () => {
    let inputs = await findAll('Input', 30000, null, null, 'code')
    return inputs
}

const getInputOrders = async () => {
    let inputOrders = await findAll('InputOrder', 100000)
    return inputOrders
}

const getInputsProviderGroups = async () => {
    let providers = await findAll('InputProviderGroup', null, null, null, 'code')
    return providers.map((provider) => {
        return {
            label: `${provider.get('code')} - ${provider.get('products')}`,
            value: provider.id
        }
    });
}

const calculatePQ12 = (nfAvaliationArray) => {
    let finalGrade = 0

    let finalDeadline = nfAvaliationArray.reduce((acc, el) => { return acc + (el.deadline || 0) }, 0) / nfAvaliationArray.length;
    let finalAttendance = nfAvaliationArray.reduce((acc, el) => { return acc + (el.attendance || 0) }, 0) / nfAvaliationArray.length;
    let finalQuality = nfAvaliationArray.reduce((acc, el) => { return acc + (el.quality || 0) }, 0) / nfAvaliationArray.length;
    let finalAdm = nfAvaliationArray.reduce((acc, el) => { return acc + (el.adm || 0) }, 0) / nfAvaliationArray.length;

    finalGrade = ((finalDeadline + finalAttendance + finalQuality + finalAdm) / 4);

    let avaliation = {
        performace: 'Ruim',
        status: 'NQ'
    };

    if (finalGrade >= 9) avaliation = { performace: 'Ótimo', status: 'QP' }
    else if (finalGrade >= 7) avaliation = { performace: 'Bom', status: 'Q' }
    else if (finalGrade >= 5) avaliation = { performace: 'Regular', status: 'QR' }

    return {
        grade: finalGrade.toFixed(2),
        performace: avaliation['performace'],
        status: avaliation['status']
    }
}

export {
    generateCode,
    getInputsToPutInOptions,
    getInputsProviderGroups,
    getInputOrders,
    calculatePQ12,
}