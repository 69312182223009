import { getUnityFormule } from "../../../../utils/unity"
import Parse from 'parse'
import { generateCotationCode } from "../../input-order-price-quote/components/input-order-price-quote-functions"
import { notification } from "antd"
const { findWhere, save } = require("../../../../utils/db_functions")

const getInputsByProvidersOfOrders = async (orders) => {
    const proposalIds = (orders || []).map(order => order.proposalRef && order.proposalRef.objectId).filter(proposalId => proposalId)

    const proposals = await findWhere('Proposal', 'objectId', proposalIds)

    let components = []
    for (let proposalIndex = 0; proposalIndex < proposals.length; proposalIndex++) {
        const proposal = proposals[proposalIndex]

        const proposalComponents = await getProposalComponents(proposal)
        components = [...components, proposalComponents].flat()
    }

    let inputs = []
    
    components = components.filter(el => el)
    
    let inputsLoadedPercent = '0.0';
    notification.open({ 
        key: 'input-get-progress', 
        message: 'Carregando os insumos dos componentes de cada pedido', 
        description: `Isso pode levar alguns minutos (${inputsLoadedPercent}%)...` ,
        placement: 'topLeft',
        duration: 0,
    });
    
    for (let componentIndex = 0; componentIndex < components.length; componentIndex++) {
        inputsLoadedPercent = (componentIndex / components.length * 100).toFixed(1);
        notification.open({ 
            key: 'input-get-progress', 
            message: 'Carregando os insumos dos componentes de cada pedido', 
            description: `Isso pode levar alguns minutos (${inputsLoadedPercent}%)...`,
            placement: 'topLeft',
            duration: 0,
        });
        
        const component = components[componentIndex]

        const componentInputs = await getComponentInputs(component)
        inputs = [...inputs, componentInputs].flat()
    }

    notification.destroy();

    const inputsByProviders = groupByProvider(inputs)

    return inputsByProviders.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
}

const getProposalComponents = async (proposal) => {
    const productBlocks = proposal.get('product_blocks');

    let components = []
    const kits = await findWhere(
        'Kit',
        'objectId',
        [
            ...new Set(
                productBlocks.map(block => {
                    return block.products.map(product =>
                        product['product_code']
                    ).flat()
                }).flat(),
            )
        ],
        ['comp_mounting', 'instalation_kit']
    )
    const componentsKits = await findWhere(
        'ComponenteKit',
        'objectId',
        [
            ...new Set(
                productBlocks.map(block => {
                    return block.products.map(el => getProductComponentIds(el).ids).flat()
                }).flat(),
            )
        ],
        ['unity_value_hh'], null, null, null, 200
    )

    const finishingComponentLabels = ['finishing_sheet', 'finishing_marco', 'fixed_aliz', 'adjustable_aliz']
    const finishingOptionLabels = ['sheet_option_finishing', 'marco_option_finishing', 'fixed_aliz_option_finishing', 'adjustable_aliz_option_finishing']

    for (let blockIndex = 0; blockIndex < productBlocks.length; blockIndex++) {
        const block = productBlocks[blockIndex];

        for (let productIndex = 0; productIndex < block.products.length; productIndex++) {
            const product = block.products[productIndex];

            const productComponents = getComponentsFromProductKit(product)
            for (let productComponentIndex = 0; productComponentIndex < Object.keys(productComponents).length; productComponentIndex++) {
                const productComponentKey = Object.keys(productComponents)[productComponentIndex];
                const productComponent = productComponents[productComponentKey];
                if (productComponent) {
                    const compComponent = componentsKits.find(comp => comp.id === productComponent);

                    const finishingIndex = finishingComponentLabels.findIndex(e => e === productComponentKey)
                    if (finishingIndex !== -1 && product[finishingOptionLabels[finishingIndex]]) {
                        const finishingId = product[finishingOptionLabels[finishingIndex]]
                        const finishingInputGroup = ((compComponent && compComponent.get('finishing_options_inputs')) || []).find(el => el.finishing === finishingId)
                        if (finishingInputGroup)
                            await compComponent.set('descriptions', [...compComponent.get('descriptions'), { description: "Acabamento", rows: finishingInputGroup.rows }])
                    }

                    if (compComponent) components.push(compComponent);
                }
            }

            const kit = kits.find(el => el.id === product.product_code)
            if(kit) {
                let comp_mounting = kit.get('comp_mounting')
                await comp_mounting.set('amount', product.amount)
                components.push(comp_mounting)
    
                let instalation_kit = kit.get('instalation_kit')
                await instalation_kit.set('amount', product.amount)
                components.push(instalation_kit)
            }
        }

        components =
            [
                ...components,
                ...getComponentsWithAmount(block.products, componentsKits, kits),
            ].flat()
    }

    return components
}

const getProductComponentIds = (product) => {
    const arrayComponents = ['finishing_sheet', 'finishing_sheet_inactive', 'finishing_marco', 'finishing_aliz', 'finishing_aliz_adjustable',]
    const arrayFerr = ['conj_fechadura', 'hardware_door_lock', 'hardware_puller', 'hardware_bar_anti_panic', 'hardware_aerial_spring', 'hardware_accessories', 'hardware_accessories_02', 'hardware_accessories_03', 'hardware_door_handle']
    const arrayFerrQtd = ['lock_quantity', 'lock_quantity', 'hardware_puller_amount', 'hardware_bar_anti_panic_amount', 'hardware_aerial_spring_amount', 'hardware_accessories_amount', 'hardware_accessories_amount_02', 'hardware_accessories_amount_03', 'lock_quantity']
    return {
        ids: [...arrayComponents, ...arrayFerr].map(el => product[el]).filter(el => el),
        hardwareIds: arrayFerr.map((el, index) => {
            return {
                field: el,
                id: product[el],
                index
            }
        }).filter(el => el.id),
        arrayFerr,
        arrayFerrQtd
    }
}

const getComponentsWithAmount = (products, components, kits) => {

    let componentsToReturn = []

    for (let productIndex = 0; productIndex < products.length; productIndex++) {
        const product = products[productIndex];

        const idsAndComponentsArray = getProductComponentIds(product)

        componentsToReturn = [
            ...componentsToReturn,
            idsAndComponentsArray.ids.map(componentId => {
                let componentWithAmount = components.find(el => el.id === componentId)
                if (componentWithAmount) {
                    let productAmount = product.amount

                    const hardware = idsAndComponentsArray.hardwareIds.find(el => el.id === componentId)
                    if (hardware) {
                        const hardwareAmount = product[idsAndComponentsArray.arrayFerrQtd[hardware.index]]
                        productAmount = productAmount * (hardwareAmount || 1)
                    } else if (componentWithAmount.get('group') === 'Folha') {
                        const kit = kits.find(el => el.id === product.product_code)
                        if (kit) productAmount = productAmount * (kit.get('sheet_qtd') || 1)
                    }

                    componentWithAmount.set('amount', productAmount)
                }
                return componentWithAmount
            })
        ].flat()
    }

    return componentsToReturn
}

const getComponentInputs = async (component, formulas = null) => {
    const inputsWithAmountAndDimensions = component.get('descriptions').map(subgroup => subgroup.rows).flat()

    const inputs = await findWhere('Input', 'objectId', inputsWithAmountAndDimensions.map(input => input.specification))

    return inputsWithAmountAndDimensions.map(input => {
        const inputDetails = inputs.find(el => el.id === input.specification)
        const inputJson = inputDetails.toJSON()

        const totalConsumo = getUnityFormule(inputJson.measure, input.unity, formulas)({
            amount: input.amount_component || 0,
            width: input.width_component || 1,
            thickness: input.thickness_component || 1,
            length: input.length_component || 1,
            convert: inputJson.convert || 1,
        })

        return {
            ...input,
            provider: inputJson.provider,
            details: {
                material_description: inputJson.material_description,
                material: inputJson.material,
                ipi: inputJson.ipi,
            },
            totalConsumo: totalConsumo * (component.get('amount') || 1),
        }
    })
}

const groupByProvider = (allInputs) => {
    const providers = [...new Set(allInputs.map(input => input.provider))]

    const inputsByProviders = providers.map(provider => {

        const providerInputs = allInputs.filter(input => input.provider === provider)
        const uniqueInputs = [...new Set(providerInputs.map(input => input.specification))]

        let inputs = []
        uniqueInputs.forEach(input => {
            let inputToAdd = providerInputs.find(el => el.specification === input)
            const totalConsumo = providerInputs.filter(el => el.specification === input).reduce((prev, current) => current.totalConsumo, 0)

            inputs = [...inputs, {
                ...inputToAdd,
                totalConsumo,
            }]
        })

        return {
            provider,
            inputs
        }
    })

    return inputsByProviders.map(provider => {

        return {
            ...provider,
        }
    })
}

const generateInputOrderQuote = async (data) => {
    const providerList = await findWhere("InputProvider", "name", [data.provider])
    const providerParseObject = providerList && providerList[0]

    const providerRef = Parse.Object.extend("InputProvider")
    let provider = new providerRef()
    provider.id = providerParseObject.id

    const cotation_code = await generateCotationCode()

    const inputOrderQuote = {
        cotation_code,
        provider,
        inputs: data.inputs.map(input => {
            return {
                "input": input.specification,
                "measure": input.origin_measure,
                "unit_value": input.details.material,
                "ipi": input.details.ipi,
                "total": input.details.material * input.totalConsumo,
                "qtd": input.totalConsumo
            }
        })
    }

    await save("InputOrderPriceQuote", inputOrderQuote)

    return cotation_code;
}

const getComponentsFromProductKit = (product) => {
    const {
        finishing_sheet,
        finishing_sheet_inactive,
        fixed_aliz,
        adjustable_aliz,
        finishing_marco,
        lock_quantity,
        conj_fechadura,
        hardware_door_lock,
        lock_door_lock,
        hardware_puller,
        hardware_puller_amount,
        hardware_bar_anti_panic,
        hardware_bar_anti_panic_amount,
        hardware_aerial_spring,
        hardware_aerial_spring_amount,
        hardware_accessories,
        hardware_accessories_amount,
        hardware_accessories_02,
        hardware_accessories_amount_02,
        hardware_accessories_03,
        hardware_accessories_amount_03,
        hardware_door_handle,
        hardware_door_handle_amount,
        accessory,
    } = product

    let prod = {
        finishing_sheet,
        finishing_sheet_inactive,
        fixed_aliz,
        adjustable_aliz,
        finishing_marco,
        lock_quantity,
        conj_fechadura,
        hardware_door_lock,
        lock_door_lock,
        hardware_puller,
        hardware_puller_amount,
        hardware_bar_anti_panic,
        hardware_bar_anti_panic_amount,
        hardware_aerial_spring,
        hardware_aerial_spring_amount,
        hardware_accessories,
        hardware_accessories_amount,
        hardware_accessories_02,
        hardware_accessories_amount_02,
        hardware_accessories_03,
        hardware_accessories_amount_03,
        hardware_door_handle,
        hardware_door_handle_amount,
        accessory,
    }

    // Iniserir lista de paineis, caso exista
    let count = 0
    if (product.wall_panel) {
        product.wall_panel.forEach(snap => {
            prod[`panel_wall_module_${count}`] = snap.panel_wall_module;
            prod[`panel_wall_qtd_${snap.panel_wall_module}`] = snap.wall_quantity;
            count += 1;
        })
    }

    return prod
}

export {
    getInputsByProvidersOfOrders,
    generateInputOrderQuote,
}