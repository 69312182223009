import React, { Component, Fragment } from "react";
import { Input, InputNumber, Button, Row, Col, Form, Select, DatePicker, notification, Upload, Icon, Switch, Divider, Modal, Table } from 'antd';
import ReactListInput from 'react-list-input';
import { CreateMask, CreatePhoneMask, ValidateEmail } from '../../../../utils/mask';
import RelationSelect from '../utils/relation';
import _, { isArray } from 'lodash';
import moment from 'moment';
import Parse from "parse";
import { getFileIcon } from "../../../../utils/general_functions";

/**
 * Classe responsável por criar uma lista de inputs editáveis.
 */
export default class DynamicInputList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
            formFields: props.formFields,
            loaded: {},
        }
        this.TypeInput = this.props.type === 'number' ? InputNumber : Input
    }

    componentWillReceiveProps(props) {
        this.setState({
            value: props.value,
            formFields: props.formFields
        });
    }

    /**
     * Renderiza a lista de inputs number ou text.
     * @param {ReactListInput} ReactListInput Pacote react-list-input. 
     * @returns Lista de inputs. 
     */
    Item({ decorateHandle, removable, onChange, onRemove, value }) {
        const changeValue = (node, value) => {

            if (node['element-attr'] && node['element-attr']['beforeChangeValue'] && (typeof node['element-attr']['beforeChangeValue'] === 'function'))
                value = node['element-attr']['beforeChangeValue'](node, value);

            onChange(value);
        }

        return (
            <div
                style={{
                    // paddingLeft: 30,
                    // paddingRight: 30,
                    paddingTop: 0,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderColor: '#e8e8e8',
                    clear: 'both',
                }}
            >
                <Row gutter={24}>
                    <Col span={1} style={{ height: '94px', paddingTop: '50px', textAlign: 'center' }}>
                        {!this.props.preventMoveItem ?
                            decorateHandle(
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon type="drag" size="large" style={{ cursor: 'move', fontSize: 18, margin: '0 16px' }} />
                                </div>
                            )
                            : null
                        }

                    </Col>

                    <Col span={21}>
                        <div>
                            {this.state.formFields.map((row, index) => {
                                return (

                                    <Row gutter={24} className="row-form-item" style={{ clear: 'both' }}>

                                        {row.map((childRow, childIndex) => {
                                            let title = childRow.title ? `${childRow.title}` : <div>&nbsp;</div>;
                                            let elAttrs = {};
                                            let size = childRow.colSize;
                                            if (childRow.hasOwnProperty('element-attr')) {
                                                for (let attr in childRow['element-attr']) {
                                                    // if (['beforeChange', 'onChangeOverride', 'afterChange'].includes(attr)) continue;
                                                    let n = childRow['element-attr'][attr]
                                                    // if (typeof n === 'function')
                                                    elAttrs[attr] = (typeof n === 'function') ? n(this, childRow, value) : n;
                                                }
                                            }

                                            switch (childRow.type) {
                                                case "header":
                                                    return (
                                                        <div span={size} key={`${childRow.key}_${index}_${childIndex}`} className={childRow.className || ""}>
                                                            <h2>{childRow.title}</h2>
                                                        </div>
                                                    );
                                                case "text":
                                                case "password":
                                                case "email":
                                                    let validateAttr = {}
                                                    if (childRow.type === 'email') validateAttr = ValidateEmail(value[childRow.key] || '')
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} {...validateAttr}>
                                                                <Input
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    placeholder={childRow.title}
                                                                    type={childRow.type}
                                                                    defaultValue={value[childRow.key]}
                                                                    // value={
                                                                    //     childRow.hasOwnProperty('maskToValue') ?
                                                                    //         childRow.maskToValue['phoneMask'] ? CreatePhoneMask(value[childRow.key]) : CreateMask(childRow.maskToValue['mask'], value[childRow.key], childRow.maskToValue['mask'].length, childRow.maskToValue['onlyNumber'])
                                                                    //         : (value[childRow.key] || '')
                                                                    // }
                                                                    size="large"
                                                                    {...elAttrs}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );
                                                case "textarea":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title}>
                                                                <Input.TextArea
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    placeholder={childRow.title}
                                                                    type={childRow.type}
                                                                    defaultValue={value[childRow.key] || ""}
                                                                    // value={value[childRow.key] || ""}
                                                                    size="large"
                                                                    {...elAttrs}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    )

                                                case "money":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <InputNumber
                                                                    min={0}
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    size="large"
                                                                    placeholder='R$ 0,00'
                                                                    step={childRow.step || "0.1"}
                                                                    defaultValue={value[childRow.key]}
                                                                    value={childRow.hasOwnProperty('maskToValue') ? CreateMask(childRow.maskToValue['mask'], value[childRow.key], childRow.maskToValue['mask'].length, childRow.maskToValue['onlyNumber']) : value[childRow.key]}
                                                                    {...childRow["element-attr"]}
                                                                    formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                                    parser={value => {
                                                                        let decimals = value.split(',')[1]
                                                                        if (decimals && decimals.length > 2) {
                                                                            decimals = decimals.substring(0, 2)
                                                                            value = `${value.split(',')[0]},${decimals}`
                                                                        }
                                                                        return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    )

                                                case "number":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <InputNumber
                                                                    min={0}
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    size="large"
                                                                    placeholder={childRow.title}
                                                                    step={childRow.step || "0.1"}
                                                                    defaultValue={value[childRow.key]}
                                                                    value={childRow.hasOwnProperty('maskToValue') ? CreateMask(childRow.maskToValue['mask'], value[childRow.key], childRow.maskToValue['mask'].length, childRow.maskToValue['onlyNumber']) : value[childRow.key]}
                                                                    {...childRow["element-attr"]}
                                                                //type='number'
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "select":
                                                    if (childRow.hasOwnProperty('optionConfig'))
                                                        this.loadContentFromConfigs(childRow)

                                                    let opts = (childRow.render) ? childRow.render(childRow.options, value) : childRow.options.map((opt, key) => {
                                                        return (
                                                            <Select.Option key={key} value={opt.value}>
                                                                {opt.label}
                                                            </Select.Option>
                                                        );
                                                    })

                                                    const copyValue = _.clone(value[childRow.key])
                                                    let local_value = value[childRow.key]

                                                    if (Array.isArray(local_value)) {
                                                        copyValue.sort((a, b) => a - b)
                                                        local_value = copyValue
                                                    }

                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <Select
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    showSearch
                                                                    autoClearSearchValue={false}
                                                                    notFoundContent="Não encontrado"
                                                                    size="large"
                                                                    placeholder={childRow.title}
                                                                    defaultValue={local_value}
                                                                    // value={local_value}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children
                                                                            .toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    {...elAttrs}>
                                                                    {opts}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "date-picker":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <DatePicker
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = event.format();
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    clear={false}
                                                                    size="large"
                                                                    placeholder={childRow.title}
                                                                    format="DD/MM/YYYY"
                                                                    defaultValue={
                                                                        value[childRow.key] ?
                                                                            (
                                                                                moment.isMoment(value[childRow.key]) ? value[childRow.key] :
                                                                                    typeof value[childRow.key] === 'string' ?
                                                                                        moment(value[childRow.key]) : moment(value[childRow.key]['iso'])
                                                                            ) :
                                                                            null
                                                                    }
                                                                    value={
                                                                        value[childRow.key] ?
                                                                            (moment.isMoment(value[childRow.key]) ? value[childRow.key] : moment(value[childRow.key])) :
                                                                            null
                                                                    }
                                                                    {...elAttrs}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "switch":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <Switch
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    size="large"
                                                                    checkedChildren={<Icon type="check" theme="outlined" />}
                                                                    unCheckedChildren={<Icon type="close" theme="outlined" />}
                                                                    defaultChecked={value[childRow.key]}
                                                                    // checked={value[childRow.key]}
                                                                    // value={this.state.formRef[node.key]}
                                                                    {...elAttrs}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "multi-select":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <Select
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    showSearch
                                                                    autoClearSearchValue={false}
                                                                    notFoundContent="Não encontrado"
                                                                    size="large"
                                                                    mode="multiple"
                                                                    defaultValue={value[childRow.key]}
                                                                    value={value[childRow.key]}
                                                                    placeholder={childRow.title}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children
                                                                            .toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    {...elAttrs}>
                                                                    {childRow.options.map((opt, key) => {
                                                                        return (
                                                                            <Select.Option key={key} value={opt.value}>
                                                                                {opt.label}
                                                                            </Select.Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "pointer":
                                                case "relation-old":
                                                    (!childRow.hasOwnProperty("loadContentRelationOverride")) ? this.loadContentRelation(childRow, childRow._ignoreLoad || false) : childRow.loadContentRelationOverride(this, childRow)
                                                    let options = childRow.options
                                                    let list = childRow['_parseObjectOptionsList']
                                                    // let defaultValue = (childRow.type === 'pointer') ? undefined : []
                                                    if ((!value[childRow.key] && childRow['_parseObjectOptionsList']) || childRow.hasOwnProperty('filter')) {
                                                        if (childRow.hasOwnProperty('filter') && childRow.filter) {
                                                            list = childRow.filter(this, childRow['_parseObjectOptionsList'])
                                                        }
                                                        options = list.map((item, key) => {
                                                            let data = item.toJSON();
                                                            let label = (typeof childRow['relation-select'].label === 'function') ? childRow['relation-select'].label(data) : data[childRow["relation-select"].label]
                                                            return {
                                                                key: data[childRow["relation-select"].key],
                                                                label,
                                                                value: data[childRow["relation-select"].value]
                                                            };
                                                        });
                                                    }

                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <Select
                                                                    // mode="multiple"
                                                                    style={{ width: "100%" }}
                                                                    placeholder="Selecione..."
                                                                    size="large"
                                                                    showSearch
                                                                    filterOption={(input, option) => (option.props.children || "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    defaultValue={value[childRow.key]}
                                                                    // value={value[childRow.key] || defaultValue}
                                                                    {...elAttrs}
                                                                    notFoundContent="Não encontrado">
                                                                    <Select.Option value={null}>
                                                                        Selecione...
                                                                    </Select.Option>
                                                                    {options.map((opt, key) => {
                                                                        return (
                                                                            <Select.Option key={key} value={opt.value}>
                                                                                {opt.label}
                                                                            </Select.Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "relation":
                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <RelationSelect
                                                                    form={this}
                                                                    node={childRow}
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = this.eventValue(event);
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    // value={value[childRow.key] || []}
                                                                    defaultValue={value[childRow.key] || []}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                case "document":
                                                    return (
                                                        <Fragment>

                                                            <Col span={size / 2} key={childRow.key} className={childRow.className || ""}>
                                                                <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                    <Upload
                                                                        node={childRow}
                                                                        beforeUpload={(file, fileList) => this.beforeUploadDoc(childRow, file, fileList)}
                                                                        onChange={event => {
                                                                            let localValue = value;
                                                                            let fileToPut = new Parse.File(event.file.name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), event.file);
                                                                            localValue[childRow.key] = fileToPut;
                                                                            changeValue(childRow, localValue);
                                                                            // this.updateRefValue(node, new Parse.File(event.file.name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), event.file) )
                                                                        }}
                                                                        defaultValue={value[childRow.key] || []}
                                                                        // value={value[childRow.key] || []}
                                                                        fileList={[]}
                                                                        className="upload-list-inline"
                                                                        {...elAttrs}>
                                                                        <Button >
                                                                            <Icon type="upload" /> Selecionar
                                                                        </Button>
                                                                    </Upload>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={size / 2} key={`${childRow.key}_docname`} style={{ paddingTop: 40 }}>
                                                                {value[childRow.key] ?
                                                                    typeof value[childRow.key].name === 'string' ?
                                                                        <a target='blank' href={value[childRow.key].url}>
                                                                            {(value[childRow.key].name.substring((value[childRow.key].name.indexOf('_') + 1),)) || ''}
                                                                        </a>
                                                                        :
                                                                        <span>{value[childRow.key]._name}</span>
                                                                    : null
                                                                }
                                                            </Col>
                                                        </Fragment>
                                                    )

                                                case "files":
                                                    const inputRef = Date.now().toString()
                                                    const columns = [
                                                        {
                                                            title: 'Nome',
                                                            dataIndex: 'name',
                                                            key: 'name',
                                                        },
                                                        {
                                                            title: 'Enviado por',
                                                            dataIndex: 'owner',
                                                            key: 'owner',
                                                            width: '150px',
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: 'Data do envio',
                                                            dataIndex: 'date',
                                                            key: 'date',
                                                            width: '150px',
                                                            align: 'center',
                                                        },
                                                        {
                                                            key: 'action',
                                                            width: '150px',
                                                            align: 'center',
                                                            render: (text, record) => {
                                                                return <span
                                                                    style={{ cursor: 'pointer', color: 'red' }}
                                                                    onClick={() => {
                                                                        Modal.confirm({
                                                                            title: 'Tem certeza que deseja exlcuir esse arquivo?',
                                                                            content: 'Essa ação não poderá ser desfeita',
                                                                            onOk() {
                                                                                let localValue = value;
                                                                                localValue[childRow.key] = (localValue[childRow.key] || [])
                                                                                localValue[childRow.key].splice(record.index, 1);
                                                                                changeValue(childRow, localValue)
                                                                                notification.success({
                                                                                    message: "Documento removido com sucesso!",
                                                                                    description: "Essa alteração só será exibida após salvar a pasta do cliente"
                                                                                });
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    Remover
                                                                </span>
                                                            },
                                                        },
                                                    ];

                                                    return (
                                                        <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                            <Row>
                                                                <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} /*ref={`${childRow.key}_form_item`}*/ label={title} >
                                                                    <Button
                                                                        onClick={() => document.getElementById(`${this.props.module.collection}_${childRow.key}_${inputRef}`).click()}
                                                                    >
                                                                        <Icon type="upload" /> Enviar arquivo
                                                                    </Button>
                                                                    <input
                                                                        type='file'
                                                                        id={`${this.props.module.collection}_${childRow.key}_${inputRef}`}
                                                                        onChange={(e) => {
                                                                            // Get file and transform in a parse file
                                                                            let fileToPut = new Parse.File(e.target.files[0].name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), e.target.files[0]);

                                                                            // Get local path to open file before save
                                                                            let localPath = window.URL.createObjectURL(e.target.files[0]);

                                                                            // Get user and date
                                                                            let currentUser = Parse.User.current();
                                                                            let date = new Date();

                                                                            // Put file in filelist
                                                                            let localValue = value;
                                                                            localValue[childRow.key] = (localValue[childRow.key] || [])
                                                                            localValue[childRow.key].push({
                                                                                file: fileToPut,
                                                                                fileType: e.target.files[0].type,
                                                                                localPath: localPath,
                                                                                userAndDate: {
                                                                                    user: currentUser.get('name'),
                                                                                    date: date.toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }),
                                                                                }
                                                                            });
                                                                            changeValue(childRow, localValue)
                                                                            notification.success({
                                                                                message: "Documento adicionado com sucesso!",
                                                                                description: "Os novos documentos serão exibidos após salvar a pasta do cliente"
                                                                            });
                                                                        }}
                                                                        style={{ display: 'none' }}
                                                                    />
                                                                </Form.Item>
                                                            </Row>
                                                            <Row>
                                                                <Table
                                                                    id={`${this.props.module.collection}_${childRow.key}_${inputRef}_table`}
                                                                    locale={{ emptyText: 'Sem arquivos' }}
                                                                    columns={columns}
                                                                    dataSource={(value[childRow.key] || []).map((item, index) => {

                                                                        // Temporary: show file named as doc
                                                                        let file = item.file || item.doc;

                                                                        return {
                                                                            'name': <a href={!file._source ? file.url : item.localPath} rel="noopener noreferrer" target="_blank">
                                                                                <Icon
                                                                                    type={getFileIcon(item.fileType).type}
                                                                                    theme="filled"
                                                                                    style={{ marginRight: 10, color: getFileIcon(item.fileType).color, fontSize: '25px' }}
                                                                                /> {file._name || file.name.split('_')[1]}
                                                                            </a>,
                                                                            'owner': item.userAndDate && item.userAndDate.user,
                                                                            'date': item.userAndDate && item.userAndDate.date,
                                                                            'index': index
                                                                        }
                                                                    })}
                                                                />
                                                            </Row>
                                                        </Col>
                                                    )

                                                case "render":
                                                    let editForm = (e) => {
                                                        let localValue = value;
                                                        localValue[childRow.key] = e;
                                                        changeValue(childRow, localValue)
                                                    }

                                                    return (
                                                        <Col span={size} key={childRow.key} className={(childRow.className || "") + " render-col"}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                {
                                                                    childRow.render(
                                                                        this,
                                                                        childRow,
                                                                        editForm,
                                                                        value
                                                                    )
                                                                }
                                                            </Form.Item>
                                                        </Col>
                                                    )

                                                case "dynamic-input-list":
                                                    return (
                                                        <Col style={{ marginTop: 15 }} span={size} key={childRow.key} className={(childRow.className || "") + " dynamic-input-list"}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} >
                                                                {typeof title === 'string' ? <Divider>{title}</Divider> : null}
                                                                <DynamicInputList
                                                                    {...elAttrs}
                                                                    module={this.props.module}
                                                                    onChange={(event) => {
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = event;
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    formFields={childRow.fields}
                                                                    value={value[childRow.key]}
                                                                    // node={childRow}
                                                                    configs={this.props.configs}
                                                                    preventMoveItem={true}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    );

                                                default:
                                                    return <div key={childRow.key} />;
                                            }

                                        })}

                                    </Row>

                                )
                            })}
                        </div>

                    </Col>

                    <Col span={2} style={{ height: '94px', paddingTop: '50px', textAlign: 'center' }}>
                        <Button
                            onClick={removable ? onRemove : x => x}
                            shape="circle" icon="delete"
                            type='danger'
                            style={{
                                cursor: removable ? 'pointer' : 'not-allowed',
                            }}
                        ></Button>
                    </Col>
                </Row>

            </div>
        )
    }

    /**
     * Adiciona input a lista.
     * @param {ReactListInput} ReactListInput Pacote react-list-input.
     * @returns Novo input. 
     */
    StagingItem({ value, onAdd, canAdd, add, onChange }) {
        value = value || {};

        const changeValue = (node, value) => {

            if (node['element-attr'] && node['element-attr']['beforeChangeValue'] && (typeof node['element-attr']['beforeChangeValue'] === 'function'))
                value = node['element-attr']['beforeChangeValue'](node, value);

            onChange(value);
        }

        return (
            <div
                style={{
                    marginBottom: 15,
                }}
            >
                <div
                    style={{
                        marginBottom: 15,
                        // backgroundColor: '#e8e8e8',
                        paddingLeft: 30,
                        paddingRight: 30,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#e8e8e8',
                    }}
                >

                    <div>
                        {this.state.formFields.map((row, index) => {
                            return (

                                <Row gutter={24} className="row-form-item" style={{ clear: 'both', marginBottom: 30 }}>

                                    {row.map((childRow, childIndex) => {
                                        let title = childRow.title ? `${childRow.title}` : <div>&nbsp;</div>;
                                        let elAttrs = {};
                                        let size = childRow.colSize;

                                        if (childRow.hasOwnProperty('element-attr')) {
                                            for (let attr in childRow['element-attr']) {
                                                // if (['beforeChange', 'onChangeOverride', 'afterChange'].includes(attr)) continue;
                                                let n = childRow['element-attr'][attr]
                                                elAttrs[attr] = (typeof n === 'function') ? n(this, childRow, value) : n;
                                            }
                                        }

                                        switch (childRow.type) {
                                            case "header":
                                                return (
                                                    <div span={size} key={`${childRow.key}_${index}_${childIndex}`} className={childRow.className || ""}>
                                                        <h2>{childRow.title}</h2>
                                                    </div>
                                                );
                                            case "text":
                                            case "password":
                                            case "email":
                                                let validateAttr = {}
                                                if (childRow.type === 'email') validateAttr = ValidateEmail(value[childRow.key] || '')

                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} {...validateAttr}>
                                                            <Input
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                placeholder={childRow.title}
                                                                value={
                                                                    childRow.hasOwnProperty('maskToValue') ?
                                                                        childRow.maskToValue['phoneMask'] ? CreatePhoneMask(value[childRow.key]) : CreateMask(childRow.maskToValue['mask'], value[childRow.key], childRow.maskToValue['mask'].length, childRow.maskToValue['onlyNumber'])
                                                                        : (value[childRow.key] || '')}
                                                                size="large"
                                                                {...elAttrs}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            case "textarea":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title}>
                                                            <Input.TextArea
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                placeholder={childRow.title}
                                                                type={childRow.type}
                                                                value={value[childRow.key] || ""}
                                                                size="large"
                                                                {...elAttrs}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )

                                            case "money":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <InputNumber
                                                                min={0}
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                size="large"
                                                                placeholder='R$ 0,00'
                                                                step={childRow.step || "0.1"}
                                                                value={childRow.hasOwnProperty('maskToValue') ? CreateMask(childRow.maskToValue['mask'], value[childRow.key], childRow.maskToValue['mask'].length, childRow.maskToValue['onlyNumber']) : value[childRow.key]}
                                                                {...childRow["element-attr"]}
                                                                formatter={value => `R$ ${value}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                                parser={value => {
                                                                    let decimals = value.split(',')[1]
                                                                    if (decimals && decimals.length > 2) {
                                                                        decimals = decimals.substring(0, 2)
                                                                        value = `${value.split(',')[0]},${decimals}`
                                                                    }
                                                                    return value.replace('R', '').replace(/\$\s?/g, '').split('.').join('').replace(',', '.')
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )

                                            case "number":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <InputNumber
                                                                min={0}
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                size="large"
                                                                placeholder={childRow.title}
                                                                step={childRow.step || "0.1"}
                                                                value={childRow.hasOwnProperty('maskToValue') ? CreateMask(childRow.maskToValue['mask'], value[childRow.key], childRow.maskToValue['mask'].length, childRow.maskToValue['onlyNumber']) : value[childRow.key]}
                                                                {...childRow["element-attr"]}
                                                            //type='number'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "select":
                                                if (childRow.hasOwnProperty('optionConfig'))
                                                    this.loadContentFromConfigs(childRow)

                                                let opts = (childRow.render) ? childRow.render(childRow.options, value) : childRow.options.map((opt, key) => {
                                                    return (
                                                        <Select.Option key={key} value={opt.value}>
                                                            {opt.label}
                                                        </Select.Option>
                                                    );
                                                })

                                                const copyValue = _.clone(value[childRow.key])
                                                let local_value = value[childRow.key]

                                                if (Array.isArray(local_value)) {
                                                    copyValue.sort((a, b) => a - b)
                                                    local_value = copyValue
                                                }

                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <Select
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                showSearch
                                                                autoClearSearchValue={false}
                                                                notFoundContent="Não encontrado"
                                                                size="large"
                                                                placeholder={childRow.title}
                                                                value={local_value}
                                                                filterOption={(input, option) =>
                                                                    option.props.children
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                {...elAttrs}>
                                                                {opts}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "date-picker":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <DatePicker
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = event.format();
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                size="large"
                                                                placeholder={childRow.title}
                                                                format="DD/MM/YYYY"
                                                                value={value[childRow.key] ? moment(value[childRow.key]) : null}
                                                                {...elAttrs}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "switch":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <Switch
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                size="large"
                                                                checkedChildren={<Icon type="check" theme="outlined" />}
                                                                unCheckedChildren={<Icon type="close" theme="outlined" />}
                                                                checked={value[childRow.key]}
                                                                value={value[childRow.key]}
                                                                {...elAttrs}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "multi-select":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <Select
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                showSearch
                                                                autoClearSearchValue={false}
                                                                notFoundContent="Não encontrado"
                                                                size="large"
                                                                mode="multiple"
                                                                value={value[childRow.key]}
                                                                placeholder={childRow.title}
                                                                filterOption={(input, option) =>
                                                                    option.props.children
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                {...elAttrs}>
                                                                {childRow.options.map((opt, key) => {
                                                                    return (
                                                                        <Select.Option key={key} value={opt.value}>
                                                                            {opt.label}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "pointer":
                                            case "relation-old":
                                                (!childRow.hasOwnProperty("loadContentRelationOverride")) ? this.loadContentRelation(childRow, childRow._ignoreLoad || false) : childRow.loadContentRelationOverride(this, childRow)
                                                let options = childRow.options
                                                let list = childRow['_parseObjectOptionsList']
                                                let defaultValue = (childRow.type === 'pointer') ? undefined : []
                                                if ((!value[childRow.key] && childRow['_parseObjectOptionsList']) || childRow.hasOwnProperty('filter')) {
                                                    if (childRow.hasOwnProperty('filter') && childRow.filter) {
                                                        list = childRow.filter(this, childRow['_parseObjectOptionsList'])
                                                    }
                                                    options = list.map((item, key) => {
                                                        let data = item.toJSON();
                                                        let label = (typeof childRow['relation-select'].label === 'function') ? childRow['relation-select'].label(data) : data[childRow["relation-select"].label]
                                                        return {
                                                            key: data[childRow["relation-select"].key],
                                                            label,
                                                            value: data[childRow["relation-select"].value]
                                                        };
                                                    });
                                                }

                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: "100%" }}
                                                                placeholder="Selecione..."
                                                                size="large"
                                                                showSearch
                                                                filterOption={(input, option) => (option.props.children || "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                value={value[childRow.key] || defaultValue}
                                                                {...elAttrs}
                                                                notFoundContent="Não encontrado">
                                                                <Select.Option value={null}>
                                                                    Selecione...
                                                                </Select.Option>
                                                                {options.map((opt, key) => {
                                                                    return (
                                                                        <Select.Option key={key} value={opt.value}>
                                                                            {opt.label}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "relation":
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            <RelationSelect
                                                                form={this}
                                                                node={childRow}
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = this.eventValue(event);
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                value={value[childRow.key] || []}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            case "document":
                                                return (
                                                    <Fragment>

                                                        <Col span={size / 2} key={childRow.key} className={childRow.className || ""}>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                                <Upload
                                                                    node={childRow}
                                                                    beforeUpload={(file, fileList) => this.beforeUploadDoc(childRow, file, fileList)}
                                                                    onChange={event => {
                                                                        let localValue = value;
                                                                        let fileToPut = new Parse.File(event.file.name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), event.file);
                                                                        localValue[childRow.key] = fileToPut;
                                                                        changeValue(childRow, localValue);
                                                                        // this.updateRefValue(node, new Parse.File(event.file.name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), event.file) )
                                                                    }}
                                                                    value={value[childRow.key] || []}
                                                                    fileList={[]}
                                                                    className="upload-list-inline"
                                                                    {...elAttrs}>
                                                                    <Button >
                                                                        <Icon type="upload" /> Selecionar
                                                                    </Button>
                                                                </Upload>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={size / 2} key={`${childRow.key}_docname`} style={{ paddingTop: 40 }}>
                                                            {value[childRow.key] ?
                                                                typeof value[childRow.key].name === 'string' ?
                                                                    <a target='blank' href={value[childRow.key].url}>
                                                                        {(value[childRow.key].name.substring((value[childRow.key].name.indexOf('_') + 1),)) || ''}
                                                                    </a>
                                                                    :
                                                                    <span>{value[childRow.key]._name}</span>
                                                                : null
                                                            }
                                                        </Col>
                                                    </Fragment>
                                                )

                                            case "files":

                                                const columns = [
                                                    {
                                                        title: 'Nome',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                    },
                                                    {
                                                        title: 'Enviado por',
                                                        dataIndex: 'owner',
                                                        key: 'owner',
                                                        width: '150px',
                                                        align: 'center',
                                                    },
                                                    {
                                                        title: 'Data do envio',
                                                        dataIndex: 'date',
                                                        key: 'date',
                                                        width: '150px',
                                                        align: 'center',
                                                    },
                                                    {
                                                        key: 'action',
                                                        width: '150px',
                                                        align: 'center',
                                                        render: (text, record) => {
                                                            return <span
                                                                style={{ cursor: 'pointer', color: 'red' }}
                                                                onClick={() => {
                                                                    Modal.confirm({
                                                                        title: 'Tem certeza que deseja exlcuir esse arquivo?',
                                                                        content: 'Essa ação não poderá ser desfeita',
                                                                        onOk() {
                                                                            let localValue = value;
                                                                            localValue[childRow.key] = (localValue[childRow.key] || [])
                                                                            localValue[childRow.key].splice(record.index, 1);
                                                                            changeValue(childRow, localValue)
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                Remover
                                                            </span>
                                                        },
                                                    },
                                                ];
                                                return (
                                                    <Col span={size} key={childRow.key} className={childRow.className || ""}>
                                                        <Row>
                                                            <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} /*ref={`${childRow.key}_form_item`}*/ label={title} >
                                                                <Button
                                                                    onClick={() => document.getElementById(`${this.props.module.collection}_${childRow.key}_staging`).click()}
                                                                >
                                                                    <Icon type="upload" /> Enviar arquivo
                                                                </Button>
                                                                <input
                                                                    type='file'
                                                                    id={`${this.props.module.collection}_${childRow.key}_staging`}
                                                                    onChange={(e) => {
                                                                        // Get file and transform in a parse file
                                                                        let fileToPut = new Parse.File(e.target.files[0].name.split('.').slice(0, -1).join('.').normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), e.target.files[0]);

                                                                        // Get local path to open file before save
                                                                        let localPath = window.URL.createObjectURL(e.target.files[0]);

                                                                        // Get user and date
                                                                        let currentUser = Parse.User.current();
                                                                        let date = new Date();

                                                                        // Put file in filelist
                                                                        let localValue = value;
                                                                        localValue[childRow.key] = (localValue[childRow.key] || [])
                                                                        localValue[childRow.key].push({
                                                                            file: fileToPut,
                                                                            fileType: e.target.files[0].type,
                                                                            localPath: localPath,
                                                                            userAndDate: {
                                                                                user: currentUser.get('name'),
                                                                                date: date.toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }),
                                                                            }
                                                                        });
                                                                        changeValue(childRow, localValue);
                                                                    }}
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </Form.Item>
                                                        </Row>
                                                        <Row>
                                                            <Table
                                                                locale={{ emptyText: 'Sem arquivos' }}
                                                                columns={columns}
                                                                dataSource={(value[childRow.key] || []).map((item, index) => {

                                                                    // Temporary: show file named as doc
                                                                    let file = item.file || item.doc;

                                                                    return {
                                                                        'name': <a href={!file._source ? file.url : item.localPath} rel="noopener noreferrer" target="_blank">
                                                                            <Icon
                                                                                type={getFileIcon(item.fileType).type}
                                                                                theme="filled"
                                                                                style={{ marginRight: 10, color: getFileIcon(item.fileType).color, fontSize: '25px' }}
                                                                            /> {file._name || file.name.split('_')[1]}
                                                                        </a>,
                                                                        'owner': item.userAndDate && item.userAndDate.user,
                                                                        'date': item.userAndDate && item.userAndDate.date,
                                                                        'index': index
                                                                    }
                                                                })}
                                                            />
                                                        </Row>
                                                    </Col>
                                                )

                                            case "render":
                                                let editForm = (e) => {
                                                    let localValue = value;
                                                    localValue[childRow.key] = e;
                                                    changeValue(childRow, localValue)
                                                }

                                                return (
                                                    <Col span={size} key={childRow.key} className={(childRow.className || "") + " render-col"}>
                                                        <Form.Item required={_.get(childRow, ['element-attr', 'required'], false)} label={title} >
                                                            {
                                                                childRow.render(
                                                                    this,
                                                                    childRow,
                                                                    editForm,
                                                                    value
                                                                )
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                )

                                            case "dynamic-input-list":
                                                return (
                                                    <Col span={size} key={childRow.key} className={(childRow.className || "") + " dynamic-input-list"}>
                                                        <Form.Item required={false}>
                                                            {typeof title === 'string' ? <Divider>{title}</Divider> : null}
                                                            <DynamicInputList
                                                                {...elAttrs}
                                                                module={this.props.module}
                                                                onChange={(event) => {
                                                                    let localValue = value;
                                                                    localValue[childRow.key] = event;
                                                                    changeValue(childRow, localValue);
                                                                }}
                                                                formFields={childRow.fields}
                                                                value={value[childRow.key] || []}
                                                                node={childRow}
                                                                configs={this.props.configs}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                );

                                            default:
                                                return <div key={childRow.key} />;
                                        }

                                    })}

                                </Row>

                            )
                        })}
                    </div>

                    <div style={{ clear: 'both' }} />
                </div>
                <Button
                    onClick={(canAdd && value) ? onAdd : undefined}
                    disabled={!canAdd}
                    type='primary'
                >Adicionar</Button>

            </div>

        )
    }

    /**
     * Inseri o valor do input no form.
     * @param {*} value Valor a ser inserido no form.
     */
    onChange(value) {
        this.setState(() => {
            return { value }
        }, () => {
            this.props.onChange(value)
        })
    }

    /**
     * Checa se o event do input traz o value direto ou através do target.
     * @param {event} event Evento retornado pelo onChange do input.
     * @returns Value inserido no input.
     */
    eventValue(event) {
        if (event === "") return ""
        if (typeof event === 'object' && !isArray(event))
            return event.target.value

        // return isNaN(event) ? null : event
        return event
    }

    /**
     * Carrega os dados relacionados.
     * @param {Object} node Field relation do documento de configuração.
     * @param {Boolean} ignoreLoaded Ignorar o carregamento das options.
     * @todo Resolver multiples requests.
     */
    loadContentRelation(node, ignoreLoaded = false) {
        if (ignoreLoaded) return false;

        if (node.preventFirstLoad || (node.hasOwnProperty('_inLoad') && node['_inLoad']) || (node.hasOwnProperty('_loaded') && node['_loaded'])) return false;

        if (this.state.loaded[node.key] && !ignoreLoaded) return false;
        let ParseQuery = new Parse.Query(
            new Parse.Object.extend(node["relation-select"].class)
        );

        if (node["relation-select"].hasOwnProperty('where')) {
            let whereQ = { ...node['relation-select'].where }
            for (let attr in whereQ) {
                if (typeof whereQ[attr] === 'function') whereQ[attr] = whereQ[attr](this, ParseQuery)
            }
            ParseQuery._where = whereQ;
        }

        if (node['relation-select'].hasOwnProperty('orderBy')) {
            ParseQuery.ascending(node['relation-select'].orderBy)
        }

        ParseQuery.limit(10000)
        node['_inLoad'] = true;
        ParseQuery.find({
            success: response => {
                node.options = response.map((item, key) => {
                    let data = item.toJSON();
                    let label = (typeof node['relation-select'].label === 'function') ? node['relation-select'].label(data) : data[node["relation-select"].label]
                    return {
                        key: data[node["relation-select"].key],
                        label,
                        value: data[node["relation-select"].value]
                    };
                });
                node['_loaded'] = true;
                node["_parseObjectOptionsList"] = response;
                let merge = {}
                if (node.hasOwnProperty('loadFirstValue') && node.loadFirstValue) {
                    merge = {
                        [node.key]: node.options.length > 0 ? node.options[0].key : null
                    }
                }
                this.setState((state) => {
                    return {
                        formRef: {
                            ...state.formRef,
                            ...merge
                        },
                        loaded: {
                            ...this.state.loaded,
                            [node.key]: true
                        }
                    }
                }, () => {
                    node['_inLoad'] = false;
                })
            },
            error: err => {
                notification.error({
                    title: "Erro, ao carregar dados do select.",
                    message: err
                });
            }
        });
    }

    /**
     * Exibe o documento
     * @param {Object} node Field do documento de configuração.
     * @param {File} file Arquivo.
     * @param {FileList} fileList Lista de arquivos.
     */
    beforeUploadDoc(node, file, fileList) {
        let formRef = Object.assign({}, this.state.formRef)
        formRef[node.key] = {
            file: file,
            fileList: fileList,
            new: true,
        }
        this.setState(state => {
            return {
                formRef
            }
        })

        return false
    }

    /**
     * Carrega os dados do módulo Configs na lista do campo select.
     * @param {Object} node Field do documento de configuração com atributo optionConfig.
     */
    loadContentFromConfigs(node) {
        // configs has been loaded on permission loader
        if (this.props.configs) {
            let configs = this.props.configs[node.optionConfig.name]

            if(!node.optionConfig.arrayWithId){
                node.options = (configs ? configs.value : []).map(v => { 
                    return { label: v, value: v } 
                })
            } else node.options = (configs ? configs.array_with_id : [])

            node.loaded = true;
        }
    }

    renderList = () => {
        return <ReactListInput
            initialStagingValue=''
            onChange={value => this.onChange(value)}
            ItemComponent={this.Item.bind(this)}
            maxItems={1000}
            StagingComponent={this.StagingItem.bind(this)}
            value={this.state.value || {}}
        />
    }

    /**
     * Renderização do componente.
     */
    render() {
        return this.renderList();
    }
}
