import React from 'react';
import { Icon, Button, notification, Modal, Input, message, Popover } from 'antd';
import Parse from 'parse';
import { findAll, findById, getCurrentUser, save } from '../../../utils/db_functions';
import CommentModal from "./entities/comment-modal.js";
import OrderFolderTree from './entities/order-folder-tree';
import ButtonGroup from 'antd/lib/button/button-group';

let surveys = [];

let techDocsOptions = [];
const getTechDocs = async (_) => {
	const user = await getCurrentUser()

	let tech_docs = await findAll('OrderDocs')

	const allTechDocs = tech_docs.map(docGroup => {
		return (docGroup.get('docs') || []).map(doc => {
			return {
				doc: {
					file: {
						name: doc.doc_description,
						url: doc.doc._url,
					},
					localPath: null,
					docName: doc.doc_description,
					userAndDate: {
						user: user.get('name'),
						date: new Date().toLocaleDateString('pt-BR')
					},
					isALink: false
				},
				typologies: doc.typologies,
				isRequired: doc.doc_is_required,
			}
		})
	}).flat()

	techDocsOptions = allTechDocs
}

const addAllTechDocs = async (form) => {
	const order = await findById('Order', form.state.formRef.objectId)
	const proposal = order ? JSON.parse(order.get("proposal")) : null
	const typologies = proposal ?
		proposal.product_blocks.map(block => {
			const blockTypologies = block.products.map(product => {
				return product.typology.trim()
			})
			return blockTypologies
		}).flat()
		: []

	let techDocs = []

	techDocsOptions.forEach(doc => {
		if (doc.isRequired &&
			(
				!doc.typologies ||
				doc.typologies.some(r => typologies.includes(r.trim()))
			)
		) techDocs.push(doc.doc)
	})

	return techDocs
}

const setDefaultClientFolder = () => {
	return [
		{ 'doc_description': 'Status do Pedido' }, { 'doc_description': 'Pedido Técnico - PTC' },
		{ 'doc_description': 'Relatórios de vistoria' }, { 'doc_description': 'Caderno de obra' },
		{ 'doc_description': 'Faturamento NF' }, { 'doc_description': 'Checklist de instalação' },
		{ 'doc_description': 'Entrega da obra' },
	]
}

const setDefaultMultidoorFolder = () => {
	return [
		{ 'doc_description': 'Pedidos de compra' }, { 'doc_description': 'Detalhes Técnicos de projeto' },
		{ 'doc_description': 'Projeto Executivo - PE' }, { 'doc_description': 'Ordem de Fabricação' },
		{ 'doc_description': 'Ordem de Serviços' }, { 'doc_description': 'Registros da Qualidade' },
	]
}

let OrderFolderComponent = {
	fields: [
		[
			{
				type: "render",
				render: (_, form) => {
					return (
						<Button
							type='primary'
							onClick={async () => {
								form.state.formRef.tech_docs_required = await addAllTechDocs(form)
								form.state.invalidForm = false
								form.forceUpdate()
							}}
							icon="folder-open"
						>
							Adicionar todos os documentos técnicos obrigatórios
						</Button>
					)
				},
				beforeSave: async (Form, ParseObject) => {
					ParseObject.set("tech_docs_required", Form.state.formRef.tech_docs_required)
					return true;
				}
			},
		],
		[
			{
				key: 'tech_docs_required_render',
				type: 'render',
				render: (_, form) => {
					return (
						<OrderFolderTree
							parenteFolder='Documentos obrigatórios'
							docList={_.formRef.tech_docs_required}
							updateDocList={(docList) => {
								_.formRef.tech_docs_required = docList
								form.state.invalidForm = false
								form.forceUpdate()
							}}
						/>
					)
				}
			},
			{
				key: 'tech_docs_required',
				render: () => '',
			}
		],
		[
			{
				key: 'client_folder_render',
				type: 'render',
				render: (_, form) => {
					return (
						<OrderFolderTree
							parenteFolder='Cliente'
							docList={_.formRef.client_docs_folder}
							updateDocList={(docList) => {
								_.formRef.client_docs_folder = docList
								form.state.invalidForm = false
								form.forceUpdate()
							}}
						/>
					)
				}
			},
			{
				key: 'client_docs_folder',
				render: () => '',
			}
		],
		[
			{
				key: 'multidoor_docs_folder_render',
				type: 'render',
				render: (_, form) => {
					return (
						<OrderFolderTree
							parenteFolder='Multidoor'
							docList={_.formRef.multidoor_docs_folder}
							updateDocList={(docList) => {
								_.formRef.multidoor_docs_folder = docList
								form.state.invalidForm = false
								form.forceUpdate()
							}}
						/>
					)
				}
			},
			{
				key: 'multidoor_docs_folder',
				render: () => '',
			}
		],
		[
			{
				type: 'render',
				render: () => {
					return (
						<div>
							<div className='order-folder-guidelines-label'>Orientações:</div>
							<div>
								<ul>
									<li>
										Clique na pasta para adicionar um arquivo/pasta, editar seu nome ou excluí-la.
									</li>

									<li>
										Para mover um arquivo, clique e arraste-o para a pasta que deseja mover (arraste para o nome da pasta e não para seu interior).
									</li>
								</ul>
							</div>
						</div>
					)
				}
			}
		]
	],
	ListWillMount: async (_) => {
		surveys = await findAll('PcpoSurvey', 10000)
		_.forceUpdate()
	},
	"submit": {
		"collection": "Order"
	},
	FormHasMounted: async (_) => {
		await getTechDocs(_);
		if (!_.state.formRef.client_docs_folder)
			_.state.formRef.client_docs_folder = setDefaultClientFolder()

		if (!_.state.formRef.multidoor_docs_folder)
			_.state.formRef.multidoor_docs_folder = setDefaultMultidoorFolder()
		_.forceUpdate()
	},
	"defaultCurrentPage": 'last',
	"schema": [
		{
			"title": "Cliente",
			"key": "client",
			"dataIndex": "proposal",
			"type": "render",
			render: (proposal) => JSON.parse(proposal).name_client
		},
		{
			"title": "Pedido",
			"key": "order_code",
			"dataIndex": "order_code",
			"type": "text",
		},
		{
			"title": "Pasta e ações",
			"key": "folder",
			"dataIndex": "folder",
			"type": "render",
			align: 'center',
			width: '200px',
			render: (_, order) => {
				return (
					<ButtonGroup>
						<Popover content='Visualizar pasta do cliente'>
							<Button
								icon="folder-open"
								onClick={() => {
									window.open(`/pasta-cliente/${order.objectId}`, '_blink');
								}}
							/>
						</Popover>

						<Popover content='Enviar link da pasta por e-mail'>
							<Button
								disabled={!order.tech_docs_required}
								icon="mail"
								onClick={() => {
									let params = {
										orderId: order.objectId,
										keyWord: JSON.parse(order.proposal).keyWord,
									}

									showEmailModal(params);
								}}
							/>
						</Popover>

						<Popover content='Enviar manual do usuário por e-mail'>
							<Button
								icon="file-done"
								onClick={() => {
									let params = {
										orderId: order.objectId,
										orderCode: order.order_code,
										keyWord: JSON.parse(order.proposal).keyWord,
									}

									sendOrdeDocsByemail(params);
								}}
							/>
						</Popover>

						<Popover content='Histórico'>
							<CommentModal
								historic={order.historic}
								orderId={order.objectId}
								order={order}
							/>
						</Popover>
					</ButtonGroup>
				)
			}
		},
		{
			title: "Pesquisa",
			key: "survey",
			dataIndex: "order_code",
			align: "center",
			width: '80px',
			render: (order_code) => {
				const survey = surveys.find(el => el.get('order') === order_code)
				let color = survey ? 'green' : 'red'

				return (
					<div
						style={{
							backgroundColor: color,
							width: '20px', height: '20px',
							margin: 'auto', borderRadius: '50%'
						}}
					/>
				);
			}
		},
	],
	"title-module": "Pasta do pedido",
	"title-navigation": <div><Icon type="folder-open" /> Pasta do pedido</div>,
	"module": "OrderFolder",
	"router-base": "/panel/pasta-pedido"
};

/**
 * Send order folder by e-mail modal confirm
 * @param		{*}			params
 * @property    {String}    orderId
 * @property    {String}    keyWord
 */
function showEmailModal(params) {
	let email = '';
	Modal.confirm({
		title: 'Enviar pasta para o cliente',
		content: (
			<div>
				<p>Informe o e-mail do cliente</p>
				<Input placeholder='E-mail' onChange={e => email = e.target.value} />
			</div>
		),
		onOk() {
			if (!email) {
				message.error('Informe um e-mail');
			} else {
				params['email'] = email;

				notification.open({
					message: 'Enviando e-mail.',
					description: `A pasta do cliente está sendo enviada para o email ${email}, aguarde...`,
					key: 'sendingOrderFolder',
					duration: 0,
					placement: 'topLeft',
					icon: <Icon type="loading" />
				});

				Parse.Cloud.run('sendOrderFolderEmail', params)
					.then(async () => {
						notification.close('sendingOrderFolder');
						notification.success({
							message: 'E-mail enviado.',
							description: 'O e-mail foi enviado para o cliente'
						})

						const user = await getCurrentUser()
						const sendingDate = new Date().toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })
						const orderToUpdate = await findById('Order', params.orderId)
						await save(
							'Order',
							{
								historic: [
									...(orderToUpdate.get('historic') || []),
									{
										key: "orderFolder",
										comment: `Pasta do cliente enviada em ${sendingDate} para o e-mail ${email}.`,
										author: user.get("name"),
										date: Date.now(),
									}
								]
							},
							params.orderId,
						)
					})
					.catch(() => {
						notification.close('sendingOrderFolder');
						notification.error({
							message: 'Alguma coisa deu errado',
							description: 'Verifique os dados e tente novamente',
						});
					})
			}
		},
		okText: 'Enviar',
		cancelText: 'Cancelar'
	});
}

/**
 * Send order docs by e-mail modal confirm
 * @param		{*}			params 
 * @property    {String}    orderId
 * @property    {String}    orderCode
 * @property    {String}    keyWord
 */
function sendOrdeDocsByemail(params) {
	let email = '';
	Modal.confirm({
		title: 'Enviar Manual do usuário para o cliente',
		content: (
			<div>
				<p>Informe o e-mail do cliente</p>
				<Input placeholder='E-mail' onChange={e => email = e.target.value} />

				<div
					className='link-style'
					style={{ marginTop: '20px' }}
					onClick={() => {
						navigator.clipboard.writeText(
							`http://minhamultidoor.com.br/pesquisa-satisfacao-pcpo?order=${params.orderCode}`
						).then(() => {
							message.success('Link copiado com sucesso!');
						})
					}}
				>
					Copiar link
				</div>
			</div>
		),
		onOk() {
			if (!email) {
				message.error('Informe um e-mail');
			} else {
				params['email'] = email;

				notification.open({
					message: 'Enviando e-mail.',
					description: `O manual está sendo enviado para o email ${email}, aguarde...`,
					key: 'sendingTermAndManual',
					duration: 0,
					placement: 'topLeft',
					icon: <Icon type="loading" />
				});

				Parse.Cloud.run('sendOrdeDocsByemail', params)
					.then(async () => {
						notification.close('sendingTermAndManual');
						notification.success({
							message: 'E-mail enviado.',
							description: 'O e-mail foi enviado para o cliente'
						})

						const user = await getCurrentUser()
						const sendingDate = new Date().toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })
						const orderToUpdate = await findById('Order', params.orderId)
						await save(
							'Order',
							{
								historic: [
									...(orderToUpdate.get('historic') || []),
									{
										key: "orderFolder",
										comment: `Manual e termo enviados em ${sendingDate} para o e-mail ${email}.`,
										author: user.get("name"),
										date: Date.now(),
									}
								]
							},
							params.orderId,
						)
					})
					.catch(() => {
						notification.close('sendingTermAndManual');
						notification.error({
							message: 'Alguma coisa deu errado',
							description: 'Verifique os dados e tente novamente',
						});
					})
			}
		},
		okText: 'Enviar',
		cancelText: 'Cancelar'
	});
}

export default OrderFolderComponent;