import React from 'react'
import { Icon, notification, Divider, Row, Button, Modal, InputNumber } from 'antd'
import Parse from 'parse'

import moment from 'moment'
import _ from 'lodash'

const renderInputsRow = {
	render: (Form, index, list, k, objectEdit, FormModule) => {

		// Set decimal places step
		const decimalPlacesStep = ["m²", "m³"].includes(Form.state.formRef[`measure_${index}`]) ? 0.001 : 0.01;

		return (
			<div key={'ref-row-' + index}>
				<Row gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(
						{
							"title": "Insumo",
							"key": `input_${index}`,
							"type": "select",
							'loaded': false,
							"options": (
								Form.state.formRef.provider ?
									inputsByProvider.filter(v => v.provider_id === Form.state.formRef.provider)[0] ?
										inputsByProvider.filter(v => v.provider_id === Form.state.formRef.provider)[0]['inputs'] : []
									: []).map(el => {
										return { label: `${el.id ? el.get('code') : null} - ${el.id ? el.get('material_description') : null}`, value: el && el.id }
									}),
							"element-attr": { "required": false, beforeChange: (_, node, value) => getInputAttr(Form, index, value) },
						}, 9, objectEdit, 0)
					}
					{Form.getElementByTypeSchema(
						{
							type: "select",
							title: 'Unidade',
							key: `measure_${index}`,
							options: [],
							optionConfig: { name: 'unidades' },
							loaded: false,
							"element-attr": {
								mode: 'select',
								required: false,
								disabled: true
							},
						}, 2, objectEdit, 0)
					}
					{Form.getElementByTypeSchema({ type: "money", title: 'Vl Unit.', key: `unit_value_${index}`, step: 0.01, "element-attr": { required: false, beforeChange: (_, node, value) => calculateSubtotal(_, index, list) } }, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "number", title: 'Quant.', key: `qtd_${index}`, step: decimalPlacesStep, "element-attr": { required: false, beforeChange: (_, node, value) => calculateSubtotal(_, index, list) } }, 2, objectEdit, 0)}
					{Form.getElementByTypeSchema({ type: "number", title: 'IPI', key: `ipi_${index}`, step: 0.01, "element-attr": { required: false, disabled: true } }, 2, objectEdit, 0)}
					{Form.getElementByTypeSchema({
						type: "render", title: 'Subtotal (insumo + frete)', key: `total_${index}`, step: 0.01,
						render: (state, module, node) => {
							return (
								<div>
									<InputNumber disabled size="large" ref={`total_${index}`} value={(Form.state.formRef[`total_${index}`] || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} className="show-input" node={node} />
								</div>
							)
						}
					}, 3, objectEdit, 0)}
					{Form.getElementByTypeSchema(
						{
							type: "render", key: 'btn_remove', render: (_, form, node) => {
								return <Button type='danger' size='large' node={node} onClick={() => {
									Modal.confirm({
										title: 'Confirme para excluir esse insumo',
										okType: 'danger',
										cancelText: 'Cancelar',
										okText: 'Excluir',
										onOk: () => {
											removeItem(FormModule, k, index, InputOrderComponent)
										}
									})
								}}>Remover</Button>
							}
						}, 3, InputOrderComponent.dataToEdit, 0)
					}
				</Row>
			</div>
		)
	}
}

const InputOrderComponent = {
	"fields": [
		[
			{
				"title": "Número",
				"key": "order_code",
				"type": "text",
				"element-attr": { required: false, disabled: true }
			},
		],
		[
			{
				"title": "Fornecedor",
				"key": "provider",
				"type": "pointer",
				"options": [],
				"relation-select": {
					"class": "InputProvider",
					"label": (obj) => `${obj.code} - ${obj.name}`,
					"value": "objectId",
					"key": "objectId",
				},
				"element-attr": { "required": true, 'mode': 'default' },
			},
			{
				"title": "Prazo de pagamento",
				"key": "payment_conditions",
				"type": "select",
				"options": [
					{ label: 'Selecione...', value: null },
					{ label: '5 dias (antecipado)', value: '5 dias (antecipado)' },
					{ label: '15 dias', value: '15 dias' },
					{ label: '30 dias', value: '30 dias' },
					{ label: '30/60 dias', value: '30/60 dias' },
					{ label: '30/45/60 dias', value: '30/45/60 dias' },
					{ label: '30/60/90 dias', value: '30/60/90 dias' },
					{ label: '30/60/90/120 dias', value: '30/60/90/120 dias' }
				],
				"element-attr": { required: false, 'mode': 'default' }
			},
			{
				"title": "Prazo de entrega",
				"key": "delivery_conditions",
				"type": "date-picker",
				"element-attr": { required: false }
			},
			{
				"title": "Observação",
				"key": "obs",
				"type": "text",
				"element-attr": { required: false }
			},
		],
		[
			{
				"title": "Responsável pelo frete",
				"key": "delivery_responsible",
				"type": "select",
				"options": [
					{ label: 'Selecione...', value: null },
					{ label: 'Comprador - Multidoor', value: 'Comprador - Multidoor' },
					{ label: 'Fornecedor - FOB SP incluso', value: 'Fornecedor - FOB SP incluso' },
					{ label: 'Fornecedor - FOB SP destaque', value: 'Fornecedor - FOB SP destaque' },
					{ label: 'Fornecedor - CIF AL incluso', value: 'Fornecedor - CIF AL incluso' },
					{ label: 'Fornecedor - CIF AL destaque', value: 'Fornecedor - CIF AL destaque' }
				],
				"element-attr": { required: false, 'mode': 'default', beforeChange: (_, node, value) => addShipmentToFinalValue(_, (_.state.formRef['shipment'] || 0)) }
			},
			{
				"title": "Transportadora",
				"key": "delivery",
				"type": "pointer",
				"options": [],
				"relation-select": {
					"class": "InputProvider",
					"label": (obj) => `${obj.code} - ${obj.name}`,
					"orderBy": "code",
					"value": "name",
					"key": "objectId",
					where: { provider_group: '71xnAN2mKl' }
				},
				_ignoreCache: true,
				"element-attr": { "required": false, 'mode': 'default', beforeChange: (_, node, value) => searchDeliveryContact(_, value) }
			},
			{
				"title": "Tipo de transporte",
				"key": "delivery_type",
				"type": "select",
				"options": [
					{ label: 'Selecione...', value: null },
					{ label: 'Rodoviário', value: 'Rodoviário' },
					{ label: 'Maritimo - porta a porta', value: 'Maritimo - porta a porta' },
					{ label: 'Maritimo - porto Suape-PE', value: 'Maritimo - porto Suape-PE' },
					{ label: 'Aéreo', value: 'Aéreo' },
					{ label: 'Sedex', value: 'Sedex' }
				],
				"element-attr": { required: false, 'mode': 'default' }
			},
		],
		[
			{
				"title": "Destino do insumo",
				"key": "input_destiny",
				"type": "select",
				"options": [
					{ label: 'Selecione...', value: null },
					{ label: 'Industrialização', value: 'Industrialização' },
					{ label: 'Consumo', value: 'Consumo' },
					{ label: 'Manutenção', value: 'Manutenção' },
					{ label: 'Ativo fixo', value: 'Ativo fixo' },
					{ label: 'Revenda', value: 'Revenda' }
				],
				"element-attr": { required: false, 'mode': 'default' }
			},
			{
				"title": "Cliente",
				"key": "client",
				"type": "pointer",
				"options": [],
				"relation-select": {
					"class": "Client",
					"label": (obj) => `${obj.cod_cliente} - ${obj.name_client}`,
					"value": "objectId",
					"key": "objectId",
				},
				"element-attr": { "required": false, 'mode': 'default' },
			},
			{
				"title": "Pedido",
				"key": "sales_order_code",
				"type": "text",
				"element-attr": { required: false }
			},
		],
		[
			{
				type: 'document',
				key: 'nf',
				title: 'Nota Fiscal',
				'element-attr': { required: false }
			},
		],
		[
			{
				"title": "Status",
				"key": "status",
				"type": "select",
				"options": [
					{ label: 'Selecione...', value: null },
					{ label: 'Programado', value: 'Programado' },
					{ label: 'Pagamento efetuado', value: 'Pagamento efetuado' },
					{ label: 'Faturado', value: 'Faturado' },
					{ label: 'Recebido', value: 'Recebido' },
					{ label: 'Cancelado', value: 'Cancelado' }
				],
				"element-attr": { required: false, 'mode': 'multiple' }
			}
		]
	],
	submit: {
		collection: "InputOrder"
	},
	data: {
		inputs: [renderInputsRow]
	},
	FormComponent: (Form, Module) => {
		if (!InputOrderComponent.dataToEdit && Form.props.objectEdit) { // load data to edit and format values to fill inputs
			let ParseQuery = new Parse.Query(new Parse.Object.extend(Module.collection))
			ParseQuery.get(Form.props.objectEdit).then(response => {
				InputOrderComponent.dataToEdit = response.toJSON();

				finalValue = InputOrderComponent.dataToEdit.total_value;
				finalValueWithShipment = moneyToNumber(InputOrderComponent.dataToEdit.total_value_with_shipment);
				finalIpiValue = InputOrderComponent.dataToEdit.ipi;
				deliveryContact = InputOrderComponent.dataToEdit.delivery_phone;

				InputOrderComponent.dataToEdit = {
					...InputOrderComponent.dataToEdit,
					...InputOrderComponent.dataToEdit.inputs.reduce((acc, v, index) => {
						let obj = {}
						for (let key in v) {
							obj[`${key}_${index}`] = v[key];
						}
						return { ...acc, ...obj }
					}, {})
				}

				InputOrderComponent.data.inputs = InputOrderComponent.dataToEdit.inputs.map((v, i) => {
					return renderInputsRow
				})

				if (InputOrderComponent.dataToEdit.provider)
					InputOrderComponent.dataToEdit.provider = InputOrderComponent.dataToEdit.provider.objectId

				if (InputOrderComponent.dataToEdit.client)
					InputOrderComponent.dataToEdit.client = InputOrderComponent.dataToEdit.client.objectId

				// Transformar as datas do formato Date em moment
				for (let value in InputOrderComponent.dataToEdit) {
					if (value.includes('delivery_conditions') && InputOrderComponent.dataToEdit[value] && typeof InputOrderComponent.dataToEdit[value] === 'object')
						InputOrderComponent.dataToEdit[value] = moment(InputOrderComponent.dataToEdit[value]['iso'])
				}

				Form.setState(() => {
					return {
						formRef: InputOrderComponent.dataToEdit
					}
				})
			})
		}

		let revenuesOptionsField = revenuesOptions

		return (
			<div>
				<Row key="row-1" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('order_code'), 4, InputOrderComponent.dataToEdit, 0)}
				</Row>
				<Row gutter={24} className='row-form-item'>
					{Form.getElementByTypeSchema(Form.getNodesByKey('provider'), 6, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('payment_conditions'), 4, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('delivery_conditions'), 4, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('obs'), 10, InputOrderComponent.dataToEdit, 0)}
				</Row>
				<Row key="row-2" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('delivery_responsible'), 6, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('delivery'), 6, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('delivery_type'), 6, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({
						"title": "Contato transportadora", "key": "delivery_phone", "type": "text",
						"element-attr": { required: false, value: deliveryContact },
					}, 6, InputOrderComponent.dataToEdit, 0)}
				</Row>
				<Row gutter={24} className='row-form-item'>
					{Form.getElementByTypeSchema({
						"title": "Faturamento", "key": "revenues", "type": "select",
						"options": revenuesOptionsField.map(el => { return { label: el.provider, value: el.objectId, disabled: el.disabled } }),
						"element-attr": { "required": true, 'mode': 'default' },
					}, 12, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({
						"title": "Local de entrega", "key": "delivery_place", "type": "select",
						"options": revenuesOptionsField.map(el => { return { label: el.provider, value: el.objectId, disabled: el.disabled } }),
						"element-attr": { "required": true, 'mode': 'default' },
					}, 12, InputOrderComponent.dataToEdit, 0)}
				</Row>
				<Row key="row-2a" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('input_destiny'), 6, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('client'), 12, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('sales_order_code'), 6, InputOrderComponent.dataToEdit, 0)}
				</Row>
				<Row gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema(Form.getNodesByKey('status'), 24, InputOrderComponent.dataToEdit, 0)}
				</Row>

				<Divider orientation='left'>Relação de insumos</Divider>
				<Row key="row-3" gutter={24} className="row-form-item">
					{(InputOrderComponent.data.inputs || []).map((cd, k, l) => {
						return cd.render(Form, k, l, k, InputOrderComponent.dataToEdit, Form)
					})}
				</Row>
				{Form.getElementByTypeSchema({
					type: "render", key: 'btn_plus', className: 'btn-plus-no-title', render: (_, form, node) => {
						return <Button node={node} size='large' onClick={event => {
							InputOrderComponent.data.inputs.push(renderInputsRow)
							Form.forceUpdate()
						}}>Adicionar Insumo</Button>
					}
				}, 24, InputOrderComponent.dataToEdit, 0)}

				<Divider orientation='left'>Totais</Divider>
				<Row key="row-4" gutter={24} className="row-form-item">
					{Form.getElementByTypeSchema({
						type: "render", title: 'Total IPI', key: `ipi`,
						render: (state, module, node) => {
							return (
								<div>
									<InputNumber disabled size="large" ref="ipi" value={(finalIpiValue || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} className="show-input" node={node} />
								</div>
							)
						}
					}, 4, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema({ type: "money", title: 'Valor frete', key: `shipment`, "element-attr": { required: false, beforeChange: (_, node, value) => addShipmentToFinalValue(_, value) /*value: finalShipmentValue*/ } }, 4, InputOrderComponent.dataToEdit, 0)}
					<div style={{ display: 'none' }}>
						{Form.getElementByTypeSchema({ type: "text", title: 'Valor total', key: `total_value`, "element-attr": { required: false, value: finalValue } }, 6, InputOrderComponent.dataToEdit, 0)}
					</div>
					{Form.getElementByTypeSchema({
						type: "render", title: 'Valor total', key: `total_value_with_shipment`,
						render: (state, module, node) => {
							return (
								<div>
									<InputNumber disabled size="large" ref="total_value_with_shipment" value={(finalValueWithShipment || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} className="show-input" node={node} />
								</div>
							)
						}
					}, 6, InputOrderComponent.dataToEdit, 0)}
					{Form.getElementByTypeSchema(Form.getNodesByKey('nf'), 4, InputOrderComponent.dataToEdit, 0)}
				</Row>
			</div>
		)
	},
	SubmitForm: (FormRef, ObjectEdit, ObjectId, FormModule) => {
		let node = FormModule.getNodesByKey('provider')
		let node_client = FormModule.getNodesByKey('client')
		let objectWithValues = parseDataToSave(FormRef, FormModule)
		let dinamicsValues = getDinamicsValues(FormRef, FormModule, InputOrderComponent)
		objectWithValues = { ...objectWithValues, inputs: dinamicsValues, width_add: FormRef.width_add }
		let refs = FormModule.getRefWithFormItem(false)
		let invalid = false;
		for (let key in refs) {
			// checking if exist empty required inputs.
			if (!FormModule.nodeIsValid(FormModule.refs[key].props.node) || (!FormModule.nodeHasEmptyValue(FormModule.refs[key].props.value || FormModule.state.formRef[key]) && FormModule.nodeIsRequired(FormModule.refs[key].props.node))) {
				notification.error({ message: FormModule.refs[key].props.node.errorMessage || `Valor inválido no campo: ${FormModule.refs[key].props.node.title}`, duration: 5 })
				invalid = true;
				break;
			}
		}

		let data = objectWithValues
		if (invalid) return false;
		if (ObjectEdit) return update(objectWithValues, ObjectId, FormModule)

		let Extension = new Parse.Object(FormModule.props.module.form.module)
		let PointerObject = new Parse.Object(node['relation-select'].class)
		PointerObject.id = data[node.key];
		Extension.set('provider', PointerObject);
		data[node.key] = PointerObject
		if (data[node_client.key]) {
			let PointerObject_Client = new Parse.Object(node_client['relation-select'].class)
			PointerObject_Client.id = data[node_client.key];
			Extension.set('client', PointerObject_Client);
			data[node_client.key] = PointerObject_Client
		}
		data['updatedBy'] = Parse.User.current()
		data['createdBy'] = Parse.User.current()

		// Transformar as datas do formato moment em Date
		for (let value in data) {
			if (moment.isMoment(data[value])) data[value] = moment(data[value]).toDate()
		}

		generateCode(FormModule).then(res => {
			data['order_code'] = res
			Extension.save(data).then(saved => {
				FormModule.afterSave()
			}).catch(err => {
				notification.error({
					message: err.message
				})
			})
		})
	},
	FormWillMount: () => {
		InputOrderComponent.data = {
			inputs: [renderInputsRow]
		}
		finalValue = 0
		finalValueWithShipment = 0
		// finalShipmentValue = 0
		finalIpiValue = 0
		deliveryContact = ''

		// Buscar Insumos do bd
		let input_query = new Parse.Query('Input').limit(100000)
		input_query.find()
			.then(res => {
				inputs = res

				// Buscar provedores do bd
				let provider_query = new Parse.Query('InputProvider').limit(1000)
				provider_query.find()
					.then(provider_res => {
						providers = provider_res

						inputsByProvider = providers.map(el => {
							return {
								provider_id: el.id,
								inputs: ((el && el.get('inputs')) || []).map(child_el => {
									let local_ipt = {}
									inputs.forEach(ipt => {
										if (ipt.id === child_el.input) local_ipt = ipt
									})
									return local_ipt
								})
							}
						})

						return this.forceUpdate()
					})
			})

	},
	ListWillMount: () => {
		// Buscar Insumos do bd
		let input_query = new Parse.Query('Input').limit(100000)
		input_query.find()
			.then(res => {
				inputs = res

				// Buscar provedores do bd
				let provider_query = new Parse.Query('InputProvider').limit(500)
				provider_query.find()
					.then(provider_res => {
						providers = provider_res

						inputsByProvider = providers.map(el => {
							return {
								provider_id: el.id,
								inputs: ((el && el.get('inputs')) || []).map(child_el => {
									let local_ipt = {}
									inputs.forEach(ipt => {
										if (ipt.id === child_el.input) local_ipt = ipt
									})
									return local_ipt
								})
							}
						})

						// Buscar canais de vendas para faturamento
						let sales_channel_query = new Parse.Query('SalesChannel')
						sales_channel_query.find()
							.then(sales_channel_res => {
								sales_channel_res = sales_channel_res.map(el => el.toJSON())
								sales_channel_res = _.uniqBy(sales_channel_res, 'cnpj')
								revenuesOptions = sales_channel_res
								revenuesOptions.push({ provider: 'Cliente', objectId: 'cliente', disabled: false })

								return this.forceUpdate()
							})

					})
			})
	},
	onClickActionEdit: row => {
		InputOrderComponent.dataToEdit = null
		finalValue = 0
		finalValueWithShipment = 0
		// finalShipmentValue = 0
		finalIpiValue = 0
		deliveryContact = ''
	},
	// className: "input-module",
	"defaultCurrentPage": 'last',
	schema: [
		{
			title: "Número",
			key: "order_code",
			dataIndex: "order_code",
			width: '150px',
			type: "text",
		},
		{
			title: "Fornecedor",
			key: "provider",
			dataIndex: "provider",
			type: "render",
			render: (v) => v.name
		},
		{
			title: 'Valor R$',
			key: 'total_value_with_shipment',
			dataIndex: 'total_value_with_shipment',
			width: '150px',
			type: 'render',
			render: (v, node) => {
				const value = moneyToNumber(v) + moneyToNumber(node.ipi || 'R$ 0');
				return (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
			}
		},
		{
			title: 'Data de entrega',
			key: 'delivery_conditions',
			dataIndex: 'delivery_conditions',
			width: '100px',
			type: "render",
			render: (_, row) => {
				if (row.delivery_conditions)
					return new Date(row.delivery_conditions.iso).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })
				return ''
			},
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			width: '80px',
			type: 'render',
			render: (v) => {
				return (v || []).map(el => {
					let color = null

					switch (el) {
						case 'Programado':
							color = 'yellow'
							break

						case 'Pagamento efetuado':
							color = 'orange'
							break

						case 'Faturado':
							color = 'green'
							break

						case 'Recebido':
							color = 'blue'
							break

						case 'Cancelado':
							color = 'red'
							break

						default:
							color = null
					}

					return (
						<div
							key={el}
							style={{ backgroundColor: color, width: '25px', height: '25px', margin: 'auto', marginBottom: '5px', borderRadius: '50%' }}
						/>
					)
				})
			}
		},
		{
			title: 'Emissão',
			key: 'emission',
			dataIndex: 'emission',
			align: 'center',
			width: '75px',
			render: (_, order) => {
				return (
					<Button title="Emitir pedido" type="ghost" shape="circle" icon="printer"
						onClick={() => {
							Parse.Cloud.run('generateInputOrder', { id: order.objectId }).then((response) => {
								notification.success({ message: 'Pedido emitido com sucesso, click no botão de PDF para visualizar o pedido.' })
								window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')
							})
						}}
					/>
				)
			}
		},
		{
			title: "PDF",
			key: "pdf",
			dataIndex: "pdf",
			align: 'center',
			width: '75px',
			render: (_, order) => {
				const pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_input_order/pedido-compra-${order.order_code}.pdf`

				return (
					<Button title="Visualizar pedido em PDF" type="default" shape="circle" icon="file-pdf"
						onClick={() => {
							window.open(pdfName, '_blink')
						}}
					/>
				)
			}
		},
		{
			title: "NF",
			key: "nf",
			dataIndex: "nf",
			align: 'center',
			width: '75px',
			render: (_, order) => {
				let pdfName = '-'
				if (_) pdfName = _.url

				return (
					<Button title="Visualizar Nota Fiscal" type="default" disabled={!_} shape="circle" icon="file-pdf"
						onClick={() => {
							window.open(pdfName, '_blink')
						}}
					/>
				)
			}
		}
	],
	tableFooter: () => {
		let statusArray = ['Programado', 'Pagamento efetuado', 'Faturado', 'Recebido', 'Cancelado'];

		return (
			<div style={{ clear: 'both', paddingBottom: 20 }}>

				{
					statusArray.map(el => {
						let color = null

						switch (el) {
							case 'Programado':
								color = 'yellow'
								break

							case 'Pagamento efetuado':
								color = 'orange'
								break

							case 'Faturado':
								color = 'green'
								break

							case 'Recebido':
								color = 'blue'
								break

							case 'Cancelado':
								color = 'red'
								break

							default:
								color = null
						}

						return (
							<div style={{ float: 'left', marginRight: 30 }}>
								<div
									key={el}
									style={{ float: 'left', backgroundColor: color, width: '15px', height: '15px', margin: 'auto', marginBottom: '5px', marginRight: 5, borderRadius: '50%' }}
								/> {el}
							</div>
						)
					})
				}

			</div>
		)
	},
	"title-navigation": <div><Icon type="file-ppt" /> Pedido de Compra</div>,
	"title-module": "Pedido de Compra",
	"module": "InputOrder",
	"router-base": "/panel/pedido-compra"
}

// Listas
let inputs = []
let providers = []
let inputsByProvider = []
let revenuesOptions = []

// Totais
let finalValue = 0
let finalValueWithShipment = 0
// let finalShipmentValue = 0
let finalIpiValue = 0

let deliveryContact = ''

/**
 * Configura os atributos do insumo selecionado.
 * @param {*} Form 
 * @param {Number} index 
 * @param {String} value Id do insumo.
 */
const getInputAttr = (Form, index, value) => {
	let input = inputs.filter(v => v.id === value)[0]
	// let jsonInput = input.toJSON()
	Form.state.formRef[`measure_${index}`] = input.get('measure')
	Form.state.formRef[`unit_value_${index}`] = input.get('material') // (getTotalCost(jsonInput) * (jsonInput.convert || 1)).toFixed(2) / 1
	Form.state.formRef[`ipi_${index}`] = input.get('ipi') || 0
	Form.state.formRef[`shipment_rate_${index}`] = input.get('shipment_rate') * 1
	Form.state.formRef[`shipment_unit_rate_${index}`] = input.get('shipment_rate')
	Form.forceUpdate()
}

// const getTotalCost = (row) => {

// 	let pis_c = (row['pis_c'] / 100 || 0)
// 	let pis_icms = (row['pis_icms'] / 100 || 0)
// 	let ipi = (row['ipi'] / 100 || 0)
// 	let shipmentRate = (row['shipment_rate'] || 0)
// 	let material = (row['material'] || 0)
// 	let loss = (row['loss'] / 100 || 0)

// 	let totalImp = (pis_c * ((row['credit_tax_shipment'] && shipmentRate) + (row['credit_tax_material'] && material))) +
// 	  (pis_icms * ((row['credit_tax_shipment'] && shipmentRate) + (row['credit_tax_material'] && material)));

// 	let totalMaterial = (shipmentRate + (ipi * material) + material);
// 	loss = (loss * (totalMaterial - totalImp));

// 	return totalMaterial + loss - totalImp;
// }

/**
 * Calcula o valor final.
 * @param {*} Form 
 * @param {Number} index 
 * @param {Array} list Lista dos insumos.
 */
const calculateSubtotal = (Form, index, list) => {
	setTimeout(() => {
		let qtd = Form.state.formRef[`qtd_${index}`] || 0
		let unitValue = Form.state.formRef[`unit_value_${index}`] || 0

		// Form.state.formRef[`shipment_rate_${index}`] = qtd * Form.state.formRef[`shipment_unit_rate_${index}`]
		Form.state.formRef[`total_${index}`] = qtd * unitValue

		finalValue = 0
		// finalShipmentValue = 0
		finalIpiValue = 0
		list.forEach((el, i) => {
			finalValue += moneyToNumber(Form.state.formRef[`total_${i}`]);
			// finalShipmentValue += (Form.state.formRef[`shipment_rate_${i}`] || 0)
			finalIpiValue += (moneyToNumber(Form.state.formRef[`ipi_${i}`] || 0) / 100) * moneyToNumber(Form.state.formRef[`total_${i}`] || 0)
		})
		finalValueWithShipment = finalValue + ((Form.state.formRef['delivery_responsible'] || '').includes('destaque') ? (Form.state.formRef['shipment'] || 0) : 0)
		Form.forceUpdate()
	}, 100)
}

/**
 * Soma o valor do frete ao valor final.
 * @param {*} Form 
 * @param {Number} value Valor do frete.
 */
const addShipmentToFinalValue = (Form, value) => {
	setTimeout(() => {
		if ((Form.state.formRef['delivery_responsible'] || '').includes('destaque')) {
			finalValueWithShipment = finalValue + (value || 0)
		} else {
			finalValueWithShipment = finalValue
		}
		Form.forceUpdate()
	}, 100)
}

const parseDataToSave = (_, FormModule) => {
	// get ParseObject of vinculated_component
	let refs = FormModule.getRefWithFormItem(false)
	return Object.keys(getValues(FormModule)).filter(v => {
		return (v.match(/[0-9]|_slider|_input/g)) ? false : v;
	}).reduce((a, k) => {
		let value = refs[k].props.value
		return { ...a, [k]: value }
	}, {})
}

const getDinamicsValues = (_, FormModule, InputOrderComponent) => {
	let refs = FormModule.getRefWithFormItem(false)
	return InputOrderComponent.data.inputs.map((v, index) => {
		if (refs.hasOwnProperty(`input_${index}`)) {
			return {
				...['input', 'measure', 'unit_value', 'qtd', 'ipi', 'total'].reduce((a, v) => {
					return { ...a, [v]: refs[`${v}_${index}`].props.value }
				}, {})
			}
		}

		return null;
	})
}

const getValues = (FormModule) => {
	let refs = FormModule.getRefWithFormItem(false)
	let obj = {}
	for (let item in refs) {
		let value = refs[item].props.value
		obj[item] = value;
	}
	return obj;
}

const update = (data, objectId, FormModule) => {
	let node = FormModule.getNodesByKey('provider')
	let node_client = FormModule.getNodesByKey('client')
	let Query = new Parse.Query(FormModule.props.module.form.module)
	let PointerObject = new Parse.Object(node['relation-select'].class)
	PointerObject.id = data[node.key]
	let PointerObject_Client = new Parse.Object(node_client['relation-select'].class)
	if (data[node_client.key]) {
		PointerObject_Client.id = data[node_client.key]
		data[node_client.key] = PointerObject_Client
	}

	data[node.key] = PointerObject
	Query.get(objectId).then(ObjectParse => {
		ObjectParse.set('provider', PointerObject);
		if (data[node_client.key]) ObjectParse.set('client', PointerObject_Client);
		ObjectParse.set('updatedBy', Parse.User.current())

		// Transformar as datas do formato moment em Date
		for (let value in data) {
			if (moment.isMoment(data[value])) data[value] = moment(data[value]).toDate()
		}

		ObjectParse.save(data, {}).then(r => {
			FormModule.finishEdit()
			InputOrderComponent.dataToEdit = null;
		}).catch(err => {
			notification.error({
				message: err.message
			})
		})
	})
}

/**
 * Gera um novo código de pedido.
 * @param {*} FormModule 
 * @returns Código gerado.
 */
async function generateCode(FormModule) {
	const currentYear = new Date().getFullYear()
	const _query = new Parse.Query(FormModule.props.module.form.module).limit(1000)

	// Listar as propostas do ano corrente
	_query.endsWith('order_code', currentYear.toString())
	let code = await _query.find().then(res => {
		if (res.length) {
			res = res.sort((a, b) => (a.get('order_code') > b.get('order_code')) ? 1 : ((b.get('order_code') > a.get('order_code')) ? -1 : 0))
			let intCode = parseInt(res[res.length - 1].get('order_code')) + 1
			let zeroLength = 4 - intCode.toString().length
			return `${'0'.repeat(zeroLength)}${intCode}-${currentYear.toString()}`
		} else return `0001-${currentYear.toString()}`
	})
	return code
}

const removeItem = (FormModule, blockCompositionIndex, rowCompositionIndex, moduleConfig) => {
	let inputs = getDinamicsValues(FormModule.formRef, FormModule, moduleConfig)
	inputs.splice(blockCompositionIndex, 1)
	applyCompositionsChange(FormModule, moduleConfig, inputs)
}

const applyCompositionsChange = (FormModule, moduleConfig, compositionList) => {
	// this function will parse the database structure to formRef struct because formRef.
	let formRef = parseDataToSave(FormModule.formRef, FormModule);
	formRef.inputs = (typeof formRef.inputs !== "string") && undefined

	formRef = {
		...formRef,
		...compositionList.reduce((acc, v, index) => {
			let obj = {}
			for (let key in v) {
				obj[`${key}_${index}`] = v[key];
			}
			return { ...acc, ...obj }
		}, {}
		)
	}

	moduleConfig.data.inputs = compositionList.map((v) => {
		return renderInputsRow
	})

	FormModule.setState({ formRef, invalidForm: false })
}

/**
 * Busca o contato do transportador.
 * @param {*} Form 
 * @param {String} providerId Id do provedor.
 */
const searchDeliveryContact = (Form, providerId) => {
	let delivery = providers.find(el => el.get('name') === providerId)
	if (delivery) deliveryContact = `${delivery.get('func_logistics_name')} - ${delivery.get('func_logistics_phone')}`
	else deliveryContact = ''
}

/**
 * Convert money string to number.
 * @param {String} moneyString 
 */
const moneyToNumber = (moneyString) => {
	if (typeof moneyString === 'number') return moneyString;

	// Remove R$
	const moneyStringWithoutCifrao = moneyString.replace('R$ ', '');

	// Remove all dots
	const moneyStringWithoutDots = moneyStringWithoutCifrao.replace(/\./g, '');

	// Change string to number
	const moneyNumber = parseFloat(moneyStringWithoutDots.replace(',', '.'));

	return moneyNumber;
}

export default InputOrderComponent;

export {
	generateCode
}