import { Divider } from 'antd';
import React from 'react'
import ProposalChoose from '../entities/proposal-choose';
import Parse from 'parse'

const generalInfoFields = (auth, discountLimit) => {
    return [
        [
            {
                "title": "Número do pedido",
                "key": "order_code",
                "type": "render",
                "colSize": 6,
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {_.formRef.order_code}
                        </div>
                    );
                },
                beforeSave: async (Form, ParseObject, node) => {
                    if (typeof Form.state.formRef.proposalRef === 'string') {
                        var proposalToRef = Parse.Object.extend("Proposal");
                        var pointerToProposal = new proposalToRef();
                        pointerToProposal.id = Form.state.formRef.proposalRef;
                        await ParseObject.set('proposalRef', pointerToProposal)
                    }

                    return true;
                }

            },
        ],
        [
            {
                "title": "Data de emissão",
                "key": "order_date",
                "type": "date-picker",
                "colSize": 4,
                "element-attr": { "required": true, 'showToday': false }
            },
        ],
        [
            {
                title: 'Proposta',
                key: 'proposalRefRender',
                type: 'render',
                render: (_, form) => {
                    return (
                        <ProposalChoose
                            orderId={_.formRef.objectId}
                            proposalId={_.formRef.proposalRef}
                            changeProposal={(id) => {
                                _.formRef.proposalRef = id
                                form.state.invalidForm = false
                                form.forceUpdate()
                            }}
                        />
                    )
                }
            },
        ],
        [
            {
                "type": "render",
                "render": () => {
                    return <Divider>Total Geral</Divider>
                }
            }
        ],
        [
            {
                "title": "Total Geral",
                "key": "total",
                colSize: '6',
                "type": "render",
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {(_.formRef.total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    );
                }
            },
        ],
        [
            {
                "title": "Desconto financeiro",
                "key": "commercial_discount",
                "type": "money",
                colSize: '6',
                "element-attr": {
                    "required": true,
                    "disabled": auth ? !auth._financialDiscountPermission : true,
                    "max": discountLimit,
                    // afterChange: (_, node, value) => {
                    //     const totalDiscountedValue = (_.state.formRef['total'] || (_.state.formRef.proposal ? JSON.parse(_.state.formRef.proposal).final_value : 0)) - value;
                    //     _.state.formRef['total_with_discount'] = roundTo(totalDiscountedValue || (_.state.formRef.proposal ? JSON.parse(_.state.formRef.proposal).final_value : 0), 2);
                    //     _.forceUpdate()
                    // }
                }
            },
        ],
        [
            {
                "title": "Total com desconto",
                "key": "total_with_discount_render",
                colSize: '6',
                "type": "render",
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {((_.formRef.total || 0) - (_.formRef.commercial_discount || 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    );
                }
            },
        ],
        [
            {
                "type": "render",
                "render": () => {
                    return <Divider>Parcelas</Divider>
                }
            }
        ],
        [
            {
                "key": "installments",
                "type": "dynamic-input-list",
                "fields": [
                    [
                        {
                            title: 'Parcela',
                            key: 'installment',
                            type: 'text',
                            colSize: '4',
                        },
                        {
                            title: 'Valor',
                            key: 'value',
                            type: 'money',
                            colSize: '6',
                        },
                        {
                            title: 'Vencimento',
                            key: 'dueDate',
                            type: 'date-picker',
                            colSize: '6',
                            'element-attr': { 'required': false, 'showToday': false, allowClear: false }
                        },
                    ]
                ]
            }
        ],
        [
            {
                "type": "render",
                "key": "installments_total",
                "render": (_) => {
                    const total = ((_ && _.formRef && _.formRef.installments) || [])
                        .reduce((prev, current) => prev += parseFloat(current.value || '0'), 0)

                    return (
                        <div style={{ textAlign: 'right' }}>
                            <b>
                                Soma das parcelas: {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </b>
                        </div>
                    )
                }
            },
        ]
    ]
}

export default generalInfoFields