import React from 'react'

const priceCompositionFields = (props) => {

    return [
        [
            {
                title: 'Código',
                key: 'product_code_in_price_composition_fields',
                type: "render",
                colSize: 5,
                render: (_) => {
                    return (
                        <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                            {_.formRef.code}
                        </div>
                    );
                },
            }
        ],
        [
            {
                title: 'Total de matéria prima',
                key: 'total_primary_mat',
                type: "render",
                colSize: 5,
                render: (_) => {
                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold', fontSize: 'larger' }}>
                            {props.calculateTotalCost(_.formRef.descriptions).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    );
                },
            }
        ],
        [
            {
                title: 'Referência HH',
                key: 'unity_value_hh',
                type: 'pointer',
                colSize: 8,
                options: props.manHours.map(manHour => {
                    return { value: manHour.id, label: `${manHour.get('description')}` }
                }),
                'relation-select': {
                    class: 'ManHour',
                    label: 'description',
                    value: 'objectId',
                    key: 'objectId'
                },
                // loadFirstValue: true,
                'element-attr': { required: false, mode: 'default' }
            },
            {
                title: 'Quantidade HH',
                key: 'size_hh',
                type: "number",
                colSize: 3,
            },
            {
                title: 'Valor unitário HH',
                key: 'ref_unity_value_hh',
                type: 'render',
                colSize: 3,
                render: (form, node, value) => {
                    const manHour = props.manHours.find((manHour) => manHour.id === form.formRef.unity_value_hh)

                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold' }}>
                            {((manHour && manHour.get('calculatedTotal')) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    )
                }
            },
            {
                title: 'Total mão de obra',
                key: 'total_hand',
                type: "render",
                colSize: 3,
                render: (form, node, value) => {
                    const manHour = props.manHours.find((manHour) => manHour.id === form.formRef.unity_value_hh)

                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold' }}>
                            {(((manHour && manHour.get('calculatedTotal')) || 0) * form.formRef.size_hh).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    )
                }
            }
        ],
        [
            {
                title: 'Custo de produção (MP + Mão de obra)',
                key: 'production_cost',
                type: "render",
                colSize: 8,
                render: (form) => {
                    let total = 0;

                    const manHour = props.manHours.find((manHour) => manHour.id === form.formRef.unity_value_hh)
                    if (manHour) {
                        total = props.calculateProductionCost({ ...form.formRef, ref_unity_value_hh: manHour.get('calculatedTotal') })
                    }

                    return (
                        <div style={{ lineHeight: '40px', fontWeight: 'bold', fontSize: 'larger' }}>
                            {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    );
                },
            }
        ],
        [
            {
                title: 'ICMS (18%) + Federal (6,29%)',
                key: 'pis_conf',
                type: 'number',
                colSize: 6,
                'element-attr': { required: false },
            },
            {
                title: 'Custo Fixo + Comercial (%)',
                key: 'custo_comer',
                type: 'number',
                colSize: 6,
                'element-attr': { required: false },
            },
            {
                title: 'Margem de contribuição (%)',
                key: 'margin_contri',
                type: 'number',
                colSize: 6,
                'element-attr': { required: false },
            },
        ],
        [
            {
                title: 'Preço Margem Zero',
                key: 'price_margin_zero',
                type: "render",
                colSize: 8,
                render: (form) => {
                    let total = 0;

                    const manHour = props.manHours.find((manHour) => manHour.id === form.formRef.unity_value_hh)
                    if (manHour) {
                        total = props.calculateTotal({ ...form.formRef, ref_unity_value_hh: manHour.get('calculatedTotal') }, true)
                    }

                    return (
                        <div>
                            <div style={{ lineHeight: '40px', fontWeight: 'bold', fontSize: 'larger' }}>
                                {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </div>
                            <div>ICMS (18%) + Federal (6,29%): {(total * (form.formRef.pis_conf / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                            <div>Custo Fixo + Comercial: {(total * (form.formRef.custo_comer / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                        </div>
                    );
                },
            },
            {
                title: 'Preço Margem Atribuída',
                key: 'price_margin_applied',
                type: "render",
                colSize: 8,
                render: (form) => {
                    let total = 0;

                    const manHour = props.manHours.find((manHour) => manHour.id === form.formRef.unity_value_hh)
                    if (manHour) {
                        total = props.calculateTotal({ ...form.formRef, ref_unity_value_hh: manHour.get('calculatedTotal') })
                    }

                    return (
                        <div>
                            <div style={{ lineHeight: '40px', fontWeight: 'bold', fontSize: 'larger' }}>
                                {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </div>
                            <div>ICMS (18%) + Federal (6,29%): {(total * (form.formRef.pis_conf / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                            <div>Custo Fixo + Comercial: {(total * (form.formRef.custo_comer / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                            <div>Margem de Contribuição: {(total * (form.formRef.margin_contri / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                        </div>
                    );
                },
            },
        ]
    ]
}

export default priceCompositionFields