import React from 'react'
import { Icon } from 'antd'
import { findAll } from '../../../utils/db_functions'
import PqAvaliationTable from './entities/pq-avaliation-table'

let providers = []

const InputQualityAssessmentComponent = {
	"fields": [
		[
			{
				"title": "Número",
				"key": "code",
				"type": "render",
				"element-attr": { required: false },
				colSize: 6,
				render: (_, form) => {
					return <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>{form.state.formRef.code}</div>
				},
				beforeSave: async (module, parseObject) => {
					if (!module.state.formRef.code) {
						const code = await generateCode()
						await parseObject.set('code', code)
					}
					return true;
				}
			},
			{
				"title": "Nota final",
				"key": "final_grade_render",
				"type": "render",
				"element-attr": { required: false },
				colSize: 4,
				render: (_, form) => {
					return <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>{form.state.formRef.final_grade}</div>
				}
			},
			{
				"title": "Performance",
				"key": "final_performace_render",
				"type": "render",
				"element-attr": { required: false },
				colSize: 4,
				render: (_, form) => {
					return <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>{form.state.formRef.final_performace}</div>
				}
			},
			{
				"title": "Status",
				"key": "final_status_render",
				"type": "render",
				"element-attr": { required: false },
				colSize: 4,
				render: (_, form) => {
					return <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>{form.state.formRef.final_status}</div>
				}
			},
		],
		[
			{
				"title": "Provedor",
				"key": "provider",
				"type": "select",
				"options": [],
				"colSize": 8,
				"element-attr": { "required": true, 'mode': 'default' },
			},
		],
		[
			{
				title: "Avaliação PQ12",
				key: "pq_avaliation_render",
				type: "render",
				render: (_, form) => {
					return (
						<PqAvaliationTable
							nfs={_.formRef.pq_avaliation}
							updateAvaliation={pq_avaliation => {
								_.formRef.pq_avaliation = pq_avaliation

								const finalAvaliation = calculatePQ12Avaliation(pq_avaliation)
								_.formRef.final_grade = finalAvaliation.final_grade
								_.formRef.final_performace = finalAvaliation.final_performace
								_.formRef.final_status = finalAvaliation.final_status

								form.state.invalidForm = false
								form.forceUpdate()
							}}
						/>
					)
				},

			}
		],
		[
			{ key: 'pq_avaliation' },
			{ key: 'final_grade' },
			{ key: 'final_performace' },
			{ key: 'final_status' },
		]
	],
	submit: { collection: "InputQualityAssessment" },
	FormHasMounted: async (_) => {
		if (!providers.length)
			providers = await findAll('InputProvider', 1000)

		_.module.form.fields[1][0].options = providers.map(provider => {
			return { value: provider.id, label: `${provider.get("code")} - ${provider.get("name")}` }
		})

		_.forceUpdate()
	},
	ListWillMount: async (_) => {
		providers = await findAll('InputProvider', 1000)
		_.forceUpdate()
	},
	schema: [
		{
			title: "Número",
			key: "code",
			dataIndex: "code",
			width: '100px',
			type: "text",
		},
		{
			title: "Provedor",
			key: "provider",
			dataIndex: "provider",
			type: "render",
			render: (v) => {
				let provider = ''
				providers.forEach(el => {
					if (el.id === v) provider = el.get('name')
				})
				return provider
			}
		},
		{
			title: "Avaliação PQ12",
			key: "pq_avaliation",
			dataIndex: 'pq_avaliation',
			align: 'center',
			width: '150px',
			render: (_, row) => {
				if (row.final_grade) return row.final_performace
				return '-'
			}
		},
	],
	"title-navigation": <div><Icon type="form" /> Avaliação Qualidade</div>,
	"title-module": "Avaliação Qualidade",
	"module": "InputQualityAssessment",
	"router-base": "/panel/avaliacao-qualidade"
}

const generateCode = async () => {
	const avaliations = await findAll("InputQualityAssessment", null, null, null, "code", "descending")
	let intCode = parseInt(avaliations[0].get('code')) + 1
	return `${intCode < 10 ? '00' : intCode < 100 ? '0' : ''}${intCode}`
}

const calculatePQ12Avaliation = (nfs) => {
	let arrayOfAvaliationFields = ['deadline', 'attendance', 'quality', 'adm']

	let final_grade = ""
	let final_performace = ""
	let final_status = ""

	let local_final_grade = 0
	nfs.forEach((nf, i) => {
		let calcGrade = 0
		arrayOfAvaliationFields.forEach(avaliationField => {
			if (nf[avaliationField]) calcGrade += nf[avaliationField]
			else calcGrade = 0
		})

		if (calcGrade) {
			// Registrar nota, performance e status
			local_final_grade += calcGrade / 4

			let avaliation = { performace: 'Ruim', status: 'NQ' }
			if (local_final_grade / (i + 1) >= 9) avaliation = { performace: 'Ótimo', status: 'QP' }
			else if (local_final_grade / (i + 1) >= 7) avaliation = { performace: 'Bom', status: 'Q' }
			else if (local_final_grade / (i + 1) >= 5) avaliation = { performace: 'Regular', status: 'QR' }

			final_grade = (local_final_grade / (i + 1)).toFixed(2)
			final_performace = avaliation['performace']
			final_status = avaliation['status']
		}
	})

	return {
		final_grade,
		final_performace,
		final_status,
	}
}

export default InputQualityAssessmentComponent