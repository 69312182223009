import { Button, Col, Form, message, Modal, Popover, Row, Select, Table } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React, { Component, Fragment } from 'react'
import { findAll } from '../../../../utils/db_functions'

export default class AuthorizedGroups extends Component {

    constructor(props) {
        super(props)
        this.state = {
            groups: [],

            authorizedGroups: this.props.authorizedGroups,

            authorizedGroupsModal: false,

            authorizedGroupFormModal: false,
            authorizedGroupForm: {
                authorizedGroupIndex: null,
                group: null,
            },
        }
    }

    componentDidMount = async () => {
        let groups = await findAll('Group')
        groups = groups.map(group => {
            return {
                value: group.id,
                label: group.get('name'),
            }
        })

        this.setState({ groups })
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.authorizedGroups !== this.state.authorizedGroups)
            this.setState({ authorizedGroups: nextProps.authorizedGroups })
    }

    saveAuthorizedGroup = () => {
        if (!this.state.authorizedGroupForm.group) {
            message.error('Preencha todos os campos antes de salvar')
        } else {
            let authorizedGroups = this.state.authorizedGroups

            if (
                this.state.authorizedGroupForm.authorizedGroupIndex ||
                this.state.authorizedGroupForm.authorizedGroupIndex === 0
            ) {
                authorizedGroups[this.state.authorizedGroupForm.authorizedGroupIndex] = this.state.authorizedGroupForm.group
            } else {
                authorizedGroups.push(this.state.authorizedGroupForm.group)
            }

            this.setState({
                authorizedGroups,
                authorizedGroupFormModal: false,
                authorizedGroupForm: { authorizedGroupIndex: null, group: null }
            })

            this.props.updateAuthorizedGroups(authorizedGroups)
        }
    }

    render() {

        const authorizedGroupsColumns = [
            {
                title: 'Grupo',
                dataIndex: 'group',
                key: 'group',
                render: (v, _) => {
                    const group = this.state.groups.find(el => el.value === _)
                    return group && group.label
                }
            },
            {
                title: 'Ações',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                width: '100px',
                render: (v, _, index) => {
                    return (
                        <ButtonGroup>
                            <Popover content='editar'>
                                <Button
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({
                                            authorizedGroupFormModal: true,
                                            authorizedGroupForm: {
                                                authorizedGroupIndex: index,
                                                group: _,
                                            }
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Excluir'>
                                <Button
                                    icon='delete'
                                    onClick={() => {
                                        let _this = this
                                        Modal.confirm({
                                            iconType: 'exclamation-circle',
                                            title: 'Tem certeza que deseja exlcuir essa regra?',
                                            content: 'Essa ação não poderá ser desfeita',
                                            onOk() {
                                                let authorizedGroups = _this.state.authorizedGroups || []
                                                authorizedGroups.splice(index, 1)
                                                _this.setState({ authorizedGroups })
                                            },
                                            okText: 'Sim',
                                            cancelText: 'Não, deixa pra lá'
                                        })
                                    }}
                                />
                            </Popover>
                        </ButtonGroup>
                    )
                }
            }
        ];

        return (
            <Fragment className='rules-card-button'>
                <Popover content='Grupos autorizados'>
                    <Button
                        icon='team'
                        onClick={() => this.setState({ authorizedGroupsModal: true })}
                    />
                </Popover>

                <Modal
                    title={`Grupos de usuários - ${this.props.description}`}
                    visible={this.state.authorizedGroupsModal}
                    onCancel={() => this.setState({ authorizedGroupsModal: false })}
                    footer={[
                        <Button type='primary' onClick={() => this.setState({ authorizedGroupFormModal: true })}>Adicionar novo grupo de usuário</Button>,
                        <Button onClick={() => this.setState({ authorizedGroupsModal: false })}>Fechar</Button>
                    ]}
                >
                    <Table
                        columns={authorizedGroupsColumns}
                        dataSource={this.state.authorizedGroups}
                        pagination={false}
                        locale={{ emptyText: 'Sem grupos' }}
                    />
                </Modal>

                <Modal
                    title='Grupo de usuário'
                    visible={this.state.authorizedGroupFormModal}
                    onCancel={() => this.setState({ authorizedGroupFormModal: false, authorizedGroupForm: { authorizedGroupIndex: null, group: null } })}
                    cancelText='Cancelar'
                    onOk={() => this.saveAuthorizedGroup()}
                    okText='Salvar'
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Grupo'>
                                <Select
                                    value={this.state.authorizedGroupForm.group}
                                    onChange={e => this.setState({ authorizedGroupForm: { ...this.state.authorizedGroupForm, group: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.groups.map(option => {
                                            return (
                                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </Fragment>
        )

    }

}