import { Form, Input, Row } from "antd"
import React from "react"

const productStepObservation = ({
    index,
    getProductValue,
    updateProductData,
}) => {
    return (
        <Row>
            <h2>
                Observações do produto
            </h2>

            <Form.Item label="Ambientes de instalação">
                <Input
                    defaultValue={getProductValue('observation')}
                    onBlur={event => updateProductData('observation', index, event.target.value)}
                    addonBefore={getProductValue('env')}
                    placeholder="Ambientes de instalação"
                />
            </Form.Item>

            <Form.Item label="Detalhes de projeto">
                <Input
                    defaultValue={getProductValue('observation_1')}
                    onBlur={event => updateProductData('observation_1', index, event.target.value)}
                    placeholder="Detalhes de projeto"
                />
            </Form.Item>

            <Form.Item label="Material não incluso no Kit">
                <Input
                    defaultValue={getProductValue('observation_2')}
                    onBlur={event => updateProductData('observation_2', index, event.target.value)}
                    placeholder="Material não incluso no Kit"
                />
            </Form.Item>

            <Form.Item label="Observação">
                <Input
                    defaultValue={getProductValue('observation_3')}
                    onBlur={event => updateProductData('observation_3', index, event.target.value)}
                    placeholder="Observação"
                />
            </Form.Item>

            <Form.Item label="Observação comercial (essa informação não é exibida na proposta)">
                <Input
                    defaultValue={getProductValue('commercial_observation')}
                    onBlur={event => updateProductData('commercial_observation', index, event.target.value)}
                    placeholder="Observação comercial"
                />
            </Form.Item>
        </Row>
    )
}

export default productStepObservation