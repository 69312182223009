import React, { Component, Fragment } from 'react'
import { findAll, findWhere, getCurrentUser, save } from '../../../../utils/db_functions'
import Auth from '../../../../auth/Auth'
import _ from 'lodash'
import { Button, Col, Form, Input, InputNumber, message, Modal, notification, Popover, Select, Switch, List, Row, Avatar, Checkbox } from 'antd'
import Parse from 'parse'
import { deleteSolutionSquare } from '../components/manage-proposal-functions.jsx'
import "../manage-proposal.css"

export default class SolutionsSquareModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [],

            visible: false,
            hasChanged: false,

            solutionSquareListModal: false,
            solutionSquares: this.props.solutionSquares,

            chooseProposalModal: false,
            selectedProposals: [],
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ solutionSquares: nextProps.solutionSquares });
    };

    showModal = async () => {
        const user = await getCurrentUser()
        let auth = new Auth(user)
        auth.init(async () => {
            if (auth.hasAction(['GenerateSolutions', '*'])) {
                // let allProposals = this.props.allProposals
                // let selectedProposals = []
                // allProposals.forEach(proposal => {
                //     if (
                //         proposal.proposal_code.split('-')[0] === this.props.proposal.proposal_code.split('-')[0] &&
                //         proposal.proposal_code.split('-')[1] === this.props.proposal.proposal_code.split('-')[1] &&
                //         proposal.checked &&
                //         !_.has(proposal, 'children')
                //     ) {
                //         selectedProposals.push(proposal)
                //     }
                // })
                // selectedProposals = _.uniqBy(selectedProposals, e => e.proposal_code)
                let selectedProposals = this.state.selectedProposals

                if (selectedProposals.length === 3 || selectedProposals.length === 5) {
                    selectedProposals = selectedProposals.sort((a, b) => (a.final_value > b.final_value) ? 1 : ((b.final_value > a.final_value) ? -1 : 0))
                    const options = new Array(selectedProposals.length).fill({ label: null, id: null }).map((option, index) => {
                        return { ...option, label: '' }
                    })
                    this.setState({ options: options, solutions: {}, selectedProposals: selectedProposals })

                    const channels = await findAll('SalesChannel')
                    this.setState(state => ({
                        ...state,
                        visible: true,
                        solutions: {
                            // 'B-': null,
                            // 'B': null,
                            // 'B+': null,
                            // 'A': null,
                            // 'A+': null
                        },
                        deadline: '10 - 12 semanas',
                        instalationDeadline: '2 - 4 semanas',
                        certifications: {
                            quality: `ABNT NBR ISO 9001:2015 (Vset2020)
                EMPRESA CERTIFICADA DESDE
                1995`,
                            product: `PORTAS DE MADEIRA PARA
                EDIFICAÇÕES
                ABNT NBR 15930-2 (V-dez2019)
                KIT PORTA DE ENTRADA (PEM)`,
                            sustainability: `FSC-CADEIA DE CUSTÓDIA DA
                MADEIRA
                FSC-BV COC 119123 (V-dez2018)`
                        },
                        channels: channels,
                    }))

                    options.forEach((snap, i) => {
                        this.handleSelect(i, { ..._.get((this.state.solutions || {}), [i]), id: selectedProposals[i].key })
                    })

                } else {
                    notification.info({
                        message: 'Atenção',
                        description: 'Selecione 3 ou 5 propostas para gerar o quadro de soluções.',
                        duration: 5
                    })
                }

            } else message.error('Você não tem permissão para acessar esse documento')
        })
    }

    handleSelect = (key, values) => {
        this.setState(state => {
            return {
                ...state,
                solutions: {
                    ...(state.solutions || {}),
                    [key]: {
                        ...state.solutions[key],
                        ...values
                    }
                }
            }
        })
    }

    handleOk = () => {
        const registrationDate = Date.now()
        const solutions = _.get(this.state, ['solutions']);
        const deadline = _.get(this.state, ['deadline']);
        const instalationDeadline = _.get(this.state, ['instalationDeadline']);
        const certifications = _.get(this.state, ['certifications']);
        const payments = _.get(this.state, ['payments'], [])
        const options = _.get(this.state, ['options'], [])
        const channel = _.get(this.state, ['salesChannel'], null)
        const showPaymentData = _.get(this.state, ['showPaymentData'], false)
        const check = options.every((_option, index) => {
            const label = _.get(solutions, [index, 'label'])
            const type = _.get(solutions, [index, 'type'])
            const id = _.get(solutions, [index, 'id'])
            return (label && type && id)
        })
        if (check && channel && deadline && instalationDeadline) {
            Parse.Cloud.run('solution',
                {
                    certifications,
                    solutions,
                    deadline,
                    instalationDeadline,
                    payments,
                    salesChannel: this.state.salesChannel,
                    showPaymentData,
                    options,
                    registrationDate
                }).then(async response => {
                    window.open(process.env.REACT_APP_PUBLIC + response.url, '_blank')
                    this.setState({ hasChanged: true })

                    // Salvar quadro de soluções no banco
                    let ids = Object.keys(solutions).map(key => solutions[key].id)
                    let code = ids.join('') + '-' + registrationDate
                    const user = await getCurrentUser()
                    for (let proposalIndex = 0; proposalIndex < this.state.selectedProposals.length; proposalIndex++) {
                        const proposal = this.state.selectedProposals[proposalIndex];

                        let arrayOfSolutionsSquare = proposal.solutionsSquareIn || []

                        arrayOfSolutionsSquare.push({ code: code, author: user.get('name'), salesChannel: this.state.salesChannelName })

                        this.setState({ solutionsSquaresToRender: arrayOfSolutionsSquare })

                        const proposalToUpdate = await findWhere('Proposal', 'proposal_code', [proposal.proposal_code])
                        await save('Proposal', { 'solutionsSquareIn': arrayOfSolutionsSquare }, proposalToUpdate[0].id)
                    }

                }).catch(() => {
                    notification.error({
                        message: 'erro ao salvar',
                        description: 'Alguma coisa deu errado, verifique se as propostas selecionadas foram preenchidas corretamente.',
                        duration: 5,
                    })
                })
        } else {
            notification.info({
                message: 'Atenção',
                description: 'Preencha todos os campos.'
            })
        }
    }

    // options = this.state.options || []
    // solutions = this.state.solutions || {}
    defaultOptions = {
        0: 'SMART',
        1: 'SMART',
        2: 'DESIGN',
        3: 'PREMIUM',
        4: 'PREMIUM'
    }
    defaultTypes = {
        0: 'B',
        1: 'B+',
        2: 'A',
        3: 'A+',
        4: 'A++'
    }

    calcInstallmentsPercent = installment => {
        let discount = 0
        let signal = 0

        if (this.state.payments) {
            discount = this.state.payments.discount || 0
            signal = this.state.payments.signal || 0
        }

        this.setState(state => {
            return {
                ...state,
                payments: {
                    ..._.get(state, ['payments'], {}),
                    installmentsPer: ((100 - discount - signal) / (installment || 1)).toFixed(2)
                }
            }
        })
    }

    renderPaymentOptions = () => {
        const handleInput = (key, value) => {
            this.setState(state => {
                return {
                    ...state,
                    payments: {
                        ..._.get(state, ['payments'], {}),
                        [key]: value
                    }
                }
            });
        }

        const handleSwitch = value => {
            this.setState({ showPaymentData: value })
        }

        return (
            <div>
                <Col span={24}>
                    <h2>Parcelado</h2>
                    <Col span={6}>
                        <Form.Item className="space" label={'Desconto %'} required>
                            <InputNumber
                                min={0} max={100}
                                placeholder="Desconto"
                                onChange={event => {
                                    handleInput("discount", event)
                                    this.calcInstallmentsPercent(this.state.payments ? this.state.payments.installmentsNum || 1 : 1)
                                }}
                                type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item className="space" label={'Sinal %'} required>
                            <InputNumber
                                min={0} max={100}
                                placeholder="Sinal"
                                onChange={event => {
                                    handleInput("signal", event)
                                    this.calcInstallmentsPercent(this.state.payments ? this.state.payments.installmentsNum || 1 : 1)
                                }}
                                type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item className="space" label={'Número de Parcelas'} required>
                            <InputNumber
                                placeholder="Número de Parcelas"
                                onChange={event => {
                                    handleInput("installmentsNum", event)
                                    this.calcInstallmentsPercent(event)
                                }}
                                type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item className="space" label={'Percentual das Parcelas'}>
                            <Input disabled
                                defaultValue={`${this.state.payments ? this.state.payments.installmentsPer || 0 : 0}%`}
                                placeholder="Percentual das Parcelas" />
                        </Form.Item>
                    </Col>
                </Col>
                <Col span={24}>
                    <h2>Antecipado</h2>
                    <Col span={8}>
                        <Form.Item className="space" label={'Porcentagem Antecipado %'} required>
                            <InputNumber
                                min={0} max={100}
                                placeholder="Porcentagem Antecipado"
                                onChange={event => handleInput("earlyPayment", event)}
                                type='number' />
                        </Form.Item>
                    </Col>
                </Col>
                <Col span={24}>
                    <Col span={24}>
                        <Form.Item label="Apresentar valores para Parcelado e Antecipado">
                            <Switch onChange={handleSwitch}>Exibir</Switch>
                        </Form.Item>
                    </Col>
                </Col>
            </div>
        )
    }

    optionsSelect = (options, selectedProposals) => {
        if (options) {
            return (options || []).map((_option, index) => {
                return (
                    <Form.Item key={index} /*layout="horizontal"*/>
                        <div style={{ display: 'flex' }}>
                            <Form.Item label="Proposta" style={{ marginRight: '15px' }}>
                                <Input
                                    disabled
                                    value={selectedProposals[index]['proposal_code']}
                                />
                            </Form.Item>

                            <Form.Item label="Primeira Legenda" required style={{ marginRight: '15px' }}>
                                <Input
                                    placeholder="Primeira Legenda"
                                    value={_.get((this.state.solutions || {}), [index, 'type'], '')}
                                    onChange={event => {
                                        this.handleSelect(index, { type: event.target.value })
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="Segunda Legenda" required>
                                <Input
                                    placeholder="Segunda Legenda"
                                    value={_.get((this.state.solutions || {}), [index, 'label'], '')} // this.defaultOptions[index])}
                                    onChange={event => {
                                        this.handleSelect(index, { label: event.target.value })
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Form.Item>
                )
            })
        }
    }

    renderCertifications = () => {

        const handleChange = (key, value) => {
            this.setState(state => {
                return {
                    ...state,
                    certifications: {
                        ..._.get(state, ['certifications'], {}),
                        [key]: value
                    }
                }
            })
        }

        const defaultValues = {
            quality: `ABNT NBR ISO 9001:2015 (Vset2020)
      EMPRESA CERTIFICADA DESDE
      1995`,
            product: `PORTAS DE MADEIRA PARA
      EDIFICAÇÕES
      ABNT NBR 15930-2 (V-dez2019)
      KIT PORTA DE ENTRADA (PEM)`,
            sustainability: `FSC-CADEIA DE CUSTÓDIA DA
      MADEIRA
      FSC-BV COC 119123 (V-dez2018)`
        }

        return (
            <div>
                <Form.Item label="Gestão da qualidade">
                    <Input.TextArea
                        placeholder="Gestão da qualidade"
                        onChange={event => handleChange('quality', event.target.value)}
                        value={_.get(this.state, ['certifications', 'quality'], defaultValues.quality)}>
                    </Input.TextArea>
                </Form.Item>
                <Form.Item label="Desempenho de produtos">
                    <Input.TextArea
                        placeholder="Desempenho de produtos"
                        onChange={event => handleChange('product', event.target.value)}
                        value={_.get(this.state, ['certifications', 'product'], defaultValues.product)}
                    >
                    </Input.TextArea>
                </Form.Item>
                <Form.Item label="Sustentabilidade">
                    <Input.TextArea
                        placeholder="Sustentabilidade"
                        onChange={event => handleChange('sustainability', event.target.value)}
                        value={_.get(this.state, ['certifications', 'sustainability'], defaultValues.sustainability)}
                    >
                    </Input.TextArea>
                </Form.Item>
            </div>
        )
    }

    handleSelectChange = (value) => {
        const options = new Array(value).fill({ label: null, id: null }).map((option, index) => {
            return { ...option, label: this.defaultOptions[index] }
        })
        this.setState({ options: options, solutions: {} })
    }

    handleObservation = event => {
        this.setState({ observation: event.target.value })
    }

    getSelectedsIds = index => {
        const ids = _.map(this.state.solutions, (solution) => {
            return _.get(solution, 'id', null)
        })
        return _.remove(ids, index)
    }

    handleValidate = (date) => {
        this.setState({ validationDate: date })
    }

    channelSales = () => {
        const handleChangeChannel = (channel) => {
            this.setState({ salesChannel: channel })

            // salvar nome do canal de vendas
            let channelChild = ''
            this.state.channels.forEach(snap => {
                if (snap.id === channel) channelChild = snap.get('name')
            })
            this.setState({
                salesChannel: channel,
                salesChannelName: channelChild
            })
        }
        return (
            <div>
                <Select placeholder="Selecione um canal de vendas" onChange={handleChangeChannel}>
                    {
                        (this.state.channels || []).map((channel) => {
                            return <Select.Option value={channel.id} key={channel.id}>{channel.get('name')}</Select.Option>
                        })
                    }
                </Select>
            </div>
        )
    }

    renderSquareOptions = () => {
        return (
            <div>
                <Form.Item label="Canal de Vendas" required>
                    {this.channelSales()}
                </Form.Item>
                {this.optionsSelect(this.state.options, this.state.selectedProposals)}

                <Form.Item label="Prazo de entrega a partir do Projeto Executivo aprovado">
                    <Input
                        placeholder="Prazo de entrega a partir do Projeto Executivo aprovado"
                        onChange={event => this.setState({ deadline: event.target.value })}
                        value={_.get(this.state, ['deadline'])}>
                    </Input>
                </Form.Item>

                <Form.Item label="Prazo de Instalação a partir do Checklist de Obra aprovado">
                    <Input
                        placeholder="Prazo de Instalação a partir do Checklist de Obra aprovado"
                        onChange={event => this.setState({ instalationDeadline: event.target.value })}
                        value={_.get(this.state, ['instalationDeadline'])}>
                    </Input>
                </Form.Item>
            </div>
        )
    }

    renderSolutionSquare = (solutionSquare, index) => {
        const date = new Date(solutionSquare.code.split('-')[1] / 1).toLocaleString("pt-BR")
        const pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_solution/${solutionSquare.code}.pdf`.replace(/\s/g, '-')

        return (
            <Row gutter={24}>
                <Col
                    span={4}
                    style={{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        paddingTop: '20px',
                    }}
                >
                    <Avatar
                        style={{ verticalAlign: 'middle' }}
                        size="large"
                        src={this.findUserImg(solutionSquare.author)}
                    >
                        {
                            !this.findUserImg(solutionSquare.author) ? solutionSquare.author[0].toUpperCase() : ''
                        }
                    </Avatar>
                </Col>

                <Col span={20}>
                    <Row>
                        <span className="manager-proposal-author-comment">{solutionSquare.author}</span>
                        <span className="manager-proposal-date-comment">{date}</span>
                    </Row>

                    <Row>
                        Canal de vendas: {solutionSquare.salesChannel || ''}
                    </Row>

                    <Row style={{ fontSize: '12px' }}>
                        <span
                            className="link-style"
                            onClick={() => window.open(pdfName, '_blink')}
                        >
                            Quadro de soluções
                        </span>&nbsp; &nbsp;

                        <span
                            className="link-style"
                            style={{ color: 'red' }}
                            onClick={() => deleteSolutionSquare(this.props.proposal, solutionSquare)}
                        >
                            Excluir
                        </span>
                    </Row>

                </Col>
            </Row>
        )
    }

    findUserImg = (userName) => {
        const user = this.state.users.find(user => user.name === userName)
        return user && user.img
    }

    render() {
        return (
            <Fragment>
                <Popover content='Quadro de soluções'>
                    <Button
                        icon="table"
                        onClick={async () => {
                            const parseUsers = await findAll('User')
                            const users = parseUsers.map(user => {
                                return {
                                    id: user.id,
                                    name: user.get('name'),
                                    img: `${Parse.serverURL}/files/${Parse.applicationId}/${user.get('photo')}`
                                }
                            })

                            this.setState({ solutionSquareListModal: true, users })
                        }}
                    />
                </Popover>

                <Modal
                    title="Quadro de soluções"
                    visible={this.state.solutionSquareListModal}
                    onCancel={() => this.setState({ solutionSquareListModal: false })}
                    footer={[
                        <Button
                            type="primary"
                            icon="form"
                            onClick={() => this.setState({ chooseProposalModal: true })}
                        >
                            Adicionar novo quadro
                        </Button>
                    ]}
                >
                    <div className='manager-proposal-historic-modal'>
                        <List
                            className="comment-list"
                            itemLayout="horizontal"
                            dataSource={this.state.solutionSquares}
                            renderItem={(item, index) => (
                                <List.Item>
                                    {this.renderSolutionSquare(item, index)}
                                </List.Item>
                            )}
                            locale={{ emptyText: 'Sem quadros' }}
                        />
                    </div>
                </Modal>

                <Modal
                    title="Propostas"
                    visible={this.state.chooseProposalModal}
                    onCancel={() => {
                        this.setState({ selectedProposals: [], chooseProposalModal: false })
                    }}
                    cancelText='Cancelar'
                    okText='Avançar'
                    onOk={async () => {
                        if (this.state.selectedProposals.length !== 3 && this.state.selectedProposals.length !== 5)
                            message.error('Escolha 3 ou 5 proposta antes de continuar')
                        else {
                            await this.showModal()
                            this.setState({ chooseProposalModal: false })
                        }
                    }}
                >
                    Escolha 3 ou 5 propostas para gerar o Quadro de Soluções. <br /><br />

                    <Checkbox.Group
                        options={this.props.allProposals.map(proposal => {
                            return {
                                label: proposal.proposal_code,
                                value: proposal,
                            }
                        })}
                        onChange={selectedProposals => {
                            this.setState({ selectedProposals })
                        }}
                        defaultValue={[]}
                        value={this.state.selectedProposals}
                    />
                </Modal>

                <Modal
                    title="Gerar Quadro de Soluções"
                    visible={this.state.visible}
                    onCancel={() => {
                        this.setState({ visible: false, selectedProposals: [] })
                        if (this.state.hasChanged)
                            window.location.reload()
                    }}
                    onOk={this.handleOk}
                    okText="Gerar quadro"
                    cancelText="Fechar"
                    // maskClosable={false}
                    // destroyOnClose={true}
                    width="60%">
                    <Form layout="horizontal">
                        {this.renderSquareOptions()}
                    </Form>
                </Modal>
            </Fragment>
        )
    }
}