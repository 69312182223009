/**
 * Arredonda um número.
 * @param {Number} n Número a ser arredondado.
 * @param {Number} digits Quantidade de digitos do arredondamento.
 * @returns Número arredondado.
 */
let roundTo = (n, digits) => {
    var negative = false;
    if (digits === undefined) {
        digits = 0;
    }
    if (n < 0) {
        negative = true;
        n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    let numberToReturn = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
        numberToReturn = (n * -1).toFixed(2);
    }
    return parseFloat(numberToReturn);
};

module.exports = {
    roundTo,
}