import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css'

import Panel from './components/panel/Panel';
import LoginApp from './components/login/Login';
import RecoveryPassword from './components/recovery-password/RecoveryPassword';

import Checklist from './components/checklist/checklistContainer';
import OrderFolder from './modules/order-manager/client-folder/external-components/client-folder';
import CustomerQualificationSurvey from './modules/commercial/qualification-survey/customer-qualification-survey';
import CustomerProposalRequest from './modules/commercial/qualification-survey/customer-proposal-request';
import CustomerSatisfactionSurvey from './modules/commercial/satisfaction-survey/customer-satisfaction-survey';
import PcpoSatisfactionSurvey from './modules/service/satisfaction-survey/pcpo/pcpo-satisfaction-survey';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { Parse } from 'parse'
import Exception from 'ant-design-pro/lib/Exception';
import 'ant-design-pro/dist/ant-design-pro.min.css';
import { Button } from 'antd'

Parse.initialize(process.env.REACT_APP_APPLICATION, process.env.REACT_APP_MASTERKEY)
Parse.serverURL = process.env.REACT_APP_SERVERURL

window.auth = function (Component) {
  let token = window.localStorage.getItem("app_session_token")
  token = (token === null) ? "" : token
  return (new Parse.Session().isValid(token) && !!Parse.User.current()) ? <Component /> : <LoginApp />
}

function MainRouter() {
  return (<Router>
    <div>
      <Switch>
        <Route exact path="/" component={LoginApp} />
        <Route path="/panel/" render={() => window.auth(Panel)} />
        <Route path="/recuperar-senha/:token" render={(props) => <RecoveryPassword router-props={props} />} />
        <Route path="/recuperar-senha" render={(props) => <RecoveryPassword router-props={props} />} />

        <Route path="/checklist" render={(props) => <Checklist router-props={props} />} />
        <Route path="/pasta-cliente/:id" render={(props) => <OrderFolder router-props={props} />} />
        <Route path="/cadastro-para-atendimento" render={(props) => <CustomerQualificationSurvey router-props={props} />} />
        <Route path="/solicitacao-proposta-cliente" render={(props) => <CustomerProposalRequest router-props={props} />} />
        <Route path="/pesquisa-satisfacao" render={(props) => <CustomerSatisfactionSurvey router-props={props} />} />
        <Route path="/pesquisa-satisfacao-pcpo" render={(props) => <PcpoSatisfactionSurvey router-props={props} />} />

        <Route exact render={() => {
          return <Exception type="404" style={{
            minHeight: 500,
            height: "80%",
            marginTop: "100px"
          }}
            desc="Pagina não encontrada."
            img='/images/404.svg'
            linkElement={() => <Link to="/"><Button type="primary">Voltar à página inicial</Button></Link>} />
        }} />
      </Switch>
    </div>
  </Router>
  )
}

ReactDOM.render(MainRouter(),
  document.getElementById('root'));
registerServiceWorker();
