import { Button, Modal, notification, Select } from 'antd'
import React, { Component } from 'react'
import { findWhere, save } from '../../../../utils/db_functions'

export default class StatusModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            statusModal: false,
            statusLoading: false,
            status: props.status || 'Ativa',
            proposal_code: props.proposal_code,

            proposal: this.props.proposal,
            color: 'orange',
            label: '',
            labelColor: 'black',
        }
    }

    componentDidMount = () => {
        let color = 'orange';
        let label = '';
        let labelColor = 'black';

        switch (this.props.proposal.proposal_management_status) {
            case 'Negociação':
                color = 'orange';
                label = 'T';
                labelColor = 'white';
                break;
            case 'NegociaçãoSemestre':
                color = 'orange';
                label = 'S';
                labelColor = 'white';
                break;
            case 'EspecificaçãoBIM':
                color = 'green';
                break;
            case 'Concorrência':
                color = 'yellow';
                break;
            case 'Aprovada':
                color = 'blue';
                break;
            case 'Parada':
                color = 'yellow';
                label = 'P';
                break;
            case 'Cancelada':
                color = 'red';
                label = 'X';
                labelColor = 'white';
                break;
            default:
                break;
        }

        if (this.props.proposal.orderGenerated) color = 'blue';

        this.setState({ color, label, labelColor })
    }

    render() {
        return (
            <div>

                <div
                    style={{
                        cursor: 'pointer',
                        backgroundColor: this.state.color,
                        width: '20px', height: '20px',
                        lineHeight: '20px',
                        margin: 'auto', borderRadius: '50%',
                        fontSize: '10px', fontWeight: 'bold',
                        color: this.state.labelColor,
                    }}
                    onClick={() => this.setState({ statusModal: true })}
                >{this.state.label}</div>

                <Modal
                    title='Atualizar Status'
                    visible={this.state.statusModal}
                    onCancel={() => this.setState({ statusModal: false, statusLoading: false })}
                    footer={[
                        <Button
                            key='send'
                            loading={this.state.statusLoading}
                            onClick={async () => {
                                this.setState({ statusLoading: true })

                                const proposalToUpdate = await findWhere('Proposal', 'proposal_code', [this.state.proposal_code])
                                await save('Proposal', { 'proposal_management_status': this.state.status }, proposalToUpdate[0].id)
                                notification.success({
                                    message: 'Atualização realizada',
                                    description: 'Status alterado com sucesso.',
                                    duration: 5
                                })
                                this.setState({ statusLoading: false })
                                window.location.reload();
                            }}
                        >Salvar</Button>
                    ]}
                >
                    <Select
                        placeholder='Status'
                        onChange={e => this.setState({ status: e })}
                        value={this.state.status}
                        style={{ width: '100%' }}
                    >
                        <Select.Option value={null}>Selecione...</Select.Option>
                        <Select.Option value='Ativa'>Ativa</Select.Option>
                        <Select.Option value='Negociação'>Negociação Trimestre</Select.Option>
                        <Select.Option value='NegociaçãoSemestre'>Negociação Semestre</Select.Option>
                        <Select.Option value='EspecificaçãoBIM'>Especificação BIM</Select.Option>
                        <Select.Option value='Concorrência'>Concorrência de obra</Select.Option>
                        <Select.Option value='Aprovada'>Aprovada</Select.Option>
                        <Select.Option value='Parada'>Parada</Select.Option>
                        <Select.Option value='Cancelada'>Cancelada</Select.Option>
                    </Select>
                </Modal>
            </div>
        )
    }
}