const fields = [
    [
        {
            'title': 'Contato',
            'key': 'contact',
            'type': 'text',
            'colSize': 4,
            'element-attr': { 'required': false, }
        },
        {
            'title': 'Cargo',
            'key': 'cargo',
            'type': 'text',
            'colSize': 5,
            'element-attr': { 'required': false }
        },
        {
            'title': 'Telefone',
            'key': 'phone',
            'type': 'text',
            'colSize': 3,
            'element-attr': { 'required': false },
            maskToValue: { mask: "(xx) xxxx-xxxx", onlyNumber: true }
        },
        {
            'title': 'Celular',
            'key': 'cel',
            'type': 'text',
            'colSize': 3,
            'element-attr': { 'required': false },
            maskToValue: { mask: "(xx) x xxxx-xxxx", onlyNumber: true }
        },
        {
            'title': 'Whatsapp',
            'key': 'whatsapp',
            'type': 'text',
            'colSize': 3,
            'element-attr': { 'required': false },
            maskToValue: { mask: "(xx) x xxxx-xxxx", onlyNumber: true }
        },
        {
            'title': 'E-mail',
            'key': 'email',
            'type': 'email',
            'colSize': 6,
            'element-attr': { 'required': false }
        },
    ]
]

const contactsFields = () => {
    return [
        [
            {
                "title": "Contatos do cliente",
                "key": "clientContacts",
                "type": "dynamic-input-list",
                "fields": fields,
                "element-attr": { "required": false }
            },
        ],
        [
            {
                "title": "Contatos da obra",
                "key": "workContacts",
                "type": "dynamic-input-list",
                "fields": fields,
                "element-attr": { "required": false }
            },
        ],
        [
            {
                "title": "Contatos do projeto",
                "key": "projectContacts",
                "type": "dynamic-input-list",
                "fields": fields,
                "element-attr": { "required": false }
            },
        ],
        [
            {
                "title": "Contatos de instalação",
                "key": "instalationContacts",
                "type": "dynamic-input-list",
                "fields": fields,
                "element-attr": { "required": false }
            },
        ],
        [
            {
                "title": "Contatos adm. financeiro",
                "key": "financesContacts",
                "type": "dynamic-input-list",
                "fields": fields,
                "element-attr": { "required": false }
            },
        ],
    ]
}

export default contactsFields