import React from 'react'
import { Icon, notification, Tag } from 'antd'
import Parse from 'parse'
export default {
	"fields": [
		[
			{
				"title": "Código",
				"key": "code",
				"type": "text",
				min: 4,
				max: 4,
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />,
				},
				beforeSave: async (Form, ParseObject, node) => {
					const query = new Parse.Query('Finishing')
					query.equalTo('code', Form.state.formRef.code)
					query.notEqualTo('objectId', ParseObject.id)
					const count = await query.count()
					if (count > 0) {
						notification.error({ message: 'Erro ao registar o acabamento', description: 'Esse código já está registrado.' })
						return false;
					}

					return true;
				}
			},
			{
				"title": "Acabamento",
				"key": "finishing",
				"type": "text",
				max: 5,
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				}
			}
		],
		[
			{
				"title": "Padrões de Cores",
				"key": "pattern_color",
				"type": "relation",
				"options": [],
				"relation-select": {
					"class": "PatternColors",
					"label": "pattern",
					"value": "objectId",
					"key": "objectId",
				},
				colSize: 12,
				"element-attr": { "required": false }
			},
			{
				"title": "Coleção",
				"key": "collection",
				"type": "pointer",
				"options": [],
				"relation-select": {
					"class": "Collections",
					"label": "name",
					"value": "objectId",
					"key": "objectId"
				},
				"element-attr": { "required": false, "mode": 'default' }
			}
		],
		[
			{
				"title": "Descrição",
				"key": "description",
				"type": "text",
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				}
			},
			{
				"title": "Componente onde é aplicado",
				"key": "component_finishing",
				"type": "select",
				"options": [
					{ label: 'Folha da porta', value: 'Folha da porta' },
					{ label: 'Marco e alizares', value: 'Marco e alizares' },
					{ label: 'Ferragens', value: 'Ferragens' }
				],
				"element-attr": { required: false, "mode": 'multiple' }
			}
		]
	],
	submit: {
		collection: "Finishing"
	},
	className: "config-component",
	schema: [
		{
			title: "Código",
			key: "code",
			dataIndex: "code",
			width: '80px',
			type: "text",
		},
		{
			title: "Acabamento",
			key: "finishing",
			dataIndex: "finishing",
			width: '120px',
			type: "text",
		},
		{
			title: "Padrões de Cores",
			key: "pattern_color",
			dataIndex: "pattern_color",
			isRelation: true,
			fieldToSearch: 'pattern',
			type: "render",
			render: (v, row) => {
				let arrayOfPatterns = []
				row._realResponse['r_pattern_color'].forEach(res => {
					arrayOfPatterns.push(res.get('pattern'))
				})
				return arrayOfPatterns.sort().map((p, i) => <Tag key={i}>{p}</Tag>)
			}
		}
	],
	"title-navigation": <div><Icon type="bg-colors" /> Acabamentos</div>,
	"title-module": "Acabamentos",
	"module": "Finishing",
	"router-base": "/panel/acabamentos",

}