import React, { Component, Fragment } from "react";
import {
    Avatar,
    Button,
    Col,
    Form,
    Input,
    List,
    message,
    Modal,
    notification,
    Popover,
    Row,
    Select,
} from "antd";
import Auth from "../../../../auth/Auth";
import Parse from "parse";
import {
    findAll,
    findWhere,
    getCurrentUser,
    save,
} from "../../../../utils/db_functions";
import "../manage-proposal.css"

export default class CommentModal extends Component {
    state = {
        visible: false,
        isToEdit: false,

        proposal_code: null,
        comment: null,
        historic: this.props.historic,

        saveLoading: false,
        saveDisabled: true,

        hasChanged: false,

        users: [],

        commentFormModal: false,
    };

    componentWillReceiveProps = (nextProps) => {
        this.setState({ historic: nextProps.historic });
    };

    showModal = async () => {
        const user = await getCurrentUser();
        let auth = new Auth(user);
        auth.init(async () => {
            if (auth.hasAction(["viewHistoric", "*"])) {

                const parseUsers = await findAll('User')
                const users = parseUsers.map(user => {
                    return {
                        id: user.id,
                        name: user.get('name'),
                        img: `${Parse.serverURL}/files/${Parse.applicationId}/${user.get('photo')}`
                    }
                })

                this.setState({
                    visible: true,
                    isToEdit: false,
                    users
                });
            } else
                message.error("Você não tem permissão para acessar esse documento");
        });
    };

    hideModal = () => {
        this.props.reRenderList(this.state.historic);

        this.setState({
            visible: false,
            proposal_code: null,
            comment: null,
            saveLoading: false,
            saveDisabled: true,
        });

        if (this.state.hasChanged) window.location.reload();
    };

    setForm = (f, v) => {
        let formHistoric = {
            proposal_code: this.state.proposal_code,
            comment: this.state.comment,
            saveDisabled: this.state.saveDisabled,
        };
        if (f === "proposal_code") formHistoric.proposal_code = v;
        else if (f === "comment") formHistoric.comment = v;

        if (formHistoric.proposal_code && formHistoric.comment)
            formHistoric.saveDisabled = false;
        else formHistoric.saveDisabled = true;

        this.setState({
            proposal_code: formHistoric.proposal_code,
            comment: formHistoric.comment,
            saveDisabled: formHistoric.saveDisabled,
        });
    };

    editComment = (proposal_code, comment) => {
        this.setState({
            commentFormModal: true,
            proposal_code: proposal_code,
            comment: comment,
            isToEdit: true,
            hasChanged: true,
        });
    };

    deleteComment = async (proposal_code) => {
        // Pegar a proposta que será incluída o histórico
        let proposalToSave = this.props.proposals.filter((proposal) => {
            return proposal.proposal_code === proposal_code;
        });

        // Retirar histórico da lista de todos os históricos e ordenar
        let allHistoric = this.state.historic;
        allHistoric.splice(0, 1);
        allHistoric = allHistoric.sort((a, b) =>
            a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        // Inserir histórico na lista de histórico da proposta
        if (!proposalToSave[0].historic) proposalToSave[0]["historic"] = [];
        proposalToSave[0].historic.pop();

        const proposalToUpdate = await findWhere("Proposal", "proposal_code", [
            proposal_code,
        ]);
        await save(
            "Proposal",
            { historic: proposalToSave[0].historic },
            proposalToUpdate[0].id
        );
        notification.success({
            message: "Remoção realizada",
            description: "Histórico removido com sucesso.",
            duration: 5,
        });

        this.setState({
            proposal_code: null,
            comment: null,
            saveLoading: false,
            saveDisabled: true,
            historic: allHistoric,
            isToEdit: false,
            hasChanged: true,
        });
    };

    saveComment = async () => {
        this.setState({ saveLoading: true });

        // Pegar a proposta que será incluída o histórico
        let proposalToSave = this.props.proposals.filter((proposal) => {
            return proposal.proposal_code === this.state.proposal_code;
        });

        // Criar objeto a ser inserido no histórico da proposa
        const user = await getCurrentUser();
        let historicToPush = {
            comment: this.state.comment,
            author: user.get("name"),
            date: Date.now(),
        };

        // Colocar histórico na lista de todos os históricos e ordenar
        let allHistoric = this.state.historic;
        if (this.state.isToEdit) allHistoric.splice(0, 1);
        allHistoric.push({
            ...historicToPush,
            proposal_code: proposalToSave[0].proposal_code,
        });
        allHistoric = allHistoric.sort((a, b) =>
            a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        // Inserir histórico na lista de histórico da proposta
        if (!proposalToSave[0].historic) proposalToSave[0]["historic"] = [];
        if (this.state.isToEdit) proposalToSave[0].historic.pop();
        proposalToSave[0].historic.push(historicToPush);

        // Salvar no banco
        let versionOfProposal = this.state.proposal_code.split("-")[2]
        // if (parseInt(versionOfProposal, 10) < 10) versionOfProposal = "0" + versionOfProposal
        let proposalCode = `${this.state.proposal_code.split("-")[0]}-${this.state.proposal_code.split("-")[1]}-${versionOfProposal}`

        const proposalToUpdate = await findWhere("Proposal", "proposal_code", [
            proposalCode,
        ])

        await save(
            "Proposal",
            { historic: proposalToSave[0].historic },
            proposalToUpdate[0].id
        )

        if (this.state.isToEdit)
            notification.success({
                message: "Alteração realizada",
                description: "Histórico alterado com sucesso.",
                duration: 5,
            });
        else
            notification.success({
                message: "Registro realizado",
                description: "Histórico registrado com sucesso.",
                duration: 5,
            });

        this.setState({
            proposal_code: null,
            comment: null,
            saveLoading: false,
            saveDisabled: true,
            historic: allHistoric,
            isToEdit: false,
            hasChanged: true,
            commentFormModal: false
        });
    };

    renderComment = (comment, index) => {

        const date = new Date(comment.date).toLocaleString("pt-BR")

        return (
            <Row gutter={24}>
                <Col
                    span={4}
                    style={{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        paddingTop: '20px',
                    }}
                >
                    <Avatar
                        style={{ verticalAlign: 'middle' }}
                        size="large"
                        src={this.findUserImg(comment.author)}
                    >
                        {
                            !this.findUserImg(comment.author) ? comment.author[0].toUpperCase() : ''
                        }
                    </Avatar>
                </Col>

                <Col span={20}>
                    <Row>
                        <span className="manager-proposal-author-comment">{comment.author}</span>
                        <span className="manager-proposal-date-comment">{date}</span>
                    </Row>

                    <Row>
                        {comment.comment}
                    </Row>

                    <Row className="manager-proposal-comment">
                        <strong style={{ fontSize: 13 }}>Proposta: {comment.proposal_code}</strong>
                    </Row>

                    {
                        index === 0 && Parse.User.current().get("name") === comment.author ?
                            (
                                <Row style={{ fontSize: '12px' }}>
                                    <span
                                        className="link-style"
                                        onClick={() =>
                                            this.editComment(
                                                comment.proposal_code,
                                                comment.comment
                                            )
                                        }
                                    >
                                        Editar
                                    </span>&nbsp; &nbsp;

                                    <span
                                        className="link-style"
                                        style={{ color: 'red' }}
                                        onClick={() => {
                                            let _this = this;
                                            Modal.confirm({
                                                title: "Tem certeza que deseja exlcuir esse comentário do histórico?",
                                                content: "Essa ação não poderá ser desfeita",
                                                cancelText: 'Cancelar',
                                                okText: 'Sim',
                                                onOk() {
                                                    _this.deleteComment(comment.proposal_code);
                                                },
                                            });
                                        }}
                                    >
                                        Excluir
                                    </span>
                                </Row>
                            ) :
                            null
                    }
                </Col>
            </Row>
        )
    }

    findUserImg = (userName) => {
        const user = this.state.users.find(user => user.name === userName)
        return user && user.img
    }

    render() {
        return (
            <Fragment>
                <Popover content='Histórico da proposta'>
                    <Button
                        icon="unordered-list"
                        onClick={async () => this.showModal()}
                    />
                </Popover>

                <Modal
                    title="Histórico da proposta"
                    visible={this.state.visible}
                    onCancel={this.hideModal}
                    footer={[
                        <Button
                            type="primary"
                            icon="form"
                            onClick={() => this.setState({ commentFormModal: true })}
                        >
                            Adicionar novo comentário
                        </Button>
                    ]}
                >

                    <div className='manager-proposal-historic-modal'>
                        <List
                            className="comment-list"
                            itemLayout="horizontal"
                            dataSource={this.state.historic}
                            renderItem={(item, index) => (
                                <List.Item>
                                    {this.renderComment(item, index)}
                                </List.Item>
                            )}
                            locale={{ emptyText: 'Sem histórico' }}
                        />
                    </div>
                </Modal>

                <Modal
                    title='Comentário'
                    visible={this.state.commentFormModal}
                    onCancel={() => {
                        this.setState({
                            commentFormModal: false,
                            proposal_code: null,
                            comment: null,
                            saveLoading: false,
                            saveDisabled: true,
                            isToEdit: false,
                            hasChanged: true,
                        })
                    }}
                    footer={[
                        <Button
                            type="primary"
                            loading={this.state.saveLoading}
                            disabled={this.state.saveDisabled}
                            onClick={this.saveComment}
                        >
                            Salvar comentário
                        </Button>
                    ]}
                >
                    <Form.Item label={"Descreva o histórico da proposta"} required>
                        <Input.TextArea
                            style={{ width: "100%", marginBottom: "10px" }}
                            rows={4}
                            placeholder="Histórico da proposta"
                            onChange={(e) => this.setForm("comment", e.target.value)}
                            value={this.state.comment}
                        />
                    </Form.Item>

                    <Form.Item label={"Selecione a proposta"} required>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Selecione um opção"
                            optionFilterProp="children"
                            onChange={(e) => this.setForm("proposal_code", e)}
                            value={this.state.proposal_code}
                            filterOption={(input, option) =>
                                option.props.children
                                    // @ts-ignore
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value={null}>Selecione um opções</Select.Option>
                            {this.props.proposals.map((proposal, index) => {
                                return (
                                    <Select.Option value={proposal.proposal_code} key={index}>
                                        {proposal.proposal_code}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Modal>
            </Fragment>
        );
    }
}
