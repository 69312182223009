import React, { Component } from "react";
import { Card, Table, Icon, Button, Modal, Spin, notification } from "antd";
import Exception from "ant-design-pro/lib/Exception";
import Parse from "parse";
import { Route } from "react-router-dom";
import Auth from "../../auth/Auth";
import PageHeader from "ant-design-pro/lib/PageHeader";
import "./Module.css";
import ListModule from "./list/List";
import FormModule from './form/Form';

/**
 * Module é um componente responsável por criar um CRUD
 * Listagem, Cadastro e Edição.
 */
export default class Module extends Component {
  state = {
    status: this.props.status || "loader",
    dataTable: this.props.dataTable || [],
    permissions: this.props.permissions || [],
    actionsTable: this.props.actionsTable || [],
    schema: this.props.schema || [],
    loadingTable: false,
    loadingDataEdit: false,
    objectEdit: null,
    formRef: {},
    form: this.props.form,
    modal: {
      visible: false,
      content: null
    },
    invalidForm: false,
    AuthIsLoaded: false
  };

  tableContent = [];

  formRef = {};

  constructor(...args) {
    super(...args);
    Parse.initialize(process.env.REACT_APP_APPLICATION);
    Parse.serverURL = process.env.REACT_APP_SERVERURL;
    this.searchRef = null;
    this.Auth = new Auth(Parse.User.current())
  }

  /**
   * Verifica se no schema tem algum campo relation ou switch.
   * @param {null}
   * @returns schema atualizado com a informação do relation e do switch.
   * @deprecated
   */
  checkRelationTableSchema() {
    let schema = this.state.schema.map(schema => {
      if (schema.type === "relation") {
        return Object.assign(
          {
            render: (valueKey, objectLine) => {
              return (
                <Button
                  icon="eye"
                  onClick={() => this.getTableRelation(valueKey, schema, objectLine)}>
                  Visualizar
                </Button>
              );
            },
            align: "center"
          },
          schema
        );
      } else if (schema.type === "switch") {
        return Object.assign(
          {
            render: (valueKey, objectLine) => {
              return (
                <Icon style={{ 'color': (valueKey) ? "green" : "red" }} type={(valueKey) ? "check" : "close"} />
              );
            },
            align: "center"
          },
          schema
        );
      } else {
        return schema;
      }
    });
    this.setState(state => {
      return {
        schema: schema
      };
    });
  }

  /**
   * Exibe modal com tabela dos dados relacionados.
   * @param {Object} row 
   * @param {Object} schema Schema com as configurações da lista.
   * @param {ParseObjectSubClass} object Registro único relacionado.
   * @returns Renderização de modal com tabela com os dados relacionados.
   * @deprecated
   */
  getTableRelation(row, schema, object) {
    let query = new Parse.Query(this.state.parse.collection);
    query.equalTo("objectId", object.objectId);
    query.first().then(ParseObjectSubClass => {
      let relation = new Parse.Relation(ParseObjectSubClass, schema.key);
      let query = relation.query();
      query.find().then(response => {
        let data = this.mapDataToTable(response);
        Modal.info({
          width: "70%",
          title: <h2>{schema.show.title}</h2>,
          content: <Table columns={schema.show.index} dataSource={data} />
        })
        this.setState(() => {
          return {
            modal: {
              visible: true
            }
          };
        });
      });
    });
  }

  /**
   * Define e formata os dados da tabela de registros relacionados.
   * @param {Array} array Lista de dados relacionados.
   * @returns Lista de dados relacionados formatado.
   * @deprecated 
   */
  mapDataToTable(array) {
    let format = function (json) {
      let type = data => {
        switch (typeof data) {
          case "number":
          case "boolean":
          case "string":
            return data.toString();
          default:
            return "";
        }
      };
      let j = {};
      for (let i in json) {
        j[i] = type(json[i]);
      }
      return j;
    };
    return array.map(json => {
      return format(json.toJSON());
    });
  }

  /**
   * Cria botões na barra superior da listagem.
   * @param {null}
   * @returns Renderização dos botões na barra superior da listagem.
   */
  btnActionHeader() {
    if (this.Auth.hasAction([`${this.state.form.module}Create`, "*"])) {
      const TopBarAction = this.state.form.topBarAction ? this.state.form.topBarAction : null
      return (
        <div className="action-btns">
          {TopBarAction && <TopBarAction module={this} />}
          <Button
            style={{ float: "right" }}
            type="primary"
            color="green"
            onClick={() => this.toCreate()}>
            <Icon type="plus" /> Cadastrar
          </Button>
        </div>
      );
    }
  }

  /**
   * Renderização do componente Module.
   */
  render() {
    if (!this.props.url_props.location.pathname.includes(this.props.form['router-base'])) {
      console.error("The router-base is different of pathname")
      return this.renderErrorForm()
    }

    if (!this.state.AuthIsLoaded)
      return this.renderViewLoader()

    return (
      <div
        className={
          this.props.form.className
            ? "content-module " + this.props.form.className
            : "content-module"
        }
      >
        {/* Componente de listagem */}
        <Route
          exact
          path={`${this.props.form['router-base']}`}
          render={() => {
            return this.renderViewHome()
          }}
        />

        {/* Componente de formulário de cadastro */}
        <Route
          exact
          path={`${this.props.form['router-base']}/cadastrar`}
          render={(props) => {
            if (this.props.form.hasOwnProperty('EntireFormComponent')) return this.props.form.EntireFormComponent(this, props)
            return <FormModule auth={this.Auth} configs={this.state.Configs} module={this.props} />
          }}
        />

        {/* Componente de formulário de atualização */}
        <Route
          exact
          path={`${this.props.form['router-base']}/editar/:objectId`}
          render={(props) => {
            if (this.props.form.hasOwnProperty('EntireFormComponent')) return this.props.form.EntireFormComponent(this, props)
            return <FormModule auth={this.Auth} configs={this.state.Configs} module={this.props} objectEdit={props.match.params.objectId} />
          }}
        />

        {/* Componente loading */}
        <Route
          exact
          path={`${this.props.form['router-base']}/load`}
          render={() => this.renderViewLoader()}
        />

        {/* Componente erro */}
        <Route
          exact
          path={`${this.props.form['router-base']}/erro`}
          render={() => this.renderErrorForm()}
        />

        {/* Componente bloqueado/sem permissão */}
        <Route
          exact
          path={`${this.props.form['router-base']}/block`}
          render={() => this.renderViewNoPermissison()}
        />
      </div>
    );
  }

  /**
   * Renderiza componente de listagem.
   * @param {null}
   * @returns Componente de listagem.
   */
  renderViewHome() {
    if (this.Auth.hasAction([`${this.state.form.module}Read`, "*"])) {
      return (<div>
        <PageHeader
          className="table-header"
          title={this.props.title}
          action={this.btnActionHeader()} />

        {this.table(this.props.collection)}
      </div>
      );
    }
    return this.renderViewLoader()
  }

  /**
   * Encaminha para determinada rota.
   * @param {Strin} path Nome da rota do módulo. 
   * @param {String} $module Rota básica.
   */
  navigateToRouterComponent(path, $module = this.props.form['router-base']) {
    this.props.url_props.history.push($module + path)
  }

  /**
   * Renderiza o componente Erro em caso de problemas de rota.
   * @param {null}
   * @returns Componente de erro.
   */
  renderErrorForm() {
    return (
      <div>
        <Exception
          type="500"
          style={{
            minHeight: 500,
            height: "80%",
            marginTop: "100px"
          }}
          title="Erro"
          desc="Alguma coisa quebrou, entre em contato com o administrador do sistema."
          img="/images/500.svg"
          linkElement={() => (
            <Button
              type="primary"
              onClick={() => {
                this.props.url_props.history.goBack();
              }}>
              Voltar
            </Button>
          )}
        />
      </div>
    )
  }

  /**
   * Renderiza um modal.
   * @param {null}
   * @returns Modal.
   * @deprecated
   */
  renderModal() {
    if (this.state.modal.visible) {
      return (
        <Modal
          title={this.state.modal.title}
          visible={this.state.modal.visible}>
          {this.state.modal.content}
        </Modal>
      );
    }
  }

  /**
   * Renderização de componente Bloqueado em caso de erro de permissão.
   * @param {null}
   * @returns Componente bloqueado.
   */
  renderViewNoPermissison() {
    return (
      <div>
        <Exception
          type="403"
          style={{
            minHeight: 500,
            height: "80%",
            marginTop: "100px"
          }}
          title="Bloqueado"
          desc="Você não tem permissão pra acessar esse módulo."
          img="/images/block.svg"
          linkElement={() => (
            <Button
              type="primary"
              onClick={() => {
                this.navigateToRouterComponent('/', '/panel')
              }}>
              Voltar
            </Button>
          )}
        />
      </div>
    );
  }

  componentWillMount() {
    localStorage.removeItem(`Pagination:${this.props.form.module}`)
    this.setState(() => {
      return {
        parse: {
          collection: Parse.Object.extend(this.props.collection)
        }
      };
    });
  }

  componentDidMount() {
    this.Auth.init(() => {
      if (this.Auth.hasAction([`${this.state.form.module}Read`, '*'])) {
        this.checkRelationTableSchema();
      } else {
        notification.error({
          message: "Erro de permissão.",
          description: 'Você não tem permissão pra acessar esse módulo.'
        })
        this.navigateToRouterComponent('/block')
      }
      Parse.Cloud.run('getConfigByName').then(values => {
        this.setState(() => {
          return {
            Configs: values.reduce((a, v) => {
              let json = v.toJSON()
              return { ...a, [json.name]: json }
            }, {}),
            AuthIsLoaded: true
          }
        })
      })
    })
  }

  /**
   * Encaminha para formulário de edição de um registro.
   * @param {ParseObjectSubClass} row Registro a ser editado.
   */
  editRow(row) {
    this.navigateToRouterComponent(`/editar/${row.objectId}`)
  }

  /**
   * Remove item da lista.
   * @param {ParseObjectSubClass} row Registro a ser removido.
   * @deprecated
   */
  removeItemTable(row) {
    let _Query = new Parse.Query(this.state.parse.collection);
    _Query.get(row.objectId, {
      success: object => {
        object.destroy({});
        // this.loadTableContent();
      }
    });
  }

  /**
   * Filtra coluna do tipo relation.
   * @param {null}
   * @returns Schema filtrado.
   * @deprecated
   */
  schemaIncludes() {
    return this.state.schema.filter(schema => {
      return (!!schema.relation || schema["relation-select"])
    }).map(inc => inc.relation.name || inc.key);
  }

  /**
   * Renderiza a listagem dos registros.
   * @param {null}
   * @returns Renderização do módulo de listagem.
   */
  table() {
    if (this.Auth.hasAction([`${this.state.form.module}Read`, "*"]))
      return (
        <Card className="flex div-table">
          <ListModule module={this.props} auth={this.Auth} onRemoveRow={row => this.removeR} onEditRow={row => this.editRow(row)} />
        </Card>
      );
  }

  /**
   * Encaminha para o formulário de criação de registro.
   * @param {null}
   */
  toCreate() {
    this.navigateToRouterComponent(`/cadastrar`);
  }

  /**
   * Renderiza loading.
   * @param {null}
   */
  renderViewLoader() {
    return (
      <div className="center-div-loader">
        <Spin size="large" />
      </div>
    );
  }

}
