import React from "react";

const totalsFields = (props) => {
    return [
        [
            {
                title: 'Valor frete',
                key: 'shipment',
                type: 'money',
                colSize: 8,
                'element-attr': { required: false, }
            },
            {
                title: 'Total IPI',
                key: 'ipi',
                type: 'render',
                colSize: 4,
                render: (_) => {
                    const ipi = (_.formRef.inputs || []).reduce((prev, current) => {
                        let unitIPI = ((current.ipi || 0) / 100) * (current.total || 0)
                        return prev + unitIPI;
                    }, 0);
                    return (
                        <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                            {(ipi || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    )
                },
                'element-attr': { required: false, }
            },
            {
                title: 'Valor total',
                key: 'total_value',
                type: 'render',
                colSize: 6,
                render: (_) => {
                    const total = (_.formRef.inputs || []).reduce((prev, current) => prev + (current.total || 0), 0);
                    return (
                        <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>
                            {((total + (_.formRef.shipment || 0)) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </div>
                    )
                },
                'element-attr': { required: false, }
            },
        ]
    ]
}

export default totalsFields