import React from 'react'
import { Icon } from 'antd'
import conditions from './suport-files/conditions.json'
import generalInfoFields from './components/general-info-fields';
import letterRemarksFields from './components/letter-remarks-fields';
import proposalConditionsFields from './components/proposal-conditions-fields';
import orderConditionsFields from './components/order-conditions-fields';
import automaticChooseRules from './components/rules-fields';
// import paymentRules from './components/payment-rules';
import defaultContractFields from './components/default-contract-fields';

// Modulos para os campos de inserção de texto com formatação
let modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'link', { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'color': ['#F00', '#0F0', '#00F', '#000', '#FFF'] }]
  ],
}
let formats = [
  'header',
  'bold', 'italic', 'underline', 'link', 'align',
  'list', 'bullet',
  'color'
]

export default {
  formTabs: [
    {
      title: 'Dados gerais',
      fields: generalInfoFields,
    },
    {
      title: 'Carta e observações',
      fields: letterRemarksFields(modules, formats),
    },
    {
      title: 'Condições - proposta',
      fields: proposalConditionsFields(modules, formats, conditions),
    },
    {
      title: 'Condições - pedido',
      fields: orderConditionsFields(modules, formats),
    },
    {
      title: 'Regras',
      fields: automaticChooseRules(),
    },
    // {
    //   title: 'Regras - cronograma financeiro',
    //   fields: paymentRules,
    // },
    {
      title: 'Contrato padrão',
      fields: defaultContractFields(modules, formats),
    }
  ],
  "submit": {
    "collection": "SalesChannel"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Nome",
      "key": "name",
      "dataIndex": "name",
      "type": "render",
      "render": (v, row) => {
        let color = 'green';
        if (row.disabled) color = 'red'
        return (
          <div style={{ clear: 'both' }}>
            <div
              style={{
                backgroundColor: color,
                width: '20px', height: '20px',
                margin: 'auto', borderRadius: '50%',
                float: 'left',
                marginRight: '10px'
              }}
            />
            <div style={{ float: 'left' }}>
              {v}
            </div>
          </div>
        );
      }
    },
    {
      "title": "CNPJ",
      "key": "cnpj",
      "dataIndex": "cnpj",
      "type": "text"
    },
    {
      "title": "Telefone",
      "key": "phone_number",
      "dataIndex": "phone_number",
      "type": "text"
    },
  ],
  "title-navigation": <div><Icon type="branches" /> Canal de Vendas</div>,
  "title-module": "Canal de Vendas",
  "module": "SalesChannel",
  "router-base": "/panel/canal-vendas"
}