import React from 'react';
import { Icon, Select, Tag } from 'antd';
import { findWhere } from '../../../utils/db_functions';

let typologies = [];

export default {
  "fields": [
    [
      {
        type: 'select',
        key: 'performances',
        title: 'Desempenho',
        options: [],
        optionConfig: { name: 'performance' },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
        colSize: 12,
      },
      {
        type: 'select',
        key: 'typology',
        title: 'Tipologia',
        options: [],
        optionConfig: { name: 'typology', arrayWithId: true },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true
        },
        colSize: 12,
        render: (options) => {
          let opt = options.reduce((acc, v) => {
            let type = v.label.split(' ')[0]
            if (!acc[type]) {
              acc[type] = {
                children: []
              }
            }
            acc[type].children.push(v)
            return acc;

          }, {});
          let types = Object.keys(opt)
          return types.map((type, index) => {
            return (
              <Select.OptGroup label={types[index]} key={`group-${index}`}>
                {opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
              </Select.OptGroup>
            )
          })
        }
      },
    ],
    [
      {
        title: "Fechadura",
        key: "component_kit_fechadura",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            $and: [
              {
                $or: [
                  { 'group': 'Fechadura' },
                  { 'group': 'Kit Fechadura' },
                  { 'group': 'Conjunto Fechadura' },
                ]
              }
            ]
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
    [
      {
        title: "Fechadura (PF)",
        key: "component_kit_fechadura_pf",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            $and: [
              {
                $or: [
                  { 'group': 'Fechadura' },
                  { 'group': 'Kit Fechadura' },
                  { 'group': 'Conjunto Fechadura' },
                ]
              }
            ]
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
    [
      {
        title: "Ferragens",
        key: "component_kit_ferragens",
        type: "relation",
        options: [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            group: 'Ferragem',
          }
        },
        "element-attr": { "required": false, "mode": 'multiple' }
      },
    ],
  ],
  FormHasMounted: async (_) => {
    setTimeout(() => {
      if (
        (_.state.formRef.component_kit_fechadura_pf &&
          (_.state.formRef.component_kit_fechadura_pf.length === 0))
      )
        _.state.formRef.component_kit_fechadura_pf = _.state.formRef.component_kit_fechadura

      _.forceUpdate()
    }, 500)
  },
  "submit": {
    "collection": "MatrizDoorLocks"
  },
  "className": "config-component",
  ListWillMount: async () => {
    const typologiesParseArray = await findWhere('Config', 'name', ['typology']);
    typologies = typologiesParseArray.length && typologiesParseArray[0].get('array_with_id');
  },
  "schema": [
    {
      "title": "Desempenho",
      "key": "performances",
      "type": "text",
      render: row => (row.performances || []).map((item, key) => (<Tag key={key}>{item}</Tag>))
    },
    {
      title: 'Tipologia',
      key: 'typology',
      dataIndex: 'typology',
      type: 'render',
      render: v => {
        return (v || [])
          .map((usage, i) => {
            const typology = typologies.find(el => el.value === usage)
            return (
              <Tag key={i}>
                {typology && typology.label}
              </Tag>
            )
          })
      }

    }
  ],
  "title-navigation": <div><Icon type="key" /> Matriz de Ferragens</div>,
  "title-module": "Matriz de Ferragens",
  "module": "MatrizDoorLocks",
  "router-base": "/panel/matriz-ferragens"
}