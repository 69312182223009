import { Col, Divider, Form, Row, Select, InputNumber, Button, Input } from "antd";
import React, { Fragment } from "react";

const productStepAccessories = ({
  index,
  getProductValue,
  updateProductData,
  _this,
  updateRequiredFields,
  isRequiredProductComponent,
}) => {
  return (
    <Fragment>
      <h2>Cadastro de Acessórios</h2>

      {(getProductValue("accessoriesArray") || []).map(
        (accessory, accessoryIndex) => {
          return (
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Tipo">
                  <Input
                    disabled={_this.props.disabledFields}
                    value={
                      getProductValue("accessoriesArray")[accessoryIndex]
                        .accessoryType
                    }
                    placeholder="Tipo"
                    size="large"
                    mode="default"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Acessório">
                  <Select
                    disabled={_this.props.disabledFields}
                    value={
                      getProductValue("accessoriesArray")[accessoryIndex]
                        .accessory
                    }
                    onChange={(value) => {
                      let accessories = getProductValue("accessoriesArray");
                      accessories[accessoryIndex].accessory = value;
                      updateProductData("accessoriesArray", index, accessories);
                    }}
                    placeholder="Acessório"
                    size="large"
                    mode="default"
                  >
                    <Select.Option value={null}>Selecione...</Select.Option>
                    {(_this.state.accessories || [])
                      .filter((accessory) => {
                        return accessory
                          .get("description_component")
                          .toLowerCase()
                          .includes(
                            (
                              (getProductValue("accessoriesArray")[
                                accessoryIndex
                              ] &&
                                getProductValue("accessoriesArray")[
                                  accessoryIndex
                                ].accessoryType) ||
                              ""
                            ).toLowerCase()
                          );
                      })
                      .map((accessory) => {
                        return (
                          <Select.Option value={accessory.id}>
                            {accessory.get("product_code")} -{" "}
                            {accessory.get("description_component")}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item label="Qtd x Kit">
                  <InputNumber
                    value={
                      getProductValue("accessoriesArray")[accessoryIndex]
                        .accessory_amount
                    }
                    max={3}
                    step={1}
                    onChange={async (value) => {
                      let accessories = getProductValue("accessoriesArray");
                      accessories[accessoryIndex].accessory_amount = value;
                      updateProductData("accessoriesArray", index, accessories);
                    }}
                    placeholder="Quantidade de acessórios"
                    size="large"
                    disabled={_this.props.disabledFields}
                  />
                </Form.Item>
              </Col>

              <Col span={2}>
                <Button
                  style={{ marginTop: "45px", width: "100%" }}
                  type="danger"
                  onClick={() => {
                    let accessories = getProductValue("accessoriesArray") || [];
                    accessories.splice(accessoryIndex, 1);
                    updateProductData("accessoriesArray", index, accessories);
                  }}
                >
                  Excluir
                </Button>
              </Col>
            </Row>
          );
        }
      )}

      <div style={{ textAlign: "center" }}>
        {[
                "Vidro Temperado",
                "Vidro Acústico",
                "Vidro Antiradiações",
                "Grelha Alumínio",
                "Canopla PVC HD",
                "Sign Serrambi",
                "Bandeira Vidro 2700",
                "Bandeira Fixa 2700",
              ].map((accessoryTypeElement) => {
          return (
            <Button
              style={{ marginTop: "20px", marginRight: "5px" }}
              disabled={(getProductValue("accessoriesArray") || []).find(el => el.accessoryType === accessoryTypeElement) ? true : false}
              onClick={() => {
                let accessories = getProductValue("accessoriesArray") || [];
                accessories.push({ accessoryType: accessoryTypeElement, accessory: null, accessory_amount: 1 });
                updateProductData("accessoriesArray", index, accessories);
              }}
            >
              Adicionar {accessoryTypeElement}
            </Button>
          );
        })}
      </div>

      <Divider dashed={true} />
    </Fragment>
  );
};

export default productStepAccessories;
