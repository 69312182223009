import React from 'react'
import { Icon } from 'antd'
import { getStaffing, sheetCostTotal } from '../fixed-cost/fixed-cost';
import Parse from 'parse';
import { moneyToNumber } from '../../../../utils/general_functions';
import { findAll } from '../../../../utils/db_functions';

let manHourValueList = [];

export default {
  "fields": [
    [
      {
        title: 'Custo fixo (ref)',
        key: 'fixed_cost',
        type: 'pointer',
        colSize: 5,
        options: [],
        'relation-select': {
          class: 'FixedCost',
          label: 'ref',
          value: 'objectId',
          key: 'objectId'
        },
        'element-attr': {
          required: false, mode: 'default',
          afterChange: async (_) => {
            const value = await calculateManHour(_.state.formRef.fixed_cost);
            _.state.formRef.value_ref = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            _.forceUpdate();
          }
        },
      },
      {
        title: 'Descrição',
        key: 'description',
        type: 'text',
        colSize: 8,
      },
      {
        title: 'Multiplicador',
        key: 'multiplier',
        type: 'number',
        colSize: 3,
        'element-attr': { required: true },
      },
      {
        title: "Valor unitário HH",
        key: "value_ref",
        type: "render",
        colSize: 4,
        render: (_) => {
          return (
            <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
              {_.formRef.value_ref}
            </div>
          );
        },
      },
      {
        title: "Valor Total",
        key: "final_value",
        type: "render",
        colSize: 4,
        render: (_) => {
          return (
            <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
              {
                (moneyToNumber(_.formRef.value_ref || '0') * (_.formRef.multiplier || 1))
                  .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            </div>
          );
        }
      },
      // {
      //   title: "Valor unitário HH (informado)",
      //   key: "value",
      //   type: "number",
      //   colSize: 6,
      //   "element-attr": { "required": false }
      // },
    ]
  ],
  'FormHasMounted': (_) => {
    setTimeout(async () => {
      const value = await calculateManHour(_.state.formRef.fixed_cost);
      _.state.formRef.value_ref = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      _.forceUpdate();
    }, 500);
  },
  "submit": {
    "collection": "ManHour"
  },
  "className": "config-component",
  'ListWillMount': async (_) => {
    const fixedCostArray = await findAll('FixedCost')
    for (let fixedCostIndex = 0; fixedCostIndex < fixedCostArray.length; fixedCostIndex++) {
      const fixedCost = fixedCostArray[fixedCostIndex];
      const manHourValueByFixedCost = await calculateManHour(fixedCost.id)
      manHourValueList.push({
        fixedCost: fixedCost.id,
        manHourValue: manHourValueByFixedCost,
      })
    }
    _.forceUpdate();
  },
  "schema": [
    {
      title: 'Descrição',
      key: 'description',
      type: 'text',
      dataIndex: 'description'
    },
    {
      title: 'Valor',
      key: 'value_ref',
      type: 'render',
      width: '150px',
      align: 'center',
      render: (_) => {
        const value = manHourValueList.find(manHour => manHour.fixedCost === _.fixed_cost.objectId)
        return (((value && value.manHourValue) || 0) * (_.multiplier || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    }
  ],
  "title-module": "Homem Hora",
  "title-navigation": <div><Icon type="man" /> Homem Hora</div>,
  "module": "ManHour",
  "router-base": "/panel/homem_hora"
}

const getFixedCost = async (fixedCostId) => {
  const query = new Parse.Query('FixedCost').limit(1000).equalTo('objectId', fixedCostId);
  const fixedCostFound = await query.first();
  return fixedCostFound;
}

const calculateManHour = async (fixedCostId) => {
  const fixedCost = await getFixedCost(fixedCostId);
  const localStaffing = fixedCost ? await fixedCost.get('staffing') : null;
  const staffing = localStaffing ? await getStaffing(localStaffing.id) : null;
  if (!staffing) return 0;
  return ((staffing.get('total') || 0) + ((sheetCostTotal(fixedCost.get('sheet_costs'), staffing) || 0) / 12)) / staffing.get('staffing_total') / 180;
}

export {
  calculateManHour,
}