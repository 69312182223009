import React from 'react'
import { generateCode } from '../../input-order/input_order [OLD]'
import Parse from 'parse'
import { Icon, notification } from 'antd'
const { findById, findWhere, findAll } = require("../../../../utils/db_functions")

const getInputsFromProvider = async (providerId) => {
    const provider = await findById('InputProvider', providerId)

    const inputsIds = provider.get('inputs').map(el => el.input)

    const inputsFound = await findWhere('Input', 'objectId', inputsIds, null, null, 'code', null, 500)

    return inputsFound.map(el => {
        return {
            label: `${el.get('code')} - ${el.get('material_description')}`,
            value: el.id,
            data: el.toJSON(),
        }
    });
}

const filterSalesChannelList = async () => {
    const salesChannellist = await findAll('SalesChannel')

    const clientParseObject = new Parse.Object('SalesChannel')

    clientParseObject.set('objectId', 'cliente');
    clientParseObject.set('provider', 'Cliente');

    let listOfCNPJ = [];
    let listToReturn = [clientParseObject];

    for (let index = 0; index < salesChannellist.length; index++) {
        const element = salesChannellist[index];
        if (!listOfCNPJ.includes(element.get('cnpj')) && !element.get('disabled')) {
            listOfCNPJ.push(element.get('cnpj'));
            listToReturn.push(element);
        }
    }

    return listToReturn.map(el => {
        return {
            label: el.get('provider'),
            value: el.id,
        }
    });
}

async function generateCotationCode() {
    const currentYear = new Date().getFullYear();

    const inputOrderPriceQuoteQuery = new Parse.Query('InputOrderPriceQuote').limit(10000).endsWith('cotation_code', currentYear.toString());
    let inputOrderPriceQuotes = await inputOrderPriceQuoteQuery.find();

    // Listar as propostas do ano corrente
    let code = `0001-${currentYear.toString()}`;
    if (inputOrderPriceQuotes.length) {
        inputOrderPriceQuotes = inputOrderPriceQuotes.sort((a, b) => (a.get('cotation_code') > b.get('cotation_code')) ? 1 : ((b.get('cotation_code') > a.get('cotation_code')) ? -1 : 0));
        let intCode = parseInt(inputOrderPriceQuotes[inputOrderPriceQuotes.length - 1].get('cotation_code')) + 1;
        let zeroLength = 4 - intCode.toString().length;
        code = `${'0'.repeat(zeroLength)}${intCode}-${currentYear.toString()}`;
    }

    return code;
}

async function transformDataToCreateInputOrder(jsonInputOrder) {

    const ipi = (jsonInputOrder.inputs || []).reduce((prev, current) => {
        let unitIPI = ((current.ipi || 0) / 100) * (current.total || 0)
        return prev + unitIPI;
    }, 0);

    const total = (jsonInputOrder.inputs || []).reduce((prev, current) => prev + (current.total || 0), 0);

    const code = await generateCode({ props: { module: { form: { module: 'InputOrder' }, }, }, });

    let providerPointer = new Parse.Object('InputProvider')
    providerPointer.id = jsonInputOrder.provider && jsonInputOrder.provider.objectId;

    let clientPointer = new Parse.Object('Client')
    clientPointer.id = jsonInputOrder.client && jsonInputOrder.client.objectId;

    let obj = {
        obs: jsonInputOrder.obs,
        delivery_phone: jsonInputOrder.delivery_phone,
        delivery_conditions: null,
        delivery_type: jsonInputOrder.delivery_type,
        inputs: jsonInputOrder.inputs,
        ipi: (ipi || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        total_value: total,
        total_value_with_shipment: (total || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        delivery_responsible: jsonInputOrder.delivery_responsible,
        client: clientPointer,
        sales_order_code: jsonInputOrder.sales_order_code,
        provider: providerPointer,
        payment_conditions: jsonInputOrder.payment_conditions,
        delivery: jsonInputOrder.delivery && jsonInputOrder.delivery.name,
        shipment: jsonInputOrder.shipment,
        revenues: jsonInputOrder.revenues,
        delivery_place: jsonInputOrder.delivery_place,
        input_destiny: jsonInputOrder.input_destiny,
        order_code: code,
    }

    let inputOrderParseObject = new Parse.Object('InputOrder')
    for (let key in obj) {
        inputOrderParseObject.set(key, obj[key]);
    }
    inputOrderParseObject.set('createdBy', Parse.User.current());
    inputOrderParseObject.set('updatedBy', Parse.User.current());

    return inputOrderParseObject;
}

const generateInputOrderPriceQuote = async (id, hasPrices) => {
    notification.open({
        message: 'Gerando/Atualizando o pdf da cotação',
        description: 'O pdf da cotação está sendo criado/atualizado, aguarde...',
        key: 'generatingCotation',
        duration: 0,
        placement: 'topLeft',
        icon: <Icon type="loading" />
    });

    Parse.Cloud.run('generateInputOrderPriceQuote', { id, hasPrices })
        .then((response) => {
            notification.close('generatingCotation');
            notification.success({
                message: 'Cotação gerada/atualizada com sucesso.',
                description: 'Acesse o pdf da cotação clicando no ícone de pdf.',
                placement: 'topLeft',
            });
            window.open(process.env.REACT_APP_PUBLIC + '/' + response.url, '_blank')
        })
        .catch(() => {
            notification.close('generatingCotation');
            notification.error({
                message: 'Erro ao gerar/atualizar o pdf.',
                description: 'Alguma coisa deu errado, tente novamente.',
                duration: 5,
            });
        })
}

export {
    getInputsFromProvider,
    filterSalesChannelList,
    generateCotationCode,
    transformDataToCreateInputOrder,
    generateInputOrderPriceQuote
}