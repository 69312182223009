import React, { Component } from "react";
import { Input, Form, Button, Icon, message } from 'antd';
import '../login/Login.css';
import logo from '../../images/logo.svg';
import FooterToolbar from 'ant-design-pro/lib/FooterToolbar';
import { Link } from 'react-router-dom'
import Parse from 'parse';
import VERSION from '../../environments/version'
import { ValidateEmail } from '../../utils/mask'

/**
 * Classe de recuperação de senha.
 */
export default class RecoveryPassword extends Component {

  state = {
    email: "",
    btnBlock: true,
    loading: false,
    password: "",
    copyPassword: ""
  }

  /**
   * Constructor: verifica a existência do token e redireciona o usuário.
   * @param {*} args 
   */
  constructor(args) {
    super(args);
    if(args[`router-props`].match.params.token){
      Parse.Cloud.run(`checkIfExistToken`, {
        token: args[`router-props`].match.params.token
      }).then(res => {
        if(!res.token)
          return this.props[`router-props`].history.push(`/404`)
      })
    }
  }

  /**
   * Renderiza o formulário com o campo e-mail para recuperação de senha.
   */
  renderRecoveryEmailForm() {
    return (
      <div className="app-login">
        <div className="content-app-login">
          <Form onSubmit={event => this.generateToken(event)}>
            <div className="image-form">
              <img src={logo} alt="Logo Senai" />
              <p style={{ fontSize: "15px", color: "rgba(0,0,0,.45)", margin: '10px 0 0px 0' }}>
                Multidoor 4.0
              </p>
              <p style={{ fontSize: "10px", color: "rgba(0,0,0,.45)", margin: '0px 0 15px 0' }}>
                {VERSION}
              </p>
            </div>
            <Form.Item {...ValidateEmail(this.state.email || '')}>
              <Input placeholder="Informe seu E-mail" type="text" required size="large"
                onInput={input => this.handleInput(input, `email`)}
                value={this.state.email}
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="login-form-button" disabled={this.state.btnBlock} loading={this.state.loading}>
              Recuperar
            </Button>
            <Link style={{ marginTop: `10px`, float: `left` }} to="/">
              <Icon type="arrow-left" /> Voltar
          </Link>
          </Form>
        </div>
        <FooterToolbar extra="Created by NTM - SENAI/AL" style={{ background: 'transparent', boxShadow: 'unset', border: 'none' }}>
        </FooterToolbar>
      </div>
    )
  }

  /**
   * Renderização do componente.
   */
  render() {
    if (!this.props[`router-props`].match.params.token)
      return this.renderRecoveryEmailForm()

    return this.renderUpdatePasswordForm()
  }

  /**
   * Renderiza o formulário com o campo de atulização de senha após o e-mail ser informado.
   */
  renderUpdatePasswordForm() {
    return (
      <div className="app-login">
        <div className="content-app-login">
          <Form onSubmit={event => this.recoveryPassword(event)}>
            <div className="image-form">
              <img src={logo} alt="Logo Senai" />
              <p style={{ fontSize: "15px", color: "rgba(0,0,0,.45)", margin: '10px 0 0px 0' }}>
                Multidoor 4.0
              </p>
              <p style={{ fontSize: "10px", color: "rgba(0,0,0,.45)", margin: '0px 0 15px 0' }}>
                {VERSION}
              </p>
            </div>
            <Form.Item>
              <Input placeholder="Nova senha" type="password" required size="large"
                onInput={input => this.handleInput(input, `password`)}
                value={this.state.password}
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>
            <Form.Item>
              <Input placeholder="Repita a senha" type="password" required size="large"
                onInput={input => this.handleInput(input, `copyPassword`)}
                value={this.state.copyPassword}
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="login-form-button" disabled={(this.state.password !== this.state.copyPassword) || !this.state.password} loading={this.state.loading}>
              Salvar
            </Button>
            <Link style={{ marginTop: `10px`, float: `left` }} to="/">
              <Icon type="arrow-left" /> Voltar
          </Link>
          </Form>
        </div>
        <FooterToolbar extra="Created by NTM - SENAI/AL" style={{ background: 'transparent', boxShadow: 'unset', border: 'none' }}>
        </FooterToolbar>
      </div>
    )
  }

  /**
   * Altera o valor do campo do form.
   * @param {String} event evento com o valor do campo.
   * @param {String} stateProp nome do campo do form.
   */
  handleInput(event, stateProp) {
    let inputValue = event.target.value;
    
    // Bloquear/desbloquear botão de recuperação de senha
    if(stateProp === 'email'){
      let validate = ValidateEmail(inputValue)
      if(validate.validateStatus === 'success') this.setState({ btnBlock: false })
      else this.setState({ btnBlock: true })
    }

    this.setState(state => {
      return { [`${stateProp}`]: inputValue }
    })
  }

  /**
   * Gera o token no submit do formulário de e-mail.
   * @param {*} event Evento submit.
   */
  generateToken(event) {
    event.preventDefault()
    this.setState(state => {
      return {
        btnBlock: true,
        loading: true,
      }
    }, () => {
      Parse.Cloud.run('generateRecoveryToken', { email: this.state.email }).then(response => {
        setTimeout(() => {
          this.setState(state => {
            return {
              btnBlock: false,
              loading: false,
              email: ""
            }
          }, () => {
            message.success(response.message, 5)
          })
        }, 1000)
      }).catch(err => {
        this.setState(state => {
          return {
            btnBlock: false,
            loading: false,
          }
        }, () => {
          message.error(err.message.err, 5)
        })
      })
    })
  }

  /**
   * Altera a senha do usuário.
   * @param {*} event Evento submit.
   */
  recoveryPassword(event) {
    event.preventDefault()
    if (this.state.password !== this.state.copyPassword)
      return message.error('Preencha os campos com a mesma senha.')

    Parse.Cloud.run(`recoveryPassword`, { password: this.state.password, token: this.props[`router-props`].match.params.token })
      .then(res => {
        message.success(res)
        this.setState(state => {
          return {
            password: ``,
            copyPassword: ``,
            loading: true,
          }
        }, () => this.props[`router-props`].history.push(`/`))
      }).catch(err => {
        message.error(err.message, 5)
        this.setState({ loading: false })
      })
  }
}
