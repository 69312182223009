import React from 'react';
import { Icon } from 'antd';

const techDocs = (type, title, route) => {
	return {
		"fields": [
			[
				{
					title: 'Assunto',
					key: 'subject',
					type: 'text',
					colSize: 24,
					beforeSave: async (Form, ParseObject, node) => {
						await ParseObject.set('filterType', type)
						return true;
					}
				},
			],
			[
				{
					title: 'Documentos',
					key: 'docs',
					type: 'dynamic-input-list',
					fields: [
						[
							{
								'title': 'Documento',
								'key': 'doc',
								'type': 'document',
								'colSize': 6,
								'element-attr': { 'required': false }
							},
							{
								'title': 'Descrição',
								'key': 'doc_description',
								'type': 'text',
								'colSize': 8,
								'element-attr': { 'required': false }
							},
							{
								'title': 'Obrigatório',
								'key': 'doc_is_required',
								'type': 'switch',
								'colSize': 2,
								'element-attr': { 'required': false }
							},
							{
								'title': 'Enviar com pesquisa PCPO?',
								'key': 'sendWithPcpoSurvey',
								'type': 'switch',
								'colSize': 4,
								'element-attr': { 'required': false }
							},
							{
								'title': 'Tipologias',
								'key': 'typologies',
								'type': 'select',
								'options': [],
								'colSize': 4,
								optionConfig: { name: 'typology', arrayWithId: true },
								loaded: false,
								"element-attr": {
									mode: 'multiple',
									required: false,
								},
							},
						]
					]
				}
			]
		],
		"submit": {
			"collection": "OrderDocs"
		},
		"schema": [
			{
				"title": "Assunto",
				"key": "subject",
				"dataIndex": "subject",
				"type": "text"
			},
			{
				"title": "Tem doc obrigatório?",
				"key": "docs",
				"dataIndex": "docs",
				"width": 100,
				"type": "render",
				"align": "center",
				render: (_, row) => {
					// check if has required doc
					let hasRequiredDoc = false;
					(_ || []).forEach(element => {
						if (element.doc_is_required)
							hasRequiredDoc = true
					});

					return (
						<div
							style={{
								backgroundColor: hasRequiredDoc ? 'green' : 'red',
								width: '25px', height: '25px',
								marginBottom: '5px',
								borderRadius: '50%',
								margin: 'auto',
							}}
						/>
					);
				}
			},
		],
		"title-module": title,
		"title-navigation": <div><Icon type="folder-open" /> {title}</div>,
		"module": "OrderDocs",
		"router-base": `/panel/${route}`,
		'filterList': () => {
			return {
				'field': 'filterType',
				'value': type,
			}
		}
	}
}

export {
	techDocs
}