import React from 'react'
import ProposalForm from '../proposal/components/proposal-form.js'
import { Icon } from 'antd'
import _ from 'lodash'
import tableFooterComponent from './components/table-footer'
import CommentModal from './entities/comment-modal.jsx'
import SolutionsSquareModal from './entities/solution-square-modal.jsx'
import { findAll } from '../../../utils/db_functions.js'
import expandedContentComponent from './components/expanded-content.jsx'
import ButtonGroup from 'antd/lib/button/button-group.js'
import WorksMapModal from './entities/works-map-modal.jsx'

let proposalManagementComponent = {
	fields: [],
	schema: [
		{
			title: "Proposta",
			key: "proposal_code",
			dataIndex: "proposal_code",
			align: 'center',
			width: '150px',
			type: "text",
			render: (_, proposal) => {
				let newProposalCode = proposal.proposal_code.slice(0, proposal.proposal_code.length - 3);
				return <strong>{newProposalCode} [{proposal.all_proposals.length}]</strong>
			}
		},
		{
			title: "Nome Cliente",
			key: "name_client",
			dataIndex: "name_client",
			type: "text",
		},
		{
			title: "Nome Obra",
			key: "name_work",
			dataIndex: "name_work",
			type: "text",
		},
		{
			title: "Açoes",
			key: "historic_qs",
			dataIndex: 'historic_qs',
			align: 'center',
			render: (this_node, proposal) => {
				function reRenderList(historic) { allHistoric = historic }


				let allHistoric = []
				let allSolutionSquare = [];

				(proposal.all_proposals || []).forEach(props => {
					if (props.historic) props.historic.forEach(historic => allHistoric.push({ ...historic, proposal_code: props.proposal_code }))
					if (props.solutionsSquareIn) props.solutionsSquareIn.forEach(solutionSquare => allSolutionSquare.push({ ...solutionSquare, proposal_code: props.proposal_code }))
				})

				allHistoric = _.uniqBy(allHistoric, 'date');
				allHistoric = allHistoric.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0));

				allSolutionSquare = allSolutionSquare.sort((a, b) => (a.code.split('-')[1] < b.code.split('-')[1]) ? 1 : ((b.code.split('-')[1] < a.code.split('-')[1]) ? -1 : 0))
				allSolutionSquare = _.uniqBy(allSolutionSquare, 'code')

				return (
					<ButtonGroup>
						<CommentModal
							proposals={proposal.all_proposals}
							historic={allHistoric}
							reRenderList={e => reRenderList(e)}
						/>

						<SolutionsSquareModal
							proposal={proposal}
							allProposals={proposal.all_proposals}
							solutionSquares={allSolutionSquare}
						/>
					</ButtonGroup>
				)
			}
		},
	],
	tableFooter: () => tableFooterComponent(),
	ListWillMount: async (_) => {
		salesChannels = await findAll('SalesChannel')
		_.forceUpdate()
	},
	"title-module": "Gerenciar Propostas",
	"title-navigation": <div><Icon type="container" /> Gerenciar Propostas</div>,
	module: "ProposalManagement",
	submit: {
		collection: "Proposal"
	},
	"router-base": "/panel/gerenciar-proposta",
	"actionsTable": false,
	defaultCurrentPage: 'last',
	// "sortBy": 'proposal_code',
	"isManagementProposal": true,
	EntireFormComponent: (Module, routerProps) => {
		return (
			<div>
				<ProposalForm module={Module} routerProps={routerProps} />
			</div>
		)
	},
	expandedRowRender: parentProposal => expandedContentComponent(parentProposal, salesChannels),
	hideCopyBtn: true,
	'topBarAction': ({ module }) =>
		<div style={{ float: 'right' }}>
			<WorksMapModal />
		</div>,
}
export default proposalManagementComponent

let salesChannels = []
