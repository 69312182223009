const requiredFields = {
    proposal: [
        'consultant',
        'consult_phones',
        'consult_email',
        'consult_whatsapp',
        'consult_repre',
        'consult_repre_phone',
        'consult_repre_email',
        'consult_repre_whatsapp',
        'request_contact_origin',
        'request_specification',
        'request_purchase_involvement',
        'request_purchase_purpose',
        'request_usage',
        'request_desempenho_kit',
        'request_desempenho',
        'request_requirements',
        'name_client',
        'name_work',
        'responsible_work',
        'work_stage',
        'prevision_buy',
        'ocupation',
        'performance_level',
        'instalation',
        'local_work',
        'delivery',
        // 'payment_conditions',
        // 'discount',
        // 'signal',
        // 'installmentsNum',
        'm20',
        'm30',
        'm60',
        'm90',
    ],
    product: [
        'env',
        'profile_performance',
        'typology',
        'product_code',
        'amount',
        'finishing_sheet',
        'pattern_sheet',
        // 'thickness_marco',
        'wall_thickness',
        'finishing_marco',
        'pattern_marco',
    ],
    wall_panel: [
        'panel_wall_type',
        'panel_wall_dimensions',
        'panel_wall_module',
        'panel_wall_finishing',
        'panel_wall_pattern',
        'panel_wall_instalation'
    ],
    specific_products: []
}

export default requiredFields