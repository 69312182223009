import React from 'react'
import { Select, Spin, Button, message } from 'antd';
import debounce from 'lodash/debounce';
import Parse from 'parse'
import _ from 'lodash'
const Option = Select.Option;

/**
 * Classe que cria o input do tipo relation.
 */
export default class RelationSelect extends React.Component {

  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchData = debounce(this.fetchData, 800);
    this.handleClickBtn = this.handleClickBtn.bind(this)
  }

  state = {
    data: [],
    value: [],
    fetching: false,
    requestsData: []
  }

  componentWillReceiveProps(props) {

    if (!props.value) return;
    let data = this.parseData(props.value, props.value)

    this.setState(() => {
      return { value: data, data, requestsData: props.value }
    })
  }

  /**
   * Busca um option na lista do select.
   * @param {String} value Valor a ser procurado na lista.
   * @returns Altera a lista do select com base no valor pesquisado.
   */
  fetchData = (value) => {
    let conf = this.props.node['relation-select']
    let query = new Parse.Query(conf.class)
    let searchRegex = '';
    let $find = { $or: [] };
    try {
      searchRegex = new RegExp(value, 'i')
    } catch (e) {
      return false;
    }
    if (typeof conf.label !== 'function') {
      query.matches(conf.label, new RegExp(searchRegex, 'i'))
      query.ascending(conf.label)
    } else {
      query.ascending(conf.orderBy)
      $find.$or = (conf.searchFields || []).map((v) => {
        return {
          [v]: {
            $regex: value,
            $options: 'i'
          }
        }
      })
    }
    query.limit(100)
    this.setState({ data: [], fetching: true });
    if (conf.hasOwnProperty('where')) {
      let whereQ = { ...conf.where }
      for (let attr in whereQ) {
        if (typeof whereQ[attr] === 'function') whereQ[attr] = whereQ[attr](this, query)
      }
      query._where = {
        ...$find,
        ...query._where,
        ...whereQ,
      }
    }

    // remover algum valor da busca
    if (conf.hasOwnProperty('excludeValue')) query.notEqualTo(conf['excludeValue']['code'], conf['excludeValue']['value'](this))

    query.find()
      .then((body) => {
        const data = this.parseData(body)
        this.setState({ data, fetching: false, requestsData: [...this.state.requestsData, ...body] });
      });
  }

  /**
   * Transforma um ParseObject em Objeto.
   * @param {ParseObject} body Objeto Parse que será convertido em um Objeto.
   * @returns Objeto com os atributos do ParseObject. 
   */
  parseData = (body) => {
    let conf = this.props.node['relation-select']
    return body.map(obj => {
      if (!obj) return false;
      let objJ = obj.toJSON()
      return {
        text: (typeof conf.label === 'function') ? conf.label(objJ) : objJ[conf.label],
        value: objJ[conf.value],
        key: objJ[conf.key],
        realR: obj
      }
    });
  }

  /**
   * Altera o valor do input no form com base no valor selecionado da lista.
   * @param {String} value Valor selecionado da lista.
   * @param {Function} callback Função a ser executada após a alteração no input.
   */
  handleChange = (value, callback) => {
    this.setState({
      value: _.orderBy(value, ['label'], ['asc']),
      fetching: false,
      changed: true
    }, () => {
      this.props.node['_internalRelationValue'] = this.state.value
      this.props.node['_changedValue'] = true;
      if (typeof callback === 'function') callback()
    });
  }

  /**
   * Retorna uma lista de ParseObjects com base em uma lista com objetos.
   * @param {Array} values Lista com objetos.
   * @param {Array} list Lista com ParseObjects.
   * @returns Lista de ParseObjects.
   */
  getParseObjectList(values, list) {
    return values.map((value) => {
      return list.find(reqData => {
        return reqData.id === value.key
      })
    })
  }

  /**
   * Registra as mudanças/escolhas no forma.
   * @param {null} 
   */
  handleClickBtn() {
    message.info(`As mudanças foram feitas no campo ${this.props.node.title.toLowerCase()}`)
    this.setState({ changed: false })
    this.props.onChange({ value: this.getParseObjectList(this.state.value, this.state.requestsData) })
    this.props.node['_changedValue'] = false;
  }

  /**
   * Renderização do componente.
   */
  render() {
    const { fetching, data, } = this.state;
    const { node } = this.props;
    const value = _.orderBy(this.state.value, ['text'], ['asc']);
    return (
      <div style={{ display: 'flex' }}>
        <Select
          style={{ marginRight: '5px', width: '100%' }}
          mode="multiple"
          labelInValue
          value={value}
          placeholder="Selecione..."
          size="large"
          ref={node.key}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.fetchData}
          onChange={this.handleChange}
          // onBlur={this.handleClickBtn}
          onDeselect={(value) => {
            const newValue = this.state.value.filter(v => v.key !== value.key)
            this.handleChange(newValue)
          }}>
          {
            data.map(d => <Option key={d.key} value={d.value}>{d.text}</Option>)
          }
        </Select>
        <Button
          disabled={!this.state.changed}
          type="primary"
          style={{ marginLeft: '16px' }}
          onClick={this.handleClickBtn}>Aplicar</Button>
      </div>
    );
  }
}