import React from 'react'
import { Icon } from 'antd'
import '../../../styles/work_stage.css'


export default {
	"fields": [
		[
			{
				"title": "Nome",
				"key": "name",
				"type": "text",
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				}
			},
		]
	],
	submit: {
		collection: "Provider"
	},
	className: "config-component",
	schema: [
		{
			title: "Nome",
			key: "name",
			dataIndex: "name",
			type: "text",
			className: "name"
		}
	],
	"title-navigation": <div><Icon type="shopping-cart" /> Fornecedor/Marca</div>,
	"title-module": "Fornecedor/Marca",
	"module": "Provider",
	"router-base": "/panel/fornecedor"
}