import React from 'react';
import { Icon, Divider, Tag } from 'antd';
import '../../../styles/config.css';

export default {
  "fields": [
    [
      {
        "title": "Componente",
        "key": "component",
        type: "select",
        options: [
          { label: 'Folha', value: 'Folha' },
          { label: 'Marco', value: 'Marco' },
          { label: 'Alizar', value: 'Alizar' },
        ],
        "element-attr": { "required": true, "mode": 'default' },
        colSize: 6,
      },
      {
        type: 'number',
        key: 'teoric_mass',
        title: 'Massa teórica',
        colSize: 6,
        "element-attr": {
          required: true
        },
      },
      {
        type: 'select',
        key: 'performance',
        title: 'Desempenho',
        options: [],
        optionConfig: { name: 'performance' },
        loaded: false,
        colSize: 12,
        "element-attr": {
          required: true,
          mode: 'multiple',
        },
      },
    ],
    [{ type: 'render', render: () => <Divider>Folha</Divider> }],
    [
      {
        type: 'text',
        key: 'folha_modelo',
        title: 'Modelo',
        colSize: 4,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'tag-list',
        key: 'folha_codes_s',
        title: 'Código produto (S)',
        colSize: 8,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'tag-list',
        key: 'folha_codes_u',
        title: 'Código produto (U)',
        colSize: 8,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'number',
        key: 'folha_espessura',
        title: 'Espessura',
        colSize: 4,
        "element-attr": {
          required: false
        },
      },
    ],
    [{ type: 'render', render: () => <Divider>Marco</Divider> }],
    [
      {
        type: 'text',
        key: 'marco_code',
        title: 'Código marco',
        colSize: 6,
        "element-attr": {
          required: false,
        }
      },
      {
        type: 'tag-list',
        key: 'marco_code_s',
        title: 'Código produto (S)',
        colSize: 7,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'tag-list',
        key: 'marco_code_u',
        title: 'Código produto (U)',
        colSize: 7,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'number',
        key: 'marco_espessura',
        title: 'Espessura',
        colSize: 4,
        "element-attr": {
          required: false
        },
      },
    ],
    [{ type: 'render', render: () => <Divider>Alizar</Divider> }],
    [
      {
        type: 'text',
        key: 'alizar_code',
        title: 'Código alizar',
        colSize: 8,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'tag-list',
        key: 'alizar_code_product',
        title: 'Código produto',
        colSize: 8,
        "element-attr": {
          required: false
        },
      },
      {
        type: 'number',
        key: 'alizar_espessura',
        title: 'Espessura',
        colSize: 8,
        "element-attr": {
          required: false
        },
      },
    ],

  ],
  "submit": {
    "collection": "TeoricMassConfigs"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Componente",
      "key": "component",
      "dataIndex": "component",
      "type": "text",
    },
    {
      "title": "Desempenho",
      "key": "performance",
      "dataIndex": "performance",
      "type": "array",
      "render": (performances) => {
        return (performances || []).map((p, i) => {
          return <Tag key={i}>{p}</Tag>;
        })
      },
    },
    {
      "title": "Modelo/Código",
      "key": "folha_modelo",
      "dataIndex": "folha_modelo",
      "type": "render",
      "width": '150px',
      "render": (model, row) => {
        if (row.component === 'Folha') return model;
        else if (row.component === 'Marco') return row.marco_code;
        else if (row.component === 'Alizar') return row.alizar_code;
        return '';
      },
    },
    {
      "title": "Espessura",
      "key": "thickness",
      "dataIndex": "folha_espessura",
      "type": "render",
      "width": '100px',
      "render": (thickness, row) => {
        if (row.component === 'Folha') return thickness;
        else if (row.component === 'Marco') return row.marco_espessura;
        else if (row.component === 'Alizar') return row.alizar_espessura;
        return '';
      },
    },
    {
      "title": "Massa",
      "key": "teoric_mass",
      "dataIndex": "teoric_mass",
      "type": "number",
      "width": '150px',
    },
    {
      "title": "Unidade massa",
      "key": "unit",
      "dataIndex": "component",
      "type": "render",
      "render": (v) => {
        if (v === 'Folha') return 'Kg/m2';
        return 'Kg/m';
      },
      "width": '150px',
    },
  ],
  "title-module": "Massa teórica",
  "title-navigation": <div><Icon type="gold" /> Massa teórica</div>,
  "module": "TeoricMassConfigs",
  "router-base": "/panel/massa-teorica-configs"
}

