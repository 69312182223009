import React, { Component } from 'react'
import logo from '../../../../images/logo.svg'
import { Card, Icon, Spin } from 'antd';
import { Link } from 'react-router-dom'
import { findById } from '../../../../utils/db_functions';
import '../client-folder.css'
import { Row } from 'antd/es/grid';

export default class Checklist extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            techDocs: [],
            order: null,
            proposal: null
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const orderId = this.props['router-props'].location.pathname.split('/')[2]
        const order = await findById('Order', orderId)

        const proposal = JSON.parse(order.get('proposal'));

        this.setState({
            order,
            proposal,
            loading: false
        });
    }

    renderFolder = (folder) => {
        if (!(folder.docs && folder.docs.length)) return null

        return (
            <Row>
                <div>
                    <Icon type='folder-open' style={{ marginRight: '10px' }} />
                    {folder.doc_description}
                </div>

                <div style={{ marginLeft: '20px' }}>
                    {folder.docs.map(doc => {
                        if (doc.doc_description) return this.renderFolder(doc)
                        return this.renderFile((doc.docName || doc.file._name || doc.file.name), (doc.file._url || doc.file.url))
                    })}
                </div>
            </Row>
        )
    }

    renderFile = (name, url) => {
        return (
            <Row>
                <Icon type='file' style={{ marginRight: '10px' }} />
                <Link
                    to={url}
                    target='blank'
                >{name}</Link>
            </Row>
        )
    }

    render() {

        return (
            <div className="app-login" style={{ padding: '50px 0px', position: 'relative', height: '100vh' }}>

                <div style={{ maxHeight: '100%', overflowX: 'scroll' }}>

                    <div style={{ maxWidth: '1000px', margin: 'auto' }}>

                        <Card style={{ width: '100%', marginBottom: '50px' }} id='checklist-card'>

                            <Spin spinning={this.state.loading}>

                                <img src={logo} alt="Logo Multidoor" width='35%' />

                                <div style={{ clear: 'both' }} />

                                <div style={{ marginTop: 50 }}>

                                    <h2>Pedido {this.state.order && this.state.order.get('order_code')}</h2>

                                    <div className='order-folder-label'>
                                        Cliente
                                    </div>
                                    {this.state.proposal && this.state.proposal.name_client}

                                    <div className='order-folder-label'>
                                        Obra
                                    </div>
                                    {this.state.proposal && this.state.proposal.name_work}

                                    <div className='order-folder-label'>
                                        Documentos comerciais
                                    </div>
                                    {
                                        this.renderFile(
                                            'Pedido',
                                            `${process.env.REACT_APP_PUBLIC}/pdf_order/${((this.state.proposal && this.state.proposal.name_client) || '').replace('/', '')}-${this.state.order && this.state.order.get('order_code')}-Multidoor.pdf`.replace(/\s/g, '-')
                                        )
                                    }

                                    <div className='order-folder-label'>
                                        Documentos técnicos
                                    </div>
                                    {
                                        ((this.state.order && this.state.order.get("client_docs_folder")) || []).map(folder => {
                                            return this.renderFolder(folder)
                                        })
                                    }

                                    <div className='order-folder-label'>
                                        Documentos diversos
                                    </div>
                                    {this.renderFolder({ doc_description: 'Manuais', docs: ((this.state.order && this.state.order.get("tech_docs_required")) || []) })}
                                </div>

                            </Spin>

                        </Card>

                    </div>

                </div>

            </div>
        )
    }

}