import React from 'react'
import { Icon, Tag } from 'antd'
export default {
	"fields": [
		[
			{
				type: 'select',
				key: 'performance',
				title: 'Desempenho',
				options: [],
				optionConfig: { name: 'performance' },
				loaded: false,
				colSize: 12,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
			{
				type: 'select',
				key: 'sheet_width',
				title: 'Dimensão da folha',
				options: [],
				optionConfig: { name: 'larguras_folha' },
				loaded: false,
				colSize: 12,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
		],
		[
			{
				type: 'select',
				key: 'typology',
				title: 'Tipologia',
				options: [],
				optionConfig: { name: 'typology', arrayWithId: true },
				loaded: false,
				colSize: 9,
				'element-attr': {
					required: true,
				}
			},
			{
				"title": "Número de Folhas",
				"key": "sheet_qtd",
				"type": "number",
				colSize: 3,
				"element-attr": {
					"required": true,
					"min": 1,
					"step": 1
				}
			},
			{
				"title": "Largura do Alizar fixo",
				"key": "width_aliz",
				"type": "number",
				colSize: 6,
				"element-attr": {
					"required": true,
					"min": 1,
					"step": 1
				}
			},
			{
				"title": "Largura do Alizar Regulável",
				"key": "width_aliz_adjustable",
				"type": "number",
				colSize: 6,
				"element-attr": {
					"required": true,
					"min": 1,
					"step": 1
				}
			}
		]
	],
	submit: {
		collection: "WidthAliz"
	},
	className: "config-component",
	schema: [
		{
			title: 'Desempenho',
			key: 'performance',
			dataIndex: 'performance',
			className: "performance",
			type: 'text',
			render: v => {
				return (v || [])
					.map((performance,i) => <Tag key={i}>{performance}</Tag>)
			}
		},
		{
			title: 'Dimensão da folha',
			key: 'sheet_width',
			dataIndex: 'sheet_width',
			render: v => {
			  return (v || [])
				.map((sheet_width,i) => <Tag key={i}>{sheet_width}</Tag>)
			}
		},
		{
			title: 'Tipologia',
			key: 'typology',
			dataIndex: 'typology',
			"type": "text",
      		className: "name"
		},
		{
			title: "Número de Folhas",
			key: "sheet_qtd",
			dataIndex: "sheet_qtd",
			type: "number",
			className: "performance"
		},
		{
			title: "Largura do Alizar fixo",
			key: "width_aliz",
			dataIndex: "width_aliz",
			type: "number",
			className: "performance"
		},
		{
			title: "Largura do Alizar regulável",
			key: "width_aliz_adjustable",
			dataIndex: "width_aliz_adjustable",
			type: "number",
			className: "performance"
		},
	],
	"title-navigation": <div><Icon type="arrows-alt" /> Largura de Alizares</div>,
	"title-module": "Largura de Alizares",
	"module": "WidthAliz",
	"router-base": "/panel/largura-alizares"
}