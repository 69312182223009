import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, Modal, notification, Row, Select } from 'antd'
import React, { Component } from 'react'
import { findAll, findWhere, findWhereMultiple, remove, save } from '../../../../utils/db_functions'
import Parse from 'parse'
import ComponentsToChangeModal from './components-to-change-modal'

export default class MassChangeModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleModal: false,
            finishings: [],
            performances: [],
            manHours: [],
            componentTypes: [],
            loadingButton: false,
            loadingDeleteButton: false,
            form: {
                group: null,
                code: null,
                performance: null,
                finishing: null,
                newFinishingLogic: null,
            },
            changeFields: {
                performance: null,
                finishing: null,
                height_component: null,
                width_component: null,
                fill_componente: null,
                product_code: null,
                pis_conf: null,
                custo_comer: null,
                margin_contri: null,
                unity_value_hh: null,
                size_hh: null,
            },
            inputs: [],
            inputsToChange: {
                toChange: null,
                changeTo: null,
                description: null,
                unity: null,
                amount_component: null,
                width_component: null,
                thickness_component: null,
                length_component: null,
            },
            inputsToDelete: [],
            descriptionToChange: {
                toChange: null,
                changeTo: null,
            },

            loadingComponentsToChange: false,
            componentsToChange: [],
        }
    }

    componentDidMount = async () => {
        const unityConfigParseObject = await findWhere('Config', 'name', ['unidades'])

        const finishings = await findAll('Finishing', 200, null, null, 'code')
        this.setState({
            finishings: finishings.map((finishing) => {
                return {
                    label: `${finishing.get('code')} - ${finishing.get('description')}`,
                    value: finishing.id,
                }
            }),
            unityArray: (unityConfigParseObject && unityConfigParseObject[0].get('value')) || [],
        })

        const inputs = await findAll('Input', 2000, null, null, 'code')
        this.setState({
            inputs: inputs.length ?
                inputs.map((input) => {
                    return { label: `${input.get('code')} - ${input.get('material_description')}`, value: input.id }
                }) : []
        })

        const performanceConfigArray = await findWhere('Config', 'name', ['performance'])
        const performances = (performanceConfigArray[0] && performanceConfigArray[0].get('value')) || []
        this.setState({
            performances: performances.map((performance) => {
                return { label: performance, value: performance }
            })
        })

        const manHours = await findAll('ManHour')
        this.setState({
            manHours: manHours.map((manHour) => {
                return {
                    label: manHour.get('description'),
                    value: manHour.id,
                }
            }),
        })

        let componentTypeConfigParseObject = await findWhere('Config', 'name', ['tipo_componente'])
        componentTypeConfigParseObject = componentTypeConfigParseObject && componentTypeConfigParseObject[0];
        if(componentTypeConfigParseObject){
            const componentTypes = componentTypeConfigParseObject.get('value');
            this.setState({ componentTypes });
        }
    }

    getComponentsToChange = async () => {
        if (!this.state.form.group || !this.state.form.code) return []

        let conditionsArray = [
            { 'field': 'group', 'value': this.state.form.group, 'op': 'equalTo', logicOp: '$and' },
            { 'field': 'product_code', 'value': this.state.form.code, 'op': 'like', logicOp: '$and' },
        ]
        if (this.state.form.performance)
            conditionsArray.push({ 'field': 'performance', 'value': this.state.form.performance.trim(), 'op': 'equalTo', logicOp: '$and' })

        let components = await findWhereMultiple('ComponenteKit', conditionsArray, ['finishing'], null, 20000)

        if (this.state.form.finishing) {
            if (!this.state.form.newFinishingLogic)
                components = components.filter(component => (component.get('finishing') && component.get('finishing').id) === this.state.form.finishing)
            else
                components = components.filter(component => (component.get('finishing_options_inputs') && component.get('finishing_options_inputs').length && component.get('finishing_options_inputs').find(el => el.finishing === this.state.form.finishing)))
        }

        return components

    }

    executeMassChanges = async () => {
        this.setState({ loadingButton: true })

        const components = await this.getComponentsToChange()

        const componentIdsAndInputs = components.map(el => {
            return {
                id: el.id,
                product_code: el.get('product_code'),
                descriptions: el.get('descriptions'),
                finishing_options_inputs: el.get('finishing_options_inputs') || [],
                description: el.get('description_component'),
            }
        })

        for (let componentIndex = 0; componentIndex < componentIdsAndInputs.length; componentIndex++) {
            const component = componentIdsAndInputs[componentIndex];

            let componentToUpdateObject = {}

            const attrArray = Object.keys(this.state.changeFields)
            for (let attrIndex = 0; attrIndex < attrArray.length; attrIndex++) {
                const attr = this.state.changeFields[attrArray[attrIndex]];

                if (attrArray[attrIndex] === 'finishing' && attr && !this.state.form.newFinishingLogic) {
                    var finishingToRef = Parse.Object.extend("Finishing");
                    var pointerToFinishing = new finishingToRef();
                    pointerToFinishing.id = attr;
                    componentToUpdateObject[attrArray[attrIndex]] = pointerToFinishing
                } else if (attrArray[attrIndex] === 'product_code' && attr) {
                    componentToUpdateObject[attrArray[attrIndex]] = component['product_code'].replace(this.state.form.code, attr)
                } else if (attrArray[attrIndex] === 'unity_value_hh' && attr) {
                    var manHourToRef = Parse.Object.extend("ManHour");
                    var pointerToManHour = new manHourToRef();
                    pointerToManHour.id = attr;
                    componentToUpdateObject[attrArray[attrIndex]] = pointerToManHour
                } else if (attr) componentToUpdateObject[attrArray[attrIndex]] = attr
            }

            if (this.state.descriptionToChange.toChange) componentToUpdateObject['description_component'] = this.changeDescription(component)
            if (this.state.inputsToChange.toChange) {
                if (!this.state.form.newFinishingLogic)
                    componentToUpdateObject['descriptions'] = this.changeInputs(component)
                else
                    componentToUpdateObject['finishing_options_inputs'] = this.changeFinishingInputs(component)

            }
            if (this.state.inputsToDelete.length) {
                if (!this.state.form.newFinishingLogic)
                    componentToUpdateObject['descriptions'] = this.deleteInputs(component)
                else
                    componentToUpdateObject['finishing_options_inputs'] = this.deleteFinishingInputs(component)
            }

            componentToUpdateObject['updatedBy'] = Parse.User.current()

            await save('ComponenteKit', componentToUpdateObject, component.id)
        }

        this.setState({ loadingButton: false })

        this.setState({ visibleModal: false })
        notification['success']({
            message: 'Atualização realizada com sucesso',
            description: 'Os dados dos componentes foram atualizados'
        })
    }

    changeDescription = (component) => {
        const description = component.description.replace(this.state.descriptionToChange.toChange, this.state.descriptionToChange.changeTo)

        return description
    }

    changeInputs = (component) => {
        const descriptions = component.descriptions.map((inputGroup) => {

            if (
                !this.state.inputsToChange.description ||
                (inputGroup.description || "").toLowerCase().includes((this.state.inputsToChange.description || "").toLowerCase())
            ) {
                return {
                    ...inputGroup,
                    rows: (inputGroup.rows || []).map(input => {
                        if (input.specification === this.state.inputsToChange.toChange) {
                            input.specification = this.state.inputsToChange.changeTo
                            if (this.state.inputsToChange.unity) input.unity = this.state.inputsToChange.unity
                            if (this.state.inputsToChange.amount_component) input.amount_component = this.state.inputsToChange.amount_component
                            if (this.state.inputsToChange.width_component) input.width_component = this.state.inputsToChange.width_component
                            if (this.state.inputsToChange.thickness_component) input.thickness_component = this.state.inputsToChange.thickness_component
                            if (this.state.inputsToChange.length_component) input.length_component = this.state.inputsToChange.length_component
                        }
                        return input
                    })
                }
            }

            return inputGroup
        })

        return descriptions
    }

    changeFinishingInputs = (component) => {
        const finishings = component.finishing_options_inputs.map((inputGroup) => {

            if (inputGroup.finishing === this.state.form.finishing) {
                return {
                    ...inputGroup,
                    rows: (inputGroup.rows || []).map(input => {
                        if (input.specification === this.state.inputsToChange.toChange) {
                            input.specification = this.state.inputsToChange.changeTo
                            if (this.state.inputsToChange.unity) input.unity = this.state.inputsToChange.unity
                            if (this.state.inputsToChange.amount_component) input.amount_component = this.state.inputsToChange.amount_component
                            if (this.state.inputsToChange.width_component) input.width_component = this.state.inputsToChange.width_component
                            if (this.state.inputsToChange.thickness_component) input.thickness_component = this.state.inputsToChange.thickness_component
                            if (this.state.inputsToChange.length_component) input.length_component = this.state.inputsToChange.length_component
                        }
                        return input
                    })
                }
            }

            return inputGroup
        })

        return finishings
    }

    deleteInputs = (component) => {
        const descriptions = component.descriptions.map((inputGroup) => {

            if (
                !this.state.inputsToChange.description ||
                (inputGroup.description || "").toLowerCase().includes((this.state.inputsToChange.description || "").toLowerCase())
            ) {
                return {
                    ...inputGroup,
                    rows: (inputGroup.rows || []).filter(input => !(this.state.inputsToDelete.includes(input.specification)))
                }
            }

            return inputGroup
        })

        return descriptions
    }

    deleteFinishingInputs = (component) => {
        const finishings = component.finishing_options_inputs.map((inputGroup) => {

            if (inputGroup.finishing === this.state.form.finishing) {
                return {
                    ...inputGroup,
                    rows: (inputGroup.rows || []).filter(input => !(this.state.inputsToDelete.includes(input.specification)))
                }
            }

            return inputGroup
        })

        return finishings
    }

    executeMassDelete = async () => {
        this.setState({ loadingDeleteButton: true })

        const components = await this.getComponentsToChange()

        const componentIds = components.map(el => el.id)

        for (let componentIndex = 0; componentIndex < componentIds.length; componentIndex++) {
            const componentId = componentIds[componentIndex];
            await remove('ComponenteKit', componentId)
        }

        this.setState({ loadingDeleteButton: false })

        this.setState({ visibleModal: false })
        notification['success']({
            message: 'Atualização realizada com sucesso',
            description: 'Os registros foram deletados'
        })
    }

    render() {
        return (
            <div style={{ float: 'left', marginRight: '10px' }}>
                <Button type='primary' onClick={() => this.setState({ visibleModal: true })}>
                    Alterações em massa
                </Button>

                <Modal
                    title='Alteração em massa'
                    visible={this.state.visibleModal}
                    width='800px'
                    onCancel={() => this.setState({ visibleModal: false })}
                    footer={[
                        <Button 
                            disabled={!(this.state.form.group && this.state.form.code)}
                            type='danger'
                            loading={this.state.loadingDeleteButton}
                            onClick={() => {
                                const _this = this
                                Modal.confirm({
                                    title: 'Deseja realmente deletar os registros?',
                                    content: 'Verifique a lista de registros, pois essa ação não poderá ser desfeita',
                                    okText: 'Sim',
                                    cancelText: 'Cancelar',
                                    onOk: async () => {
                                        await _this.executeMassDelete()
                                        window.location.reload()
                                    },
                                })
                            }}
                        >Deletar registros</Button>,
                        <Button
                            disabled={!(this.state.form.group && this.state.form.code)}
                            type='primary'
                            loading={this.state.loadingButton}
                            onClick={() => {
                                const _this = this
                                Modal.confirm({
                                    title: 'Deseja realmente realizar essa alteração?',
                                    content: 'Verifique todos os campos antes de realizar essa ação, pois ela não poderá ser desfeita',
                                    okText: 'Sim',
                                    cancelText: 'Cancelar',
                                    onOk() {
                                        _this.executeMassChanges()
                                    },
                                })
                            }}
                        >Realizar alterações</Button>,
                        <Button onClick={() => this.setState({ visibleModal: false })}>Cancelar</Button>
                    ]}
                >

                    <Row gutter={24}>

                        <Divider>Campos para busca</Divider>

                        <Col span={12}>
                            <Form.Item label='Tipo de Componente'>
                                <Select
                                    value={this.state.form.group}
                                    onChange={async e => {
                                        await this.setState({ form: { ...this.state.form, group: e }, loadingComponentsToChange: true })

                                        const componentsToChange = await this.getComponentsToChange()
                                        this.setState({ componentsToChange, loadingComponentsToChange: false })
                                    }}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.componentTypes.map(componentType => {
                                        return <Select.Option value={componentType}>{componentType}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Código do Produto'>
                                <Input
                                    value={this.state.form.code}
                                    placeholder='Código do produto'
                                    onChange={e => this.setState({ form: { ...this.state.form, code: e.target.value } })}
                                    onBlur={async () => {
                                        await this.setState({ loadingComponentsToChange: true })

                                        const componentsToChange = await this.getComponentsToChange()
                                        this.setState({ componentsToChange, loadingComponentsToChange: false })
                                    }}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Desempenho da folha'>
                                <Select
                                    value={this.state.form.performance}
                                    onChange={async e => {
                                        await this.setState({ form: { ...this.state.form, performance: e }, loadingComponentsToChange: true })

                                        const componentsToChange = await this.getComponentsToChange()
                                        this.setState({ componentsToChange, loadingComponentsToChange: false })
                                    }}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.performances.map(performance => {
                                        return <Select.Option value={performance.value}>{performance.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label='Acabamento'>
                                <Select
                                    value={this.state.form.finishing}
                                    onChange={async e => {
                                        await this.setState({ form: { ...this.state.form, finishing: e }, loadingComponentsToChange: true })

                                        const componentsToChange = await this.getComponentsToChange()
                                        this.setState({ componentsToChange, loadingComponentsToChange: false })
                                    }}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.finishings.map(finishing => {
                                        return <Select.Option value={finishing.value}>{finishing.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12} style={{ color: 'white' }}>-</Col>
                        <Col span={12}>
                            <Checkbox
                                checked={this.state.form.newFinishingLogic}
                                onChange={async e => {
                                    this.setState({
                                        form: { ...this.state.form, newFinishingLogic: e.target.checked },
                                        loadingComponentsToChange: true,
                                        inputsToChange: { ...this.state.inputsToChange, description: null }
                                    })
                                    const componentsToChange = await this.getComponentsToChange()
                                    this.setState({ componentsToChange, loadingComponentsToChange: false })
                                }}
                            >
                                Nova lógica do acabamento
                            </Checkbox>
                        </Col>
                    </Row>

                    {this.state.loadingComponentsToChange ?
                        <div>Carregando componentes que serão atualizados...</div>
                        :
                        <div>

                            {this.state.componentsToChange.length}&nbsp;
                            {
                                this.state.componentsToChange.length === 1 ?
                                    <span>componente será atualizado.&nbsp;</span>
                                    :
                                    <span>componentes serão atualizados.&nbsp;</span>
                            }
                            <ComponentsToChangeModal componentsToChange={this.state.componentsToChange} />
                        </div>
                    }
                    <br /><br />

                    <Divider>Campos que serão atualizados</Divider>

                    <Row gutter={24} style={{ marginTop: '30px' }}>
                        <Col span={10}>
                            <Form.Item label='Desempenho da folha'>
                                <Select
                                    value={this.state.changeFields.performance}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, performance: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.performances.map(performance => {
                                        return <Select.Option value={performance.value}>{performance.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item label='Referência HH'>
                                <Select
                                    value={this.state.changeFields.unity_value_hh}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, unity_value_hh: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.manHours.map(manHour => {
                                        return <Select.Option value={manHour.value} key={manHour.value}>{manHour.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label='Quantidade HH'>
                                <InputNumber
                                    value={this.state.changeFields.size_hh}
                                    placeholder='Quantidade HH'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, size_hh: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Acabamento'>
                                <Select
                                    value={this.state.changeFields.finishing}
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, finishing: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {this.state.finishings.map(finishing => {
                                        return <Select.Option value={finishing.value}>{finishing.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Altura (mm)'>
                                <InputNumber
                                    value={this.state.changeFields.height_component}
                                    placeholder='Altura (mm)'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, height_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Largura (mm)'>
                                <InputNumber
                                    value={this.state.changeFields.width_component}
                                    placeholder='Largura (mm)'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, width_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Espessura (mm)'>
                                <InputNumber
                                    value={this.state.changeFields.fill_componente}
                                    placeholder='Espessura (mm)'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, fill_componente: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Código'>
                                <Input
                                    value={this.state.changeFields.product_code}
                                    placeholder='Código'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, product_code: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='ICMS + Fed.'>
                                <InputNumber
                                    value={this.state.changeFields.pis_conf}
                                    placeholder='ICMS + Fed'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, pis_conf: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Fixo + Comercial'>
                                <InputNumber
                                    value={this.state.changeFields.custo_comer}
                                    placeholder='Fixo + Comercial'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, custo_comer: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Margem'>
                                <InputNumber
                                    value={this.state.changeFields.margin_contri}
                                    placeholder='Margem'
                                    onChange={e => this.setState({ changeFields: { ...this.state.changeFields, margin_contri: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '50px' }}>

                        <Divider>Alterar descrição</Divider>

                        <Col span={12}>
                            <Form.Item label='Trecho a ser substituido'>
                                <Input
                                    value={this.state.descriptionToChange.toChange}
                                    placeholder='Trecho a ser substituido'
                                    onChange={e => this.setState({ descriptionToChange: { ...this.state.descriptionToChange, toChange: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Trecho a substituir'>
                                <Input
                                    value={this.state.descriptionToChange.changeTo}
                                    placeholder='Trecho a substituir'
                                    onChange={e => this.setState({ descriptionToChange: { ...this.state.descriptionToChange, changeTo: e.target.value } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24} style={{ marginTop: '50px' }}>

                        <Divider>Substituição de insumos {this.state.form.newFinishingLogic ? 'do acabamento' : null}</Divider>

                        <Col span={24}>
                            <Form.Item label='Insumo a ser substituido'>
                                <Select
                                    value={this.state.inputsToChange.toChange}
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, toChange: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.inputs.map(input => {
                                            return <Select.Option value={input.value} key={`inputToChange_${input.value}`}>{input.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label='Isumo a substituir'>
                                <Select
                                    value={this.state.inputsToChange.changeTo}
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, changeTo: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.inputs.map(input => {
                                            return <Select.Option value={input.value} key={`inputToChange_${input.value}`}>{input.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Composição'>
                                <Input
                                    value={this.state.inputsToChange.description}
                                    placeholder='Composição (ex.: Quadro)'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, description: e.target.value } })}
                                    disabled={this.state.form.newFinishingLogic === true}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Unidade'>
                                <Select
                                    value={this.state.inputsToChange.unity}
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, unity: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        (this.state.unityArray || []).map(unity => {
                                            return <Select.Option value={unity} key={`unityToChange_${unity}`}>{unity}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Quantidade'>
                                <InputNumber
                                    value={this.state.inputsToChange.amount_component}
                                    placeholder='Quantidade'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, amount_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Largura'>
                                <InputNumber
                                    value={this.state.inputsToChange.width_component}
                                    placeholder='Largura'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, width_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Espessura'>
                                <InputNumber
                                    value={this.state.inputsToChange.thickness_component}
                                    placeholder='Espessura'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, thickness_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item label='Comprimento'>
                                <InputNumber
                                    value={this.state.inputsToChange.length_component}
                                    placeholder='Comprimento'
                                    onChange={e => this.setState({ inputsToChange: { ...this.state.inputsToChange, length_component: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label='Isumos a serem deletados'>
                                <Select
                                    value={this.state.inputsToDelete}
                                    onChange={e => this.setState({ inputsToDelete: e })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode='multiple'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.inputs.map(input => {
                                            return <Select.Option value={input.value} key={`inputToDelete_${input.value}`}>{input.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </div>
        )
    }

}