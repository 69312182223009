import React from 'react'
import { Icon } from 'antd'
import { defaultMarcoMaterials } from '../../../utils/defaultDataSource.js'

export default {
	"fields": [
		[
			{
				type: 'select',
				key: 'material',
				title: 'Material',
				options: defaultMarcoMaterials,
				colSize: 8,
				'element-attr': {
					required: true,
					mode: 'default',
				}
			},
			{
				type: 'number',
				key: 'wall_thickness',
				title: 'Espessura parede',
				colSize: 4,
				'element-attr': {
					required: true,
					step: 1
				}
			},
			{
				type: 'number',
				key: 'marco_thickness',
				title: 'Espessura marco',
				colSize: 4,
				'element-attr': {
					required: true,
					step: 1
				}
			},
			{
				type: 'text',
				key: 'adjustable_aliz',
				title: 'Alizar Regulável',
				colSize: 4,
				'element-attr': { required: false }
			},
			{
				type: 'text',
				key: 'marco_passagem',
				title: 'Marco de Passagem',
				colSize: 4,
				'element-attr': { required: false }
			},
		],
	],
	submit: {
		collection: "MatrizMarcoThickness"
	},
	schema: [
		{
			"title": "Material",
			"key": "material",
			"dataIndex": "material",
			"type": "render",
			render: (material) => {
				const materialData = defaultMarcoMaterials.find(el => el.value === material)
				return materialData && materialData.label
			}
		},
		{
			"title": "Espessura parede",
			"key": "wall_thickness",
			"dataIndex": "wall_thickness",
			"type": "text",
		},
		{
			"title": "Espessura marco",
			"key": "marco_thickness",
			"dataIndex": "marco_thickness",
			"type": "text",
		},
		{
			"title": "Alizar Regulável",
			"key": "adjustable_aliz",
			"dataIndex": "adjustable_aliz",
			"type": "text",
		},
		{
			"title": "Marco de Passagem",
			"key": "marco_passagem",
			"dataIndex": "marco_passagem",
			"type": "text",
		},
	],
	"title-navigation": <div><Icon type="shrink" /> Matriz do Marco</div>,
	"title-module": "Matriz do Marco",
	"module": "MatrizMarcoThickness",
	"router-base": "/panel/matriz-espessura-marco"
}