import { Input, Modal, Spin, Table, Button } from 'antd'
import React, { Component } from 'react'
import { findWhereMultiple } from '../../../../utils/db_functions'

export default class ClientChooseModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            codClient: this.props.codClient,
            clientChooseModal: false,
            loading: false,
            clients: [],
        }
    }

    componentDidMount = async () => {
        let code = this.props.codClient

        if (!code && this.props.uniqueId) {
            const clients = await findWhereMultiple(
                'Client',
                [
                    { 'field': 'cpf', 'value': this.props.uniqueId, 'op': 'equalTo', logicOp: '$or' },
                    { 'field': 'cnpj', 'value': this.props.uniqueId, 'op': 'equalTo', logicOp: '$or' }
                ]
            )
            if (clients && clients.length) code = clients[0].get('cod_cliente')
        }

        this.setState({ codClient: code })
    }

    componentWillReceiveProps = async (nextProps) => {
        let code = nextProps.codClient

        if (!code && nextProps.uniqueId) {
            const clients = await findWhereMultiple(
                'Client',
                [
                    { 'field': 'cpf', 'value': nextProps.uniqueId, 'op': 'equalTo', logicOp: '$or' },
                    { 'field': 'cnpj', 'value': nextProps.uniqueId, 'op': 'equalTo', logicOp: '$or' }
                ]
            )
            if (clients && clients.length) code = clients[0].get('cod_cliente')
        }

        if (code !== this.state.codClient) {
            this.setState({ codClient: code })
        }
    }

    searchClients = async (term) => {
        this.setState({ loading: true })

        const clientsFound = await findWhereMultiple(
            'Client',
            [
                { 'field': 'cod_cliente', 'value': term, 'op': 'like', logicOp: '$or' },
                { 'field': 'name_client', 'value': term, 'op': 'like', logicOp: '$or' }
            ],
            null, null, null, 'cod_cliente'
        )

        this.setState({
            clients: clientsFound,
            loading: false
        })
    }

    render() {

        const columns = [
            {
                title: 'Código',
                dataIndex: 'cod_cliente',
                key: 'cod_cliente',
                width: '150px',
                render: (_, client) => (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.props.chooseClient(client)
                            this.setState({ clientChooseModal: false, clients: [] })
                        }}
                    >
                        {client.get('cod_cliente')}
                    </div>
                )
            },
            {
                title: 'Cliente',
                dataIndex: 'name_client',
                key: 'name_client',
                render: (_, client) => (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.props.chooseClient(client)
                            this.setState({ clientChooseModal: false, clients: [] })
                        }}
                    >
                        {client.get('name_client')}
                    </div>
                )
            },
        ];

        return (
            <div>
                {
                    this.state.codClient ?
                        <div>
                            <Button
                                icon='delete'
                                type='danger'
                                onClick={() => this.props.removeClient()}
                            />&nbsp;&nbsp;
                            {this.state.codClient}
                        </div>
                        :
                        <div
                            className='link-style'
                            onClick={() => this.setState({ clientChooseModal: true })}
                        >
                            Escolher cliente
                        </div>
                }

                <Modal
                    title='Cliente'
                    visible={this.state.clientChooseModal}
                    onCancel={() => this.setState({ clientChooseModal: false })}
                    footer={[]}
                >
                    <Spin spinning={this.state.loading} tip='Carregando insumos'>

                        <Input.Search
                            style={{ marginBottom: '20px' }}
                            placeholder='Código ou nome do cliente'
                            onSearch={e => {
                                this.searchClients(e)
                            }}
                        />

                        <Table
                            dataSource={this.state.clients}
                            columns={columns}
                            locale={{ emptyText: 'Nenhum cliente encontrado' }}
                            pagination={false}
                        />
                    </Spin>
                </Modal>
            </div>
        )

    }

}