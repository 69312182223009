import React from 'react'
import { Icon, Tag } from 'antd'
export default {
	"fields": [
		[
			{
				"title": "Nível de Desempenho",
				"key": "profile_performance",
				"type": "text",
				"element-attr": {
					required: true,
					prefix: <Icon type="tags" />
				}
			},
			{
				type: 'select',
				key: 'performance_level',
				title: 'Perfis de Desempenho',
				options: [],
				optionConfig: { name: 'performance' },
				loaded: false,
				colSize: 12,
				'element-attr': {
					required: true,
					mode: 'multiple'
				}
			},
        ]
	],
	submit: {
		collection: "ProfilePerformance"
	},
	className: "config-component",
	schema: [
		{
			title: "Nível de Desempenho",
			key: "profile_performance",
			dataIndex: "profile_performance",
			type: "text",
			className: "name"
		},
		{
			title: 'Perfis de Desempenho',
			key: 'performance_level',
			dataIndex: 'performance_level',
			render: v => {
			  return (v || [])
				.map((performance,i) => <Tag key={i}>{performance}</Tag>)
			}
		}
	],
	"title-navigation": <div><Icon type="apartment" /> Perfil de Desempenho</div>,
	"title-module": "Perfil de Desempenho",
	"module": "ProfilePerformance",
	"router-base": "/panel/desempenho"
}