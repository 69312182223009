import { Button, Col, Form, InputNumber, message, Modal, Popover, Row, Select, Table } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import React, { Component, Fragment } from 'react'
import '../sales-channel.css'

export default class Rules extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rules: this.props.rules,

            rulesModal: false,

            ruleFormModal: false,
            ruleForm: {
                ruleIndex: null,
                param: null,
                rule: null,
                value: null,
            },

            paramsOptions: [
                { value: 'qtd', label: 'Qtd de kits' },
                { value: 'value', label: 'Valor da proposta' },
            ],
            rulesOptions: [
                { value: '<', label: 'Menor que' },
                { value: '<=', label: 'Menor ou igual' },
                { value: '=', label: 'Igual' },
                { value: '>', label: 'Maior que' },
                { value: '>=', label: 'Maior ou igual' },
            ]
        }
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.rules !== this.state.rules)
            this.setState({ rules: nextProps.rules })
    }

    saveRule = () => {
        if (
            !this.state.ruleForm.param ||
            !this.state.ruleForm.rule ||
            (!this.state.ruleForm.value && this.state.ruleForm.value !== 0)
        ) {
            message.error('Preencha todos os campos antes de salvar')
        } else {
            let rules = this.state.rules

            if (this.state.ruleForm.ruleIndex) {
                rules[this.state.ruleForm.ruleIndex] = {
                    ...rules[this.state.ruleForm.ruleIndex],
                    param: this.state.ruleForm.param,
                    rule: this.state.ruleForm.rule,
                    value: this.state.ruleForm.value,
                }
            } else {
                rules.push({
                    param: this.state.ruleForm.param,
                    rule: this.state.ruleForm.rule,
                    value: this.state.ruleForm.value,
                })
            }

            this.setState({
                rules,
                ruleFormModal: false,
                ruleForm: { ruleIndex: null, param: null, rule: null, value: null }
            })

            this.props.updateRules(rules)
        }
    }

    render() {

        const rulesColumns = [
            {
                title: 'Parâmetro',
                dataIndex: 'param',
                key: 'param',
                render: (v) => {
                    const param = this.state.paramsOptions.find(el => el.value === v)
                    return param && param.label
                }
            },
            {
                title: 'Regra',
                dataIndex: 'rule',
                key: 'rule',
                align: 'center',
                render: (v) => {
                    const rule = this.state.rulesOptions.find(el => el.value === v)
                    return rule && rule.label
                }
            },
            {
                title: 'Valor',
                dataIndex: 'value',
                key: 'value',
                align: 'center',
                render: (v, _) => {
                    if (_.param === 'value') return v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    return v.toLocaleString('pt-BR')
                }
            },
            {
                title: 'Ações',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                render: (v, _, index) => {
                    return (
                        <ButtonGroup>
                            <Popover content='editar'>
                                <Button
                                    icon='edit'
                                    onClick={() => {
                                        this.setState({
                                            ruleFormModal: true,
                                            ruleForm: {
                                                ruleIndex: index,
                                                param: _.param,
                                                rule: _.rule,
                                                value: _.value,
                                            }
                                        })
                                    }}
                                />
                            </Popover>

                            <Popover content='Excluir'>
                                <Button
                                    icon='delete'
                                    onClick={() => {
                                        let _this = this
                                        Modal.confirm({
                                            iconType: 'exclamation-circle',
                                            title: 'Tem certeza que deseja exlcuir essa regra?',
                                            content: 'Essa ação não poderá ser desfeita',
                                            onOk() {
                                                let rules = _this.state.rules || []
                                                rules.splice(index, 1)
                                                _this.setState({ rules })

                                                // _this.props.updateRules(rules)
                                            },
                                            okText: 'Sim',
                                            cancelText: 'Não, deixa pra lá'
                                        })
                                    }}
                                />
                            </Popover>
                        </ButtonGroup>
                    )
                }
            }
        ];

        return (
            <Fragment className='rules-card-button'>
                <Popover content='Regras de desconto'>
                    <Button
                        icon='percentage'
                        onClick={() => this.setState({ rulesModal: true })}
                    />
                </Popover>

                <Modal
                    title={`Regras de desconto - ${this.props.description}`}
                    visible={this.state.rulesModal}
                    onCancel={() => this.setState({ rulesModal: false })}
                    footer={[
                        <Button type='primary' onClick={() => this.setState({ ruleFormModal: true })}>Adicionar nova regra de desconto</Button>,
                        <Button onClick={() => this.setState({ rulesModal: false })}>Fechar</Button>
                    ]}
                    width={800}
                >
                    <Table
                        columns={rulesColumns}
                        dataSource={this.state.rules}
                        pagination={false}
                        locale={{ emptyText: 'Sem regras' }}
                    />
                </Modal>

                <Modal
                    title='Regra'
                    visible={this.state.ruleFormModal}
                    onCancel={() => this.setState({ ruleFormModal: false, ruleForm: { ruleIndex: null, param: null, rule: null, value: null } })}
                    cancelText='Cancelar'
                    onOk={() => this.saveRule()}
                    okText='Salvar'
                    width={800}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label='Parâmetro'>
                                <Select
                                    value={this.state.ruleForm.param}
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, param: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.paramsOptions.map(option => {
                                            return (
                                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Regra'>
                                <Select
                                    value={this.state.ruleForm.rule}
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, rule: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                >
                                    <Select.Option value={null}>Selecione</Select.Option>
                                    {
                                        this.state.rulesOptions.map(option => {
                                            return (
                                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label='Valor'>
                                <InputNumber
                                    value={this.state.ruleForm.value}
                                    placeholder='Valor'
                                    onChange={e => this.setState({ ruleForm: { ...this.state.ruleForm, value: e } })}
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </Fragment>
        )

    }

}