import { Button, Modal, Spin, Input, Table } from 'antd'
import React, { Component } from 'react'
import { findWhereMultiple } from '../../../../utils/db_functions'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

export default class WorksMapModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            worksChoosed: [],

            chooseProposalModal: false,
            searchWork: null,
            loadingWorks: false,
            works: [],
        }
    }

    componentDidMount = async () => {

    }

    searchWork = async (param) => {
        this.setState({ loadingWorks: true })

        const allProposals = await findWhereMultiple('Proposal', [{ 'field': 'name_work', 'value': param, 'op': 'like' }], null, null, null, 'proposal_code', 'descending')
        const works = allProposals
            .map((proposal, proposalIndex) => {
                const proposalCode = proposal.get('proposal_code').slice(0, -3)
                const work = {
                    work: proposal.get('name_work'),
                    address: `${proposal.get('city_work')}-${proposal.get('state_work')}`,
                    proposal: proposalCode,
                    lat: proposal.get('generalInfo') && proposal.get('generalInfo')['workAddressLat'],
                    long: proposal.get('generalInfo') && proposal.get('generalInfo')['workAddressLong']
                }

                if (proposalIndex === 0) return work
                else if (proposal.get('proposal_code').slice(0, -3) !== allProposals[proposalIndex - 1].get('proposal_code').slice(0, -3)) return work
                else return null
            })
            .filter(el => el)

        this.setState({
            works,
            loadingWorks: false
        })
    }

    addAWork = (work) => {
        const worksChoosed = [...this.state.worksChoosed, work]
        this.setState({ worksChoosed })
    }

    removeAWork = (index) => {
        const worksChoosed = this.state.worksChoosed
        worksChoosed.splice(index, 1)
        this.setState({ worksChoosed })
    }

    clearWorks = () => {
        this.setState({ worksChoosed: [] })
    }

    render() {

        const worksColumns = [
            {
                title: 'Obra',
                dataIndex: 'work',
                key: 'work',
                render: (work, row) => (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.addAWork(row)
                            this.setState({
                                works: [],
                                chooseProposalModal: false,
                                searchWork: null,
                            })
                        }}
                    >
                        {work}
                    </div>
                )
            },
            {
                title: 'Endereço',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: 'Proposta',
                dataIndex: 'proposal',
                key: 'proposal'
            },
        ];

        const worksChoosedColumns = [
            {
                title: 'Obra',
                dataIndex: 'work',
                key: 'work'
            },
            {
                title: 'Endereço',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: 'Proposta',
                dataIndex: 'proposal',
                key: 'proposal'
            },
        ];

        return (
            <div>

                <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => this.setState({ visible: true })}
                >Mapa de obras</Button>

                <Modal
                    visible={this.state.visible}
                    title='Mapa de obras'
                    onCancel={() => this.setState({ visible: false })}
                    footer={[]}
                    width={800}
                >
                    <Table
                        dataSource={this.state.worksChoosed}
                        columns={worksChoosedColumns}
                        locale={{ emptyText: 'Nenhuma obra adicionada' }}
                        pagination={false}
                    />

                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Button
                            style={{ float: 'left' }}
                            type='primary'
                            onClick={() => this.setState({ chooseProposalModal: true })}
                        >Adicionar obra</Button>

                        <Button
                            style={{ float: 'left', marginLeft: '10px' }}
                            onClick={() => this.clearWorks()}
                        >Deletar obras</Button>

                        <div style={{ clear: 'both' }} />
                    </div>

                    <LoadScript googleMapsApiKey="AIzaSyCOZc1o13-SOhuuBYbdrlZI33F9u9iRFnw">
                        <GoogleMap
                            mapContainerStyle={{
                                width: '750px',
                                height: '750px'
                            }}
                            center={{
                                lat: -15.725022024191858,
                                lng: -47.97650626992828,
                            }}
                            zoom={5}
                        >
                            {this.state.worksChoosed.map(work => {
                                return (
                                    <Marker
                                        key={work.proposal}
                                        position={{
                                            lat: parseFloat(work.lat),
                                            lng: parseFloat(work.long),
                                        }}
                                    />
                                )
                            })}
                        </GoogleMap>
                    </LoadScript>
                </Modal>

                <Modal
                    visible={this.state.chooseProposalModal}
                    title='Propostas'
                    onCancel={() => this.setState({ chooseProposalModal: false })}
                    footer={[]}
                >
                    <Spin spinning={this.state.loadingWorks} tip='Carregando obras'>

                        <Input.Search
                            style={{ marginBottom: '20px' }}
                            placeholder='Nome da obra'
                            onSearch={e => this.searchWork(e)}
                            onChange={e => this.setState({ searchWork: e.target.value })}
                            value={this.state.searchWork}
                        />

                        <Table dataSource={this.state.works} columns={worksColumns} locale={{ emptyText: 'Nenhuma obra encontrada' }} />
                    </Spin>
                </Modal>
            </div>
        )

    }

}