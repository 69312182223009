import { Button, Modal, Table } from 'antd'
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

export default class ComponentsToChangeModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            componentsToChange: this.props.componentsToChange,

            componentsToChangeModal: false,
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.componentsToChange !== this.state.componentsToChange)
            this.setState({ componentsToChange: nextProps.componentsToChange })
    }

    render() {
        return (
            <Fragment>

                <span
                    className='link-style'
                    onClick={() => this.setState({ componentsToChangeModal: true })}
                >Ver componente(s)</span>

                <Modal
                    visible={this.state.componentsToChangeModal}
                    title='Componentes que serão atualizados'
                    onCancel={() => this.setState({ componentsToChangeModal: false })}
                    footer={[
                        <Button
                            onClick={() => this.setState({ componentsToChangeModal: false })}
                        >Fechar</Button>
                    ]}
                    width={800}
                >
                    <Table
                        columns={[
                            {
                                title: 'Código',
                                key: 'code',
                                dataIndex: 'code',
                            },
                            {
                                title: 'Descrição',
                                key: 'description',
                                dataIndex: 'description',
                            },
                            {
                                title: 'Detalhe',
                                key: 'details',
                                dataIndex: 'details',
                                render: (v, _) => {
                                    return (
                                        <Link
                                            to={`/panel/montagem-kit/editar/${_.objectId}`}
                                            target='_blank'
                                        >
                                            <Button icon='eye' />
                                        </Link>
                                    )
                                }
                            }
                        ]}
                        dataSource={this.state.componentsToChange.map(el => el.toJSON())}
                        locale={{ emptyText: "Nenhum componente encontrado." }}
                    />
                </Modal>
            </Fragment>
        )
    }

}