import { Button, Col, Collapse, Divider, Form, Icon, Row, Select, Spin, Table } from 'antd'
import { getNameOfProposalPdf } from '../../proposal/suport-files/proposal-functions'
import React, { Component } from 'react'
import { findWhereMultiple } from '../../../../utils/db_functions'
import { Link } from 'react-router-dom'
import tableFooterComponent from './table-footer'
import ProposalStatusClass from '../../proposal/entities/proposal-status'

export default class ProposalsByTradeRepresentative extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            proposals: [],
            proposalsFiltered: [],
            startMonth: 0,
            endMonth: 11,
            year: new Date().getFullYear(),
            status: null,
            objectId: null
        }
    }

    componentDidUpdate = async (previousProps, previousState) => {
        if (previousProps.form.state.formRef.objectId) {
            if (previousProps.form !== previousState.form) {

                const year = new Date().getFullYear()

                const proposals = await this.getProposals(year, previousProps.form.state.formRef.objectId)

                this.setState({ form: previousProps.form, proposals, proposalsFiltered: proposals, loading: false, objectId: previousProps.form.state.formRef.objectId })
            }
        }
    }

    months = [
        // { label: 'Todos', value: null },
        { label: 'Janeiro', value: 0 },
        { label: 'Fevereiro', value: 1 },
        { label: 'Março', value: 2 },
        { label: 'Abril', value: 3 },
        { label: 'Maio', value: 4 },
        { label: 'Junho', value: 5 },
        { label: 'Julho', value: 6 },
        { label: 'Agosto', value: 7 },
        { label: 'Setembro', value: 8 },
        { label: 'Outubro', value: 9 },
        { label: 'Novembro', value: 10 },
        { label: 'Dezembro', value: 11 },
    ]

    getProposals = async (year, objectId) => {

        let proposals = await findWhereMultiple(
            'Proposal',
            [
                { 'field': 'consult_repre_id', 'value': objectId, 'op': 'equalTo', logicOp: '$and' },
                { 'field': 'proposal_code', 'value': `${year}-`, 'op': 'like', logicOp: '$and' },
            ],
            null, null, 2000, 'proposal_code', 'descending',
        )

        proposals = proposals
            .map(proposal => {
                return { ...proposal.toJSON(), key: proposal.id }
            })

        // Get orders
        const proposalsWithOrder = proposals.filter(proposal =>
            proposal.orderGenerated || proposal.proposal_management_status === "Aprovada" || proposal.proposal_management_status === "PedidoGerado"
        )
        const orders = await findWhereMultiple(
            'Order',
            proposalsWithOrder.map(proposal => {
                return { 'field': 'proposal', 'value': `${proposal.proposal_code}`, 'op': 'like', logicOp: '$or' };
            }), null, null, 2000
        )

        let parentProposals = []
        for (let proposalIndex = 0; proposalIndex < proposals.length; proposalIndex++) {
            const proposal = proposals[proposalIndex]

            const proposalCode = proposal.proposal_code.slice(0, -3)

            if (proposalIndex === 0)
                parentProposals.push({ ...proposal, proposal_code: proposalCode })
            else if (
                proposals[proposalIndex - 1].proposal_code.slice(0, -3) !==
                proposal.proposal_code.slice(0, -3)
            )
                parentProposals.push({ ...proposal, proposal_code: proposalCode, key: `__${proposal.key}` })
        }

        let proposalsToReturn = []
        for (let proposalIndex = 0; proposalIndex < parentProposals.length; proposalIndex++) {
            const proposal = parentProposals[proposalIndex];

            const order = orders.find(order => order.get("proposal").includes(`${proposal.proposal_code}-`))

            const allProposals = proposals
                .filter(childProposal => childProposal.proposal_code.includes(proposal.proposal_code))
                .sort((a, b) => (a.proposal_code > b.proposal_code) ? 1 : ((b.proposal_code > a.proposal_code) ? -1 : 0))

            proposalsToReturn.push({
                ...proposal,
                proposal_code: `${proposal.proposal_code} [${allProposals.length}]`,
                final_value: allProposals.reduce((prev, current) => current.final_value || prev, ""),
                orderGenerated: allProposals.reduce((prev, current) => current.final_value ? current.orderGenerated : prev, ""),
                proposal_management_status: allProposals.reduce((prev, current) => current.final_value ? current.proposal_management_status : prev, ""),
                baseFinalPriceAndAmount: allProposals.reduce((prev, current) => current.final_value ? current.baseFinalPriceAndAmount : prev, ""),
                key: proposal.key.replace('__', ''),
                approvedIn: order && order.get('order_date'),
                allProposals
            })
        }

        return proposalsToReturn
    }

    advancedFilterProposals = (proposals, startMonth, endMonth, status) => {
        const startDate = new Date(this.state.year, startMonth, 1)
        let endDate = new Date(this.state.year, endMonth + 1, 1)
        endDate.setDate(endDate.getDate() - 1)

        let proposalsFiltered = proposals
            .filter(parentalProposal => {
                const versions = parentalProposal.allProposals.sort((a, b) => (a.proposal_code > b.proposal_code) ? 1 : ((b.proposal_code > a.proposal_code) ? -1 : 0))
                let lastVersion = versions.reduce((prev, current) => current, null)

                const statusIsOk = (
                    !status ||
                    (lastVersion.orderGenerated && status === 'Aprovada') ||
                    (!lastVersion.proposal_management_status && status === 'Negociação') ||
                    (lastVersion.proposal_management_status === status)
                )

                const dateIsOk = (
                    lastVersion && (
                        new Date(lastVersion.updatedAt).getTime() > startDate.getTime() &&
                        new Date(lastVersion.updatedAt).getTime() < endDate.getTime()
                    )
                )

                return statusIsOk && dateIsOk ? true : false
            })

        return proposalsFiltered
    }

    render() {
        const columns = [
            {
                title: 'Número',
                dataIndex: 'proposal_code',
                key: 'proposal_code',
                width: '150px',
            },
            {
                title: 'Nome Cliente',
                dataIndex: 'name_client',
                key: 'name_client',
            },
            {
                title: 'Obra',
                dataIndex: 'name_work',
                key: 'name_work',
            },
            {
                title: 'Qtd kits',
                dataIndex: 'baseFinalPriceAndAmount',
                key: 'baseFinalPriceAndAmount',
                width: '100px',
                align: 'center',
                render: (v) => v && v.finalAmount
            },
            {
                title: 'Valor da proposta',
                dataIndex: 'final_value',
                key: 'final_value',
                width: '180px',
                render: (v) => v ? v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Proposta não emitida'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '80px',
                render: (_, proposal) => <ProposalStatusClass proposal={proposal} />,
            },
            {
                title: 'E-mail',
                dataIndex: 'allProposals',
                key: 'email_sent',
                width: '80px',
                render: (allProposals) => {
                    const lastProposal = allProposals.reduce((prev, current) => current || prev, null)
                    const historic = lastProposal.historic

                    let color = 'red';

                    if (historic && historic.length) {
                        historic.forEach(comment => {
                            if (
                                comment.comment.includes(`${lastProposal.proposal_code} enviada para o e-mail`) ||
                                comment.comment.includes(`${lastProposal.proposal_code} enviada para o cliente via whatsapp`)
                            ) color = 'green';
                        });
                    };

                    return (
                        <div
                            style={{
                                backgroundColor: color,
                                width: '20px', height: '20px',
                                margin: 'auto', borderRadius: '50%'
                            }}
                        />
                    );
                }
            },
            {
                title: "Data",
                key: "date",
                dataIndex: "allProposals",
                align: "center",
                width: '120px',
                render: (proposals) => {
                    const date = (proposals || []).reduce((prev, current) => {
                        return current.updatedAt || prev
                    }, null)
                    return (date && new Date(date).toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' })) || "-"
                }
            },
            {
                title: 'Aprovado em',
                dataIndex: 'approvedIn',
                key: 'approvedIn',
                width: '150px',
                render: (v) => v && v.toLocaleString('pt-BR', { timeZone: "America/Maceio", day: 'numeric', month: 'numeric', year: 'numeric' }),
            },
        ];

        const expandedContentColumns = [
            {
                title: 'Versão',
                dataIndex: 'proposal_code',
                key: 'proposal_code',
                width: '80px',
                render: (code, proposal) => {

                    return (
                        <Link to={'/panel/gerenciar-proposta/editar/' + proposal.objectId}>
                            {code.split("-")[2]}
                        </Link>
                    )
                }
            },
            {
                title: 'Qtd kits',
                dataIndex: 'baseFinalPriceAndAmount',
                key: 'baseFinalPriceAndAmount',
                align: 'center',
                render: (v) => v && v.finalAmount
            },
            {
                title: 'Valor da proposta',
                dataIndex: 'final_value',
                key: 'final_value',
                render: (v) => v ? v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Proposta não emitida'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                align: 'center',
                key: 'status',
                render: (_, proposal) => <ProposalStatusClass proposal={proposal} />,
            },
            {
                title: "PDF",
                key: "pdf",
                dataIndex: 'pdf',
                align: 'center',
                width: '50px',
                render: (_, proposal) => {
                    if (proposal.proposal_code.split("-").length === 2) return ""
                    const pdfName = getNameOfProposalPdf(proposal)

                    return (
                        <Button
                            title="Visualizar proposta em PDF"
                            disabled={!proposal.pdf} type="default" shape="circle" icon="file-pdf"
                            onClick={async () => window.open(pdfName, '_blink')}
                        />
                    )
                }
            }
        ];

        const customPanelStyle = {
            background: '#f7f7f7',
            borderRadius: 4,
            marginBottom: 24,
            border: 0,
            overflow: 'hidden',
        };

        return (
            <div>
                <Divider />

                <h3>Propostas</h3>

                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label='Ano'>
                            <Select
                                style={{ width: '100%', marginTop: '10px', marginBottom: '30px' }}
                                value={this.state.year}
                                onChange={async year => {
                                    this.setState({ loading: true, year })
                                    const proposals = await this.getProposals(year, this.state.objectId)
                                    this.setState({
                                        proposals,
                                        proposalsFiltered: proposals,
                                        startMonth: 0,
                                        endMonth: 11,
                                        status: null,
                                        loading: false
                                    })
                                }}
                            >
                                {
                                    Array(33).fill(2018).map((year, yearIndex) => {
                                        return <Select.Option key={year + yearIndex} value={year + yearIndex}>{year + yearIndex}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Collapse
                    bordered={false}
                    // defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                >
                    <Collapse.Panel header="Filtros avançados" key="1" style={customPanelStyle}>
                        <Row gutter={24}>
                            <Col span={4}>
                                <Form.Item label='Mês inicial'>
                                    <Select
                                        style={{ width: '100%', marginTop: '10px', marginBottom: '30px' }}
                                        value={this.state.startMonth}
                                        onChange={async startMonth => {
                                            this.setState({ loading: true, startMonth })
                                            const proposals = await this.advancedFilterProposals(this.state.proposals, startMonth, this.state.endMonth, this.state.status)
                                            this.setState({ proposalsFiltered: proposals, loading: false })
                                        }}
                                    >
                                        {
                                            this.months.map(month => {
                                                return <Select.Option key={month.label} value={month.value}>{month.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item label='Mês final'>
                                    <Select
                                        style={{ width: '100%', marginTop: '10px', marginBottom: '30px' }}
                                        value={this.state.endMonth}
                                        onChange={async endMonth => {
                                            this.setState({ loading: true, endMonth })
                                            const proposals = await this.advancedFilterProposals(this.state.proposals, this.state.startMonth, endMonth, this.state.status)
                                            this.setState({ proposalsFiltered: proposals, loading: false })
                                        }}
                                    >
                                        {
                                            this.months.map(month => {
                                                return <Select.Option key={month.label} value={month.value}>{month.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label='Status'>
                                    <Select
                                        style={{ width: '100%', marginTop: '10px', marginBottom: '30px' }}
                                        value={this.state.status}
                                        onChange={async status => {
                                            this.setState({ loading: true, status })
                                            // const proposals = await this.getProposals(this.state.year, this.state.objectId)
                                            const proposals = await this.advancedFilterProposals(this.state.proposals, this.state.startMonth, this.state.endMonth, status)
                                            this.setState({ proposalsFiltered: proposals, loading: false })
                                        }}
                                    >
                                        {
                                            [
                                                { label: 'Todos', value: null },
                                                { label: 'Negociação', value: 'Negociação' },
                                                { label: 'Negociação Semestre', value: 'NegociaçãoSemestre' },
                                                { label: 'Especificação BIM', value: 'EspecificaçãoBIM' },
                                                { label: 'Concorrência', value: 'Concorrência' },
                                                { label: 'Aprovada', value: 'Aprovada' },
                                                { label: 'Parada', value: 'Parada' },
                                                { label: 'Cancelada', value: 'Cancelada' },
                                            ].map(status => {
                                                return <Select.Option key={`status_${status.value}`} value={status.value}>{status.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>

                {this.state.loading ?

                    <div style={{ width: '100%', textAlign: 'center' }}><Spin spinning={this.state.loading} tip='Carregando as propostas' /></div> :
                    <Table
                        dataSource={this.state.proposalsFiltered}
                        columns={columns}
                        locale={{ emptyText: 'Sem propostas' }}
                        footer={tableFooterComponent}
                        pagination={false}
                        scroll={{ y: 400 }}
                        expandedRowRender={(proposal) => {
                            return (
                                <div style={{ width: "650px", margin: "auto" }}>
                                    <Table
                                        size='small'
                                        dataSource={proposal.allProposals}
                                        columns={expandedContentColumns}
                                        locale={{ emptyText: 'Sem propostas' }}
                                        pagination={false}
                                    />
                                </div>
                            )
                        }}
                    />
                }
            </div>
        )
    }

}