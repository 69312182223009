import Parse from "parse";
import { findWhere } from "../../../../utils/db_functions";
import { moneyToNumber } from "../../../../utils/general_functions";

const generateCode = async (FormModule) => {
  const currentYear = new Date().getFullYear();

  let code = `0001-${currentYear.toString()}`;

  const _query = new Parse.Query("InputOrder")
    .endsWith("order_code", currentYear.toString())
    .descending("order_code");

  let inputOrders = await _query.find();

  if (inputOrders.length) {
    let intCode = parseInt(inputOrders[0].get("order_code")) + 1;
    let zeroLength = 4 - intCode.toString().length;
    code = `${"0".repeat(zeroLength)}${intCode}-${currentYear.toString()}`;
  }

  return code;
};

const getDeliveries = async () => {
  const deliveries = await findWhere("InputProvider", "provider_group", [
    "71xnAN2mKl",
  ]);
  return deliveries.map((el) => {
    return {
      label: `${el.get("code")} - ${el.get("name")}`,
      value: el.id,
      data: el,
    };
  });
};

const calculateInputOrderTotal = ({ inputs }) => {
  return (inputs || []).reduce((prev, current) => {
    const unitPrice =
      current.total && isNaN(current.total)
        ? moneyToNumber(current.total)
        : current.total;
    return prev + (unitPrice || 0);
  }, 0);
};

const calculateInputOrderIPI = ({ inputs, discount, total }) => {
  const unitDiscountPercent = discount / total;
  return (inputs || []).reduce((prev, current) => {
    let unitIPI =
      ((current.ipi || 0) / 100) *
      (current.total * (1 - unitDiscountPercent) || 0);
    return prev + unitIPI;
  }, 0);
};

export {
  generateCode,
  getDeliveries,
  calculateInputOrderTotal,
  calculateInputOrderIPI,
};
