import React, { Component } from 'react';
import { Card, Button, Radio, Divider, message, Modal, Form, Input } from 'antd';
import { surveyQuestionsAndAnswers } from './suport-files/survey-questions';

export default class CustomerQualificationSurvey extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            disabledFields: false,
            proposalName: null,

            work: null,
            email: null,
            form: {},

            surveyId: null,
        }
    }

    componentWillMount = async () => {
        let form = {}
        surveyQuestionsAndAnswers.forEach(survey => {
            form[survey.question.key] = null
        })
        this.setState({ form })
    }

    downloadFormDoc = async () => {

        let serverUrl = process.env.REACT_APP_SERVERURL.split('/');
        serverUrl.pop();
        serverUrl = serverUrl.join('/');

        if (this.state.disabledFields) {
            window.location.href = `http://minhamultidoor.com.br/solicitacao-proposta-cliente?surveyId=${this.state.surveyId}`;
        } else {
            const response = await fetch(`${serverUrl}/api/qualification-survey`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        work: this.state.work,
                        email: this.state.email,
                        form: this.state.form,
                    })
                });
            const responseJson = await response.json();

            if (responseJson.code !== 200) message.error('Erro ao salvar a pesquisa, tente novamente!');
            else {
                this.setState({ disabledFields: true, surveyId: responseJson.data.surveyId });
                window.location.href = `http://minhamultidoor.com.br/solicitacao-proposta-cliente?surveyId=${responseJson.data.surveyId}`
            }
        }
    }


    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            <div className="app-login" style={{ padding: '5vh', position: 'relative', height: '100vh' }}>

                <div style={{ maxHeight: '100%' }}>

                    <Card style={{ width: '100%', maxWidth: '1000px', margin: 'auto', marginBottom: '50px', height: '90vh', overflowY: 'auto' }} id='checklist-card'>

                        <h3>Responda as perguntas abaixo antes de preencher o seu cadastro para atendimento:</h3>
                        Multidoor 4.0
                        <Divider />

                        <div>

                            <div>
                                <Form.Item label="E-mail">
                                    <Input
                                        placeholder='Informe seu e-mail'
                                        disabled={this.state.disabledFields}
                                        value={this.state.email}
                                        onChange={e => this.setState({ email: e.target.value })}
                                    />
                                </Form.Item>

                                <Form.Item label="Obra">
                                    <Input
                                        placeholder='Informe o nome da obra'
                                        disabled={this.state.disabledFields}
                                        value={this.state.work}
                                        onChange={e => this.setState({ work: e.target.value })}
                                    />
                                </Form.Item>
                            </div>

                            <Divider />

                            {
                                surveyQuestionsAndAnswers.map(survey => {
                                    return (
                                        <div>
                                            <h3>{survey.question.label}</h3>
                                            <Radio.Group
                                                disabled={this.state.disabledFields}
                                                onChange={(e) => this.setState({ form: { ...this.state.form, [survey.question.key]: e.target.value } })}
                                                value={this.state.form && this.state.form[survey.question.key]}
                                            >
                                                {
                                                    survey.answers
                                                        .map(el => el.label)
                                                        .map(el => {
                                                            return <Radio style={radioStyle} value={el}>{el}</Radio>
                                                        })
                                                }
                                            </Radio.Group>

                                            <Divider />
                                        </div>
                                    )
                                })
                            }

                            {this.state.disabledFields ?
                                <Button
                                    type='primary'
                                    disabled={
                                        Object.values(this.state.form).includes(null) ||
                                        !this.state.work || !this.state.email
                                    }
                                    onClick={() => this.downloadFormDoc()}
                                >
                                    Acessar cadastro para atendimento
                                </Button> :
                                <Button
                                    type='primary'
                                    disabled={
                                        Object.values(this.state.form).includes(null) ||
                                        !this.state.work || !this.state.email
                                    }
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Obrigado!',
                                            content: 'Agradecemos pela resposta, agora acesse o cadastro para atendimento para concluir a sua solicitação de orçamento!',
                                            cancelText: 'Cancelar',
                                            okText: 'Acessar cadastro',
                                            onOk: () => this.downloadFormDoc(),
                                            iconType: 'like',
                                        })
                                    }}
                                >
                                    Acessar cadastro para atendimento
                                </Button>
                            }
                        </div>

                    </Card>

                </div>

            </div>
        )
    }

}