import { Button } from 'antd'
import { calculateTotalCost, getComponentTotalPrice, getManHours } from '../../shared/component-mounting-instalation-functions'
import React, { Component } from 'react'
import { findAll, findWhere } from '../../../../utils/db_functions'
import { getComparative } from '../suport-files/comparative-table-functions'
import './comparative-table.css'
import { moneyToNumber } from '../../../../utils/general_functions'

export default class ComparativeTableComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loadingTitle: null,
            comparativeTables: [],
            comparativeTablesFilled: [],
            finishings: [],

            sheetClass: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6'],
            components: [
                { label: 'Kit porta', value: 'kit' },
                { label: 'Folha', value: 'sheet' },
                { label: 'Marco', value: 'marco' },
                { label: 'Montagem', value: 'mounting' },
                { label: 'Instalação', value: 'instalation' },
            ],
            subComponents: [
                { label: 'Insumos', value: 'inputs', },
                { label: 'Mão de Obra', value: 'manHour' },
                { label: 'HH', value: 'size_hh' },
                { label: 'Margem (%)', value: 'margin_contri' },
            ],
        }
    }

    componentDidMount = () => {
        this.generateTable(this.props.comparativeTables)
    }

    getFinishings = async (comparativeTables) => {
        const finishingIds = [
            ...new Set(
                comparativeTables.map(el => {
                    return [
                        el.finishing,
                        el.finishing_marco,
                    ]
                }).flat()
            )
        ]

        const finishings = await findWhere('Finishing', 'objectId', finishingIds)

        return finishings;
    }

    generateTable = async (comparativeTables) => {

        this.setState({ loading: true, loadingTitle: 'Carregando os insumos necessários para os cálculos...' })
        const inputs = await findAll('Input', 30000, null, null, 'code')
        const manHours = await getManHours()

        this.setState({ loading: true, loadingTitle: 'Carregando tabelas...' })
        const finishings = await this.getFinishings(comparativeTables)

        let kitIds = []
        let sheetIds = []
        let marcoIds = []
        let mountingIds = []
        let instalationIds = []

        let comparativeTablesFilled = []

        for (let comparativeTableIndex = 0; comparativeTableIndex < comparativeTables.length; comparativeTableIndex++) {
            const comparativeTable = comparativeTables[comparativeTableIndex]

            let comparativeSubTablesFilled = []
            for (let sheetClassIndex = 0; sheetClassIndex < this.state.sheetClass.length; sheetClassIndex++) {
                const sheetClass = this.state.sheetClass[sheetClassIndex]
                const componentsToPush = await getComparative(comparativeTable, sheetClass)
                comparativeSubTablesFilled.push(componentsToPush)

                kitIds.push(componentsToPush && componentsToPush.kit)
                sheetIds.push(componentsToPush && componentsToPush.sheet)
                marcoIds.push(componentsToPush && componentsToPush.marco)
                mountingIds.push(componentsToPush && componentsToPush.mounting)
                instalationIds.push(componentsToPush && componentsToPush.instalation)
            }

            comparativeTablesFilled.push(comparativeSubTablesFilled)
        }

        const kits = await this.getComponents(kitIds, 'Kit')
        const components = await this.getComponents([...sheetIds, ...marcoIds], 'ComponenteKit')
        const mountings = await this.getComponents(mountingIds, 'MountingKit')
        const instalations = await this.getComponents(instalationIds, 'InstalationKit')

        for (let comparativeTableFilledIndex = 0; comparativeTableFilledIndex < comparativeTablesFilled.length; comparativeTableFilledIndex++) {
            const comparativeSubTablesFilled = comparativeTablesFilled[comparativeTableFilledIndex];

            for (let comparativeSubTableIndex = 0; comparativeSubTableIndex < comparativeSubTablesFilled.length; comparativeSubTableIndex++) {
                const comparativeSubTableFilled = comparativeSubTablesFilled[comparativeSubTableIndex];

                let kit = kits.find(el => el.id === (comparativeSubTableFilled && comparativeSubTableFilled.kit))

                this.setState({ loadingTitle: `Tabela comparativa ${comparativeTableFilledIndex + 1} - calculando componentes...` })

                const sheet = await this.getComponentPriceAndAttr(components, comparativeSubTableFilled && comparativeSubTableFilled.sheet, inputs, 'ComponenteKit', manHours)
                const marco = await this.getComponentPriceAndAttr(components, comparativeSubTableFilled && comparativeSubTableFilled.marco, inputs, 'ComponenteKit', manHours)
                const mounting = await this.getComponentPriceAndAttr(mountings, comparativeSubTableFilled && comparativeSubTableFilled.mounting, inputs, 'MountingKit', manHours)
                const instalation = await this.getComponentPriceAndAttr(instalations, comparativeSubTableFilled && comparativeSubTableFilled.instalation, inputs, 'InstalationKit', manHours)

                const attrs = ['totalPrice', ...this.state.subComponents.map(el => el.value)]
                for (let attrIndex = 0; attrIndex < attrs.length; attrIndex++) {
                    const attr = attrs[attrIndex];
                    const sumComponentsInfo = this.sumComponentsInfoToSetInKit(attr, [sheet, marco, mounting, instalation]) || ''
                    if (kit && sumComponentsInfo) await kit.set(attr, sumComponentsInfo)
                }

                comparativeTablesFilled[comparativeTableFilledIndex][comparativeSubTableIndex] = { kit, sheet, marco, mounting, instalation }
            }
        }

        this.setState({
            comparativeTablesFilled,
            comparativeTables,
            finishings,
            loading: false,
            loadingTitle: null,
        })
    }

    getComponents = async (ids, module) => {
        const components = await findWhere(module, 'objectId', [...new Set(ids.filter(el => el))])
        return components
    }

    getComponentPriceAndAttr = async (componentsArray, id, inputs, module, manHours) => {
        const component = componentsArray.find(el => el.id === id)

        if (component) {
            const totalPrice = await getComponentTotalPrice(component.id, inputs, null, null, module) // TODO: inserir formulas como parametro
            await component.set('totalPrice', (totalPrice || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))

            await component.set('inputs', calculateTotalCost(component.get('descriptions'), inputs)
                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) // TODO: inserir formulas como parametro

            const manHour = manHours.find((manHour) => manHour.id === component.get('unity_value_hh').id)
            await component.set('manHour',
                (((manHour && manHour.get('calculatedTotal')) || 0) * component.get('size_hh')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            )
        }

        return component
    }

    sumComponentsInfoToSetInKit = (attr, components) => {
        let countToCalculateMarginMedia = 0

        const sum = components.reduce((prev, current) => {
            const currentValue = !current ? 0 :
                (
                    typeof current.get(attr) === 'string' ?
                        moneyToNumber(current.get(attr))
                        :
                        current.get(attr)
                )

            countToCalculateMarginMedia += (currentValue ? 1 : 0)
            return prev += (currentValue || 0)
        }, 0)

        if (['totalPrice', 'inputs', 'manHour'].includes(attr))
            return sum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

        if (attr === 'margin_contri') return sum / (countToCalculateMarginMedia || 1)

        return sum
    }

    getDataSourceRow = (componentDescription) => {
        return [
            { label: componentDescription.label, value: componentDescription.value },
            ...this.state.subComponents,
        ]
    }

    renderTable = (comparativeTable, comparativeTableIndex) => {

        const comparativeTableFields = [
            { label: 'Folha', value: 'sheet' },
            { label: 'Largura', value: 'width' },
            { label: 'Altura', value: 'height' },
            { label: 'Acabamento', value: 'finishing' },
            { label: 'Marco', value: 'finishing_marco' },
            { label: 'Tipologia', value: 'typology' },
            // { label: 'Fechadura', value: 'door_handle' },
        ]

        return (
            <div style={{ marginBottom: '100px' }}>
                <table className='comparative-table'>
                    {
                        comparativeTableFields
                            .map(el => {
                                return <th>{el.label}</th>
                            })
                    }
                    <tr>
                        {
                            comparativeTableFields
                                .map(el => {
                                    if (el.value.includes('finishing')) {
                                        const finishing = this.state.finishings.find(finishing => finishing.id === comparativeTable[el.value])
                                        return <td>{finishing && finishing.get('code')}</td>
                                    }
                                    return <td>{comparativeTable[el.value]}</td>
                                })
                        }
                    </tr>
                </table>

                <br />

                <table className='comparative-table'>
                    <tr>
                        <th>Descrição</th>
                        {
                            this.state.sheetClass
                                .map(el => {
                                    return <th>{el}</th>
                                })
                        }
                    </tr>

                    {
                        this.state.components
                            .map((rowGroup, rowGroupIndex) => {
                                return this.getDataSourceRow(rowGroup).map((data, dataIndex) => {
                                    return (
                                        <tr
                                            style={{
                                                backgroundColor: rowGroupIndex % 2 !== 0 ? '#f2f2f2' : '',
                                                fontWeight: dataIndex === 0 ? 'bold' : 'normal'
                                            }}
                                        >
                                            {
                                                [data].concat(this.state.comparativeTablesFilled[comparativeTableIndex])
                                                    .map((row, rowIndex) => {
                                                        const param = this.state.subComponents.map(el => el.value).includes(data.value) ? data.value : 'totalPrice'

                                                        const dataToShow = (
                                                            (row && row.label) ||
                                                            (row[rowGroup.value] && row[rowGroup.value].get(param))
                                                        )
                                                        return <td style={{ width: rowIndex === 0 ? '20%' : '13%' }}>{dataToShow}</td>
                                                    })
                                            }
                                        </tr>
                                    )
                                })
                            })
                    }
                </table>
            </div>
        )
    }

    render() {

        return (
            <div style={{ textAlign: 'center' }}>
                <Button
                    size='large'
                    type='primary'
                    loading={this.state.loading}
                    onClick={() => {
                        this.generateTable(this.props.comparativeTables)
                    }}
                >
                    {this.state.loadingTitle || 'Gerar/Atualizar tabela'}
                </Button>

                <br /><br />

                {
                    (this.state.comparativeTables || []).map((comparativeTable, comparativeTableIndex) => {
                        return this.renderTable(comparativeTable, comparativeTableIndex)
                    })
                }
            </div>
        )
    }

}