import { Button, Checkbox, Col, Divider, Form, InputNumber, message, Row, Spin, Select } from 'antd'
import React, { Component } from 'react'
import { findAll, findById, findWhere, getCurrentUser } from '../../../../../utils/db_functions'
import { calculateTotals } from '../../suport-files/proposal-functions'

export default class TotalStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            auth: this.props.auth,
            loadingMsg: null,
            totalPrice: 0,
            totalVolume: 0,
            totalWeight: 0,
            totalQtd: 0,
            dataConfigs: {},
            regionsData: [],
            instalationData: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const user = await getCurrentUser()
        this.setState({ loadingMsg: 'Calculando preço total...', user })

        const productBlocks = this.props.getValue('product_blocks')
        const { totalPrice, totalVolume, totalWeight, totalQtd } = await calculateTotals(productBlocks, this.props.inputs, this.props.formulas, null, (this.props.getValue('generalInfo') && this.props.getValue('generalInfo')['isMultipleCotation']))

        const dataConfigsArray = await findWhere('Config', 'name', ['proposal_form_notes_totais', 'delivery'])
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {})

        const regionsData = this.props.dataConfigs['work-regionsData'] || await findAll('Region')
        const instalationData = this.props.dataConfigs['work-instalationData'] || await findAll('Instalation')

        this.setState({ loading: false, loadingMsg: null, totalPrice, totalQtd, totalVolume, totalWeight, dataConfigs, regionsData, instalationData })

        this.props.setDataConfigs({
            'work-regionsData': regionsData, 'work-instalationData': instalationData,
        })

        await this.checkAndCalculateShipping()
    }

    changeNullShippingValueToZero = (shippingType) => {
        const shippingTypeArray = ['m20', 'm30', 'm60', 'm90'].filter(el => el !== shippingType)
        shippingTypeArray.forEach(shipping => {
            if (!this.props.getValue(shipping)) this.props.updateDataValue(shipping, 0)
        })
    }

    checkAndCalculateShipping = async () => {
        const shippingTypeArray = ['m20', 'm30', 'm60', 'm90']

        let updateShipping = true
        shippingTypeArray.forEach(shipping => {
            if (this.props.getValue(shipping) || this.props.getValue(shipping) === 0) updateShipping = false
        })

        if (updateShipping) await this.calculateShipping()
    }

    calculateShipping = async () => {
        // Check if delivery is to AL, PE, SE
        const regionId = (this.props.getValue('local_work') || '-').split('-')[0]
        const localWork = await findById('Region', regionId)
        // const fator =
        //     !localWork ? 0 : ((localWork.get('name').includes('AL') || localWork.get('name').includes('PE') || localWork.get('name').includes('SE')) ? 1 : 0)

        const distance = localWork.get('distance')
        const volume = this.state.totalVolume

        let m90 = 0;
        let m60 = 0;
        let m30 = 0;
        let m20 = 0;

        if(volume < 10 && distance < 400) m20 = 1
        else if(volume < 44 && distance < 1200) m30 = 1
        else {
            m90 = Math.floor(volume / 97)
            if((volume % 97) > 58) m90 += 1
            else {
                // m60 = Math.floor((volume % 97) / 58)
                m60 = (((volume % 97) % 58) < 29 ? 0.5 : 1)
            }
        }

        this.props.updateDataValue('m20', m20)
        this.props.updateDataValue('m30', m30)
        this.props.updateDataValue('m60', m60)
        this.props.updateDataValue('m90', m90)
    }

    render() {
        return (
            <div style={{ paddingTop: '40px' }}>

                <Row gutter={24}>
                    <Col span={16}>
                        <Spin spinning={this.state.loading} size='large' tip={this.state.loadingMsg || 'Carregando as informações...'}>
                            <Row gutter={24}>
                                <Col span={24} >
                                    <h2 style={{ margin: 0 }}>Totais</h2>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label='Volume (m³)'>
                                        <div style={{ fontWeight: 'bold', fontSize: 'large' }}>{(this.state.totalVolume).toLocaleString('pt-BR')}</div>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label='Peso total (Kg)'>
                                        <div style={{ fontWeight: 'bold', fontSize: 'large' }}>{(this.state.totalWeight).toLocaleString('pt-BR')}</div>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label='Qtd de kits'>
                                        <div style={{ fontWeight: 'bold', fontSize: 'large' }}>{this.state.totalQtd}</div>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label='Cotação múltipla'>
                                        <Checkbox
                                            disabled={this.props.disabledFields}
                                            onChange={async value => {
                                                const generalInfo = {
                                                    ...this.props.getValue('generalInfo'),
                                                    isMultipleCotation: value.target.checked
                                                }
                                                await this.props.updateDataValue('generalInfo', generalInfo)
                                                this.componentDidMount()
                                            }}
                                            checked={this.props.getValue('generalInfo') && this.props.getValue('generalInfo')['isMultipleCotation']}
                                        >
                                            Cotação múltipla
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Spin>
                        <Divider />

                        <Row gutter={24}>
                            <Col span={24} >
                                <h2 style={{ marginBottom: 0 }}>Frete</h2>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Button
                                    type='primary'
                                    size='large'
                                    onClick={async () => {
                                        if (this.state.loading) {
                                            message.error('Aguarde o cálculo do preço finalizar para cálcular o frete')
                                        } else {
                                            this.calculateShipping()
                                        }
                                    }}>
                                    Calcular Frete
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label='Van (10m³)' required>
                                    <InputNumber
                                        disabled={this.props.disabledFields}
                                        placeholder="Van (10m³)"
                                        onChange={value => {
                                            this.props.updateDataValue('m20', value || 0)
                                            this.changeNullShippingValueToZero('m20')
                                        }}
                                        value={this.props.getValue("m20")}
                                        size="large"
                                        type='number'
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label='Caminhão Toco (44m³)' required>
                                    <InputNumber
                                        disabled={this.props.disabledFields}
                                        placeholder="Caminhão Toco (44m³)"
                                        onChange={value => {
                                            this.props.updateDataValue('m30', value || 0)
                                            this.changeNullShippingValueToZero('m30')
                                        }}
                                        value={this.props.getValue("m30")}
                                        size="large"
                                        type='number'
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label='Caminhão Truck (58m³)' required>
                                    <InputNumber
                                        disabled={this.props.disabledFields}
                                        placeholder="Caminhão Truck (58m³)"
                                        onChange={value => {
                                            this.props.updateDataValue('m60', value || 0)
                                            this.changeNullShippingValueToZero('m60')
                                        }}
                                        value={this.props.getValue("m60")}
                                        size="large"
                                        type='number'
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label='Carreta (97m³)' required>
                                    <InputNumber
                                        disabled={this.props.disabledFields}
                                        placeholder="Carreta (97m³)"
                                        onChange={value => {
                                            this.props.updateDataValue('m90', value || 0)
                                            this.changeNullShippingValueToZero('m90')
                                        }}
                                        value={this.props.getValue("m90")}
                                        size="large"
                                        type='number'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item>
                                    <Checkbox
                                        disabled={this.props.disabledFields || !(this.props.auth && this.props.auth['_deliveryValueRule'])}
                                        onChange={value => this.props.updateDataValue('ignoreDeliveryRule', value.target.checked)}
                                        checked={this.props.getValue("ignoreDeliveryRule")}
                                    >
                                        Desabilitar regra dos 25% do valor dos produtos
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="Entrega" required>
                                    <Select value={this.props.getValue('delivery')} onChange={value => this.props.updateDataValue('delivery', value)} placeholder="Entrega" size="large" disabled={this.props.disabledFields}>
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {(this.state.dataConfigs.delivery || []).map((delivery, index) => {
                                            return (
                                                <Select.Option key={index} value={delivery}>{delivery}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Entrega dos produtos">
                                    <Select value={this.props.getValue('delivery_products')} placeholder="Entrega dos produtos" size="large" disabled={this.props.disabledFields}
                                        onChange={value => {
                                            this.props.updateDataValue('delivery_products', value)

                                            // Selecionando automaticamente SP no local da obra em caso de redespacho ou Maceió em caso de retirada direto na fábrica
                                            if (value === 'redispatch_sp') {
                                                this.state.regionsData.forEach(region => {
                                                    if (region.get('name').includes('SP-')) {
                                                        region.get('citys').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).forEach((city, index) => {
                                                            if (city.name.toLowerCase().includes('são paulo')) this.props.updateDataValue('local_work', `${region.id}-${index}`)
                                                        })
                                                    }
                                                })
                                            } else if (value === 'free_shipping') {
                                                this.state.regionsData.forEach(region => {
                                                    if (region.get('name').includes('AL-')) {
                                                        region.get('citys').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).forEach((city, index) => {
                                                            if (city.name.toLowerCase().includes('maceió')) this.props.updateDataValue('local_work', `${region.id}-${index}`)
                                                        })
                                                    }
                                                })
                                            } else this.props.updateDataValue('local_work', null)
                                        }}
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        <Select.Option value='in_work'>Entrega na obra</Select.Option>
                                        <Select.Option value='redispatch_sp'>Redespacho</Select.Option>
                                        <Select.Option value='free_shipping'>Retira fábrica</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={this.props.getValue('delivery_products') === 'redispatch_sp' ? "Redespacho" : this.props.getValue('delivery_products') === 'free_shipping' ? "Retirada na fábrica" : "Local da obra"}
                                    required
                                >
                                    <Select
                                        value={this.props.getValue('local_work')}
                                        onChange={value => this.props.updateDataValue('local_work', value)}
                                        placeholder="Local da obra"
                                        size="large"
                                        disabled={this.props.disabledFields || this.props.getValue('delivery_products') === 'free_shipping'}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Select.Option value={null}>Selecione...</Select.Option>
                                        {(this.state.regionsData || [])
                                            // Filtrar as regiões do select de acordo com a instalação escolhida
                                            .filter(v => {
                                                let instalation = (this.state.instalationData || []).find(val => val.id === this.props.getValue('instalation'))
                                                if (instalation) {
                                                    if (
                                                        instalation.get('name') === 'Multidoor Service' &&
                                                        (v.get('name').includes('AL-') || v.get('name').includes('PE-') || v.get('name').includes('SE-') || v.get('name').includes('PB-')) &&
                                                        parseInt(v.get('distance'), 10) <= 400
                                                    ) return v

                                                    if (instalation.get('name') !== 'Multidoor Service') return v
                                                }

                                                if (this.props.getValue('delivery_products') === 'redispatch_sp') return v

                                                return null
                                            })
                                            .map((ParseRegion, regIndex) => {
                                                return (
                                                    <Select.OptGroup key={regIndex} label={ParseRegion.get('name')}>
                                                        {
                                                            (ParseRegion.get('citys') || []).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((city, index) => <Select.Option key={regIndex + '-' + index} value={ParseRegion.id + '-' + index}> {city.name} </Select.Option>)
                                                        }
                                                    </Select.OptGroup>
                                                )
                                            })}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Col>

                    <Col span={8}>
                        <div className='note-box'>
                            <div className='note-title'>Instruções:</div>
                            <div>{
                                ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_totais) || [])
                                    .map(paragraph => <p>{paragraph}</p>)
                            }</div>
                        </div>
                    </Col>
                </Row>
            </div >
        )
    }

}