import React from 'react'
import { Icon } from 'antd'
export default {
  "fields": [
    [
      {
        "title": "Nome",
        "key": "name",
        "type": "text",
        "element-attr": { "required": true, "prefix": <Icon type="tags" />}
      },
      {
        "title": "Valor",
        "key": "value",
        "type": "number",
        "element-attr": { "required": false, "prefix": <Icon type="tags" />, min: 0, max: 100}
      }
    ]
  ],
  "submit": {
    "collection": "MobilityWork"
  },
  "className": "config-component",
  "schema": [
    {
      "title": "Nome",
      "key": "name",
      "dataIndex": "name",
      "type": "text",
      className: "name"
    },
    {
      title: "Valor",
      key: 'value',
      dataIndex: 'value',
      type: "render",
      render: r => `${r}%`
    }
  ],
  "title-navigation": <div><Icon type="hdd" /> Mobilidade da Obra</div>,
  "title-module": "Mobilidade da Obra",
  "module": "MobilityWork",
  "router-base": "/panel/mobilidade-obra"
}

