import { Modal } from "antd";
import React, { Component } from "react";
import { findById, save } from "../../../../utils/db_functions";

export default class TechDocsStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      techDocsStatus: this.props.techDocsStatus,
      color: "red",
    };
  }

  componentDidMount = () => {
    let color = this.props.techDocsStatus ? "green" : "red";
    if (color !== this.state.color) this.setState({ color });
  };

  componentWillReceiveProps = (nextProps) => {
    let color = nextProps.techDocsStatus ? "green" : "red";
    if (color !== this.state.color) this.setState({ color });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: this.state.color,
          width: "20px",
          height: "20px",
          margin: "auto",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={() => {
          Modal.confirm({
            title: "Deseja atualizar o status?",
            content: "O status desse documento será atualizado.",
            cancelText: "Cancelar",
            okText: "Atualizar",
            onOk: async () => {
              const orderToUpdate = await findById("Order", this.props.orderId);
              let techDocsStatus = orderToUpdate.get("techDocsStatus") || {};
              techDocsStatus[this.props.techDocType] = techDocsStatus[
                this.props.techDocType
              ]
                ? !techDocsStatus[this.props.techDocType]
                : true;
              await save("Order", { techDocsStatus }, orderToUpdate.id);

              const color = techDocsStatus[this.props.techDocType]
                ? "green"
                : "red";
              this.setState({ color });
            },
          });
        }}
      />
    );
  }
}
