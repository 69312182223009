import React from "react";

const inputsRelationFields = (props) => {

    return [
        [
            {
                type: 'dynamic-input-list',
                key: 'inputs',
                fields: [
                    [
                        {
                            title: "Insumo",
                            key: 'input',
                            type: "select",
                            options: props.inputs,
                            colSize: 10,
                            "element-attr": {
                                required: false,
                                beforeChangeValue: (node, value) => {
                                    const inputs = node.options
                                    const inputData = (inputs || []).find(el => el.data.objectId === value.input);
                                    return {
                                        ...value,
                                        measure: inputData && inputData.data.measure,
                                        unit_value: inputData && inputData.data.material,
                                        ipi: inputData && inputData.data.ipi,
                                        total: ((inputData && inputData.data.material) || 0) * (value.qtd || 0),
                                    }
                                }
                            },
                        },
                        {
                            title: 'Unidade',
                            key: 'measure',
                            colSize: 2,
                            type: 'render',
                            "element-attr": { required: false },
                            render: (_, module, node, value) => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>{value && value.measure}</div>
                        },
                        {
                            title: 'Vl Unit.',
                            key: 'unit_value',
                            step: 0.01,
                            colSize: 4,
                            type: "money",
                            "element-attr": {
                                required: false,
                                beforeChangeValue: (node, value) => {
                                    return {
                                        ...value,
                                        total: (value.unit_value || 0) * (value.qtd || 0),
                                    }
                                }
                            },
                        },
                        {
                            type: "number", title: 'Quant.', key: 'qtd', step: '0.001', colSize: 2,
                            "element-attr": {
                                required: false,
                                beforeChangeValue: (node, value) => {
                                    return {
                                        ...value,
                                        total: (value.unit_value || 0) * (value.qtd || 0),
                                    }
                                }
                            },
                        },
                        {
                            title: 'IPI',
                            key: 'ipi',
                            step: 0.01,
                            colSize: 2,
                            type: 'number',
                            "element-attr": { required: false },
                        },
                        {
                            title: 'Subtotal (insumo + frete)',
                            key: 'total',
                            colSize: 4,
                            type: "render",
                            "element-attr": { required: false },
                            render: (_, module, node, value) => <div style={{ fontWeight: 'bold', lineHeight: '40px' }}>{((value && value.total) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                        },
                    ]
                ],
            }
        ]
    ]
}

export default inputsRelationFields