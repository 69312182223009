import { Button, Input, Modal, Spin, Table } from 'antd'
import React, { Component } from 'react'
import { findWhereMultiple } from '../../../../utils/db_functions'

export default class OrderSelectModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            loading: false,
            orders: [],
        }
    }

    searchOrders = async (term) => {
        this.setState({ loading: true })

        const ordersFound = await findWhereMultiple(
            'Order',
            [
                { 'field': 'order_code', 'value': term, 'op': 'like', logicOp: '$or' },
            ],
            ['proposalRef'], null, null, 'order_code'
        )

        this.setState({
            orders: ordersFound.map((order) => order.toJSON()),
            loading: false
        })
    }

    render() {
        const columns = [
            {
                title: 'Código',
                dataIndex: 'order_code',
                width: '150px',
                key: 'code',
                render: (order_code, order) => {
                    return (
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.addOrder(order)
                                this.setState({ modal: false })
                            }}
                        >
                            {order_code}
                        </div>
                    )
                }
            },
            {
                title: 'Cliente',
                dataIndex: 'customer',
                key: 'customer',
                render: (v, order) => {
                    return (
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.addOrder(order)
                                this.setState({ modal: false })
                            }}
                        >
                            {order.proposalRef && order.proposalRef.name_client}
                        </div>
                    )
                }
            },
            {
                title: 'Obra',
                dataIndex: 'work',
                key: 'work',
                render: (v, order) => {
                    return (
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.addOrder(order)
                                this.setState({ modal: false })
                            }}
                        >
                            {order.proposalRef && order.proposalRef.name_work}
                        </div>
                    )
                }
            },
        ];

        return (
            <div>
                <Button
                    type='primary'
                    icon='plus'
                    onClick={() => this.setState({ modal: true })}
                    style={{ marginTop: '20px' }}
                >Adicionar pedido</Button>

                <Modal
                    visible={this.state.modal}
                    title='Escolher pedido'
                    footer={[]}
                    width={800}
                    onCancel={() => this.setState({ modal: false })}
                >

                    <Spin spinning={this.state.loading} tip='Carregando pedidos'>

                        <Input.Search
                            style={{ marginBottom: '20px' }}
                            placeholder='Código do pedido'
                            onSearch={e => this.searchOrders(e)}
                        />

                        <Table dataSource={this.state.orders} columns={columns} locale={{ emptyText: 'Nenhum pedido encontrado' }} />
                    </Spin>
                </Modal>
            </div>
        )
    }

}