import { Button, Col, Form, Modal, notification, Row, Select } from 'antd'
import React, { Component } from 'react'
import { findAll, findWhere, save } from '../../../../utils/db_functions'

export default class UpdateInputsModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleModal: false,
            inputProviders: [],
            inputProvidersChoosed: [],
            loadingButton: false,
        }
    }

    componentDidMount = async () => {
        const inputProviders = await findAll('InputProvider', 500, null, null, 'code')
        this.setState({
            inputProviders: inputProviders.map((inputProvider) => {
                return {
                    label: `${inputProvider.get('code')} - ${inputProvider.get('name')}`,
                    value: inputProvider.get('name'),
                }
            })
        })
    }

    executeUpdate = async (updateAllProviders) => {
        this.setState({ loadingButton: true })

        const fieldsToUpdate = [
            'credit_tax_material', 'credit_tax_shipment', 'ipi', 'loss', 'material', 'measure',
            'pis_c', 'pis_icms', 'shipment_rate', 'convert', 'consumption_measure'
        ]

        const inputs = updateAllProviders ?
            await findAll('Input', 10000) :
            await findWhere('Input', 'provider', [this.state.inputProvidersChoosed], null, null, null, null, 1000)

        for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
            const input = inputs[inputIndex]

            // First, lets check if price is updated
            let priceIsUpdated = true
            fieldsToUpdate.forEach(el => {
                if (input.get(el) !== (input.get('updated_data') && input.get('updated_data')[0][el])) priceIsUpdated = false
            })

            // Now, lets updated if price is not updated
            if (!priceIsUpdated) {
                let updatedData = input.get('updated_data');
                fieldsToUpdate.forEach(el => {
                    if (!updatedData) updatedData = [{}]
                    updatedData[0][el] = input.get(el)
                })

                let inputToUpdateObject = {}
                inputToUpdateObject['updated_data'] = updatedData

                await save('Input', inputToUpdateObject, input.id)
            }
        }

        this.setState({ loadingButton: false })

        this.setState({ visibleModal: false })
        notification['success']({
            message: 'Atualização realizada com sucesso',
            description: 'Os insumos foram atualizados'
        })
    }

    render() {
        return (
            <div style={{ float: 'left', marginRight: '10px' }}>
                <Button onClick={() => this.setState({ visibleModal: true })}>
                    Atualizar insumos
                </Button>

                <Modal
                    title='Atualização de insumos'
                    visible={this.state.visibleModal}
                    width='800px'
                    onCancel={() => this.setState({ visibleModal: false })}
                    footer={[
                        <Button
                            loading={this.state.loadingButton}
                            onClick={() => {
                                const _this = this
                                Modal.confirm({
                                    title: 'Deseja realmente atualizar?',
                                    content: 'Verifique todos os campos antes de realizar essa ação, pois ela não poderá ser desfeita',
                                    onOk() {
                                        _this.executeUpdate(true)
                                    },
                                })
                            }}
                        >Atualizar todos</Button>,
                        <Button
                            type='primary'
                            loading={this.state.loadingButton}
                            onClick={() => {
                                const _this = this
                                Modal.confirm({
                                    title: 'Deseja realmente atualizar?',
                                    content: 'Verifique todos os campos antes de realizar essa ação, pois ela não poderá ser desfeita',
                                    onOk() {
                                        _this.executeUpdate(false)
                                    },
                                })
                            }}
                        >Atualizar selecionados</Button>,
                        <Button onClick={() => this.setState({ visibleModal: false })}>Cancelar</Button>
                    ]}
                >

                    <Row gutter={24}>

                        <Col span={24}>
                            <Form.Item label='Provedores'>
                                <Select
                                    value={this.state.inputProvidersChoosed}
                                    onChange={e => this.setState({ inputProvidersChoosed: e })}
                                    style={{ width: '100%' }}
                                    size='large'
                                    mode='multiple'
                                >
                                    {this.state.inputProviders.map(inputProvider => {
                                        return <Select.Option value={inputProvider.value}>{inputProvider.label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                </Modal>
            </div>
        )
    }

}