import React from 'react'
import { Select } from 'antd'

const generalInfoFields = (props) => {

    return [
        [
            {
                type: "text", title: 'Código', key: 'code', colSize: 8, max: 20,
                valid: { check: (form) => (form.getNodeValue('product_code') || '').length <= 20 },
                "element-attr": { "required": false },
            },
            { type: "text", title: 'Descrição do Componente', key: 'description', colSize: 16, "element-attr": { "required": false }, },
        ],
        [
            {
                type: 'select',
                key: 'typology',
                title: 'Tipologia',
                options: [],
                optionConfig: { name: 'typology', arrayWithId: true },
                loaded: false,
                "element-attr": { mode: 'default', required: true },
                colSize: 8,
                render: (options) => {
                    let opt = options.reduce((acc, v) => {
                        let type = v.label.split(' ')[0]
                        if (!acc[type]) {
                            acc[type] = {
                                children: []
                            }
                        }
                        acc[type].children.push(v)
                        return acc;

                    }, {});

                    let types = window.Object.keys(opt)
                    return types.map((type, index) => {
                        return (
                            <Select.OptGroup label={types[index]} key={`group-${index}`}>
                                {opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
                            </Select.OptGroup>
                        )
                    })
                }
            },
            { type: "number", title: 'Peso', key: 'weight', colSize: 2, "element-attr": { required: false }, },
            {
                title: "Aplicação",
                key: "aplication",
                type: "select",
                colSize: 7,
                options: [],
                optionConfig: { name: 'performance' },
                "element-attr": { required: true, mode: 'multiple' }
            },
            { type: "number", title: 'Altura', key: 'height', colSize: 3, "element-attr": { required: true }, },
            { type: 'tag-list', key: 'width', title: 'Larguras', colSize: 4, 'element-attr': { type: 'number' } },
        ],
    ]
}

export default generalInfoFields