import React from 'react'
import { Icon, notification } from 'antd'
import Parse from 'parse'
export default {
	"fields": [
		[
			{
				"title": "Número",
				"key": "code",
				"type": "text",
				colSize: 4,
				"element-attr": {
					required: false,
					disabled: true,
				}
			},
			{
				"title": "Produtos",
				"key": "products",
				"type": "text",
				colSize: 20,
				"element-attr": {
					required: true,
				}
			},
		]
	],
	submit: {
		collection: "InputProviderGroup"
	},
	SubmitForm: (FormRef, ObjectEdit, ObjectId, FormModule) => {
		let objectWithValues = parseDataToSave(FormRef, FormModule)
		let refs = FormModule.getRefWithFormItem(false)
		let invalid = false;
		for (let key in refs) {
			// checking if exist empty required inputs.
			if (!FormModule.nodeIsValid(FormModule.refs[key].props.node) || (!FormModule.nodeHasEmptyValue(FormModule.refs[key].props.value || FormModule.state.formRef[key]) && FormModule.nodeIsRequired(FormModule.refs[key].props.node))) {
				notification.error({ message: 'Erro ao salvar/editar', description: FormModule.refs[key].props.node.errorMessage || `Valor inválido no campo: ${FormModule.refs[key].props.node.title}`, duration: 5 })
				invalid = true;
				break;
			}
		}

		let data = objectWithValues
		if (invalid) return false;
		if (ObjectEdit) return update(objectWithValues, ObjectId, FormModule)

		let Extension = new Parse.Object(FormModule.props.module.form.module)
		data['updatedBy'] = Parse.User.current()
		data['createdBy'] = Parse.User.current()

		generateCode(FormModule).then(res => {
			data['code'] = res
			Extension.save(data).then(saved => {
				FormModule.afterSave()
			}).catch(err => {
				notification.error({
					message: 'Erro ao gerar o código do grupo',
					description: err.message
				})
			})
		})
	},
	// className: "input-module",
	schema: [
		{
			title: "Número",
			key: "code",
			dataIndex: "code",
			width: '150px',
			type: "text",
		},
		{
			title: "Produtos",
			key: "products",
			dataIndex: "products",
			type: "text",
		},
	],
	"title-navigation": <div><Icon type="cluster" /> Grupo de Provedores</div>,
	"title-module": "Grupo de Provedores",
	"module": "InputProviderGroup",
	"router-base": "/panel/grupo-provedores"
}

const parseDataToSave = (_, FormModule) => {
	// get ParseObject of vinculated_component
	let refs = FormModule.getRefWithFormItem(false)
	return Object.keys(getValues(FormModule)).filter(v => {
		return (v.match(/[0-9]|_slider|_input/g)) ? false : v;
	}).reduce((a, k) => {
		let value = refs[k].props.value
		return { ...a, [k]: value }
	}, {})
}

const getValues = (FormModule) => {
	let refs = FormModule.getRefWithFormItem(false)
	let obj = {}
	for (let item in refs) {
		let value = refs[item].props.value
		obj[item] = value;
	}
	return obj;
}

const update = (data, objectId, FormModule) => {
	let Query = new Parse.Query(FormModule.props.module.form.module)
	Query.get(objectId).then(ObjectParse => {
		ObjectParse.set('updatedBy', Parse.User.current())
		ObjectParse.save(data, {}).then(r => {
			FormModule.finishEdit()
		}).catch(err => {
			notification.error({
				message: 'Erro ao atualizar',
				description: err.message
			})
		})
	})
}

async function generateCode(FormModule) {
	const _query = new Parse.Query(FormModule.props.module.form.module).limit(1000)

	// Listar as propostas do ano corrente
	let code = await _query.find().then(res => {
		if (res.length) {
			res = res.sort((a, b) => (a.get('code') > b.get('code')) ? 1 : ((b.get('code') > a.get('code')) ? -1 : 0))
			let intCode = parseInt(res[res.length - 1].get('code')) + 1
			return `${intCode < 10 ? '0' : ''}${intCode}`
		} else return '01'
	})
	return code
}