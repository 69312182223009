import React from "react";
import { findById } from "../../../../utils/db_functions";
import { generateCotationCode } from "./input-order-price-quote-functions";

const generalInfoFields = (props) => {

    return [
        [
            {
                key: 'cotation_code',
                type: 'render',
                colSize: 12,
                "render": (_, Form, node) => {
                    return (
                        <div style={{ fontSize: 'larger' }}>
                            <b>
                                Cotação: {Form.state.formRef.cotation_code || ''}
                            </b>
                        </div>
                    )
                },
                'element-attr': { required: false, },
                beforeSave: async (_, parseObject) => {
                    let code = await generateCotationCode();

                    if (!parseObject.get('cotation_code'))
                        await parseObject.set('cotation_code', code);

                    return true;
                }
            },
        ],
        [
            {
                "title": "Fornecedor",
                "key": "provider",
                "type": "pointer",
                colSize: 6,
                "options": props.providers.map(provider => {
                    return {
                        label: `${provider.get('code')} - ${provider.get('name')}`,
                        value: provider.id,
                    }
                }),
                "relation-select": {
                    "class": "InputProvider",
                    "label": (obj) => `${obj.code} - ${obj.name}`,
                    "value": "objectId",
                    "key": "objectId",
                },
                "element-attr": {
                    "required": true, 'mode': 'default',
                    afterChange: async (_, node, value) => {
                        const inputs = await props.getInputsFromProvider(value)
                        _.props.module.form.formTabs[1].fields[0][0].fields[0][0].options = inputs
                        _.forceUpdate();
                    }
                },
            },
            {
                "title": "Prazo de pagamento",
                "key": "payment_conditions",
                "type": "select",
                colSize: 4,
                options: [],
                optionConfig: { name: 'pedido_compra_prazo_pgto' },
                "element-attr": { required: false, 'mode': 'default' }
            },
            {
                "title": "Observação",
                "key": "obs",
                "type": "text",
                colSize: 14,
                "element-attr": { required: false }
            },
        ],
        [
            {
                "title": "Responsável pelo frete",
                "key": "delivery_responsible",
                colSize: 6,
                "type": "select",
                "options": [
                    { label: 'Selecione...', value: null },
                    { label: 'Comprador - Multidoor', value: 'Comprador - Multidoor' },
                    { label: 'Fornecedor - FOB SP incluso', value: 'Fornecedor - FOB SP incluso' },
                    { label: 'Fornecedor - FOB SP destaque', value: 'Fornecedor - FOB SP destaque' },
                    { label: 'Fornecedor - CIF AL incluso', value: 'Fornecedor - CIF AL incluso' },
                    { label: 'Fornecedor - CIF AL destaque', value: 'Fornecedor - CIF AL destaque' }
                ],
                "element-attr": {
                    required: false, 'mode': 'default',
                }
            },
            {
                "title": "Transportadora",
                "key": "delivery",
                "type": "pointer",
                colSize: 6,
                "options": props.deliveries,
                "relation-select": {
                    "class": "InputProvider",
                    "label": (obj) => `${obj.code} - ${obj.name}`,
                    "orderBy": "code",
                    "value": "objectId",
                    "key": "objectId",
                    where: { provider_group: '71xnAN2mKl' }
                },
                _ignoreCache: true,
                "element-attr": {
                    "required": false, 'mode': 'default',
                    afterChange: async (_, node, value) => {
                        const delivery = await findById('InputProvider', value);

                        if (delivery.get('func_logistics_name') && delivery.get('func_logistics_phone'))
                            _.state.formRef.delivery_phone = `${delivery.get('func_logistics_name')} - ${delivery.get('func_logistics_phone')}`;
                        _.forceUpdate();
                    }
                }
            },
            {
                "title": "Tipo de transporte",
                "key": "delivery_type",
                "type": "select",
                colSize: 6,
                "options": [
                    { label: 'Selecione...', value: null },
                    { label: 'Rodoviário', value: 'Rodoviário' },
                    { label: 'Maritimo - porta a porta', value: 'Maritimo - porta a porta' },
                    { label: 'Maritimo - porto Suape-PE', value: 'Maritimo - porto Suape-PE' },
                    { label: 'Aéreo', value: 'Aéreo' },
                    { label: 'Sedex', value: 'Sedex' }
                ],
                "element-attr": { required: false, 'mode': 'default' }
            },
            {
                "title": "Contato transportadora",
                "key": "delivery_phone",
                "type": "text",
                colSize: 6,
                "element-attr": { required: false },
            },
        ],
        [
            {
                "title": "Faturamento",
                "key": "revenues",
                "type": "select",
                "options": props.salesChannels,
                "element-attr": { "required": true, 'mode': 'default' },
            },
            {
                "title": "Local de entrega",
                "key": "delivery_place",
                "type": "select",
                "options": props.salesChannels,
                "element-attr": { "required": true, 'mode': 'default' },
            },
        ],
        [
            {
                "title": "Destino do insumo",
                "key": "input_destiny",
                "type": "select",
                colSize: 6,
                "options": [
                    { label: 'Selecione...', value: null },
                    { label: 'Industrialização', value: 'Industrialização' },
                    { label: 'Consumo', value: 'Consumo' },
                    { label: 'Manutenção', value: 'Manutenção' },
                    { label: 'Ativo fixo', value: 'Ativo fixo' },
                    { label: 'Revenda', value: 'Revenda' }
                ],
                "element-attr": { required: false, 'mode': 'default' }
            },
            {
                "title": "Cliente",
                "key": "client",
                "type": "pointer",
                colSize: 12,
                "options": props.clients.map(client => {
                    return {
                        label: `${client.get('cod_cliente')} - ${client.get('name_client')}`,
                        value: client.id,
                    }
                }),
                "relation-select": {
                    "class": "Client",
                    "label": (obj) => `${obj.cod_cliente} - ${obj.name_client}`,
                    "value": "objectId",
                    "key": "objectId",
                },
                "element-attr": { "required": false, 'mode': 'default' },
            },
            {
                "title": "Pedido",
                "key": "sales_order_code",
                "type": "text",
                colSize: 6,
                "element-attr": { required: false }
            },
        ],
    ]
}

export default generalInfoFields